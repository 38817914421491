import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as Sentry from '@sentry/react';
import {
  Box,
  Button,
  createStyles,
  Grid,
  makeStyles,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import {
  Refresh as RefreshIcon,
  ReportProblem as ReportIcon,
  ArrowBack as ArrowBackIcon,
} from '@material-ui/icons';

import { ReactComponent as Sad } from 'img/icons/sad.svg';

import { HOME_PATH } from 'common/constants/globalConstants';

import { colors } from 'common/ui/theme/default';

import errorMessages from 'common/errors/messages';
import messages from 'common/messages/messages';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.default,
    },
    sadIcon: {
      fontSize: 138,
      color: colors.errorSadIcon,
    },
    wrongText: {
      fontWeight: theme.typography.h1.fontWeight,
      fontSize: '3rem',

      [theme.breakpoints.down('md')]: {
        fontSize: '1.5rem',
      },
    },
  }),
);

const ErrorPage = (): JSX.Element => {
  const classes = useStyles();

  return (
    <Box className={classes.root} height="100vh">
      <Grid container alignItems="center" spacing={4} direction="column">
        <Grid item xs={12}>
          <SvgIcon component={Sad} className={classes.sadIcon} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h1" className={classes.wrongText} align="center">
            <FormattedMessage {...errorMessages.oopsError} />
            <br />
            <FormattedMessage {...errorMessages.somethingWentWrongError} />
          </Typography>
        </Grid>
        {/* <Grid item xs={12}>
          <Typography variant="subtitle1">
            <FormattedMessage {...messages.errorCode} values={{ code: 500 }} />
          </Typography> // TODO after adding functionality error handling of application
        </Grid> */}
        <Grid container justify="center" spacing={1}>
          <Grid item>
            <Button
              variant="contained"
              startIcon={<ArrowBackIcon />}
              color="primary"
              onClick={() => window.location.replace(HOME_PATH)}
            >
              <FormattedMessage {...messages.goBackBtn} />
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="primary"
              startIcon={<RefreshIcon />}
              onClick={() => window.history.go(0)}
            >
              <FormattedMessage {...errorMessages.refresh} />
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="outlined"
              startIcon={<ReportIcon />}
              color="primary"
              onClick={() => Sentry.showReportDialog()}
            >
              <FormattedMessage {...errorMessages.report} />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ErrorPage;
