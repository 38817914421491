import GrapesJS from 'grapesjs';

export const defaultToolbar = [
  {
    attributes: { class: 'fa fa-arrow-up' },
    command: 'move-up',
  },
  {
    attributes: { class: 'fa fa-arrow-down' },
    command: 'move-down',
  },
  {
    attributes: { class: 'fa fa-arrows' },
    command: 'tlb-move',
  },
  {
    attributes: { class: 'fa fa-clone' },
    command: 'tlb-clone',
  },
  {
    attributes: { class: 'fa fa-trash-o' },
    command: 'tlb-delete',
  },
];

(GrapesJS as any).plugins.add('text-edit', function(editor) {
  function addCommands() {
    editor.Commands.add('move-up', {
      run(commandEditor, sender, data) {
        const component = commandEditor.getSelected();

        const parent = component.parent();
        const index = component.index();

        if (index > 0) {
          const styles = component.getStyle();
          const removedComponent = component.remove();

          removedComponent.setStyle(styles);
          parent.append(removedComponent, { at: index - 1 });
          commandEditor.refresh();
        }
      },
    });

    editor.Commands.add('move-down', {
      run(commandEditor, sender, data) {
        const component = commandEditor.getSelected();

        const parent = component.parent();
        const index = component.index();

        if (index < parent.components().length - 1) {
          const styles = component.getStyle();
          const removedComponent = component.remove();

          removedComponent.setStyle(styles);
          parent.append(removedComponent, { at: index + 1 });
          commandEditor.refresh();
        }
      },
    });
  }

  function addComponents() {
    const textType = editor.DomComponents.getType('text');
    const { initToolbar } = textType.model.prototype;

    editor.DomComponents.addType('text', {
      model: {
        ...textType.model,
        initToolbar(args) {
          initToolbar.apply(this, args);

          this.set('toolbar', defaultToolbar);
        },
      },
      view: {
        ...textType.view,
        events: {
          ...textType.view.events,
          click: 'onActive',
          touchend: 'onActive',
        },
      },
    });
  }

  addCommands();
  addComponents();
});

export default 'text-edit';
