/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Box, FormHelperText } from '@material-ui/core';
import { colors } from 'common/ui/theme/default';
// interfaces
import { IDateRangeFilterValue } from 'common/interfaces/filter';
// messages
import messages from 'modules/services/messages/messages';
// components
import { DateRange } from 'common/components';
import DropdownFilter from '../FiltersContainer/DropdownFilter/DropdownFilter';
import HoverFilter from '../FiltersContainer/HoverFilter/HoverFilter';
import FilterMenuBody from '../FiltersContainer/FilterMenuBody/FilterMenuBody';

const useStyles = makeStyles(theme => ({
  root: {
    '& .filter-button': {
      zIndex: 2,
      width: '100%',
      height: '48px',
      border: `1px solid ${colors.input.borderColor}`,
      boxShadow: 'unset',
      borderRadius: 3,
      '& .MuiButton-label': {
        '& p': {
          color: theme.palette.text.primary,
          fontWeight: 400,
        },
        '& span': {
          display: 'none',
        },
      },
    },
    '& .selected': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    '& .filter-list-button': {
      width: '100%',
      height: 48,
      boxShadow: 'unset',
      backgroundColor: 'unset',
      border: `1px solid ${colors.input.borderColor}`,
      borderRadius: 3,
      padding: '8px!important',
      justifyContent: 'center',
      '& .MuiListItemIcon-root': {
        display: 'none',
      },
      '& p': {
        color: theme.palette.text.primary,
        fontWeight: 400,
      },
    },
  },
}));

interface IDateRangeSelectorProps {
  value: IDateRangeFilterValue;
  onChange: (value: IDateRangeFilterValue) => void;
  name: string;
  canReset?: boolean;
  resetValue?: IDateRangeFilterValue;
  error?: boolean;
  helperText?: JSX.Element | string;

  dateTitleFormat?: string;
}

const DEFAULT_DATE_FORMAT = 'MMM Do';

const DateRangeSelector: React.FC<IDateRangeSelectorProps> = ({
  value,
  onChange,
  name,
  canReset,
  resetValue,
  error,
  helperText,
  dateTitleFormat = DEFAULT_DATE_FORMAT,
}: IDateRangeSelectorProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const initialState: IDateRangeFilterValue = value || { startDate: null, endDate: null };

  const [dateRangeValue, setDateRangeValue] = useState<IDateRangeFilterValue>(initialState);

  const isShowReset: boolean =
    canReset && (value.startDate !== resetValue.startDate || value.endDate !== resetValue.endDate);

  // effects

  useEffect(() => setDateRangeValue(value || { startDate: null, endDate: null }), [value]);

  // handlers

  const handleFilterOptionsChange = (closePicker?: () => void) => {
    const RangeValue = !dateRangeValue.startDate && !dateRangeValue.endDate ? null : dateRangeValue;
    onChange(RangeValue);
    if (closePicker) closePicker();
  };

  const handleChangeCancel = useCallback(
    () => setDateRangeValue(value || { startDate: null, endDate: null }),
    [value],
  );

  const handleFilterReset = useCallback(() => {
    setDateRangeValue(prevState => ({ ...prevState, startDate: null, endDate: null }));
  }, [setDateRangeValue]);

  const renderFilterTitle = (): JSX.Element | string => {
    // TODO check below is probably not working, need to check
    if (canReset && value.startDate === null && value.endDate === null) return 'Multiple';
    const { startDate, endDate } = value || { startDate: null, endDate: null };
    if (startDate === null) {
      return <FormattedMessage {...messages.periodTitle} />;
    }

    const strStartDate = moment(startDate).format(dateTitleFormat);

    if (endDate === null) {
      return `From ${strStartDate}`;
    }

    const strEndDate = moment(endDate).format(dateTitleFormat);

    return strStartDate === strEndDate ? strStartDate : `${strStartDate} - ${strEndDate}`;
  };

  const renderFilterBody = (closeFilterBody?: () => void) => {
    const onChangeDateRange = (dateRange: IDateRangeFilterValue) => {
      setDateRangeValue(prevState => ({ ...prevState, ...dateRange }));
    };

    return (
      <FilterMenuBody
        showClear={!isSmallScreen}
        showApply={!isSmallScreen}
        disableClear={!dateRangeValue.startDate}
        onClearFilter={handleFilterReset}
        onApplyFilter={() => handleFilterOptionsChange(closeFilterBody)}
      >
        <Box width="100%" display="flex" justifyContent="center">
          <DateRange
            value={dateRangeValue}
            months={isSmallScreen ? 1 : 2}
            onChange={onChangeDateRange}
            maxDate={dateRangeValue?.maxDate}
            minDate={dateRangeValue?.minDate}
          />
        </Box>
      </FilterMenuBody>
    );
  };

  const renderDropdownFilter = () => {
    return (
      <DropdownFilter
        name={name}
        title={renderFilterTitle()}
        dialogMenu={isSmallScreen}
        onClose={handleChangeCancel}
        renderBody={renderFilterBody}
        onDialogApply={handleFilterOptionsChange}
        className={classes.root}
        isShowReset={isShowReset}
        reset={event => {
          event.stopPropagation();
          onChange(resetValue);
        }}
      />
    );
  };

  const renderHoverMenuFilter = () => {
    return (
      <HoverFilter
        name={name}
        title={renderFilterTitle()}
        dialogMenu={isSmallScreen}
        onClose={handleChangeCancel}
        renderBody={renderFilterBody}
        onDialogApply={handleFilterOptionsChange}
        className={classes.root}
        isShowReset={isShowReset}
        reset={event => {
          event.stopPropagation();
          onChange(resetValue);
        }}
      />
    );
  };

  return (
    <>
      {isSmallScreen ? renderHoverMenuFilter() : renderDropdownFilter()}
      {error && <FormHelperText error>{helperText}</FormHelperText>}
    </>
  );
};

export default React.memo(DateRangeSelector);
