import CheckInService from './CheckInService';
import PersonsService from './PersonsService';
import MembersService from './MembersService';
import AlertsSettingsService from './AlertsSettingsService';
import FileUploadService from './FileUploadService';
import BookingService from 'services/network/BookingService';
import EmployeesService from './EmployeesService';
import PeakEmployeesService from './PeakEmployeesService';
import DepartmentsService from './DepartmentsService';
import ResourcesService from './ResourcesService';
import ScriptsService from './ScriptsService';
import TasksService from './TasksService';
import LeadsService from './LeadsService';
import CampaignsService from './CampaignsService';
import ServicePackageService from './ServicePackageService';
import DictionaryListsService from './DictionaryListsService';
import ReportsService from './ReportsService';
import PosSettingsService from './PosSettingsService';
import ServicesService from './ServicesService';
import PosKioskService from './PosKioskService';
import DictionariesService from './DictionariesService';
import AuthenticationService from './AuthenticationService';
import PermissionsService from './PermissionsService';
import CorporationsService from './CorporationsService';
import CorporationMessagingSettingsService from 'services/network/CorporationMessagingSettings';
import ClubsService from './ClubsService';
import AllergiesService from 'services/network/AllergiesService';
import CRMTemplatesService from 'services/network/CRMTemplatesService';
import FrontDeskService from 'services/network/FrontDeskService';
import CameFromService from 'services/network/CameFromService';
import AdditionalGuestFieldsService from 'services/network/AdditionalGuestFieldsService';
import NoteCodesService from 'services/network/NoteCodesService';
import FallThroughReasonsService from 'services/network/FallThroughReasonsService';
import PTLeadsService from 'services/network/PTLeadsService';
import DictionaryItemService from 'services/network/DictionaryItemService';
import ClubSettingsService from 'services/network/ClubSettingsService';
import GlobalSearchService from 'services/network/GlobalSearchService';
import HelpInfoSearchService from 'services/network/HelpInfoSearchService';
import CorporationSettingsService from 'services/network/CorporationSettings';
import FeesService from './FeesService';
import KidZoneService from './KidZoneService';
import FrontDeskAlertsService from './FrontDeskAlertsService';
import PtTasksService from './PtTasksService';
import PtScriptsService from './PtScriptsService';
import PtCRMTemplatesService from './PtCRMTemplatesService';
import PtCampaignsService from './PtCampaignsService';
import BillingScheduleService from './BillingScheduleService';
import FrontDeskRedeemService from './FrontDeskRedeemService';
import MembersRedeemService from './MembersRedeemService';
import PTLeadRedeemService from './PTLeadRedeemService';
import LeadRedeemService from './LeadRedeemService';
import PeakMessagingSettings from './PeakMessagingSettings';
import TimeclockService from './TimeclockService';
import PeakContactsService from './PeakContactsService';
import MemberPortalAuthenticationService from './MemberPortalAuthenticationService';
import MemberPortalProfileService from './MemberPortalProfileService';
import MemberPortalDictionaryListsService from './MemberPortalDictionaryListsService';
import CommunicationService from './CommunicationService';
import EndReasonsService from './EndReasonsService';

export default {
  Authentication: new AuthenticationService(),
  MemberPortalAuthentication: new MemberPortalAuthenticationService(),
  MemberPortalProfile: new MemberPortalProfileService(),
  CheckIn: new CheckInService(),
  Persons: new PersonsService(),
  Members: new MembersService(),
  Alerts: new AlertsSettingsService(),
  FileUpload: new FileUploadService(),
  Booking: new BookingService(),
  Employees: new EmployeesService(),
  PeakEmployees: new PeakEmployeesService(),
  Departments: new DepartmentsService(),
  Resources: new ResourcesService(),
  Scripts: new ScriptsService(),
  PtScripts: new PtScriptsService(),
  TasksService: new TasksService(),
  PtTasksService: new PtTasksService(),
  Leads: new LeadsService(),
  PTLeads: new PTLeadsService(),
  CampaignsService: new CampaignsService(),
  PtCampaignsService: new PtCampaignsService(),
  ServicePackage: new ServicePackageService(),
  DictionaryLists: new DictionaryListsService(),
  DictionaryItems: new DictionaryItemService(),
  Reports: new ReportsService(),
  PosSettings: new PosSettingsService(),
  ServicesList: new ServicesService(),
  PosKiosk: new PosKioskService(),
  Dictionaries: new DictionariesService(),
  Permissions: new PermissionsService(),
  Corporations: new CorporationsService(),
  CorporationSettings: new CorporationSettingsService(),
  CorporationMessagingSettings: new CorporationMessagingSettingsService(),
  Clubs: new ClubsService(),
  ClubSettings: new ClubSettingsService(),
  Allergies: new AllergiesService(),
  CRMTemplates: new CRMTemplatesService(),
  PtCRMTemplates: new PtCRMTemplatesService(),
  FrontDesk: new FrontDeskService(),
  CameFromIndicators: new CameFromService(),
  AdditionalGuestFields: new AdditionalGuestFieldsService(),
  NoteCodes: new NoteCodesService(),
  FallThroughReasons: new FallThroughReasonsService(),
  GlobalSearch: new GlobalSearchService(),
  HelpInfoSearch: new HelpInfoSearchService(),
  Fees: new FeesService(),
  KidZone: new KidZoneService(),
  FrontDeskAlerts: new FrontDeskAlertsService(),
  BillingSchedule: new BillingScheduleService(),
  FrontDeskRedeem: new FrontDeskRedeemService(),
  MembersRedeem: new MembersRedeemService(),
  LeadsRedeem: new LeadRedeemService(),
  PTLeadsRedeem: new PTLeadRedeemService(),
  PeakMessagingSettings: new PeakMessagingSettings(),
  Timeclock: new TimeclockService(),
  PeakContacts: new PeakContactsService(),
  MemberPortalDictionaryListsService: new MemberPortalDictionaryListsService(),
  Communication: new CommunicationService(),
  EndReasons: new EndReasonsService(),
};
