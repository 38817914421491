import * as React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { DispatchFunction } from '../state/interfaces';

interface ReduxProps {
  makeStateToProps?: () => (state: any, ownProps?: any) => any;
  mapStateToProps?: (state: any, ownProps?: any) => any;
  mapDispatchToProps?: (dispatch: DispatchFunction) => void;
}

export const Connect = (reduxProps?: ReduxProps) => (
  WrappedComponent: React.ComponentType<any>,
) => {
  let mapStateToProps: any = () => ({});
  if (reduxProps.makeStateToProps) {
    mapStateToProps = reduxProps.makeStateToProps;
  } else if (reduxProps.mapStateToProps) {
    mapStateToProps = (state, ownProps) =>
      createStructuredSelector(reduxProps.mapStateToProps(state, ownProps));
  }

  return connect(
    mapStateToProps,
    reduxProps.mapDispatchToProps ? reduxProps.mapDispatchToProps : () => {},
  )(WrappedComponent);
};
