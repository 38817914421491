import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { List as ImmutableList } from 'immutable';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';
// constants
import { payTypeLabels } from 'modules/employees/constants';
import { EmployeePayTypes } from 'common/constants/employee';
import { DictionaryList } from 'common/constants';
import employeesMessages from 'common/messages/employeesMessages';
import { ServerError } from 'common/errors/serverErrors';
// state
import { selectDictionaryList } from 'common/state/dictionary/selectors';
import { fetchDictionaryList } from 'common/state/dictionary/actions';
import { selectCurrentCorporateUserAvailableClubs } from 'modules/authentication/state/selectors';
import { selectUpdateEmployeeError } from 'modules/employees/state/selectors';
// hooks
import { useAppDispatch } from 'store/hooks';
// interfaces
import { IDictionaryCommonItem } from 'modules/dictionaries/interfaces/interfaces';
import { IServerError } from 'common/interfaces/http';
import { AlertTypes } from 'common/interfaces/alerts';
// common
import { MultipleSelect, NumberTextField, Select, Alert } from 'common/components';
// messages
import inputLabels from 'common/messages/inputLabels';
import commonMessages from 'common/messages/messages';
import errorMessages from 'common/errors/messages';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

interface IJobSectionProps {
  isVisibleTitle?: boolean;
  disableEditableFields?: boolean;
}

const JobSection = (props: IJobSectionProps): JSX.Element => {
  const { isVisibleTitle = true, disableEditableFields } = props;

  const dispatch = useAppDispatch();
  const { control, errors, watch } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'jobPositions',
    keyName: 'key',
  });

  const clubs = useSelector(selectCurrentCorporateUserAvailableClubs);
  const departments: ImmutableList<IDictionaryCommonItem> = useSelector(
    selectDictionaryList(DictionaryList.DEPARTMENTS),
  );
  const updateEmployeeError: IServerError = useSelector(selectUpdateEmployeeError);

  const renderIntlMessage = useRenderIntlMessage();

  const hasDuplicateJobPositionError = !!updateEmployeeError?.codes?.find(
    errorItem => errorItem === ServerError.DUPLICATE_JOB_POSITION_ERROR,
  );

  useEffect(() => {
    dispatch(fetchDictionaryList(DictionaryList.DEPARTMENTS));
  }, [dispatch]);

  return (
    <Grid container spacing={1}>
      {isVisibleTitle && (
        <Grid item xs={12}>
          <Typography variant="button" color="textSecondary" gutterBottom>
            <FormattedMessage {...employeesMessages.job} />
          </Typography>
        </Grid>
      )}

      {hasDuplicateJobPositionError && (
        <Alert
          title={<FormattedMessage {...errorMessages.duplicateJobPositionError} />}
          severity={AlertTypes.Danger}
        />
      )}

      {fields.map(({ salary, club, jobTitle, department, payType, id, key }, index) => {
        const disableFields = disableEditableFields && Boolean(id);

        return (
          <Grid item xs={12} key={key}>
            <Box pt={index === 0 && !isVisibleTitle ? 0 : 3} width="100%">
              <Grid item container xs={12} spacing={1} wrap="nowrap">
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    {id && (
                      <Controller
                        name={`jobPositions[${index}].id`}
                        defaultValue={id}
                        control={control}
                        render={() => <></>}
                      />
                    )}
                    <Grid item xs={12} sm={6}>
                      <Controller
                        control={control}
                        defaultValue={jobTitle}
                        name={`jobPositions[${index}].jobTitle`}
                        variant="outlined"
                        autoComplete="off"
                        fullWidth
                        render={({ value, onChange, onBlur }) => (
                          <TextField
                            disabled={disableFields}
                            variant="outlined"
                            label={<FormattedMessage {...inputLabels.title} />}
                            onBlur={onBlur}
                            onChange={onChange}
                            fullWidth
                            error={!!errors.jobPositions?.[index]?.jobTitle}
                            helperText={renderIntlMessage(
                              errors.jobPositions?.[index]?.jobTitle?.message,
                            )}
                            value={value}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name={`jobPositions[${index}].club`}
                        control={control}
                        defaultValue={club}
                        render={({ value, onChange, onBlur }) => (
                          <MultipleSelect
                            disabled={disableFields}
                            fullWidth
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            options={clubs?.toJS()}
                            label={<FormattedMessage {...inputLabels.club} />}
                            error={!!errors.jobPositions?.[index]?.club}
                            helperText={renderIntlMessage(
                              errors.jobPositions?.[index]?.club?.message,
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name={`jobPositions[${index}].department`}
                        control={control}
                        defaultValue={department}
                        render={({ value, onChange, onBlur }) => (
                          <MultipleSelect
                            disabled={disableFields}
                            fullWidth
                            onBlur={onBlur}
                            value={value}
                            onChange={onChange}
                            options={departments?.toJS()}
                            label={<FormattedMessage {...inputLabels.department} />}
                            error={!!errors.jobPositions?.[index]?.department}
                            helperText={renderIntlMessage(
                              errors.jobPositions?.[index]?.department?.message,
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <Controller
                        control={control}
                        name={`jobPositions[${index}].payType`}
                        variant="outlined"
                        defaultValue={payType}
                        render={({ onChange, value, onBlur, name }) => (
                          <Select
                            disabled={disableFields}
                            label={<FormattedMessage {...inputLabels.payType} />}
                            value={value}
                            name={name}
                            fullWidth
                            onBlur={onBlur}
                            onChange={onChange}
                            error={!!errors.jobPositions?.[index]?.payType}
                            helperText={renderIntlMessage(
                              errors.jobPositions?.[index]?.payType?.message,
                            )}
                          >
                            {payTypeLabels.values.map(type => (
                              <MenuItem key={type.key} value={type.value}>
                                {payTypeLabels.translate(type.value)}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </Grid>

                    <Grid item xs={6} sm={3}>
                      <Controller
                        control={control}
                        name={`jobPositions[${index}].salary`}
                        defaultValue={salary}
                        variant="outlined"
                        render={({ onChange, value, onBlur }) => {
                          const positionPayType = watch(`jobPositions[${index}].payType`);
                          const isUnitPayType = positionPayType === EmployeePayTypes.UNIT;

                          return (
                            <NumberTextField
                              label={
                                <FormattedMessage
                                  {...payTypeLabels.find(positionPayType).message}
                                />
                              }
                              disabled={disableFields}
                              value={value}
                              variant="outlined"
                              fullWidth
                              onChange={onChange}
                              onBlur={onBlur}
                              numberFormatProps={{
                                maxLength: 10,
                                decimalScale: isUnitPayType ? 0 : 2,
                              }}
                              InputProps={{
                                startAdornment: !isUnitPayType ? (
                                  <InputAdornment position="start">$</InputAdornment>
                                ) : null,
                              }}
                              error={!!errors.jobPositions?.[index]?.salary}
                              helperText={renderIntlMessage(
                                errors.jobPositions?.[index]?.salary?.message,
                              )}
                            />
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid>
                  <Box pt={1}>
                    <IconButton onClick={() => remove(index)} style={{ padding: '6px' }}>
                      <DeleteIcon fontSize="small" color="secondary" />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <Link
          underline="none"
          component="button"
          type="button"
          disabled={!!errors.jobPositions}
          onClick={() =>
            append({ club: null, salary: '', payType: EmployeePayTypes.SALARY, department: null })
          }
          color="primary"
        >
          <AddIcon />
          <FormattedMessage {...commonMessages.addBtn} />
        </Link>
      </Grid>
    </Grid>
  );
};

export default React.memo(JobSection);
