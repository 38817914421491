import React, { forwardRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// constants
import {
  defaultEditPackageFormData,
  PackageCostType,
  PackageType,
} from 'modules/services/constants/packages';
// validationSchema
import { membershipSchema } from './membershipValidationSchema';
// interfaces
import { IMembershipSection, IPackageStepProps } from 'modules/services/interfaces/packages';
// components
import { ScrollBox } from 'common/components';
import MembershipStepForm from './MembershipStepForm';
import { useStepsLogic } from 'modules/services/hooks/useStepLogics';

interface IMembershipStepProps extends IPackageStepProps<IMembershipSection> {
  packageType: PackageType;
  packageCostType: PackageCostType;
}

const MembershipStep = forwardRef(
  (
    {
      editPackageSectionFormData,
      renderFooter,
      handleChange,
      packageType,
      packageCostType,
      onPackageSubmit,
      formErrors,
      submitAttempted,
    }: IMembershipStepProps,
    ref,
  ): JSX.Element => {
    const defaultValues: IMembershipSection = {
      ...defaultEditPackageFormData.membershipSection,
      ...editPackageSectionFormData,
    };

    const formMethods = useForm({
      defaultValues,
      resolver: yupResolver(membershipSchema),
      mode: 'all',
    });

    const { trigger, getValues } = formMethods;

    const { onSubmit, onNext, onBack } = useStepsLogic(
      'membershipSection',
      ref,
      getValues,
      trigger,
      formErrors,
      onPackageSubmit,
      handleChange,
      submitAttempted,
    );

    return (
      <FormProvider {...formMethods}>
        <ScrollBox hasShadowsOnScroll>
          <form autoComplete="none">
            <MembershipStepForm
              formMethods={formMethods}
              defaultValues={defaultValues}
              packageType={packageType}
              packageCostType={packageCostType}
            />
          </form>
        </ScrollBox>
        {renderFooter(onSubmit, onNext, onBack)}
      </FormProvider>
    );
  },
);

export default React.memo(MembershipStep);
