import { fromJS } from 'immutable';

import { actionTypes } from 'modules/crm/constants/scripts';

const initStateRequestErrors = fromJS({
  errors: [],
});

export const requestErrorsReducer = (state = initStateRequestErrors, action) => {
  switch (action.type) {
    case actionTypes.PUSH_REQUEST_ERROR:
      return state.update('errors', errors => errors.push(action.payload));
    case actionTypes.RESET_REQUEST_ERRORS:
      return state.set('errors', []);
    default:
      return state;
  }
};

const initStateScripts = fromJS({
  list: [],
  meta: {
    page: 0,
    perPage: 25,
    total: 0,
  },
  listLoading: false,
  changeScriptStatusLoading: false,
  changeScriptStatusResult: {},
});

export const scriptsTableReducer = (state = initStateScripts, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SCRIPTS_LIST_LOADING:
      return state.set('listLoading', action.payload);
    case actionTypes.FETCH_SCRIPTS_LIST_SUCCESS:
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta))
        .set('listLoading', false);
    case actionTypes.CHANGE_SCRIPT_STATUS_LOADING:
      return state.set('changeScriptStatusLoading', action.payload);
    case actionTypes.CHANGE_SCRIPT_STATUS_SUCCESS:
      return state.set('changeScriptStatusResult', fromJS(action.payload));
    case actionTypes.RESET_CHANGE_SCRIPT_STATUS_RESULT:
      return state.set('changeScriptStatusResult', fromJS({}));
    default:
      return state;
  }
};

const initStateScriptCreateForm = fromJS({
  scriptCreating: false,
  createdScript: null,
});

export const createScriptReducer = (state = initStateScriptCreateForm, action) => {
  switch (action.type) {
    case actionTypes.RESET_SCRIPT_FORM:
      return initStateScriptCreateForm;
    case actionTypes.CREATE_SCRIPT_LOADING:
      return state.set('scriptCreating', action.payload);
    case actionTypes.CREATE_SCRIPT_SUCCESS:
      return state.set('createdScript', fromJS(action.payload)).set('scriptCreating', false);
    default:
      return state;
  }
};

const initStateScriptUpdateForm = fromJS({
  script: {},
  scriptLoading: false,
  updatedScript: null,
  scriptUpdating: false,
});

export const updateScriptReducer = (state = initStateScriptUpdateForm, action) => {
  switch (action.type) {
    case actionTypes.RESET_SCRIPT_FORM:
      return initStateScriptUpdateForm;
    case actionTypes.FETCH_SCRIPT_LOADING:
      return state.set('scriptLoading', action.payload);
    case actionTypes.FETCH_SCRIPT_SUCCESS:
      return state.set('script', fromJS(action.payload)).set('scriptLoading', false);
    case actionTypes.UPDATE_SCRIPT_LOADING:
      return state.set('scriptUpdating', action.payload);
    case actionTypes.UPDATE_SCRIPT_SUCCESS:
      return state.set('updatedScript', fromJS(action.payload)).set('scriptUpdating', false);
    default:
      return state;
  }
};
