import React from 'react';
import { Avatar, Box, makeStyles, MenuItem, Theme, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { getPriceAmountString } from 'common/utils/calculate';
import { formatNumberToPrice } from 'common/utils/format';

import { IInvoiceProductItem, InvoiceStatus } from 'common/interfaces/invoices';
import { ReactComponent as DefaultIcon } from 'img/icons/default-icon.svg';

import { Button, Select } from 'common/components/index';

import commonMessages from 'common/messages/messages';
import messages from 'modules/pos-kiosk/messages/messages';
import { colors } from 'common/ui/theme/default';
import { IPosKioskRegisterDataImt } from '../../../../modules/pos-kiosk/interfaces/registers';
import { useSelector } from 'react-redux';
import { selectPosKioskRegisterData } from '../../../../modules/pos-kiosk/state/register/selectors';
import { IRegisterPosCategory } from '../../../../modules/pos-settings/interfaces/register';

const useStyles = makeStyles((theme: Theme) => ({
  productImage: {
    marginRight: theme.spacing(1),
    borderRadius: 3,
  },
  itemTitle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  itemPrice: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
  },
  pricePerItem: {
    display: 'inline-block',
    color: theme.palette.secondary.dark,
  },
  errorText: {
    color: theme.palette.error.main,
    fontWeight: 500,
  },
  primaryText: {
    color: colors.primary,
    fontWeight: 500,
  },
  countSelect: {
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      padding: theme.spacing(0.5, 4, 0.5, 1.5),
    },
    marginRight: theme.spacing(1),
  },
}));

interface IInvoiceProductItemProps {
  product: IInvoiceProductItem;
  onCountChange?: (count: number) => void;
  onAdd?: () => void;
  onDelete?: () => void;
  isAddItems?: boolean;
  addedProducts?: IInvoiceProductItem[];
  disabled?: boolean;
  isOutOfStock?: boolean;
  isAlreadyAdded?: boolean;
  selectedInvoiceStatus?: InvoiceStatus;
}

export default function InvoiceProductItem({
  product,
  isAddItems,
  addedProducts,
  onAdd,
  onDelete,
  onCountChange,
  disabled,
  isOutOfStock = false,
  isAlreadyAdded = false,
  selectedInvoiceStatus = InvoiceStatus.OPEN,
}: IInvoiceProductItemProps): JSX.Element {
  const renderIntlMessage = useRenderIntlMessage();

  const classes = useStyles();

  const register: IPosKioskRegisterDataImt = useSelector(selectPosKioskRegisterData);

  const isAdd = Boolean(addedProducts?.find(item => item.id === product.inventoryId && item.type));

  const taxedPrice = product.price + product.taxAmount;

  const isInProgressSelectedInvoice = selectedInvoiceStatus === InvoiceStatus.IN_PROGRESS;

  const getOnHandAmount = (inventoryId: string) => {
    const posButton = register.toJS().registerPosButton;

    const searchInPosCategoryRecursively = (posCategory: IRegisterPosCategory) => {
      if (!posCategory) {
        return posCategory;
      }

      const inventoryClub = posCategory.posButtonInventoryClubList?.find(
        el => el.inventoryClub.id === inventoryId,
      );

      return (
        inventoryClub ||
        posCategory.childButtonList
          ?.map(childButtonList => searchInPosCategoryRecursively(childButtonList))
          .find(el => !!el)
      );
    };

    return searchInPosCategoryRecursively(posButton)?.inventoryClub.onHandAmount || 0;
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" paddingY={1.5}>
      <Box display="flex" alignItems="center">
        {isAddItems ? (
          <>
            <Avatar
              className={classes.productImage}
              src={product.imageUrl}
              alt={product.title}
              variant="square"
            >
              <DefaultIcon />
            </Avatar>

            <Typography className={classes.itemTitle}>
              <Typography component="span">{product.title}</Typography>

              <Typography component="span" className={classes.pricePerItem}>
                ${formatNumberToPrice(product.price)}
              </Typography>
            </Typography>
          </>
        ) : (
          <>
            <Select
              value={product.number}
              onChange={count => (Number.isInteger(count) ? onCountChange(count) : onDelete())}
              size="small"
              className={classes.countSelect}
              disabled={!isInProgressSelectedInvoice ? disabled : true}
            >
              {isInProgressSelectedInvoice && (
                <MenuItem value={product.number}>{product.number}</MenuItem>
              )}
              {!isInProgressSelectedInvoice &&
                Array.from(
                  {
                    length: getOnHandAmount(product.inventorySnapshotSourceId) + product.number + 1,
                  },
                  (_, index) => index || renderIntlMessage(commonMessages.removeBtn),
                ).map((itemsCount, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <MenuItem key={index} value={itemsCount}>
                    {itemsCount}
                  </MenuItem>
                ))}
            </Select>

            <Box display="flex" flexDirection="column">
              <Typography variant="h5">{product.title}</Typography>
            </Box>
          </>
        )}
      </Box>

      {isOutOfStock && (
        <Typography variant="caption" className={classes.errorText}>
          <FormattedMessage {...commonMessages.outOfStockText} />
        </Typography>
      )}

      {isAlreadyAdded && (
        <Typography variant="caption" className={classes.primaryText}>
          <FormattedMessage {...commonMessages.alreadyInCartText} />
        </Typography>
      )}

      {isAddItems && !isOutOfStock && !isAlreadyAdded && (
        <Button
          color={isAdd ? 'default' : 'primary'}
          variant="contained"
          size="small"
          onClick={() => (isAdd ? onDelete() : onAdd())}
        >
          <FormattedMessage {...(!isAdd ? commonMessages.addBtn : commonMessages.removeBtn)} />
        </Button>
      )}

      {!isAddItems && !isOutOfStock && (
        <Typography className={classes.itemPrice}>
          <Typography component="span" variant="h5">
            ${formatNumberToPrice(product.price)}
          </Typography>

          <Typography component="span" className={classes.pricePerItem}>
            ${formatNumberToPrice(getPriceAmountString(taxedPrice, product.number))}
          </Typography>
        </Typography>
      )}
    </Box>
  );
}
