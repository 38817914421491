import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Avatar, Box, makeStyles, Paper, Typography } from '@material-ui/core';

import commonMessages from 'common/messages/messages';
import { ListboxComponent, SearchInputWithOptions } from 'common/components';
import { ReactComponent as AddIcon } from 'img/icons/add.svg';
import { CustomTheme } from 'common/ui/interfaces';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { ICustomerShort, IFamilyMemberSearch } from 'common/components/PersonProfile/interfaces';
import { getInitials } from 'helpers/common';
import profilePlaceholder from 'img/photo-placeholder.png';
import { debounce } from 'common/utils';
import { PeakModules } from 'common/constants/peakModules';
import Services from 'services/network';
import messages from 'common/components/PersonProfile/messages';

const useStyles = makeStyles((theme: CustomTheme) => ({
  autocomplete: {
    '& .MuiAutocomplete-inputRoot': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  optionAvatar: {
    width: '100%',
    height: '100%',
  },
  optionName: {
    fontSize: '0.9rem',
    fontWeight: 500,
  },
  avatarWrap: {
    position: 'relative',
    flex: 'none',
    width: '36px',
    height: '36px',
    backgroundColor: theme.palette.secondary.light,
    borderRadius: '50%',
  },
  avatarInitial: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    fontSize: '16px',
    fontWeight: 700,
    color: theme.palette.darkBackground.light,
  },
  addOption: {
    padding: theme.spacing(1.5, 2),
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  addOptionIcon: {
    marginRight: theme.spacing(1),
    width: 16,
    height: 16,
  },
}));

interface IMemberSearchInputProps {
  module: PeakModules;
  personId: string;
  onChange?: (value: NonNullable<ICustomerShort>) => void;
  getOptionDisabled?: (options: ICustomerShort) => boolean;
  onCreateNew: () => void;
  fullWidth: boolean;
}

const MemberSearchInput = (props: IMemberSearchInputProps) => {
  const { module, personId, onChange, getOptionDisabled, onCreateNew, fullWidth } = props;

  const [options, setOptions] = useState<ICustomerShort[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const renderIntlMessages = useRenderIntlMessage();

  const classes = useStyles();

  const loadMembers = useCallback(
    debounce(async value => {
      setIsLoading(true);
      let searchMethod: (searchStr: string, id: string) => Promise<IFamilyMemberSearch>;

      switch (module) {
        case PeakModules.FrontDesk:
          searchMethod = Services.FrontDesk.searchFamilyMembers;
          break;
        case PeakModules.Crm:
          searchMethod = Services.Leads.searchFamilyMembers;
          break;
        case PeakModules.PersonalTrainingCrm:
          searchMethod = Services.PTLeads.searchFamilyMembers;
          break;
        default:
          searchMethod = Services.Members.searchFamilyMembers;
      }

      const members = await searchMethod(value, personId);

      setOptions(members.data);

      setIsLoading(false);
    }),
    [module],
  );

  const onSelect = (e: React.BaseSyntheticEvent, option: NonNullable<ICustomerShort>) => {
    onChange(option);
  };

  const renderUserOption = ({ imageUrl, firstName, lastName, id }: ICustomerShort) => {
    const initials = getInitials(firstName, lastName);

    return (
      <Box display="flex" key={id}>
        <Box className={classes.avatarWrap}>
          {initials && !imageUrl ? (
            <Box className={classes.avatarInitial}>{initials}</Box>
          ) : (
            <Avatar
              src={imageUrl || profilePlaceholder}
              className={classes.optionAvatar}
              alt={firstName}
            />
          )}
        </Box>
        <Box ml={1}>
          <Typography className={classes.optionName}>{`${firstName} ${lastName}`}</Typography>
        </Box>
      </Box>
    );
  };

  const ListboxProps = {
    itemSize: 50,
    limitCount: 6,
    isLoading,
  };

  const onBlur = () => {
    setOptions([]);
  };

  return (
    <SearchInputWithOptions<ICustomerShort>
      className={classes.autocomplete}
      onBlur={onBlur}
      fullWidth={fullWidth}
      onChange={onSelect}
      showPopperComponent
      placeholder={renderIntlMessages(messages.stepAutocompleteLabel)}
      getOptionsByValue={loadMembers}
      loading={isLoading}
      renderOption={renderUserOption}
      getOptionLabel={option => (option ? `${option.firstName} ${option.lastName}` : '')}
      options={options}
      ListboxComponent={ListboxComponent}
      ListboxProps={ListboxProps}
      getOptionDisabled={getOptionDisabled}
      PaperComponent={({ children, className }) => {
        return (
          <Paper className={className}>
            <Box
              className={classes.addOption}
              onMouseDown={e => e.preventDefault()}
              onClick={onCreateNew}
            >
              <AddIcon className={classes.addOptionIcon} />
              <Typography variant="subtitle2" color="inherit">
                <FormattedMessage {...commonMessages.newMemberCommonTitle} />
              </Typography>
            </Box>
            {children}
          </Paper>
        );
      }}
    />
  );
};

export default MemberSearchInput;
