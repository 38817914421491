// template
const selectTemplateLoading = (state: any) =>
  state.getIn(['services', 'documentTemplates', 'template', 'isLoading']);
const selectTemplate = (state: any) =>
  state.getIn(['services', 'documentTemplates', 'template', 'result']);
const selectSaveTemplateActionResult = (state: any) =>
  state.getIn(['services', 'documentTemplates', 'template', 'saveTemplateActionResult']);
const selectSaveDocumentTemplateLoading = (state: any) =>
  state.getIn(['services', 'documentTemplates', 'template', 'saveTemplateLoading']);

// templates
const selectDocumentTemplatesLoading = (state: any) =>
  state.getIn(['services', 'documentTemplates', 'templates', 'isLoading']);
const selectUpdateDocumentStatusIsLoading = (state: any) =>
  state.getIn(['services', 'documentTemplates', 'templates', 'updateStatusIsLoading']);
const selectUpdateDocumentStatusActionResult = (state: any) =>
  state.getIn(['services', 'documentTemplates', 'templates', 'updateStatusActionResult']);
const selectDocumentTemplatesList = (state: any) =>
  state.getIn(['services', 'documentTemplates', 'templates', 'list']);
const selectDocumentTemplatesListMeta = (state: any) =>
  state.getIn(['services', 'documentTemplates', 'templates', 'meta']);

export {
  selectDocumentTemplatesLoading,
  selectDocumentTemplatesList,
  selectDocumentTemplatesListMeta,
  selectTemplateLoading,
  selectTemplate,
  selectSaveTemplateActionResult,
  selectSaveDocumentTemplateLoading,
  selectUpdateDocumentStatusIsLoading,
  selectUpdateDocumentStatusActionResult,
};
