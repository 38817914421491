import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IBillingOption } from 'modules/services/interfaces/packages';
import { Box, Grid, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import messages from 'modules/services/messages/messages';
import { Check as CheckIcon, Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons';
import commonMessages from 'common/messages/messages';
import ResourceButton from 'modules/booking/components/ResourceForm/ResourceButton/ResourceButton';
import { FieldInfo } from 'common/components';
import {
  BillingOptionType,
  BillingOptionTypes,
  FrequencyType,
  FrequencyTypes,
} from 'modules/services/constants/packages';
import { CustomTheme } from 'common/ui/interfaces';
import { Message } from 'common/interfaces/common';
import {
  countPricePerBilling,
  countTaxedAmount,
  IDurationOptions,
} from 'modules/services/utils/billingUtils';

const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1.5),
  },
  btnBox: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'flex-end',
    '& > *': {
      marginLeft: theme.spacing(1),
    },
  },
  allowedItem: {
    display: 'inline-flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(1),
      strokeWidth: 1,
      stroke: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },
}));

interface IBillingOptionView {
  billingOption: IBillingOption;
  durationOption: IDurationOptions;
  totalTax: number;

  onRemove: () => void;
  onEdit: () => void;
}

const BillingOptionView = ({
  billingOption,
  durationOption,
  totalTax = 0,
  onRemove,
  onEdit,
}: IBillingOptionView): JSX.Element => {
  const classes = useStyles();

  const { totalAmount, paymentEditableSchedule, type: billingOptionType } = billingOption;

  const taxedTotalAmount =
    totalAmount && totalTax ? countTaxedAmount(totalAmount, totalTax) : totalAmount;
  const isFinancedBillingOptionType = billingOptionType === BillingOptionType.FINANCED;

  const countedTaxedPricePerBilling =
    isFinancedBillingOptionType &&
    countPricePerBilling(
      totalAmount,
      totalTax,
      durationOption,
      paymentEditableSchedule.value as FrequencyType,
    );

  const renderAllowView = (isRender: boolean, msg: Message) =>
    isRender && (
      <Grid item xs={12}>
        <Typography component="span" className={classes.allowedItem}>
          <CheckIcon fontSize="small" /> <FormattedMessage {...msg} />
        </Typography>
      </Grid>
    );

  return (
    <Box className={classes.container}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" flexWrap="wrap" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Typography variant="h5">{billingOption?.title}</Typography>
            </Box>
            <Box className={classes.btnBox}>
              <ResourceButton
                iconButton
                icon={<EditIcon />}
                iconButtonTooltip={<FormattedMessage {...commonMessages.editBtn} />}
                IconButtonProps={{
                  size: 'small',
                }}
                onClick={onEdit}
              />
              <ResourceButton
                iconButton
                icon={<DeleteIcon />}
                iconButtonTooltip={<FormattedMessage {...commonMessages.removeBtn} />}
                IconButtonProps={{
                  size: 'small',
                }}
                onClick={onRemove}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={2}>
              <FieldInfo
                label={<FormattedMessage {...messages.billingOptionTypeLabel} />}
                description={BillingOptionTypes.translate(billingOption?.type)}
              />
            </Grid>

            {isFinancedBillingOptionType && (
              <>
                <Grid item xs={2}>
                  <FieldInfo
                    label={<FormattedMessage {...messages.billingOptionScheduleLabel} />}
                    description={FrequencyTypes.translate(
                      billingOption?.paymentEditableSchedule?.value as string,
                    )}
                  />
                </Grid>

                <Grid item xs={2}>
                  <FieldInfo
                    label={<FormattedMessage {...messages.billingOptionPerBillingLabel} />}
                    description={`$${countedTaxedPricePerBilling?.toFixed(2)}`}
                  />
                </Grid>
              </>
            )}

            <Grid item xs={2}>
              <FieldInfo
                label={<FormattedMessage {...messages.billingOptionTotalAmountLabel} />}
                description={`$${taxedTotalAmount.toFixed(2)}`}
              />
            </Grid>
          </Grid>
        </Grid>

        {renderAllowView(billingOption.useAsDefault, messages.defaultPaymentOptionLabel)}

        {renderAllowView(!!billingOption.freeze?.allow, messages.allowMembershipFreezeLabel)}

        {renderAllowView(
          billingOption.freeze?.billingFrozenMembership,
          messages.billingFrozenMembershipLabel,
        )}

        {renderAllowView(
          billingOption.paymentSplitting?.allow,
          messages.allowSplitEachPaymentLabel,
        )}

        {renderAllowView(billingOption.paymentGrace?.allow, messages.allowPaymentGracePeriodLabel)}

        {/* {renderAllowView(billingOption.downPayment?.allow, messages.allowDownPaymentsLabel)} */}

        {renderAllowView(billingOption.creditCardFee?.enabled, messages.creditCardFeeLabel)}
      </Grid>
    </Box>
  );
};

export default BillingOptionView;
