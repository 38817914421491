import axios from 'axios';

import ApiService from 'services/network/ApiService';

import {
  InventoryRedeemHistoryDto,
  InventoryInstanceDetailsDto,
  IServiceItemDetails,
  IServiceRedeemHistoryImt,
  IServiceSectionData,
  IFamilyMemberServiceInstanceDetailsDto,
  IFamilyMemberToRedeem,
  IRedeemFamilyMemberServicePayload,
} from 'common/interfaces/service';
import { IPaginatedData } from 'common/interfaces/pagination';
import { IBookingEvent, IBookingEventListItem } from 'modules/booking/interfaces';
import {
  IServiceEventsCalendarParams,
  IScheduledServiceRedeemDTO,
  IUnscheduledServiceRedeemDTO,
  IRedeemResourceTag,
  IPrimaryMemberInfo,
  IFamilyMemberSearch,
  IFamilyMemberService,
  IFamilyMemberCreateDTO,
} from 'common/components/PersonProfile/interfaces';
import { ITableParams } from 'common/interfaces/table';

export default class MembersRedeemService extends ApiService {
  public getPersonServicesData = async (personId: string): Promise<IServiceSectionData> =>
    axios.get(`/api/v1/members/${personId}/service-section`);

  public getPersonServiceDetails = (
    personId: string,
    serviceId: string,
  ): Promise<IServiceItemDetails> =>
    axios.get(`api/v1/members/${personId}/service-section/services/${serviceId}`);

  public getPersonServiceEvents = (
    personId: string,
    searchParams?: IServiceEventsCalendarParams,
  ): Promise<IPaginatedData<IBookingEventListItem>> =>
    axios.get(`api/v1/members/${personId}/service-section/events`, {
      params: searchParams,
    });

  public getPersonServiceEventDetails = (
    personId: string,
    eventId: string,
    eventStartDate: string,
  ): Promise<IBookingEvent> =>
    axios.get(`api/v1/members/${personId}/service-section/events/${eventId}`, {
      params: { eventStartDate },
    });

  public getPersonAppointmentDetails = (
    personId: string,
    appointmentId: string,
    eventStartDate: string,
  ): Promise<IBookingEvent> =>
    axios.get(`/api/v1/members/${personId}/appointment/${appointmentId}`, {
      params: { eventStartDate },
    });

  public getRedeemedInventoryDetails = (
    personId: string,
    inventoryId: string,
  ): Promise<InventoryInstanceDetailsDto> => {
    return axios.get(`api/v1/members/${personId}/service-section/inventories/${inventoryId}`);
  };

  public getRedeemedFamilyMemberServiceDetails = (
    personId: string,
    serviceInstanceId: string,
  ): Promise<IFamilyMemberServiceInstanceDetailsDto> => {
    return axios.get(
      `/api/v1/members/${personId}/service-section/family-members/services/${serviceInstanceId}`,
    );
  };

  public getFamilyMembersToRedeem = (
    personId: string,
    serviceInstanceId: string,
  ): Promise<IFamilyMemberToRedeem[]> => {
    return axios.get(
      `/api/v1/members/${personId}/service-section/family-members/services/${serviceInstanceId}/redeem/members`,
    );
  };

  public getRedeemedInventoryRecentHistory = (
    personId: string,
    inventoryId: string,
  ): Promise<InventoryRedeemHistoryDto[]> => {
    return axios.get(
      `api/v1/members/${personId}/service-section/inventories/${inventoryId}/redeem/history/recent`,
    );
  };

  public getRedeemedInventoryHistory = (
    personId: string,
    inventoryId: string,
    tableParams: ITableParams,
  ): Promise<IPaginatedData<InventoryRedeemHistoryDto>> => {
    const searchParams = this.getSearchFromTableParams(tableParams);

    return axios.get(
      `api/v1/members/${personId}/service-section/inventories/${inventoryId}/redeem/history`,
      { params: searchParams },
    );
  };

  // Redeem

  public redeemInventory = (
    personId: string,
    inventoryId: string,
    redeemedNumber: number,
    pinCode: string,
    clubId: string,
  ): Promise<void> => {
    return axios.put(
      `api/v1/members/${personId}/service-section/inventories/${inventoryId}/redeem`,
      {
        redeemedNumber,
        pinCode,
        clubId,
      },
    );
  };

  public redeemFamilyMembers = (
    personId: string,
    serviceInstanceId: string,
    payload: IRedeemFamilyMemberServicePayload,
  ): Promise<void> => {
    return axios.put(
      `api/v1/members/${personId}/service-section/family-members/services/${serviceInstanceId}/redeem`,
      payload,
    );
  };

  public redeemScheduledService = (
    personId: string,
    serviceId: string,
    redeemData: IScheduledServiceRedeemDTO,
  ): Promise<void> =>
    axios.put(
      `api/v1/members/${personId}/service-section/services/${serviceId}/redeem/scheduled`,
      redeemData,
    );

  public redeemAppointmentScheduledService = (
    personId: string,
    serviceId: string,
    appointmentId: string,
    redeemData: IScheduledServiceRedeemDTO,
  ): Promise<void> =>
    axios.put(
      `api/v1/members/${personId}/appointment/${appointmentId}/services/${serviceId}/redeem/scheduled`,
      redeemData,
    );

  public redeemUnscheduledService = (
    personId: string,
    serviceId: string,
    redeemData: IUnscheduledServiceRedeemDTO,
  ): Promise<void> =>
    axios.put(
      `api/v1/members/${personId}/service-section/services/${serviceId}/redeem/unscheduled`,
      redeemData,
    );

  public getServiceResourceTags = (
    personId: string,
    clubId: string,
    date: string,
    tags: Array<string>,
  ): Promise<Array<IRedeemResourceTag>> =>
    axios.get(`/api/v1/members/${personId}/service-section/resources`, {
      params: { clubId, date, tags: tags.join(',') },
    });

  public getServiceRedeemRecentHistory = (
    personId: string,
    serviceId: string,
  ): Promise<IServiceRedeemHistoryImt> => {
    return axios.get(
      `api/v1/members/${personId}/service-section/services/${serviceId}/redeem/history/recent`,
    );
  };

  public getServiceRedeemHistory = (
    personId: string,
    serviceId: string,
    tableParams: ITableParams,
  ): Promise<IPaginatedData<InventoryRedeemHistoryDto>> => {
    const searchParams = this.getSearchFromTableParams(tableParams);

    return axios.get(
      `api/v1/members/${personId}/service-section/services/${serviceId}/redeem/history`,
      { params: searchParams },
    );
  };

  // Family members

  public createMember = (
    data: IPrimaryMemberInfo,
    initialFamilyMemberId: string = null,
  ): Promise<IPrimaryMemberInfo> =>
    axios.post(`/api/v1/members/${initialFamilyMemberId}/service-section/family-members`, data);

  public addFamilyMember = (personId: string, data: IFamilyMemberCreateDTO): Promise<void> =>
    axios.post(`/api/v1/members/${personId}/service-section/family-members/add`, data);

  public searchFamilyMembers = (
    searchStr: string,
    personId: string,
  ): Promise<IFamilyMemberSearch> => {
    const config = {
      params: searchStr
        ? {
            search: searchStr,
          }
        : {},
    };

    return axios.get(`/api/v1/members/${personId}/service-section/family-members/search`, config);
  };

  public getMemberServices = (memberId: string): Promise<IFamilyMemberService[]> =>
    axios.get(`/api/v1/members/${memberId}/service-section/family-members/services`);
}
