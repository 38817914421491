import React, { useContext, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormattedMessage } from 'react-intl';
import { FormProvider, useForm } from 'react-hook-form';
import { List as ImmutableList } from 'immutable';
import { useSelector } from 'react-redux';

import { fetchDictionaryList, resetDictionaryListAction } from 'common/state/dictionary/actions';
import {
  fetchAdditionalInfo,
  updateAdditionalInfo,
} from 'common/state/newPerson/additionalInfo/actions';

import { useAppDispatch } from 'store/hooks';

import { DictionaryList } from 'common/constants';

import { StepContext } from 'common/createContext/stepContext';

import { IAdditionalInfoImt } from 'modules/front-desk/interfaces';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';

import { ScrollBox } from 'common/components/index';
import InsuranceSection from './InsuranceSection/InsuranceSection';
import EmergencySection from './EmergencySection/EmergencySection';
import AllergiesSection from './AllergiesSection/AllergiesSection';
import MoreInfoSection from './MoreInfoSection/MoreInfoSection';
import { AdditionalInfoStepValidationSchema } from './ValidationSchema/ValidationSchema';

import stepsMessages from 'common/components/Steps/AdditionalInfoStep/messages';
import personProfileMessages from 'common/components/PersonProfile/messages';
import { INamedEntityImt } from 'common/interfaces/common';
import { selectDictionaryList } from 'common/state/dictionary/selectors';
import { snackbar } from 'common/utils/snackbarUtils';
import commonMessages from 'common/messages/messages';
import { selectProfileRequiredFields } from 'common/state/settings/selectors';
import { useRecommendedFields } from 'common/hooks/useRecommendedFields';
import { showRecommendedFieldsModal } from 'common/state/modals/actions';
import { selectIsSubmittedRecommendedFieldsModal } from 'common/state/modals/selectors';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1, 2),
    flex: 1,
    height: '100%',
    minHeight: 0,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
}));

interface IProps {
  personId?: string;
  handleOnSubmit?: (data?) => void;
  isResponseSuccess?: boolean;
  isLoading: boolean;
  additionalInfo: IAdditionalInfoImt;
  module: PeakModuleForNewPersonType;
}

const initialValues = {
  insuranceDto: {
    insuranceCompany: null,
    insuranceMemberId: '',
  },
  allergies: [],
  additionalFields: [],
  emergencyContactDto: {
    emergencyAddress1: '',
    emergencyAddress2: '',
    emergencyName: '',
    emergencyPhone: '',
  },
};

const AdditionalInfoStep = ({
  personId,
  handleOnSubmit,
  isResponseSuccess,
  isLoading,
  additionalInfo,
  module,
}: IProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const additionalFieldsList: ImmutableList<INamedEntityImt> = useSelector(
    selectDictionaryList(DictionaryList.ADDITIONAL_FIELDS),
  );
  const profileRequiredFields = useSelector(selectProfileRequiredFields);
  const isSubmitted = useSelector(selectIsSubmittedRecommendedFieldsModal);

  const { checkRecommendedFieldsAreNotEmpty, profileRecommendedFields } = useRecommendedFields();

  const { renderFooter, onNext } = useContext(StepContext);

  const classes = useStyles();

  const formMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(AdditionalInfoStepValidationSchema(profileRequiredFields)),
    mode: 'onTouched',
  });

  const { handleSubmit, getValues, reset } = formMethods;

  useEffect(() => {
    if (isSubmitted) {
      const values = getValues();

      handleOnSubmit(values);
      dispatch(showRecommendedFieldsModal(false));
    }
  }, [isSubmitted, dispatch, getValues, handleOnSubmit]);

  useEffect(() => {
    if (isResponseSuccess) {
      onNext({ additionalInfo: getValues() });
    }
  }, [isResponseSuccess, onNext, getValues]);

  useEffect(() => {
    if (personId) {
      dispatch(fetchAdditionalInfo(personId, module));
    }
  }, [dispatch, personId, module]);

  useEffect(() => {
    if (additionalInfo?.size) {
      let additionalDictionaryFields = [];

      if (additionalFieldsList?.size) {
        const additionalFields = additionalInfo.get('additionalFields');

        additionalDictionaryFields = additionalFieldsList.toJS().map(item => {
          const additionalField = additionalFields?.find(
            field => field.getIn(['field', 'id']) === item.id,
          );

          if (additionalField) {
            return { id: item.id, value: additionalField.get('value') };
          }

          return { id: item.id, value: '' };
        });
      }

      reset({
        ...(additionalInfo.get('insuranceDto') ? additionalInfo.toJS() : initialValues),
        additionalFields: additionalDictionaryFields,
      });
    }
  }, [additionalInfo, additionalFieldsList, dispatch, reset]);

  useEffect(() => {
    dispatch(fetchDictionaryList(DictionaryList.INSURANCE_COMPANY, { module }));
    dispatch(fetchDictionaryList(DictionaryList.ALLERGIES));
    dispatch(fetchDictionaryList(DictionaryList.ADDITIONAL_FIELDS, { module }));
  }, [dispatch, module]);

  useEffect(() => {
    return () => {
      dispatch(updateAdditionalInfo(null));
      dispatch(resetDictionaryListAction({ dictionary: DictionaryList.INSURANCE_COMPANY }));
    };
  }, [dispatch]);

  const onSubmit = values => {
    const hasEmptyRecommendedFields = checkRecommendedFieldsAreNotEmpty(values);
    if (hasEmptyRecommendedFields) {
      dispatch(showRecommendedFieldsModal(true));
    } else {
      handleOnSubmit(values);
    }
  };

  const onHandleBack = () => {
    snackbar.warning(<FormattedMessage {...commonMessages.notImplementedYet} />);
    // onBack({ additionalInfo: getValues() });
  };

  return (
    <FormProvider {...formMethods}>
      <ScrollBox hasShadowsOnScroll>
        <form
          id="additional-info-form"
          className={classes.root}
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="none"
        >
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <InsuranceSection
                name="insuranceDto"
                title={<FormattedMessage {...stepsMessages.newMemberAdditionalInfoInsurance} />}
                recommendedFields={profileRecommendedFields}
              />
            </Grid>

            <Grid item xs={12}>
              <EmergencySection
                name="emergencyContactDto"
                title={<FormattedMessage {...stepsMessages.newMemberAdditionalInfoEmergency} />}
                recommendedFields={profileRecommendedFields}
              />
            </Grid>

            <Grid item xs={12}>
              <AllergiesSection
                title={<FormattedMessage {...stepsMessages.newMemberAdditionalInfoAllergies} />}
                recommendedFields={profileRecommendedFields}
              />
            </Grid>

            <Grid item xs={12}>
              <MoreInfoSection
                title={<FormattedMessage {...personProfileMessages.moreInfo} />}
                additionalFields={additionalFieldsList}
              />
            </Grid>
          </Grid>
        </form>
      </ScrollBox>

      {!!renderFooter && renderFooter(onHandleBack, handleSubmit(onSubmit), isLoading)}
    </FormProvider>
  );
};

export default React.memo(AdditionalInfoStep);
