import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Chip, makeStyles } from '@material-ui/core';

import { CustomTheme } from 'common/ui/interfaces';
import commonMessages from 'common/messages/messages';
import { INamedEntity } from 'common/interfaces/common';
import { Button } from 'common/components/index';

interface IChipsProps {
  chips: INamedEntity[];
  chipsLimit?: number;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  chip: {
    height: theme.spacing(2.5),
    fontSize: theme.spacing(1.5),
    fontWeight: 700,
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    background: theme.palette.text.secondary,
    color: theme.palette.primary.contrastText,
    borderRadius: theme.spacing(1.25),
  },
  showMoreButton: {
    borderRadius: theme.spacing(1.25),
    borderColor: theme.palette.borderColor.main,
    fontSize: theme.spacing(1.5),
    color: theme.palette.secondary.dark,
    textTransform: 'none',
    height: theme.spacing(2.5),
    '&:hover': {
      background: theme.palette.darkBackground.light,
      color: theme.palette.primary.contrastText,
    },
  },
}));
const Chips = (props: IChipsProps): JSX.Element => {
  const { chips, chipsLimit = 5 } = props;

  const [currentChips, setCurrentChips] = useState(() => {
    if (chips.length > chipsLimit) {
      return chips.slice(0, chipsLimit);
    }

    return chips;
  });

  const classes = useStyles();

  const elements = currentChips.map(item => {
    return <Chip key={item.id} className={classes.chip} label={item.title} />;
  });

  useEffect(() => {
    if (chips && chips.length > chipsLimit) {
      setCurrentChips(chips.slice(0, chipsLimit));
    } else {
      setCurrentChips(chips);
    }
  }, [chips, setCurrentChips, chipsLimit]);

  const showMore = () => {
    setCurrentChips(prevState => {
      return [...prevState, ...chips.slice(prevState.length, prevState.length + chipsLimit)];
    });
  };

  return (
    <Box display="flex" flexWrap="wrap">
      {elements}
      {chips.length > chipsLimit && chips.length !== currentChips.length && (
        <Button
          type="button"
          onClick={showMore}
          size="small"
          variant="outlined"
          className={classes.showMoreButton}
        >
          <FormattedMessage {...commonMessages.showMoreBtn} /> +
        </Button>
      )}
    </Box>
  );
};

export default Chips;
