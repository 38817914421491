import { createAction } from '@reduxjs/toolkit';

import { IFiltersWithSearch, ITableParams } from 'common/interfaces/table';

import Services from 'services/network';
import { actionTypes } from '../../constants/invoiceHistory';
import {
  IAdjustInvoiceData,
  IInventoryDetailedInfo,
  IInvoiceItem,
  IPaginatedInvoiceHistory,
  ITransactionItem,
} from '../../interfaces/invoiceHistory';
import { enqueueErrorNotification } from 'common/state/notifications/actions';
import { GeneralThunkAction } from 'common/state/interfaces';
import { ActionResult } from 'common/constants';
import { IPrimaryMemberInfo } from 'common/components/PersonProfile/interfaces';
import { IBundleView } from 'modules/services/interfaces/packages';
import { IPaginatedData } from 'common/interfaces/pagination';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import { PeakModules } from 'common/constants/peakModules';
import { printHTML } from 'common/state/printHTML/actions';

const fetchInvoiceHistoryLoading = createAction<boolean>(actionTypes.FETCH_INVOICE_HISTORY_LOADING);
const fetchInvoiceHistoryAction = createAction<IPaginatedInvoiceHistory>(
  actionTypes.FETCH_INVOICE_HISTORY,
);

export const fetchInvoiceHistoryList = (requestOptions?: ITableParams): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchInvoiceHistoryLoading(true));

    try {
      const paginatedData = await Services.Reports.getInvoiceHistory(requestOptions);
      dispatch(fetchInvoiceHistoryAction(paginatedData));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchInvoiceHistoryLoading(false));
    }
  };
};

const fetchInvoiceByIdLoading = createAction<boolean>(actionTypes.FETCH_INVOICE_LOADING);
const fetchInvoiceByIdAction = createAction<IInvoiceItem>(actionTypes.FETCH_INVOICE_ITEM);
const resetInvoiceAction = createAction<boolean>(actionTypes.RESET_INVOICE_ITEM);

export const resetInvoice = (): GeneralThunkAction => {
  return async dispatch => {
    dispatch(resetInvoiceAction());
  };
};
export const fetchInvoiceById = (invoiceId: string): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchInvoiceByIdLoading(true));

    try {
      const invoice = await Services.Reports.getInvoiceById(invoiceId);
      dispatch(fetchInvoiceByIdAction(invoice));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchInvoiceByIdLoading(false));
    }
  };
};

const fetchInvoiceInventoryInfoLoading = createAction<boolean>(
  actionTypes.FETCH_INVOICE_INVENTORY_DETAILED_INFO_LOADING,
);
const fetchInvoiceInventoryInfoAction = createAction<IInventoryDetailedInfo>(
  actionTypes.FETCH_INVOICE_INVENTORY_DETAILED_INFO,
);
export const resetInvoiceInventoryInfoAction = createAction<boolean>(
  actionTypes.RESET_INVOICE_INVENTORY_DETAILED_INFO,
);

export const fetchInvoiceInventoryInfo = (
  invoiceId: string,
  inventoryId: string,
  isPackageInventory?: boolean,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchInvoiceInventoryInfoLoading(true));

    try {
      let inventoryInfo;

      if (isPackageInventory) {
        inventoryInfo = await Services.Reports.getInvoicePackageInventoryInfo(
          invoiceId,
          inventoryId,
        );
      } else {
        inventoryInfo = await Services.Reports.getInvoiceInventoryInfo(invoiceId, inventoryId);
      }

      dispatch(fetchInvoiceInventoryInfoAction(inventoryInfo));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchInvoiceInventoryInfoLoading(false));
    }
  };
};

const fetchInvoicePackageInfoLoading = createAction<boolean>(
  actionTypes.FETCH_INVOICE_PACKAGE_DETAILED_INFO_LOADING,
);
const fetchInvoicePackageInfoAction = createAction<IBundleView>(
  actionTypes.FETCH_INVOICE_PACKAGE_DETAILED_INFO,
);
export const resetInvoicePackageInfoAction = createAction<boolean>(
  actionTypes.RESET_INVOICE_PACKAGE_DETAILED_INFO,
);

export const fetchInvoicePackageInfo = (
  invoiceId: string,
  packageId: string,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchInvoicePackageInfoLoading(true));

    try {
      const packageInfo = await Services.Reports.getInvoicePackageInfo(invoiceId, packageId);

      dispatch(fetchInvoicePackageInfoAction(packageInfo));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchInvoicePackageInfoLoading(false));
    }
  };
};

const fetchInvoiceTransactionsLoadingAction = createAction<boolean>(
  actionTypes.FETCH_INVOICE_TRANSACTIONS_DETAILED_INFO_LOADING,
);
const fetchInvoiceTransactionsAction = createAction<IPaginatedData<ITransactionItem>>(
  actionTypes.FETCH_INVOICE_TRANSACTIONS_DETAILED_INFO,
);
export const resetInvoiceTransactionsAction = createAction<boolean>(
  actionTypes.RESET_INVOICE_TRANSACTIONS_DETAILED_INFO,
);

export const fetchInvoiceTransactions = (invoiceId: string): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchInvoiceTransactionsLoadingAction(true));

    try {
      const transactions = await Services.Reports.getInvoiceTransactions(invoiceId);

      dispatch(fetchInvoiceTransactionsAction(transactions));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchInvoiceTransactionsLoadingAction(false));
    }
  };
};

const voidInvoiceLoadingAction = createAction<boolean>(
  actionTypes.VOID_INVOICE_ITEM_ACTION_LOADING,
);
const voidInvoiceAction = createAction<ActionResult>(actionTypes.VOID_INVOICE_ITEM_ACTION_RESULT);
export const resetVoidInvoiceActionResult = createAction(
  actionTypes.RESET_VOID_INVOICE_ITEM_ACTION_RESULT,
);

export const voidInvoice = (invoiceId: string, reason: string): GeneralThunkAction => {
  return async dispatch => {
    dispatch(voidInvoiceLoadingAction(true));

    try {
      await Services.Reports.voidInvoice(invoiceId, reason);

      dispatch(voidInvoiceAction(ActionResult.SUCCESS_ACTION));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(voidInvoiceLoadingAction(false));
    }
  };
};

const assignInvoiceLoding = createAction<boolean>(actionTypes.ASSIGN_INVOICE_LOADING);
const assignInvoiceAction = createAction<any>(actionTypes.ASSIGN_INVOICE);
export const resetAssignSuccess = createAction(actionTypes.RESET_ASSIGN_INVOICE_SUCCESS);

export const assignCustomerToInvoice = (
  invoiceId: string,
  memberId: string,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(assignInvoiceLoding(true));

    try {
      await Services.Reports.assignCustomerToInvoice(invoiceId, memberId);
      dispatch(assignInvoiceAction(ActionResult.SUCCESS_ACTION));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(assignInvoiceLoding(false));
    }
  };
};

const exportInvoiceLoding = createAction<boolean>(actionTypes.EXPORT_INVOICE_HISTORY_LOADING);
const exportInvoiceAction = createAction<any>(actionTypes.EXPORT_INVOICE_HISTORY);
export const resetExportSuccess = createAction(actionTypes.RESET_EXPORT_INVOICE_HISTORY_SUCCESS);

export const exportInvoice = (
  data: Record<string, any>,
  tableParams: ITableParams,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(exportInvoiceLoding(true));

    try {
      const response = await Services.Reports.exportInvoice(data, tableParams);
      dispatch(exportInvoiceAction(response));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(exportInvoiceLoding(false));
    }
  };
};

const adjustInvoiceLoding = createAction<boolean>(actionTypes.ADJUST_INVOICE_LOADING);
const adjustInvoiceAction = createAction<any>(actionTypes.ADJUST_INVOICE);
export const resetAdjustInvoiceSuccess = createAction(actionTypes.RESET_ADJUST_INVOICE_SUCCESS);

export const adjustInvoice = (invoiceId: string, data: IAdjustInvoiceData): GeneralThunkAction => {
  return async dispatch => {
    dispatch(adjustInvoiceLoding(true));
    try {
      const response = await Services.Reports.adjustInvoice(invoiceId, data);
      dispatch(adjustInvoiceAction(response));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(adjustInvoiceLoding(false));
    }
  };
};

const invoiceHistorySnapshotsLoading = createAction<boolean>(
  actionTypes.INVOICE_HISTORY_SNAPSHOTS_LOADING,
);
const invoiceHistorySnapshotsAction = createAction<any>(
  actionTypes.INVOICE_HISTORY_SNAPSHOTS_ACTION,
);
export const resetInvoiceHistorySnapshotsSuccess = createAction(
  actionTypes.RESET_INVOICE_HISTORY_SNAPSHOTS_SUCCESS,
);

export const getInvoiceReportSnapshots = (tableParams: ITableParams): GeneralThunkAction => {
  return async dispatch => {
    dispatch(invoiceHistorySnapshotsLoading(true));
    try {
      const response = await Services.Reports.getInvoiceReportSnapshots(tableParams);
      dispatch(invoiceHistorySnapshotsAction(response));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(invoiceHistorySnapshotsLoading(false));
    }
  };
};

const invoiceStatisticsLoading = createAction<boolean>(actionTypes.INVOICE_STATISTICS_LOADING);
const invoiceStatisticsAction = createAction<any>(actionTypes.INVOICE_STATISTICS_ACTION);
export const resetInvoiceStatisticsSuccess = createAction(
  actionTypes.RESET_INVOICE_STATISTICS_SUCCESS,
);

export const getInvoiceHistoryReportStatistics = (
  requestOptions?: IFiltersWithSearch,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(invoiceStatisticsLoading(true));
    try {
      const response = await Services.Reports.getInvoiceHistoryReportStatistics(requestOptions);
      dispatch(invoiceStatisticsAction(response));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(invoiceStatisticsLoading(false));
    }
  };
};

const createMemberSuccess = createAction<IPrimaryMemberInfo>(actionTypes.CREATE_MEMBER_SUCCESS);
const createMemberLoading = createAction<boolean>(actionTypes.CREATE_MEMBER_LOADING);
export const resetCreateMember = createAction(actionTypes.RESET_CREATE_MEMBER);

export const createMember = (data: IPrimaryMemberInfo): GeneralThunkAction => {
  return async dispatch => {
    try {
      dispatch(createMemberLoading(true));

      const response = await Services.Reports.createMember(data);
      dispatch(createMemberSuccess(response));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(createMemberLoading(false));
    }
  };
};

const fetchInvoiceHTMLLoading = createAction<boolean>(actionTypes.FETCH_INVOICE_HTML_LOADING);

export const printInvoice = (invoiceId: string): GeneralThunkAction => async dispatch => {
  try {
    dispatch(fetchInvoiceHTMLLoading(true));

    const result = await Services.Reports.getInvoiceToPrint(invoiceId);

    dispatch(printHTML(result));
  } catch (e) {
    dispatch(enqueueErrorNotification(e));
  } finally {
    dispatch(fetchInvoiceHTMLLoading(false));
  }
};
