import { createAction } from '@reduxjs/toolkit';

import { DictionaryItem, DictionaryList } from 'common/constants/dictionaryConstants';
import Services from 'services/network';
import {
  FETCH_DICTIONARY_ITEM_LOADING,
  FETCH_DICTIONARY_ITEM_SUCCESS,
  FETCH_DICTIONARY_LIST_LOADING,
  FETCH_DICTIONARY_LIST_SUCCESS,
  RESET_DICTIONARY_ITEM,
  RESET_DICTIONARY_LIST,
} from 'common/constants/actionTypes';

import { IDictionaryOptions } from 'common/interfaces/dictionary';
import { GeneralThunkAction } from 'common/state/interfaces';
import {
  FetchDictionaryItemLoadingPayload,
  FetchDictionaryItemSuccessPayload,
  FetchDictionaryListLoadingPayload,
  FetchDictionaryListSuccessPayload,
  ResetDictionaryItemPayload,
  ResetDictionaryListPayload,
} from 'common/state/dictionary/reducers';

import { enqueueErrorNotification } from 'common/state/notifications/actions';

const fetchDictionaryListLoadingAction = createAction<FetchDictionaryListLoadingPayload>(
  FETCH_DICTIONARY_LIST_LOADING,
);

const fetchDictionaryListSuccessAction = createAction<FetchDictionaryListSuccessPayload>(
  FETCH_DICTIONARY_LIST_SUCCESS,
);

export const resetDictionaryListAction = createAction<ResetDictionaryListPayload>(
  RESET_DICTIONARY_LIST,
);

export const fetchDictionaryList = (
  dictionary: DictionaryList,
  options?: IDictionaryOptions,
): GeneralThunkAction => {
  return async dispatch => {
    const { quietLoading } = options || {};

    try {
      if (!quietLoading) {
        dispatch(fetchDictionaryListLoadingAction({ dictionary, isLoading: true }));
      }

      const fetchedList = await Services.DictionaryLists.fetchDictionaryByName(dictionary, options);

      const isArray = Array.isArray(fetchedList);
      const list = isArray ? fetchedList : fetchedList.data || [];
      const meta = isArray ? null : fetchedList.meta;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(fetchDictionaryListSuccessAction({ dictionary, list, meta }));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      if (!quietLoading) {
        dispatch(fetchDictionaryListLoadingAction({ dictionary, isLoading: false }));
      }
    }
  };
};

export const updateDictionaryList = (
  payload: FetchDictionaryListSuccessPayload,
): GeneralThunkAction => {
  return dispatch => {
    dispatch(fetchDictionaryListSuccessAction(payload));
  };
};

const fetchDictionaryItemLoadingAction = createAction<FetchDictionaryItemLoadingPayload>(
  FETCH_DICTIONARY_ITEM_LOADING,
);

const fetchDictionaryItemSuccessAction = createAction<FetchDictionaryItemSuccessPayload>(
  FETCH_DICTIONARY_ITEM_SUCCESS,
);

export const resetDictionaryItemAction = createAction<ResetDictionaryItemPayload>(
  RESET_DICTIONARY_ITEM,
);

export const fetchDictionaryItem = (
  dictionary: DictionaryItem,
  param?: any,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchDictionaryItemLoadingAction({ dictionary, isLoading: true }));

    try {
      const response = await Services.DictionaryItems.fetchDictionaryItemByName(dictionary, param);

      dispatch(fetchDictionaryItemSuccessAction({ dictionary, item: response }));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchDictionaryItemLoadingAction({ dictionary, isLoading: false }));
    }
  };
};
