import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import {
  Box,
  FormControlLabel,
  IconButton,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { getAccessByPropPath } from 'common/utils/errorObject';

import { FullWeekdays, Weekday } from 'common/interfaces/common';

import { ReactComponent as DeleteIcon } from 'img/icons/trash_deprecated.svg';
import { Checkbox } from 'common/components/index';
import TimeAvailabilitySelector from './TimeAvailabilitySelector';

import inputLabels from 'common/messages/inputLabels';

const useStyles = makeStyles((theme: Theme) => ({
  checkboxLabel: {
    '& .MuiFormControlLabel-label': {
      marginLeft: theme.spacing(1),
    },
  },
  disabledColor: {
    '& .MuiInputBase-input.Mui-disabled': {
      opacity: 0.4,
    },
  },
  deleteButton: {
    marginLeft: theme.spacing(1),
  },
  addIcon: {
    width: 16,
    height: 16,
  },
}));

const options = Object.values(Weekday).map(weekdayItem => ({
  weekday: weekdayItem,
  allDay: false,
  timeRanges: [{ startTime: null, endTime: null }],
  editableRangeIndex: null,
}));

interface IProps {
  weekdaysFieldName?: string;
  rangesFieldName?: string;
}

const DayTimeAvailabilitySection = ({
  rangesFieldName,
  weekdaysFieldName,
}: IProps): JSX.Element => {
  const { control, errors, setValue, getValues } = useFormContext();

  const renderIntlMessage = useRenderIntlMessage();

  const classes = useStyles();

  const fieldName = rangesFieldName || 'ranges';
  const weekdaysName = weekdaysFieldName ? `${weekdaysFieldName}.weekdays` : 'weekdays';

  const handleRemoveWeekday = (weekday: Weekday) => {
    const weekdays = getValues(weekdaysName);

    const restWeekdays = weekdays.filter(weekdayItem => weekdayItem.weekday !== weekday);
    setValue(weekdaysName, restWeekdays);
  };

  const { fields: ranges, append, remove } = useFieldArray({ control, name: fieldName });

  return (
    <Box display="flex" flexDirection="column" gridGap={16} overflow="hidden">
      <Controller
        name={weekdaysName}
        control={control}
        render={({ value, onChange, onBlur }) => (
          <Autocomplete
            value={value}
            onBlur={onBlur}
            multiple
            onChange={(_, values, action, details) => {
              onChange(values);

              if (action === 'select-option') {
                append(details.option);
              }

              if (action === 'remove-option') {
                const index = ranges.findIndex(
                  rangeItem => rangeItem.weekday === details.option.weekday,
                );

                remove(index);
              }

              if (action === 'clear') {
                setValue(fieldName, []);
              }
            }}
            getOptionSelected={(option, selectedOption) =>
              option?.weekday === selectedOption?.weekday
            }
            getOptionLabel={option => renderIntlMessage(FullWeekdays.message(option.weekday)) || ''}
            options={options}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                error={!!getAccessByPropPath(errors, weekdaysName)}
                helperText={renderIntlMessage(getAccessByPropPath(errors, weekdaysName)?.message)}
                label={<FormattedMessage {...inputLabels.dayOfTheWeek} />}
              />
            )}
          />
        )}
      />

      {ranges.map((rangeItem, index) => {
        const isAllDay = getValues(`${fieldName}.${index}.allDay`);

        return (
          <Box key={rangeItem.id}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="button" color="textSecondary">
                {renderIntlMessage(rangeItem.weekday)}
              </Typography>

              <Box display="flex" alignItems="center">
                <Controller
                  control={control}
                  name={`${fieldName}.${index}.allDay`}
                  defaultValue={rangeItem.allDay}
                  render={({ onChange, onBlur, value }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={value}
                          onChange={async e => {
                            const isChecked = e.target.checked;

                            onChange(isChecked);

                            if (isChecked) {
                              setValue(`${fieldName}.${index}.editableRangeIndex`, null);
                            }

                            setValue(
                              `${fieldName}.${index}.timeRanges`,
                              isChecked ? [] : [{ startTime: null, endTime: null }],
                            );
                          }}
                          onBlur={onBlur}
                        />
                      }
                      label={<FormattedMessage {...inputLabels.allDay} />}
                      className={classes.checkboxLabel}
                    />
                  )}
                />

                <IconButton
                  color="primary"
                  size="small"
                  onClick={() => {
                    remove(index);
                    handleRemoveWeekday(rangeItem.weekday);
                  }}
                  className={classes.deleteButton}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Box>

            {!isAllDay && <TimeAvailabilitySelector name={`${fieldName}.${index}`} />}
          </Box>
        );
      })}
    </Box>
  );
};

export default DayTimeAvailabilitySection;
