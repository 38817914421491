import React, { useMemo } from 'react';
import { Box, IconButton, makeStyles, Typography } from '@material-ui/core';

import { formatNumberToPrice } from 'common/utils/format';
import { getPriceAmountString } from 'common/utils';

import { CartUnitTypes, IInvoiceDetailsImt } from 'common/interfaces/invoices';
import { CustomTheme } from 'common/ui/interfaces';

import InvoiceServiceItem from 'common/components/InvoiceOperating/InvoiceServiceItem/InvoiceServiceItem';
import InvoiceTotals from 'common/components/InvoiceOperating/InvoiceTotals/InvoiceTotals';
import InvoiceTopUpBalanceItem from 'common/components/InvoiceOperating/InvoiceTopUpBalanceItem/InvoiceTopUpBalanceItem';
import InvoicePastDueItem from 'common/components/InvoiceOperating/InvoicePastDueItem/InvoicePastDueItem';
import InvoiceBillingItem from 'common/components/InvoiceOperating/InvoiceBillingItem/InvoiceBillingItem';
import { ReactComponent as InfoIcon } from 'img/icons/info.svg';
import InvoiceGiftCardItem from '../../InvoiceGiftCardItem/InvoiceGiftCardItem';

const useStyles = makeStyles((theme: CustomTheme) => ({
  itemsContainer: {
    borderTop: `1px solid ${theme.palette.borderColor.main}`,
  },
  productItem: {
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.borderColor.main}`,
    },
  },
  topUpBalanceItem: {
    borderBottom: `1px solid ${theme.palette.borderColor.main}`,
  },
  productTitle: {
    margin: theme.spacing(2, 0),
  },
  itemPrice: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
  },
  priceHint: {
    display: 'inline-block',
    marginTop: theme.spacing(0.5),
    color: theme.palette.secondary.dark,
  },
  infoIcon: {
    width: '1rem',
    height: '1rem',
  },
}));

interface IProps {
  checkOutData: IInvoiceDetailsImt;
  onEditTotal?: () => void;
  onViewInventoryInfo?: (inventoryId: string) => void;
  onViewPackageInfo?: (packageId: string) => void;
}

const CheckOutResponsePaymentInfo = ({
  checkOutData,
  onEditTotal,
  onViewInventoryInfo,
  onViewPackageInfo,
}: IProps): JSX.Element => {
  const transformedCheckOutData = useMemo(() => checkOutData.toJS(), [checkOutData]);

  const {
    invoicePaymentSplits,
    totalAmount,
    subtotalAmount,
    discount,
    taxAmount,
    invoiceUnits,
  } = transformedCheckOutData;

  const classes = useStyles();

  return (
    <Box className={classes.itemsContainer}>
      {invoiceUnits?.map(invoiceUnit => {
        const { id, type } = invoiceUnit;

        switch (type) {
          case CartUnitTypes.INVENTORY: {
            const { inventory } = invoiceUnit;
            const taxedPrice = inventory.price + inventory.taxAmount;

            return (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                className={classes.productItem}
                key={id}
              >
                <Box display="flex" alignItems="center" gridGap={4}>
                  <Typography variant="h5" component="p" className={classes.productTitle}>
                    {`${inventory.number} x ${inventory.title}`}
                  </Typography>

                  {!!onViewInventoryInfo && (
                    <IconButton
                      size="small"
                      type="button"
                      color="primary"
                      onClick={() => onViewInventoryInfo(inventory.id)}
                    >
                      <InfoIcon className={classes.infoIcon} />
                    </IconButton>
                  )}
                </Box>

                <Typography className={classes.itemPrice}>
                  <Typography component="span" variant="h5">
                    {`$${formatNumberToPrice(getPriceAmountString(taxedPrice, inventory.number))}`}
                  </Typography>

                  {inventory.number > 1 && (
                    <Typography component="span" variant="h5" className={classes.priceHint}>
                      {`${inventory.number} x $${formatNumberToPrice(taxedPrice)}`}
                    </Typography>
                  )}
                </Typography>
              </Box>
            );
          }
          case CartUnitTypes.CANCEL_MEMBERSHIP:
          case CartUnitTypes.CANCEL_NON_MEMBERSHIP: {
            const { bundle, cancelPastDues, cancelBillingSchedules } = invoiceUnit;

            return (
              <InvoiceServiceItem
                key={bundle.id}
                invoicePackage={bundle}
                pastDues={cancelPastDues}
                billingSchedules={cancelBillingSchedules}
                onViewDetailedPackageInfo={onViewPackageInfo}
              />
            );
          }
          case CartUnitTypes.BILLING_SCHEDULE: {
            const { billingSchedule } = invoiceUnit;

            return <InvoiceBillingItem billing={billingSchedule} key={id} />;
          }
          case CartUnitTypes.PAST_DUE: {
            const { pastDue, pastDueResolve } = invoiceUnit;

            return (
              <InvoicePastDueItem pastDue={pastDue} pastDueResolve={pastDueResolve} key={id} />
            );
          }
          case CartUnitTypes.TOP_UP_BALANCE: {
            const { topUpBalance } = invoiceUnit;

            return (
              <Box className={classes.topUpBalanceItem}>
                <InvoiceTopUpBalanceItem disabled topUpBalance={topUpBalance} />
              </Box>
            );
          }
          case CartUnitTypes.GIFT_CARD: {
            const { giftCard } = invoiceUnit;

            return <InvoiceGiftCardItem giftCard={giftCard} disabled />;
          }
          case CartUnitTypes.MEMBERSHIP_BUNDLE:
          case CartUnitTypes.SERVICE_BUNDLE: {
            const { bundle } = invoiceUnit;

            return (
              <InvoiceServiceItem
                key={id}
                invoicePackage={bundle}
                onViewDetailedPackageInfo={onViewPackageInfo}
              />
            );
          }
          case CartUnitTypes.REACTIVATE_MEMBERSHIP:
          case CartUnitTypes.REACTIVATE_NON_MEMBERSHIP: {
            const { bundle, reactivatePastDues } = invoiceUnit;

            return (
              <InvoiceServiceItem
                key={id}
                invoicePackage={bundle}
                pastDues={reactivatePastDues}
                onViewDetailedPackageInfo={onViewPackageInfo}
              />
            );
          }
          default:
            return null;
        }
      })}

      <Box marginY={2}>
        <InvoiceTotals
          payments={invoicePaymentSplits}
          total={totalAmount}
          subtotal={subtotalAmount}
          tax={taxAmount}
          discount={discount}
          isCheckOutResponse
          onEditTotal={onEditTotal}
        />
      </Box>
    </Box>
  );
};

export default CheckOutResponsePaymentInfo;
