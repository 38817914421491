import { defineMessages } from 'react-intl';

export default defineMessages({
  name: {
    id: 'app.common.tableHeaders.name',
    defaultMessage: 'name',
  },
  register: {
    id: 'app.common.tableHeaders.register',
    defaultMessage: 'register',
  },
  memberNOS: {
    id: 'app.common.tableHeaders.memberNOS',
    defaultMessage: 'member/NOS',
  },
  invoiceNumber: {
    id: 'app.common.tableHeaders.invoiceNumber',
    defaultMessage: 'Invoice number',
  },
  overtime: {
    id: 'app.common.tableHeaders.overtime',
    defaultMessage: 'Overtime',
  },
  initialClockIn: {
    id: 'app.common.tableHeaders.initialClockIn',
    defaultMessage: 'Initial Clock In',
  },
  finalClockOut: {
    id: 'app.common.tableHeaders.finalClockOut',
    defaultMessage: 'Final Clock Out',
  },
  duration: {
    id: 'app.common.tableHeaders.duration',
    defaultMessage: 'Duration',
  },
  dayOvertime: {
    id: 'app.common.tableHeaders.dayOvertime',
    defaultMessage: 'Day Overtime',
  },
  events: {
    id: 'app.common.tableHeaders.events',
    defaultMessage: 'Events',
  },
  customer: {
    id: 'app.common.tableHeaders.customer',
    defaultMessage: 'Customer',
  },
  servicedBy: {
    id: 'app.common.tableHeaders.servicedBy',
    defaultMessage: 'Serviced By',
  },
  redeemedBy: {
    id: 'app.reports.redeemedBy',
    defaultMessage: 'Redeemed By',
  },
  inventories: {
    id: 'app.reports.inventories',
    defaultMessage: 'Inventory',
  },
  total: {
    id: 'app.common.tableHeaders.total',
    defaultMessage: 'total',
  },
  subtotal: {
    id: 'app.common.tableHeaders.subtotal',
    defaultMessage: 'subtotal',
  },
  discount: {
    id: 'app.common.tableHeaders.discount',
    defaultMessage: 'discount',
  },
  author: {
    id: 'app.common.tableHeaders.author',
    defaultMessage: 'author',
  },
  member: {
    id: 'app.common.tableHeaders.member',
    defaultMessage: 'Member',
  },
  redeemed: {
    id: 'app.common.tableHeaders.redeemed',
    defaultMessage: 'Redeemed',
  },
  period: {
    id: 'app.common.tableHeaders.period',
    defaultMessage: 'Period',
  },
  status: {
    id: 'app.common.tableHeaders.status',
    defaultMessage: 'status',
  },
  campaignStatus: {
    id: 'app.common.tableHeaders.campaignStatus',
    defaultMessage: 'campaign status',
  },
  crmStatus: {
    id: 'app.common.tableHeaders.crmStatus',
    defaultMessage: 'crm status',
  },
  actions: {
    id: 'app.common.tableHeaders.actions',
    defaultMessage: 'actions',
  },
  remaining: {
    id: 'app.common.tableHeaders.remaining',
    defaultMessage: 'remaining',
  },
  balance: {
    id: 'app.common.tableHeaders.balance',
    defaultMessage: 'balance',
  },
  maxInvoice: {
    id: 'app.common.tableHeaders.maxInvoice',
    defaultMessage: 'max invoice %',
  },
  barcode: {
    id: 'app.common.tableHeaders.barcode',
    defaultMessage: 'barcode',
  },
  amount: {
    id: 'app.common.tableHeaders.amount',
    defaultMessage: 'amount',
  },
  created: {
    id: 'app.common.tableHeaders.created',
    defaultMessage: 'created',
  },
  employee: {
    id: 'app.common.tableHeaders.employee',
    defaultMessage: 'employee',
  },
  clubs: {
    id: 'app.common.tableHeaders.clubs',
    defaultMessage: 'clubs',
  },
  club: {
    id: 'app.common.tableHeaders.club',
    defaultMessage: 'club',
  },
  potentialClub: {
    id: 'app.common.tableHeaders.potentialClub',
    defaultMessage: 'potential club',
  },
  paymentOptions: {
    id: 'app.common.tableHeaders.paymentOptions',
    defaultMessage: 'Payment Options',
  },
  perPayment: {
    id: 'app.common.tableHeaders.perPayment',
    defaultMessage: 'per payment',
  },
  more: {
    id: 'app.common.tableHeaders.more',
    defaultMessage: 'More...',
  },
  homeClub: {
    id: 'app.common.tableHeaders.homeClub',
    defaultMessage: 'home club',
  },
  departments: {
    id: 'app.common.tableHeaders.departments',
    defaultMessage: 'departments',
  },
  payType: {
    id: 'app.common.tableHeaders.payType',
    defaultMessage: 'pay type',
  },
  redeemType: {
    id: 'app.common.tableHeaders.redeemType',
    defaultMessage: 'redeem type',
  },
  condition: {
    id: 'app.common.tableHeaders.condition',
    defaultMessage: 'condition',
  },
  sound: {
    id: 'app.common.tableHeaders.sound',
    defaultMessage: 'sound',
  },
  edit: {
    id: 'app.common.tableHeaders.edit',
    defaultMessage: 'edit',
  },
  bulkEdit: {
    id: 'app.common.tableHeaders.bulkEdit',
    defaultMessage: 'bulk edit',
  },
  packageType: {
    id: 'app.common.tableHeaders.packageType',
    defaultMessage: 'bundle type',
  },
  employees: {
    id: 'app.common.tableHeaders.employees',
    defaultMessage: 'employees',
  },
  services: {
    id: 'app.common.tableHeaders.services',
    defaultMessage: 'services',
  },
  items: {
    id: 'app.common.tableHeaders.items',
    defaultMessage: 'items',
  },
  rate: {
    id: 'app.common.tableHeaders.rate',
    defaultMessage: 'rate',
  },
  taxes: {
    id: 'app.common.tableHeaders.taxes',
    defaultMessage: 'taxes',
  },
  availability: {
    id: 'app.common.tableHeaders.availability',
    defaultMessage: 'availability',
  },
  left: {
    id: 'app.common.tableHeaders.left',
    defaultMessage: 'left',
  },
  delete: {
    id: 'app.common.tableHeaders.delete',
    defaultMessage: 'DELeTE',
  },
  code: {
    id: 'app.common.tableHeaders.code',
    defaultMessage: 'code',
  },
  location: {
    id: 'app.common.tableHeaders.location',
    defaultMessage: 'location',
  },
  cost: {
    id: 'app.common.tableHeaders.cost',
    defaultMessage: 'cost',
  },
  retail: {
    id: 'app.common.tableHeaders.retail',
    defaultMessage: 'retail',
  },
  rCode: {
    id: 'app.common.tableHeaders.rCode',
    defaultMessage: 'r. code',
  },
  withTax: {
    id: 'app.common.tableHeaders.withTax',
    defaultMessage: 'with tax',
  },
  onHandIdeal: {
    id: 'app.common.tableHeaders.onHandIdeal',
    defaultMessage: 'on hand/ideal',
  },
  reorder: {
    id: 'app.common.tableHeaders.reorder',
    defaultMessage: 'REoRDER',
  },
  vendor: {
    id: 'app.common.tableHeaders.vendor',
    defaultMessage: 'vendor',
  },
  catalog: {
    id: 'app.common.tableHeaders.catalog',
    defaultMessage: 'catalog',
  },
  description: {
    id: 'app.common.tableHeaders.description',
    defaultMessage: 'description',
  },
  taxesTaxBundles: {
    id: 'app.common.tableHeaders.taxesTaxBundles',
    defaultMessage: 'taxes & TaX bundles',
  },
  taxesBundlesTotal: {
    id: 'app.common.tableHeaders.taxesBundlesTotal',
    defaultMessage: 'taxes & bundles total',
  },
  totalRate: {
    id: 'app.common.tableHeaders.totalRate',
    defaultMessage: 'Total rate',
  },
  inUse: {
    id: 'app.common.tableHeaders.inUse',
    defaultMessage: 'in use',
  },
  userId: {
    id: 'app.common.tableHeaders.userId',
    defaultMessage: 'user id',
  },
  id: {
    id: 'app.common.tableLabels.id',
    defaultMessage: 'id',
  },
  phone: {
    id: 'app.common.tableHeaders.phone',
    defaultMessage: 'phone',
  },
  phones: {
    id: 'app.common.tableHeaders.phones',
    defaultMessage: 'phones',
  },
  email: {
    id: 'app.common.tableHeaders.email',
    defaultMessage: 'email',
  },
  emails: {
    id: 'app.common.tableHeaders.emails',
    defaultMessage: 'emails',
  },
  accountNumber: {
    id: 'app.common.tableLabels.accountNumber',
    defaultMessage: 'account number',
  },
  website: {
    id: 'app.common.tableLabels.website',
    defaultMessage: 'website',
  },
  label: {
    id: 'app.common.tableHeaders.label',
    defaultMessage: 'label',
  },
  itemService: {
    id: 'app.common.tableHeaders.itemService',
    defaultMessage: 'item/service',
  },
  qty: {
    id: 'app.common.tableHeaders.qty',
    defaultMessage: 'qty',
  },
  perUnit: {
    id: 'app.common.tableHeaders.perUnit',
    defaultMessage: 'per unit',
  },
  units: {
    id: 'app.common.tableHeaders.units',
    defaultMessage: 'Units',
  },
  unit: {
    id: 'app.common.tableHeaders.unit',
    defaultMessage: 'Unit',
  },
  pricePerBilling: {
    id: 'app.common.tableHeaders.pricePerBilling',
    defaultMessage: 'price per billing',
  },
  payPeriod: {
    id: 'app.common.tableHeaders.payPeriod',
    defaultMessage: 'Pay Period',
  },
  startDate: {
    id: 'app.common.tableHeaders.startDate',
    defaultMessage: 'Start Date',
  },
  endDate: {
    id: 'app.common.tableHeaders.endDate',
    defaultMessage: 'End Date',
  },
  payDate: {
    id: 'app.common.tableHeaders.payDate',
    defaultMessage: 'Pay Date',
  },
  campaign: {
    id: 'app.common.tableHeaders.campaign',
    defaultMessage: 'campaign',
  },
  campaigns: {
    id: 'app.common.tableHeaders.campaigns',
    defaultMessage: 'campaigns',
  },
  type: {
    id: 'app.common.tableHeaders.type',
    defaultMessage: 'type',
  },
  membership: {
    id: 'app.common.tableHeaders.membership',
    defaultMessage: 'membership',
  },
  contactInformation: {
    id: 'app.common.tableHeaders.contactInformation',
    defaultMessage: 'contact information',
  },
  lead: {
    id: 'app.common.tableHeaders.lead',
    defaultMessage: 'lead',
  },
  ptLead: {
    id: 'app.common.tableHeaders.ptLead',
    defaultMessage: 'PT lead',
  },
  salesperson: {
    id: 'app.common.tableHeaders.salesperson',
    defaultMessage: 'salesperson',
  },
  date: {
    id: 'app.common.tableHeaders.date',
    defaultMessage: 'date',
  },
  entrance: {
    id: 'app.common.tableHeaders.entrance',
    defaultMessage: 'entrance',
  },
  address: {
    id: 'app.common.tableHeaders.address',
    defaultMessage: 'address',
  },
  contactName: {
    id: 'app.common.tableHeaders.contactName',
    defaultMessage: 'contact name',
  },
  script: {
    id: 'app.common.tableHeaders.script',
    defaultMessage: 'Script',
  },
  entities: {
    id: 'app.common.tableHeaders.entities',
    defaultMessage: 'Entities',
  },
  state: {
    id: 'app.common.tableHeaders.state',
    defaultMessage: 'state',
  },
  level: {
    id: 'app.common.tableHeaders.level',
    defaultMessage: 'Level',
  },
  open: {
    id: 'app.common.tableHeaders.open',
    defaultMessage: 'Open/Closed',
  },
  trigger: {
    id: 'app.common.tableHeaders.trigger',
    defaultMessage: 'trigger',
  },
  module: {
    id: 'app.common.tableHeaders.module',
    defaultMessage: 'module',
  },
  deliveryType: {
    id: 'app.common.tableHeaders.deliveryType',
    defaultMessage: 'delivery type',
  },
  jobPosition: {
    id: 'app.common.tableHeaders.jobPosition',
    defaultMessage: 'Job Position',
  },
  includedInventory: {
    id: 'app.common.tableHeaders.includedInventory',
    defaultMessage: 'Included inventory',
  },
  includedServices: {
    id: 'app.common.tableHeaders.includedServices',
    defaultMessage: 'Included services',
  },
  includedPackages: {
    id: 'app.common.tableHeaders.includedPackages',
    defaultMessage: 'Included bundles',
  },
  daysTimes: {
    id: 'app.common.tableHeaders.daysTimes',
    defaultMessage: 'Days & times',
  },
  size: {
    id: 'app.common.tableHeaders.size',
    defaultMessage: 'Size',
  },
  change: {
    id: 'app.common.tableHeaders.change',
    defaultMessage: 'Change',
  },
  changedBy: {
    id: 'app.common.tableHeaders.changedBy',
    defaultMessage: 'Changed by',
  },
  price: {
    id: 'app.common.tableHeaders.price',
    defaultMessage: 'Price',
  },
  reward: {
    id: 'app.common.tableHeaders.reward',
    defaultMessage: 'Reward',
  },
  documentNumber: {
    id: 'app.common.tableHeaders.documentNumber',
    defaultMessage: 'Document number',
  },
  checkOut: {
    id: 'app.common.tableHeaders.checkOut',
    defaultMessage: 'Check-out',
  },
  pickedUp: {
    id: 'app.common.tableHeaders.pickedUp',
    defaultMessage: 'Picked-up',
  },
  historyOfChanges: {
    id: 'app.common.tableHeaders.historyOfChanges',
    defaultMessage: 'History Of Changes',
  },
  pastValue: {
    id: 'app.common.tableHeaders.pastValue',
    defaultMessage: 'Past Value',
  },
  newValue: {
    id: 'app.common.tableHeaders.newValue',
    defaultMessage: 'New Value',
  },
  dropOff: {
    id: 'app.common.tableHeaders.dropOff',
    defaultMessage: 'Drop Off',
  },
  participants: {
    id: 'app.common.tableHeaders.participants',
    defaultMessage: 'Participants',
  },
  slots: {
    id: 'app.common.tableHeaders.slots',
    defaultMessage: 'Slots',
  },
  attended: {
    id: 'app.common.tableHeaders.attended',
    defaultMessage: 'Attended',
  },
  eventTag: {
    id: 'app.common.tableHeaders.eventTag',
    defaultMessage: 'Event Tag',
  },
  resources: {
    id: 'app.common.tableHeaders.resources',
    defaultMessage: 'Resources',
  },
  registersStatus: {
    id: 'app.common.tableHeaders.registersStatus',
    defaultMessage: 'Registers Status',
  },
  opened: {
    id: 'app.common.tableHeaders.opened',
    defaultMessage: 'Opened',
  },
  openedDate: {
    id: 'app.common.tableHeaders.openedDate',
    defaultMessage: 'Opened Date',
  },
  openedBy: {
    id: 'app.common.tableHeaders.openedBy',
    defaultMessage: 'Opened By',
  },
  opened$$$: {
    id: 'app.common.tableHeaders.opened$$$',
    defaultMessage: 'Opened $$$',
  },
  closed: {
    id: 'app.common.tableHeaders.closed',
    defaultMessage: 'Closed',
  },
  closedDate: {
    id: 'app.common.tableHeaders.closedDate',
    defaultMessage: 'Closed Date',
  },
  closedBy: {
    id: 'app.common.tableHeaders.closedBy',
    defaultMessage: 'Closed By',
  },
  closed$$$: {
    id: 'app.common.tableHeaders.closed$$$',
    defaultMessage: 'Closed $$$',
  },
  employeeOpened: {
    id: 'app.common.tableHeaders.employeeOpened',
    defaultMessage: 'Employee Opened',
  },
  clockIn: {
    id: 'app.common.tableHeaders.clockIn',
    defaultMessage: 'Clock In',
  },
  locked: {
    id: 'app.common.tableHeaders.locked',
    defaultMessage: 'Locked',
  },
  clockOut: {
    id: 'app.common.tableHeaders.clockOut',
    defaultMessage: 'Clock Out',
  },
  startingAmount: {
    id: 'app.common.tableHeaders.startingAmount',
    defaultMessage: 'Starting Amount',
  },
  employeeClosed: {
    id: 'app.common.tableHeaders.employeeClosed',
    defaultMessage: 'Employee Closed ',
  },
  private: {
    id: 'app.common.tableHeaders.private',
    defaultMessage: 'Private',
  },
  paid: {
    id: 'app.common.tableHeaders.paid',
    defaultMessage: 'Paid',
  },
  billOrCoin: {
    id: 'app.common.tableHeaders.billOrCoin',
    defaultMessage: 'Bill / Coin',
  },
  uploadDate: {
    id: 'app.common.tableHeaders.uploadDate',
    defaultMessage: 'Upload Date',
  },
  signedDate: {
    id: 'app.common.tableHeaders.signedDate',
    defaultMessage: 'Signed Date',
  },
  invoiceNumberShort: {
    id: 'app.common.tableHeaders.invoiceNumberShort',
    defaultMessage: 'Invoice №',
  },
  paymentMethod: {
    id: 'app.common.tableHeaders.paymentMethod',
    defaultMessage: 'Payment Method',
  },
  errorResponse: {
    id: 'app.common.tableHeaders.errorResponse',
    defaultMessage: 'Error Response',
  },
  response: {
    id: 'app.common.tableHeaders.response',
    defaultMessage: 'Response',
  },
  from: {
    id: 'app.common.tableHeaders.from',
    defaultMessage: 'from',
  },
  to: {
    id: 'app.common.tableHeaders.to',
    defaultMessage: 'to',
  },
  message: {
    id: 'app.common.tableHeaders.message',
    defaultMessage: 'message',
  },
  eventDescription: {
    id: 'app.common.tableHeaders.eventDescription',
    defaultMessage: 'Event Description',
  },
  publishedBy: {
    id: 'app.common.tableHeaders.publishedBy',
    defaultMessage: 'Published By',
  },
  action: {
    id: 'app.common.tableHeaders.Action',
    defaultMessage: 'Action',
  },
  image: {
    id: 'app.common.tableHeaders.image',
    defaultMessage: 'Image',
  },
  title: {
    id: 'app.common.tableHeaders.title',
    defaultMessage: 'Title',
  },
  body: {
    id: 'app.common.tableHeaders.body',
    defaultMessage: 'Body',
  },
  sentTo: {
    id: 'app.common.tableHeaders.sentTo',
    defaultMessage: 'Sent to',
  },
  willBeRemovedAfter: {
    id: 'app.common.tableHeaders.willBeRemovedAfter',
    defaultMessage: 'Will be removed after',
  },
  published: {
    id: 'app.common.tableHeaders.published',
    defaultMessage: 'Published',
  },
});
