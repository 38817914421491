import Payment from 'payment';

import {
  CreditCardItems,
  PDCreditCardType,
  PDCreditCardTypes,
} from 'common/components/CreditCardData/interfaces';

import { masks } from 'common/constants';

export const getCardType = (value: string): PDCreditCardType => {
  return PDCreditCardTypes.find(Payment.fns.cardType(value))?.value as PDCreditCardType;
};

export const getCardDataValidationStatus = (
  cardDataItem: CreditCardItems,
  cardData: string,
  cardType?: string,
): boolean => {
  switch (cardDataItem) {
    case CreditCardItems.CardNumber:
      return Payment.fns.validateCardNumber(cardData);
    case CreditCardItems.CardExpired:
      return Payment.fns.validateCardExpiry(cardData);
    case CreditCardItems.CardCVV:
      return Payment.fns.validateCardCVC(cardData, cardType);
    default:
      return false;
  }
};

export const getCardItemMask = (cardDataItem: CreditCardItems, card: PDCreditCardType) => {
  switch (cardDataItem) {
    case CreditCardItems.CardNumber:
      return card === PDCreditCardType.amex ? masks.AMEX_CARD_NUMBER : masks.DEFAULT_CARD_NUMBER;
    case CreditCardItems.CardCVV:
      return card === PDCreditCardType.amex ? masks.CVV_CODE_AMEX : masks.CVV_CODE_DEFAULT;
    default:
      return [];
  }
};
