import React, { Component } from 'react';
import { createStyles, StyledComponentProps, Theme, withStyles } from '@material-ui/core';
import { List } from 'immutable';
import { FormattedMessage } from 'react-intl';

// Components
import { Connect } from 'common/decorators';
import TableSection, {
  ITableHeaderColumn,
  ITableSectionColumn,
} from 'modules/reports/components/TableSection/TableSection';
// Selectors
import {
  selectSalesSummary,
  selectSalesSummaryLoading,
} from 'modules/reports/state/salesPOS/selectors';
// Actions
import { fetchSalesSummary } from 'modules/reports/state/salesPOS/actions';
// Constants
import { DEFAULT_TABLE_PARAMS } from 'common/constants/table';
// Interfaces
import { ISalesSummaryItem, ISalesSummaryItemImt } from 'modules/reports/interfaces/salesPOS';
import { DispatchFunction } from 'common/state/interfaces';
import { ITableParams, Order } from 'common/interfaces/table';
// Utils
import { formatPrice } from 'common/utils';
// Messages
import inputLabels from 'common/messages/inputLabels';
import messages from 'modules/reports/messages/messages';

const styles = (theme: Theme) => createStyles({});

interface IInvoicesSummaryProps extends StyledComponentProps {
  mainTableParams: ITableParams;
  salesSummary?: List<ISalesSummaryItemImt>;
  salesSummaryLoading?: boolean;

  fetchSalesSummaryData?: (params?) => void;
}

@(withStyles(styles) as any)
@(Connect({
  mapStateToProps: () => ({
    salesSummary: selectSalesSummary(),
    salesSummaryLoading: selectSalesSummaryLoading(),
  }),
  mapDispatchToProps: (dispatch: DispatchFunction) => ({
    fetchSalesSummaryData: (requestOptions?) => dispatch(fetchSalesSummary(requestOptions)),
  }),
}) as any)
class SalesSummary extends Component<IInvoicesSummaryProps, ITableParams<ISalesSummaryItem>> {
  private readonly columnSettings: ITableSectionColumn[];

  private readonly headerSettings: ITableHeaderColumn<ISalesSummaryItem>[];

  constructor(props: IInvoicesSummaryProps) {
    super(props);

    this.state = {
      page: 0,
      perPage: 5,
      orderBy: 'category',
      order: 'desc',
      filters: [],
    };

    this.columnSettings = [
      {
        name: 'category',
        label: <FormattedMessage {...inputLabels.category} />,
      },
      {
        name: 'quantity',
        label: <FormattedMessage {...messages.qty} />,
        align: 'right',
      },
      {
        name: 'netSales',
        label: <FormattedMessage {...inputLabels.netSales} />,
        align: 'right',
        transform: value => formatPrice(value),
      },
    ];

    this.headerSettings = [
      {
        name: 'category',
        label: <FormattedMessage {...inputLabels.category} />,
        sort: true,
      },
      {
        name: 'quantity',
        label: <FormattedMessage {...messages.qty} />,
        sort: true,
        align: 'right',
      },
      {
        name: 'netSales',
        label: <FormattedMessage {...inputLabels.netSales} />,
        align: 'right',
        sort: true,
      },
    ];
  }

  componentDidMount(): void {
    const { fetchSalesSummaryData, mainTableParams } = this.props;
    const { page, perPage, orderBy, order } = this.state;

    this.setState({ filters: mainTableParams.filters });
    fetchSalesSummaryData({
      ...DEFAULT_TABLE_PARAMS,
      filters: mainTableParams.filters,
      page,
      perPage,
      orderBy,
      order,
    });
  }

  componentDidUpdate(): void {
    const { mainTableParams, fetchSalesSummaryData } = this.props;
    const { page, perPage, orderBy, order, filters } = this.state;

    if (JSON.stringify(filters) !== JSON.stringify(mainTableParams.filters)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ filters: mainTableParams.filters });
      fetchSalesSummaryData({
        ...DEFAULT_TABLE_PARAMS,
        filters: mainTableParams.filters,
        page,
        perPage,
        orderBy,
        order,
      });
    }
  }

  handleChangeTableProps = (page: number, orderBy, order: Order): void => {
    this.updateTableParams({ ...this.state, page, orderBy, order });
  };

  updateTableParams = (tableParams: ITableParams<ISalesSummaryItem>): void => {
    this.setState(tableParams);
    const { fetchSalesSummaryData } = this.props;
    fetchSalesSummaryData(tableParams);
  };

  render(): JSX.Element {
    const { salesSummary, salesSummaryLoading } = this.props;
    const { page, perPage, orderBy, order } = this.state;

    return (
      <TableSection<ISalesSummaryItem, ISalesSummaryItemImt>
        id="sales-category-summary-section"
        title={<FormattedMessage {...messages.salesCategorySummaryTitle} />}
        columns={this.columnSettings}
        headerColumns={this.headerSettings}
        data={salesSummary}
        dataLoading={salesSummaryLoading}
        page={page}
        total={salesSummary?.size}
        order={order}
        orderBy={orderBy}
        perPage={perPage}
        hidePagination
        onSettingsChanged={this.handleChangeTableProps}
      />
    );
  }
}

export default SalesSummary;
