import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { List } from 'immutable';
import { Box, Button, Grid, makeStyles, Theme, Typography } from '@material-ui/core';

import { useAppDispatch } from 'store/hooks';
import { makeTableParams } from 'common/utils/http';
import { usePersonSelectorTemplate } from 'common/components/PersonProfile/hooks/usePersonSelector';

import { TableOrderByParams } from 'common/constants';

import * as selectors from 'common/components/PersonProfile/state/selectors';
import {
  fetchUpcomingBillings,
  resetUpdateBillingScheduleAmountActionResult,
  updateBillingScheduleAmount,
} from 'common/components/PersonProfile/state/actions';

import { BillingsTable } from '../../CancelMembershipModal/BillingStep/BillingsTable';
import {
  IBilling,
  IBillingImt,
  ISelectableBilling,
} from 'common/components/PersonProfile/interfaces';
import { ITableParams } from 'common/interfaces/table';
import { ISelectedData } from 'common/hooks/useMultipleSelect';

import commonMessages from 'common/messages/messages';
import messages from 'common/components/PersonProfile/messages';
import { LoadingBackdrop } from 'common/components/index';

const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(2, 0),
  },
}));

interface IProps {
  personId: string;
  onSubmit: (billingSchedule: IBilling[]) => void;
  onCancel: () => void;
}

const BillingScheduleStep = ({ personId, onSubmit, onCancel }: IProps): JSX.Element => {
  // state
  const dispatch = useAppDispatch();

  const usePersonSelector = usePersonSelectorTemplate(personId);

  const personUpcomingBillings: List<IBillingImt> = usePersonSelector(
    selectors.selectUpcomingBillings,
  );
  const personUpcomingBillingsLoading: boolean = usePersonSelector(
    selectors.selectUpcomingBillingsLoading,
  );
  const updateBillingScheduleAmountActionLoading: boolean = usePersonSelector(
    selectors.selectUpdateBillingScheduleAmountActionLoading,
  );
  const updateBillingScheduleAmountActionResult: IBillingImt = usePersonSelector(
    selectors.selectUpdateBillingScheduleAmountActionResult,
  );

  const [tableParams, setTableParams] = useState(() =>
    makeTableParams([], null, { orderBy: TableOrderByParams.DATE, order: 'desc' }),
  );
  const [billings, setBillings] = useState<ISelectableBilling[]>([]);
  const [billingsSelectParams, setBillingsSelectParams] = useState<Partial<ISelectedData>>({
    isAllSelected: false,
    selectedIds: [],
  });

  const isInitialBillingsExist = useRef(false);
  const prevTableParams = useRef(tableParams);

  const classes = useStyles();

  useEffect(() => {
    dispatch(fetchUpcomingBillings(personId, tableParams));
  }, [dispatch, personId]);

  useEffect(() => {
    if (!personUpcomingBillings?.size && isInitialBillingsExist.current) {
      setBillings([]);
    }

    if (personUpcomingBillings?.size) {
      if (!isInitialBillingsExist.current) {
        isInitialBillingsExist.current = true;
      }

      setBillings(
        personUpcomingBillings.toJS().map((billing: IBilling) => ({ selected: false, ...billing })),
      );
    }
  }, [personUpcomingBillings]);

  useEffect(() => {
    if (updateBillingScheduleAmountActionResult?.size) {
      const updatedBilling = updateBillingScheduleAmountActionResult.toJS();

      setBillings(prevState =>
        prevState.map(billingItem =>
          updatedBilling.id === billingItem.id
            ? { ...billingItem, ...updatedBilling }
            : billingItem,
        ),
      );

      dispatch(resetUpdateBillingScheduleAmountActionResult(null, personId));
    }
  }, [dispatch, personId, updateBillingScheduleAmountActionResult]);

  const handleChangeTableParams = useCallback((tableProps: ITableParams): void => {
    const { page: prevPage, perPage: prevPerPage } = prevTableParams.current;
    const { page, perPage } = tableProps;

    setTableParams(tableProps);

    prevTableParams.current = tableProps;

    if (prevPage !== page || prevPerPage !== perPage) {
      return;
    }

    dispatch(fetchUpcomingBillings(personId, tableProps));
  }, []);

  const handleBillingChange = useCallback(
    (billingItemId: string, amount: number) => {
      dispatch(updateBillingScheduleAmount(personId, billingItemId, amount));
    },
    [dispatch, personId],
  );

  const handleBillingsSelect = useCallback((params: ISelectedData) => {
    const { selectedIds, isAllSelected } = params;
    setBillingsSelectParams(params);

    setBillings(prevState => {
      const nextBillingsState = [...prevState];

      if (isAllSelected) {
        nextBillingsState.forEach(billing => {
          billing.selected = true;
        });
      } else {
        nextBillingsState.forEach(billing => {
          billing.selected = Array.isArray(selectedIds) ? selectedIds.includes(billing.id) : null;
        });
      }

      return nextBillingsState;
    });
  }, []);

  const handleSubmit = () => {
    let billingSchedules;

    if (billingsSelectParams.isAllSelected && !billingsSelectParams.excludedIds?.length) {
      billingSchedules = billings;

      onSubmit(billingSchedules);

      return;
    }

    if (billingsSelectParams.excludedIds?.length) {
      billingSchedules = billings.reduce(
        (acc, billingItem) =>
          billingsSelectParams.excludedIds.includes(billingItem.id) ? acc : [...acc, billingItem],
        [],
      );
    } else {
      billingSchedules = billings.filter(billingItem => billingItem.selected);
    }

    onSubmit(billingSchedules);
  };

  const isSubmitBtnDisabled =
    (!billingsSelectParams.selectedIds.length && !billingsSelectParams.isAllSelected) ||
    (billingsSelectParams.isAllSelected &&
      billingsSelectParams.excludedIds?.length === billings.length);

  return (
    <>
      <LoadingBackdrop
        isLoading={personUpcomingBillingsLoading || updateBillingScheduleAmountActionLoading}
      />

      {personUpcomingBillings?.size || isInitialBillingsExist.current ? (
        <BillingsTable
          billings={billings}
          onChangeParams={handleChangeTableParams}
          onSelect={handleBillingsSelect}
          onBillingChanged={handleBillingChange}
          tableParams={tableParams}
        />
      ) : (
        <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="200px">
          <Typography variant="h2" color="textSecondary">
            <FormattedMessage {...messages.billingScheduleModalNoBillingSchedule} />
          </Typography>
        </Box>
      )}

      <Grid container spacing={2} className={classes.footer}>
        <Grid item>
          <Button color="primary" onClick={onCancel}>
            <FormattedMessage {...commonMessages.cancelBtn} />
          </Button>
        </Grid>

        <Grid item>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={isSubmitBtnDisabled}
          >
            <FormattedMessage {...commonMessages.proceedToPaymentBtn} />
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default BillingScheduleStep;
