import { createAction } from '@reduxjs/toolkit';

import { IPaginatedScripts } from 'services/network/ScriptsService';
import { IScriptDetailed } from 'modules/crm/interfaces/scripts';
import { IMultipleSelectData, ITableParams } from 'common/interfaces/table';
import { GeneralThunkAction } from 'common/state/interfaces';

import Services from 'services/network';
import { actionTypes } from 'modules/crm/constants/scripts';

import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from 'common/state/notifications/actions';

import messages from 'modules/crm/messages/scripts';
import { PeakModules } from 'common/constants/peakModules';

const fetchScriptsListLoadingAction = createAction<boolean>(actionTypes.FETCH_SCRIPTS_LIST_LOADING);
const fetchScriptsListSuccessAction = createAction<IPaginatedScripts>(
  actionTypes.FETCH_SCRIPTS_LIST_SUCCESS,
);

export const fetchScripts = (
  pageOptions?: ITableParams,
  module?: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchScriptsListLoadingAction(true));
    try {
      let paginatedData;
      switch (module) {
        case PeakModules.Crm:
          paginatedData = await Services.Scripts.getScripts(pageOptions);
          break;
        case PeakModules.PersonalTrainingCrm:
          paginatedData = await Services.PtScripts.getScripts(pageOptions);
          break;
        default:
          paginatedData = null;
      }
      dispatch(fetchScriptsListSuccessAction(paginatedData));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchScriptsListLoadingAction(false));
    }
  };
};

export const fetchActiveScripts = (module: PeakModules): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchScriptsListLoadingAction(true));
    try {
      let paginatedData: IPaginatedScripts;
      switch (module) {
        case PeakModules.Crm:
          paginatedData = await Services.Scripts.getActiveScripts();
          break;
        case PeakModules.PersonalTrainingCrm:
          paginatedData = await Services.PtScripts.getActiveScripts();
          break;
        default:
          paginatedData = null;
      }
      dispatch(fetchScriptsListSuccessAction(paginatedData));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchScriptsListLoadingAction(false));
    }
  };
};

const fetchScriptLoading = createAction<boolean>(actionTypes.FETCH_SCRIPT_LOADING);
const fetchScriptAction = createAction<IScriptDetailed>(actionTypes.FETCH_SCRIPT_SUCCESS);

export const fetchScriptById = (scriptId: string, module: PeakModules): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchScriptLoading(true));
    try {
      let script: IScriptDetailed;
      switch (module) {
        case PeakModules.Crm:
          script = await Services.Scripts.getScriptById(scriptId);
          break;
        case PeakModules.PersonalTrainingCrm:
          script = await Services.PtScripts.getScriptById(scriptId);
          break;
        default:
          script = null;
      }

      dispatch(fetchScriptAction(script));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchScriptLoading(false));
    }
  };
};

const updateScriptLoading = createAction<boolean>(actionTypes.UPDATE_SCRIPT_LOADING);
const updateScriptAction = createAction<IScriptDetailed>(actionTypes.UPDATE_SCRIPT_SUCCESS);

export const updateScript = (
  scriptId: string,
  updatedScriptForm: IScriptDetailed,
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(updateScriptLoading(true));
    try {
      let updatedScript: IScriptDetailed;
      switch (module) {
        case PeakModules.Crm:
          updatedScript = await Services.Scripts.updateScript(scriptId, updatedScriptForm);
          break;
        case PeakModules.PersonalTrainingCrm:
          updatedScript = await Services.PtScripts.updateScript(scriptId, updatedScriptForm);
          break;
        default:
          updatedScript = null;
      }
      dispatch(updateScriptAction(updatedScript));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(updateScriptLoading(false));
    }
  };
};

const createScriptLoading = createAction<boolean>(actionTypes.CREATE_SCRIPT_LOADING);
const createScriptAction = createAction<IScriptDetailed>(actionTypes.CREATE_SCRIPT_SUCCESS);

export const createScript = (
  newScriptForm: IScriptDetailed,
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(createScriptLoading(true));
    try {
      let newScript: IScriptDetailed;
      switch (module) {
        case PeakModules.Crm:
          newScript = await Services.Scripts.createScript(newScriptForm);
          break;
        case PeakModules.PersonalTrainingCrm:
          newScript = await Services.PtScripts.createScript(newScriptForm);
          break;
        default:
      }

      dispatch(createScriptAction(newScript));
      dispatch(enqueueSuccessNotification(messages.scriptCreatedMessage));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(createScriptLoading(false));
    }
  };
};

const resetScriptFormAction = createAction(actionTypes.RESET_SCRIPT_FORM);
export const resetScriptForm = (): GeneralThunkAction => {
  return async dispatch => {
    dispatch(resetScriptFormAction());
  };
};

const changeScriptStatusLoading = createAction<boolean>(actionTypes.CHANGE_SCRIPT_STATUS_LOADING);
const changeScriptStatusAction = createAction<Record<string, any>>(
  actionTypes.CHANGE_SCRIPT_STATUS_SUCCESS,
);
export const resetChangeScriptStatusResult = createAction<void>(
  actionTypes.RESET_CHANGE_SCRIPT_STATUS_RESULT,
);
export const changeScriptStatus = (
  data: IMultipleSelectData,
  isActive: boolean,
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(changeScriptStatusLoading(true));
    try {
      switch (module) {
        case PeakModules.Crm:
          await Services.Scripts.changeScriptStatus(data, isActive);
          break;
        case PeakModules.PersonalTrainingCrm:
          await Services.PtScripts.changeScriptStatus(data, isActive);
          break;
        default:
      }
      dispatch(changeScriptStatusAction({ success: true }));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(changeScriptStatusLoading(false));
    }
  };
};
