import { createSelector } from 'reselect';

// ServicesSection
const selectServicesListDomain = (state: any) =>
  state.getIn(['services', 'services', 'servicesList']);

const selectServicesListLoading = () =>
  createSelector(selectServicesListDomain, state => state.get('isLoading'));

const selectServicesList = () =>
  createSelector(selectServicesListDomain, state => state.get('list'));

const selectServicesListMeta = () =>
  createSelector(selectServicesListDomain, state => state.get('meta'));
// Change status
const selectChangeServiceStatusLoading = () =>
  createSelector(selectServicesListDomain, state => state.get('changeServiceStatusLoading'));

const selectChangeServiceStatusResult = () =>
  createSelector(selectServicesListDomain, state => state.get('changeServiceStatusResult'));

// Service Item
const selectServiceItemDomain = (state: any) =>
  state.getIn(['services', 'services', 'serviceItem']);

const selectServiceItemLoading = () =>
  createSelector(selectServiceItemDomain, state => state.get('isServiceLoading'));

const selectServiceItem = () =>
  createSelector(selectServiceItemDomain, state => state.get('service'));

// Edit
const selectEditServiceLoading = () =>
  createSelector(selectServiceItemDomain, state => state.get('editServiceLoading'));

const selectEditServiceResult = () =>
  createSelector(selectServicesListDomain, state => state.get('editServiceResult'));

// Create
const selectCreateServiceLoading = () =>
  createSelector(selectServiceItemDomain, state => state.get('editServiceLoading'));

const selectCreateServiceResult = () =>
  createSelector(selectServicesListDomain, state => state.get('editServiceResult'));

// TODO
const selectCreateUpdateServiceLoading = () =>
  createSelector(selectServiceItemDomain, state => state.get('isCreateUpdateServiceLoading'));
const selectCreateUpdateServiceActionResult = () =>
  createSelector(selectServiceItemDomain, state => state.get('createUpdateServiceActionResult'));

export {
  // Services List
  selectServicesListLoading,
  selectServicesList,
  selectServicesListMeta,
  selectChangeServiceStatusLoading,
  selectChangeServiceStatusResult,
  // Service Item
  selectEditServiceLoading,
  selectEditServiceResult,
  selectServiceItemLoading,
  selectServiceItem,
  selectCreateServiceLoading,
  selectCreateServiceResult,
  // TODO
  selectCreateUpdateServiceLoading,
  selectCreateUpdateServiceActionResult,
};
