import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ConstOptions } from 'common/constants/classes';

import corporationMessages from 'modules/corporations/messages';
import clubMessages from 'modules/clubs/messages';
import commonMessages from 'common/messages/messages';

export const actionTypes = {
  FETCH_CLUBS_LIST_LOADING: 'FETCH_CLUBS_LIST_LOADING',
  FETCH_CLUBS_LIST_SUCCESS: 'FETCH_CLUBS_LIST_SUCCESS',
  RESET_CLUBS_LIST: 'RESET_CLUBS_LIST',

  CREATE_CLUB_SUCCESS: 'CREATE_CLUB_SUCCESS',
  CREATE_CLUB_LOADING: 'CREATE_CLUB_LOADING',
  RESET_CREATED_CLUB: 'RESET_CREATED_CLUB',

  FETCH_CLUB_GENERAL_INFO: 'FETCH_CLUB_GENERAL_INFO',
  FETCH_CLUB_GENERAL_INFO_LOADING: 'FETCH_CLUB_GENERAL_INFO_LOADING',
  RESET_CLUB_GENERAL_INFO: 'RESET_CLUB_GENERAL_INFO',

  FETCH_CLUB_GENERAL_INFO_VIEW: 'FETCH_CLUB_GENERAL_INFO_VIEW',
  FETCH_CLUB_GENERAL_INFO_VIEW_LOADING: 'FETCH_CLUB_GENERAL_INFO_VIEW_LOADING',
  RESET_CLUB_GENERAL_INFO_VIEW: 'RESET_CLUB_GENERAL_INFO_VIEW',

  FETCH_CLUB_STEP_INFO: 'FETCH_CLUB_STEP_INFO',
  FETCH_CLUB_STEP_INFO_LOADING: 'FETCH_CLUB_STEP_INFO_LOADING',
  RESET_CLUB_STEP_INFO: 'RESET_CLUB_STEP_INFO',

  FETCH_CLUB_BANK_INFO_VIEW: 'FETCH_CLUB_BANK_INFO_VIEW',
  FETCH_CLUB_BANK_INFO_VIEW_LOADING: 'FETCH_CLUB_BANK_INFO_VIEW_LOADING',
  RESET_CLUB_BANK_INFO_VIEW: 'RESET_CLUB_BANK_INFO_VIEW',

  UPDATE_CLUB_STEP_INFO: 'UPDATE_CLUB_STEP_INFO',
  UPDATE_CLUB_BANK_INFO_STEP: 'UPDATE_CLUB_BANK_INFO_STEP',
  UPDATE_CLUB_GENERAL_STEP_INFO: 'UPDATE_CLUB_GENERAL_STEP_INFO',
  UPDATE_CLUB_STEP_INFO_LOADING: 'UPDATE_CLUB_STEP_INFO_LOADING',
  RESET_UPDATED_CLUB_STEP_INFO_RESULT: 'RESET_UPDATED_CLUB_STEP_INFO_RESULT',

  UPDATE_CLUB_GENERAL_INFO_ACTION_RESULT: 'UPDATE_CLUB_GENERAL_INFO_ACTION_RESULT',

  CHANGE_CLUB_STATUS: 'CLUB_SETTINGS/CHANGE_CLUB_STATUS',
  CHANGE_CLUB_STATUS_LOADING: 'CLUB_SETTINGS/CHANGE_CLUB_STATUS_LOADING',
  RESET_CLUB_CHANGE_STATUS_RESULT: 'CLUB_SETTINGS/RESET_CLUB_CHANGE_STATUS_RESULT',
};

export enum ClubsPaths {
  CLUBS = '/',
  NEW_CLUB = 'new',

  CLUB_EDIT_BANK_INFO = ':clubId/bank-info',
  CLUB_EDIT_BILLING = ':clubId/billing',
  CLUB_EDIT_PROCESSOR_CONFIG = ':clubId/processor-config',
  CLUB_EDIT_TAXPAYER = ':clubId/taxpayer',
  CLUB_EDIT_OTHER = ':clubId/other',
  CLUB_EDIT_WORKING_HOURS = ':clubId/working-hours',
  CLUB_EDIT_KID_ZONE_SETTINGS = ':clubId/kid-zone-settings',
}

export enum ClubSteps {
  GENERAL_INFORMATION = 'GENERAL_INFORMATION',
  WORKING_HOURS = 'WORKING_HOURS',
  BANK_INFO = 'BANK_INFO',
  BILLING = 'BILLING',
  PROCESSOR_CONFIGURATION = 'PROCESSOR_CONFIGURATION',
  TAXPAYER = 'TAXPAYER',
  OTHER = 'OTHER',
  KID_ZONE_SETTINGS = 'KID_ZONE_SETTINGS',
}

export const NewClubStepsLabels = {
  [ClubSteps.GENERAL_INFORMATION]: (
    <FormattedMessage {...corporationMessages.generalInformationLabel} />
  ),
  [ClubSteps.WORKING_HOURS]: <FormattedMessage {...clubMessages.workingHoursLabel} />,
  [ClubSteps.BANK_INFO]: <FormattedMessage {...corporationMessages.bankInfoLabel} />,
  [ClubSteps.BILLING]: <FormattedMessage {...commonMessages.billingLabel} />,
  [ClubSteps.PROCESSOR_CONFIGURATION]: (
    <FormattedMessage {...commonMessages.processorConfigurationLabel} />
  ),
  [ClubSteps.TAXPAYER]: <FormattedMessage {...corporationMessages.taxpayerLabel} />,
  [ClubSteps.OTHER]: <FormattedMessage {...clubMessages.otherLabel} />,
};

export const BillingOptionLabels = new ConstOptions([
  { value: 'couponBilling', message: clubMessages.couponBillingLabel },
  { value: 'paymentSplits', message: clubMessages.paymentSplitsLabel },
  { value: 'dues', message: clubMessages.duesLabel },
  { value: 'downPayments', message: clubMessages.downPaymentsLabel },
  { value: 'memberServicesHandledByPeak', message: clubMessages.memberServicesLabel },
]);
