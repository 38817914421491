import React from 'react';
import { List as ImmutableList } from 'immutable';
import { useSelector } from 'react-redux';
import { Controller, UseFormMethods } from 'react-hook-form';
import { useIntl } from 'react-intl';
import {
  Avatar,
  Box,
  CardContent,
  Grid,
  IconButton,
  InputBase,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Add, Cancel, Remove } from '@material-ui/icons';
// icons
import { ReactComponent as DefaultIcon } from 'img/icons/default-icon.svg';
// constants
import { IssueFrequencyType, IssueFrequencyTypes } from 'modules/services/constants/packages';
// hooks
import { useAppDispatch } from 'store/hooks';
// interfaces
import { IFormStepStyle } from 'modules/services/interfaces/packages';
import { INamedEntity, INamedEntityImt } from 'common/interfaces/common';
// utils
import { checkObjectPropertyExist } from 'common/utils';
// state
import * as actions from 'modules/services/state/packages/actions';
import * as selectors from 'modules/services/state/packages/selectors';
// components
import { SearchInputWithOptions, Select } from 'common/components';
import StatusBlock from 'common/components/StatusBlock/StatusBlock';

import inputLabels from 'common/messages/inputLabels';

const useStyles = makeStyles((theme: Theme) => ({
  contentWrapper: {
    flex: 1,
    minHeight: 0,
    overflow: 'hidden',
  },
  hidden: {
    display: 'none',
  },
  cardContent: {
    padding: ({ smallPaddingContent }: IFormStepStyle) =>
      smallPaddingContent ? theme.spacing(1, 0) : theme.spacing(1, 2),
    '&:last-child': {
      padding: ({ smallPaddingContent }: IFormStepStyle) =>
        smallPaddingContent ? theme.spacing(1, 0) : theme.spacing(1, 2),
    },
  },
  wrapFlexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  searchByNameImg: {
    height: 24,
    width: 24,
  },
  searchByNamePrice: {
    flex: 'none',
    opacity: 0.5,
  },
  searchByNameName: {
    width: '100%',
    paddingLeft: 8,
    paddingRight: 12,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.text.primary,
  },
  inventoryStatus: {
    marginLeft: theme.spacing(1),
  },
  amountInput: {
    width: 20,
    '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      // eslint-disable-next-line no-useless-computed-key
      ['-webkit-appearance']: 'none',
      margin: 0,
    },
    '& input': {
      textAlign: 'center',
      minWidth: 0,
      padding: 0,
      // eslint-disable-next-line no-useless-computed-key
      ['-moz-appearance']: 'textfield',
    },
  },
  inputButton: {
    '&.MuiIconButton-sizeSmall': {
      padding: 0,
      width: 18,
    },
  },
  select: {
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      padding: '0 32px 0 0',
      textAlign: 'right',
    },
    '& fieldset': {
      border: 'unset',
    },
    minWidth: 110,
  },
  removeButton: {
    color: theme.palette.text.primary,
    opacity: 0.4,
  },
}));

interface IInventoryStepProps extends IFormStepStyle {
  formMethods: UseFormMethods<any>;
}

const handleChangeInventory = (value, data, index) => {
  return value.map((item, indexMap) => {
    if (indexMap === index) {
      return {
        ...item,
        ...data,
      };
    }
    return item;
  });
};

const InventoryStepForm = ({
  formMethods,
  smallPaddingContent,
}: IInventoryStepProps): JSX.Element => {
  const classes = useStyles({ smallPaddingContent });
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const { control } = formMethods;

  const searchData: ImmutableList<INamedEntityImt> = useSelector(
    selectors.selectPackageInventoryList,
  );
  const isLoading: boolean = useSelector(selectors.selectPackageInventoryLoading);

  return (
    <Box className={classes.contentWrapper}>
      <CardContent className={classes.cardContent}>
        <Controller
          control={control}
          name="inventories"
          render={({ value, onChange }) => (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SearchInputWithOptions
                  placeholder={intl.formatMessage(inputLabels.searchItems)}
                  getOptionsByValue={searchStr => {
                    if (searchStr) dispatch(actions.fetchPackageInventory(searchStr));
                    else dispatch(actions.resetPackageInventory());
                  }}
                  loading={isLoading}
                  onChange={(event, inventory: INamedEntity) => {
                    if (!value.map(item => item.inventory.id).includes(inventory.id)) {
                      const dataInventory = {
                        frequency: IssueFrequencyType.OneTime,
                        inventory: {
                          id: inventory.id,
                          imageUrl: inventory.imageUrl,
                          title: inventory.title,
                        },
                        number: 1,
                      };
                      onChange([...value, dataInventory]);
                    } else {
                      onChange(
                        value.map(item => {
                          if (item.inventory.id === inventory.id) {
                            return { ...item, number: item.number + 1 };
                          }
                          return item;
                        }),
                      );
                    }
                  }}
                  renderOption={({ title, imageUrl }: INamedEntity) => {
                    return (
                      <Box className={classes.wrapFlexCenter} width="100%">
                        <Avatar
                          src={imageUrl}
                          alt={title}
                          variant="rounded"
                          sizes="24px"
                          className={classes.searchByNameImg}
                        >
                          <DefaultIcon />
                        </Avatar>
                        <Box className={classes.searchByNameName}>{title}</Box>
                      </Box>
                    );
                  }}
                  getOptionLabel={option => option && option.title}
                  options={searchData?.toJS()}
                />
              </Grid>
              {[...value].map((invItem, index) => (
                <Grid item xs={12} key={invItem.inventory.id}>
                  <Box>
                    <Grid container justify="space-between" spacing={2}>
                      <Grid item>
                        <Grid container spacing={1} wrap="nowrap">
                          <Grid item className={classes.wrapFlexCenter}>
                            <Avatar
                              src={invItem.inventory.imageUrl}
                              alt={invItem.inventory.title}
                              variant="rounded"
                              sizes="24px"
                              className={classes.searchByNameImg}
                            >
                              <DefaultIcon />
                            </Avatar>
                          </Grid>
                          <Grid item className={classes.wrapFlexCenter}>
                            <Box
                              display="flex"
                              alignItems="center"
                              className={classes.searchByNameName}
                            >
                              <Typography>{invItem.inventory.title}</Typography>

                              {checkObjectPropertyExist(invItem.inventory, 'active') &&
                                !invItem.inventory.active && (
                                  <StatusBlock
                                    isActive={false}
                                    className={classes.inventoryStatus}
                                  />
                                )}
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item className={classes.wrapFlexCenter}>
                        <Grid container spacing={2}>
                          <Grid item>
                            <IconButton
                              className={classes.inputButton}
                              aria-label="decrement"
                              size="small"
                              color="primary"
                              type="button"
                              onClick={() =>
                                onChange(
                                  handleChangeInventory(
                                    value,
                                    { number: invItem.number - 1 },
                                    index,
                                  ),
                                )
                              }
                              disabled={invItem.number === 1}
                            >
                              <Remove fontSize="small" />
                            </IconButton>
                            <InputBase
                              inputProps={{ min: 1, max: 99 }}
                              type="number"
                              value={invItem.number}
                              className={classes.amountInput}
                            />
                            <IconButton
                              className={classes.inputButton}
                              aria-label="decrement"
                              size="small"
                              color="primary"
                              type="button"
                              onClick={() =>
                                onChange(
                                  handleChangeInventory(
                                    value,
                                    { number: invItem.number + 1 },
                                    index,
                                  ),
                                )
                              }
                            >
                              <Add fontSize="small" />
                            </IconButton>
                          </Grid>
                          <Grid item className={classes.wrapFlexCenter}>
                            <Select
                              label=""
                              fullWidth
                              variant="outlined"
                              value={invItem.frequency}
                              onChange={event =>
                                onChange(handleChangeInventory(value, { frequency: event }, index))
                              }
                              className={classes.select}
                            >
                              {IssueFrequencyTypes.getSelectOptions()}
                            </Select>
                          </Grid>
                          <Grid item className={classes.wrapFlexCenter}>
                            <IconButton
                              size="small"
                              type="button"
                              className={classes.removeButton}
                              onClick={() =>
                                onChange(value.filter((item, indexFilter) => index !== indexFilter))
                              }
                            >
                              <Cancel fontSize="inherit" />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}
        />
      </CardContent>
    </Box>
  );
};

export default React.memo(InventoryStepForm);
