import { createSelector } from 'reselect';

// Tasks
const selectTaskDomain = (state: any) => state.getIn(['crmModule', 'tasksTable']);

const selectTasks = () => createSelector(selectTaskDomain, state => state.get('list'));

const selectTasksMeta = () => createSelector(selectTaskDomain, state => state.get('meta'));

const selectTasksLoading = () =>
  createSelector(selectTaskDomain, state => state.get('listLoading'));

const selectTaskCompleting = () =>
  createSelector(selectTaskDomain, state => state.get('taskCompleting'));

const selectTaskAssigning = () =>
  createSelector(selectTaskDomain, state => state.get('taskAssigning'));

const selectTasksActionResult = () =>
  createSelector(selectTaskDomain, state => state.get('tasksActionResult'));

export {
  // Tasks
  selectTasks,
  selectTasksMeta,
  selectTasksLoading,
  selectTaskCompleting,
  selectTaskAssigning,
  selectTasksActionResult,
};
