export const actionTypes = {
  USER_SIGN_IN: 'MEMBER_PORTAL_AUTHENTICATION/USER_SIGN_IN',
  USER_SIGN_IN_LOADING: 'MEMBER_PORTAL_AUTHENTICATION/USER_SIGN_IN_LOADING',
  USER_SIGN_IN_ERROR: 'MEMBER_PORTAL_AUTHENTICATION/USER_SIGN_IN_ERROR',

  USER_LOG_OUT: 'MEMBER_PORTAL_AUTHENTICATION/USER_LOG_OUT',

  FETCH_USER_PROFILE: 'MEMBER_PORTAL_AUTHENTICATION/FETCH_USER_PROFILE',
  FETCH_USER_PROFILE_LOADING: 'MEMBER_PORTAL_AUTHENTICATION/FETCH_USER_PROFILE_LOADING',
  FETCH_USER_PROFILE_ERROR: 'MEMBER_PORTAL_AUTHENTICATION/FETCH_USER_PROFILE_ERROR',
  RESET_USER_PROFILE_DATA: 'MEMBER_PORTAL_AUTHENTICATION/RESET_USER_PROFILE_DATA',

  SELECT_ORGANIZATION: 'MEMBER_PORTAL_AUTHENTICATION/SELECT_ORGANIZATION',
  SET_ORGANIZATION_PRIMARY_COLOR: 'MEMBER_PORTAL_AUTHENTICATION/SET_ORGANIZATION_PRIMARY_COLOR',
  RESET_ORGANIZATION_PRIMARY_COLOR: 'MEMBER_PORTAL_AUTHENTICATION/RESET_ORGANIZATION_PRIMARY_COLOR',

  SET_IS_SIDEBAR_OPEN: 'MEMBER_PORTAL_AUTHENTICATION/SET_IS_SIDEBAR_OPEN',

  SET_USER_AVATAR: 'MEMBER_PORTAL_AUTHENTICATION/SET_USER_AVATAR',

  // UPDATE_CLUB_DATA: 'MEMBER_PORTAL_AUTHENTICATION/UPDATE_CLUB_DATA',
  // UPDATE_CORPORATION_DATA: 'MEMBER_PORTAL_AUTHENTICATION/UPDATE_CORPORATION_DATA',

  FETCH_CORPORATIONS_FOR_SIGN_IN: 'MEMBER_PORTAL_AUTHENTICATION/FETCH_CORPORATIONS_FOR_SIGN_IN',
  FETCH_CORPORATIONS_FOR_SIGN_IN_LOADING:
    'MEMBER_PORTAL_AUTHENTICATION/FETCH_CORPORATIONS_FOR_SIGN_IN_LOADING',
  RESET_CORPORATIONS_FOR_SIGN_IN: 'MEMBER_PORTAL_AUTHENTICATION/RESET_CORPORATIONS_FOR_SIGN_IN',
};
