import { IEditPackageDetails } from '../interfaces/packages';
import { AvailabilityType } from '../constants/packages';

export const transformPackageDetailToDTO = (packageDetail: IEditPackageDetails) => {
  const packageDTO: any = { ...packageDetail };

  packageDTO.generalSection.allowAllEmployee = !packageDetail.generalSection?.employees?.length;
  packageDTO.generalSection.allowAccessAllClubs = !packageDetail.generalSection?.accessClubs
    ?.length;
  packageDTO.generalSection.allowSellInAllClubs = !packageDetail.generalSection?.sellerClubs
    ?.length;

  if (packageDTO.serviceSection) {
    packageDTO.serviceSection.includedServices = packageDetail.serviceSection?.includedServices?.map(
      includedService => ({
        ...includedService,
        hasCustomDayTimeAvailability: !!includedService.dayTimeAvailDtoList?.length,
      }),
    );
  }

  return packageDTO;
};

export const transformPackageDetailFromDTO = (packageDetailDTO): IEditPackageDetails => {
  const packageDetail = { ...packageDetailDTO };

  packageDetail.generalSection.availabilityType = packageDetailDTO.generalSection?.endDate
    ? AvailabilityType.ForRange
    : AvailabilityType.StartingOnDate;

  return packageDetail;
};
