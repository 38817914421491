import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Button, Collapse, Grid, IconButton, makeStyles, Theme } from '@material-ui/core';

import useScrollIntoView from 'common/hooks/useScrollIntoView';

import { IEditablePackageConfiguration } from 'common/interfaces/membership';
import { TEditableInstance } from '../MembershipFormView';

import AmountSectionView from './AmountSectionView/AmountSectionView';
import SplitPaymentSectionView from './SplitPaymentSectionView/SplitPaymentSectionView';
import FreezeSectionView from './FreezeSectionView/FreezeSectionView';
import GracePeriodSectionView from './GracePeriodSectionView/GracePeriodSectionView';
import CreditCardFeeSectionView from './CreditCardFeeSectionView/CreditCardFeeSectionView';
import { FieldInfo } from 'common/components/index';
import { ReactComponent as AngleDownIcon } from 'img/icons/angle-down.svg';
import { ReactComponent as AngleRightIcon } from 'img/icons/angle-right.svg';
import { ReactComponent as EditIcon } from 'img/icons/pencil_deprecated.svg';

import servicesMessages from 'modules/services/messages/messages';
import commonMessages from 'common/messages/messages';

const useStyles = makeStyles((theme: Theme) => ({
  sectionBtn: {
    padding: 0,
    color: theme.palette.text.secondary,

    '& svg': {
      width: '0.75rem',
      height: '0.75rem',
      marginRight: theme.spacing(1),
    },
  },
  editIcon: {
    width: '1.25rem',
    height: '1.25rem',
  },
  sectionContent: {
    marginTop: theme.spacing(1),
  },
}));

interface IProps {
  membershipPackage: IEditablePackageConfiguration;
  onEditPackage: (
    servicePackage: IEditablePackageConfiguration,
    instance: TEditableInstance,
  ) => void;
  isOutOfTerm?: boolean;
}

const BillingSectionView = ({
  membershipPackage,
  onEditPackage,
  isOutOfTerm,
}: IProps): JSX.Element => {
  const [showContent, setShowContent] = useState<boolean>(true);

  const { scrollRef } = useScrollIntoView(showContent);

  const classes = useStyles();

  const {
    billingOption: billingOptionId,
    billingOptions,
    revenueCode: { totalTax: revenueCodeTotalTax } = {},
  } = membershipPackage;

  const data = isOutOfTerm ? membershipPackage.outOfTermBillingOption : membershipPackage;

  const billingOption = billingOptions?.find(option => {
    if (isOutOfTerm) {
      return option.isOutOfTerm;
    }

    return billingOptionId ? billingOptionId === option.id : option.useAsDefault;
  });

  const checkEditIcon =
    !!billingOption &&
    (billingOption.freeze?.editableDaysNumber.editable ||
      billingOption.splitting?.allow ||
      billingOption.paymentGrace?.allow ||
      billingOption.creditCardFee?.enabled);

  const handleEditOption = () => {
    onEditPackage(membershipPackage, isOutOfTerm ? 'outOfTerm' : 'billingOption');
  };

  return (
    <Box mt={2}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Button
          onClick={() => setShowContent(!showContent)}
          startIcon={showContent ? <AngleDownIcon /> : <AngleRightIcon />}
          className={classes.sectionBtn}
        >
          <FormattedMessage
            {...(isOutOfTerm
              ? servicesMessages.outOfTermSectionLabel
              : commonMessages.billingLabel)}
          />
        </Button>
        {checkEditIcon && (
          <IconButton color="primary" size="small" onClick={handleEditOption}>
            <EditIcon className={classes.editIcon} />
          </IconButton>
        )}
      </Box>

      <Collapse in={showContent} ref={scrollRef}>
        <Grid container spacing={2} className={classes.sectionContent}>
          <Grid item xs={4}>
            <FieldInfo
              label={<FormattedMessage {...servicesMessages.reVenueCodeTaxLabel} />}
              description={`${revenueCodeTotalTax}%`}
            />
          </Grid>
          {!isOutOfTerm && (
            <Grid item xs={4}>
              <FieldInfo
                label={<FormattedMessage {...servicesMessages.billingOptionLabel} />}
                description={billingOption?.title}
              />
            </Grid>
          )}
          {!!billingOption && (
            <>
              <AmountSectionView
                membershipPackage={membershipPackage}
                billingOption={billingOption}
                isOutOfTerm={isOutOfTerm}
              />

              {billingOption.freeze && <FreezeSectionView freeze={data.freeze} />}

              {billingOption.splitting.allow && (
                <SplitPaymentSectionView splitting={data.splitting} />
              )}

              {billingOption.paymentGrace.allow && (
                <GracePeriodSectionView paymentGrace={data.paymentGrace} />
              )}

              {billingOption.creditCardFee.enabled && (
                <CreditCardFeeSectionView creditCardFee={data.creditCardFee} />
              )}
            </>
          )}
        </Grid>
      </Collapse>
    </Box>
  );
};

export default BillingSectionView;
