import React, { useEffect } from 'react';
import { Box, ListItemIcon, MenuItem, Typography } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles } from '@material-ui/core/styles';
import { bindHover, bindMenu } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import classnames from 'classnames';
import { ReactComponent as ReplayIcon } from 'img/icons/reset.svg';

import useMenuState from 'common/hooks/useMenuState';

import { Button, DialogComponent } from 'common/components';
import FiltersCounter from '../FiltersCounter/FiltersCounter';
import { FormattedMessage } from 'react-intl';

import commonMessages from 'common/messages/messages';

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'space-between',
    display: 'flex',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      '& p': {
        color: theme.palette.primary.contrastText,
      },
    },
  },
  rootSelected: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  chevronIcon: {
    color: theme.palette.text.primary,
  },
  selectedColor: {
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
  activeColor: {
    color: 'white',
  },
  iconsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: theme.spacing(-1),
  },
  replayContainer: {
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    zIndex: 1,
    right: 0,
    top: 0,
    borderRadius: 3,
    cursor: 'pointer',
    height: 18,
    width: 20,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconReplay: {
    fill: theme.palette.background.paper,
    height: 16,
    width: 16,
    padding: '0 2px 0px',
  },
}));

interface IProps {
  name: string;
  title: string | JSX.Element | React.ReactNode;
  filtersTotal?: number;
  active?: boolean;
  dialogMenu?: boolean;
  className?: string;
  hoverMenuClassName?: string;
  isShowReset?: boolean;
  reset?: (event: React.MouseEvent<HTMLElement>) => void;

  renderBody?: (closeMenu?: () => void) => JSX.Element;

  onClose?: () => void;

  clearBtn?: boolean;
  clearDisabled?: boolean;
  onClear?: () => void;
  onDialogApply?: (closeMenu?: () => void) => void;
}

const HoverFilter = ({
  name,
  title,
  filtersTotal,
  dialogMenu,
  onClose,
  children,
  renderBody,
  clearBtn,
  clearDisabled,
  onClear,
  onDialogApply,
  className,
  isShowReset,
  reset,
  hoverMenuClassName,
}: React.PropsWithChildren<IProps>) => {
  const classes = useStyles();

  const { menuAnchorEl, handleShowMenu, handleCloseMenu } = useMenuState();

  const popupState = usePopupState({
    variant: 'popover',
    popupId: `${name}-hover-menu`,
  });

  const { isOpen, close } = popupState;
  const selected = filtersTotal > 0;

  useEffect(() => {
    if (onClose && isOpen === false) onClose();
  }, [onClose, isOpen]);

  const renderMenuItem = () => {
    return (
      <MenuItem
        disableRipple
        className={classnames('filter-list-button', classes.root, {
          [classes.rootSelected]: isOpen,
        })}
        onClick={handleShowMenu}
      >
        {isShowReset && (
          <Box className={classes.replayContainer} onClick={reset}>
            <ReplayIcon className={classes.iconReplay} />
          </Box>
        )}
        <Typography
          className={classnames({
            [classes.selectedColor]: selected,
            [classes.activeColor]: isOpen,
          })}
        >
          {title}
        </Typography>

        <ListItemIcon className={classes.iconsContainer}>
          <FiltersCounter count={filtersTotal} inverseColor={isOpen} />
          <ChevronRightIcon
            fontSize="small"
            className={classnames(classes.chevronIcon, {
              [classes.activeColor]: isOpen,
            })}
          />
        </ListItemIcon>
      </MenuItem>
    );
  };

  const renderDialogMenu = () => {
    return (
      <div className={className}>
        {renderMenuItem()}

        <DialogComponent
          title={title}
          isOpen={Boolean(menuAnchorEl)}
          submitBtnTitle="Apply"
          cancelBtnTitle="Cancel"
          size="md"
          onClose={handleCloseMenu}
          onSubmit={() => onDialogApply(handleCloseMenu)}
          additionalActionButtons={
            clearBtn && (
              <Button color="primary" disabled={clearDisabled} onClick={onClear}>
                <FormattedMessage {...commonMessages.clearBtn} />
              </Button>
            )
          }
        >
          {renderBody ? renderBody() : children}
        </DialogComponent>
      </div>
    );
  };

  const renderHoverMenu = () => {
    return (
      <div {...bindHover(popupState)} className={className}>
        {renderMenuItem()}

        <HoverMenu
          {...bindMenu(popupState)}
          className={hoverMenuClassName}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          {renderBody ? renderBody(close) : children}
        </HoverMenu>
      </div>
    );
  };

  return dialogMenu ? renderDialogMenu() : renderHoverMenu();
};

HoverFilter.defaultProps = {
  dialogMenu: false,
};

export default React.memo(HoverFilter);
