import React, { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Box, Link, SvgIcon } from '@material-ui/core';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from 'common/state/notifications/actions';
import { ServerError } from 'common/errors/serverErrors';
import { DeviceType } from 'common/constants/scanner';
import commonMessages from 'common/messages/messages';
import { Button } from 'common/components';
import { ReactComponent as MagneticStripeIcon } from 'img/icons/magnetic-stripe.svg';
import { CustomTheme } from 'common/ui/interfaces';
import WebHidApi from 'services/webHidApi';
import { ReactComponent as RevenueCodeIcon } from 'img/icons/revenue-code.svg';
import { BarcodeScanningModal } from 'common/modals';
import { ICustomDeviceActionButtonProps } from 'common/interfaces/webHid';

interface IScannerProps {
  onScanningSuccess?: (scannerString: string) => void;
  deviceType: DeviceType;
  messageDescriptor?: MessageDescriptor;
  CustomButton?: React.FunctionComponent<ICustomDeviceActionButtonProps>;
  disabled?: boolean;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  magneticStripeIcon: {
    marginRight: theme.spacing(1),
  },
  barcodeButton: {
    fontSize: '14px',

    '& svg': {
      marginRight: '6px',
    },
  },
}));

const { hid } = navigator as Navigator & HID;

const { BarcodeScanner, MagneticStripeScanner, CashDrawer } = WebHidApi;

const DeviceActionButton = memo((props: IScannerProps) => {
  const { onScanningSuccess, deviceType, messageDescriptor, CustomButton, disabled } = props;

  const [isOpenModal, setIsOpenModal] = useState(false);

  const dispatch = useDispatch();

  const [scannerStr, setScannerStr] = useState<string>();

  const classes = useStyles();

  useEffect(() => {
    const onOpenBarcodeScanner = () => {
      dispatch(enqueueSuccessNotification(commonMessages.barcodeScannerConnected));
    };

    const onOpenCashDrawer = () => {
      dispatch(enqueueSuccessNotification(commonMessages.cashDrawerConnected));
    };

    const onOpenMagneticStripeScanner = () => {
      dispatch(enqueueSuccessNotification(commonMessages.magneticStripeConnected));
    };

    const onOpenDeviceError = () => {
      dispatch(
        enqueueErrorNotification({
          codes: [ServerError.DEVICE_CONNECTION_ERROR],
          message: undefined,
        }),
      );
    };

    const onMagneticStripeScannerString = (scannerString: string) => {
      setScannerStr(scannerString);
    };

    if (deviceType === DeviceType.Barcode) {
      BarcodeScanner.subscribeOnOpenBarcodeScanner(onOpenBarcodeScanner);
      BarcodeScanner.subscribeOnOpenBarcodeScannerError(onOpenDeviceError);
    }

    if (deviceType === DeviceType.CashDrawer) {
      CashDrawer.subscribeOnOpenCashDrawer(onOpenBarcodeScanner);
      CashDrawer.subscribeOnOpenCashDrawerError(onOpenDeviceError);
    }

    if (deviceType === DeviceType.MagneticStripe) {
      MagneticStripeScanner.subscribeOnOpenMagneticStripeScanner(onOpenMagneticStripeScanner);
      MagneticStripeScanner.subscribeOnOpenMagneticStripeScannerError(onOpenDeviceError);
      MagneticStripeScanner.subscribeOnScannerString(onMagneticStripeScannerString);
    }

    return () => {
      if (deviceType === DeviceType.Barcode) {
        BarcodeScanner.unsubscribeOnOpenBarcodeScanner(onOpenBarcodeScanner);
        BarcodeScanner.unsubscribeOnOpenBarcodeScannerError(onOpenDeviceError);
      }

      if (deviceType === DeviceType.CashDrawer) {
        CashDrawer.unsubscribeOnOpenCashDrawer(onOpenCashDrawer);
        CashDrawer.unsubscribeOnOpenOpenCashDrawerError(onOpenDeviceError);
      }

      if (deviceType === DeviceType.MagneticStripe) {
        MagneticStripeScanner.unsubscribeOnOpenMagneticStripeScanner(onOpenMagneticStripeScanner);
        MagneticStripeScanner.unsubscribeOnOpenMagneticStripeScannerError(onOpenDeviceError);
        MagneticStripeScanner.unsubscribeOnScannerString(onMagneticStripeScannerString);
      }
    };
  }, [dispatch, deviceType, setScannerStr]);

  useEffect(() => {
    if (scannerStr && onScanningSuccess) {
      onScanningSuccess(scannerStr);
      setScannerStr(undefined);
    }
  }, [scannerStr, onScanningSuccess, setScannerStr]);

  const requestDevices = () => {
    switch (deviceType) {
      case DeviceType.Barcode:
        BarcodeScanner.init();
        break;
      case DeviceType.MagneticStripe:
        MagneticStripeScanner.init();
        break;
      default: {
        CashDrawer.init();
      }
    }
  };

  const isAllScannersAreOpen = () => {
    switch (deviceType) {
      case DeviceType.Barcode:
        return BarcodeScanner.getIsAllScannersAreOpen;
      case DeviceType.MagneticStripe:
        return MagneticStripeScanner.getIsAllScannersAreOpen;
      default: {
        return CashDrawer.getIsAllScannersAreOpen;
      }
    }
  };

  const doDeviceOperation = () => {
    switch (deviceType) {
      case DeviceType.Barcode:
        setIsOpenModal(true);
        break;
      case DeviceType.CashDrawer:
        CashDrawer.openCashDrawer();
        break;
      default:
    }
  };

  const onClick = () => {
    if (hid) {
      if (isAllScannersAreOpen()) {
        doDeviceOperation();
      } else {
        requestDevices();
      }
    } else {
      dispatch(
        enqueueErrorNotification({
          codes: [ServerError.BROWSER_NOT_SUPPORT_HID_API],
          message: undefined,
        }),
      );
    }
  };

  const defaultButton =
    deviceType === DeviceType.Barcode ? (
      <Link
        className={classes.barcodeButton}
        component="button"
        underline="none"
        onClick={onClick}
        disabled={disabled}
        type="button"
      >
        <Box whiteSpace="nowrap" display="flex" alignItems="center" justifyContent="space-between">
          <RevenueCodeIcon /> {messageDescriptor && <FormattedMessage {...messageDescriptor} />}
        </Box>
      </Link>
    ) : (
      <Button
        type="button"
        onClick={onClick}
        color="primary"
        className={classes.magneticStripeIcon}
        startIcon={<SvgIcon component={MagneticStripeIcon} />}
        disabled={disabled}
      >
        <FormattedMessage {...commonMessages.scanFromMagneticStripeBtn} />
      </Button>
    );

  return (
    <>
      {CustomButton ? <CustomButton onClick={onClick} disabled={disabled} /> : defaultButton}
      {isOpenModal && (
        <BarcodeScanningModal
          isOpen={isOpenModal}
          setScannerStr={setScannerStr}
          setIsOpenModal={setIsOpenModal}
        />
      )}
    </>
  );
});

export default DeviceActionButton;
