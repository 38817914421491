import axios from 'axios';

import ApiService from './ApiService';

import { IFiltersWithSearch, ITableParams } from 'common/interfaces/table';
import {
  ICashSummary,
  IInvoicesSummary,
  IPaymentSummary,
  IRegistersSummary,
  IRevenueSummary,
  ISalesPOSSummary,
  ISalesSummary,
  IServiceSummary,
  ITaxSummary,
} from 'modules/reports/interfaces/salesPOS';
import {
  IAdjustInvoiceData,
  IInventoryDetailedInfo,
  IInvoiceHistoryStatistics,
  IInvoiceItem,
  IPaginatedInvoiceHistory,
  ITransactionItem,
} from 'modules/reports/interfaces/invoiceHistory';
import {
  ICampaignItem,
  IPaginatedCampaigns,
  IPaginatedRecipients,
} from 'modules/reports/interfaces/campaignsReport';
import {
  IPaginatedZOutReport,
  IPaymentTypeTotals,
  IZOutReportListItem,
  IZOutReportStatistics,
} from 'modules/reports/interfaces/zOutReport';
import { IPaginatedAppointments } from 'modules/reports/interfaces/appointmentsReport';
import { IBookingEvent } from 'modules/booking/interfaces';
import { IPaginatedPaymentTransactions } from 'modules/reports/interfaces/paymentTransactionsReport';
import { IPaginatedRegisterStatus } from 'modules/reports/interfaces/registerStatusReport';
import {
  IBulkUnredeemDto,
  IPaginatedUsedSessions,
  IRedeemTotalDto,
  IUnredeemDto,
  IUsedSessionsListItem,
} from 'modules/reports/interfaces/usedSessionsReport';
import {
  IOverTimeActivityItem,
  IOverTimeStatisticsItem,
} from 'modules/reports/interfaces/salesOverTime';
import {
  IAppointmentSimpleDto,
  IBacklog,
  IConversionRateItemImt,
  ICrmActivity,
  ICrmDashboardStats,
  IMembershipsSoldItemImt,
} from 'modules/reports/interfaces/crmDashboard';
import {
  IMemberCountReportDTO,
  IMembershipMixReportDTO,
  ISalesDrivenReportDTO,
  ITotalOverviewStatistics,
} from 'modules/reports/interfaces/reportsOverview';
import { IFilter } from 'common/interfaces/filter';
import { ISuccessResponse } from 'common/interfaces/http';
import { invoiceHistorySnapshots, salesPOSReport } from 'server/mocks/reports';
import {
  ICloseRegisterInvoices,
  IPosKioskReconcileDTO,
  IPosKioskRegisterTotals,
} from 'modules/pos-kiosk/interfaces/registers';
import { ICustomerShort, IPrimaryMemberInfo } from 'common/components/PersonProfile/interfaces';
import { IBundleView } from 'modules/services/interfaces/packages';
import {
  IScheduledBillingActivity,
  IScheduledBillingItem,
  ITotalScheduledBillingsStatistics,
} from 'modules/reports/interfaces/scheduledBillingsReport';
import { PeriodFilterType } from 'modules/reports/interfaces/common';
import { IPaginatedData } from 'common/interfaces/pagination';
import {
  ICheckInHistoryActivityDto,
  ICheckInHistoryReportItem,
  ICheckinHistoryStatisticsDto,
} from 'modules/reports/interfaces/checkInHistory';
import {
  IKidzoneCheckInHistoryActivityDto,
  IKidzoneCheckInHistoryReportItem,
  IKidzoneCheckinHistoryStatisticsDto,
} from 'modules/reports/interfaces/kidzoneCheckInHistory';

export default class ReportsService extends ApiService {
  public getAppointments = (tableParams?: ITableParams): Promise<IPaginatedAppointments> => {
    const searchParams = this.getSearchFromTableParams(tableParams);

    return axios.get('/api/v1/reports/appointment', { params: searchParams });
  };

  public exportAppointmentsReport = (
    exportData: Record<string, any>,
    params: ITableParams,
  ): Promise<{ success: true }> => {
    return new Promise(resolve => setTimeout(() => resolve({ success: true }), 500));
  };

  public getAppointmentById = (eventId: string, eventDate: string): Promise<IBookingEvent> =>
    axios.get(`/api/v1/reports/appointment/${eventId}`, {
      params: { eventDate },
    });

  public getInvoiceHistory = (requestOptions?: ITableParams): Promise<IPaginatedInvoiceHistory> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get(`api/v1/reports/invoice`, { params: searchParams });
  };

  public getInvoiceById = (invoiceId: string): Promise<IInvoiceItem> => {
    return axios.get(`api/v1/reports/invoice/${invoiceId}`);
  };

  public getInvoiceInventoryInfo = (
    invoiceId: string,
    inventoryId: string,
  ): Promise<IInventoryDetailedInfo> =>
    axios.get(`/api/v1/reports/invoice/${invoiceId}/inventories/${inventoryId}`);

  public getInvoicePackageInfo = (invoiceId: string, packageId: string): Promise<IBundleView> =>
    axios.get(`/api/v1/reports/invoice/${invoiceId}/packages/${packageId}`);

  public getInvoiceTransactions = (invoiceId: string): Promise<IPaginatedData<ITransactionItem>> =>
    axios.get(`/api/v1/reports/invoice/${invoiceId}/transactions`);

  public getInvoicePackageInventoryInfo = (
    invoiceId: string,
    inventoryId: string,
  ): Promise<IInventoryDetailedInfo> =>
    axios.get(`/api/v1/reports/invoice/${invoiceId}/packages/inventories/${inventoryId}`);

  public getInvoiceReportSnapshots = (requestOptions?: ITableParams) => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return new Promise(resolve =>
      setTimeout(() => resolve({ data: invoiceHistorySnapshots.data }), 500),
    );
  };

  public getInvoiceHistoryReportStatistics = (
    requestOptions?: IFiltersWithSearch,
  ): Promise<IInvoiceHistoryStatistics> => {
    const { filters, search } = requestOptions;
    const searchParams = this.getSearchFromFilters(filters);
    const paramsWithSearch = search.length === 0 ? searchParams : { ...searchParams, search };
    return axios.get('api/v1/reports/invoice/statistics', {
      params: paramsWithSearch,
    });
  };

  public voidInvoice = (invoiceId: string, reason: string): Promise<void> =>
    axios.put(`/api/v1/reports/invoice/${invoiceId}/void`, { reason });

  public adjustInvoice = (invoiceId: string, data: IAdjustInvoiceData): Promise<ISuccessResponse> =>
    axios.put(`/api/v1/reports/invoice/${invoiceId}/total`, data);

  public assignCustomerToInvoice = (invoiceId: string, customerId: string): Promise<void> => {
    return axios.put(`api/v1/reports/invoice/${invoiceId}/customer?customerId=${customerId}`);
  };

  public searchMembers = (searchStr: string): Promise<ICustomerShort[]> => {
    return axios.get('/api/v1/reports/customers', { params: { search: searchStr } });
  };

  public createMember = (data: IPrimaryMemberInfo): Promise<IPrimaryMemberInfo> => {
    return axios.post('api/v1/reports/customers', data);
  };

  public exportInvoice = (
    exportData: Record<string, any>,
    params: ITableParams,
  ): Promise<ISuccessResponse> => {
    return new Promise(resolve => setTimeout(() => resolve({ success: true }), 500));
  };

  public getCrmDashboardStatistics = (
    requestOptions?: ITableParams,
  ): Promise<ICrmDashboardStats> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get(`api/v1/reports/dashboard/statistics`, {
      params: searchParams,
    });
  };

  public getCrmAppointments = (
    requestOptions?: ITableParams,
  ): Promise<IPaginatedData<IAppointmentSimpleDto>> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get(`api/v1/reports/dashboard/appointments`, {
      params: searchParams,
    });
  };

  public getCrmBacklog = (requestOptions?: ITableParams): Promise<IBacklog> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get(`api/v1/reports/dashboard/backlog`, { params: searchParams });
  };

  public getCrmActivity = (requestOptions?: ITableParams): Promise<ICrmActivity> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get(`api/v1/reports/dashboard/activity`, { params: searchParams });
  };

  public getMembershipSold = (requestOptions?: ITableParams): Promise<IMembershipsSoldItemImt> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get(`api/v1/reports/dashboard/membership-sold`, {
      params: searchParams,
    });
  };

  public getConversionValue = (requestOptions?: ITableParams): Promise<IConversionRateItemImt> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get(`api/v1/reports/dashboard/conversion-value`, {
      params: searchParams,
    });
  };

  public getSalesPOSSummary = (requestOptions?: ITableParams): Promise<ISalesPOSSummary> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get(`api/v1/reports/sales-pos/summary`, { params: searchParams });
  };

  public getCashSummary = (requestOptions?: ITableParams): Promise<ICashSummary> => {
    return new Promise(resolve => setTimeout(() => resolve(salesPOSReport.cashSummary), 500));
  };

  public getPaymentsSummary = (requestOptions?: ITableParams): Promise<IPaymentSummary> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get(`api/v1/reports/sales-pos/summary/payment`, {
      params: searchParams,
    });
  };

  public getServiceModeSummary = (requestOptions?: ITableParams): Promise<IServiceSummary> => {
    return new Promise(resolve => setTimeout(() => resolve(salesPOSReport.serviceSummary), 500));
  };

  public getInvoicesSummary = (requestOptions?: ITableParams): Promise<IInvoicesSummary> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get(`api/v1/reports/sales-pos/summary/invoice`, {
      params: searchParams,
    });
  };

  public getSalesCategorySummary = (requestOptions?: ITableParams): Promise<ISalesSummary> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get(`api/v1/reports/sales-pos/summary/category`, {
      params: searchParams,
    });
  };

  public getRegistersSummary = (requestOptions?: ITableParams): Promise<IRegistersSummary> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get(`api/v1/reports/sales-pos/summary/register`, {
      params: searchParams,
    });
  };

  public getTaxSummary = (requestOptions?: ITableParams): Promise<ITaxSummary> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get(`api/v1/reports/sales-pos/summary/tax`, {
      params: searchParams,
    });
  };

  public getRevenueSummary = (requestOptions?: ITableParams): Promise<IRevenueSummary> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get(`api/v1/reports/sales-pos/summary/revenue-code`, {
      params: searchParams,
    });
  };

  public getCampaigns = (requestOptions?: ITableParams): Promise<IPaginatedCampaigns> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get(`api/v1/reports/campaign`, { params: searchParams });
  };

  public getCampaignById = (id: string): Promise<ICampaignItem> => {
    return axios.get(`api/v1/reports/campaign/${id}`);
  };

  public getCampaignRecipients = (
    campaignId: string,
    requestOptions?: ITableParams,
  ): Promise<IPaginatedRecipients> => {
    return axios.get(`api/v1/reports/campaign/${campaignId}/recipients`, {
      params: requestOptions,
    });
  };

  public getSalesOverTimeStatistics = (
    tableParams?: ITableParams,
  ): Promise<IOverTimeStatisticsItem> => {
    const searchParams = this.getSearchFromTableParams(tableParams);
    return axios.get(`api/v1/reports/sales-over-time/statistics`, {
      params: searchParams,
    });
  };

  public getSalesOverTimeActivity = (filters?: IFilter[]): Promise<IOverTimeActivityItem> => {
    const searchParams = this.getSearchFromFilters(filters);
    return axios.get('api/v1/reports/sales-over-time/activity', {
      params: searchParams,
    });
  };

  public getPaymentTransactions = (
    requestOptions?: ITableParams,
  ): Promise<IPaginatedPaymentTransactions> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get(`api/v1/reports/transactions`, { params: searchParams });
  };

  public exportPaymentTransactionsReportList = (
    exportData: Record<string, any>,
    params: ITableParams,
  ): Promise<ISuccessResponse> => {
    return new Promise(resolve => setTimeout(() => resolve({ success: true }), 500));
  };

  public getZOutReportList = (requestOptions?: ITableParams): Promise<IPaginatedZOutReport> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);

    return axios.get(`api/v1/reports/z-report`, { params: searchParams });
  };

  public exportZOutReportList = (
    exportData: Record<string, any>,
    params: ITableParams,
  ): Promise<ISuccessResponse> => {
    return new Promise(resolve => setTimeout(() => resolve({ success: true }), 500));
  };

  public getStatisticsZOutReport = (requestFilters?: IFilter[]): Promise<IZOutReportStatistics> => {
    const searchParams = this.getSearchFromFilters(requestFilters);

    return axios.get('api/v1/reports/z-report/statistics', { params: searchParams });
  };

  public getZOutReport = (zOutReportId: string): Promise<IZOutReportListItem> => {
    return axios.get(`api/v1/reports/z-report/${zOutReportId}`);
  };

  public getZOutReportPayments = (zOutReportId: string): Promise<IPaymentTypeTotals[]> => {
    return axios.get(`api/v1/reports/z-report/${zOutReportId}/payments`);
  };

  public exportZOutReport = (
    exportData: Record<string, any>,
    id: string,
  ): Promise<ISuccessResponse> => {
    return new Promise(resolve => setTimeout(() => resolve({ success: true }), 500));
  };

  public exportCampaignsReport = (
    exportData: Record<string, any>,
    params: ITableParams,
  ): Promise<ISuccessResponse> => {
    return new Promise(resolve => setTimeout(() => resolve({ success: true }), 500));
  };

  public exportCampaign = (
    exportData: Record<string, any>,
    campaignId: string,
  ): Promise<ISuccessResponse> => {
    return new Promise(resolve => setTimeout(() => resolve({ success: true }), 500));
  };

  // Registers report
  public getRegisterStatusReports = (
    tableParams?: ITableParams,
  ): Promise<IPaginatedRegisterStatus> => {
    const searchParams = this.getSearchFromTableParams(tableParams);

    return axios.get('/api/v1/reports/register', { params: searchParams });
  };

  public getInvoicesCountFromRegister = (registerId: string): Promise<ICloseRegisterInvoices> => {
    return axios.get(`/api/v1/reports/register/${registerId}/close/invoices`);
  };

  public closeRegister = (registerId: string, data: IPosKioskReconcileDTO): Promise<string> =>
    axios.put(`/api/v1/reports/register/${registerId}/close`, data);

  public reconcileRegisterSales = (
    registerId: string,
    data: Partial<IPosKioskReconcileDTO>,
  ): Promise<void> => axios.put(`/api/v1/reports/register/${registerId}/reconcile-sales`, data);

  public getRegisterTotalSales = (
    registerId: string,
    cashAmount: number,
    checkAmount: number,
  ): Promise<IPosKioskRegisterTotals> =>
    axios.get(`/api/v1/reports/register/${registerId}/total-sales`, {
      params: { cashAmount, checkAmount },
    });

  public exportRegisterStatusReport = (
    exportData: Record<string, any>,
    params: ITableParams,
  ): Promise<ISuccessResponse> => {
    return new Promise(resolve => setTimeout(() => resolve({ success: true }), 500));
  };

  public getUsedSessions = (requestOptions?: ITableParams): Promise<IPaginatedUsedSessions> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get('/api/v1/reports/redemptions', { params: searchParams });
  };

  public unredeemUsedSession = (id: string, data: IUnredeemDto): Promise<IUsedSessionsListItem> => {
    return axios.put(`/api/v1/reports/redemptions/${id}/unredeem`, data);
  };

  public unredeemUsedSessions = (data: IBulkUnredeemDto): Promise<void> => {
    return axios.put('/api/v1/reports/redemptions/bulk-unredeem', data);
  };

  public fetchUsedSessionsTotals = (requestOptions?: ITableParams): Promise<IRedeemTotalDto> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get('api/v1/reports/redemptions/totals', { params: searchParams });
  };

  public exportSalesPOSReport = (
    exportData: Record<string, any>,
    params: ITableParams,
  ): Promise<ISuccessResponse> => {
    return new Promise(resolve => setTimeout(() => resolve({ success: true }), 500));
  };

  public getSalesDrivenReportData = (
    filters: IFilter[],
    period: PeriodFilterType,
  ): Promise<ISalesDrivenReportDTO> => {
    const searchParams = this.getSearchFromFilters(filters);

    return axios.get('/api/v1/reports/overview/sales-driven', {
      params: { ...searchParams, period },
    });
  };

  public getMemberCountReportData = (
    filters: IFilter[],
    period: PeriodFilterType,
  ): Promise<IMemberCountReportDTO> => {
    const searchParams = this.getSearchFromFilters(filters);

    return axios.get('/api/v1/reports/overview/member-count', {
      params: { ...searchParams, period },
    });
  };

  public getMembershipMixReportData = (
    filters: IFilter[],
    period: PeriodFilterType,
  ): Promise<IMembershipMixReportDTO> => {
    const searchParams = this.getSearchFromFilters(filters);

    return axios.get('/api/v1/reports/overview/membership-mix', {
      params: { ...searchParams, period },
    });
  };

  public getTotalOverviewStatistics = (filters: IFilter[]): Promise<ITotalOverviewStatistics> => {
    const searchParams = this.getSearchFromFilters(filters);

    return axios.get('/api/v1/reports/overview', { params: searchParams });
  };

  // Scheduled billings

  public getTotalScheduledBillingsStatistics = (
    tableParams: ITableParams,
    period: PeriodFilterType,
  ): Promise<ITotalScheduledBillingsStatistics> => {
    const searchParams = this.getSearchFromTableParams(tableParams, true);

    return axios.get('/api/v1/reports/billing/statistics', { params: { ...searchParams, period } });
  };

  public getScheduledBillingsActivityReportData = (
    tableParams: ITableParams,
    period: PeriodFilterType,
  ): Promise<IScheduledBillingActivity> => {
    const searchParams = this.getSearchFromTableParams(tableParams, true);

    return axios.get('/api/v1/reports/billing/activity', { params: { ...searchParams, period } });
  };

  public getScheduledBillingReportItems = (
    tableParams: ITableParams,
    period: PeriodFilterType,
  ): Promise<IPaginatedData<IScheduledBillingItem>> => {
    const searchParams = this.getSearchFromTableParams(tableParams);

    return axios.get('/api/v1/reports/billing', { params: { ...searchParams, period } });
  };

  // check in history

  public getCheckInHistoryReportItems = (
    tableParams: ITableParams,
    period: PeriodFilterType,
  ): Promise<IPaginatedData<ICheckInHistoryReportItem>> => {
    const searchParams = this.getSearchFromTableParams(tableParams);

    return axios.get('/api/v1/reports/checkin/front-desk', { params: { ...searchParams, period } });
  };

  public getCheckInHistoryActivity = (
    tableParams: ITableParams,
    period: PeriodFilterType,
  ): Promise<ICheckInHistoryActivityDto> => {
    const searchParams = this.getSearchFromTableParams(tableParams, true);

    return axios.get('/api/v1/reports/checkin/front-desk/activity', {
      params: { ...searchParams, period },
    });
  };

  public getCheckInHistoryStatistics = (
    tableParams: ITableParams,
    period: PeriodFilterType,
  ): Promise<ICheckinHistoryStatisticsDto> => {
    const searchParams = this.getSearchFromTableParams(tableParams, true);

    return axios.get('/api/v1/reports/checkin/front-desk/statistics', {
      params: { ...searchParams, period },
    });
  };

  // kidzone check in history

  public getKidzoneCheckInHistoryReportItems = (
    tableParams: ITableParams,
    period: PeriodFilterType,
  ): Promise<IPaginatedData<IKidzoneCheckInHistoryReportItem>> => {
    const searchParams = this.getSearchFromTableParams(tableParams);

    return axios.get('/api/v1/reports/checkin/kid-zone', { params: { ...searchParams, period } });
  };

  public getKidzoneCheckInHistoryActivity = (
    tableParams: ITableParams,
    period: PeriodFilterType,
  ): Promise<IKidzoneCheckInHistoryActivityDto> => {
    const searchParams = this.getSearchFromTableParams(tableParams, true);

    return axios.get('/api/v1/reports/checkin/kid-zone/activity', {
      params: { ...searchParams, period },
    });
  };

  public getKidzoneCheckInHistoryStatistics = (
    tableParams: ITableParams,
    period: PeriodFilterType,
  ): Promise<IKidzoneCheckinHistoryStatisticsDto> => {
    const searchParams = this.getSearchFromTableParams(tableParams, true);

    return axios.get('/api/v1/reports/checkin/kid-zone/statistics', {
      params: { ...searchParams, period },
    });
  };

  public getInvoiceToPrint = (invoiceId: string): Promise<string> => {
    return axios.get(`api/v1/reports/invoice/${invoiceId}/receipt`);
  };
}
