// libraries
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// custom interfaces
import { ChangeStatusModal } from 'common/components';
import { IFeeImt } from '../../../interfaces/fees';
// hooks
import { useAppDispatch } from 'store/hooks';
// state
import * as actions from '../../../state/fees/actions';
import * as selectors from '../../../state/fees/selectors';

interface IFeeChangeStatusModalProps {
  fee: IFeeImt;

  onDone: () => void;
}

const FeeChangeStatusModal: React.FC<IFeeChangeStatusModalProps> = (
  props: IFeeChangeStatusModalProps,
): JSX.Element => {
  const { fee, onDone } = props;

  // local state

  const [isOpen, setIsOpen] = useState(false);

  // state
  const dispatch = useAppDispatch();

  const changeStatusResult = useSelector(selectors.selectFeeActionResult);
  const changeDataLoading = useSelector(selectors.selectFeeActionLoading);

  // handlers

  const handleOnSubmit = () => dispatch(actions.changeFeeStatus(fee.get('id'), !fee.get('active')));

  // effects

  useEffect(() => {
    setIsOpen(!!fee);

    return () => {
      dispatch(actions.resetChangeFeeStatus());
    };
  }, [dispatch, fee]);

  useEffect(() => {
    if (changeStatusResult && changeStatusResult.get('id')) {
      if (onDone) onDone();
    }
  }, [changeStatusResult, dispatch, onDone]);

  // renders

  return (
    !!fee && (
      <ChangeStatusModal
        isOpen={isOpen}
        itemTitle={fee.get('title')}
        isActive={fee.get('active')}
        isLoading={changeDataLoading}
        onSubmit={handleOnSubmit}
        onClose={() => setIsOpen(false)}
      />
    )
  );
};

export default React.memo(FeeChangeStatusModal);
