import React from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    zIndex: 10,
    backgroundColor: 'rgba(0, 0, 0, 0.09)',
    overflow: 'hidden',
  },
  hidden: {
    // for safari scrolls
    zIndex: -1,
  },
}));

interface IProps {
  extraClassName?: string;
  isLoading?: boolean;
}

const LoadingBackdrop = ({ isLoading = false, extraClassName }: IProps): JSX.Element => {
  const classes = useStyles();
  return (
    <Backdrop
      className={cx(extraClassName, classes.root, { [classes.hidden]: !isLoading })}
      open={isLoading}
    >
      <CircularProgress color="primary" />
    </Backdrop>
  );
};

export default React.memo(LoadingBackdrop);
