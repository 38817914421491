import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, CardContent, FormControlLabel, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
// components
import {
  AvailabilityScheduleSelector,
  Checkbox,
  DateRangeSelector,
  DateTimePicker,
  MinMaxNumberInput,
  NumberTextField,
  Select,
} from 'common/components';
import CheckBoxWithReset from 'common/components/CheckBoxWithReset/CheckBoxWithReset';
import { StatusTypes } from 'common/constants/defaultOptions';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import ClubsSelector from './ClubsSelector';
import EmployeesSelector from './EmployeesSelector';
// hooks
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import { useAppDispatch } from 'store/hooks';
// constants
import {
  AvailabilityType,
  AvailabilityTypes,
  DurationType,
  DurationTypes,
  PackageCostTypes,
  PackageType,
  PackageTypes,
} from 'modules/services/constants/packages';
import { DictionaryList } from 'common/constants';
import { ServicesSubModules } from 'common/constants/peakModules';
// actions
import { fetchDictionaryList } from 'common/state/dictionary/actions';
// interfaces
import { IFormStepStyle, IGeneralSection } from 'modules/services/interfaces/packages';
// messages
import messages from 'modules/services/messages/messages';
import inputLabels from 'common/messages/inputLabels';
import commonMessages from 'common/messages/messages';
import packageMessages from 'modules/services/messages/packages';
import { defaultNumberFormatProps } from 'common/components/NumberController/NumberController';

const useStyles = makeStyles((theme: Theme) => ({
  contentWrapper: {
    flex: 1,
    minHeight: 0,
    overflow: 'hidden',
  },
  hidden: {
    display: 'none',
  },
  cardContent: {
    padding: ({ smallPaddingContent }: IFormStepStyle) =>
      smallPaddingContent ? theme.spacing(3, 0, 1) : theme.spacing(1, 2),
    '&:last-child': {
      padding: ({ smallPaddingContent }: IFormStepStyle) =>
        smallPaddingContent ? theme.spacing(3, 0, 1) : theme.spacing(1, 2),
    },
  },
  mainCheckboxLabel: {
    fontWeight: 500,
  },
}));

interface IGeneralInfoStepFormProps extends IFormStepStyle {
  multi?: boolean;
  defaultValues?: IGeneralSection;
}

const GeneralInfoStepForm = ({
  smallPaddingContent,
  multi = false,
  defaultValues,
}: IGeneralInfoStepFormProps): JSX.Element => {
  const classes = useStyles({ smallPaddingContent });
  const renderIntlMessage = useRenderIntlMessage();
  const [timezoneMoment] = useTimezoneMoment();
  const dispatch = useAppDispatch();

  // form

  const { control, errors, watch, trigger, clearErrors } = useFormContext();

  const durationType = watch('durationType');
  const type = watch('type');
  const availabilityType = watch('availabilityType');

  watch('durationEditableNumber.editable');

  useEffect(() => {
    dispatch(fetchDictionaryList(DictionaryList.CLUBS, ServicesSubModules.ServicePackages));
  }, [dispatch]);

  const startTimePickerName = 'startTime';
  const startDatePickerName = 'startDate';
  const endTimePickerName = 'endTime';
  const endDatePickerName = 'endDate';

  // renders

  const isForRangeType = availabilityType === AvailabilityType.ForRange;
  const isStartingOnDateType = availabilityType === AvailabilityType.StartingOnDate;

  const startPickerProps = {
    onChange: isForRangeType
      ? () => {
          trigger(endTimePickerName);
          trigger(endDatePickerName);
        }
      : null,
  };

  const endPickerProps = {
    onChange:
      isStartingOnDateType || isForRangeType
        ? () => {
            trigger(startTimePickerName);
            trigger(startDatePickerName);
          }
        : null,
  };

  const onChangeAvailabilityType = (val: AvailabilityType) => {
    if (val === AvailabilityType.StartingOnDate) {
      /*
       * When you change the 'AvailabilityType', the validation error does not disappear.
       * Added manual error cleanup
       * */
      if (errors.startTime) {
        clearErrors(startTimePickerName);
      }

      if (errors.startDate) {
        clearErrors(startDatePickerName);
      }
    }
  };

  return (
    <Box className={classes.contentWrapper}>
      <CardContent className={classes.cardContent}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Controller
              control={control}
              name="title"
              defaultValue={defaultValues?.title}
              render={({ value, onChange }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  label={<FormattedMessage {...messages.packageNameLabel} />}
                  variant="outlined"
                  autoComplete="off"
                  fullWidth
                  error={!!errors.title?.message}
                  helperText={renderIntlMessage(errors.title?.message)}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="active"
              control={control}
              defaultValue={defaultValues?.active}
              render={({ name, value, onChange }) => (
                <Select
                  fullWidth
                  label={<FormattedMessage {...commonMessages.statusTitle} />}
                  variant="outlined"
                  name={name}
                  value={value}
                  onChange={e => onChange(e === 'true')}
                  error={!!errors.active?.message}
                  helperText={renderIntlMessage(errors.active?.message)}
                >
                  {StatusTypes.getSelectOptions()}
                </Select>
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item sm={4} xs={6}>
                <Controller
                  control={control}
                  name="type"
                  defaultValue={defaultValues?.type}
                  render={({ name, value, onChange, onBlur }) => (
                    <Select
                      fullWidth
                      label={<FormattedMessage {...messages.packageTypeLabel} />}
                      variant="outlined"
                      name={name}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                    >
                      {PackageTypes.getSelectOptions()}
                    </Select>
                  )}
                />
              </Grid>
              <Grid item sm={4} xs={6}>
                <Controller
                  control={control}
                  name="durationType"
                  defaultValue={defaultValues?.durationType}
                  render={({ name, value, onChange, onBlur }) => (
                    <Select
                      fullWidth
                      variant="outlined"
                      label={<FormattedMessage {...messages.packageDurationLabel} />}
                      name={name}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={!!errors.durationType?.message}
                      helperText={renderIntlMessage(errors.durationType?.message)}
                    >
                      {DurationTypes.getSelectOptions().slice(3)}
                    </Select>
                  )}
                />
              </Grid>
              {durationType === DurationType.Custom ? (
                <Grid item sm={4} xs={12}>
                  <Controller
                    control={control}
                    name="customDates"
                    defaultValue={{
                      startDate: timezoneMoment().add(1, 'd'),
                      endDate: timezoneMoment().add(1, 'month'),
                    }}
                    render={({ name, value, onChange }) => (
                      <DateRangeSelector
                        name={name}
                        value={value}
                        onChange={onChange}
                        error={!!errors.customDates}
                        helperText={renderIntlMessage(
                          errors.customDates?.message ||
                            errors.customDates?.startDate?.message ||
                            errors.customDates?.endDate?.message,
                        )}
                      />
                    )}
                  />
                </Grid>
              ) : (
                <Grid item sm={4} xs={12}>
                  <MinMaxNumberInput
                    control={control}
                    errors={errors}
                    trigger={trigger}
                    baseName="durationEditableNumber"
                    label={<FormattedMessage {...messages.amountLabel} />}
                    baseDefaultValue={defaultValues?.durationEditableNumber}
                  />
                </Grid>
              )}
              {type === PackageType.Trial && (
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Controller
                        control={control}
                        name="costType"
                        render={({ name, value, onChange, onBlur }) => (
                          <Select
                            fullWidth
                            variant="outlined"
                            label={<FormattedMessage {...messages.packageCostTypeLabel} />}
                            name={name}
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            error={!!errors.costType?.message}
                            helperText={renderIntlMessage(errors.costType?.message)}
                          >
                            {PackageCostTypes.getSelectOptions()}
                          </Select>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {PackageType.Trial !== type && (
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="renewalNumber"
                    render={({ value, onChange }) => (
                      <NumberTextField
                        numberFormatProps={defaultNumberFormatProps}
                        variant="outlined"
                        label={<FormattedMessage {...messages.renewalsLabel} />}
                        value={value}
                        onChange={onChange}
                        error={!!errors.renewalNumber}
                        helperText={renderIntlMessage(errors.renewalNumber?.message)}
                      />
                    )}
                  />
                </Grid>
              )}

              <Grid item xs={4}>
                <Controller
                  control={control}
                  name="availabilityType"
                  defaultValue={defaultValues?.availabilityType}
                  render={({ name, value, onChange, onBlur }) => (
                    <Select
                      fullWidth
                      variant="outlined"
                      label={<FormattedMessage {...messages.availabilityType} />}
                      name={name}
                      value={value}
                      onChange={(val: AvailabilityType) => {
                        onChange(val);
                        onChangeAvailabilityType(val);
                      }}
                      onBlur={onBlur}
                      error={!!errors.availabilityType?.message}
                      helperText={renderIntlMessage(errors.availabilityType?.message)}
                    >
                      {AvailabilityTypes.getSelectOptions()}
                    </Select>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {(isStartingOnDateType || isForRangeType) && (
                    <Grid item xs={6}>
                      <DateTimePicker
                        timePickerName={startTimePickerName}
                        datePickerName={startDatePickerName}
                        dateLabelMessageDescriptor={inputLabels.startDate}
                        timeLabelMessageDescriptor={inputLabels.startTime}
                        timePickerProps={startPickerProps}
                        datePickerProps={startPickerProps}
                      />
                    </Grid>
                  )}
                  {isForRangeType && (
                    <Grid item xs={6}>
                      <DateTimePicker
                        timePickerName={endTimePickerName}
                        datePickerName={endDatePickerName}
                        timePickerProps={endPickerProps}
                        datePickerProps={endPickerProps}
                        dateLabelMessageDescriptor={inputLabels.endDate}
                        timeLabelMessageDescriptor={inputLabels.endTime}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="membership"
                  variant="outlined"
                  defaultValue={defaultValues?.membership}
                  render={({ onChange, value }) => (
                    <FormControlLabel
                      label={
                        <Typography className={classes.mainCheckboxLabel}>
                          <FormattedMessage {...messages.membershipPackageLabel} />
                        </Typography>
                      }
                      control={
                        <Checkbox
                          size="small"
                          checked={value}
                          onChange={e => onChange(e.target.checked)}
                        />
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="allowMembersToPurchaseOnline"
                  variant="outlined"
                  render={({ onChange, value }) => (
                    <CheckBoxWithReset
                      value={value}
                      onChange={onChange}
                      label={
                        <Typography className={classes.mainCheckboxLabel}>
                          <FormattedMessage {...messages.allowMembersToPurchaseOnlineLabel} />
                        </Typography>
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="dayTimeAvailDtoList"
                  render={({ onChange }) => (
                    <AvailabilityScheduleSelector
                      defaultValue={defaultValues?.dayTimeAvailDtoList}
                      defaultOptionLabel={<FormattedMessage {...inputLabels.accessAsClub} />}
                      onChange={onChange}
                    />
                  )}
                />
              </Grid>

              <EmployeesSelector control={control} />

              <ClubsSelector
                control={control}
                fieldName="sellerClubs"
                label={<FormattedMessage {...packageMessages.limitSellerClubs} />}
              />
              <ClubsSelector
                control={control}
                fieldName="accessClubs"
                label={<FormattedMessage {...packageMessages.limitClubs} />}
              />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Box>
  );
};

export default React.memo(GeneralInfoStepForm);
