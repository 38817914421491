import { createAction } from '@reduxjs/toolkit';
import { batch } from 'react-redux';

import Services from 'services/network';
import { actionTypes } from 'modules/timeclock/constants';

import { GeneralThunkAction } from 'common/state/interfaces';
import { ActionResult } from 'common/constants';
import { ITableParams } from 'common/interfaces/table';
import { ITimeClockEmployeeJobPosition } from 'common/interfaces/employee';
import { enqueueErrorNotification } from 'common/state/notifications/actions';
import {
  IAddClockInManuallyData,
  IClockedInEmployee,
  IClockInParams,
  IPaginatedClockInData,
  IPaginatedMyPayrollReportList,
  IPaginatedPayrollReportList,
  IPaginatedTimeclockList,
  IPaginatedUnitData,
  IPayrollReportActivityDTO,
  IPayrollStatistics,
  ITimeclockEventsRequestData,
  ITimeclockListItem,
} from '../interfaces/timeclock';
import { IUnlockCredentials } from 'modules/authentication/interfaces';
import { IBookingEvent } from 'modules/booking/interfaces';
import { IPaginatedData } from 'common/interfaces/pagination';
import { IPaginatedPayPeriods, IPeriodItem } from 'modules/corporations/interfaces';
import { INamedEntity } from 'common/interfaces/common';
import { IServerError } from 'common/interfaces/http';
import { getCurrentPayPeriod, getNewTableParams } from 'modules/timeclock/utils/filters';
import { IFilter } from 'common/interfaces/filter';
import { PeriodFilterType } from 'modules/reports/interfaces/common';

export const setCurrentPayrollPayPeriod = createAction<IPeriodItem | null>(
  actionTypes.SET_CURRENT_PAYROLL_PAY_PERIOD,
);

export const getSelectedEmployeePayrollReport = createAction<INamedEntity>(
  actionTypes.GET_SELECTED_EMPLOYEE_PAYROLL_REPORT,
);
const getAllTimeclockListAction = createAction<IPaginatedTimeclockList>(
  actionTypes.GET_TIMECLOCK_LIST_SUCCESS,
);
const getAllTimeclockListLoading = createAction<boolean>(actionTypes.GET_TIMECLOCK_LIST_LOADING);
const getAllTimeclockListError = createAction<boolean>(actionTypes.GET_TIMECLOCK_LIST_ERROR);

export const getAllTimeclockList = (requestOptions?: ITableParams): GeneralThunkAction => {
  return async dispatch => {
    dispatch(getAllTimeclockListLoading(true));

    try {
      const timeclockData: IPaginatedTimeclockList = await Services.Timeclock.getAllTimeclock(
        requestOptions,
      );

      dispatch(getAllTimeclockListAction(timeclockData));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(getAllTimeclockListLoading(false));
    }
  };
};

export const deleteTimeclockByIdAction = createAction<ActionResult>(
  actionTypes.DELETE_TIMECLOCK_BY_ID_SUCCESS,
);
const deleteTimeclockByIdLoading = createAction<boolean>(
  actionTypes.DELETE_TIMECLOCK_BY_ID_LOADING,
);

export const deleteTimeclockById = (timeclockId: string): GeneralThunkAction => {
  return async dispatch => {
    dispatch(deleteTimeclockByIdLoading(true));

    try {
      await Services.Timeclock.deleteTimeclockById(timeclockId);

      dispatch(deleteTimeclockByIdAction(ActionResult.SUCCESS_ACTION));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(deleteTimeclockByIdLoading(false));
    }
  };
};

const getEmployeeTimeClockJobPositionsAction = createAction<ITimeClockEmployeeJobPosition[]>(
  actionTypes.GET_EMPLOYEE_TIMECLOCK_JOB_POSITIONS_SUCCESS,
);
const getEmployeeTimeClockJobPositionsLoading = createAction<boolean>(
  actionTypes.GET_EMPLOYEE_TIMECLOCK_JOB_POSITIONS_LOADING,
);
const getEmployeeTimeClockJobPositionsError = createAction<boolean>(
  actionTypes.GET_EMPLOYEE_TIMECLOCK_JOB_POSITIONS_ERROR,
);

export const getEmployeeTimeclockJobPositions = (employeeId: string): GeneralThunkAction => {
  return async dispatch => {
    dispatch(getEmployeeTimeClockJobPositionsLoading(true));

    try {
      const timeclockJobPositionsData = await Services.Timeclock.getEmployeeTimeclockJobPositions(
        employeeId,
      );

      dispatch(getEmployeeTimeClockJobPositionsAction(timeclockJobPositionsData));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(getEmployeeTimeClockJobPositionsLoading(false));
    }
  };
};

export const clockInEmployeePositionAction = createAction<ActionResult>(
  actionTypes.CLOCK_IN_EMPLOYEE_POSITION_SUCCESS,
);
const clockInEmployeePositionLoading = createAction<boolean>(
  actionTypes.CLOCK_IN_EMPLOYEE_POSITION_LOADING,
);

export const clockInEmployeePosition = (
  employeeId: string,
  data: IClockInParams,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(clockInEmployeePositionLoading(true));

    try {
      await Services.Timeclock.clockInEmployeePosition(employeeId, data);

      dispatch(clockInEmployeePositionAction(ActionResult.SUCCESS_ACTION));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(clockInEmployeePositionLoading(false));
    }
  };
};

const clockOutEmployeePositionAction = createAction<ActionResult>(
  actionTypes.CLOCK_OUT_EMPLOYEE_POSITION_SUCCESS,
);
const clockOutEmployeePositionLoading = createAction<boolean>(
  actionTypes.CLOCK_OUT_EMPLOYEE_POSITION_LOADING,
);

export const clockOutEmployeePosition = (
  employeeId: string,
  data: IClockInParams,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(clockOutEmployeePositionLoading(true));

    try {
      await Services.Timeclock.clockOutEmployeePosition(employeeId, data);

      dispatch(clockOutEmployeePositionAction(ActionResult.SUCCESS_ACTION));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(clockOutEmployeePositionLoading(false));
    }
  };
};

export const clockInTimeclockSignInAction = createAction<IClockedInEmployee>(
  actionTypes.CLOCK_IN_TIMECLOCK_SIGN_IN_SUCCESS,
);
const clockInTimeclockSignInLoading = createAction<boolean>(
  actionTypes.CLOCK_IN_TIMECLOCK_SIGN_IN_LOADING,
);

export const clockInTimeclockSignInError = createAction<IServerError>(
  actionTypes.CLOCK_IN_TIMECLOCK_SIGN_IN_ERROR,
);

export const clockInTimeclockSignIn = (data: IUnlockCredentials): GeneralThunkAction => {
  return async (dispatch, getState) => {
    const state = getState();
    const corporationId = state.getIn(['currentUser', 'profile', 'corporation', 'id']);

    dispatch(clockInTimeclockSignInLoading(true));

    try {
      const employeeData = await Services.Timeclock.clockIn({ ...data, corporationId });

      dispatch(clockInTimeclockSignInAction(employeeData));
    } catch (error) {
      dispatch(clockInTimeclockSignInError(error));
    } finally {
      dispatch(clockInTimeclockSignInLoading(false));
    }
  };
};

const getFullPayrollReportListAction = createAction<IPaginatedPayrollReportList>(
  actionTypes.GET_FULL_PAYROLL_REPORT_LIST_SUCCESS,
);
const getFullPayrollReportListLoading = createAction<boolean>(
  actionTypes.GET_FULL_PAYROLL_REPORT_LIST_LOADING,
);

export const getFullPayrollReportList = (
  requestOptions: ITableParams,
  period: PeriodFilterType,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(getFullPayrollReportListLoading(true));

    try {
      const payrollReportData = await Services.Timeclock.getFullPayrollReportList(
        requestOptions,
        period,
      );

      dispatch(getFullPayrollReportListAction(payrollReportData));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(getFullPayrollReportListLoading(false));
    }
  };
};

export const getFullPayrollReportListInitialRequests = (
  requestOptions: ITableParams,
  isExistRangePickerParams: boolean,
  period: PeriodFilterType,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(getFullPayrollReportListLoading(true));

    try {
      const payPeriodsData = await Services.Timeclock.getPayrollPayPeriods();
      const { data } = payPeriodsData;

      let currentPayPeriod = null;
      let newTableParams = requestOptions;

      if (!isExistRangePickerParams) {
        currentPayPeriod = getCurrentPayPeriod(data);
        newTableParams = getNewTableParams(requestOptions, currentPayPeriod);
      }

      const payrollReportData = await Services.Timeclock.getFullPayrollReportList(
        newTableParams,
        period,
      );

      batch(() => {
        if (currentPayPeriod) {
          dispatch(setCurrentPayrollPayPeriod(currentPayPeriod));
        }
        dispatch(getPayrollPayPeriodsAction(payPeriodsData));
        dispatch(getFullPayrollReportListAction(payrollReportData));
      });
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(getFullPayrollReportListLoading(false));
    }
  };
};

const getMyFullPayrollReportListAction = createAction<IPaginatedMyPayrollReportList>(
  actionTypes.GET_MY_FULL_PAYROLL_REPORT_LIST_SUCCESS,
);
const getMyFullPayrollReportListLoading = createAction<boolean>(
  actionTypes.GET_MY_FULL_PAYROLL_REPORT_LIST_LOADING,
);

const getPayrollPayPeriodsAction = createAction<IPaginatedPayPeriods>(
  actionTypes.GET_PAYROLL_PAY_PERIODS_SUCCESS,
);
const getPayrollPayPeriodsLoading = createAction<boolean>(
  actionTypes.GET_PAYROLL_PAY_PERIODS_LOADING,
);

export const getPayrollPayPeriods = (requestOptions?: ITableParams): GeneralThunkAction => {
  return async dispatch => {
    dispatch(getPayrollPayPeriodsLoading(true));

    try {
      const payPeriodsData = await Services.Timeclock.getPayrollPayPeriods();

      dispatch(getPayrollPayPeriodsAction(payPeriodsData));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(getPayrollPayPeriodsLoading(false));
    }
  };
};

export const getMyFullPayrollReportList = (
  requestOptions: ITableParams,
  period: PeriodFilterType,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(getMyFullPayrollReportListLoading(true));

    try {
      const payrollReportData = await Services.Timeclock.getMyFullPayrollReportList(
        requestOptions,
        period,
      );

      dispatch(getMyFullPayrollReportListAction(payrollReportData));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(getMyFullPayrollReportListLoading(false));
    }
  };
};

export const getMyFullPayrollReportInitialRequests = (
  requestOptions: ITableParams,
  isExistRangePickerParams: boolean,
  period: PeriodFilterType,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(getMyFullPayrollReportListLoading(true));

    try {
      const payPeriodsData = await Services.Timeclock.getPayrollPayPeriods();
      const { data } = payPeriodsData;

      let currentPayPeriod = null;
      let newTableParams = requestOptions;

      if (!isExistRangePickerParams) {
        currentPayPeriod = getCurrentPayPeriod(data);
        newTableParams = getNewTableParams(requestOptions, currentPayPeriod);
      }

      const payrollReportData = await Services.Timeclock.getMyFullPayrollReportList(
        newTableParams,
        period,
      );

      batch(() => {
        if (currentPayPeriod) {
          dispatch(setCurrentPayrollPayPeriod(currentPayPeriod));
        }

        dispatch(getPayrollPayPeriodsAction(payPeriodsData));
        dispatch(getMyFullPayrollReportListAction(payrollReportData));
      });
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(getMyFullPayrollReportListLoading(false));
    }
  };
};

const getClockinDataListAction = createAction<IPaginatedClockInData>(
  actionTypes.GET_CLOCK_IN_DATA_LIST_SUCCESS,
);
const getClockinDataListLoading = createAction<boolean>(actionTypes.GET_CLOCK_IN_DATA_LIST_LOADING);

export const getClockinDataList = (
  empoyeeId: string,
  requestOptions?: ITableParams,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(getClockinDataListLoading(true));

    try {
      const clockInData = await Services.Timeclock.getClockInDataList(empoyeeId, requestOptions);

      dispatch(getClockinDataListAction(clockInData));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(getClockinDataListLoading(false));
    }
  };
};

const getUnitDataListAction = createAction<IPaginatedUnitData>(
  actionTypes.GET_UNIT_DATA_LIST_SUCCESS,
);
const getUnitDataListLoading = createAction<boolean>(actionTypes.GET_UNIT_DATA_LIST_LOADING);

export const getUnitDataList = (
  empoyeeId: string,
  requestOptions?: ITableParams,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(getUnitDataListLoading(true));

    try {
      const unitData = await Services.Timeclock.getUnitDataList(empoyeeId, requestOptions);

      dispatch(getUnitDataListAction(unitData));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(getUnitDataListLoading(false));
    }
  };
};

const getPayrollStatsAction = createAction<IPayrollStatistics>(
  actionTypes.GET_PAYROLL_STATISTICS_SUCCESS,
);
const getPayrollStatsLoading = createAction<boolean>(actionTypes.GET_PAYROLL_STATISTICS_LOADING);

export const getPayrollStatistics = (
  empoyeeId: string,
  requestOptions?: ITableParams,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(getPayrollStatsLoading(true));

    try {
      const statsData = await Services.Timeclock.getStatisticsByEmployeeId(
        empoyeeId,
        requestOptions,
      );

      dispatch(getPayrollStatsAction(statsData));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(getPayrollStatsLoading(false));
    }
  };
};

export const makeEmployeePayrollReportsInitialRequests = (
  empoyeeId: string,
  baseParams: ITableParams,
  commonParams: ITableParams,
  isExistRangePickerParams?: boolean,
): GeneralThunkAction => {
  return async dispatch => {
    batch(() => {
      dispatch(getPayrollStatsLoading(true));
      dispatch(getPayrollPayPeriodsLoading(true));
      dispatch(getClockinDataListLoading(true));
      dispatch(getUnitDataListLoading(true));
    });

    try {
      const payPeriodsData = await Services.Timeclock.getPayrollPayPeriods();
      const { data } = payPeriodsData;

      let currentPayPeriod = null;
      let newBaseTableParams = baseParams;
      let newCommonTableParams = commonParams;

      if (!isExistRangePickerParams) {
        currentPayPeriod = getCurrentPayPeriod(data);
        newBaseTableParams = getNewTableParams(baseParams, currentPayPeriod);
        newCommonTableParams = getNewTableParams(commonParams, currentPayPeriod);
      }

      const statsData = await Services.Timeclock.getStatisticsByEmployeeId(
        empoyeeId,
        newCommonTableParams,
      );
      const clockInData = await Services.Timeclock.getClockInDataList(
        empoyeeId,
        newBaseTableParams,
      );
      const unitData = await Services.Timeclock.getUnitDataList(empoyeeId, newBaseTableParams);

      batch(() => {
        if (currentPayPeriod) {
          dispatch(setCurrentPayrollPayPeriod(currentPayPeriod));
        }

        dispatch(getPayrollPayPeriodsAction(payPeriodsData));
        dispatch(getClockinDataListAction(clockInData));
        dispatch(getUnitDataListAction(unitData));
        dispatch(getPayrollStatsAction(statsData));
      });
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      batch(() => {
        dispatch(getUnitDataListLoading(false));
        dispatch(getPayrollStatsLoading(false));
        dispatch(getPayrollPayPeriodsLoading(false));
        dispatch(getClockinDataListLoading(false));
      });
    }
  };
};

export const addClockInManuallyDataAction = createAction<ITimeclockListItem>(
  actionTypes.ADD_CLOCK_IN_MANUALLY_SUCCESS,
);
export const addClockInUnitManuallyDataAction = createAction<ITimeclockListItem>(
  actionTypes.ADD_CLOCK_IN_UNIT_MANUALLY_SUCCESS,
);
const addClockInManuallyDataLoading = createAction<boolean>(
  actionTypes.ADD_CLOCK_IN_MANUALLY_LOADING,
);

export const addClockInManuallyData = (
  empoyeeId: string,
  isAddUnit: boolean,
  data: IAddClockInManuallyData,
  isProfile: boolean,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(addClockInManuallyDataLoading(true));

    try {
      const resultData = isProfile
        ? await Services.Timeclock.addProfileManualClockIn(data)
        : await Services.Timeclock.addManualClockIn(empoyeeId, data);

      if (isAddUnit) {
        dispatch(addClockInUnitManuallyDataAction(resultData));
      } else {
        dispatch(addClockInManuallyDataAction(resultData));
      }
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(addClockInManuallyDataLoading(false));
    }
  };
};

const getTimeclockEventsAction = createAction<IPaginatedData<IBookingEvent>>(
  actionTypes.GET_TIMECLOCK_EVENTS_SUCCESS,
);
const getTimeclockEventsLoading = createAction<boolean>(actionTypes.GET_TIMECLOCK_EVENTS_LOADING);

export const getTimeclockEvents = (
  empoyeeId: string,
  requestOptions?: ITimeclockEventsRequestData,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(getTimeclockEventsLoading(true));

    const requestData = {
      ...requestOptions,
      clubIds: requestOptions.clubIds ? requestOptions.clubIds.join(',') : '',
    };

    try {
      const statsData = await Services.Timeclock.getTimeclockEvents(empoyeeId, requestData);

      dispatch(getTimeclockEventsAction(statsData));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(getTimeclockEventsLoading(false));
    }
  };
};

export const deletePayrollItemAction = createAction<ActionResult>(
  actionTypes.DELETE_PAYROLL_ITEM_SUCCESS,
);
const deletePayrollItemLoading = createAction<boolean>(actionTypes.DELETE_PAYROLL_ITEM_LOADING);

export const deletePayrollItem = (employeeId: string, date: string): GeneralThunkAction => {
  return async dispatch => {
    dispatch(deletePayrollItemLoading(true));

    try {
      await Services.Timeclock.deletePayrollItem(employeeId, date);

      dispatch(deletePayrollItemAction(ActionResult.SUCCESS_ACTION));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(deletePayrollItemLoading(false));
    }
  };
};

export const deleteClockInDataItemAction = createAction<ActionResult>(
  actionTypes.DELETE_CLOCK_IN_DATA_ITEM_SUCCESS,
);
export const deleteUnitDataItemAction = createAction<ActionResult>(
  actionTypes.DELETE_UNIT_DATA_ITEM_SUCCESS,
);

const deleteClockInDataItemLoading = createAction<boolean>(
  actionTypes.DELETE_CLOCK_IN_DATA_ITEM_LOADING,
);

export const deleteClockInDataItem = (timeclockId: string, isUnit: boolean): GeneralThunkAction => {
  return async dispatch => {
    dispatch(deleteClockInDataItemLoading(true));

    try {
      await Services.Timeclock.deleteClockInDataItem(timeclockId);

      if (isUnit) {
        dispatch(deleteUnitDataItemAction(ActionResult.SUCCESS_ACTION));
      } else {
        dispatch(deleteClockInDataItemAction(ActionResult.SUCCESS_ACTION));
      }
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(deleteClockInDataItemLoading(false));
    }
  };
};

const getTimeclockJobPositionsAction = createAction<ITimeClockEmployeeJobPosition[]>(
  actionTypes.GET_TIMECLOCK_JOB_POSITIONS_SUCCESS,
);
const getTimeclockJobPositionsLoading = createAction<boolean>(
  actionTypes.GET_TIMECLOCK_JOB_POSITIONS_LOADING,
);

export const getTimeclockJobPositions = (
  employeeId: string,
  isProfile: boolean,
  isUnit: boolean,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(getTimeclockJobPositionsLoading(true));

    try {
      let timeclockJobPositionsData;

      if (isProfile) {
        timeclockJobPositionsData = isUnit
          ? await Services.Timeclock.getProfileUnitDataJobPositions()
          : await Services.Timeclock.getProfileClockInDataJobPositions();
      } else {
        timeclockJobPositionsData = isUnit
          ? await Services.Timeclock.getPayrollUnitDataJobPositions(employeeId)
          : await Services.Timeclock.getPayrollClockInDataJobPositions(employeeId);
      }

      dispatch(getTimeclockJobPositionsAction(timeclockJobPositionsData));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(getTimeclockJobPositionsLoading(false));
    }
  };
};

export const getPersonEventAction = createAction<IBookingEvent>(
  actionTypes.GET_PERSON_EVENT_SUCCESS,
);

const getPersonEventLoading = createAction<boolean>(actionTypes.GET_PERSON_EVENT_LOADING);

export const getPersonEvent = (eventId: string): GeneralThunkAction => {
  return async dispatch => {
    dispatch(getPersonEventLoading(true));

    try {
      const event = await Services.Timeclock.getTimeclockPersonEvent(eventId);

      dispatch(getPersonEventAction(event));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(getPersonEventLoading(false));
    }
  };
};

export const getAllEventsAction = createAction<IPaginatedData<IBookingEvent>>(
  actionTypes.GET_ALL_PROFILE_EVENTS_SUCCESS,
);

const getAllEventsLoading = createAction<boolean>(actionTypes.GET_ALL_PROFILE_EVENTS_LOADING);

export const getAllEvents = (
  requestOptions: ITimeclockEventsRequestData,
  isProfile: boolean,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(getAllEventsLoading(true));

    const requestData = {
      ...requestOptions,
      clubIds: requestOptions.clubIds ? requestOptions.clubIds.join(',') : '',
    };

    try {
      const event = isProfile
        ? await Services.Timeclock.getMyTimeclockEvents(requestData)
        : await Services.Timeclock.getPositionEvents(requestData);

      dispatch(getAllEventsAction(event));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(getAllEventsLoading(false));
    }
  };
};

export const fetchPayrollReportActivityAction = createAction<IPayrollReportActivityDTO>(
  actionTypes.FETCH_PAYROLL_REPORT_ACTIVITY,
);

const fetchPayrollReportActivityActionLoading = createAction<boolean>(
  actionTypes.FETCH_PAYROLL_REPORT_ACTIVITY_LOADING,
);

export const fetchPayrollReportActivity = (
  requestFilters: IFilter[],
  period: PeriodFilterType,
  isProfile?: boolean,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPayrollReportActivityActionLoading(true));

    try {
      const reportActivity = isProfile
        ? await Services.Timeclock.getProfilePayrollReportActivity(requestFilters, period)
        : await Services.Timeclock.getPayrollReportActivity(requestFilters, period);

      dispatch(fetchPayrollReportActivityAction(reportActivity));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchPayrollReportActivityActionLoading(false));
    }
  };
};
