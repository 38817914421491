import { IProfileInfoImt } from 'common/components/PersonProfile/interfaces';
import { SenderAvailabilityTypeList } from 'modules/booking/constants/senderAvailability';
import { TSenderAvailabilityImt } from 'modules/booking/interfaces';
import { AppStoreTypeImt } from 'store';

export const selectMemberProfile = (state): IProfileInfoImt => {
  return state.getIn(['memberPortalProfile', 'profile', 'result']);
};
export const selectMemberProfileLoading = (state): boolean => {
  return state.getIn(['memberPortalProfile', 'profile', 'isLoading']);
};

export const selectPersonAlerts = state =>
  state.getIn(['memberPortalProfile', 'profile', 'alerts']);

export const selectSenderAvailability = (type: SenderAvailabilityTypeList) => (
  state: AppStoreTypeImt,
): TSenderAvailabilityImt =>
  state.getIn(['memberPortalProfile', 'profile', 'sendAvailability', type, 'data']);
