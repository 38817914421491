import React from 'react';
import { FormattedMessage } from 'react-intl';

// components
import { DialogComponent } from 'common/components/index';
// interfaces
import { INote } from 'common/components/PersonProfile/interfaces';
// messages
import notes from '../messages/notes';
import messages from 'common/messages/messages';

interface IProps {
  noteToDelete: INote;
  isNoteModalOpen: boolean;
  deletePersonNote: (id: string) => void;
  loading: boolean;
  onCloseModal: () => void;
}

export function DeleteNoteModal(props: IProps): JSX.Element {
  const { noteToDelete, isNoteModalOpen, deletePersonNote, loading, onCloseModal } = props;

  const onCloseModalHandler = () => {
    onCloseModal();
  };

  const onSubmitDeletingNote = () => {
    deletePersonNote(noteToDelete.id);
  };

  if (!isNoteModalOpen) {
    return <></>;
  }

  return (
    <DialogComponent
      title={<FormattedMessage {...notes.deleteNote} />}
      submitBtnTitle={<FormattedMessage {...messages.deleteBtn} />}
      size="xs"
      isOpen={isNoteModalOpen}
      onClose={onCloseModalHandler}
      onSubmit={onSubmitDeletingNote}
      disableFullScreenBehavior
      loading={loading}
    />
  );
}
