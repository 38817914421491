import axios from 'axios';
import ApiService from 'services/network/ApiService';

import { ITableParams } from 'common/interfaces/table';
import {
  ICorporationBankInfo,
  ICorporationBillingInfo,
  ICorporationGeneralInfo,
  ICorporationGeneralInfoView,
  ICorporationListItem,
  ICorporationOtherInfo,
  ICorporationProcessorConfigInfo,
  ICorporationTaxpayerInfo,
} from 'modules/corporations/interfaces';
import { IPaginatedData } from 'common/interfaces/pagination';
import {
  IBankProcessorConfiguration,
  IBankRemittanceInfo,
  IBankViewInfoDto,
  IPaymentBankInfo,
} from 'modules/clubs/interfaces';
import { IPeakMessagingSettings } from 'modules/peak-settings/interfaces';
import { INamedEntity } from 'common/interfaces/common';
import { IRequiredFieldInfo } from 'modules/corporate-settings/interfaces';

export type IPaginatedCorporations = IPaginatedData<ICorporationListItem>;

export default class CorporationsService extends ApiService {
  public getCorporationsList = (requestOptions?: ITableParams): Promise<IPaginatedCorporations> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);

    return axios.get('/api/v1/corporations', { params: searchParams });
  };

  public getPeakSystemSettings = (): Promise<IPeakMessagingSettings> =>
    axios.get('/api/v1/corporations/zendesk-settings');

  public createCorporation = (
    requestData: ICorporationGeneralInfo,
  ): Promise<ICorporationGeneralInfo> => axios.post('/api/v1/corporations', requestData);

  public getCorporationGeneralInfo = (corporationId: string): Promise<ICorporationGeneralInfo> =>
    axios.get(`/api/v1/corporations/${corporationId}`);

  public getCorporationGeneralInfoView = (
    corporationId: string,
  ): Promise<ICorporationGeneralInfoView> =>
    axios.get(`/api/v1/corporations/${corporationId}/view`);

  public getCorporationBankInfo = (corporationId: string): Promise<ICorporationBankInfo> =>
    axios.get(`/api/v1/corporations/${corporationId}/bank-info`);

  public getCorporationBankInfoView = (corporationId: string): Promise<IBankViewInfoDto> =>
    axios.get(`/api/v1/corporations/${corporationId}/bank-info/view`);

  public getCorporationBillingInfo = (corporationId: string): Promise<ICorporationBillingInfo> =>
    axios.get(`/api/v1/corporations/${corporationId}/billing-info`);

  public getCorporationProcessorConfigInfo = (
    corporationId: string,
  ): Promise<ICorporationProcessorConfigInfo> =>
    axios.get(`/api/v1/corporations/${corporationId}/processor-config`);

  public getCorporationTaxpayerInfo = (corporationId: string): Promise<ICorporationTaxpayerInfo> =>
    axios.get(`/api/v1/corporations/${corporationId}/taxpayer-info`);

  // TODO add api for integrations step
  /* public getCorporationIntegrationsInfo = (corporationId: string): Promise<ICorporationTaxesInfo> =>
axios.get(`/api/v1/corporations/${corporationId}/tax-info`); */

  public getCorporationOtherInfo = (corporationId: string): Promise<ICorporationOtherInfo> =>
    axios.get(`/api/v1/corporations/${corporationId}/other-info`);

  public updateCorporationGeneralInfo = (
    corporationId: string,
    requestData: ICorporationGeneralInfo,
  ): Promise<ICorporationGeneralInfo> =>
    axios.put(`/api/v1/corporations/${corporationId}`, requestData);

  public updateCorporationBankInfo = (
    corporationId: string,
    requestData: ICorporationBankInfo,
  ): Promise<ICorporationBankInfo> =>
    axios.put(`/api/v1/corporations/${corporationId}/bank-info`, requestData);

  public updateCorporationBankRemittanceInfo = (
    corporationId: string,
    requestData: IBankRemittanceInfo,
  ): Promise<IBankRemittanceInfo> =>
    axios.put(`/api/v1/corporations/${corporationId}/bank-info/remittance-info`, requestData);

  public updateCorporationCreditCardBankInfo = (
    corporationId: string,
    requestData: IPaymentBankInfo,
  ): Promise<IPaymentBankInfo> =>
    axios.put(`/api/v1/corporations/${corporationId}/bank-info/credit-card-bank-info`, requestData);

  public updateCorporationEcheckBankInfo = (
    corporationId: string,
    requestData: IPaymentBankInfo,
  ): Promise<IPaymentBankInfo> =>
    axios.put(`/api/v1/corporations/${corporationId}/bank-info/echeck-bank-info`, requestData);

  public updateCorporationBankProcessorConfigInfo = (
    corporationId: string,
    requestData: IBankProcessorConfiguration,
  ): Promise<IBankProcessorConfiguration> =>
    axios.put(`/api/v1/corporations/${corporationId}/bank-info/processor-config`, requestData);

  public updateCorporationBillingInfo = (
    corporationId: string,
    requestData: ICorporationBillingInfo,
  ): Promise<ICorporationBillingInfo> =>
    axios.put(`/api/v1/corporations/${corporationId}/billing-info`, requestData);

  public updateCorporationProcessorConfigInfo = (
    corporationId: string,
    requestData: ICorporationProcessorConfigInfo,
  ): Promise<ICorporationProcessorConfigInfo> =>
    axios.put(`/api/v1/corporations/${corporationId}/processor-config`, requestData);

  public updateCorporationTaxpayerInfo = (
    corporationId: string,
    requestData: ICorporationTaxpayerInfo,
  ): Promise<ICorporationTaxpayerInfo> =>
    axios.put(`/api/v1/corporations/${corporationId}/taxpayer-info`, requestData);

  public updateCorporationOtherInfo = (
    corporationId: string,
    requestData: ICorporationOtherInfo,
  ): Promise<ICorporationOtherInfo> =>
    axios.put(`/api/v1/corporations/${corporationId}/other-info`, requestData);

  public changeCorporationStatus = (
    corporationId: string,
    isActive: boolean,
  ): Promise<ICorporationGeneralInfo> =>
    axios.put(`/api/v1/corporations/${corporationId}/change-status`, {
      active: isActive,
    });

  public updateCorporation = (
    corporationId: string,
    requestData: ICorporationGeneralInfo,
  ): Promise<ICorporationGeneralInfo> =>
    axios.put(`/api/v1/corporations/${corporationId}`, requestData);

  public transferOwnership = (
    corporationId: string,
    employeeId: string,
  ): Promise<IPaginatedData<INamedEntity>> =>
    axios.put(
      `/api/v1/corporations/${corporationId}/transfer-ownership
`,
      null,
      { params: { employeeId } },
    );

  public getProfileFieldsInfo = (corporationId: string): Promise<Array<IRequiredFieldInfo>> =>
    axios.get(`/api/v1/corporations/${corporationId}/profile-required-info`);
}
