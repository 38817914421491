import React, { useState } from 'react';
import cx from 'classnames';
import { Checkbox } from '../index';
import { Box, createStyles, FormControlLabel, makeStyles, Theme } from '@material-ui/core';
import { ReactComponent as ReplayIcon } from 'img/icons/reset.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      position: 'relative',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main,
      },
      '& :hover .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main,
      },
    },
    wrapReset: {
      justifyContent: 'space-between',
    },
    replayContainer: {
      backgroundColor: theme.palette.primary.main,
      zIndex: 1,
      borderRadius: 3,
      cursor: 'pointer',
      height: 18,
      width: 20,
      textAlign: 'center',
      marginLeft: theme.spacing(1),
    },
    replayContainerSmall: {
      width: 13,
      height: 13,
      fontSize: 'small',
      alignSelf: 'center',
    },
    iconReplay: {
      fill: theme.palette.background.paper,
      height: 16,
      width: 16,
      padding: '2px 2px 0px',
    },
    iconReplaySmall: {
      height: 12,
      width: 12,
    },
  }),
);

type TextFieldWithResetProps = {
  name?: string;
  value?: boolean | null;
  label?: string | JSX.Element;
  disabled?: boolean;
  className?: string;
  wrapperClassName?: string;
  isCheckBoxWrap?: boolean;
  resetValue?: boolean | null;
  onChange: (...event: any[]) => void;
  onBlur?: () => void;
  onResetValue?: (value: any) => void;
};

const CheckBoxWithReset = ({
  onChange,
  name,
  value,
  label,
  disabled,
  className,
  wrapperClassName = '',
  isCheckBoxWrap,
  onResetValue,
  resetValue = null,
  onBlur,
}: TextFieldWithResetProps): JSX.Element => {
  const classes = useStyles();
  const [isReset, setIsReset] = useState(false);
  return (
    <Box display="flex" className={cx(wrapperClassName, { [classes.wrapReset]: isCheckBoxWrap })}>
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            name={name}
            indeterminate={value === null}
            checked={value !== null && value}
            onChange={e => {
              if (!isReset) onChange(e.target.checked);
              setIsReset(false);
            }}
            disabled={disabled}
            onBlur={onBlur}
          />
        }
        label={label}
        className={className}
      />
      {!!onResetValue && value !== resetValue && (
        <Box
          className={cx(classes.replayContainer, {
            [classes.replayContainerSmall]: isCheckBoxWrap,
          })}
          onClick={() => {
            setIsReset(true);
            if (onResetValue) {
              onResetValue(resetValue);
              setIsReset(false);
              onBlur();
            }
          }}
        >
          <ReplayIcon
            className={cx(classes.iconReplay, { [classes.iconReplaySmall]: isCheckBoxWrap })}
          />
        </Box>
      )}
    </Box>
  );
};

export default CheckBoxWithReset;
