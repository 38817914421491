import GrapesJS from 'grapesjs';

import { defaultToolbar } from './TextPlugin';

(GrapesJS as any).plugins.add('table-plugin', function(editor) {
  function addComponents() {
    const tableType = editor.DomComponents.getType('table');
    const cellType = editor.DomComponents.getType('cell');
    const { initToolbar } = tableType.model.prototype;

    editor.DomComponents.addType('table', {
      model: {
        ...tableType.model,
        initToolbar(args) {
          initToolbar.apply(this, args);

          this.set('toolbar', defaultToolbar);
        },
      },
      view: {
        ...tableType.view,
      },
    });

    editor.DomComponents.addType('cell', {
      model: {
        ...cellType.model,
        initToolbar(args) {
          initToolbar.apply(this, args);

          this.set('toolbar', defaultToolbar);
        },
      },
      view: {
        ...cellType.view,
      },
    });
  }

  addComponents();
});

export default 'table-plugin';
