// Libraries
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { List as ImmutableList } from 'immutable';
import cx from 'classnames';
import {
  Box,
  IconButton,
  ListItem,
  makeStyles,
  Paper,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
// Custom components
import GuestStatus from 'modules/front-desk/components/GuestStatus/GuestStatus';
import ProfileAvatar from '../ProfileAvatar/ProfileAvatar';
import { ReactComponent as WarningIcon } from 'img/icons/warning.svg';
import { ReactComponent as CakeIcon } from 'img/icons/cake.svg';
import { ReactComponent as StoreIcon } from 'img/icons/cash-register.svg';
import { ReactComponent as BarcodeIcon } from 'img/icons/barcode.svg';
// Interfaces
import { ICheckInImt } from 'modules/front-desk/interfaces';
import { IAlertSettingsImt } from 'common/interfaces/alerts';
// Hooks
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
// Constants
import { CHECK_IN_LIST_LARGE_ITEMS_NUMBER } from 'modules/front-desk/constants';
import { DEFAULT_DATE_FORMAT } from 'common/constants/dateFormats';
// Utils
import { hasCelebrationAlert, hasErrorAlert, hasWarningAlert } from 'common/utils/alerts';
import { formatPersonalCode } from 'common/utils/format';
import { getInitials } from 'helpers/common';
// Messages
import messages from 'modules/front-desk/messages';
import { TooltipTypography } from 'common/components/index';

interface IProps {
  checkIn: ICheckInImt;
  isActive: boolean;
  onClick: (personId: string) => void;
  onClickPOSBtn: (personId: string) => void;
  index: number;
  alertsList: ImmutableList<IAlertSettingsImt>;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    cursor: 'pointer',
    padding: 0,
    paddingBottom: theme.spacing(1),

    '&:last-of-type': {
      paddingBottom: 0,
    },
  },
  itemPaper: {
    display: 'flex',
    width: '100%',
    borderRadius: '3px',
    overflow: 'hidden',

    '&:hover': {
      '&:not(.itemActive)': {
        boxShadow: '0 6px 12px 0 rgba(0,0,0,0.16)',
      },
    },
  },
  itemAvatar: {
    width: '52px',
    minWidth: '52px',
    height: '52px',
    backgroundSize: 'cover',
    backgroundPosition: 'top center',
  },
  notification: {
    fontSize: '0.75rem',
  },
  info: {
    display: 'flex',
    width: '50%',
    padding: theme.spacing(1.5),

    '&.smallItem': {
      padding: theme.spacing(1, 1.5, 1, 1),
      width: '100%',
      justifyContent: 'space-between',
    },
  },
  itemActive: {
    '&.largeItem': {
      '& .itemAvatar': {
        width: '50%',
        height: '100%',
        minHeight: '150px',
      },
    },
    '& .itemAvatar': {
      minWidth: '52px',
      height: '52px',
    },
  },
  largeItem: {
    height: '150px',

    '& .check-in-time': {
      margin: 0,
    },

    '& .itemAvatar': {
      width: '50%',
      height: '150px',
    },

    '& h5': {
      fontSize: '1rem',
    },
  },
  id: {
    marginBottom: theme.spacing(0.5),
    fontSize: '0.75rem',
  },
  alertIcon: {
    width: '16px',
    height: '16px',
  },
  posButton: {
    display: 'flex',
    alignSelf: 'end',

    '& svg': {
      width: '20px',
      height: '20px',
    },

    '&.smallItem': {
      width: '24px',
      height: '24px',
      alignSelf: 'center',
      marginLeft: theme.spacing(1),

      '& svg': {
        width: '16px',
        height: '16px',
      },
    },

    '&.isActive svg': {
      color: theme.palette.primary.contrastText,
    },

    '&.isActive:hover': {
      background: 'rgba(255, 255, 255, 0.4)',
    },
  },
  checkInTime: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),

    '& > svg': {
      width: '16px',
      height: '16px',
    },
  },
}));

const CheckInItem = ({
  index,
  isActive,
  alertsList,
  checkIn,
  onClick,
  onClickPOSBtn,
}: IProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const [timezoneMoment] = useTimezoneMoment();

  const alerts = checkIn.get('alerts')?.toJS();

  const isLargeItem = index < CHECK_IN_LIST_LARGE_ITEMS_NUMBER;
  const initials = getInitials(checkIn.get('firstName'), checkIn.get('lastName'));
  const getItemColor = (): string => {
    if (!isActive) {
      return theme.palette.background.paper;
    }

    if (hasErrorAlert(alertsList, alerts)) {
      return theme.palette.error.main;
    }

    if (hasWarningAlert(alertsList, alerts)) {
      return theme.palette.warning.main;
    }

    if (hasCelebrationAlert(alerts)) {
      return theme.palette.celebration.main;
    }

    return theme.palette.primary.main;
  };

  const getTextColor = (): string => {
    if (!isActive) {
      return theme.palette.text.secondary;
    }

    return theme.palette.primary.contrastText;
  };

  // handlers

  const handleOnClick = (): void => {
    onClick(checkIn.get('personId'));
  };

  const handleClickPOSBtn = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();

    onClickPOSBtn(checkIn.get('personId'));
  };

  // renders

  const renderAlertIcon = (): JSX.Element => {
    let icon: JSX.Element = null;

    if (hasErrorAlert(alertsList, alerts)) {
      const color = isActive ? theme.palette.error.contrastText : theme.palette.error.main;
      icon = <WarningIcon style={{ color }} className={classes.alertIcon} />;
    } else if (hasWarningAlert(alertsList, alerts)) {
      const color = isActive ? theme.palette.warning.contrastText : theme.palette.warning.main;
      icon = <WarningIcon style={{ color }} className={classes.alertIcon} />;
    } else if (hasCelebrationAlert(alerts)) {
      const color = isActive
        ? theme.palette.celebration.contrastText
        : theme.palette.celebration.main;
      icon = <CakeIcon style={{ color }} className={classes.alertIcon} />;
    }

    return icon && <Box ml={1}>{icon}</Box>;
  };

  const paperClassName = cx(
    classes.itemPaper,
    isLargeItem && `${classes.largeItem} largeItem`,
    isActive && classes.itemActive,
    isActive && 'itemActive',
  );

  return (
    <ListItem className={classes.root} onClick={handleOnClick}>
      <Paper
        className={paperClassName}
        style={{ background: getItemColor(), borderColor: getItemColor() }}
      >
        <ProfileAvatar
          avatarUrl={checkIn.get('imageUrl')}
          className={`${classes.itemAvatar} itemAvatar`}
          isLargeItem={isLargeItem}
          initials={initials}
        />

        <Box
          className={cx(classes.info, { smallItem: !isLargeItem })}
          flexDirection={isLargeItem ? 'column' : 'row'}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            maxWidth={!isLargeItem ? '120px' : 'initial'}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <TooltipTypography
                ellipsized
                style={{ color: isActive ? getTextColor() : 'initial' }}
                variant="h5"
              >
                {checkIn.get('firstName')} {checkIn.get('lastName')}
              </TooltipTypography>

              {isLargeItem && renderAlertIcon()}
            </Box>

            <GuestStatus
              color={isActive ? getTextColor() : null}
              type={checkIn.get('personType')}
            />
          </Box>

          <Box
            display="flex"
            flexDirection={isLargeItem ? 'column' : 'row'}
            justifyContent="space-between"
            mt={isLargeItem && 1}
            flex={isLargeItem ? 1 : 'initial'}
          >
            <Box
              display={isLargeItem ? 'block' : 'flex'}
              flexDirection={isLargeItem ? 'row' : 'column'}
              alignItems="end"
            >
              {isLargeItem && (
                <Typography className={classes.id} style={{ color: getTextColor() }}>
                  <FormattedMessage
                    {...messages.ID}
                    values={{
                      personId: formatPersonalCode(checkIn.get('personalCode')),
                    }}
                  />
                </Typography>
              )}

              {!isLargeItem && renderAlertIcon()}

              <Tooltip
                title={timezoneMoment(checkIn.get('checkInTime')).format(DEFAULT_DATE_FORMAT)}
              >
                <Typography
                  color="textSecondary"
                  className={classes.checkInTime}
                  style={{ color: getTextColor() }}
                >
                  <Typography variant="body2" component="span" color="inherit">
                    {timezoneMoment(checkIn.get('checkInTime')).format('h:mm A')}
                  </Typography>

                  <BarcodeIcon />
                </Typography>
              </Tooltip>
            </Box>

            <IconButton
              color="default"
              size="small"
              onClick={handleClickPOSBtn}
              className={cx(classes.posButton, { smallItem: !isLargeItem, isActive })}
            >
              <StoreIcon />
            </IconButton>
          </Box>
        </Box>
      </Paper>
    </ListItem>
  );
};

export default CheckInItem;
