// interfaces
import { IUserAvatarImt } from 'modules/authentication/interfaces';
import { IServerError } from 'common/interfaces/http';

// Authentication

export const selectIsMemberPortalUserLoggedIn = state =>
  state.getIn(['memberPortalAuthentication', 'authentication', 'isUserLogged']);

export const selectSignInLoading = state =>
  state.getIn(['memberPortalAuthentication', 'authentication', 'isLoading']);

export const selectSignInError = (state): IServerError =>
  state.getIn(['memberPortalAuthentication', 'authentication', 'error']);

export const selectCorporationsForSignIn = state =>
  state.getIn(['memberPortalAuthentication', 'authentication', 'corporationsForSign', 'result']);
export const selectCorporationsForSignInLoading = (state): boolean =>
  state.getIn(['memberPortalAuthentication', 'authentication', 'corporationsForSign', 'isLoading']);

export const selectUserAvatar = (state): IUserAvatarImt => {
  return state.getIn(['memberPortalAuthentication', 'currentUser', 'userAvatar']);
};

export const selectSelectedOrganizationColor = (state): string =>
  state.getIn(['memberPortalAuthentication', 'currentUser', 'selectedOrganizationPrimaryColor']);
