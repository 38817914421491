// Libraries
import React, { CSSProperties } from 'react';
import ReactCrop from 'react-image-crop';

// Styles
import 'react-image-crop/dist/ReactCrop.css';
import { ICropResult, ImageFormatType } from 'common/interfaces/uploadFile';

interface IImageCropperProps {
  src: string;
  aspectRatio?: number;
  imageStyle?: CSSProperties;
  onCrop: (result: ICropResult) => void;
}

interface ICropSettings {
  unit?: string;
  width?: number;
  height?: number;
  x?: number;
  y?: number;
  aspect?: number;
}

interface IImageCropperState {
  crop: ICropSettings;
  croppedImageUrl?: string;
  customCropAspect?: number;
}

export default class ImageCropper extends React.Component<IImageCropperProps, IImageCropperState> {
  private imageRef: HTMLImageElement;

  private croppedFileUrl: string;

  constructor(props: IImageCropperProps) {
    super(props);
    this.state = {
      crop: {
        unit: '%',
        aspect: props.aspectRatio,
      },
    };
  }

  private onImageLoaded = image => {
    this.imageRef = image;

    const { aspectRatio } = this.props;

    const aspect = aspectRatio || 1;
    const originalAspect = image.width / image.height;

    let width;
    let height;

    if (originalAspect > aspect) {
      height = image.height / 2;
      width = (image.height * aspect) / 2;
    } else {
      width = image.width / 2;
      height = image.width / aspect / 2;
    }
    const x = (image.width - width) / 2;
    const y = (image.height - height) / 2;

    const crop = {
      unit: 'px',
      width,
      height,
      x,
      y,
      aspect,
    };

    this.setState({ crop });
    this.onCropComplete(crop);

    return false;
  };

  private onCropComplete = async (crop: ICropSettings): Promise<void> => {
    const { onCrop } = this.props;
    if (this.imageRef && crop.width && crop.height) {
      const cropResult: ICropResult = await this.getCroppedImg(this.imageRef, crop);
      onCrop(cropResult);
    }
  };

  private onCropChange = (crop: ICropSettings) => {
    this.setState({ crop });
  };

  getCroppedImg(image: HTMLImageElement, crop: ICropSettings): Promise<ICropResult> {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          reject(new Error('No data for cropping'));
        }

        window.URL.revokeObjectURL(this.croppedFileUrl);
        const croppedFileUrl = window.URL.createObjectURL(blob);
        this.croppedFileUrl = croppedFileUrl;
        resolve({ blob, url: croppedFileUrl });
      }, ImageFormatType.JPEG);
    });
  }

  render(): JSX.Element {
    const { src, imageStyle } = this.props;
    const { crop } = this.state;

    return (
      <div>
        {src && (
          <ReactCrop
            src={src}
            crop={crop}
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onCropComplete}
            onChange={this.onCropChange}
            imageStyle={imageStyle}
          />
        )}
      </div>
    );
  }
}
