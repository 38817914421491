// libraries
import React, { useCallback, useEffect, useRef } from 'react';
import { FormHelperText, Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { ArrayField, Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
// custom interfaces
import { NumberTextField, Select } from '../../../../index';
import { FrequencyTypes } from 'modules/services/constants/packages';
import { IEditable, IPackageFee } from 'modules/services/interfaces/packages';
import { Control } from 'react-hook-form/dist/types/form';
// utils constants
import { getSplitsPayment } from 'modules/services/utils/billingUtils';
import { getAccessByPropPath } from 'common/utils/errorObject';
import {
  defaultNumberFormatProps,
  defaultPriceNumberProps,
} from '../../../../NumberController/NumberController';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import useScrollIntoView from 'common/hooks/useScrollIntoView';
// messages
import inputLabels from 'common/messages/inputLabels';

interface IFeeInstanceSplitSectionProps {
  control: Control;
  packageFee: Partial<ArrayField<IPackageFee, 'packageFeeKey'>>;
  formName: string;

  errors: any;
}

const FeeInstanceSplitSection: React.FC<IFeeInstanceSplitSectionProps> = (
  props: IFeeInstanceSplitSectionProps,
): JSX.Element => {
  const { packageFee, formName } = props;
  const { control, errors, setValue, trigger } = useFormContext();
  const renderIntlMessage = useRenderIntlMessage();

  // form errors

  const paymentsNumberError = getAccessByPropPath(errors, `${formName}.paymentsNumber.value`);
  const paymentScheduleError = getAccessByPropPath(errors, `${formName}.splitPaymentSchedule`);
  const splitsError = getAccessByPropPath(errors, `${formName}.editableSplits`);

  const { scrollRef } = useScrollIntoView(!!splitsError);

  const isInitialRender = useRef(true);

  // watches

  const splitNumber = useWatch<IEditable>({
    control,
    name: `${formName}.paymentsNumber`,
  });

  const totalAmount = useWatch<IEditable>({
    control,
    name: `${formName}.totalAmount`,
  });

  // form

  const { fields, append, remove } = useFieldArray<IEditable>({
    control,
    name: `${formName}.editableSplits`,
  });

  // handlers

  const reRenderSplits = useCallback(
    ({ updatedNumber = splitNumber.value, updatedAmount = totalAmount.value }): void => {
      if (updatedNumber && updatedAmount) {
        const updatedSplits: IEditable[] = getSplitsPayment(updatedNumber, updatedAmount, false);

        const amountDifference: number = updatedSplits.length - fields.length;

        if (amountDifference > 0) {
          append(updatedSplits.slice(-amountDifference));
        } else if (amountDifference < 0) {
          const removableIndexes: number[] = Array.from(Array(fields.length).keys()).slice(
            amountDifference,
          );

          remove(removableIndexes);
        }
      }
    },
    [append, fields.length, remove, splitNumber.value, totalAmount.value],
  );

  const handleSplitsNumberChanged = (val: IEditable, onChange: (...event: any[]) => void) => {
    onChange(val);
    reRenderSplits({ updatedNumber: val.value });
  };

  // effects

  useEffect(() => {
    if (packageFee.editableSplits?.length && isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    const updatedSplits: IEditable[] = getSplitsPayment(
      splitNumber.value,
      totalAmount.value,
      false,
    );

    updatedSplits.forEach((payment, index) => {
      setValue(`${formName}.editableSplits.${index}`, payment);
    });

    reRenderSplits({ updatedNumber: splitNumber.value });
  }, [
    totalAmount.value,
    splitNumber.value,
    setValue,
    formName,
    reRenderSplits,
    packageFee.editableSplits.length,
  ]);

  // renders

  return (
    <>
      <Grid item xs={4}>
        <Controller
          control={control}
          name={`${formName}.splitPaymentSchedule`}
          defaultValue={packageFee.splitPaymentSchedule}
          render={({ name, value, onChange, onBlur }) => (
            <Select
              fullWidth
              variant="outlined"
              label={<FormattedMessage {...inputLabels.paymentSchedule} />}
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              disabled
              error={!!paymentScheduleError}
              helperText={renderIntlMessage(paymentScheduleError?.message)}
            >
              {FrequencyTypes.getSelectOptions()}
            </Select>
          )}
        />
      </Grid>

      <Grid item xs={4}>
        <Controller
          control={control}
          name={`${formName}.paymentsNumber`}
          defaultValue={packageFee.paymentsNumber}
          render={({ name, value: paymentsNumber, onChange, onBlur }) => (
            <NumberTextField
              fullWidth
              variant="outlined"
              name={name}
              value={paymentsNumber?.value}
              label={<FormattedMessage {...inputLabels.paymentsNumber} />}
              numberFormatProps={defaultNumberFormatProps}
              disabled={!paymentsNumber?.editable}
              error={!!paymentsNumberError}
              helperText={renderIntlMessage(paymentsNumberError?.message, {
                value: paymentsNumberError?.message?.value,
              })}
              onChange={value => handleSplitsNumberChanged({ ...paymentsNumber, value }, onChange)}
              onBlur={onBlur}
            />
          )}
        />
      </Grid>

      {fields.map((field, index) => (
        <Grid item xs={4} key={field.id}>
          <Controller
            name={`${formName}.editableSplits.${index}.value`}
            control={control}
            defaultValue={field.value}
            render={({ value, onChange, onBlur }) => (
              <NumberTextField
                value={value}
                onChange={onChange}
                onBlur={() => {
                  onBlur();
                  trigger(`${formName}.editableSplits`);
                }}
                fullWidth
                label={`#${index + 1}`}
                variant="outlined"
                numberFormatProps={defaultPriceNumberProps}
                error={!!splitsError}
              />
            )}
          />
        </Grid>
      ))}

      {!!splitsError && (
        <Grid item xs={12} ref={scrollRef}>
          <FormHelperText error>{renderIntlMessage(splitsError?.message)}</FormHelperText>
        </Grid>
      )}
    </>
  );
};

export default FeeInstanceSplitSection;
