import React, { CSSProperties } from 'react';
import cx from 'classnames';
import { Avatar, Box, makeStyles } from '@material-ui/core';

import { CustomTheme } from 'common/ui/interfaces';

import profilePlaceholder from 'img/photo-placeholder.png';

const useStyles = makeStyles((theme: CustomTheme) => ({
  avatar: ({ width, height }: CSSProperties) => ({
    width: width || 28,
    height: height || 28,
  }),
  borderRadiusOfAvatar: ({ borderRadius }) => ({
    borderRadius,
  }),
  avatarWrap: ({ borderRadius, marginRight, imageUrl }: IProps) => ({
    position: 'relative',
    flex: 'none',
    borderRadius: borderRadius || '50%',
    marginRight: theme.spacing(marginRight !== undefined ? (marginRight as number) : 1),
    backgroundColor: imageUrl ? null : theme.palette.secondary.light,
  }),
  avatarInitial: ({ fontSize }: CSSProperties) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    fontSize: fontSize || '0.75rem',
    fontWeight: 700,
    color: theme.palette.darkBackground.light,
    whiteSpace: 'nowrap',
  }),
}));

interface IProps extends CSSProperties {
  initials: string;
  imageUrl: string;
  marginRight?: number;
  variant?: 'circle' | 'circular' | 'rounded' | 'square';
}

const AvatarWithInitials = (props: IProps): JSX.Element => {
  const { initials, imageUrl, variant, borderRadius } = props;

  const classes = useStyles(props);

  return (
    <Box className={cx(classes.avatar, classes.avatarWrap)}>
      {initials && !imageUrl ? (
        <Box className={classes.avatarInitial}>{initials}</Box>
      ) : (
        <Avatar
          src={imageUrl || profilePlaceholder}
          variant={variant}
          className={cx(classes.avatar, { [classes.borderRadiusOfAvatar]: Boolean(borderRadius) })}
        />
      )}
    </Box>
  );
};

export default AvatarWithInitials;
