// libraries
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { List as ImmutableList } from 'immutable';
import { makeStyles, Theme, Typography } from '@material-ui/core';
// interfaces
import { IHeadCell, ITableRow } from 'common/interfaces/table';
import { ISelectable } from 'common/components/PersonProfile/interfaces';
import { IPackageInstanceDetails, IPackageInstanceDetailsImt } from 'common/interfaces/service';
import { ISelectedData } from 'common/hooks/useMultipleSelect';
// components
import { Table } from 'common/components/index';
// utils
import { useAppDispatch } from 'store/hooks';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
// state
import {
  fetchCancelItems,
  fetchReactivateMembershipItems,
  fetchReactivateServiceItems,
  resetCancelItems,
  resetReactivateItems,
} from 'common/components/PersonProfile/state/membership/actions';

import {
  selectCancelItems,
  selectCancelItemsLoading,
  selectReactivateItems,
  selectReactivateItemsLoading,
} from 'common/components/PersonProfile/state/membership/selectors';
// constants
import { StepContext } from 'common/createContext/stepContext';
import { FrequencyTypes } from 'modules/services/constants/packages';
import { DEFAULT_DATE_FORMAT, DEFAULT_DATE_TIME_FORMAT } from 'common/constants/dateFormats';

import messages from 'common/components/PersonProfile/messages';
import sevicesMessages from 'modules/services/messages/messages';
import inputLabels from 'common/messages/inputLabels';
import { PeakModules } from 'common/constants/peakModules';
import { PackageSelectionType } from '../../../constants';

const useStyles = makeStyles((theme: Theme) => ({
  helperLabel: {
    fontSize: '1rem',
    fontWeight: 700,
    margin: theme.spacing(3, 0, 1.5, 0),
  },
}));

const headerOptions: IHeadCell[] = [
  { id: 'packageName', label: '', sort: false },
  {
    id: 'expirationDate',
    label: <FormattedMessage {...inputLabels.expirationDate} />,
    sort: false,
  },
];

interface IProps {
  personId: string;
  module: PeakModules;
  packageSelectionType: PackageSelectionType;
}

const PackageSelectionStep: React.FC<IProps> = ({
  personId,
  module,
  packageSelectionType,
}: IProps): JSX.Element => {
  // state
  const dispatch = useAppDispatch();

  const reactivateItems: ImmutableList<IPackageInstanceDetailsImt> = useSelector(
    selectReactivateItems,
  );
  const cancelItems: ImmutableList<IPackageInstanceDetailsImt> = useSelector(selectCancelItems);
  const isReactivateItemsLoading: boolean = useSelector(selectReactivateItemsLoading);
  const isCancelItemsLoading: boolean = useSelector(selectCancelItemsLoading);

  const [packageItems, setPackageItems] = useState<Array<IPackageInstanceDetails & ISelectable>>(
    [],
  );

  const renderIntlMessage = useRenderIntlMessage();
  const [timezoneMoment] = useTimezoneMoment();

  const classes = useStyles();

  const isCancelServiceType = packageSelectionType === PackageSelectionType.CANCEL_SERVICE;

  useEffect(() => {
    if (packageSelectionType === PackageSelectionType.CANCEL_SERVICE) {
      dispatch(fetchCancelItems(personId, module));
    } else if (packageSelectionType === PackageSelectionType.REACTIVATE_MEMBERSHIP) {
      dispatch(fetchReactivateMembershipItems(personId, module));
    } else if (packageSelectionType === PackageSelectionType.REACTIVATE_SERVICE) {
      dispatch(fetchReactivateServiceItems(personId, module));
    }

    return () => {
      if (packageSelectionType === PackageSelectionType.CANCEL_SERVICE) {
        dispatch(resetCancelItems());
      } else {
        dispatch(resetReactivateItems());
      }
    };
  }, [dispatch, personId, module, packageSelectionType]);

  useEffect(() => {
    const items =
      packageSelectionType === PackageSelectionType.CANCEL_SERVICE ? cancelItems : reactivateItems;

    if (items?.size) {
      setPackageItems(
        items
          .toJS()
          .map((packageItem: IPackageInstanceDetails) => ({ selected: false, ...packageItem })),
      );
    }
  }, [reactivateItems, cancelItems, packageSelectionType]);

  const { onBack, renderFooter, onNext } = useContext(StepContext);

  const isSubmitDisabled =
    packageItems.every(item => !item.selected) ||
    packageItems.filter(item => item.selected).length > 1;

  const initialSelected: string[] = [];
  packageItems.forEach(item => {
    if (item.selected) {
      initialSelected.push(item.packageInstanceId);
    }
  });

  const renderPackageNameCell = useCallback(
    billingItem => {
      const packageName = [];

      if (billingItem.pricePerBilling !== null && billingItem.paymentSchedule) {
        packageName.push(
          `${billingItem.title} $${billingItem.pricePerBilling.toFixed(2)}/${renderIntlMessage(
            FrequencyTypes.message(billingItem.paymentSchedule),
          )}`,
        );
      } else if (billingItem.pricePerBilling !== null) {
        packageName.push(`${billingItem.title} $${billingItem.pricePerBilling.toFixed(2)}`);
      } else {
        packageName.push(renderIntlMessage(sevicesMessages.freeOption));
      }

      packageName.push(
        ` (${timezoneMoment(billingItem.startDate).format(DEFAULT_DATE_FORMAT)} - ${timezoneMoment(
          billingItem.endDate,
        ).format(DEFAULT_DATE_FORMAT)})`,
      );

      return packageName;
    },
    [renderIntlMessage, timezoneMoment],
  );

  // table configuration

  const rows: ITableRow[] = packageItems.map(billingItem => ({
    id: billingItem.packageInstanceId,
    cells: [
      {
        align: 'left',
        label: '',
        cellComponent: <Typography>{renderPackageNameCell(billingItem)}</Typography>,
      },
      {
        align: 'left',
        label: '',
        cellComponent: (
          <Typography>
            {timezoneMoment(billingItem.expirationDate).format(DEFAULT_DATE_TIME_FORMAT)}
          </Typography>
        ),
      },
    ],
  }));

  // handlers

  const handleSubmit = () => {
    const { packageInstanceId, configurationDto } = packageItems.find(
      billingItem => billingItem.selected,
    );

    onNext({
      configurationDto,
      packageInstanceId,
    });
  };

  const handleSelectBillingItem = useCallback(({ selectedIds }: ISelectedData) => {
    setPackageItems(prevState => {
      const updatedBillingItems = [...prevState];

      updatedBillingItems.forEach(billingItem => {
        billingItem.selected = selectedIds.includes(billingItem.packageInstanceId);
      });

      return updatedBillingItems;
    });
  }, []);

  // renders

  return (
    <>
      <Typography className={classes.helperLabel}>
        <FormattedMessage
          {...(isCancelServiceType
            ? messages.canceleMembershipSelectPackageStepLabel
            : messages.reactivateMembershipSelectPackageStepLabel)}
        />
      </Typography>
      <Table
        activeSelect
        isRadioButtonMode
        hidePagination
        hideSearchInput
        showHeaderSelect={false}
        headerOptions={headerOptions}
        rows={rows}
        isLoading={isReactivateItemsLoading || isCancelItemsLoading}
        initialSelected={initialSelected}
        onSelect={handleSelectBillingItem}
        noDataMsg={
          <FormattedMessage
            {...(isCancelServiceType
              ? messages.canceleMembershipNoPackagesAvailable
              : messages.reactivateMembershipNoPackagesAvailable)}
          />
        }
      />

      {renderFooter(onBack, handleSubmit, isSubmitDisabled)}
    </>
  );
};

export default React.memo(PackageSelectionStep);
