import { ConstOptions } from 'common/constants/classes';
import { CheckingSavingsTypes } from 'common/constants/invoices';
import { colors, ThemeBreakpoints } from 'common/ui/theme/default';

import { DocumentType } from 'common/components/PersonProfile/interfaces';

import messages from 'common/components/PersonProfile/messages';
import notificationsMessages from 'modules/dictionaries/messages/notifications';
import commonMessages from 'common/messages/messages';
import menuItems from 'common/messages/menuItems';
import tableHeaders from 'common/messages/tableHeaders';
import { PersonAppointmentStatus } from 'modules/booking/interfaces';

export const UPDATE_PERSON_PHOTO = 'UPDATE_PERSON_PHOTO';
export const UPDATE_PERSON_PHOTO_LOADING = 'UPDATE_PERSON_PHOTO_LOADING';

export const CustomBrakePoints = {
  desktop: ThemeBreakpoints.DESKTOP,
  laptop: 800,
  tablet: ThemeBreakpoints.SM,
  xs: 400,
};

export enum EmailStatus {
  Sending = 'SENDING',
  Sent = 'SENT',
  FailedToSend = 'FAILED_TO_SEND',
  Received = 'RECEIVED',
  EmailSettingsNotConfigured = 'EMAIL_SETTINGS_NOT_CONFIGURED',
}

export enum SMSStatus {
  Sending = 'SENDING',
  Sent = 'SENT',
  FailedToSend = 'FAILED_TO_SEND',
  Received = 'RECEIVED',
  SMSSettingsNotConfigured = 'SMS_SETTINGS_NOT_CONFIGURED',
}

export enum ReasonType {
  Dialog = 'DIALOG',
  SystemNotification = 'SYSTEM_NOTIFICATION',
}

export enum TicketTopic {
  CancelMember = 'CANCEL_MEMBER',
  FreezeMember = 'FREEZE_MEMBER',
  RefundMember = 'REFUND_MEMBER',
  MemberChange = 'MEMBER_CHANGE',
  QuestionOnReport = 'QUESTION_ON_REPORT',
  GeneralQuestion = 'GENERAL_QUESTION',
  Other = 'OTHER',
}

export enum TicketStatus {
  New = 'NEW',
  Open = 'OPEN',
  Pending = 'PENDING',
  Hold = 'HOLD',
  Solved = 'SOLVED',
  Closed = 'CLOSED',
  Deleted = 'DELETED',
}

export const EmailStatusMessages = {
  [EmailStatus.Sent]: messages.sentStatus,
  [EmailStatus.Received]: messages.receivedStatus,
  [EmailStatus.FailedToSend]: messages.failedToSendStatus,
  [EmailStatus.Sending]: messages.sendingStatus,
  [EmailStatus.EmailSettingsNotConfigured]: messages.emailSettingsNotConfigured,
};

export const SMSStatusMessages = {
  [SMSStatus.Sent]: messages.sentStatus,
  [SMSStatus.Received]: messages.receivedStatus,
  [SMSStatus.FailedToSend]: messages.failedToSendStatus,
  [SMSStatus.Sending]: messages.sendingStatus,
  [SMSStatus.SMSSettingsNotConfigured]: messages.smsSettingsNotConfigured,
};

export enum FallthroughReasons {
  OtherReason = 'OTHER_REASON',
}

export enum PhoneTypes {
  Home = 'HOME',
  Work = 'WORK',
  Mobile = 'MOBILE',
}

export const PhoneTypesOptions = new ConstOptions([
  { key: PhoneTypes.Home, value: PhoneTypes.Home, message: commonMessages.homeOption },
  { key: PhoneTypes.Work, value: PhoneTypes.Work, message: commonMessages.workOption },
  { key: PhoneTypes.Mobile, value: PhoneTypes.Mobile, message: commonMessages.cellOption },
]);

export enum AppointmentTypes {
  Tour = 'Tour',
  FreeClass = 'Free class',
  Orientation = 'Orientation',
  Other = 'Other',
}

export enum PastDueActions {
  Keep = 'KEEP',
  Waive = 'WAIVE',
}

export const PastDueResolutions = new ConstOptions([
  {
    key: PastDueActions.Keep,
    value: PastDueActions.Keep,
    message: messages.cancelMembershipModalPastDuesOptionsKeep,
  },
  {
    key: PastDueActions.Waive,
    value: PastDueActions.Waive,
    message: messages.cancelMembershipModalPastDuesOptionsWaive,
  },
]);

export enum CancelReasons {
  Relocation = 'RELOCATION',
  Disability = 'DISABILITY',
  Other = 'OTHER',
}

export enum AlertTypes {
  Danger = 'danger',
  Warning = 'warning',
  Info = 'info',
}

export const DocumentTypes = new ConstOptions([
  {
    key: DocumentType.Waiver,
    value: DocumentType.Waiver,
    message: notificationsMessages.waiverLabel,
  },
  {
    key: DocumentType.Contract,
    value: DocumentType.Contract,
    message: notificationsMessages.contractLabel,
  },
]);

export enum FreezePackageType {
  Planned = 'PLANNED',
  Active_freeze = 'ACTIVE_FREEZE',
  Finished = 'FINISHED',
}

export const AppointmentStatuses = new ConstOptions([
  {
    key: PersonAppointmentStatus.Cancelled,
    value: PersonAppointmentStatus.Cancelled,
    message: commonMessages.cancelled,
  },
  {
    key: PersonAppointmentStatus.Attended,
    value: PersonAppointmentStatus.Attended,
    message: tableHeaders.attended,
  },
  {
    key: PersonAppointmentStatus.Pending,
    value: PersonAppointmentStatus.Pending,
    message: commonMessages.pending,
  },
  {
    key: PersonAppointmentStatus.Missed,
    value: PersonAppointmentStatus.Missed,
    message: commonMessages.missed,
  },
  {
    key: PersonAppointmentStatus.Confirmed,
    value: PersonAppointmentStatus.Confirmed,
    message: commonMessages.confirmed,
  },
]);

export const AppointmentStatusColors = {
  [PersonAppointmentStatus.Confirmed]: colors.info,
  [PersonAppointmentStatus.Attended]: colors.primary,
  [PersonAppointmentStatus.Missed]: colors.text.secondary,
  [PersonAppointmentStatus.Cancelled]: colors.error,
  [PersonAppointmentStatus.Pending]: colors.warning,
};

export const checkingSavingsOptions = new ConstOptions([
  {
    key: CheckingSavingsTypes.Checking,
    value: CheckingSavingsTypes.Checking,
    message: commonMessages.checkingLabel,
  },
  {
    key: CheckingSavingsTypes.Savings,
    value: CheckingSavingsTypes.Savings,
    message: commonMessages.savingsLabel,
  },
]);

export const topicTypeLabels = new ConstOptions([
  {
    key: TicketTopic.CancelMember,
    value: TicketTopic.CancelMember,
    message: messages.cancelMember,
  },
  {
    key: TicketTopic.FreezeMember,
    value: TicketTopic.FreezeMember,
    message: messages.freezeMember,
  },
  {
    key: TicketTopic.MemberChange,
    value: TicketTopic.MemberChange,
    message: messages.memberChange,
  },
  {
    key: TicketTopic.RefundMember,
    value: TicketTopic.RefundMember,
    message: messages.refundMember,
  },
  {
    key: TicketTopic.GeneralQuestion,
    value: TicketTopic.GeneralQuestion,
    message: messages.generalQuestion,
  },
  {
    key: TicketTopic.QuestionOnReport,
    value: TicketTopic.QuestionOnReport,
    message: messages.questionOnReport,
  },
  { key: TicketTopic.Other, value: TicketTopic.Other, message: menuItems.other },
]);

export const ticketStatuses = new ConstOptions([
  {
    key: TicketStatus.New,
    value: TicketStatus.New,
    message: messages.newStatus,
    color: colors.error,
  },
  {
    key: TicketStatus.Open,
    value: TicketStatus.Open,
    message: messages.openStatus,
    color: colors.warning,
  },
  {
    key: TicketStatus.Pending,
    value: TicketStatus.Pending,
    message: messages.pendingStatus,
    color: colors.info,
  },
  {
    key: TicketStatus.Hold,
    value: TicketStatus.Hold,
    message: messages.holdStatus,
    color: colors.celebration,
  },
  {
    key: TicketStatus.Solved,
    value: TicketStatus.Solved,
    message: messages.solvedStatus,
    color: colors.primary,
  },
  {
    key: TicketStatus.Closed,
    value: TicketStatus.Closed,
    message: messages.closedStatus,
    color: colors.coldBlue,
  },
  {
    key: TicketStatus.Deleted,
    value: TicketStatus.Deleted,
    message: messages.deletedStatus,
    color: colors.background.darkLighter,
  },
]);

export enum PackageSelectionType {
  REACTIVATE_MEMBERSHIP = 'REACTIVATE_MEMBERSHIP',
  REACTIVATE_SERVICE = 'REACTIVATE_SERVICE',
  CANCEL_SERVICE = 'CANCEL_SERVICE',
}
