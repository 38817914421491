import { IEditablePackageConfiguration } from 'common/interfaces/membership';

export const checkIsGracePeriodAllow = (
  packageItem: IEditablePackageConfiguration,
  isPaymentGraceAllow = false,
): boolean => {
  if (packageItem.paymentGrace?.allow || isPaymentGraceAllow) {
    return true;
  }

  packageItem.services.forEach(serviceItem => {
    if (serviceItem.package !== null) {
      isPaymentGraceAllow = checkIsGracePeriodAllow(serviceItem.package, isPaymentGraceAllow);
    }
  });

  return isPaymentGraceAllow;
};
