import { fromJS } from 'immutable';
import { combineReducers } from 'redux-immutable';

import { actionTypes } from 'memberPortalModules/authentication/state/constants';
import { initialState, initReqStateImt } from 'common/constants/initialState';
import TokenService from 'services/auth/TokenService';
import AppService from 'services/application/AppService';

const signInInitialState = fromJS({
  isUserLogged: Boolean(TokenService.getAccessToken() && AppService.getUserRole() === 'member'),
  isLoading: false,
  error: false,

  corporationsForSign: initialState,
});

export const authenticationReducer = (state = signInInitialState, action) => {
  switch (action.type) {
    case actionTypes.USER_SIGN_IN:
      return state.set('isUserLogged', fromJS(action.payload?.success)).set('error', false);
    case actionTypes.USER_SIGN_IN_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.USER_SIGN_IN_ERROR:
      return state.set('error', action.payload);
    case actionTypes.USER_LOG_OUT:
      return state.set('isUserLogged', false);
    case actionTypes.FETCH_CORPORATIONS_FOR_SIGN_IN_LOADING:
      return state.setIn(['corporationsForSign', 'isLoading'], action.payload);
    case actionTypes.FETCH_CORPORATIONS_FOR_SIGN_IN:
      return state.setIn(['corporationsForSign', 'result'], fromJS(action.payload));
    case actionTypes.RESET_CORPORATIONS_FOR_SIGN_IN:
      return state.set('corporationsForSign', initReqStateImt);
    default:
      return state;
  }
};

const userProfileInitialState = fromJS({
  userAvatar: null,

  selectedOrganizationPrimaryColor: '',
});

export const currentUserReducer = (state = userProfileInitialState, action) => {
  switch (action.type) {
    case actionTypes.SET_USER_AVATAR:
      return state.set('userAvatar', fromJS(action.payload));
    case actionTypes.SET_ORGANIZATION_PRIMARY_COLOR:
      return state.set('selectedOrganizationPrimaryColor', action.payload);
    case actionTypes.RESET_ORGANIZATION_PRIMARY_COLOR:
      return state.set('selectedOrganizationPrimaryColor', null);
    case actionTypes.RESET_USER_PROFILE_DATA:
      return userProfileInitialState;
    default:
      return state;
  }
};

export default combineReducers({
  authentication: authenticationReducer,
  currentUser: currentUserReducer,
});
