import React from 'react';
import { Box, makeStyles, SvgIcon, Typography } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

import { ReactComponent as Bage } from 'img/badge.svg';

const useStyles = makeStyles((theme: Theme) => ({
  body1: {
    marginRight: '1rem',
    fontSize: '1.5rem',
  },
  body2: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.warning.main,
    fontSize: '1.5rem',
  },
  balance: {
    margin: '14px 0 4px 0',
    [theme.breakpoints.down('md')]: {
      margin: '6px 0 2px 0',
    },
  },
}));

interface IBalanceProps {
  balance: string;
  reward: number;
}

const Balance: React.FC<IBalanceProps> = React.memo(({ balance, reward }: IBalanceProps) => {
  const classes = useStyles();

  return (
    <Box mb={1}>
      <Typography
        component="p"
        align="center"
        variant="body2"
        color="textSecondary"
        className={classes.balance}
      >
        Balance/Reward
      </Typography>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography color="primary" component="span" className={classes.body1}>
          ${balance || '0'}
        </Typography>
        <Typography component="span" className={classes.body2}>
          <SvgIcon style={{ width: '22px', height: 'auto' }}>
            <Bage />
          </SvgIcon>
          {reward || '0'}
        </Typography>
      </Box>
    </Box>
  );
});

export default Balance;
