import React from 'react';
import { Grid, Modal, Typography, makeStyles, Box } from '@material-ui/core';
import { Theme, createStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Button } from 'common/components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.text.primary,
    },
    modalContent: {
      position: 'relative',
    },
    wrapper: {
      background: 'white',
      borderRadius: '4px',
      outline: 'none',
      maxWidth: '95%',
      maxHeight: '90%',
      overflow: 'auto',
    },
    container: {
      position: 'relative',
      outline: 'none',
      flexDirection: 'column',
      flexWrap: 'nowrap',
    },
    title: {
      marginBottom: theme.spacing(2.5),
    },
    cancel: {
      color: theme.palette.primary.main,
      fontWeight: 700,
    },
    confirm: {
      background: theme.palette.primary.main,
      color: 'white',
      fontWeight: 700,
      borderRadius: '3px',
      // minWidth: '76px',
      minWidth: '94px',
      '&:hover': {
        background: theme.palette.primary.main,
      },
      '&:disabled': {
        opacity: 0.5,
        color: 'white',
      },
    },
    actionButtonsContainer: {
      marginTop: 24,
    },
    close: {
      position: 'absolute',
      right: '0.5rem',
      top: '0.5rem',
      fontSize: '1rem',
      opacity: 0.4,
      cursor: 'pointer',
    },
  }),
);

interface IProps {
  open: boolean;
  title: string | JSX.Element;
  confirmLabel?: string | JSX.Element;
  cancelLabel?: string | JSX.Element;
  id?: string;
  children?: React.ReactNode;
  width?: string;
  hideButtons?: boolean;
  disabledButton?: boolean;
  hideCloseButton?: boolean;
  onClose?: () => void;
  onButtonClick?: () => void;
}

const ModalComponent = ({
  id,
  open,
  onClose,
  title,
  width,
  children,
  hideButtons,
  hideCloseButton,
  disabledButton,
  confirmLabel,
  cancelLabel,
  onButtonClick,
}: IProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Modal id={id} open={open} onClose={onClose} className={classes.modal}>
      <Box className={classes.wrapper} style={{ width: width || '300px' }} p={3}>
        <Grid container className={classes.container}>
          <CloseIcon className={classes.close} onClick={onClose} />
          <Grid className={classes.title} item>
            <Typography variant="h4">{title}</Typography>
          </Grid>
          <Grid className={`modal-content ${classes.modalContent}`} item>
            {children}
          </Grid>
          {hideButtons ? null : (
            <Grid className={classes.actionButtonsContainer} item>
              <Grid spacing={2} container justify="flex-end">
                {onClose && !hideCloseButton && (
                  <Grid item>
                    <Button color="primary" onClick={onClose}>
                      {cancelLabel || 'Cancel'}
                    </Button>
                  </Grid>
                )}
                {confirmLabel ? (
                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={disabledButton}
                      onClick={onButtonClick}
                    >
                      {confirmLabel}
                    </Button>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    </Modal>
  );
};

export default ModalComponent;
