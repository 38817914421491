import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { FormattedMessage } from 'react-intl';

import { DialogComponent } from 'common/components';
import NewLead from 'modules/crm/containers/NewLead/NewLead';
import { IProfileInfoImt } from 'common/components/PersonProfile/interfaces';
import { createLeadProfileSuccessAction } from 'modules/crm/state/leads/actions';
// messages
import messages from 'common/messages/messages';
import modalTitles from 'common/messages/modalTitles';

interface IProps {
  profile: IProfileInfoImt;
  open?: boolean;
  onClose?: () => void;
  onSubmit?: (formData) => void;
}

const useStyles = makeStyles(() => ({
  addMembershipModal: {
    '&>.MuiDialog-container>.MuiDialog-paper': {
      height: '100%',
      '&>.MuiBox-root': {
        padding: 0,
      },
    },
  },
}));

export default function MembershipModal(props: IProps): JSX.Element {
  const { open, onClose, profile } = props;

  const dispatch = useDispatch();

  const classes = useStyles();

  useEffect(() => {
    if (profile) {
      // added a lead to the state for used in step containers
      dispatch(createLeadProfileSuccessAction(profile.toJS()));
    }
  }, [dispatch, profile]);

  return (
    <DialogComponent
      submitBtnTitle={<FormattedMessage {...messages.saveBtn} />}
      size="md"
      className={classes.addMembershipModal}
      isOpen={open}
      onClose={onClose}
      hasShadowsOnScroll={false}
      hasCustomFooter
    >
      <NewLead
        mainTitle={
          <FormattedMessage
            {...(profile?.get('membership')
              ? modalTitles.changePackagePlan
              : modalTitles.addMembership)}
          />
        }
        onCloseModal={onClose}
        profile={profile}
        hasChangePackagePlanMode
      />
    </DialogComponent>
  );
}
