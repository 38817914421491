import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Grid, makeStyles, Typography } from '@material-ui/core';

// components
import { fetchPersonDocuments } from 'common/components/PersonProfile/state/actions';
import { selectPersonDocumentsLoading } from 'common/components/PersonProfile/state/selectors';
import { DialogComponent, FormFilesToServerUploader, LoadingBackdrop } from 'common/components';
import DocumentsTable from '../DocumentsTable/DocumentsTable';
import { SignDocumentModal } from 'common/components/PersonProfile/components/index';
// state
import { selectSignContractActionResult } from 'common/state/newPerson/contract/selectors';
import { selectSetSignWaiversActionResult } from 'common/state/newPerson/waiver/selectors';

import { useAppDispatch } from 'store/hooks';

import { ActionResult } from 'common/constants';
// interfaces
import { IAttachment } from 'common/interfaces/uploadFile';
import {
  IPersonDocumentGroupImt,
  IPersonDocumentGroupItemImt,
  IPersonDocumentsImt,
} from 'common/components/PersonProfile/interfaces';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
// messages
import messages from 'common/components/PersonProfile/messages';
import commonMessages from 'common/messages/messages';

const useStyles = makeStyles(() => ({
  root: {
    overflow: 'hidden',
  },
}));

interface IProps {
  isOpen: boolean;
  isAttachmentsUpdateLoading: boolean;
  onClose: () => void;
  onSubmit: (formValues: IAttachment[]) => void;
  documents?: IPersonDocumentsImt;
  module: PeakModuleForNewPersonType;
  profileId: string;
}

const DocumentsViewModal = ({
  isOpen,
  isAttachmentsUpdateLoading,
  onClose,
  onSubmit,
  documents,
  module,
  profileId,
}: IProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const isDocumentsLoading: boolean = useSelector(selectPersonDocumentsLoading(profileId));

  const signContractActionResult = useSelector(selectSignContractActionResult);
  const signWaiverActionResult = useSelector(selectSetSignWaiversActionResult);

  const [selectedDocument, setSelectedDocument] = useState<IPersonDocumentGroupItemImt>(null);

  const formMethods = useForm({
    defaultValues: { attachments: documents?.get('attachments')?.toJS() },
    mode: 'onBlur',
  });

  const classes = useStyles();

  useEffect(() => {
    if (
      signContractActionResult === ActionResult.SUCCESS_ACTION ||
      signWaiverActionResult === ActionResult.SUCCESS_ACTION
    ) {
      dispatch(fetchPersonDocuments(profileId, module));
      setSelectedDocument(null);
    }
  }, [dispatch, module, profileId, signContractActionResult, signWaiverActionResult]);

  const { handleSubmit } = formMethods;

  const handleSelectDocument = useCallback((document: IPersonDocumentGroupItemImt) => {
    setSelectedDocument(document);
  }, []);

  return (
    <DialogComponent
      isOpen={isOpen}
      onClose={onClose}
      cancelBtn={null}
      loading={isAttachmentsUpdateLoading}
      submitBtnTitle={<FormattedMessage {...commonMessages.doneBtn} />}
      size="lg"
      onSubmit={handleSubmit(formValues => onSubmit(formValues.attachments))}
      title={<FormattedMessage {...messages.documentsBlockTitle} />}
    >
      <FormProvider {...formMethods}>
        <form className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="button" color="textSecondary">
                <FormattedMessage {...commonMessages.attachmentsLabel} />
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <FormFilesToServerUploader
                shouldBeVisibleTableHeader
                hasVisibleDownloadBtn
                filesListTextVariant="body1"
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={3}>
                {documents?.get('documentGroups')?.map((documentGroup: IPersonDocumentGroupImt) => {
                  const packageTitle = documentGroup.getIn(['packageTemplate', 'title']);

                  return (
                    <Grid item xs={12} key={packageTitle}>
                      <Typography variant="button" color="textSecondary">
                        {packageTitle}
                      </Typography>

                      <DocumentsTable
                        documents={documentGroup.get('documents')}
                        onSelectSignDocument={handleSelectDocument}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>

          <LoadingBackdrop isLoading={isDocumentsLoading} />

          {!!selectedDocument?.size && (
            <SignDocumentModal
              personId={profileId}
              isOpen={!!selectedDocument}
              document={selectedDocument}
              onClose={() => setSelectedDocument(null)}
              module={module}
            />
          )}
        </form>
      </FormProvider>
    </DialogComponent>
  );
};

export default DocumentsViewModal;
