import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, makeStyles, Theme } from '@material-ui/core';

import { IAttachmentImt } from 'common/interfaces/uploadFile';
import { IPersonDocumentGroupItemImt } from 'common/components/PersonProfile/interfaces';

import { ActionItem, ActionsMenu, TooltipTypography } from 'common/components/index';
import ContractStatus from 'common/components/Steps/SignDocumentStep/components/ContractStatus';
import { ReactComponent as DeleteIcon } from 'img/icons/trash_deprecated.svg';
import { ReactComponent as SignatureIcon } from 'img/icons/signature.svg';
import { ReactComponent as DownloadDocIcon } from 'img/icons/download-doc.svg';

import leadsMessages from 'modules/crm/messages/leads';
import commonMessages from 'common/messages/messages';
import { WaiverStatus } from 'modules/crm/constants/leads';

const useStyles = makeStyles((theme: Theme) => ({
  documentTitleWrapper: {
    maxWidth: '80%',
  },
  documentTitle: {
    marginBottom: theme.spacing(0.5),
    fontWeight: 700,
  },
  actionIcon: {
    width: '16px',
    height: '16px',
  },
}));

interface IProps {
  attachmentItem?: IAttachmentImt;
  groupItem?: IPersonDocumentGroupItemImt;
  onSelectSignDocument?: (document: IPersonDocumentGroupItemImt) => void;
  onDeleteAttachment?: (attachmentId: string) => void;
  profileId?: string;
}

const DocumentListItem = ({
  attachmentItem,
  groupItem,
  onSelectSignDocument,
  onDeleteAttachment,
}: IProps): JSX.Element => {
  const classes = useStyles();

  const needSighDocument = groupItem?.get('status') !== WaiverStatus.NO_SIGN;

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" paddingY={1}>
      <Box className={classes.documentTitleWrapper}>
        <TooltipTypography ellipsized className={classes.documentTitle}>
          {attachmentItem?.get('name') || groupItem?.get('title')}
        </TooltipTypography>

        {groupItem?.size &&
          (needSighDocument ? (
            <ContractStatus isSignedContract={groupItem.get('status') === WaiverStatus.SIGNED} />
          ) : (
            '-'
          ))}
      </Box>
      <ActionsMenu horizontal tableActionsMode>
        {attachmentItem?.get('name') ? (
          <>
            <ActionItem
              onClick={() => onDeleteAttachment(attachmentItem.get('id'))}
              icon={<DeleteIcon />}
            >
              <FormattedMessage {...commonMessages.deleteBtn} />
            </ActionItem>

            <a href={attachmentItem.get('filePath')} download>
              <ActionItem onClick={null} icon={<DownloadDocIcon className={classes.actionIcon} />}>
                <FormattedMessage {...commonMessages.downloadBtn} />
              </ActionItem>
            </a>
          </>
        ) : (
          <>
            {groupItem.get('status') !== WaiverStatus.SIGNED && needSighDocument && (
              <ActionItem
                icon={<SignatureIcon className={classes.actionIcon} />}
                onClick={() => onSelectSignDocument(groupItem)}
              >
                <FormattedMessage {...leadsMessages.newLeadSignWaiversStepSign} />
              </ActionItem>
            )}

            <a download href={groupItem.get('url')}>
              <ActionItem icon={<DownloadDocIcon className={classes.actionIcon} />} onClick={null}>
                <FormattedMessage {...commonMessages.downloadBtn} />
              </ActionItem>
            </a>
          </>
        )}
      </ActionsMenu>
    </Box>
  );
};

export default DocumentListItem;
