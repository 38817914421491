import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, Popover, Paper, Dialog } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

// state
import * as reportActions from 'modules/reports/state/appointmentsReport/actions';
// components
import EventDetailsContainer from '../EventDetails/EventDetailsContainer';
// interfaces
import {
  IBookingEventImt,
  IEventAction,
  IRemindAppointmentDto,
  PersonAppointmentStatus,
} from 'modules/booking/interfaces';
// constants
import { PeakModules } from 'common/constants/peakModules';
import { SenderAvailabilityTypeList } from 'modules/booking/constants/senderAvailability';
import { EShortNotificationType } from 'modules/booking/constants/notificationType';
import { ICancelParticipationFormValues } from 'common/components/PersonProfile/components/Appointments/ParticipationModal/ParticipationModal';

interface IStylesProps {
  isOverflow?: boolean;
  isParticipantsDetailed?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // booking
    '& .MuiPopover-paper': {
      height: ({ isOverflow }: IStylesProps) => (isOverflow ? 'calc(100vh - 64px)' : 'auto'),
      top: '50% !important',
      transform: 'translateY(-50%) !important',
      'scrollbar-width': 'none',
      '-ms-overflow-style': 'none',

      '&::-webkit-scrollbar': {
        width: 0,
        height: 0,
      },
      [theme.breakpoints.down('xs')]: {
        height: ({ isOverflow }: IStylesProps) => (isOverflow ? '100vh' : 'auto'),
        maxHeight: '100%',
        maxWidth: '100%',
        left: '50% !important',
        transform: 'translateX(-50%) translateY(-50%) !important',
      },
    },
    // other
    '&>.MuiDialog-container>.MuiPaper-root': {
      height: ({ isOverflow }: IStylesProps) => (isOverflow ? 'calc(100vh - 64px)' : 'auto'),
      [theme.breakpoints.down('xs')]: {
        height: ({ isOverflow }: { isOverflow: boolean }) => (isOverflow ? '100vh' : 'auto'),
        maxHeight: '100%',
      },
    },
    '& .MuiDialog-paper': {
      margin: 'auto',
    },
  },
  paper: {
    width: 424,
    maxWidth: '100%',
    minHeight: 150,
    height: '100%',
    position: 'relative',
  },
}));

interface IEventDetailsProps {
  eventId?: string;
  eventDate?: string;
  readOnly?: boolean;
  anchorEl?: HTMLElement;
  isDialog?: boolean;
  eventDetails?: IBookingEventImt;
  appointmentStatus?: PersonAppointmentStatus;
  isEventDetailsLoading?: boolean;
  isEventActionLoading?: boolean;
  onLoadEvent?: (eventId: string, eventDate: string) => void;
  onEdit?: (event: IBookingEventImt) => void;
  onCancelParticipation?: (formData: ICancelParticipationFormValues) => void;
  onConfirmParticipation?: (formData: ICancelParticipationFormValues) => void;
  onClose: () => void;
  onDelete?: (formValues: IEventAction) => void;
  onCancel?: (formValues: IEventAction) => void;
  onRestore?: (event: IBookingEventImt) => void;
  onRemind?: (formValues: IRemindAppointmentDto) => void;
  onResetEvent?: () => void;
  onDuplicateEvent?: (isDuplicateParticipants: boolean) => void;
  type: SenderAvailabilityTypeList;
  onLoadSenderAvailability: (
    type: SenderAvailabilityTypeList,
    shortTypeEvent: EShortNotificationType,
  ) => void;
  module: PeakModules;
}

const EventDetailsModal = ({
  eventId,
  eventDate,
  anchorEl,
  isDialog,
  readOnly = false,
  eventDetails,
  isEventDetailsLoading,
  isEventActionLoading,
  onLoadEvent,
  onEdit,
  onClose,
  onDelete,
  onCancel,
  onRestore,
  onResetEvent,
  onDuplicateEvent,
  onRemind,
  type,
  appointmentStatus,
  onLoadSenderAvailability,
  onCancelParticipation,
  onConfirmParticipation,
  module,
}: IEventDetailsProps): JSX.Element => {
  const dispatch = useDispatch();

  const [isOverflow, setIsOverflow] = useState(false);

  const classes = useStyles({ isOverflow });

  useEffect(() => {
    if (anchorEl && eventId && eventDate) {
      onLoadEvent(eventId, eventDate);
    }

    return () => {
      if (readOnly) {
        dispatch(reportActions.resetAppointmentReportEvent());
      } else {
        onResetEvent();
      }
    };
  }, [eventId, eventDate, anchorEl, onLoadEvent, onResetEvent, readOnly, dispatch]);

  const handleOverflow = overflow => {
    setIsOverflow(overflow);
  };

  return isDialog ? (
    <Dialog
      className={classes.root}
      open={Boolean(anchorEl)}
      onClose={(event, reason) => (reason !== 'backdropClick' ? onClose() : null)}
    >
      <Paper className={classes.paper}>
        <EventDetailsContainer
          handleOverflow={handleOverflow}
          module={module}
          readOnly={readOnly}
          event={eventDetails}
          isEventDetailsLoading={isEventDetailsLoading}
          isEventActionLoading={isEventActionLoading}
          onEdit={onEdit}
          onClose={onClose}
          onDelete={onDelete}
          onCancel={onCancel}
          appointmentStatus={appointmentStatus}
          onCancelParticipation={onCancelParticipation}
          onConfirmParticipation={onConfirmParticipation}
          onRestore={onRestore}
          onDuplicate={onDuplicateEvent}
          onRemind={onRemind}
          type={type}
          onLoadSenderAvailability={onLoadSenderAvailability}
        />
      </Paper>
    </Dialog>
  ) : (
    <Popover
      className={classes.root}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl || document.body}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
    >
      <Paper className={classes.paper} elevation={2}>
        <EventDetailsContainer
          handleOverflow={handleOverflow}
          module={module}
          readOnly={readOnly}
          event={eventDetails}
          appointmentStatus={appointmentStatus}
          isEventDetailsLoading={isEventDetailsLoading}
          isEventActionLoading={isEventActionLoading}
          onEdit={onEdit}
          onClose={onClose}
          onDelete={onDelete}
          onCancel={onCancel}
          onCancelParticipation={onCancelParticipation}
          onConfirmParticipation={onConfirmParticipation}
          onRestore={onRestore}
          onDuplicate={onDuplicateEvent}
          onRemind={onRemind}
          type={type}
          onLoadSenderAvailability={onLoadSenderAvailability}
        />
      </Paper>
    </Popover>
  );
};

export default EventDetailsModal;
