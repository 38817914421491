import GrapesJS from 'grapesjs';

import imageIcon from 'img/editor-icons/image.svg';
import { IAttachmentImt } from 'common/interfaces/uploadFile';
import { GrapesCustomTypes, GrapesCustomCommands } from '../constants';

interface ICustomImageAttributes {
  loading: 'lazy' | 'eager';
  src: string;
  alt: string;
  'data-gjs-type': GrapesCustomTypes;
  'data-status': 'set' | 'empty';
  'data-custom-image-id'?: string;
}

const updateImageModel = (model: any, attachment: IAttachmentImt): void => {
  if (!model || !attachment) return;

  const updatedProps: ICustomImageAttributes = {
    'data-gjs-type': GrapesCustomTypes.CustomImage,
    loading: 'lazy',
    src: attachment?.get('filePath') || imageIcon,
    alt: attachment?.get('name')
      ? `Template image: ${attachment.get('name')}`
      : 'Email template image',
    'data-status': 'set',
    'data-custom-image-id': attachment?.get('id'),
  };
  // need this to keep attributes updated while moving item
  // Grapes doesn't update source for some reason (prob type-extension business logics)
  model.set(updatedProps);
  // sets attributes
  model.setAttributes(updatedProps);
};

const defaultImageModelAttribute: ICustomImageAttributes = {
  'data-gjs-type': GrapesCustomTypes.CustomImage,
  loading: 'lazy',
  src: imageIcon,
  alt: 'Email template image',
  'data-status': 'empty',
};

const imageToolbar = [
  {
    attributes: { class: 'fa fa-arrows' },
    command: 'tlb-move',
  },
  {
    attributes: { class: 'fa fa-edit' },
    command: GrapesCustomCommands.EditImage,
  },
  {
    attributes: { class: 'fa fa-trash-o' },
    command: 'tlb-delete',
  },
];

(GrapesJS as any).plugins.add(GrapesCustomTypes.CustomImage, function(editor) {
  function addCommands() {
    // handlers for starting and finishing of Add command
    editor.Commands.add(GrapesCustomCommands.AddImage, {
      stop(
        commandEditor,
        sender,
        { model, attachment }: { model: any; attachment: IAttachmentImt },
      ) {
        updateImageModel(model, attachment);
      },
    });

    editor.Commands.add(GrapesCustomCommands.EditImage, {
      stop(
        commandEditor,
        sender,
        { model, attachment }: { model: any; attachment: IAttachmentImt },
      ) {
        updateImageModel(model, attachment);
      },
    });
  }

  function addComponents() {
    // added template section config
    const defaultType = editor.DomComponents.getType('image');
    const { initToolbar } = defaultType.model.prototype;

    editor.DomComponents.addType(GrapesCustomTypes.CustomImage, {
      extend: 'image',
      extendView: 'image',
      model: {
        ...defaultType.model,
        defaults: {
          // add these for now
          tagName: 'img',
          draggable: true,
          droppable: false, // can't drop anything inside
          highlightable: true,
          attributes: defaultImageModelAttribute,
        },
        isComponent: el => {
          if (
            el.tagName === 'img' &&
            el.hasAttribute('data-gjs-type') === GrapesCustomTypes.CustomImage
          ) {
            return { type: GrapesCustomTypes.CustomImage };
          }
          return false;
        },
        initToolbar(args) {
          initToolbar.apply(this, args);

          this.set('toolbar', imageToolbar);
        },
        init() {
          const attributes: ICustomImageAttributes = this.getAttributes();
          if (attributes['data-status'] === 'empty') {
            editor.runCommand(GrapesCustomCommands.AddImage, { model: this });
          }
        },
      },
    });
  }

  function addBlocks() {
    // tools block config
    editor.BlockManager.add(GrapesCustomTypes.CustomImage, {
      label: `
        <img class="gjs-block-icon" src="${imageIcon}" alt="Add image block" />
        <div class="gjs-block-title">Image</div>
      `,
      content: {
        type: GrapesCustomTypes.CustomImage,
        style: {
          width: '200px',
          height: '200px',
        },
      },
      select: true,
    });
  }

  addCommands();
  addComponents();
  addBlocks();
});

export default GrapesCustomTypes.CustomImage;
