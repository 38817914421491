import { defineMessages } from 'react-intl';

export default defineMessages({
  requiredFieldError: {
    id: 'app.common.inputErrors.requiredFieldError',
    defaultMessage: 'Required field',
  },
  // date, day and time
  minDateMessage: {
    id: 'app.common.inputErrors.minDateMessage',
    defaultMessage: 'Date must be in the future',
  },
  timeMatchFutureError: {
    id: 'app.common.inputErrors.timeMatchFutureError',
    defaultMessage: 'Time must be in the future',
  },
  dateInFeatureOrToday: {
    id: 'app.common.inputErrors.dateInFeatureOrToday',
    defaultMessage: 'Select a date in future or today',
  },
  endTimeBeforeStartTimeError: {
    id: 'app.common.inputErrors.endTimeBeforeStartTimeError',
    defaultMessage: 'End Time cannot be before Start Time',
  },
  endDateBeforeStartDateError: {
    id: 'app.common.inputErrors.endDateBeforeStartDateError',
    defaultMessage: 'End Date cannot be before Start Date',
  },
  startTimeAfterEndTimeError: {
    id: 'app.common.inputErrors.startTimeAfterEndTimeError',
    defaultMessage: 'Start Time cannot be after End Time',
  },
  startDateAfterEndDateError: {
    id: 'app.common.inputErrors.startDateAfterEndDateError',
    defaultMessage: 'Start Date cannot be after End Date',
  },
  endTimeEqualStartTimeError: {
    id: 'app.common.inputErrors.startTimeEqualEndTimeError',
    defaultMessage: 'End Time cannot be equal to Start Time',
  },
  notInClubRangeErrorMessage: {
    id: 'app.common.inputErrors.notInClubRangeErrorMessage',
    defaultMessage: 'Selected availability schedule exceeds club working hours',
  },
  notInPackageRangeErrorMessage: {
    id: 'app.common.inputErrors.notInPackageRangeErrorMessage',
    defaultMessage: 'Selected schedule exceeds bundle availability range',
  },
  firstNameLengthError: {
    id: 'app.common.inputErrors.firstNameLengthError',
    defaultMessage: 'First Name must be 50 characters or less',
  },
  firstNameMatchError: {
    id: 'app.common.inputErrors.firstNameMatchError',
    defaultMessage: 'First Name must contain only letters',
  },
  lastNameMatchError: {
    id: 'app.common.inputErrors.lastNameMatchError',
    defaultMessage: 'Last Name must contain only letters',
  },
  lastNameLengthError: {
    id: 'app.common.inputErrors.firstNameLengthError',
    defaultMessage: 'Last Name must be 50 characters or less',
  },
  emailInvalidError: {
    id: 'app.common.inputErrors.emailInvalidError',
    defaultMessage: 'Invalid Email',
  },
  barcodeMatchError: {
    id: 'app.common.inputErrors.barcodeMatchError',
    defaultMessage: 'Invalid Barcode',
  },
  barcodeLengthError: {
    id: 'app.common.inputErrors.barcodeLengthError',
    defaultMessage: 'Barcode must be 20 characters or less',
  },
  pinCodeLengthError: {
    id: 'app.common.inputErrors.pinCodeLengthError',
    defaultMessage: 'PIN code must be 4 digits',
  },
  healthPlanLengthError: {
    id: 'app.common.inputErrors.healthPlanLengthError',
    defaultMessage: 'Health Plan must be 300 characters or less',
  },
  insuranceCompanyLengthError: {
    id: 'app.common.inputErrors.insuranceCompanyLengthError',
    defaultMessage: 'Insurance Company must be 300 characters or less',
  },
  insuranceIdLengthError: {
    id: 'app.common.inputErrors.insuranceIdLengthError',
    defaultMessage: 'ID must be 50 characters or less',
  },
  insuranceGroupLengthError: {
    id: 'app.common.inputErrors.insuranceGroupLengthError',
    defaultMessage: 'Group must be 50 characters or less',
  },
  emergencyNameLengthError: {
    id: 'app.common.inputErrors.emergencyNameLengthError',
    defaultMessage: 'Name must be 50 characters or less',
  },
  emergencyNameMatchesError: {
    id: 'app.common.inputErrors.emergencyNameMatchesError',
    defaultMessage: 'Name must contain only letters',
  },
  emergencyAddressLengthError: {
    id: 'app.common.inputErrors.emergencyAddressLengthError',
    defaultMessage: 'Address must be 1000 characters or less',
  },
  subjectLengthError: {
    id: 'app.common.inputErrors.subjectLengthError',
    defaultMessage: 'Subject must be 255 characters or less',
  },
  phoneLengthError: {
    id: 'app.common.inputErrors.phoneLengthError',
    defaultMessage: 'Invalid Phone',
  },
  clubsCountError: {
    id: 'app.common.inputErrors.clubsCountError',
    defaultMessage: 'Clubs must be 1 or more',
  },
  billingOptionsCountError: {
    id: 'app.common.inputErrors.billingOptionsCount',
    defaultMessage: 'At least one billing option required.',
  },
  addressLengthError: {
    id: 'app.common.inputErrors.addressLengthError',
    defaultMessage: 'Address must be 100 characters or less',
  },
  cityMatchError: {
    id: 'app.common.inputErrors.cityMatchError',
    defaultMessage: 'Only alphabets are allowed for this field',
  },
  cityLengthError: {
    id: 'app.common.inputErrors.cityLengthError',
    defaultMessage: 'City must be 100 characters or less',
  },
  nameLengthError: {
    id: 'app.common.inputErrors.nameLengthError',
    defaultMessage: 'Name must be 100 characters or less',
  },
  vendorIdLengthError: {
    id: 'app.common.inputErrors.vendorIdLengthError',
    defaultMessage: 'Id must be 10 characters or less',
  },
  websiteMatchError: {
    id: 'app.common.inputErrors.websiteMatchError',
    defaultMessage: 'Enter valid URL',
  },
  accountNumberLengthError: {
    id: 'app.common.inputErrors.accountNumberLengthError',
    defaultMessage: 'The account number should be no more than 10 characters',
  },
  eventNameLengthError: {
    id: 'app.common.inputErrors.eventNameLengthError',
    defaultMessage: 'Event name must be 32 characters or less',
  },
  spotsMinLengthError: {
    id: 'app.common.inputErrors.spotsMinLengthError',
    defaultMessage: 'Spots amount must be a positive number',
  },
  countMinLengthError: {
    id: 'app.common.inputErrors.countMinLengthError',
    defaultMessage: 'Count must be greater than or equal to 1',
  },
  countMaxLengthError: {
    id: 'app.common.inputErrors.countMaxLengthError',
    defaultMessage: 'Count must be less than or equal to 1000',
  },
  descriptionLengthError: {
    id: 'app.common.inputErrors.descriptionLengthError',
    defaultMessage: 'Description must be 120 characters or less',
  },
  participantsNumberMatchError: {
    id: 'app.common.inputErrors.participantsNumberMatchError',
    defaultMessage: 'Number of participants exceeds the number of available spots',
  },
  reasonLengthError: {
    id: 'app.common.inputErrors.reasonLengthError',
    defaultMessage: 'Reason must be 1000 characters or less',
  },
  salesInfoLengthError: {
    id: 'app.common.inputErrors.salesInfoLengthError',
    defaultMessage: 'Other must be 1000 characters or less',
  },
  permissionSetLengthError: {
    id: 'app.common.inputErrors.permissionSetLengthError',
    defaultMessage: 'Select at least 1 value',
  },
  multipleCorpPeakLevelPoliciesError: {
    id: 'app.common.inputErrors.multipleCorpPeakLevelPoliciesError',
    defaultMessage: 'You can add only 1 policy in this permissions level',
  },
  clubCodeMatchError: {
    id: 'app.common.inputErrors.clubCodeMatchError',
    defaultMessage: 'Club code must be 4 numeric characters',
  },
  generalTitleMinLength: {
    id: 'app.common.inputErrors.generalTitleMinLength',
    defaultMessage: 'Title length should be from 2 up to 100 characters',
  },
  nameValueMaxLengthError: {
    id: 'app.common.inputErrors.nameValueMaxLengthError',
    defaultMessage: 'Value must be 32 characters or less',
  },
  valueMaxLengthError: {
    id: 'app.common.inputErrors.valueMaxLengthError',
    defaultMessage: 'Must be less than or equal to {value}',
  },
  valueMinLengthError: {
    id: 'app.common.inputErrors.valueMinLengthError',
    defaultMessage: 'Must be greater than or equal to {value}',
  },
  splitsSumNotEqualBillingPriceError: {
    id: 'app.common.inputErrors.splitsSumNotEqualBillingPriceError',
    defaultMessage: 'Sum must be equal to price per billing',
  },
  inactiveResourceError: {
    id: 'app.common.inputErrors.inactiveResourceError',
    defaultMessage: 'Please remove inactive resource',
  },
  inactiveEventTagError: {
    id: 'app.common.inputErrors.inactiveEventTagError',
    defaultMessage: 'Please remove inactive event tag',
  },
  splitsSumNotEqualDownError: {
    id: 'app.common.inputErrors.splitsSumNotEqualDownError',
    defaultMessage: 'Sum must be equal to total down amount',
  },
  greaterThanZero: {
    id: 'app.common.inputErrors.greaterThanZero',
    defaultMessage: 'Should be a positive number',
  },
  cannotBeNegative: {
    id: 'app.common.inputErrors.cannotBeNegative',
    defaultMessage: 'Cannot be negative',
  },
  invalidZipCode: {
    id: 'app.common.inputErrors.invalidZipCode',
    defaultMessage: 'Invalid Zip Code',
  },
  subjectMaxLengthError: {
    id: 'app.common.inputErrors.subjectMaxLengthError',
    defaultMessage: 'Subject must be 70 characters or less',
  },
  clockInClockOutTimeError: {
    id: 'app.common.inputErrors.clockInClockOutTimeError',
    defaultMessage: 'Clock in time should be less than clock out time',
  },
  // password
  passwordMaxLengthError: {
    id: 'app.common.inputErrors.passwordMaxLengthError',
    defaultMessage: 'Password must contain a length maximum of 20 characters',
  },
  passwordMinLengthError: {
    id: 'app.common.inputErrors.passwordMinLengthError',
    defaultMessage: 'Password Hint must contain a length maximum of 50 characters',
  },
  passwordLengthError: {
    id: 'app.common.inputErrors.passwordLengthError',
    defaultMessage:
      'Password must contain a length of at least 8 characters and a maximum of 20 characters',
  },
  invalidPassword: {
    id: 'app.common.inputErrors.invalidPassword',
    defaultMessage:
      "Please use only letters (a-z and A-Z), numbers (0-9), and special characters like !@#$%^&*.()'",
  },
  passwordDigitError: {
    id: 'app.common.inputErrors.passwordDigitError',
    defaultMessage: 'Password must contain at least one digit',
  },
  passwordLowercaseCharacterError: {
    id: 'app.common.inputErrors.passwordLowercaseCharacterError',
    defaultMessage: 'Password must contain at least one lowercase Latin character [a-z]',
  },
  passwordUppercaseCharacterError: {
    id: 'app.common.inputErrors.passwordUppercaseCharacterError',
    defaultMessage: 'Password must contain at least one uppercase Latin character [A-Z]',
  },
  passwordSpecialCharacterError: {
    id: 'app.common.inputErrors.passwordSpecialCharacterError',
    defaultMessage: "Password must contain at least one special character like !@#$%^&*_.()'",
  },
  passwordsDoNotMatchError: {
    id: 'app.common.inputErrors.passwordsDoNotMatchError',
    defaultMessage: 'Passwords do not match',
  },
  inactiveInstanceInputError: {
    id: 'app.common.inputErrors.inactiveInstanceInputError',
    defaultMessage: 'All the entities should be active',
  },
  amountHigherThanLeftToPay: {
    id: 'app.common.inputErrors.amountHigherThanLeftToPay',
    defaultMessage: 'Should be lower than left to pay',
  },
  amountHigherThanBalance: {
    id: 'app.common.inputErrors.amountHigherThanBalance',
    defaultMessage: 'Should be lower than balance',
  },
  amountHigherThanClientRewards: {
    id: 'app.common.inputErrors.amountHigherThanClientRewards',
    defaultMessage: 'Should be lower than client rewards',
  },
  tenderedLowerThanAmount: {
    id: 'app.common.inputErrors.tenderedLowerThanAmount',
    defaultMessage: 'Should be equal or more than amount to pay',
  },
  changeHigherThanTendered: {
    id: 'app.common.inputErrors.changeHigherThanTendered',
    defaultMessage: 'Cannot be more than tendered',
  },
  changeCountError: {
    id: 'app.common.inputErrors.changeCountError',
    defaultMessage: 'Change should be the result of substracting amount from tendered',
  },
  linkMaxLengthError: {
    id: 'app.common.inputErrors.linkMaxLengthError',
    defaultMessage: 'Link title must be 200 characters or less',
  },
  mdLicenceMinLengthError: {
    id: 'app.common.inputErrors.mdLicenceMinLengthError',
    defaultMessage: 'Medical licence should be more than 2 symbols long',
  },
  mdLicenceMaxLengthError: {
    id: 'app.common.inputErrors.mdLicenceMaxLengthError',
    defaultMessage: 'Medical licence should be less than 150 symbols',
  },
  expiredCardError: {
    id: 'app.common.inputErrors.expiredCardError',
    defaultMessage: 'Expired. Please change card',
  },
  onlyFourNumbersOrLettersError: {
    id: 'app.common.inputErrors.onlyFourNumbersOrLettersError',
    defaultMessage: 'There can only be 4 numbers or letters',
  },
  weekdaysMinLengthError: {
    id: 'app.common.inputErrors.weekdaysMinLengthError',
    defaultMessage: 'Select at least one day',
  },
  intervalsOverlapError: {
    id: 'app.common.inputErrors.intervalsOverlapError',
    defaultMessage: 'Time intervals must not overlap with already added',
  },
  sizeMustBeBetweenError: {
    id: 'app.common.inputErrors.sizeMustBeBetweenError',
    defaultMessage: 'Size must be between {startNumber} and {endNumber}',
  },
  sizeMustBeError: {
    id: 'app.common.inputErrors.sizeMustBeError',
    defaultMessage: 'Size must be 9',
  },
  inactiveCardError: {
    id: 'app.common.inputErrors.inactiveCardError',
    defaultMessage: 'Card is inactive',
  },
  inactiveCheckingSavingsError: {
    id: 'app.common.inputErrors.inactiveCheckingError',
    defaultMessage: 'Checking/Savings is inactive',
  },
  redeemFamilyMembersError: {
    id: 'app.common.inputErrors.redeemFamilyMembersError',
    defaultMessage: "You can't select more than {value} family members",
  },
  checksAmountCountError: {
    id: 'app.common.inputErrors.checksAmountCountError',
    defaultMessage: 'Amount must be greater than or equal to 0',
  },
  checksAmountMinError: {
    id: 'app.common.inputErrors.checksAmountCountError',
    defaultMessage: 'Amount must be greater than 0',
  },
  varianceMaxLengthError: {
    id: 'app.common.inputErrors.varianceMaxLengthError',
    defaultMessage: 'Variance note must be 1000 characters or less',
  },
  minMaxError: {
    id: 'app.common.inputErrors.minMaxError',
    defaultMessage: "Min value can't be greater than max value",
  },
  payPeriodNameLengthError: {
    id: 'app.common.inputErrors.payPeriodNameLengthError',
    defaultMessage: 'Name must be 60 characters or less',
  },
  reasonRequiredError: {
    id: 'app.common.inputErrors.reasonRequiredError',
    defaultMessage: 'Reason must be one of the following values: Relocation, Disability, Other.',
  },
  attachmentCountError: {
    id: 'app.common.inputErrors.attachmentCountError',
    defaultMessage: 'You can add only 1 attachment',
  },
  idMinLengthError: {
    id: 'app.common.inputErrors.idMinLengthError',
    defaultMessage: 'ID (SSN/EIN) must be 9 characters',
  },
  ownerNameMaxLengthError: {
    id: 'app.common.inputErrors.ownerNameMaxLengthError',
    defaultMessage: 'Owner Name must be 22 characters or less',
  },
  softDescriptorAchMaxLengthError: {
    id: 'app.common.inputErrors.softDescriptorAchMaxLengthError',
    defaultMessage: 'Soft Description ACH must be 16 characters or less',
  },
  cameFromCodeLengthError: {
    id: 'app.common.inputErrors.cameFromCodeLengthError',
    defaultMessage: 'Code must be 2 characters',
  },
  recommendedFieldLabel: {
    id: 'app.common.inputErrors.recommendedField',
    defaultMessage: 'Recommended field',
  },
  creditCardCodeLengthValidationError: {
    id: 'app.common.inputErrors.creditCardCodeLengthValidationError',
    defaultMessage: 'Code must be at least 3 characters',
  },
  AMEXCreditCardCodeLengthValidationError: {
    id: 'app.common.inputErrors.AMEXCreditCardCodeLengthValidationError',
    defaultMessage: 'Code must be at least 4 characters',
  },
  invalidCardNumberMessageError: {
    id: 'app.common.inputErrors.invalidCardNumberMessageError',
    defaultMessage: 'Card number is invalid',
  },
  invalidExpiredDateMessageError: {
    id: 'app.common.inputErrors.invalidExpiredDateMessageError',
    defaultMessage: 'Expired date is invalid',
  },
  invalidCVVCodeMessageError: {
    id: 'app.common.inputErrors.invalidCVVCodeMessageError',
    defaultMessage: 'CVV is invalid',
  },
  endReasonCodeLengthError: {
    id: 'app.common.inputErrors.endReasonCodeLengthError',
    defaultMessage: 'Code must be 1 characters',
  },
});
