import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {
  ActionItem,
  ActionsMenu,
  DialogComponent,
  LoadingBackdrop,
  Table,
} from 'common/components';
import { ReactComponent as AddIcon } from 'img/icons/add_deprecated.svg';
import messages from 'common/components/PersonProfile/messages';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import { IHeadCell, ITableRow } from 'common/interfaces/table';
import tableHeaders from 'common/messages/tableHeaders';
import {
  ITicketShortDtoImt,
  TicketShortDtoImtList,
} from 'common/components/PersonProfile/interfaces';
import { DEFAULT_DATE_TIME_FORMAT } from 'common/constants/dateFormats';
import inputLabels from 'common/messages/inputLabels';
import { ticketStatuses } from 'common/components/PersonProfile/constants';
import { colors } from 'common/ui/theme/default';
import { CustomTheme } from 'common/ui/interfaces';

interface ITicketsViewModalOpen {
  isOpen: boolean;
  isLoading: boolean;
  onOpenTicketCreateModal: () => void;
  onOpenTicketViewModal: (ticketId: number) => void;
  tickets: TicketShortDtoImtList;
  onClose: () => void;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  rowIdTitle: {
    color: colors.primary,
  },
  menuItem: {
    '&:hover .MuiTypography-root': {
      color: theme.palette.primary.contrastText,
    },
  },
  actionItem: {
    display: 'flex',
    alignItems: 'center',
  },
  actionItemIcon: {
    color: theme.palette.text.secondary,
    width: '.875rem',
    height: '.875rem',
    marginRight: theme.spacing(1),
  },
}));

const TicketsViewModal = (props: ITicketsViewModalOpen): JSX.Element => {
  const {
    isOpen,
    isLoading,
    onClose,
    tickets,
    onOpenTicketCreateModal,
    onOpenTicketViewModal,
  } = props;

  const [timezoneMoment] = useTimezoneMoment();

  const classes = useStyles();

  const headerOptions = useMemo(
    (): IHeadCell[] => [
      {
        id: 'id',
        label: '#',
        sort: false,
      },
      {
        id: 'subject',
        label: <FormattedMessage {...inputLabels.subject} />,
        sort: false,
      },
      {
        id: 'club',
        label: <FormattedMessage {...tableHeaders.club} />,
        sort: false,
      },
      {
        id: 'entrance',
        label: <FormattedMessage {...tableHeaders.openedDate} />,
        sort: false,
      },
      {
        id: 'date',
        label: <FormattedMessage {...tableHeaders.closedDate} />,
        sort: false,
      },
      {
        id: 'status',
        label: <FormattedMessage {...tableHeaders.status} />,
        sort: false,
      },
    ],
    [],
  );

  const rows = useMemo(() => {
    return tickets
      ?.map(
        (item: ITicketShortDtoImt): ITableRow => {
          const ticketStatus = ticketStatuses.find(item.get('status'));

          return {
            id: item.get('id').toString(),
            cells: [
              {
                label: (
                  <Link
                    underline="none"
                    component="button"
                    onClick={() => onOpenTicketViewModal(item.get('id'))}
                  >
                    <Typography variant="h6" className={classes.rowIdTitle}>
                      {item.get('id')}
                    </Typography>
                  </Link>
                ),
              },
              {
                label: item.get('subject') || '-',
              },
              {
                label: item.get('club'),
              },
              {
                label: `${timezoneMoment(item.get('openedDate')).format(DEFAULT_DATE_TIME_FORMAT)}`,
              },
              {
                label: item.get('closedDate')
                  ? `${timezoneMoment(item.get('closedDate')).format(DEFAULT_DATE_TIME_FORMAT)}`
                  : '-',
              },
              {
                label: (
                  <Typography style={{ color: ticketStatus?.color }}>
                    {ticketStatuses.translate(item.get('status'))}
                  </Typography>
                ),
              },
            ],
          };
        },
      )
      .toJS();
  }, [classes.rowIdTitle, onOpenTicketViewModal, tickets, timezoneMoment]);

  return (
    <DialogComponent
      isOpen={isOpen}
      onClose={onClose}
      title={<FormattedMessage {...messages.ticketsBlockTitle} />}
      additionalHeaderSettings={
        <Box ml={2}>
          <ActionsMenu horizontal>
            <ActionItem key="1" onClick={onOpenTicketCreateModal} className={classes.menuItem}>
              <Typography className={classes.actionItem}>
                <AddIcon className={classes.actionItemIcon} />

                <Typography variant="body1">
                  <FormattedMessage {...messages.newTicketTitle} />
                </Typography>
              </Typography>
            </ActionItem>
          </ActionsMenu>
        </Box>
      }
      size="lg"
      submitBtn={null}
      cancelBtn={null}
    >
      <>
        <LoadingBackdrop isLoading={isLoading} />
        <Table rows={rows} headerOptions={headerOptions} hideSearchInput hidePagination />
      </>
    </DialogComponent>
  );
};

export default TicketsViewModal;
