import { countPricePerBilling, getSplitsPayment } from 'modules/services/utils/billingUtils';

import { initialValues } from 'common/components/Steps/TrialMembershipStep/initialValues';

import {
  IEditablePackageConfiguration,
  IPackageConfiguration,
  IAddIncludedPackageDTO,
} from 'common/interfaces/membership';

export const transformPackageToDTO = (
  packageItem: IEditablePackageConfiguration,
): IAddIncludedPackageDTO => {
  const {
    billingOption,
    downPayment,
    durationEditableNumber,
    paymentGrace,
    splitting,
    paymentEditableSchedule,
    services,
    freeze,
    dayTimeAvailDtoList,
    feeSection: { fees },
    outOfTermBillingOption,
  } = packageItem;

  const { ...rest } = packageItem;

  return {
    ...rest,
    durationNumber: durationEditableNumber?.value,
    billingOption,
    paymentSchedule: paymentEditableSchedule?.value,
    dayTimeAvails: dayTimeAvailDtoList,
    hasCustomDayTimeAvailability: !!dayTimeAvailDtoList?.length,
    // splitting
    paymentSplitEnabled: !!splitting && splitting.allow,
    paymentNumber: splitting?.paymentEditableNumber?.value,
    paymentSplits: splitting?.paymentEditableSplits,
    downPaymentEnabled: !!downPayment && downPayment.allow,
    downPaymentNumber: downPayment?.editableNumber?.value,
    downPaymentAmount: downPayment?.editableAmount?.value,
    downPaymentSplits: downPayment?.editableSplits,
    paymentGraceEnabled: !!paymentGrace && paymentGrace.allow,
    paymentGraceNumber: paymentGrace?.editableDurationNumber?.value,
    freezeDaysNumber: freeze?.editableDaysNumber?.value ?? null,

    services: services
      .filter(el => el.included)
      .map(service => ({
        ...service,
        ...(service.package && { package: transformPackageToDTO(service.package) }),
      })),

    fees: fees
      ?.filter(el => el.included)
      .map(fee => ({
        id: fee.id,
        totalAmount: fee.totalAmount?.value,
        chargeAfterDays: fee.chargeAfterDays?.value,
        paymentNumber: fee.split ? fee.paymentsNumber?.value : null,
        paymentSplits: fee.split ? fee.editableSplits : null,
        fee: fee.fee,
      })),
    outOfTermBillingOption: {
      ...outOfTermBillingOption,
      billingOption: outOfTermBillingOption?.id,
      freezeDaysNumber: outOfTermBillingOption?.freeze?.editableDaysNumber?.value,
      paymentGraceEnabled: outOfTermBillingOption?.paymentGrace.allow,
      paymentGraceNumber: outOfTermBillingOption?.paymentGrace.editableDurationNumber?.value,
      paymentNumber: outOfTermBillingOption?.splitting.paymentEditableNumber?.value,
      paymentSchedule: outOfTermBillingOption?.paymentEditableSchedule?.value,
      paymentSplitEnabled: outOfTermBillingOption?.paymentSplitting?.allow,
      paymentSplits: outOfTermBillingOption?.paymentSplitting?.paymentEditableSplits,
    },
  };
};

export const transformPackageFromDTO = (
  packageItem: IPackageConfiguration,
): IEditablePackageConfiguration => {
  const billingOption = packageItem.billingOptions?.find(option => option.useAsDefault);

  const {
    id: billingOptionId,
    totalAmount,
    paymentSplitting,
    splitting,
    downPayment,
    paymentGrace,
    paymentEditableSchedule,
    creditCardFee,
    freeze,
  } = billingOption || {};

  const { revenueCode, durationEditableNumber, customDates } = packageItem;

  const countedTaxedPricePerBilling = countPricePerBilling(
    totalAmount,
    revenueCode?.totalTax,
    {
      type: packageItem.durationType,
      amount: durationEditableNumber?.value,
      fromDate: customDates?.startDate,
      toDate: customDates?.endDate,
    },
    paymentEditableSchedule?.value,
  );

  return {
    ...initialValues.packageConfiguration,
    ...packageItem,

    billingOption: billingOptionId,
    outOfTermBillingOption: packageItem.billingOptions?.find(item => item.isOutOfTerm),

    ...(billingOption && {
      totalAmount,
      splitting: {
        ...(splitting || paymentSplitting),
        ...(splitting.allow
          ? {
              paymentEditableSplits: getSplitsPayment(
                splitting.paymentEditableNumber.value,
                totalAmount,
                false,
              ),
            }
          : {}),
      },
      downPayment,
      paymentGrace,
      paymentEditableSchedule,
      creditCardFee,
      freeze,
    }),

    pricePerBilling: countedTaxedPricePerBilling,

    feeSection: {
      ...packageItem.feeSection,
      fees: packageItem.feeSection.fees.map(fee => ({
        ...fee,
        included: true,
        split: false,
        editableSplits: [
          ...getSplitsPayment(fee.paymentsNumber?.value || 0, fee.totalAmount?.value || 0, false),
        ],
      })),
    },

    ...(packageItem.services?.length && {
      services: packageItem.services.map(serviceItem =>
        serviceItem.package
          ? {
              ...serviceItem,
              included: true,
              package: transformPackageFromDTO(serviceItem.package),
            }
          : { ...serviceItem, included: true },
      ),
    }),
  };
};
