// libraries
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Control, useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { FormControl, FormHelperText, Grid, GridSize } from '@material-ui/core';
// utils
import { getAccessByPropPath } from 'common/utils/errorObject';
// custom interfaces
import { CustomTheme } from 'common/ui/interfaces';
import { IEditable } from 'modules/services/interfaces/packages';
// custom component
import NumberController from 'common/components/NumberController/NumberController';
import CheckBoxWrap from 'common/components/CheckBoxWrap/CheckBoxWrap';
import { NumberFormatProps } from 'react-number-format';
// messages
import { useRenderIntlMessage } from '../../hooks/useRenderIntlMessage';
import inputMessages from 'common/messages/inputLabels';

interface IMinMaxNumberInputProps {
  control: Control;
  trigger: (name?: string | string[]) => Promise<boolean>;
  errors: any;
  // names
  baseName: string;
  name?: string;
  minName?: string;
  maxName?: string;
  editableName?: string;
  // labels
  label: string | JSX.Element;
  minLabel?: string | JSX.Element;
  maxLabel?: string | JSX.Element;
  editableLabel?: string | JSX.Element;
  // defaultValues
  baseDefaultValue: IEditable;
  defaultValue?: number;
  defaultMinValue?: number;
  defaultMaxValue?: number;
  defaultEditableValue?: number;

  // input props
  numberFormatProps?: NumberFormatProps;

  // debouncing main input
  debounced?: boolean;

  // make inputs look invalid
  hasError?: boolean;

  // triggering validation on value change
  additionalTriggeredFields?: string | string[];

  // visual props
  xs?: GridSize;
  exs?: GridSize;
  sm?: GridSize;
  esm?: GridSize;
  md?: GridSize;
  emd?: GridSize;

  // should use controlled input model (no debounce this way)
  isControlled?: boolean;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  multiInputWrap: {
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      // padding: 0,
    },
    '& >div:first-child .MuiOutlinedInput-root': {
      borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
    },
    '& >div .MuiOutlinedInput-root': {
      borderRadius: 0,
      width: 'calc(100% + 1px)',
    },
    '& >div:last-child .MuiOutlinedInput-root': {
      borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
      width: '100%',
    },
  },
}));

const MinMaxNumberInput: React.FC<IMinMaxNumberInputProps> = (
  props: IMinMaxNumberInputProps,
): JSX.Element => {
  const classes = useStyles(props);
  const renderIntlMessage = useRenderIntlMessage();

  const {
    control,
    errors,
    trigger,
    // names
    baseName,
    name = `${baseName}.value`,
    minName = `${baseName}.minValue`,
    maxName = `${baseName}.maxValue`,
    editableName = `${baseName}.editable`,
    // labels
    label,
    minLabel = <FormattedMessage {...inputMessages.minLabel} />,
    maxLabel = <FormattedMessage {...inputMessages.maxLabel} />,
    editableLabel = <FormattedMessage {...inputMessages.editableLabel} />,
    // defaultValues
    baseDefaultValue,
    defaultValue = baseDefaultValue?.value,
    defaultMinValue = baseDefaultValue?.minValue,
    defaultMaxValue = baseDefaultValue?.maxValue,
    defaultEditableValue = baseDefaultValue?.editable,
    // additional props
    numberFormatProps,
    debounced,
    hasError,
    additionalTriggeredFields,
    // visual props
    xs = 12,
    exs = xs,
    sm,
    esm,
    md,
    emd,
    // controlled
    isControlled,
  } = props;

  // form

  const editableValue = useWatch({
    control,
    name: editableName,
  });

  const valueChangeTriggers = [baseName];
  if (additionalTriggeredFields) {
    if (typeof additionalTriggeredFields === 'string') {
      valueChangeTriggers.push(additionalTriggeredFields);
    } else {
      valueChangeTriggers.push(...additionalTriggeredFields);
    }
  }

  // renders

  const renderError = (): JSX.Element => {
    const mainErrors = getAccessByPropPath(errors, name);
    const minErrors = getAccessByPropPath(errors, minName);
    const maxErrors = getAccessByPropPath(errors, maxName);

    let errorMessage: string;

    if (mainErrors?.type === 'min') {
      errorMessage = renderIntlMessage(mainErrors.message, { value: mainErrors.message.value });
    }

    if (minErrors?.type === 'min') {
      errorMessage = renderIntlMessage(minErrors.message, { value: minErrors.message.value });
    }

    if (mainErrors?.type === 'max' && !maxErrors?.message) {
      errorMessage = renderIntlMessage(mainErrors.message, { value: mainErrors.message.value });
    }

    if (mainErrors?.type === 'required') {
      errorMessage = renderIntlMessage(mainErrors.message);
    }

    if (maxErrors?.type === 'minMaxError') {
      errorMessage = renderIntlMessage(maxErrors.message);
    }

    if (maxErrors?.type === 'min') {
      errorMessage = renderIntlMessage(maxErrors.message);
    }

    return <FormHelperText error>{errorMessage}</FormHelperText>;
  };

  return (
    <Grid
      item
      xs={editableValue ? exs : xs}
      sm={editableValue ? esm : sm}
      md={editableValue ? emd : md}
    >
      <FormControl fullWidth>
        <CheckBoxWrap
          control={control}
          label={editableLabel}
          name={editableName}
          className={editableValue ? classes.multiInputWrap : ''}
          defaultValue={defaultEditableValue}
          trigger={trigger}
          additionalTriggeredFields={baseName}
        >
          <Grid item xs={editableValue ? 4 : 12}>
            <NumberController
              control={control}
              name={name}
              label={label}
              debounced={debounced}
              isControlledComponent={isControlled}
              defaultValue={defaultValue}
              numberFormatProps={numberFormatProps}
              error={!!getAccessByPropPath(errors, name) || hasError}
              trigger={trigger}
              additionalTriggeredFields={valueChangeTriggers}
            />
          </Grid>
          {editableValue && (
            <>
              <Grid item xs={4}>
                <NumberController
                  control={control}
                  name={minName}
                  label={minLabel}
                  numberFormatProps={numberFormatProps}
                  defaultValue={defaultMinValue}
                  error={!!getAccessByPropPath(errors, minName) || hasError}
                  trigger={trigger}
                  additionalTriggeredFields={baseName}
                />
              </Grid>
              <Grid item xs={4}>
                <NumberController
                  control={control}
                  name={maxName}
                  label={maxLabel}
                  numberFormatProps={numberFormatProps}
                  defaultValue={defaultMaxValue}
                  error={!!getAccessByPropPath(errors, maxName) || hasError}
                  trigger={trigger}
                  additionalTriggeredFields={baseName}
                />
              </Grid>
            </>
          )}
        </CheckBoxWrap>

        {renderError()}
      </FormControl>
    </Grid>
  );
};

export default MinMaxNumberInput;
