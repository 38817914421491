import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { List as ImmutableList } from 'immutable';

import { LeadStatuses } from 'modules/crm/constants/leads';
import { ILeadImt } from 'modules/crm/interfaces/leads';

import LeadsColumn from './LeadsColumn';
import { IConstOption } from 'common/constants/classes';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2),
    },
    [theme.breakpoints.down('md')]: {
      height: 'auto',
    },
  },
  column: {
    height: '100%',
    padding: theme.spacing(0, 1),
    '&:first-of-type': {
      paddingLeft: 0,
    },
  },
}));

interface IProps {
  items: ImmutableList<ILeadImt>;
  onLoadMore: (status: IConstOption, offset: number) => void;
  isCrmModule: boolean;
  onChangeStatus: (leadId: string) => void;
  onChangeSalesperson: (leadId: string) => void;
}

const LeadsPipeline = ({
  items,
  onLoadMore,
  onChangeStatus,
  onChangeSalesperson,
  isCrmModule,
}: IProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      {LeadStatuses.values.map(status => (
        <Box className={classes.column} key={status.key}>
          <LeadsColumn
            status={status}
            color={status.color}
            isCrmModule={isCrmModule}
            items={items.filter(i => i.get('salesStatus') === status.value)}
            onScrollEnd={onLoadMore}
            onChangeStatus={onChangeStatus}
            onChangeSalesperson={onChangeSalesperson}
          />
        </Box>
      ))}
    </Box>
  );
};

export default LeadsPipeline;
