import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import makeStyles from '@material-ui/core/styles/makeStyles';

// components
import { SignWaiversStep } from 'common/components';
// state
import {
  selectAppliedSignWaiver,
  selectIsWaiverHTMLLoading,
  selectSetApplyWaiverActionResult,
  selectSetSignWaiversActionResult,
  selectWaivers,
  selectWaiversIsLoading,
} from 'common/state/newPerson/waiver/selectors';
import {
  applyWaiverThunk,
  getWaiversThunk,
  printWaiver,
  resetWaivers,
  setSignWaiversActionResult,
  signWaiversThunk,
} from 'common/state/newPerson/waiver/actions';
import { selectRightPanelPersonId } from 'modules/front-desk/state/selectors';
import { enqueueErrorNotification } from 'common/state/notifications/actions';
import { getContactInfoThunk } from 'common/state/newPerson/contactInfo/actions';
import { selectContactInfo } from 'common/state/newPerson/contactInfo/selectors';
import { selectCreatedLead } from 'modules/crm/state/leads/selectors';
import { selectMemberId } from 'common/state/newPerson/primaryInfo/selectors';
import {
  fetchSenderAvailabilityThunk,
  resetBookingEvents,
} from 'modules/booking/state/senderAvailability/actions';
// helpers
import { closeSigWebTablet, getFieldName } from 'helpers';
// constants
import { SignType } from 'common/constants/contract';
import { ActionResult } from 'common/constants';
import { PeakModules } from 'common/constants/peakModules';
import { SenderAvailabilityTypeList } from 'modules/booking/constants/senderAvailability';
import { ENotificationType } from 'modules/booking/constants/notificationType';
// messages
import commonFileErrorMessages from 'common/messages/fileErrors';
// hooks
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { useAppDispatch } from 'store/hooks';
// interfaces
import { IPersonDocumentItemDto, IPersonDocumentToSignDto } from 'common/interfaces/contract';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import { ILeadProfileImt } from 'modules/crm/interfaces/leads';
import { IAttachment } from 'common/interfaces/uploadFile';
// common
import { StepContext } from 'common/createContext/stepContext';
import { ServerError } from 'common/errors/serverErrors';
import { useCombinePrintLoading } from 'common/hooks/useCombinePrintLoading';
import { WaiverStatus } from 'modules/crm/constants/leads';

interface SignWaiversStepContainerProps {
  module: PeakModuleForNewPersonType;
}

interface IWaiverStepFormValues {
  [key: string]: string | IAttachment | IAttachment[] | null;
}

const useStyles = makeStyles(() => ({
  signWaiverStepForm: {
    height: 'calc(100% - 180px)',
  },
}));

const SignWaiversStepContainer = ({ module }: SignWaiversStepContainerProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const [isSignedWaiverData, setIsSignedWaiverData] = useState<{ [key: string]: boolean }>({});

  const contactInfo = useSelector(selectContactInfo);
  const waivers = useSelector(selectWaivers);
  const rightPanelPersonId = useSelector(selectRightPanelPersonId);
  const memberProfileId = useSelector(selectMemberId);
  const signWaiversActionResult = useSelector(selectSetSignWaiversActionResult);
  const applyWaiverActionResult = useSelector(selectSetApplyWaiverActionResult);
  const appliedSignWaiver = useSelector(selectAppliedSignWaiver);
  const isLoading = useSelector(selectWaiversIsLoading);
  const isWaiverHTMLLoading = useSelector(selectIsWaiverHTMLLoading);
  const leadPrimaryInfo: ILeadProfileImt = useSelector(selectCreatedLead);

  const waiversList = waivers.toJS();

  const isCrmOrPTCrmModule =
    module === PeakModules.Crm ||
    module === PeakModules.PersonalTrainingCrm ||
    module === PeakModules.Members;

  const isFormInitializedRef = useRef(false);

  const { onNext } = useContext(StepContext);

  const renderIntlMessage = useRenderIntlMessage();

  const formMethods = useForm<{ [key: string]: any }>({
    defaultValues: {},
    shouldUnregister: false,
    mode: 'onBlur',
  });

  const classes = useStyles();

  const personId = isCrmOrPTCrmModule
    ? leadPrimaryInfo.get('id')
    : rightPanelPersonId || memberProfileId;

  const { reset, handleSubmit, setValue, setError } = formMethods;

  const signValues = Object.values(isSignedWaiverData);
  const noSignValues = waiversList.sort(item => item.status === WaiverStatus.NO_SIGN);

  const isSubmitEnabled =
    (waiversList.length === signValues.length && signValues.every(item => item)) ||
    noSignValues.length === waiversList.length;

  useEffect(() => {
    if (signWaiversActionResult === ActionResult.SUCCESS_ACTION) {
      onNext({ waivers });
      dispatch(setSignWaiversActionResult(null));
    }
  }, [signWaiversActionResult, waivers, onNext, dispatch]);

  useEffect(() => {
    if (applyWaiverActionResult === ActionResult.SUCCESS_ACTION) {
      const convertedWaiver = appliedSignWaiver.toJS();
      if (convertedWaiver.signature) {
        const topazSignatureFieldName = getFieldName('topazSignature', convertedWaiver.documentId);

        setValue(topazSignatureFieldName, convertedWaiver.signature);
      }
    }
  }, [applyWaiverActionResult, appliedSignWaiver, setValue]);

  useEffect(() => {
    dispatch(getWaiversThunk(personId, module));
    dispatch(getContactInfoThunk(personId, module));
  }, [personId, dispatch, module]);

  useEffect(() => {
    return () => {
      closeSigWebTablet({ shouldCallReject: false });
      dispatch(resetWaivers());
    };
  }, [dispatch]);

  const getFieldNames = useCallback((documentId: string) => {
    const signTypeFieldName = getFieldName('signType', documentId);
    const signInPersonFieldName = getFieldName('files', documentId);
    const signViaSmsFieldName = getFieldName('phone', documentId);
    const signViaEmailFieldName = getFieldName('email', documentId);
    const topazSignatureFieldName = getFieldName('topazSignature', documentId);

    return {
      signTypeFieldName,
      signInPersonFieldName,
      signViaSmsFieldName,
      signViaEmailFieldName,
      topazSignatureFieldName,
    };
  }, []);

  useEffect(() => {
    const { email, phones = [] } = contactInfo;
    if (waiversList.length && email && phones[0] && !isFormInitializedRef.current) {
      isFormInitializedRef.current = true;

      const initFormData = waiversList.reduce((acc, { documentId }) => {
        const {
          signTypeFieldName,
          signInPersonFieldName,
          signViaSmsFieldName,
          signViaEmailFieldName,
          topazSignatureFieldName,
        } = getFieldNames(documentId);

        return {
          ...acc,
          [signViaEmailFieldName]: email,
          [signViaSmsFieldName]: phones[0].phoneId,
          [signInPersonFieldName]: [],
          [signTypeFieldName]: SignType.ViaTopaz,
          [topazSignatureFieldName]: {},
        };
      }, {});

      reset(initFormData);
    }
  }, [waiversList, reset, contactInfo, getFieldNames]);

  const isPrinting = useCombinePrintLoading(isWaiverHTMLLoading);

  const onSubmitForm = (values: IWaiverStepFormValues) => {
    const sendingData: IPersonDocumentToSignDto[] = waivers
      .toJS()
      .map(({ documentId }: IPersonDocumentItemDto) => {
        const {
          signViaEmailFieldName,
          topazSignatureFieldName,
          signTypeFieldName,
          signInPersonFieldName,
          signViaSmsFieldName,
        } = getFieldNames(documentId);

        const email = values[signViaEmailFieldName];
        const phone = values[signViaSmsFieldName];
        const signType = values[signTypeFieldName];
        const topazSignature = values[topazSignatureFieldName];
        const files = values[signInPersonFieldName];

        const defaultSendingData = {
          attachments: [],
          documentId,
          signType,
          signed: true,
        };

        if (email && signType === SignType.SendViaEmail) {
          return {
            ...defaultSendingData,
            email,
          };
        }

        if (phone && signType === SignType.SendViaSMS) {
          return {
            ...defaultSendingData,
            phoneId: phone,
          };
        }

        if (signType === SignType.InPerson) {
          if ((files as IAttachment[] | undefined)?.length) {
            return {
              ...defaultSendingData,
              attachments: files,
            };
          }

          setError(signInPersonFieldName, {
            message: renderIntlMessage(commonFileErrorMessages.fileCountError),
          });
        }

        if ((topazSignature as IAttachment)?.id && signType === SignType.ViaTopaz) {
          return {
            ...defaultSendingData,
            signature: topazSignature,
          };
        }

        return defaultSendingData;
      });

    if (isSubmitEnabled) {
      dispatch(signWaiversThunk(personId, sendingData, module));
    } else {
      dispatch(
        enqueueErrorNotification({
          codes: [ServerError.UNSIGNED_DOCUMENTS_ERROR],
          message: undefined,
        }),
      );
    }
  };

  const applyWaiverSignature = useCallback(
    (file: File, documentId: string) => {
      dispatch(applyWaiverThunk(personId, documentId, file, module));
    },
    [dispatch, personId, module],
  );

  const getEventWaiver = useCallback((): ENotificationType => {
    switch (module) {
      case PeakModules.Crm:
        return ENotificationType.CRM_LEAD_WAIVER_SENDING;
      case PeakModules.PersonalTrainingCrm:
        return ENotificationType.PT_CRM_LEAD_WAIVER_SENDING;
      case PeakModules.FrontDesk:
        return ENotificationType.FRONTDESK_WAIVER_SENDING;
      case PeakModules.Members:
        return ENotificationType.MEMBER_WAIVER_SENDING;
      default:
        return ENotificationType.WAIVER;
    }
  }, [module]);

  useEffect(() => {
    dispatch(
      fetchSenderAvailabilityThunk([SenderAvailabilityTypeList.DOCUMENTS], {
        module,
        events: [getEventWaiver()],
      }),
    );
    return () => {
      dispatch(resetBookingEvents({ type: SenderAvailabilityTypeList.DOCUMENTS }));
    };
  }, [dispatch, getEventWaiver, module]);

  const onPrintWaiver = useCallback(
    (waiverId: string) => {
      dispatch(printWaiver(personId, waiverId, module));
    },
    [dispatch, personId, module],
  );

  return (
    <FormProvider {...formMethods}>
      <form
        className={classes.signWaiverStepForm}
        id="waiver-form"
        onSubmit={handleSubmit(onSubmitForm)}
        autoComplete="none"
      >
        <SignWaiversStep
          contactInfo={contactInfo}
          fetchApplySignature={applyWaiverSignature}
          isLoading={isPrinting || isLoading}
          waivers={waivers}
          setIsSignedWaiverData={setIsSignedWaiverData}
          onPrintWaiver={onPrintWaiver}
          isSignedWaiverData={isSignedWaiverData}
        />
      </form>
    </FormProvider>
  );
};

export default SignWaiversStepContainer;
