import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';

import { actionTypes } from 'modules/corporations/constants/constants';
import { initialState } from 'common/constants/initialState';
import { mapToAddressLine } from 'common/utils/responseMapper';

const initialStateCorporationsList = fromJS({
  list: [],
  meta: {
    page: 0,
    perPage: 0,
    total: 0,
  },
  isLoading: false,
});

function corporationsListReducer(state = initialStateCorporationsList, action) {
  switch (action.type) {
    case actionTypes.FETCH_CORPORATIONS_LIST_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_CORPORATIONS_LIST_SUCCESS:
      return state
        .set('list', fromJS(action.payload.data))
        .set('meta', fromJS(action.payload.meta))
        .set('isLoading', false);
    case actionTypes.RESET_CORPORATIONS_LIST:
      return initialStateCorporationsList;
    default:
      return state;
  }
}

const initStateCorporationCreateForm = fromJS(initialState);

export const createCorporationReducer = (state = initStateCorporationCreateForm, action) => {
  switch (action.type) {
    case actionTypes.RESET_CREATED_CORPORATION:
      return initStateCorporationCreateForm;
    case actionTypes.CREATE_CORPORATION_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.CREATE_CORPORATION_SUCCESS:
      return state.set('result', fromJS(action.payload));
    default:
      return state;
  }
};

const initStateCorporationInfo = fromJS({
  corporationGeneralInfoView: {},
  isCorporationGeneralInfoViewLoading: false,

  corporationGeneralInfo: {},
  isCorporationGeneralInfoLoading: false,

  corporationStepInfo: {},
  isCorporationStepInfoLoading: false,

  corporationBankInfoView: {},
  isCorporationBankInfoViewLoading: false,

  updateCorporationStepInfo: initialState,
  corporationGeneralInfoUpdateActionResult: null,

  changeCorporationStatus: initialState,

  payPeriodInfo: {},
  payPeriodInfoLoading: false,

  allPayPeriodSettings: [],
  allPayPeriodSettingsLoading: false,

  createdPayPeriodSettingResult: null,
  createdPayPeriodSettingLoading: false,

  editPayPeriodSettingInfo: null,
  editPayPeriodSettingInfoLoading: false,

  updatedPayPeriodSettingResult: null,
  updatedPayPeriodSettingLoading: false,

  transferOwnershipAction: initialState,
});

const corporationInfoReducer = (state = initStateCorporationInfo, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CORPORATION_GENERAL_INFO_VIEW_LOADING:
      return state.set('isCorporationGeneralInfoViewLoading', action.payload);
    case actionTypes.FETCH_CORPORATION_GENERAL_INFO_VIEW:
      return state.set('corporationGeneralInfoView', fromJS(action.payload));
    case actionTypes.RESET_CORPORATION_GENERAL_INFO_VIEW:
      return state.set('corporationGeneralInfoView', fromJS({}));

    case actionTypes.FETCH_CORPORATION_GENERAL_INFO_LOADING:
      return state.set('isCorporationGeneralInfoLoading', action.payload);
    case actionTypes.FETCH_CORPORATION_GENERAL_INFO:
      return state.set('corporationGeneralInfo', fromJS(action.payload));
    case actionTypes.RESET_CORPORATION_GENERAL_INFO:
      return state.set('corporationGeneralInfo', fromJS({}));

    case actionTypes.FETCH_CORPORATION_STEP_INFO_LOADING:
      return state.set('isCorporationStepInfoLoading', action.payload);
    case actionTypes.FETCH_CORPORATION_STEP_INFO:
      return state.set('corporationStepInfo', fromJS(action.payload || {}));
    case actionTypes.RESET_CORPORATION_STEP_INFO:
      return state.set('corporationStepInfo', fromJS({}));

    case actionTypes.FETCH_CORPORATION_BANK_INFO_VIEW_LOADING:
      return state.set('isCorporationBankInfoViewLoading', action.payload);
    case actionTypes.FETCH_CORPORATION_BANK_INFO_VIEW:
      return state.set('corporationBankInfoView', fromJS(action.payload || {}));
    case actionTypes.RESET_CORPORATION_BANK_INFO_VIEW:
      return state.set('corporationBankInfoView', fromJS({}));

    case actionTypes.FETCH_CORPORATIONS_PAY_PERIOD_SUCCESS:
      return state.set('payPeriodInfo', fromJS(action.payload));
    case actionTypes.FETCH_CORPORATIONS_PAY_PERIOD_LOADING:
      return state.set('payPeriodInfoLoading', action.payload);

    case actionTypes.FETCH_ALL_PAY_PERIOD_SETTINGS_SUCCESS:
      return state.set('allPayPeriodSettings', fromJS(action.payload));
    case actionTypes.FETCH_ALL_PAY_PERIOD_SETTINGS_LOADING:
      return state.set('allPayPeriodSettingsLoading', action.payload);

    case actionTypes.CREATE_PAY_PERIOD_SETTING_SUCCESS:
      return state.set('createdPayPeriodSettingResult', fromJS(action.payload));
    case actionTypes.CREATE_CORPORATION_LOADING:
      return state.set('createdPayPeriodSettingLoading', action.payload);
    case actionTypes.RESET_CREATE_PAY_PERIOD_SETTING:
      return state.set('createdPayPeriodSettingResult', null);

    case actionTypes.GET_PAY_PERIOD_SETTING_INFO_SUCCESS:
      return state.set('editPayPeriodSettingInfo', fromJS(action.payload));
    case actionTypes.GET_PAY_PERIOD_SETTING_INFO_LOADING:
      return state.set('editPayPeriodSettingInfoLoading', action.payload);
    case actionTypes.RESET_GET_PAY_PERIOD_SETTING_INFO:
      return state.set('editPayPeriodSettingInfo', null);

    case actionTypes.UPDATE_PAY_PERIOD_SETTING_INFO_SUCCESS:
      return state.set('updatedPayPeriodSettingResult', fromJS(action.payload));
    case actionTypes.UPDATE_PAY_PERIOD_SETTING_INFO_LOADING:
      return state.set('updatedPayPeriodSettingLoading', action.payload);
    case actionTypes.RESET_UPDATE_PAY_PERIOD_SETTING_INFO:
      return state.set('updatedPayPeriodSettingResult', null);

    case actionTypes.UPDATE_CORPORATION_STEP_INFO_LOADING:
      return state.setIn(['updateCorporationStepInfo', 'isLoading'], action.payload);
    case actionTypes.UPDATE_CORPORATION_STEP_INFO:
      return state.setIn(['updateCorporationStepInfo', 'result'], fromJS(action.payload));
    case actionTypes.UPDATE_REQUIRED_PROFILE_INFO:
      const updatedData = state.get('corporationStepInfo').map(info => {
        return info.get('id') === action.payload.id ? fromJS(action.payload) : info;
      });

      return state
        .setIn(['updateCorporationStepInfo', 'result'], fromJS(action.payload))
        .set('corporationStepInfo', updatedData);
    case actionTypes.UPDATE_CORPORATION_BANK_INFO_STEP:
    case actionTypes.UPDATE_CORPORATION_INTEGRATIONS_STEP:
      return state
        .setIn(['updateCorporationStepInfo', 'result'], fromJS(action.payload))
        .update('corporationStepInfo', stepInfo =>
          fromJS({ ...stepInfo.toJS(), ...action.payload }),
        );
    case actionTypes.UPDATE_CORPORATION_GENERAL_STEP_INFO:
      const { address, ...rest } = action.payload;

      const generalInfoView = address
        ? { ...rest, addressLine: mapToAddressLine(address) }
        : action.payload;

      return state
        .setIn(['updateCorporationStepInfo', 'result'], fromJS(action.payload))
        .set('corporationGeneralInfoView', fromJS(generalInfoView));
    case actionTypes.UPDATE_CORPORATION_GENERAL_INFO_ACTION_RESULT:
      return state.set('corporationGeneralInfoUpdateActionResult', action.payload);
    case actionTypes.RESET_UPDATED_CORPORATION_STEP_INFO_RESULT:
      return state.setIn(['updateCorporationStepInfo', 'result'], fromJS({}));

    case actionTypes.CHANGE_CORPORATION_STATUS_LOADING:
      return state.setIn(['changeCorporationStatus', 'isLoading'], action.payload);
    case actionTypes.CHANGE_CORPORATION_STATUS:
      return state
        .setIn(['changeCorporationStatus', 'result'], fromJS(action.payload))
        .update('corporationGeneralInfoView', generalInfo =>
          generalInfo.set('active', action.payload.active),
        );
    case actionTypes.RESET_CORPORATION_CHANGE_STATUS_RESULT:
      return state.setIn(['changeCorporationStatus', 'result'], fromJS({}));
    case actionTypes.TRANSFER_OWNERSHIP_LOADING:
      return state.setIn(['transferOwnershipAction', 'isLoading'], action.payload);
    case actionTypes.TRANSFER_OWNERSHIP:
      return state.setIn(['transferOwnershipAction', 'result'], action.payload);
    case actionTypes.RESET_TRANSFER_OWNERSHIP_ACTION_RESULT:
      return state.setIn(['transferOwnershipAction', 'result'], null);
    default:
      return state;
  }
};

const initStatePayPeriodInfo = fromJS({
  payPeriod: {},
  payPeriodLoading: false,
  updatedPayPeriodResult: {},
  deactivatedPayPeriodsResult: false,
});

export const payPeriodInfoReducer = (state = initStatePayPeriodInfo, action) => {
  switch (action.type) {
    case actionTypes.RESET_GET_PAY_PERIOD_BY_ID:
      return initStatePayPeriodInfo;
    case actionTypes.GET_PAY_PERIOD_BY_ID_LOADING:
      return state.set('payPeriodLoading', action.payload);
    case actionTypes.GET_PAY_PERIOD_BY_ID_SUCCESS:
      return state.set('payPeriod', fromJS(action.payload));
    case actionTypes.DEACTIVATE_PAY_PERIODS_SUCCESS:
      return state.set('deactivatedPayPeriodsResult', action.payload);
    case actionTypes.CHANGE_PAY_PERIOD_STATUS_SUCCESS:
    case actionTypes.UPDATE_PAY_PERIOD_BY_ID_SUCCESS:
      return state.set('updatedPayPeriodResult', fromJS(action.payload));
    default:
      return state;
  }
};

export default combineReducers({
  corporationsList: corporationsListReducer,
  createdCorporation: createCorporationReducer,
  corporationStepInfo: corporationInfoReducer,
  payPeriodInfo: payPeriodInfoReducer,
});
