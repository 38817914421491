import React, { useRef } from 'react';
import { Box, Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';

import { FormContainer } from 'common/components';
import { EmailEditor } from 'modules/crm/components';
import EmailFormFields from './EmailFormFields/EmailFormFields';
import messages from 'modules/crm/messages/templates';
import { EmailTemplateSchema } from 'modules/crm/constants/shemas';
import {
  ICreateDocumentTemplateDto,
  IDocumentTemplateDtoImt,
} from 'common/interfaces/documentTemplate';
import { TemplatePurpose, TemplateTypes } from 'common/constants/documentTemplate';

const useStyles = makeStyles((theme: Theme) => ({
  editor: {
    '& .gjs-editor': {
      '& .gjs-pn-panels': {
        marginRight: theme.spacing(3),
      },
      '& .gjs-cv-canvas': {
        flex: 2,
        height: 'auto',
      },
      '& .gjs-block': {
        flexBasis: '25%',
        maxHeight: '80px',
        minHeight: 'auto',
      },
      '& .gjs-comp-selected': {
        outline: '2px solid red !important',
      },
    },
  },
}));

interface IEmailFormValues {
  from: string;
  fromName: string;
  name: string;
  subject: string;
  status: boolean;
}

interface IProps {
  template: IDocumentTemplateDtoImt;
  isLoading: boolean;
  onCancel: () => void;
  onSubmit: (doc: ICreateDocumentTemplateDto) => void;
}

const EmailForm = (props: IProps): JSX.Element => {
  const { isLoading, onCancel, onSubmit, template } = props;

  const editor = useRef(null);

  const classes = useStyles();

  const formMethods = useForm({
    defaultValues: {
      name: template.get('title') || '',
      subject: template.get('emailSubject') || '',
      from: template.get('senderEmail') || '',
      fromName: template.get('emailSenderName') || '',
      status: Boolean(template.get('active')),
      content: template.get('content'),
    },
    resolver: yupResolver(EmailTemplateSchema),
    mode: 'onBlur',
  });
  const { handleSubmit } = formMethods;

  const formSubmit = (values: IEmailFormValues) => {
    const style = editor.current.getCss();
    const content = `<div><style>${style}</style>${editor.current.getHtml()}</div>`;

    onSubmit({
      title: values.name,
      content,
      active: values.status,
      emailSenderName: values.fromName,
      emailSubject: values.subject,
      senderEmail: values.from,
      purposeType: TemplatePurpose.CampaignTemplate,
      attachments: [],
      type: TemplateTypes.Email,
      components: JSON.stringify(editor.current.getComponents()),
      style: JSON.stringify(style),
    });
  };

  const onEditorInit = editorInstance => {
    if (template.size) {
      editorInstance.setComponents(
        JSON.parse(template.get('components')) || template.get('content'),
      );
      editorInstance.addStyle(JSON.parse(template.get('style')));
    }
  };

  return (
    <FormContainer
      title={
        <FormattedMessage
          {...(template?.size ? messages.editEmailTitle : messages.newEmailTitle)}
        />
      }
      onSubmit={handleSubmit(formSubmit)}
      onCancel={onCancel}
      isSubmitting={isLoading}
    >
      <form id="email-form">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} lg={4}>
            <FormProvider {...formMethods}>
              <EmailFormFields />
            </FormProvider>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.editor}>
              <EmailEditor id="gjs" ref={editor} width="100%" onInit={onEditorInit} />
            </Box>
          </Grid>
        </Grid>
      </form>
    </FormContainer>
  );
};

export default EmailForm;
