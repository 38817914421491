import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { TrialMembershipStep } from 'common/components';
import {
  selectMembershipInfoActionLoading,
  selectMembershipInfoActionResult,
  selectMembershipItem,
  selectMembershipItemLoading,
  selectMembershipPackages,
  selectMembershipPackagesLoading,
} from 'common/state/newPerson/membership/selectors';
import {
  fetchMembershipPackageConfiguration,
  fetchMembershipPackages,
  resetMembershipPackageItem,
  setMembershipInfo,
  updateMembershipInfoResult,
} from 'common/state/newPerson/membership/actions';
import {
  IMembershipFormNew,
  IMembershipPackageShort,
  IMembershipTransformed,
  IPackageConfigurationImt,
} from 'common/interfaces/membership';
import { ActionResult } from 'common/constants/globalConstants';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import { ILeadProfileImt } from 'modules/crm/interfaces/leads';
import { selectCurrentUserAvailableClubs } from 'modules/authentication/state/selectors';
import { selectCreatedLead, selectLeadForm } from 'modules/crm/state/leads/selectors';
import { PeakModules } from 'common/constants/peakModules';
import { fetchProfileInfoView } from 'common/components/PersonProfile/state/actions';
import { selectPrimaryInfo } from 'common/state/newPerson/primaryInfo/selectors';
import { useAppDispatch } from 'store/hooks';
import { transformPackageToDTO } from 'common/components/MembershipFormView/utils';
import { debounce } from '@material-ui/core';

export const transformMembershipFormValues = (
  formValues: IMembershipFormNew,
): IMembershipTransformed => {
  return {
    ...formValues,
    packageConfiguration: {
      ...transformPackageToDTO(formValues.packageConfiguration),
    },
  };
};

interface ITrialMembershipStepContainerProps {
  setSelectedPackage: (data: IMembershipPackageShort) => void;
  selectedPackage: IMembershipPackageShort;
  module: PeakModuleForNewPersonType;
  selectedClubId?: string;
  isChangePackagePlanMode?: boolean;
}

const TrialMembershipStepContainer = (props: ITrialMembershipStepContainerProps): JSX.Element => {
  const {
    module,
    selectedPackage,
    setSelectedPackage,
    selectedClubId,
    isChangePackagePlanMode,
  } = props;

  const dispatch = useAppDispatch();
  const primaryInfo = useSelector(selectPrimaryInfo);
  const membershipPackages = useSelector(selectMembershipPackages);
  const membershipPackagesLoading = useSelector(selectMembershipPackagesLoading);
  const updateMembershipResult = useSelector(selectMembershipInfoActionResult);
  const isActionLoading = useSelector(selectMembershipInfoActionLoading);
  const clubs = useSelector(selectCurrentUserAvailableClubs);
  const leadPrimaryInfo: ILeadProfileImt = useSelector(selectCreatedLead);
  const selectedLead: ILeadProfileImt = useSelector(selectLeadForm);
  const membershipPackage: IPackageConfigurationImt = useSelector(selectMembershipItem);
  const isMembershipPackageLoading: boolean = useSelector(selectMembershipItemLoading);

  const isCrmOrPTCrmModule =
    module === PeakModules.Crm ||
    module === PeakModules.PersonalTrainingCrm ||
    module === PeakModules.Members;

  const personId = isCrmOrPTCrmModule ? leadPrimaryInfo?.get('id') : primaryInfo?.get('id');
  const homeClubId = isCrmOrPTCrmModule
    ? leadPrimaryInfo?.get('homeClub')?.get('id') || selectedLead?.get('homeClub')?.get('id')
    : primaryInfo?.get('homeClub')?.get('id');
  const membershipPackageId = isCrmOrPTCrmModule
    ? leadPrimaryInfo?.get('membership')?.get('id')
    : primaryInfo?.get('membership')?.get('id');

  const currentClubId = useMemo(() => homeClubId || selectedClubId, [homeClubId, selectedClubId]);

  useEffect(() => {
    if (currentClubId) {
      dispatch(fetchMembershipPackages(currentClubId, module));
    }
  }, [dispatch, currentClubId, module]);

  useEffect(() => {
    if (updateMembershipResult === ActionResult.SUCCESS_ACTION) {
      dispatch(updateMembershipInfoResult(null));

      if (!isCrmOrPTCrmModule && isChangePackagePlanMode) {
        dispatch(fetchProfileInfoView(personId, module));
      }
    }
  }, [
    updateMembershipResult,
    dispatch,
    isChangePackagePlanMode,
    personId,
    isCrmOrPTCrmModule,
    module,
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetMembershipPackageItem());
    };
  }, [dispatch]);

  const handleOnSubmitMembershipStep = (data: IMembershipFormNew) => {
    const { club, membership } = data;

    const selectedMembership = membershipPackages
      .get('available')
      .find(packageItem => packageItem.get('id') === membership);

    if (selectedMembership) {
      setSelectedPackage({
        ...selectedMembership.toJS(),
        clubId: club,
      });
    }

    if (personId) {
      dispatch(setMembershipInfo(personId, transformMembershipFormValues(data), module));
    }
  };

  const handleChangeSelectedClub = (clubId: string) => {
    dispatch(fetchMembershipPackages(clubId, module));
  };

  const handleSearchMembershipPackages = useCallback(
    debounce((search: string) => {
      dispatch(fetchMembershipPackages(currentClubId, module, search));
    }, 500),
    [currentClubId, module],
  );

  const handleChangeMembershipPackage = (membershipId: string, clubId: string) => {
    if (!membershipId) {
      dispatch(resetMembershipPackageItem());
    } else {
      dispatch(fetchMembershipPackageConfiguration(membershipId, clubId, module));
    }
  };

  return (
    <TrialMembershipStep
      module={module}
      clubId={selectedClubId || homeClubId}
      clubs={clubs}
      membershipPackageId={membershipPackageId}
      membershipPackages={membershipPackages}
      membershipPackage={membershipPackage}
      selectedPackage={selectedPackage}
      membershipPackagesLoading={membershipPackagesLoading}
      onSubmit={handleOnSubmitMembershipStep}
      isResponseSuccess={updateMembershipResult === ActionResult.SUCCESS_ACTION}
      onChangeSelectedPackage={handleChangeMembershipPackage}
      onChangeSelectedClub={handleChangeSelectedClub}
      handleSearchMembershipPackages={handleSearchMembershipPackages}
      isLoading={isMembershipPackageLoading}
      isSubmitting={isActionLoading}
    />
  );
};

export default TrialMembershipStepContainer;
