import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';

import 'grapesjs/dist/css/grapes.min.css';

// import store from 'ducks';
import store from 'store'; // which store we should use?
import { ErrorBoundary } from 'components';
import IntlProvider from 'routes/LanguageProvider';
import Routes from 'routes';

import translationMessages from 'translations';

import ThemeProviderWrapper from 'common/createContext/themeProviderWrapper';
import SocketsHandler from 'common/components/SocketListener/SocketsHandler';
import {
  CheckinNotifications,
  SnackbarNotifications,
  PrintHTML,
  SilentPrintHTML,
  RecommendedFields,
} from 'common/containers';
import { NavigationProvider } from 'common/createContext/navigationContext';
import { SnackbarUtilsConfigurator } from 'common/utils/snackbarUtils';

class App extends React.Component {
  private renderProviders = ({ children }: any): JSX.Element => (
    <Provider store={store}>
      <ThemeProviderWrapper>
        <IntlProvider messages={translationMessages}>
          <ErrorBoundary>
            <SnackbarProvider
              maxSnack={3}
              autoHideDuration={2000}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              hideIconVariant
            >
              <SnackbarUtilsConfigurator />
              <NavigationProvider>{children}</NavigationProvider>
            </SnackbarProvider>
          </ErrorBoundary>
        </IntlProvider>
      </ThemeProviderWrapper>
    </Provider>
  );

  render(): JSX.Element {
    const Providers = this.renderProviders;

    return (
      <Providers>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
        <SocketsHandler />
        <PrintHTML />
        <RecommendedFields />
        <SilentPrintHTML />
        <SnackbarNotifications />
        <CheckinNotifications />
      </Providers>
    );
  }
}

export default App;
