import React from 'react';
import cx from 'classnames';
import { ListItemIcon, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { AllowedTo } from '../AllowedTo/AllowedTo';
import { CustomTheme } from 'common/ui/interfaces';

interface IActionItemProps {
  children: React.ReactNode;
  icon?: JSX.Element;
  disabled?: boolean;
  divider?: boolean;
  onClick?: () => void;
  className?: string;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  menuItem: {
    '&:hover .MuiListItemIcon-root': {
      color: theme.palette.background.paper,
    },

    '& .MuiListItemIcon-root:first-child': {
      marginRight: theme.spacing(1),
    },
  },
}));

const ActionItem = (props: IActionItemProps) => {
  const { children, icon, disabled, divider, className, onClick } = props;

  const classes = useStyles();

  return (
    <MenuItem
      className={cx(classes.menuItem, className)}
      onClick={onClick}
      disabled={disabled}
      divider={divider}
    >
      <AllowedTo perform={!!icon}>
        <ListItemIcon>{icon}</ListItemIcon>
      </AllowedTo>
      {children}
    </MenuItem>
  );
};

export default ActionItem;
