import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { CheckInBlock } from 'common/components/PersonProfile/components';
import messages from 'common/components/PersonProfile/messages';
import { ReactComponent as EditIcon } from 'img/icons/pencil_deprecated.svg';
import inputLabels from 'common/messages/inputLabels';
import { FieldInfo } from 'common/components';
import { TCustomerEmergencyContactDtoImt } from 'common/interfaces/additionalInfo';
import commonMessages from 'common/messages/messages';
import { Button } from 'common/components/index';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { CustomTheme } from 'common/ui/interfaces';
import { convertPhoneNumber } from 'helpers/common';

interface IEmergencyContact {
  emergencyContact: TCustomerEmergencyContactDtoImt;
  onOpenModal: () => void;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  editIcon: {
    width: '15px',
    height: '15px',
  },
}));

const EmergencyContact = (props: IEmergencyContact) => {
  const { emergencyContact, onOpenModal } = props;
  const classes = useStyles();

  return (
    <CheckInBlock
      title={<FormattedMessage {...messages.emergencyContactBlockTitle} />}
      buttonTitle={
        <Button hasHoverStyle color="primary">
          <EditIcon className={classes.editIcon} />
        </Button>
      }
      onClickButton={onOpenModal}
    >
      {emergencyContact?.size ? (
        <Grid xs={12} container item spacing={2}>
          <Grid item xs={6}>
            <FieldInfo
              label={<FormattedMessage {...inputLabels.name} />}
              description={emergencyContact.get('emergencyName')}
            />
          </Grid>
          <Grid item xs={6}>
            <FieldInfo
              label={<FormattedMessage {...inputLabels.phone} />}
              description={convertPhoneNumber(emergencyContact.get('emergencyPhone'))}
            />
          </Grid>
          <Grid item xs={12}>
            <FieldInfo
              hasNotEllipsesDescription
              label={<FormattedMessage {...inputLabels.address} />}
              description={emergencyContact.get('emergencyAddress1')}
            />
          </Grid>
        </Grid>
      ) : (
        <Typography variant="body2" color="textSecondary">
          <FormattedMessage {...commonMessages.notSpecified} />
        </Typography>
      )}
    </CheckInBlock>
  );
};

export default EmergencyContact;
