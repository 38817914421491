import axios from 'axios';

import Services from 'services/network';
import { mapCheckInDto } from 'common/utils/responseMapper';

import { ICheckIn, ICheckInResponse } from 'modules/front-desk/interfaces';
import { AlertTypes } from 'common/interfaces/alerts';
import { CustomerStatus } from 'modules/front-desk/constants/common/constants';

export default class CheckInService {
  private checkInsList: Array<ICheckIn> = [];

  public getRecentCheckIns = async (
    clubId: string,
    entranceId: string,
    customerType: CustomerStatus,
    alertType: AlertTypes,
  ): Promise<ICheckIn[]> => {
    const config = {
      params: {
        clubId,
        entranceId,
        customerType,
        alertType,
      },
    };
    const data = (await axios.get(`/api/v1/check-ins`, config)) as any;
    return data.map(mapCheckInDto);
  };

  public getCheckInInfo = (checkInId: string): Promise<ICheckInResponse> =>
    axios.get(`/api/v1/check-ins/${checkInId}`);

  public memberCheckIn = (personId: string, entranceId: string): Promise<ICheckInResponse> => {
    return axios.put(`/api/v1/front-desk/${personId}/checkins/manually`, null, {
      params: {
        entranceId,
      },
    });
  };

  public barcodeCheckIn = (barcode: string): Promise<void> => {
    return axios.get(`/api/v1/front-desk/barcode-check-in?barcode=${barcode}`);
  };

  /**
   * This checkIn used on front desk page, in checked manually block.
   * @param clientId - id of club probably?
   * @param personId - id of member to check in
   */

  public async updateRecentCheckIns(clientId: string, personId: string): Promise<ICheckIn[]> {
    const res: any = await new Promise((resolve, reject) => {
      setTimeout(() => {
        const members = Services.Members.getFoundGuestsList();
        const selectedMember: any = members.find(item => item.person.id === personId);

        selectedMember.createdAt = new Date().toString();
        selectedMember.checkInType = 'manual';

        const checkIn = {
          id: 10 + Math.random() * (100000 + 1 - 10),
          personId: selectedMember.person.id,
          personalId: selectedMember.person.personalId,
          photoUrl: selectedMember.person.photoUrl,
          firstName: selectedMember.person.firstName,
          lastName: selectedMember.person.lastName,
          birthday: selectedMember.person.birthday,
          checkInTime: selectedMember.createdAt,
          checkInType: selectedMember.checkInType,
          personType: selectedMember.person.type,
          alerts: selectedMember.alerts,
        };

        resolve(checkIn);
      }, 800);
    });
    this.checkInsList = [res, ...this.checkInsList];
    return this.checkInsList;
  }

  public memberKioskModeCheckIn = (
    personId: string,
    entranceId: string,
  ): Promise<ICheckInResponse> => {
    return axios.put(`/api/v1/front-desk/kiosk-mode/${personId}/checkins/manually`, null, {
      params: {
        entranceId,
      },
    });
  };
}
