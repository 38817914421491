import GrapesJS from 'grapesjs';

import { defaultToolbar } from './TextPlugin';

(GrapesJS as any).plugins.add('reply-content', function(editor) {
  function addComponents() {
    const defaultType = editor.DomComponents.getType('default');
    const { initToolbar } = defaultType.model.prototype;

    editor.DomComponents.addType('reply-content', {
      model: {
        ...defaultType.model,
        defaults: {
          ...defaultType.model.defaults,
          droppable: false,
          editablde: false,
          copyable: false,
          badgable: false,
          draggable: true,
          selectable: true,
          highlightable: false,
          hoverable: false,
          propagate: [
            'droppable',
            'editable',
            'removable',
            'draggable',
            'badgable',
            'copyable',
            'selectable',
            'hoverable',
            'highlightable',
          ],
        },
        init() {
          const attrs = this.getAttributes();
          const content = attrs.content || {};

          this.get('components').add(`
            <blockquote style="padding: 15px; margin: 10px; background-color: #f9f9f9; border-left: 2px solid lightgray;">
                ${content}
            </blockquote>
          `);

          this.addClass('reply-content');
        },
        initToolbar(args) {
          initToolbar.apply(this, args);

          this.set(
            'toolbar',
            defaultToolbar.filter(t => t.command !== 'tlb-clone'),
          );
        },
      },
      view: {
        ...defaultType.view,
        tagName: 'div',
      },
    });
  }

  addComponents();
});

export default 'reply-content';
