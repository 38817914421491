import { Route, Routes as SwitchRoutes } from 'react-router-dom';
import React, { lazy } from 'react';

import { FRONT_DESK_PATH, HOME_PATH } from 'common/constants/globalConstants';
import CustomRoute from 'modules/authentication/containers/CustomRoute/CustomRoute';
import { PermissionLevels } from 'common/constants/permissions';
import { TimeclockPaths } from 'modules/timeclock/constants';

const HomePageRoute = lazy(() => import('routes/HomePage/HomePage'));
const KidZoneRoutes = lazy(() => import('modules/kid-zone/routes'));
const FrontDeskRoutes = lazy(() => import('modules/front-desk/FrontDesk'));
const BookingRoutes = lazy(() => import('modules/booking/routes'));
const EmployeesRoutes = lazy(() => import('modules/employees/routes'));
const PeakEmployeesRoutes = lazy(() => import('modules/peakEmployees/routes'));
const CrmRoutes = lazy(() => import('modules/crm/routes/index'));
const PersonalTrainingCrmRoutes = lazy(() => import('modules/personal-training-crm/routes/index'));
const ReportsRoutes = lazy(() => import('modules/reports/routes'));
const PosSettingsRoutes = lazy(() => import('modules/pos-settings/routes'));
const PosKioskRoutes = lazy(() => import('modules/pos-kiosk/routes'));
const DictionariesRoutes = lazy(() => import('modules/dictionaries/routes'));
const ServicesRoutes = lazy(() => import('modules/services/routes'));
const MembersRoutes = lazy(() => import('modules/members/routes'));
const PermissionsRoutes = lazy(() => import('modules/permissions/routes'));
const CorporationsRoutes = lazy(() => import('modules/corporations/routes'));
const CorporateSettingsRoutes = lazy(() => import('modules/corporate-settings/routes'));
const ClubsRoutes = lazy(() => import('modules/clubs/routes'));
const ClubSettingsRoutes = lazy(() => import('modules/club-settings/routes'));
const MyProfileRoutes = lazy(() => import('modules/my-profile/routes'));
const PeakSettingsRoutes = lazy(() => import('modules/peak-settings/routes'));
const TimeclockRoutes = lazy(() => import('modules/timeclock/routes'));
const CommunicationRoutes = lazy(() => import('modules/communication/routes'));
const CatchAllRoute = lazy(() => import('../CatchAll'));

const ClientRoutes = () => {
  return (
    <SwitchRoutes>
      <Route
        path={HOME_PATH}
        element={
          <CustomRoute
            secure
            levels={[PermissionLevels.PEAK, PermissionLevels.CORPORATE, PermissionLevels.CLIENT]}
          >
            <HomePageRoute />
          </CustomRoute>
        }
      />

      <Route
        path={FRONT_DESK_PATH}
        element={
          <CustomRoute secure levels={[PermissionLevels.CLIENT]}>
            <FrontDeskRoutes />
          </CustomRoute>
        }
      />

      <Route path="kid-zone/*" element={<KidZoneRoutes />} />
      <Route path="booking/*" element={<BookingRoutes />} />
      <Route path="employees/*" element={<EmployeesRoutes />} />
      <Route path="peak-employees/*" element={<PeakEmployeesRoutes />} />
      <Route path="crm/*" element={<CrmRoutes />} />
      <Route path="personal-training-crm/*" element={<PersonalTrainingCrmRoutes />} />
      <Route path="reports/*" element={<ReportsRoutes />} />
      <Route path="pos-settings/*" element={<PosSettingsRoutes />} />
      <Route path="pos/*" element={<PosKioskRoutes />} />
      <Route path="dictionaries/*" element={<DictionariesRoutes />} />
      <Route path="services/*" element={<ServicesRoutes />} />
      <Route path="members/*" element={<MembersRoutes />} />
      <Route path="permissions/*" element={<PermissionsRoutes />} />
      <Route path="corporations/*" element={<CorporationsRoutes />} />
      <Route path="corporate-settings/*" element={<CorporateSettingsRoutes />} />
      <Route path="clubs/*" element={<ClubsRoutes />} />
      <Route path="club-settings/*" element={<ClubSettingsRoutes />} />
      <Route path="my-profile/*" element={<MyProfileRoutes />} />
      <Route path="peak-settings/*" element={<PeakSettingsRoutes />} />
      <Route path={`${TimeclockPaths.TIMECLOCK}/*`} element={<TimeclockRoutes />} />
      <Route path="communication/*" element={<CommunicationRoutes />} />
      <Route element={<CatchAllRoute />} />
    </SwitchRoutes>
  );
};

export default ClientRoutes;
