export const actionTypes = {
  FETCH_MEMBER_INVOICE: 'MEMBER_PORTAL/FETCH_MEMBER_INVOICE',
  FETCH_MEMBER_INVOICE_LOADING: 'MEMBER_PORTAL/FETCH_MEMBER_INVOICE_LOADING',
  RESET_MEMBER_INVOICE: 'MEMBER_PORTAL/RESET_MEMBER_INVOICE',

  FETCH_NVOICE_UNIT_COUNT: 'MEMBER_PORTAL/FETCH_NVOICE_UNIT_COUNT',

  ADD_INVOICE_UNIT: 'MEMBER_PORTAL/ADD_INVOICE_UNIT',
  ADD_INVOICE_UNIT_LOADING: 'MEMBER_PORTAL/ADD_INVOICE_UNIT_LOADING',
  DELETE_INVOICE_UNIT: 'MEMBER_PORTAL/DELETE_INVOICE_UNIT',

  CHANGE_INVOICE_UNIT_NUMBER: 'MEMBER_PORTAL/CHANGE_INVOICE_UNIT_NUMBER',
  CHANGE_INVOICE_UNIT_NUMBER_LOADING: 'MEMBER_PORTAL/CHANGE_INVOICE_UNIT_NUMBER_LOADING',

  // UPDATE_SOCKET_INVOICES_LIST: 'MEMBER_PORTAL/UPDATE_SOCKET_INVOICES_LIST',
  // REMOVE_SOCKET_INVOICE: 'MEMBER_PORTAL/REMOVE_SOCKET_INVOICE',

  CANCEL_INVOICE: 'MEMBER_PORTAL/CANCEL_INVOICE',
  CANCEL_INVOICE_LOADING: 'MEMBER_PORTAL/CANCEL_INVOICE_LOADING',
  RESET_CANCEL_INVOICE: 'MEMBER_PORTAL/RESET_CANCEL_INVOICE',

  UPDATE_INVOICE_LOADING: 'MEMBER_PORTAL/UPDATE_INVOICE_LOADING',

  SET_CHECKOUT_RESULT: 'MEMBER_PORTAL/SET_CHECKOUT_RESULT',
  RESET_CHECKOUT_RESULT: 'MEMBER_PORTAL/RESET_CHECKOUT_RESULT',

  RESET_INVOICE_OPERATING_STATE: 'MEMBER_PORTAL/RESET_INVOICE_OPERATING_STATE',

  FETCH_AVAILABLE_PAYMENT_METHODS_LOADING: 'MEMBER_PORTAL/FETCH_AVAILABLE_PAYMENT_METHODS_LOADING',
  RESET_AVAILABLE_PAYMENT_METHODS: 'MEMBER_PORTAL/RESET_AVAILABLE_PAYMENT_METHODS',
  FETCH_AVAILABLE_PAYMENT_METHODS_RESULT: 'MEMBER_PORTAL/FETCH_AVAILABLE_PAYMENT_METHODS_RESULT',

  UPDATE_INVOICE_WITH_SYNC_LOADING: 'MEMBER_PORTAL/UPDATE_INVOICE_WITH_SYNC_LOADING',
  UPDATE_INVOICE_WITH_SYNC_RESULT: 'MEMBER_PORTAL/UPDATE_INVOICE_WITH_SYNC_RESULT',
  UPDATE_INVOICE_WITH_SYNC_ERROR: 'MEMBER_PORTAL/UPDATE_INVOICE_WITH_SYNC_ERROR',
  RESET_UPDATE_INVOICE_WITH_SYNC: 'MEMBER_PORTAL/RESET_UPDATE_INVOICE_WITH_SYNC',

  FETCH_BALANCE_BY_CUSTOMER_ID_LOADING: 'MEMBER_PORTAL/FETCH_BALANCE_BY_CUSTOMER_ID_LOADING',
  FETCH_BALANCE_BY_CUSTOMER_ID: 'MEMBER_PORTAL/FETCH_BALANCE_BY_CUSTOMER_ID',
  RESET_BALANCE: 'MEMBER_PORTAL/RESET_BALANCE',

  STORE_CREDIT_CARD_LOADING: 'MEMBER_PORTAL/STORE_CREDIT_CARD_LOADING',
  STORE_CREDIT_CARD_RESULT: 'MEMBER_PORTAL/STORE_CREDIT_CARD_RESULT',
  RESET_STORED_CREDIT_CARD_RESULT: 'MEMBER_PORTAL/RESET_STORED_CREDIT_CARD_RESULT',

  FETCH_STORED_CREDIT_CARDS_LOADING: 'MEMBER_PORTAL/FETCH_STORED_CREDIT_CARDS_LOADING',
  FETCH_STORED_CREDIT_CARDS_RESULT: 'MEMBER_PORTAL/FETCH_STORED_CREDIT_CARDS_RESULT',
  RESET_STORED_CREDIT_CARDS_REDUCER: 'MEMBER_PORTAL/RESET_STORED_CREDIT_CARDS_REDUCER',

  FETCH_CHECKING_SAVINGS_DATA: 'MEMBER_PORTAL/FETCH_CHECKING_SAVINGS_DATA',
  FETCH_CHECKING_SAVINGS_DATA_LOADING: 'MEMBER_PORTAL/FETCH_CHECKING_SAVINGS_DATA_LOADING',
  ADD_CHECKING_SAVINGS_DATA: 'MEMBER_PORTAL/ADD_CHECKING_SAVINGS_DATA',
  ADD_CHECKING_SAVINGS_DATA_LOADING: 'MEMBER_PORTAL/ADD_CHECKING_SAVINGS_DATA_LOADING',
  ADD_CHECKING_SAVINGS_DATA_ACTION_RESULT: 'MEMBER_PORTAL/ADD_CHECKING_SAVINGS_DATA_ACTION_RESULT',
  DELETE_CHECKING_SAVINGS_ITEM: 'MEMBER_PORTAL/DELETE_CHECKING_SAVINGS_ITEM',
  DELETE_CHECKING_SAVINGS_ITEM_LOADING: 'MEMBER_PORTAL/DELETE_CHECKING_SAVINGS_ITEM_LOADING',
  DELETE_CHECKING_SAVINGS_ITEM_ACTION_RESULT:
    'MEMBER_PORTAL/DELETE_CHECKING_SAVINGS_ITEM_ACTION_RESULT',
  RESET_CHECKING_SAVINGS_DATA: 'MEMBER_PORTAL/RESET_CHECKING_SAVINGS_DATA',

  FETCH_PAYMENT_METHOD_BY_BARCODE_RESULT: 'MEMBER_PORTAL/FETCH_PAYMENT_METHOD_BY_BARCODE_RESULT',
  FETCH_PAYMENT_METHOD_BY_BARCODE_LOADING: 'MEMBER_PORTAL/FETCH_PAYMENT_METHOD_BY_BARCODE_LOADING',
  FETCH_PAYMENT_METHOD_BY_BARCODE_ERROR: 'MEMBER_PORTAL/FETCH_PAYMENT_METHOD_BY_BARCODE_ERROR',
  RESET_FETCH_PAYMENT_METHOD_BY_BARCODE: 'MEMBER_PORTAL/RESET_FETCH_PAYMENT_METHOD_BY_BARCODE',

  FETCH_RECEIPT_LOADING: 'MEMBER_PORTAL/FETCH_RECEIPT_LOADING',
};
