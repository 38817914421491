import React from 'react';
import { FormattedMessage } from 'react-intl';
import commonMessages from 'common/messages/messages';

export enum PoliciesPermissionSetItems {
  // Club level
  // Financial group
  SET_CLIENT_FEE_SCHEDULE = 'SET_CLIENT_FEE_SCHEDULE',
  // Point of sale group
  OPEN_REGISTER = 'OPEN_REGISTER',
  // Sales management
  SALES_VIEW_LEADS = 'SALES_VIEW_LEADS',
  SALES_EDIT_LEADS = 'SALES_EDIT_LEADS',
  // Collections group
  SEND_A_LETTER = 'SEND_A_LETTER',
  // corporate or club level
  // scheduling
  BOOKING_EVENTS_VIEW = 'BOOKING_EVENTS_VIEW',
  BOOKING_EVENTS_CREATE = 'BOOKING_EVENTS_CREATE',
  BOOKING_EVENTS_EDIT = 'BOOKING_EVENTS_EDIT',
  BOOKING_EVENTS_CANCEL = 'BOOKING_EVENTS_CANCEL',
  BOOKING_EVENTS_DELETE = 'BOOKING_EVENTS_DELETE',
  BOOKING_EVENTS_DUPLICATE = 'BOOKING_EVENTS_DUPLICATE',
  BOOKING_EVENTS_REMIND_PARTICIPANTS = 'BOOKING_EVENTS_REMIND_PARTICIPANTS',
  BOOKING_EVENTS_NOT_SELF_EDIT = 'BOOKING_EVENTS_NOT_SELF_EDIT',
  BOOKING_RESOURCES_VIEW = 'BOOKING_RESOURCES_VIEW',
  BOOKING_RESOURCES_CREATE = 'BOOKING_RESOURCES_CREATE',
  BOOKING_RESOURCES_EDIT = 'BOOKING_RESOURCES_EDIT',
  BOOKING_RESOURCES_CHANGE_STATUS = 'BOOKING_RESOURCES_CHANGE_STATUS',
  // crm
  CRM_TASK_VIEW = 'CRM_TASK_VIEW',
  CRM_TASK_EDIT_STATUS = 'CRM_TASK_EDIT_STATUS',
  CRM_TASK_EDIT_SALESPERSON = 'CRM_TASK_EDIT_SALESPERSON',
  CRM_LEAD_VIEW = 'CRM_LEAD_VIEW',
  CRM_LEAD_CREATE = 'CRM_LEAD_CREATE',
  CRM_LEAD_EDIT = 'CRM_LEAD_EDIT',
  CRM_LEAD_EDIT_STATUS = 'CRM_LEAD_EDIT_STATUS',
  CRM_LEAD_EDIT_SALESPERSON = 'CRM_LEAD_EDIT_SALESPERSON',
  CRM_SCRIPT_VIEW = 'CRM_SCRIPT_VIEW',
  CRM_SCRIPT_CREATE = 'CRM_SCRIPT_CREATE',
  CRM_SCRIPT_EDIT = 'CRM_SCRIPT_EDIT',
  CRM_SCRIPT_CHANGE_STATUS = 'CRM_SCRIPT_CHANGE_STATUS',
  CRM_CAMPAIGN_VIEW = 'CRM_CAMPAIGN_VIEW',
  CRM_CAMPAIGN_CREATE = 'CRM_CAMPAIGN_CREATE',
  CRM_CAMPAIGN_EDIT = 'CRM_CAMPAIGN_EDIT',
  // pt crm
  PT_CRM_TASK_VIEW = 'PT_CRM_TASK_VIEW',
  PT_CRM_TASK_EDIT_STATUS = 'PT_CRM_TASK_EDIT_STATUS',
  PT_CRM_TASK_EDIT_SALESPERSON = 'PT_CRM_TASK_EDIT_SALESPERSON',
  PT_CRM_LEAD_VIEW = 'PT_CRM_LEAD_VIEW',
  PT_CRM_LEAD_CREATE = 'PT_CRM_LEAD_CREATE',
  PT_CRM_LEAD_EDIT = 'PT_CRM_LEAD_EDIT',
  PT_CRM_LEAD_EDIT_STATUS = 'PT_CRM_LEAD_EDIT_STATUS',
  PT_CRM_LEAD_EDIT_SALESPERSON = 'PT_CRM_LEAD_EDIT_SALESPERSON',
  PT_CRM_SCRIPT_VIEW = 'PT_CRM_SCRIPT_VIEW',
  PT_CRM_SCRIPT_CREATE = 'PT_CRM_SCRIPT_CREATE',
  PT_CRM_SCRIPT_EDIT = 'PT_CRM_SCRIPT_EDIT',
  PT_CRM_SCRIPT_CHANGE_STATUS = 'PT_CRM_SCRIPT_CHANGE_STATUS',
  PT_CRM_CAMPAIGN_VIEW = 'PT_CRM_CAMPAIGN_VIEW',
  PT_CRM_CAMPAIGN_CREATE = 'PT_CRM_CAMPAIGN_CREATE',
  PT_CRM_CAMPAIGN_EDIT = 'PT_CRM_CAMPAIGN_EDIT',
  // member management
  MEMBER_VIEW = 'MEMBER_VIEW',
  MEMBER_CREATE = 'MEMBER_CREATE',
  MEMBER_EDIT = 'MEMBER_EDIT',
  // employee management
  EMPLOYEE_VIEW = 'EMPLOYEE_VIEW',
  EMPLOYEE_CREATE = 'EMPLOYEE_CREATE',
  EMPLOYEE_EDIT = 'EMPLOYEE_EDIT',
  EMPLOYEE_CHANGE_STATUS = 'EMPLOYEE_CHANGE_STATUS',
  EMPLOYEE_UPDATE_PERMISSIONS = 'EMPLOYEE_UPDATE_PERMISSIONS',
  EMPLOYEE_TAKE_PHOTO = 'EMPLOYEE_TAKE_PHOTO',
  EMPLOYEE_VIEW_DOCUMENTS = 'EMPLOYEE_VIEW_DOCUMENTS',
  EMPLOYEE_UPLOAD_DOCUMENTS = 'EMPLOYEE_UPLOAD_DOCUMENTS',
  // time clock
  TIME_CLOCK_VIEW = 'TIME_CLOCK_VIEW',
  TIME_CLOCK_DELETE = 'TIME_CLOCK_DELETE',
  TIME_CLOCK_IN = 'TIME_CLOCK_IN',
  TIME_CLOCK_OUT = 'TIME_CLOCK_OUT',
  TIME_CLOCK_PAYROLL_REPORT_VIEW = 'TIME_CLOCK_PAYROLL_REPORT_VIEW',
  TIME_CLOCK_PAYROLL_REPORT_EXPORT = 'TIME_CLOCK_PAYROLL_REPORT_EXPORT',
  // reporting
  REPORTS_VIEW = 'REPORTS_VIEW',
  REPORTS_EXPORT = 'REPORTS_EXPORT',
  OVERVIEW_REPORT_VIEW = 'OVERVIEW_REPORT_VIEW',
  OVERVIEW_REPORT_EXPORT = 'OVERVIEW_REPORT_EXPORT',
  SALES_REPORT_VIEW = 'SALES_REPORT_VIEW',
  SALES_REPORT_EXPORT = 'SALES_REPORT_EXPORT',
  INVOICE_REPORT_VIEW = 'INVOICE_REPORT_VIEW',
  INVOICE_REPORT_DETAILED_VIEW = 'INVOICE_REPORT_DETAILED_VIEW',
  INVOICE_REPORT_EXPORT = 'INVOICE_REPORT_EXPORT',
  INVOICE_REPORT_VIEW_SNAPSHOTS = 'INVOICE_REPORT_VIEW_SNAPSHOTS',
  INVOICE_REPORT_MAKE_SNAPSHOT = 'INVOICE_REPORT_MAKE_SNAPSHOT',
  INVOICE_REPORT_ASSIGN_MEMBER = 'INVOICE_REPORT_ASSIGN_MEMBER',
  INVOICE_REPORT_VOID = 'INVOICE_REPORT_VOID',
  Z_REPORT_VIEW = 'Z_REPORT_VIEW',
  Z_REPORT_DETAILED_VIEW = 'Z_REPORT_DETAILED_VIEW',
  Z_REPORT_EXPORT = 'Z_REPORT_EXPORT',
  OVER_TIME_REPORT_VIEW = 'OVER_TIME_REPORT_VIEW',
  OVER_TIME_REPORT_EXPORT = 'OVER_TIME_REPORT_EXPORT',
  REGISTER_STATUS_REPORT_VIEW = 'REGISTER_STATUS_REPORT_VIEW',
  REGISTER_STATUS_REPORT_EXPORT = 'REGISTER_STATUS_REPORT_EXPORT',
  REGISTER_STATUS_REPORT_CLOSE = 'REGISTER_STATUS_REPORT_CLOSE',
  TRANSACTION_REPORT_VIEW = 'TRANSACTION_REPORT_VIEW',
  TRANSACTION_REPORT_EXPORT = 'TRANSACTION_REPORT_EXPORT',
  REDEMPTION_REPORT_VIEW = 'REDEMPTION_REPORT_VIEW',
  REDEMPTION_REPORT_EXPORT = 'REDEMPTION_REPORT_EXPORT',
  REDEMPTION_REPORT_UNREDEEM = 'REDEMPTION_REPORT_UNREDEEM',
  DASHBOARD_REPORT_VIEW = 'DASHBOARD_REPORT_VIEW',
  DASHBOARD_REPORT_EXPORT = 'DASHBOARD_REPORT_EXPORT',
  CAMPAIGN_REPORT_VIEW = 'CAMPAIGN_REPORT_VIEW',
  CAMPAIGN_REPORT_DETAILED_VIEW = 'CAMPAIGN_REPORT_DETAILED_VIEW',
  CAMPAIGN_REPORT_EXPORT = 'CAMPAIGN_REPORT_EXPORT',
  APPOINTMENT_REPORT_VIEW = 'APPOINTMENT_REPORT_VIEW',
  APPOINTMENT_REPORT_DETAILED_VIEW = 'APPOINTMENT_REPORT_DETAILED_VIEW',
  APPOINTMENT_REPORT_EXPORT = 'APPOINTMENT_REPORT_EXPORT',
  // corporate level
  // Service management
  CORPORATE_SU = 'CORPORATE_SU',
  SERVICE_VIEW = 'SERVICE_VIEW',
  SERVICE_CREATE = 'SERVICE_CREATE',
  SERVICE_EDIT = 'SERVICE_EDIT',
  SERVICE_CHANGE_STATUS = 'SERVICE_CHANGE_STATUS',
  FEE_VIEW = 'FEE_VIEW',
  FEE_CREATE = 'FEE_CREATE',
  FEE_EDIT = 'FEE_EDIT',
  FEE_CHANGE_STATUS = 'FEE_CHANGE_STATUS',
  SERVICE_BUNDLE_VIEW = 'SERVICE_BUNDLE_VIEW',
  SERVICE_BUNDLE_CREATE = 'SERVICE_BUNDLE_CREATE',
  SERVICE_BUNDLE_EDIT = 'SERVICE_BUNDLE_EDIT',
  SERVICE_BUNDLE_CHANGE_STATUS = 'SERVICE_BUNDLE_CHANGE_STATUS',
  SERVICE_BUNDLE_DUPLICATE = 'SERVICE_BUNDLE_DUPLICATE',
  CONTRACT_VIEW = 'CONTRACT_VIEW',
  CONTRACT_CREATE = 'CONTRACT_CREATE',
  CONTRACT_EDIT = 'CONTRACT_EDIT',
  CONTRACT_CHANGE_STATUS = 'CONTRACT_CHANGE_STATUS',
  WAIVER_VIEW = 'WAIVER_VIEW',
  WAIVER_CREATE = 'WAIVER_CREATE',
  WAIVER_EDIT = 'WAIVER_EDIT',
  WAIVER_CHANGE_STATUS = 'WAIVER_CHANGE_STATUS',
  // POS content
  RECEIPT_TEMPLATES_VIEW = 'RECEIPT_TEMPLATES_VIEW',
  RECEIPT_TEMPLATES_CREATE = 'RECEIPT_TEMPLATES_CREATE',
  RECEIPT_TEMPLATES_EDIT = 'RECEIPT_TEMPLATES_EDIT',
  RECEIPT_TEMPLATES_CHANGE_STATUS = 'RECEIPT_TEMPLATES_CHANGE_STATUS',
  TAX_RATE_VIEW = 'TAX_RATE_VIEW',
  TAX_RATE_CREATE = 'TAX_RATE_CREATE',
  TAX_RATE_EDIT = 'TAX_RATE_EDIT',
  TAX_RATE_CHANGE_STATUS = 'TAX_RATE_CHANGE_STATUS',
  REVENUE_CODE_VIEW = 'REVENUE_CODE_VIEW',
  REVENUE_CODE_CREATE = 'REVENUE_CODE_CREATE',
  REVENUE_CODE_EDIT = 'REVENUE_CODE_EDIT',
  REVENUE_CODE_CHANGE_STATUS = 'REVENUE_CODE_CHANGE_STATUS',
  VENDOR_VIEW = 'VENDOR_VIEW',
  VENDOR_CREATE = 'VENDOR_CREATE',
  VENDOR_EDIT = 'VENDOR_EDIT',
  VENDOR_CHANGE_STATUS = 'VENDOR_CHANGE_STATUS',
  INVENTORY_VIEW = 'INVENTORY_VIEW',
  INVENTORY_CREATE = 'INVENTORY_CREATE',
  INVENTORY_EDIT = 'INVENTORY_EDIT',
  INVENTORY_CHANGE_STATUS = 'INVENTORY_CHANGE_STATUS',
  REGISTER_VIEW = 'REGISTER_VIEW',
  REGISTER_CREATE = 'REGISTER_CREATE',
  REGISTER_EDIT = 'REGISTER_EDIT',
  REGISTER_CHANGE_STATUS = 'REGISTER_CHANGE_STATUS',
  DEFAULT_PAYMENT_METHOD_VIEW = 'DEFAULT_PAYMENT_METHOD_VIEW',
  DEFAULT_PAYMENT_METHOD_EDIT = 'DEFAULT_PAYMENT_METHOD_EDIT',
  DEFAULT_PAYMENT_METHOD_CHANGE_STATUS = 'DEFAULT_PAYMENT_METHOD_CHANGE_STATUS',
  CUSTOM_PAYMENT_METHOD_VIEW = 'CUSTOM_PAYMENT_METHOD_VIEW',
  CUSTOM_PAYMENT_METHOD_CREATE = 'CUSTOM_PAYMENT_METHOD_CREATE',
  CUSTOM_PAYMENT_METHOD_EDIT = 'CUSTOM_PAYMENT_METHOD_EDIT',
  CUSTOM_PAYMENT_METHOD_CHANGE_STATUS = 'CUSTOM_PAYMENT_METHOD_CHANGE_STATUS',
  // Dictionaries
  DICTIONARIES_VIEW = 'DICTIONARIES_VIEW',
  DICTIONARIES_CREATE = 'DICTIONARIES_CREATE',
  DICTIONARIES_EDIT = 'DICTIONARIES_EDIT',
  DICTIONARIES_CHANGE_STATUS = 'DICTIONARIES_CHANGE_STATUS',
  CAME_FROM_INDICATION_VIEW = 'CAME_FROM_INDICATION_VIEW',
  CAME_FROM_INDICATION_CREATE = 'CAME_FROM_INDICATION_CREATE',
  CAME_FROM_INDICATION_EDIT = 'CAME_FROM_INDICATION_EDIT',
  CAME_FROM_INDICATION_CHANGE_STATUS = 'CAME_FROM_INDICATION_CHANGE_STATUS',
  ADDITIONAL_GUEST_FIELD_VIEW = 'ADDITIONAL_GUEST_FIELD_VIEW',
  ADDITIONAL_GUEST_FIELD_CREATE = 'ADDITIONAL_GUEST_FIELD_CREATE',
  ADDITIONAL_GUEST_FIELD_EDIT = 'ADDITIONAL_GUEST_FIELD_EDIT',
  ADDITIONAL_GUEST_FIELD_CHANGE_STATUS = 'ADDITIONAL_GUEST_FIELD_CHANGE_STATUS',
  ALERT_SOUND_VIEW = 'ALERT_SOUND_VIEW',
  ALERT_SOUND_EDIT = 'ALERT_SOUND_EDIT',
  NOTE_CODE_VIEW = 'NOTE_CODE_VIEW',
  NOTE_CODE_CREATE = 'NOTE_CODE_CREATE',
  NOTE_CODE_EDIT = 'NOTE_CODE_EDIT',
  NOTE_CODE_CHANGE_STATUS = 'NOTE_CODE_CHANGE_STATUS',
  FALLTHROUGH_REASON_VIEW = 'FALLTHROUGH_REASON_VIEW',
  FALLTHROUGH_REASON_CREATE = 'FALLTHROUGH_REASON_CREATE',
  FALLTHROUGH_REASON_EDIT = 'FALLTHROUGH_REASON_EDIT',
  FALLTHROUGH_REASON_CHANGE_STATUS = 'FALLTHROUGH_REASON_CHANGE_STATUS',
  DEPARTMENT_VIEW = 'DEPARTMENT_VIEW',
  DEPARTMENT_CREATE = 'DEPARTMENT_CREATE',
  DEPARTMENT_EDIT = 'DEPARTMENT_EDIT',
  DEPARTMENT_CHANGE_STATUS = 'DEPARTMENT_CHANGE_STATUS',
  CLUB_GROUP_VIEW = 'CLUB_GROUP_VIEW',
  CLUB_GROUP_CREATE = 'CLUB_GROUP_CREATE',
  CLUB_GROUP_EDIT = 'CLUB_GROUP_EDIT',
  CLUB_GROUP_CHANGE_STATUS = 'CLUB_GROUP_CHANGE_STATUS',
  INSURANCE_COMPANY_VIEW = 'INSURANCE_COMPANY_VIEW',
  INSURANCE_COMPANY_CREATE = 'INSURANCE_COMPANY_CREATE',
  INSURANCE_COMPANY_EDIT = 'INSURANCE_COMPANY_EDIT',
  INSURANCE_COMPANY_CHANGE_STATUS = 'INSURANCE_COMPANY_CHANGE_STATUS',
  ACCESS_HOUR_VIEW = 'ACCESS_HOUR_VIEW',
  ACCESS_HOUR_CREATE = 'ACCESS_HOUR_CREATE',
  ACCESS_HOUR_EDIT = 'ACCESS_HOUR_EDIT',
  ACCESS_HOUR_CHANGE_STATUS = 'ACCESS_HOUR_CHANGE_STATUS',
  EVENT_TAG_VIEW = 'EVENT_TAG_VIEW',
  EVENT_TAG_CREATE = 'EVENT_TAG_CREATE',
  EVENT_TAG_EDIT = 'EVENT_TAG_EDIT',
  EVENT_TAG_CHANGE_STATUS = 'EVENT_TAG_CHANGE_STATUS',
  RESOURCE_TAG_VIEW = 'RESOURCE_TAG_VIEW',
  RESOURCE_TAG_CREATE = 'RESOURCE_TAG_CREATE',
  RESOURCE_TAG_EDIT = 'RESOURCE_TAG_EDIT',
  RESOURCE_TAG_CHANGE_STATUS = 'RESOURCE_TAG_CHANGE_STATUS',
  NOTIFICATION_VIEW = 'NOTIFICATION_VIEW',
  NOTIFICATION_EDIT = 'NOTIFICATION_EDIT',
  HELP_INFORMATION_VIEW = 'HELP_INFORMATION_VIEW',
  HELP_INFORMATION_CREATE = 'HELP_INFORMATION_CREATE',
  HELP_INFORMATION_EDIT = 'HELP_INFORMATION_EDIT',
  HELP_INFORMATION_CHANGE_STATUS = 'HELP_INFORMATION_CHANGE_STATUS',
  // Permissions
  SECURITY_ROLE_VIEW = 'SECURITY_ROLE_VIEW',
  SECURITY_ROLE_CREATE = 'SECURITY_ROLE_CREATE',
  SECURITY_ROLE_EDIT = 'SECURITY_ROLE_EDIT',
  SECURITY_ROLE_CHANGE_STATUS = 'SECURITY_ROLE_CHANGE_STATUS',
  SECURITY_POLICY_VIEW = 'SECURITY_POLICY_VIEW',
  SECURITY_POLICY_CREATE = 'SECURITY_POLICY_CREATE',
  SECURITY_POLICY_EDIT = 'SECURITY_POLICY_EDIT',
  SECURITY_POLICY_CHANGE_STATUS = 'SECURITY_POLICY_CHANGE_STATUS',
  // Client management
  CLIENT_VIEW = 'CLIENT_VIEW',
  CLIENT_CREATE = 'CLIENT_CREATE',
  CLIENT_CHANGE_STATUS = 'CLIENT_CHANGE_STATUS',
  CLIENT_GENERAL_INFO_VIEW = 'CLIENT_GENERAL_INFO_VIEW',
  CLIENT_GENERAL_INFO_EDIT = 'CLIENT_GENERAL_INFO_EDIT',
  CLIENT_BANK_INFO_VIEW = 'CLIENT_BANK_INFO_VIEW',
  CLIENT_BANK_INFO_EDIT = 'CLIENT_BANK_INFO_EDIT',
  CLIENT_BILLING_INFO_VIEW = 'CLIENT_BILLING_INFO_VIEW',
  CLIENT_BILLING_INFO_EDIT = 'CLIENT_BILLING_INFO_EDIT',
  CLIENT_TAXES_INFO_VIEW = 'CLIENT_TAXES_INFO_VIEW',
  CLIENT_TAXES_INFO_EDIT = 'CLIENT_TAXES_INFO_EDIT',
  CLIENT_OTHER_INFO_VIEW = 'CLIENT_OTHER_INFO_VIEW',
  CLIENT_OTHER_INFO_EDIT = 'CLIENT_OTHER_INFO_EDIT',
  CLIENT_WORKING_HOURS_VIEW = 'CLIENT_WORKING_HOURS_VIEW',
  CLIENT_WORKING_HOURS_EDIT = 'CLIENT_WORKING_HOURS_EDIT',
  CLIENT_KIDZONE_SETTINGS_VIEW = 'CLIENT_KIDZONE_SETTINGS_VIEW',
  CLIENT_KIDZONE_SETTINGS_EDIT = 'CLIENT_KIDZONE_SETTINGS_EDIT',
  CLIENT_KIDZONE_SETTINGS_CHANGE_STATUS = 'CLIENT_KIDZONE_SETTINGS_CHANGE_STATUS',
  // corporate management
  CORPORATE_VIEW = 'CORPORATE_VIEW',
  CORPORATE_CREATE = 'CORPORATE_CREATE',
  CORPORATE_CHANGE_STATUS = 'CORPORATE_CHANGE_STATUS',
  CORPORATE_GENERAL_INFO_VIEW = 'CORPORATE_GENERAL_INFO_VIEW',
  CORPORATE_GENERAL_INFO_EDIT = 'CORPORATE_GENERAL_INFO_EDIT',
  CORPORATE_TRANSFER_OWNERSHIP = 'CORPORATE_TRANSFER_OWNERSHIP',
  CORPORATE_BANK_INFO_VIEW = 'CORPORATE_BANK_INFO_VIEW',
  CORPORATE_BANK_INFO_EDIT = 'CORPORATE_BANK_INFO_EDIT',
  CORPORATE_BILLING_INFO_VIEW = 'CORPORATE_BILLING_INFO_VIEW',
  CORPORATE_BILLING_INFO_EDIT = 'CORPORATE_BILLING_INFO_EDIT',
  CORPORATE_TAXES_INFO_VIEW = 'CORPORATE_TAXES_INFO_VIEW',
  CORPORATE_TAXES_INFO_EDIT = 'CORPORATE_TAXES_INFO_EDIT',
  CORPORATE_OTHER_INFO_VIEW = 'CORPORATE_OTHER_INFO_VIEW',
  CORPORATE_OTHER_INFO_EDIT = 'CORPORATE_OTHER_INFO_EDIT',
  CORPORATE_INTEGRATION_VIEW = 'CORPORATE_INTEGRATION_VIEW',
  CORPORATE_INTEGRATION_EDIT = 'CORPORATE_INTEGRATION_EDIT',
  CORPORATE_INTEGRATION_CHANGE_STATUS = 'CORPORATE_INTEGRATION_CHANGE_STATUS',
  CORPORATE_REQUIRED_FIELDS_VIEW = 'CORPORATE_REQUIRED_FIELDS_VIEW',
  CORPORATE_REQUIRED_FIELDS_EDIT = 'CORPORATE_REQUIRED_FIELDS_EDIT',
  CORPORATE_PAY_PERIOD_SETTINGS_VIEW = 'CORPORATE_PAY_PERIOD_SETTINGS_VIEW',
  CORPORATE_PAY_PERIOD_SETTINGS_CREATE = 'CORPORATE_PAY_PERIOD_SETTINGS_CREATE',
  CORPORATE_PAY_PERIOD_SETTINGS_EDIT = 'CORPORATE_PAY_PERIOD_SETTINGS_EDIT',
  CORPORATE_PAY_PERIOD_VIEW = 'CORPORATE_PAY_PERIOD_VIEW',
  CORPORATE_PAY_PERIOD_EDIT = 'CORPORATE_PAY_PERIOD_EDIT',
  CORPORATE_PAY_PERIOD_CHANGE_STATUS = 'CORPORATE_PAY_PERIOD_CHANGE_STATUS',
  // peak level
  PEAK_SU = 'PEAK_SU',
  PEAK_HELP_INFORMATION_VIEW = 'PEAK_HELP_INFORMATION_VIEW',
  PEAK_HELP_INFORMATION_CREATE = 'PEAK_HELP_INFORMATION_CREATE',
  PEAK_HELP_INFORMATION_EDIT = 'PEAK_HELP_INFORMATION_EDIT',
  PEAK_HELP_INFORMATION_CHANGE_STATUS = 'PEAK_HELP_INFORMATION_CHANGE_STATUS',
  PEAK_EMPLOYEE_VIEW = 'PEAK_EMPLOYEE_VIEW',
  PEAK_EMPLOYEE_CREATE = 'PEAK_EMPLOYEE_CREATE',
  PEAK_EMPLOYEE_EDIT = 'PEAK_EMPLOYEE_EDIT',
  PEAK_EMPLOYEE_CHANGE_STATUS = 'PEAK_EMPLOYEE_CHANGE_STATUS',
  PEAK_ORGANIZATION_CREATE = 'PEAK_ORGANIZATION_CREATE',
  PEAK_ORGANIZATION_VIEW = 'PEAK_ORGANIZATION_VIEW',
  PEAK_ORGANIZATION_EDIT = 'PEAK_ORGANIZATION_EDIT',
  PEAK_ORGANIZATION_CHANGE_STATUS = 'PEAK_ORGANIZATION_CHANGE_STATUS',
  PEAK_INTEGRATION_VIEW = 'PEAK_INTEGRATION_VIEW',
  PEAK_INTEGRATION_EDIT = 'PEAK_INTEGRATION_EDIT',
  PEAK_SECURITY_ROLE_VIEW = 'PEAK_SECURITY_ROLE_VIEW',
  PEAK_INTEGRATION_CHANGE_STATUS = 'PEAK_INTEGRATION_CHANGE_STATUS',
  PEAK_SECURITY_ROLE_CREATE = 'PEAK_SECURITY_ROLE_CREATE',
  PEAK_SECURITY_ROLE_EDIT = 'PEAK_SECURITY_ROLE_EDIT',
  PEAK_SECURITY_ROLE_CHANGE_STATUS = 'PEAK_SECURITY_ROLE_CHANGE_STATUS',
  PEAK_SECURITY_POLICY_VIEW = 'PEAK_SECURITY_POLICY_VIEW',
  PEAK_SECURITY_POLICY_CREATE = 'PEAK_SECURITY_POLICY_CREATE',
  PEAK_SECURITY_POLICY_EDIT = 'PEAK_SECURITY_POLICY_EDIT',
  PEAK_SECURITY_POLICY_CHANGE_STATUS = 'PEAK_SECURITY_POLICY_CHANGE_STATUS',
}

export enum PoliciesPermissionGroups {
  FINANCIAL = 'FINANCIAL',
  MEMBER_MANAGEMENT = 'MEMBER_MANAGEMENT',
  POINT_OF_SALE_POS = ' POINT_OF_SALE_POS',
  REPORTING = 'REPORTING',
  EMPLOYEE_MANAGEMENT = 'EMPLOYEE_MANAGEMENT',
  SALES_MANAGEMENT = 'SALES_MANAGEMENT',
  SCHEDULING = 'SCHEDULING',
  COLLECTIONS = 'COLLECTIONS',
  CRM = 'CRM',
  DICTIONARIES = 'DICTIONARIES',
}

export enum PermissionLevels {
  CLIENT = 'CLIENT',
  CORPORATE = 'CORPORATE',
  PEAK = 'PEAK',
}

export const PermissionsLevelsLabels = {
  [PermissionLevels.CLIENT]: <FormattedMessage {...commonMessages.clubTitle} />,
  [PermissionLevels.CORPORATE]: <FormattedMessage {...commonMessages.corporateTitle} />,
  [PermissionLevels.PEAK]: <FormattedMessage {...commonMessages.peakTitle} />,
};
