import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import moment, { MomentZoneOffset } from 'moment-timezone';
import { IconButton, makeStyles, Menu, MenuItem, Theme, Typography } from '@material-ui/core';

import { selectTimezone } from 'common/state/settings/selectors';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import useComponentUpdate from 'common/hooks/useComponentUpdate';

import { ReactComponent as TimezoneIcon } from 'img/icons/time-zone.svg';

import messages from 'common/messages/messages';

const useStyles = makeStyles((theme: Theme) => ({
  timezoneMenu: {
    '& .MuiMenu-paper': {
      height: '300px',
    },
  },
  localLabel: {
    marginLeft: theme.spacing(0.5),
    fontWeight: 600,
    textTransform: 'none',
  },
  timezoneIcon: {
    width: '20px',
    height: '20px',
  },
}));

const USTimezones: MomentZoneOffset[] = moment.tz.zonesForCountry('US', true);
const localTimezone = moment.tz.guess();

const UserTimezoneSelector = (): JSX.Element => {
  const currentTimezoneView: string = useSelector(selectTimezone);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const renderIntlMessage = useRenderIntlMessage();

  const [, , changeTimezone] = useTimezoneMoment();

  useComponentUpdate();

  const classes = useStyles();

  const onMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onMenuClose = () => {
    setAnchorEl(null);
  };

  const renderTimezoneOption = (timezone: string) => {
    const timezoneLabel = timezone
      .split('/')
      .slice(1)
      .map(labelItem => ` ${labelItem.replaceAll('_', ' ')}`);

    return (
      <>
        <Typography component="span" variant="inherit">
          {`${moment.tz(timezone).format('h:mm A')} (GMT ${moment
            .tz(timezone)
            .format('Z')}) ${timezoneLabel}`}
        </Typography>

        {localTimezone === timezone && (
          <Typography
            color="textSecondary"
            component="span"
            variant="inherit"
            className={classes.localLabel}
          >
            {`(${renderIntlMessage(messages.localLabel)})`}
          </Typography>
        )}
      </>
    );
  };

  const timezones = useMemo(() => {
    const notUSTimezone = USTimezones.find(timezone => timezone.name === localTimezone);

    return !notUSTimezone ? [{ name: localTimezone }, ...USTimezones] : USTimezones;
  }, []);

  return (
    <>
      <IconButton
        onClick={onMenuOpen}
        color={currentTimezoneView === localTimezone ? 'secondary' : 'primary'}
      >
        <TimezoneIcon className={classes.timezoneIcon} />
      </IconButton>

      <Menu
        id="actions-menu"
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        className={classes.timezoneMenu}
      >
        {timezones.map(({ name: timezoneName }: MomentZoneOffset) => (
          <MenuItem
            selected={currentTimezoneView === timezoneName}
            key={timezoneName}
            value={timezoneName}
            onClick={() => {
              changeTimezone(timezoneName);
              onMenuClose();
            }}
          >
            {renderTimezoneOption(timezoneName)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default UserTimezoneSelector;
