import React, { useCallback, useState } from 'react';
import { Grid, makeStyles, MenuItem, TextField, Typography } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentAdapter from '@date-io/moment';

import { AdaptiveDatePicker, AvatarCard, NumberTextField, Select } from 'common/components';

import { CustomTheme } from 'common/ui/interfaces';
import { UploadImageModal } from 'common/modals';
import { IAttachmentImt } from 'common/interfaces/uploadFile';

import { genderTypeLabels, ActiveInactiveStatus, maritalStatusesLabels } from 'common/constants';

import { FormattedMessage } from 'react-intl';
import inputLabels from 'common/messages/inputLabels';
import WebcamImageModal from 'common/modals/WebcamImageModal/WebcamImageModal';
import { Controller, useFormContext } from 'react-hook-form';
import employeesMessages from 'common/messages/employeesMessages';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import DeleteImageModal from 'common/modals/DeleteImageModal/DeleteImageModal';

const useStyles = makeStyles((theme: CustomTheme) => ({
  avatar: {
    padding: theme.spacing(0),
    marginRight: theme.spacing(1.25),
  },
  infoWrapper: {
    [theme.breakpoints.up('md')]: {
      flexWrap: 'nowrap',
    },
  },
  preferredNameHint: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const EmployeeInfoSection = (): JSX.Element => {
  const classes = useStyles();
  const { control, errors, setValue } = useFormContext();

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isOpenWebcamModal, setIsOpenWebcamModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  const renderIntlMessage = useRenderIntlMessage();

  const onToggleUploadModal = useCallback(() => setIsUploadModalOpen(!isUploadModalOpen), [
    isUploadModalOpen,
    setIsUploadModalOpen,
  ]);

  const onToggleWebcamModal = useCallback(() => setIsOpenWebcamModal(!isOpenWebcamModal), [
    isOpenWebcamModal,
  ]);

  const onUploadSubmit = (uploadedImage: IAttachmentImt, isWebcam?: boolean) => {
    setValue('image', { id: uploadedImage.get('id'), url: uploadedImage.get('filePath') });
    (isWebcam ? onToggleWebcamModal : onToggleUploadModal)();
  };

  const onToggleDeleteModal = useCallback(() => setIsOpenDeleteModal(!isOpenDeleteModal), [
    isOpenDeleteModal,
  ]);

  const onDeleteSubmit = () => {
    setValue('image', null);
    onToggleDeleteModal();
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="button" color="textSecondary" gutterBottom>
          <FormattedMessage {...employeesMessages.personal} />
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justify="space-between"
          spacing={2}
          className={classes.infoWrapper}
        >
          <Grid item>
            <Controller
              name="image"
              render={({ value }) => (
                <AvatarCard
                  avatarUrl={value?.url}
                  height="162px"
                  onToggleUploadModal={onToggleUploadModal}
                  onToggleWebcamModal={onToggleWebcamModal}
                  onToggleDeleteModal={onToggleDeleteModal}
                />
              )}
            />
            {isUploadModalOpen && (
              <UploadImageModal
                id="upload-employee-photo-modal"
                isOpen={isUploadModalOpen}
                onSubmit={onUploadSubmit}
                onToggle={onToggleUploadModal}
              />
            )}
            {isOpenWebcamModal && (
              <WebcamImageModal
                id="webcam-employee-photo-modal"
                isOpen={isOpenWebcamModal}
                onToggle={onToggleWebcamModal}
                onSubmit={uploadedImageUrl => onUploadSubmit(uploadedImageUrl, true)}
              />
            )}

            {isOpenDeleteModal && (
              <DeleteImageModal
                isOpen={isOpenDeleteModal}
                onClose={onToggleDeleteModal}
                onSubmit={onDeleteSubmit}
              />
            )}
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4}>
                <Controller
                  control={control}
                  name="firstName"
                  variant="outlined"
                  autoComplete="off"
                  fullWidth
                  render={({ value, onChange, onBlur }) => (
                    <TextField
                      variant="outlined"
                      label={<FormattedMessage {...inputLabels.firstName} />}
                      onBlur={onBlur}
                      onChange={onChange}
                      fullWidth
                      error={!!errors.firstName}
                      helperText={renderIntlMessage(errors.firstName?.message)}
                      value={value}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <Controller
                  control={control}
                  name="lastName"
                  variant="outlined"
                  autoComplete="off"
                  fullWidth
                  render={({ value, onChange, onBlur }) => (
                    <TextField
                      variant="outlined"
                      label={<FormattedMessage {...inputLabels.lastName} />}
                      onBlur={onBlur}
                      onChange={onChange}
                      fullWidth
                      error={!!errors.lastName}
                      helperText={renderIntlMessage(errors.lastName?.message)}
                      value={value}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6} sm={3}>
                <Controller
                  control={control}
                  name="active"
                  variant="outlined"
                  render={({ onChange, value, onBlur, name }) => (
                    <Select
                      label={<FormattedMessage {...inputLabels.status} />}
                      value={value ? 1 : 0}
                      name={name}
                      fullWidth
                      defaultValue=""
                      onBlur={onBlur}
                      onChange={e => {
                        onChange(!!e);
                      }}
                      labelId="status"
                    >
                      {ActiveInactiveStatus.map(status => (
                        <MenuItem value={Number(status.value)} key={status.key}>
                          {status.label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="preferredName"
                  variant="outlined"
                  autoComplete="off"
                  fullWidth
                  render={({ value, onChange, onBlur }) => (
                    <TextField
                      variant="outlined"
                      label={<FormattedMessage {...inputLabels.preferredName} />}
                      onBlur={onBlur}
                      onChange={onChange}
                      fullWidth
                      error={!!errors.preferredName}
                      helperText={renderIntlMessage(errors.preferredName?.message)}
                      value={value}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} className={classes.preferredNameHint}>
                <Typography component="p" color="textSecondary">
                  <FormattedMessage
                    values={{
                      replacedField: (
                        <Typography variant="h6" component="span" color="inherit">
                          <FormattedMessage {...inputLabels.preferredName} />
                        </Typography>
                      ),
                      newField: (
                        <Typography component="span" color="inherit">
                          <FormattedMessage {...inputLabels.firstName} />
                        </Typography>
                      ),
                    }}
                    {...employeesMessages.willBeDisplayedInsteadOfText}
                  />
                </Typography>
              </Grid>

              <Grid item xs={6} sm={4}>
                <Controller
                  control={control}
                  name="gender"
                  variant="outlined"
                  render={({ onChange, value, onBlur, name }) => (
                    <Select
                      label={<FormattedMessage {...inputLabels.gender} />}
                      value={value}
                      name={name}
                      fullWidth
                      onBlur={onBlur}
                      onChange={onChange}
                      error={!!errors.gender}
                      helperText={renderIntlMessage(errors.gender?.message)}
                    >
                      {genderTypeLabels.getSelectOptions()}
                    </Select>
                  )}
                />
              </Grid>

              <Grid item xs={6} sm={4}>
                <MuiPickersUtilsProvider utils={MomentAdapter}>
                  <Controller
                    name="birthday"
                    control={control}
                    defaultValue={null}
                    render={({ onChange, onBlur, value }) => (
                      <AdaptiveDatePicker
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        disableFuture
                        label={<FormattedMessage {...inputLabels.dateOfBirth} />}
                        error={!!errors.birthday}
                        helperText={renderIntlMessage(errors.birthday?.message)}
                        openTo="year"
                      />
                    )}
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={6} sm={4}>
                <Controller
                  control={control}
                  name="maritalStatus"
                  variant="outlined"
                  render={({ onChange, value, onBlur, name }) => (
                    <Select
                      label={<FormattedMessage {...inputLabels.maritalStatus} />}
                      value={value}
                      name={name}
                      fullWidth
                      onBlur={onBlur}
                      onChange={onChange}
                      error={!!errors.maritalStatus}
                      helperText={renderIntlMessage(errors.maritalStatus?.message)}
                    >
                      {maritalStatusesLabels.values.map(maritalStatus => (
                        <MenuItem key={maritalStatus.key} value={maritalStatus.value}>
                          {maritalStatusesLabels.translate(maritalStatus.value)}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={8}>
                <Controller
                  control={control}
                  name="exemptionsNumber"
                  render={({ value, onChange }) => (
                    <NumberTextField
                      numberFormatProps={{
                        decimalScale: 0,
                        fixedDecimalScale: true,
                        allowLeadingZeros: false,
                        allowNegative: false,
                        isNumericString: true,
                        maxLength: 11,
                        type: 'text',
                        format: (inputValue: string) => inputValue,
                      }}
                      variant="outlined"
                      label={<FormattedMessage {...employeesMessages.exemptionsNumber} />}
                      value={Number(value)}
                      onChange={onChange}
                      fullWidth
                      error={!!errors.exemptionsNumber}
                      helperText={renderIntlMessage(errors.exemptionsNumber?.message)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  control={control}
                  name="federalWithholding"
                  variant="outlined"
                  fullWidth
                  render={({ value, onChange }) => (
                    <NumberTextField
                      numberFormatProps={{
                        decimalScale: 0,
                        fixedDecimalScale: true,
                        allowLeadingZeros: false,
                        allowNegative: false,
                        isNumericString: true,
                        maxLength: 11,
                        type: 'text',
                        format: (inputValue: string) => inputValue,
                      }}
                      variant="outlined"
                      label={<FormattedMessage {...employeesMessages.federalWithholding} />}
                      value={Number(value)}
                      onChange={onChange}
                      fullWidth
                      error={!!errors.federalWithholding}
                      helperText={renderIntlMessage(errors.federalWithholding?.message)}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EmployeeInfoSection;
