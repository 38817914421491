import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, makeStyles, Typography } from '@material-ui/core';

import { DEFAULT_DATE_FORMAT } from 'common/constants/dateFormats';

import { TooltipTypography } from 'common/components/index';
import RemoveInvoiceItemController from '../RemoveInvoiceItemController/RemoveInvoiceItemController';

import { getBillingItemDescription } from 'common/components/PersonProfile/modals/CancelMembershipModal/utils';
import { formatNumberToPrice } from 'common/utils';
import { removeTaxAmount } from 'modules/services/utils/billingUtils';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';

import { IPastDue } from 'common/components/PersonProfile/interfaces';
import { CustomTheme } from 'common/ui/interfaces';
import { PastDueActions } from 'common/components/PersonProfile/constants';

import messages from '../messages';

const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    padding: ({ isIncluded }: Partial<IProps>) =>
      isIncluded ? theme.spacing(0) : theme.spacing(2, 0),
    borderBottom: ({ isIncluded }: Partial<IProps>) =>
      isIncluded ? 'none' : `1px solid ${theme.palette.borderColor.light}`,
    margin: ({ isIncluded }: Partial<IProps>) =>
      isIncluded ? theme.spacing(2, 0) : theme.spacing(0),
  },
  pastDueTitle: {
    flex: 1,
  },
  pastDueHint: {
    marginRight: theme.spacing(1),
  },
  dateRange: {
    marginLeft: theme.spacing(1),
  },
}));

interface IProps {
  pastDue: IPastDue;
  pastDueResolve: PastDueActions;
  onDelete?: () => void;
  disabled?: boolean;
  isIncluded?: boolean;
}

const InvoicePastDueItem = ({
  pastDue,
  pastDueResolve,
  onDelete,
  disabled,
  isIncluded,
}: IProps): JSX.Element => {
  const classes = useStyles({ isIncluded });

  const [timezoneMoment] = useTimezoneMoment();

  const { package: packageInfo, revenueCode, amount, paymentDate } = pastDue;

  const isPastDueWaived = pastDueResolve === PastDueActions.Waive;

  const renderPrice = () => {
    if (isPastDueWaived) {
      return `$${formatNumberToPrice(0)}`;
    }
    return `$${formatNumberToPrice(
      revenueCode?.totalTax ? removeTaxAmount(amount, revenueCode.totalTax) : amount,
    )}`;
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" className={classes.root}>
      {!!onDelete && <RemoveInvoiceItemController onDelete={onDelete} disabled={disabled} />}

      <TooltipTypography
        ellipsized
        variant={isIncluded ? 'body1' : 'h5'}
        className={classes.pastDueTitle}
      >
        <Typography component="span">{getBillingItemDescription(packageInfo)}</Typography>

        <Typography component="span" color="textSecondary" className={classes.dateRange}>
          {`(${timezoneMoment(paymentDate).format(DEFAULT_DATE_FORMAT)})`}
        </Typography>
      </TooltipTypography>

      <Typography color="error" className={classes.pastDueHint}>
        <FormattedMessage
          {...(isPastDueWaived ? messages.pastDueWaivedShortLabel : messages.pastDueShortLabel)}
        />
      </Typography>

      <Typography component="p" variant="h5">
        {renderPrice()}
      </Typography>
    </Box>
  );
};

export default InvoicePastDueItem;
