import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, Typography } from '@material-ui/core';

import * as actions from 'common/components/PersonProfile/state/actions';
import * as selectors from 'common/components/PersonProfile/state/selectors';
import { FilterTypes, IFilterSettings } from 'common/interfaces/filter';
import tableFilters from 'common/messages/tableFilters';
import { SMSStatuses, statusColors } from 'common/constants/sms';
import modalMessages from 'common/messages/modalTitles';
import commonMessages from 'common/messages/messages';
import tableHeaders from 'common/messages/tableHeaders';
import { automaticManualOptions } from 'common/constants/historyModal';
import { makeTableParams } from 'common/utils/http';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import { SMSStatusMessages } from 'common/components/PersonProfile/constants';
import { DEFAULT_DATE_TIME_FORMAT } from 'common/constants/dateFormats';
import { useAppDispatch } from 'store/hooks';
import { TableOrderByParams } from 'common/constants';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import { IHeadCell, ITableParams, ITableRow } from 'common/interfaces/table';

import { DialogComponent, Table } from 'common/components/index';
import { convertPhoneNumber } from 'helpers/common';
import { ISMSImt } from 'common/components/PersonProfile/interfaces';

const headerOptions: IHeadCell[] = [
  {
    id: TableOrderByParams.DATE,
    label: <FormattedMessage {...tableHeaders.date} />,
    sort: true,
  },
  {
    id: 'from',
    label: <FormattedMessage {...tableHeaders.from} />,
    sort: false,
  },
  {
    id: 'to',
    label: <FormattedMessage {...tableHeaders.to} />,
    sort: false,
  },
  {
    id: TableOrderByParams.CAMPAIGN,
    label: <FormattedMessage {...tableHeaders.campaign} />,
    sort: true,
  },
  {
    id: 'message',
    label: <FormattedMessage {...tableHeaders.message} />,
    sort: false,
  },
  {
    id: TableOrderByParams.STATUS,
    label: <FormattedMessage {...tableHeaders.status} />,
    sort: true,
  },
  {
    id: TableOrderByParams.TYPE,
    label: <FormattedMessage {...tableHeaders.type} />,
    sort: true,
  },
];

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  personId: string;
  onOpenSMSDetails: (sms: ISMSImt) => void;
  module: PeakModuleForNewPersonType;
}

const SMSHistoryModal = ({
  isOpen,
  onClose,
  personId,
  module,
  onOpenSMSDetails,
}: IProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [timezoneMoment] = useTimezoneMoment();

  const historyMeta = useSelector(selectors.selectPersonSMSHistoryMeta(personId));
  const history = useSelector(selectors.selectPersonSMSHistory(personId));
  const loading = useSelector(selectors.selectPersonSMSHistoryLoading(personId));

  const tableFilterSettings: IFilterSettings[] = useMemo(
    () => [
      {
        name: 'range',
        title: <FormattedMessage {...tableFilters.period} />,
        type: FilterTypes.DATE_RANGE,
        options: {
          startDate: null,
          endDate: null,
        },
      },
      {
        name: 'status',
        title: <FormattedMessage {...tableFilters.status} />,
        type: FilterTypes.SINGLE,
        options: SMSStatuses.getFilterOptions(),
      },
      {
        name: 'automatic',
        title: <FormattedMessage {...tableFilters.type} />,
        type: FilterTypes.SINGLE,
        options: automaticManualOptions,
      },
    ],
    [],
  );

  const rows: ITableRow[] = useMemo(() => {
    return history
      ?.map(smsItem => {
        const smsStatus = smsItem.get('status');
        const smsStatusMessage = Object.entries(SMSStatusMessages).find(
          ([status, _]) => status === smsStatus,
        )[1];
        const smsStatusColor = statusColors[smsStatus];

        return {
          id: smsItem.get('id'),
          cells: [
            {
              label: '',
              cellComponent: (
                <Link underline="none" onClick={() => onOpenSMSDetails(smsItem)}>
                  <Typography variant="h6" color="primary">
                    {timezoneMoment(smsItem.get('date')).format(DEFAULT_DATE_TIME_FORMAT)}
                  </Typography>
                </Link>
              ),
            },
            {
              label: convertPhoneNumber(smsItem.get('senderPhone')),
            },
            {
              label: convertPhoneNumber(smsItem.get('receiverPhone')),
            },
            {
              label: smsItem.getIn(['campaign', 'title']),
            },
            {
              label: smsItem.get('message'),
            },
            {
              label: '',
              cellComponent: (
                <Typography variant="body1" style={{ color: smsStatusColor }}>
                  <FormattedMessage {...smsStatusMessage} />
                </Typography>
              ),
            },
            {
              label: (
                <FormattedMessage
                  {...(smsItem.get('automatic')
                    ? commonMessages.automaticLabel
                    : commonMessages.manualLabel)}
                />
              ),
            },
          ],
        };
      })
      .toJS();
  }, [history, timezoneMoment]);

  const [tableParams, setTableParams] = useState(() =>
    makeTableParams(tableFilterSettings, null, {
      orderBy: TableOrderByParams.DATE,
      order: 'desc',
    }),
  );

  const onChangeTableParams = useCallback((tableProps: ITableParams): void => {
    setTableParams(tableProps);
  }, []);

  useEffect(() => {
    dispatch(actions.fetchPersonSMSHistory(personId, module, tableParams));
  }, [dispatch, module, personId, tableParams]);

  return (
    <DialogComponent
      isOpen={isOpen}
      onClose={onClose}
      title={<FormattedMessage {...modalMessages.smsHistory} />}
      size="xl"
      submitBtn={null}
      cancelBtn={null}
    >
      <Table
        rows={rows}
        tableParams={tableParams}
        headerOptions={headerOptions}
        filters={tableFilterSettings}
        onChangeParams={onChangeTableParams}
        totalRows={historyMeta?.get('total')}
        page={historyMeta?.get('page')}
        isLoading={loading}
        hideToolbar
        showPerPageSelect
        hideSearchInput
      />
    </DialogComponent>
  );
};

export default SMSHistoryModal;
