import { Dispatch } from 'redux';
import { createAction } from '@reduxjs/toolkit';

import Services from 'services/network';
import { actionTypes } from 'modules/authentication/state/constants';
import TokenService from 'services/auth/TokenService';
import { IUserAvatar, IUserProfileInfo } from 'modules/authentication/interfaces';
import { enqueueErrorNotification } from 'common/state/notifications/actions';
import { IServerError, ISuccessResponse } from 'common/interfaces/http';
import { batch } from 'react-redux';
import AppService from 'services/application/AppService';
import { GeneralThunkAction } from 'common/state/interfaces';
import { IUserOrganization } from 'common/interfaces/clients';
import { PermissionLevels } from 'common/constants/permissions';
import { IClubGeneralInfo } from 'modules/clubs/interfaces';
import { ICorporationGeneralInfo } from 'modules/corporations/interfaces';
import { resetSelectedEntranceIdAction } from 'modules/front-desk/state/actions';
import { INamedEntity } from 'common/interfaces/common';
import { resetUserRole } from 'common/state/userRole/actions';
import { fetchProfileFieldsInfoAction } from 'common/state/settings/actions';

const signInAction = createAction<ISuccessResponse>(actionTypes.USER_SIGN_IN);
const signInLoadingAction = createAction<boolean>(actionTypes.USER_SIGN_IN_LOADING);
export const signInErrorAction = createAction<IServerError>(actionTypes.USER_SIGN_IN_ERROR);

export const signIn = (
  email: string,
  password: string,
  corporationId: string,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(signInLoadingAction(true));

    try {
      const signInData = await Services.Authentication.signIn(email, password, corporationId);

      TokenService.setAccessToken(signInData.accessToken);
      TokenService.setRefreshToken(signInData.refreshToken);

      dispatch(signInAction({ success: true }));
    } catch (e) {
      dispatch(signInErrorAction(e));
    } finally {
      dispatch(signInLoadingAction(false));
    }
  };
};

const resetUserProfileAction = createAction<IUserProfileInfo>(actionTypes.RESET_USER_PROFILE_DATA);
export const clearUserDataAction = createAction(actionTypes.USER_LOG_OUT);

export const logOut = (): GeneralThunkAction => {
  return async dispatch => {
    try {
      await Services.Authentication.signOut();
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      TokenService.clearTokens();
      AppService.clearAppSelectedRegisters();
      AppService.clearAppSelectedEntrance();

      batch(() => {
        dispatch(clearUserDataAction());
        dispatch(resetUserProfileAction());
        dispatch(resetSelectedEntranceIdAction());
        dispatch(resetUserRole());
        dispatch(fetchProfileFieldsInfoAction([]));
      });
    }
  };
};

const selectCurrentUserOrganizationAction = createAction<IUserOrganization>(
  actionTypes.SELECT_ORGANIZATION,
);
export const setOrganizationPrimaryColorAction = createAction<string>(
  actionTypes.SET_ORGANIZATION_PRIMARY_COLOR,
);
export const resetOrganizationPrimaryColorAction = createAction(
  actionTypes.RESET_ORGANIZATION_PRIMARY_COLOR,
);

export const selectCurrentUserOrganization = (userId: string, organization: IUserOrganization) => (
  dispatch: Dispatch,
): void => {
  AppService.setUserSelectedOrgId(userId, organization.id);
  dispatch(selectCurrentUserOrganizationAction(organization));
};

const fetchUserProfileAction = createAction<IUserProfileInfo>(actionTypes.FETCH_USER_PROFILE);
const fetchUserProfileLoadingAction = createAction<boolean>(actionTypes.FETCH_USER_PROFILE_LOADING);
const fetchUserProfileErrorAction = createAction<Error>(actionTypes.FETCH_USER_PROFILE_ERROR);
export const setUserAvatar = createAction<IUserAvatar>(actionTypes.SET_USER_AVATAR);

const chooseSelectedOrg = (
  user: IUserProfileInfo,
  prevSelectedOrgId?: string,
): IUserOrganization => {
  const { level, securityRoles, corporation } = user;

  if (level === PermissionLevels.PEAK) {
    return null;
  }

  const userClubs: IUserOrganization[] = securityRoles.flatMap(role => role.clubList);
  const selectedClub = userClubs.find(club => club.id === prevSelectedOrgId);

  if (selectedClub) {
    return { ...selectedClub, permissionsLevel: PermissionLevels.CLIENT };
  }

  if (level === PermissionLevels.CORPORATE) {
    return { ...corporation, permissionsLevel: PermissionLevels.CORPORATE };
  }

  if (userClubs.length) {
    return { ...userClubs[0], permissionsLevel: PermissionLevels.CLIENT };
  }

  return null;
};

export const fetchUserProfile = (): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchUserProfileLoadingAction(true));
    try {
      const userProfile: IUserProfileInfo = await Services.Authentication.getProfile();

      const prevSelectedOrgId: string = AppService.getUserSelectedOrgId(userProfile.id);
      const selectedOrg: IUserOrganization = chooseSelectedOrg(userProfile, prevSelectedOrgId);

      if (selectedOrg) {
        AppService.setUserSelectedOrgId(userProfile.id, selectedOrg.id);
        batch(() => {
          dispatch(selectCurrentUserOrganizationAction(selectedOrg));
          dispatch(fetchUserProfileAction(userProfile));
        });
      } else {
        dispatch(fetchUserProfileAction(userProfile));
      }

      const { avatar, preferredName } = userProfile;
      dispatch(setUserAvatar({ avatar, preferredName }));
    } catch (e) {
      console.error('Failed to load user profile: ', e);
      dispatch(enqueueErrorNotification(e));
      dispatch(fetchUserProfileErrorAction(e));
    } finally {
      dispatch(fetchUserProfileLoadingAction(false));
    }
  };
};

const fetchCorporationsForSignInAction = createAction<INamedEntity[]>(
  actionTypes.FETCH_CORPORATIONS_FOR_SIGN_IN,
);
const fetchCorporationsForSignInActionLoading = createAction<boolean>(
  actionTypes.FETCH_CORPORATIONS_FOR_SIGN_IN_LOADING,
);
export const resetCorporationsForSignInAction = createAction(
  actionTypes.RESET_CORPORATIONS_FOR_SIGN_IN,
);

export const fetchCorporationsForSignIn = (email: string): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchCorporationsForSignInActionLoading(true));

    try {
      const corporations = await Services.Authentication.getCorporationsForSignIn(email);

      dispatch(fetchCorporationsForSignInAction(corporations));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchCorporationsForSignInActionLoading(false));
    }
  };
};

export const updateClubInProfile = createAction<IClubGeneralInfo>(actionTypes.UPDATE_CLUB_DATA);
export const updateCorporationInProfile = createAction<ICorporationGeneralInfo>(
  actionTypes.UPDATE_CORPORATION_DATA,
);
