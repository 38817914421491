// libraries
import React, { forwardRef, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { fade, Paper, StyledComponentProps } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { DateInput, EventInput } from '@fullcalendar/common';
import FullCalendar from '@fullcalendar/react';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { List as ImmutableList } from 'immutable';
// custom components
import { Loader } from 'common/components';
import { EventBlock } from 'modules/booking/components';
import { IBookingEventListItem, IBookingEventListItemImt } from 'modules/booking/interfaces';

import { selectTimezone } from 'common/state/settings/selectors';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      height: 600,
      paddingBottom: theme.spacing(13),
    },
    '& .fc-scrollgrid-section-header': {
      '& .fc-scroller': {
        overflow: 'hidden',
        'scrollbar-width': 'none',
        '-ms-overflow-style': 'none',

        '&::-webkit-scrollbar': {
          width: 0,
          height: 0,
        },
      },
    },
    '& .fc, .fc-view-harness': {
      overflow: 'auto',
      borderRadius: '3px',
      height: '100%',
    },
    '& .fc-view': {
      backgroundColor: 'white',
    },
    '& .fc .fc-scrollgrid': {
      border: 'none',
    },
    '& .fc .fc-button-primary, .fc .fc-button-primary:not(:disabled).fc-button-active': {
      width: '65px',
      border: 'none',
      backgroundColor: 'white',
      boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.1)',
      fontSize: '14px',
      fontWeight: '500',
      color: theme.palette.text.primary,
    },
    '& .fc .fc-button-primary:not(:disabled).fc-button-active, .fc .fc-button-primary:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
    '& .fc .fc-button-primary:not(:disabled):active:focus, .fc .fc-button-primary:not(:disabled).fc-button-active:focus': {
      boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.1)',
    },
    '& .fc .fc-button-primary:not(:disabled):active, .fc .fc-button-primary:not(:disabled).fc-button-active': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
    '& .fc .fc-button-primary:disabled': {
      backgroundColor: 'white',
      color: theme.palette.text.disabled,
    },
    '& .fc .fc-button-primary:focus, .fc-button:focus': {
      boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.1)',
    },
    '& .fc-timegrid-axis': {
      border: 'none',
    },
    '& .fc-col-header-cell': {
      padding: '12px',
      borderLeft: 'none',
      borderRight: 'none',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 2,
        paddingRight: 2,
      },

      '& .fc-col-header-cell-cushion': {
        fontWeight: 'normal',
        color: theme.palette.text.primary,
        [theme.breakpoints.down('xs')]: {
          maxWidth: 28,
          padding: 0,
        },
      },
    },
    '& .fc-col-header-cell.fc-day-today': {
      '& .fc-col-header-cell-cushion': {
        fontWeight: 'bold',
        color: theme.palette.primary.main,
      },
    },
    '& .fc-col-header-cell.fc-day-past': {
      '& .fc-col-header-cell-cushion': {
        opacity: '0.5',
      },
    },
    '& .fc .fc-timegrid-col.fc-day-today, .fc .fc-daygrid-day.fc-day-today': {
      background: 'none',
    },
    '& .fc-timegrid-slot': {
      '& .fc-timegrid-slot-label-cushion': {
        fontSize: '0.6rem',
        color: theme.palette.text.disabled,
      },
    },
    '& .fc-timegrid-cols': {
      zIndex: 2,

      '& .fc-timegrid-col.fc-day-today, .fc-timegrid-col.fc-day-future': {
        cursor: 'pointer',
      },
    },
    '& .fc-daygrid-day.fc-day-today, .fc-daygrid-day.fc-day-future': {
      cursor: 'pointer',
    },
    '& .fc .fc-timegrid-slot-label': {
      borderTop: 'none',
    },
    '& .fc-day-today .fc-daygrid-day-number': {
      color: theme.palette.primary.main,
    },
    '& .fc .fc-timegrid-slot-minor': {
      borderTop: 'none',
    },

    '& .fc-event': {
      minHeight: '23px',
      overflow: 'hidden',

      '&.fc-event-cancelled': {
        opacity: '0.4',
      },

      '&:hover': {
        cursor: 'pointer',
      },
      [theme.breakpoints.down('xs')]: {
        '& .fcAvatarGroup, & .fcParticipants, & .fcTitle': {
          display: 'none',
        },
      },
    },

    '& .fc-timegrid-now-indicator-container': {
      overflow: 'visible',
    },

    '& .fc-timegrid-col.fc-day-past': {
      opacity: 0.65,
      backgroundColor: fade(theme.palette.text.primary, 0.04),
    },

    '& .fc-daygrid-day.fc-day-past': {
      opacity: 0.65,
      backgroundColor: fade(theme.palette.text.primary, 0.04),
    },

    '& .canceled': {
      opacity: 0.65,
    },

    '--fc-now-indicator-color': theme.palette.primary.main,
    '& .fc-timegrid-now-indicator-line': {
      borderWidth: theme.spacing(0.25, 0, 0),
      position: 'relative',
      '&:before': {
        content: '""',
        width: theme.spacing(1.875),
        height: theme.spacing(1.875),
        borderRadius: '50%',
        backgroundColor: theme.palette.primary.main,
        position: 'absolute',
        top: theme.spacing(-1.0625),
        left: theme.spacing(-1),
      },
    },
  },
}));

// TODO CALENDAR in fullcalendar v5 viewsOptions was changed, need to change options config
/* const viewsOptions = {
  timeGridDay: {
    dayHeaderFormat: { day: 'numeric', weekday: 'short', omitCommas: true },
  },
  timeGridWeek: {
    dayHeaderFormat: { day: 'numeric', weekday: 'short', omitCommas: true },
  },
  dayGridMonth: {
    dayHeaderFormat: { weekday: 'short' },
  },
}; */

const viewsOptions = {
  timeGridDay: {
    day: 'numeric',
    weekday: 'short',
    omitCommas: true,
  },
  timeGridWeek: {
    day: 'numeric',
    weekday: 'short',
    omitCommas: true,
  },
  dayGridMonth: {
    weekday: 'short',
  },
};

export type CalendarView = 'timeGridWeek' | 'timeGridDay' | 'dayGridMonth';

interface IBookingCalendarProps extends StyledComponentProps {
  data: ImmutableList<IBookingEventListItemImt>;
  initialView?: CalendarView;
  initialDate?: DateInput;
  validRange?: {
    start?: DateInput;
    end?: DateInput;
  };
  isLoading?: boolean;
  onEventClick?: (e) => void;
  onDateClick?: (e) => void;
  onDatesRangeChange?: (range) => void;
  newEventTime?: Date;
  timezone?: string;
}

const BookingCalendar = forwardRef<any, IBookingCalendarProps>(
  (
    {
      data,
      initialView,
      initialDate,
      validRange,
      isLoading,
      onEventClick,
      onDateClick,
      onDatesRangeChange,
      timezone,
    }: IBookingCalendarProps,
    ref,
  ) => {
    const classes = useStyles();

    const currentTimezoneView: string = useSelector(selectTimezone);

    const [timezoneMoment] = useTimezoneMoment();

    const getEventClassNames = useCallback(({ event }) => {
      const cancelClassName =
        event.extendedProps?.status === 'CANCELLED' ? 'fc-event-cancelled' : '';

      return [cancelClassName];
    }, []);

    const events: EventInput[] = useMemo(() => {
      return data.toJS().map(
        (event: IBookingEventListItem): EventInput => ({
          ...event,
          id: `${event.id}_${event.date.toString()}`, // should be unique
          eventId: event.id,
          start: timezoneMoment(event.date).format(),
          end: timezoneMoment(event.date)
            .add(event.durationInMinutes, 'minutes')
            .format(),
          className: event.canceled ? 'canceled' : 'active',
          // needed to fetch event
          utcDate: event.date,
        }),
      );
    }, [data, timezoneMoment]);

    return (
      <Paper className={classes.root} elevation={2}>
        {!!isLoading && <Loader />}

        <FullCalendar
          ref={ref}
          // needed for reinitialize component after changing timezone view
          key={currentTimezoneView}
          plugins={[interactionPlugin, dayGridPlugin, timeGridPlugin, momentTimezonePlugin]}
          initialView={initialView}
          initialDate={initialDate}
          validRange={validRange}
          events={events}
          slotMinTime="00:00:00"
          allDaySlot={false}
          eventDisplay="block"
          eventBorderColor="white"
          headerToolbar={null}
          eventContent={EventBlock}
          eventClassNames={getEventClassNames}
          views={viewsOptions}
          eventClick={({ event }) => {
            onEventClick({
              startDate: event.extendedProps.utcDate,
              eventId: event.extendedProps.eventId,
            });
          }}
          dateClick={onDateClick}
          datesSet={onDatesRangeChange}
          nowIndicator
          timeZone={timezone || currentTimezoneView}
          timeZoneParam={timezone || currentTimezoneView}
        />
      </Paper>
    );
  },
);

export default React.memo(BookingCalendar);
