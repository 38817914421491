import * as yup from 'yup';

import { CreditCardItems, PDCreditCardType } from 'common/components/CreditCardData/interfaces';

import {
  getCardDataValidationStatus,
  getCardItemMask,
  getCardType,
} from 'common/components/CreditCardData/helpers';

import inputErrors from 'common/messages/inputErrors';
import { requiredMessage } from 'common/constants/globalConstants';
import { masks } from 'common/constants';

export const CreditCardValidationSchema = yup.object().shape({
  cardHolderName: yup.string().required(requiredMessage),
  cardNumber: yup
    .string()
    .required(requiredMessage)
    .test(
      'isValidCardNumber',
      () => inputErrors.invalidCardNumberMessageError,
      value => {
        const card = getCardType(value);
        const mask = getCardItemMask(CreditCardItems.CardNumber, card);

        if (card && mask.length === value.length) {
          return getCardDataValidationStatus(CreditCardItems.CardNumber, value);
        }
        return true;
      },
    ),

  expiredIn: yup
    .string()
    .required(requiredMessage)
    .test(
      'isValidDate',
      () => inputErrors.invalidExpiredDateMessageError,
      async value => {
        if (value.length === masks.EXPIRED_CARD_DATE.length) {
          return getCardDataValidationStatus(CreditCardItems.CardExpired, value);
        }

        return true;
      },
    ),

  code: yup
    .string()
    .required(requiredMessage)
    .test(
      'isValidCVVLength',
      () => inputErrors.creditCardCodeLengthValidationError,
      function checkCVVCodeLength(value: string) {
        const cardNumber = this.resolve(yup.ref('cardNumber')) as string;
        if (cardNumber) {
          const type = getCardType(cardNumber);
          if (type !== PDCreditCardType.amex) {
            return value.length === 3;
          }
        } else {
          return value.length === 3;
        }

        return true;
      },
    )
    .test(
      'isValidAMEXCVVLength',
      () => inputErrors.AMEXCreditCardCodeLengthValidationError,
      function checkCVVCodeLength(value: string) {
        const cardNumber = this.resolve(yup.ref('cardNumber')) as string;
        if (cardNumber) {
          const type = getCardType(cardNumber);
          if (type === PDCreditCardType.amex) {
            return value.length === 4;
          }
        }

        return true;
      },
    )
    .test(
      'isValidCVVCode',
      () => inputErrors.invalidCVVCodeMessageError,
      function checkCVVCode(value: string) {
        const cardNumber = this.resolve(yup.ref('cardNumber')) as string;

        if (cardNumber) {
          return getCardDataValidationStatus(
            CreditCardItems.CardCVV,
            value,
            getCardType(cardNumber),
          );
        }

        return true;
      },
    ),
});
