import React, { useEffect, useRef } from 'react';
import { FormControlLabel, Grid, makeStyles, Typography } from '@material-ui/core';
import { Controller, UseFormMethods } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
// messages
import messages from 'modules/services/messages/messages';
// constants
import { SimpleDurationTypes } from 'modules/services/constants/packages';
// components
import { Checkbox, MinMaxNumberInput, Select } from 'common/components';
import { IBillingOption, IBillingSection } from '../../../../interfaces/packages';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

interface IPaymentGraceSectorProps {
  formMethods: UseFormMethods<any>;
  defaultValues: IBillingOption;
}

const useStyles = makeStyles(() => ({
  mainCheckboxLabel: {
    fontWeight: 500,
  },
}));

const PaymentGraceSector = ({
  formMethods,
  defaultValues,
}: IPaymentGraceSectorProps): JSX.Element => {
  // const

  const classes = useStyles();
  const graceConfigRef = useRef(null);
  const renderIntlMessage = useRenderIntlMessage();

  // form

  const { control, watch, errors, trigger } = formMethods;
  const allowValue = watch('paymentGrace.allow');

  // effects

  useEffect(() => {
    if (allowValue && graceConfigRef.current) {
      graceConfigRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [allowValue]);

  // render

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="paymentGrace.allow"
            variant="outlined"
            render={({ value, onChange, onBlur }) => (
              <FormControlLabel
                label={
                  <Typography className={classes.mainCheckboxLabel}>
                    <FormattedMessage {...messages.allowPaymentGracePeriodLabel} />
                  </Typography>
                }
                control={
                  <Checkbox
                    size="small"
                    value={value}
                    checked={value}
                    onBlur={onBlur}
                    onChange={e => onChange(e.target.checked)}
                  />
                }
              />
            )}
          />
        </Grid>

        {allowValue && (
          <>
            <Grid item xs={4} ref={graceConfigRef}>
              <Controller
                control={control}
                name="paymentGrace.duration"
                render={({ name, value, onChange, onBlur }) => (
                  <Select
                    fullWidth
                    variant="outlined"
                    label={<FormattedMessage {...messages.durationLabel} />}
                    name={name}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.paymentGrace?.duration}
                    helperText={renderIntlMessage(errors.paymentGrace?.duration?.message)}
                  >
                    {SimpleDurationTypes.getSelectOptions()}
                  </Select>
                )}
              />
            </Grid>

            <Grid item xs={4}>
              <MinMaxNumberInput
                control={control}
                errors={errors}
                trigger={trigger}
                baseName="paymentGrace.editableDurationNumber"
                label={<FormattedMessage {...messages.amountLabel} />}
                baseDefaultValue={defaultValues?.paymentGrace?.editableDurationNumber}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default PaymentGraceSector;
