import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, Button, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Controller, useFormContext } from 'react-hook-form';

import { LeadStatuses } from 'modules/crm/constants/leads';
import { PeakModules } from 'common/constants/peakModules';

import messages from 'modules/crm/messages/leads';
import ptCrmMessages from 'modules/personal-training-crm/messages/messages';

const useStyles = makeStyles((theme: Theme) => ({
  selectBtn: {
    padding: '7px 12px',
    boxShadow: 'none',

    '& .MuiButton-label': {
      fontWeight: '500',
    },
  },
}));

interface IProps {
  module: PeakModules.Crm | PeakModules.PersonalTrainingCrm | PeakModules.Members;
}

const StatusSection = ({ module }: IProps): JSX.Element => {
  const classes = useStyles();

  const { control, watch, setValue } = useFormContext();

  const isCrmModule = module === PeakModules.Crm;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="button" color="textSecondary">
          <FormattedMessage
            {...(isCrmModule
              ? messages.newLeadPrimaryInfoStepLeadStatus
              : ptCrmMessages.ptCrmLeadStatus)}
          />
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          {LeadStatuses.values.map(s => {
            const isSelected = s.value === watch('salesStatus');

            return (
              <Grid item key={s.value}>
                <Controller
                  name="salesStatus"
                  contorl={control}
                  as={
                    <Button
                      className={classes.selectBtn}
                      style={{
                        color: isSelected ? '#fff' : s.color,
                        backgroundColor: isSelected ? s.color : '#fff',
                        border: `1px solid ${s.color}`,
                      }}
                      variant="contained"
                      onClick={() => setValue('salesStatus', s.value)}
                    >
                      <FormattedMessage {...s.message} />
                    </Button>
                  }
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(StatusSection);
