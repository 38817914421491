import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Typography, Card, Box, makeStyles, Theme } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import cx from 'classnames';

// interfaces
import { ILeadImt } from 'modules/crm/interfaces/leads';
// components
import LeadsActionsMenu from '../LeadsTable/ActionsMenu/ActionsMenu';
import { AvatarWithInitials } from 'common/components';
// helpers
import { getInitials } from 'helpers/common';

const StyledLink = styled(Link)({
  '&:hover': {
    textDecoration: 'underline',
  },
});

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    padding: '12px',
    borderRadius: 0,
  },
  cardLast: {
    borderBottomLeftRadius: '3px',
    borderBottomRightRadius: '3px',
  },
  title: {
    fontWeight: 500,
  },
  description: {
    padding: theme.spacing(1, 0),
    lineHeight: '1.25',
  },
}));

interface IProps {
  lead: ILeadImt;
  isLast: boolean;
  isCrmModule: boolean;
  onChangeStatus: (leadId: string) => void;
  onChangeSalesperson: (leadId: string) => void;
}

const LeadCard = ({
  lead,
  isLast,
  onChangeStatus,
  onChangeSalesperson,
  isCrmModule,
}: IProps): JSX.Element => {
  const location = useLocation();

  const classes = useStyles();
  const leadInitials = getInitials(lead.get('firstName'), lead.get('lastName'));
  const salespersonInitials = getInitials(
    lead.getIn(['assignedSalesperson', 'firstName']),
    lead.getIn(['assignedSalesperson', 'lastName']),
  );

  return (
    <Card className={cx(classes.card, { [classes.cardLast]: isLast })}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <StyledLink to={`/${location.pathname.split('/')[1]}/leads/${lead.get('id')}`}>
          <Box display="flex" alignItems="center">
            <AvatarWithInitials
              initials={leadInitials}
              imageUrl={lead.get('imageUrl')}
              width="24px"
              height="24px"
            />

            <Typography className={classes.title} variant="body1">
              {`${lead.get('firstName')} ${lead.get('lastName')}`}
            </Typography>
          </Box>
        </StyledLink>
        <LeadsActionsMenu
          isCrmModule={isCrmModule}
          leadId={lead.get('id')}
          onChangeStatus={onChangeStatus}
          onChangeSalesperson={onChangeSalesperson}
        />
      </Box>

      <Typography className={classes.description} variant="body2">
        {lead.get('addressLine')}
      </Typography>

      <Box display="flex" justifyContent="flex-end">
        <Box display="flex" alignItems="center">
          <AvatarWithInitials
            initials={salespersonInitials}
            imageUrl={lead.getIn(['assignedSalesperson', 'imageUrl'])}
            width="16px"
            height="16px"
            fontSize="6px"
            marginRight={0.5}
          />

          <Typography color="textSecondary" variant="body2">
            {`${lead.getIn(['assignedSalesperson', 'firstName'])} ${lead.getIn([
              'assignedSalesperson',
              'lastName',
            ])}`}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};

export default React.memo(LeadCard);
