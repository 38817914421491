import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Grid, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';

// components
import { ExportReport } from 'modules/reports/components/ExportReport/ExportReport';
import { ReactComponent as BackArrowIcon } from 'img/arrow-circle-left.svg';

// interfaces
import { ExportTypes } from 'modules/reports/interfaces/export';
import { snackbar } from 'common/utils/snackbarUtils';
import { FormattedMessage } from 'react-intl';
import commonMessages from 'common/messages/messages';

const useStyles = makeStyles(() => ({
  buttonsSection: {
    width: '50%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

interface IProps {
  title: string | JSX.Element;
  backRedirectLink: string;
  isEmployeesReport?: boolean;
}

const PayrollReportHeader = ({
  backRedirectLink,
  title,
  isEmployeesReport = false,
}: IProps): JSX.Element => {
  const classes = useStyles();

  const handleExport = () => {
    snackbar.warning(<FormattedMessage {...commonMessages.notImplementedYet} />);
  };

  return (
    <Box display="flex" justifyContent="space-between" width="100%" mb={2}>
      <Box display="flex" alignItems="center">
        <Box mr={1}>
          <Link to={backRedirectLink}>
            <IconButton style={{ padding: 0 }}>
              <BackArrowIcon style={{ width: 17, height: 17 }} />
            </IconButton>
          </Link>
        </Box>
        {!isEmployeesReport && <Typography variant="h2">{title}</Typography>}
        {isEmployeesReport && <>{title}</>}
      </Box>

      <Box className={classes.buttonsSection}>
        <ExportReport exportAction={handleExport} exportTypes={[ExportTypes.CSV]} />
      </Box>
    </Box>
  );
};

export default PayrollReportHeader;
