// libraries
import React from 'react';

import { DialogComponent } from 'common/components';
import { IClubInfo } from '../../../../modules/crm/interfaces/leads';
import { Box, Typography } from '@material-ui/core';

interface IProps {
  open: boolean;
  clubs: IClubInfo[];
  onClose: () => void;
}

const PackageInfoDialog: React.FC<IProps> = ({ open, clubs, onClose }: IProps): JSX.Element => {
  return (
    <DialogComponent
      isOpen={open}
      onClose={onClose}
      submitBtn={null}
      cancelBtn={null}
      enableBackdropClick
      supressBottomShadow
      size="sm"
      title="Available clubs"
    >
      <Box display="flex" flexDirection="column" flexWrap="wrap" justifyContent="flex-start">
        {clubs.map(club => (
          <Typography component="p" variant="h5">
            {club.title}
          </Typography>
        ))}
      </Box>
    </DialogComponent>
  );
};

export default PackageInfoDialog;
