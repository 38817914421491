import { defineMessages } from 'react-intl';

export default defineMessages({
  clubSettingsTitle: {
    id: 'app.modules.club-settings.clubSettings.clubSettingsTitle',
    defaultMessage: 'Club Settings',
  },
  clubProfileTitle: {
    id: 'app.modules.club-settings.clubSettings.clubProfileTitle',
    defaultMessage: 'Club Profile',
  },
  editInfoLabel: {
    id: 'app.modules.club-settings.generalInfoSection.menuItem.editInfoLabel',
    defaultMessage: 'Edit Base Info',
  },
  remittanceLabel: {
    id: 'app.modules.club-settings.bankInfo.remittanceLabel',
    defaultMessage: 'Remittance',
  },
  softDescriptorLabel: {
    id: 'app.modules.club-settings.processorConfig.softDescriptorLabel',
    defaultMessage: 'Soft Descriptor',
  },
  creditCardBankLabel: {
    id: 'app.modules.club-settings.bankInfo.creditCardBankLabel',
    defaultMessage: 'Credit Card Bank',
  },
  echeckBankLabel: {
    id: 'app.modules.club-settings.bankInfo.echeckBankLabel',
    defaultMessage: 'Echeck Bank',
  },
  copyCheckLabel: {
    id: 'app.modules.club-settings.bankInfo.copyCheckLabel',
    defaultMessage: 'Copy Of Voided Check',
  },
  documentsDatesLabel: {
    id: 'app.modules.club-settings.other.documentDatesLabel',
    defaultMessage: 'Document Dates & Term With Peak',
  },
  documentsRepositoryLabel: {
    id: 'app.modules.club-settings.other.documentRepositoryLabel',
    defaultMessage: 'Document Repository',
  },
  additionalServicesLabel: {
    id: 'app.modules.club-settings.other.additionalServicesLabel',
    defaultMessage: 'Additional Services From Peak',
  },
  holidayGiftsPOCPhoneLabel: {
    id: 'app.modules.club-settings.other.holidayGiftsPOCPhoneLabel',
    defaultMessage: 'Holiday Gifts POC Phone',
  },
  nightlyReportsLabel: {
    id: 'app.modules.club-settings.other.nightlyReportsLabel',
    defaultMessage: 'Nightly Reports Emails',
  },
  workingHoursLabel: {
    id: 'app.modules.club-settings.checkInHours.workingHoursLabel',
    defaultMessage: 'Working Hours',
  },
  configureRemittanceInfoText: {
    id: 'app.modules.club-settings.bankInfo.configureRemittanceInfoText',
    defaultMessage: 'You need to configure Remittance Info',
  },
  configureCreditCardBankInfoText: {
    id: 'app.modules.club-settings.bankInfo.configureCreditCardBankInfoText',
    defaultMessage: 'You need to configure Credit Card Bank Info',
  },
  configureEcheckBankInfoText: {
    id: 'app.modules.club-settings.bankInfo.configureEcheckBankInfoText',
    defaultMessage: 'You need to configure Echeck Bank Info',
  },
  configureProcessorText: {
    id: 'app.modules.clubSettings-settings.bankInfo.configureProcessorText',
    defaultMessage: 'You need to configure Processor Configuration',
  },
  configureBillingInfoText: {
    id: 'app.modules.club-settings.billing.configureBillingInfoText',
    defaultMessage: 'You need to configure Billing Info',
  },
  configureProcessorConfigInfoText: {
    id: 'app.modules.club-settings.billing.configureProcessorConfigInfoText',
    defaultMessage: 'You need to configure Processor Config Info',
  },
  configureTaxpayerInfoText: {
    id: 'app.modules.club-settings.taxes.configureTaxpayerInfoText',
    defaultMessage: 'You need to configure Taxpayer Info',
  },
  configureOtherInfoText: {
    id: 'app.modules.club-settings.taxes.configureOtherInfoText',
    defaultMessage: 'You need to configure Other Info',
  },
  configurePayPeriodInfoText: {
    id: 'app.modules.club-settings.taxes.configurePayPeriodInfoText',
    defaultMessage: 'To see the pay period, you need to create them',
  },
  configureCheckInHoursText: {
    id: 'app.modules.club-settings.taxes.configureCheckInHoursText',
    defaultMessage: 'You need to configure Check-in hours',
  },
  configureKidZoneSettingsText: {
    id: 'app.modules.club-settings.taxes.configureKidZoneSettingsText',
    defaultMessage: 'You need to configure KidZone Settings',
  },
  editRemittanceTitle: {
    id: 'app.modules.club-settings.bankInfo.modals.editRemittanceTitle',
    defaultMessage: 'Edit Remittance',
  },
  editCreditCardBankTitle: {
    id: 'app.modules.club-settings.bankInfo.modals.editCreditCardBankTitle',
    defaultMessage: 'Edit Credit Card Bank',
  },
  editEcheckBankTitle: {
    id: 'app.modules.club-settings.bankInfo.modals.editEcheckBankTitle',
    defaultMessage: 'Edit Echeck Bank',
  },
  editProcessorConfigurationTitle: {
    id: 'app.modules.club-settings.bankInfo.modals.editProcessorConfigurationTitle',
    defaultMessage: 'Edit Processor Configuration',
  },
  editBillingTitle: {
    id: 'app.modules.club-settings.billing.modals.editBillingTitle',
    defaultMessage: 'Edit Billing',
  },
  editWorkingHoursTitle: {
    id: 'app.modules.club-settings.billing.modals.editWorkingHoursTitle',
    defaultMessage: 'Edit Working Hours',
  },
  editTaxpayerTitle: {
    id: 'app.modules.club-settings.taxes.modals.editTaxpayerTitle',
    defaultMessage: 'Edit Taxpayer',
  },
  editOtherTitle: {
    id: 'app.modules.club-settings.taxes.modals.editOtherTitle',
    defaultMessage: 'Edit Other',
  },
  newPayPeriodTitle: {
    id: 'app.modules.club-settings.taxes.modals.newPayPeriodTitle',
    defaultMessage: 'New Pay Period',
  },
  editPayPeriodTitle: {
    id: 'app.modules.club-settings.taxes.modals.editPayPeriodTitle',
    defaultMessage: 'Edit Pay Period',
  },
  editKidZoneSettingsTitle: {
    id: 'app.modules.club-settings.taxes.modals.editKidZoneSettingsTitle',
    defaultMessage: 'Edit KidZone Settings',
  },
  kidzoneDuration: {
    id: 'app.modules.club-settings.kidzone.kidzoneDuration',
    defaultMessage: '{hour} hours {minute} minutes',
  },
});
