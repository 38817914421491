import axios from 'axios';
import {
  IBillingOption,
  IEditPackageDetails,
  IPackageTemplate,
} from 'modules/services/interfaces/packages';
import { INamedEntity } from 'common/interfaces/common';
import { IPackageRCodeListItem } from 'modules/pos-settings/interfaces/revenueCodes';
import ApiService from './ApiService';
import { transformPackageDetailToDTO } from 'modules/services/utils/serializers';

export default class ServicePackageService extends ApiService {
  public createServicePackage = (formData: IEditPackageDetails): Promise<IPackageTemplate> => {
    const newPackageDto = transformPackageDetailToDTO(formData);

    return axios.post('/api/v1/packages', newPackageDto);
  };

  public updateServicePackage = (
    packageId: string,
    formData: IEditPackageDetails,
  ): Promise<IPackageTemplate> => {
    const editPackageDto = transformPackageDetailToDTO(formData);

    return axios.put(`/api/v1/packages/${packageId}`, editPackageDto);
  };

  public getPackageRcodes = (title?: string): Promise<IPackageRCodeListItem> => {
    const config = title && { params: { title } };
    return axios.get('/api/v1/packages/revenue-codes', config);
  };

  // billing options

  public getBillingOptions = (packageId: string): Promise<INamedEntity[]> =>
    axios.get(`/api/v1/packages/${packageId}/sub-bundle-configuration/billing-options`);

  public getBillingOptionById = (optionId: string): Promise<IBillingOption> =>
    axios.get(`/api/v1/packages/billing-options/${optionId}`);
}
