import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { List as ImmutableList } from 'immutable';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// interfaces
import { ILeadBulkSalespersonDto, ILeadImt, TViewType } from 'modules/crm/interfaces/leads';
import { ImmutableObject } from 'common/state/interfaces';
import { ICameFromIndicatorDictionaryItemImt } from 'common/interfaces/dictionary';
import { ITableParams } from 'common/interfaces/table';
import { FilterTypes, IFilterSettings, ISingleFilterValue } from 'common/interfaces/filter';
import { IPageMetaImt } from 'common/interfaces/pagination';

// constants
import { DictionaryList, QueryPageList } from 'common/constants';
import { LeadStatuses } from 'modules/crm/constants/leads';

// components
import { LeadsTable } from 'modules/crm/components';
import { RouteBackground } from 'components';

// utils
import { makeRequestUrlFromFilter, makeTableParams, pushQueryToUrl } from 'common/utils/http';
import { useAppDispatch } from 'store/hooks';

// state
import * as actions from 'modules/crm/state/leads/actions';
import * as selectors from 'modules/crm/state/leads/selectors';
import { selectDictionaryList } from 'common/state/dictionary/selectors';
import { fetchDictionaryList } from 'common/state/dictionary/actions';
import { updateQueryParams } from 'common/state/queryPage-lists/actions';
import {
  selectCurrentUserAvailableClubs,
  selectFilterByClubDefaultValue,
} from 'modules/authentication/state/selectors';
import { INamedEntityImt } from 'common/interfaces/common';
import { CustomerStatuses } from 'modules/front-desk/constants/common/constants';
import tableFiltersMessages from 'common/messages/tableFilters';
import { PeakModules } from 'common/constants/peakModules';
import Services from 'services/network';
import commonMessages from 'common/messages/messages';
import { useUpdateFiltersOnChangeClub } from 'common/hooks/useUpdateFiltersOnChangeClub';
import { IViewTypeData } from './LeadsContainer';
import useFirst from 'common/hooks/useFirst';

interface ILeadsProps {
  setViewTypeData: Dispatch<SetStateAction<IViewTypeData>>;
  viewType: TViewType;
  locationSearch: string;
  viewTypeData: IViewTypeData;
  isTableInitialized: boolean;
}

const Leads = (props: ILeadsProps): JSX.Element => {
  const { setViewTypeData, viewType, locationSearch, viewTypeData, isTableInitialized } = props;

  // state
  const dispatch = useAppDispatch();

  const leads: ImmutableList<ILeadImt> = useSelector(selectors.selectLeads());
  const leadsMeta: IPageMetaImt = useSelector(selectors.selectLeadsMeta());
  const isLeadsLoading: boolean = useSelector(selectors.selectLeadsLoading());
  const cameFrom: ImmutableList<ICameFromIndicatorDictionaryItemImt> = useSelector(
    selectDictionaryList(DictionaryList.CAME_FROM),
  );
  const clubsList: ImmutableList<INamedEntityImt> = useSelector(selectCurrentUserAvailableClubs);
  const filterByClubDefaultValue: ISingleFilterValue = useSelector(selectFilterByClubDefaultValue);
  const isLeadsAssignLoading: boolean = useSelector(selectors.selectLeadsAssignLoading());
  const isLeadsStatusChangeLoading: boolean = useSelector(
    selectors.selectLeadsStatusChangeLoading(),
  );
  const leadsActionResult: ImmutableObject<Record<string, boolean>> = useSelector(
    selectors.selectLeadsActionResult(),
  );

  const first = useFirst();

  const location = useLocation();
  const navigate = useNavigate();
  const module =
    location.pathname.indexOf('personal-training-crm') === -1
      ? PeakModules.Crm
      : PeakModules.PersonalTrainingCrm;

  const clubFilterOptions = useMemo(() => {
    const transformedClubs = clubsList.toJS().map(club => ({
      key: club.id,
      label: club.title,
      value: club.id,
    }));

    transformedClubs.unshift({
      key: 'emptyHomeClub',
      label: <FormattedMessage {...commonMessages.emptyHomeClubLabel} />,
      value: null,
    });

    return transformedClubs;
  }, [clubsList]);

  const clubFilterName = 'clubIds';

  const tableFilterSettings: IFilterSettings[] = useMemo(
    () => [
      {
        name: clubFilterName,
        title: <FormattedMessage {...tableFiltersMessages.club} />,
        type: FilterTypes.MULTIPLE,
        options: clubFilterOptions,
        defaultValue: filterByClubDefaultValue ? [filterByClubDefaultValue] : null,
      },
      {
        name: 'salesStatusSet',
        title: (
          <FormattedMessage
            {...(module === PeakModules.Crm
              ? tableFiltersMessages.crmStatus
              : tableFiltersMessages.ptCrmStatus)}
          />
        ),
        type: FilterTypes.MULTIPLE,
        options: LeadStatuses.getFilterOptions(),
      },
      {
        name: 'customerTypes',
        title: <FormattedMessage {...tableFiltersMessages.membershipStatus} />,
        type: FilterTypes.MULTIPLE,
        options: CustomerStatuses.getFilterOptions(),
      },
      {
        name: 'salespersonsIds',
        title: <FormattedMessage {...tableFiltersMessages.salesperson} />,
        type: FilterTypes.MULTIPLE_WITH_PAGINATE,
        perPage: 20,
        fetchItemTitle: async (id: string) => {
          try {
            const employee = await Services.Employees.getEmployeeById(id);
            return `${employee.firstName} ${employee.lastName}`;
          } catch (e) {
            return null;
          }
        },
        fetchList: async (search, page) => {
          try {
            const { meta, data } = await Services.DictionaryLists.getEmployeesList({
              search,
              page,
              perPage: 20,
              module,
            });

            return {
              pageElements: data.map(item => ({ key: item.id, label: item.title, value: item.id })),
              itemCount: meta.total,
            };
          } catch (e) {
            return { pageElements: [], itemCount: 0 };
          }
        },
      },
      {
        name: 'cameFromIndicatorIds',
        title: <FormattedMessage {...tableFiltersMessages.cameFrom} />,
        type: FilterTypes.MULTIPLE,
        options: cameFrom.toJS().map(item => ({
          key: item.id,
          label: item.title,
          value: item.id,
        })),
      },
      {
        name: 'created',
        title: <FormattedMessage {...tableFiltersMessages.created} />,
        type: FilterTypes.DATE_RANGE,
        options: {
          startDate: null,
          endDate: null,
        },
      },
    ],
    [clubFilterName, cameFrom, clubFilterOptions, filterByClubDefaultValue, module],
  );
  const [tableParams, setTableParams] = useUpdateFiltersOnChangeClub(
    {
      filterSettings:
        isTableInitialized && first
          ? tableFilterSettings.map(item => {
              if (clubFilterName === item.name) {
                return { ...item, defaultValue: null };
              }

              return item;
            })
          : tableFilterSettings,
      clubFilterName,
    },
    locationSearch,
  );

  // lifecycle

  useEffect(() => {
    dispatch(fetchDictionaryList(DictionaryList.CAME_FROM, { module }));
    dispatch(fetchDictionaryList(DictionaryList.EMPLOYEES, { module }));
  }, [dispatch, module]);

  useEffect(() => {
    dispatch(actions.fetchLeads(module, tableParams, viewType));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (leadsActionResult.get('success')) {
      dispatch(actions.fetchLeads(module, tableParams, viewType));
    }
  }, [dispatch, leadsActionResult, module, tableParams, viewType]);

  useEffect(() => {
    if (first && !isTableInitialized) {
      const params = makeTableParams(tableFilterSettings, locationSearch);
      const query = makeRequestUrlFromFilter('', params.filters);

      setViewTypeData(prevState => ({
        ...prevState,
        [viewType]: {
          ...prevState[viewType],
          locationSearch: query,
          isInitialized: true,
        },
      }));

      navigate(query);
    }
  }, [isTableInitialized, setViewTypeData, viewType, first]);

  // handlers

  const updateQueryFunction = useCallback(
    query => dispatch(updateQueryParams({ page: QueryPageList.LEADS, query })),
    [dispatch],
  );

  const handleChangeTableProps = useCallback(
    (tableProps: ITableParams): void => {
      setTableParams(tableProps);
      dispatch(actions.fetchLeads(module, tableProps, viewType));

      const query = pushQueryToUrl(
        navigate,
        tableProps,
        updateQueryFunction,
        false,
        viewType === 'pipeline',
      );

      setViewTypeData(prevState => {
        if (prevState.table.selected) {
          return { ...prevState, table: { ...prevState.table, locationSearch: query } };
        }

        return { ...prevState, pipeline: { ...prevState.pipeline, locationSearch: query } };
      });
    },
    [dispatch, module, navigate, setTableParams, setViewTypeData, updateQueryFunction, viewType],
  );

  const handleChangeViewType = (view: TViewType): void => {
    setViewTypeData(prevState => ({
      table: {
        ...prevState.table,
        selected: view === 'table',
      },
      pipeline: {
        ...prevState.pipeline,
        selected: view === 'pipeline',
      },
    }));

    const { locationSearch: query, isInitialized } = viewTypeData[view];

    const filterSettings = isInitialized
      ? tableFilterSettings.map(item => {
          if (clubFilterName === item.name) {
            return { ...item, defaultValue: null };
          }

          return item;
        })
      : tableFilterSettings;

    setTableParams(makeTableParams(filterSettings, query));
    navigate(query);
    updateQueryFunction(query);
  };

  const onAssignSalespersonLeads = useCallback(
    (options: ILeadBulkSalespersonDto) => {
      dispatch(actions.assignSalespersonLeads(options, module));
    },
    [dispatch, module],
  );

  const onChangeLeadsStatus = useCallback(
    options => {
      dispatch(actions.changeLeadsStatus(options, module));
    },
    [dispatch, module],
  );

  const onResetLeadsActionResult = useCallback(() => {
    dispatch(actions.resetLeadsActionResult());
  }, [dispatch]);

  return (
    <RouteBackground>
      <LeadsTable
        module={module}
        items={leads}
        totalRows={leadsMeta.get('total')}
        isLoading={isLeadsLoading || isLeadsAssignLoading || isLeadsStatusChangeLoading}
        leadsActionResult={leadsActionResult.toJS()}
        onChangeParams={handleChangeTableProps}
        onAssignSalespersonLeads={onAssignSalespersonLeads}
        onChangeLeadsStatus={onChangeLeadsStatus}
        resetLeadsActionResult={onResetLeadsActionResult}
        tableFilterSettings={tableFilterSettings}
        tableParams={tableParams}
        viewType={viewType}
        setViewType={handleChangeViewType}
      />
    </RouteBackground>
  );
};

export default Leads;
