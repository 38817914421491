import { createSelector } from 'reselect';

const selectPackagesListDomain = state => state.getIn(['services', 'packages', 'packagesList']);

export const selectPackagesList = () =>
  createSelector(selectPackagesListDomain, state => state.get('list'));
export const selectPackagesListMeta = () =>
  createSelector(selectPackagesListDomain, state => state.get('meta'));
export const selectPackagesListLoading = () =>
  createSelector(selectPackagesListDomain, state => state.get('isLoading'));

const selectPackageDomain = (state: any) => state.getIn(['services', 'packages', 'package']);
// New and Edit package
export const selectEditPackage = () =>
  createSelector(selectPackageDomain, state => state.get('package'));
export const selectEditPackageLoading = () =>
  createSelector(selectPackageDomain, state => state.get('packageLoading'));
export const selectCreateUpdatePackageLoading = () =>
  createSelector(selectPackageDomain, state => state.get('createUpdatePackageLoading'));
export const selectCreateUpdatePackageActionResult = () =>
  createSelector(selectPackageDomain, state => state.get('createUpdatePackageActionResult'));

// ...
const selectPackagesDomain = state => state.getIn(['services', 'packages']);

export const selectChangeStatusRequestState = () =>
  createSelector(selectPackagesDomain, state => state.get('changeStatusState'));

export const selectCloneRequestState = () =>
  createSelector(selectPackagesDomain, state => state.get('cloneState'));

// Package revenue codes

export const selectPackageRcodesList = state =>
  state.getIn(['services', 'packages', 'revenueCodes', 'data']);

export const selectPackageRcodesLoading = state =>
  state.getIn(['services', 'packages', 'revenueCodes', 'loading']);

// Employees

export const selectPackageEmployeesList = () => state =>
  state.getIn(['services', 'packages', 'employees', 'data']);

export const selectPackageEmployeesLoading = () => state =>
  state.getIn(['services', 'packages', 'employees', 'loading']);

export const selectPackageEmployeesMeta = () => state =>
  state.getIn(['services', 'packages', 'employees', 'meta']);

// Inventory

export const selectPackageInventoryList = state =>
  state.getIn(['services', 'packages', 'inventory', 'data']);

export const selectPackageInventoryLoading = state =>
  state.getIn(['services', 'packages', 'inventory', 'loading']);

// Services

export const selectPackageServicesPackagesList = state =>
  state.getIn(['services', 'packages', 'services', 'result']);

export const selectPackageServicesPackagesLoading = state =>
  state.getIn(['services', 'packages', 'services', 'loading']);

// Fees

export const selectPackageFeesList = state =>
  state.getIn(['services', 'packages', 'fees', 'result']);

export const selectPackageFeesLoading = state =>
  state.getIn(['services', 'packages', 'fees', 'loading']);

// Package info

export const selectPackageInfo = state =>
  state.getIn(['services', 'packages', 'packageInfo', 'result']);

export const selectPackageInfoLoading = state =>
  state.getIn(['services', 'packages', 'packageInfo', 'loading']);

// Billing options

const billingOptionsPath = ['services', 'packages', 'billingOptions'];

export const selectBillingOptions = state => state.getIn([...billingOptionsPath, 'billingOptions']);

export const selectBillingOptionsLoading = state =>
  state.getIn([...billingOptionsPath, 'isBillingOptionsLoading']);

export const selectBillingOption = state => state.getIn([...billingOptionsPath, 'billingOption']);

export const selectBillingOptionLoading = state =>
  state.getIn([...billingOptionsPath, 'isBillingOptionLoading']);
