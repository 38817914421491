import React from 'react';
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab';
import { Controller, useFormContext } from 'react-hook-form';
import { List as ImmutableList } from 'immutable';
import {
  Box,
  InputAdornment,
  makeStyles,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';

import {
  IPackageInstanceSubscription,
  IPaymentAccount,
  IPaymentAccountImt,
} from 'common/components/PersonProfile/interfaces';
import { getCreditCardIcon } from 'common/components/CreditCardData/constants';
import { getExpiryDate } from 'common/utils/time';
import { getAccessByPropPath } from 'common/utils/errorObject';
import { CustomTheme } from 'common/ui/interfaces';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { FormattedMessage } from 'react-intl';
import commonMessages from '../../../../messages/messages';

interface IPaymentAccountFieldProps {
  storedCreditCards: ImmutableList<IPaymentAccountImt>;
  isNewCardInputDisabled: boolean;
  index: number;
  subscription: IPackageInstanceSubscription;
  setIsOpenAddCreditCardModal: (isOpen: boolean) => void;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  paymentMethodOptionTitle: {
    marginLeft: theme.spacing(1),
  },
  optionIcon: {
    width: '16px',
    height: '16px',
    color: theme.palette.darkBackground.light,
  },
  autocompleteInput: {
    '& .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot': {
      padding: theme.spacing(0.375, 1),
    },
  },
  inputStartIcon: {
    alignSelf: 'center',
    color: theme.palette.darkBackground.light,

    '& svg': {
      width: '1rem',
      height: '1rem',
    },
  },
  addOption: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: theme.spacing(0.375),
    '&.MuiMenuItem-root.Mui-selected': {
      color: theme.palette.background.paper,
      fill: theme.palette.background.paper,
      backgroundColor: theme.palette.primary.main,
    },
  },
  addNewBtn: {
    padding: theme.spacing(1.5),
    '& p': {
      width: '100%',
      textAlign: 'center',
    },
  },
}));

const PaymentAccountField = (props: IPaymentAccountFieldProps): JSX.Element => {
  const {
    subscription,
    index,
    isNewCardInputDisabled,
    storedCreditCards,
    setIsOpenAddCreditCardModal,
  } = props;

  const { errors, control } = useFormContext();

  const renderIntlMessage = useRenderIntlMessage();

  const classes = useStyles();

  return (
    <Controller
      control={control}
      name={`subscriptions.${index}.paymentAccount`}
      defaultValue={subscription.paymentAccount}
      render={({ onBlur, value, onChange }) => (
        <MuiAutocomplete
          multiple={false}
          value={value}
          onChange={(_, card: IPaymentAccount) => onChange(card)}
          onBlur={onBlur}
          disableClearable
          disabled={isNewCardInputDisabled}
          size="small"
          options={storedCreditCards?.toJS()}
          renderOption={(card: IPaymentAccount) => {
            const CreditCardIcon = getCreditCardIcon(card.creditCardType);

            return (
              <Box display="flex">
                <CreditCardIcon className={classes.optionIcon} />
                <Typography
                  color={card.expired ? 'error' : 'initial'}
                  className={classes.paymentMethodOptionTitle}
                >
                  {`* ${card.lastFour} ${card ? getExpiryDate(card.expDate) : ''} `}
                </Typography>
              </Box>
            );
          }}
          getOptionLabel={(option: IPaymentAccount) =>
            option.lastFour ? `* ${option.lastFour} ${getExpiryDate(option.expDate)}` : ''
          }
          getOptionSelected={(option: IPaymentAccount, selectedOption: IPaymentAccount) =>
            selectedOption?.id === option?.id
          }
          renderInput={params => {
            const CreditCardIcon = getCreditCardIcon(subscription.paymentAccount?.creditCardType);

            return (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment className={classes.inputStartIcon} position="start">
                      <CreditCardIcon />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                className={classes.autocompleteInput}
                label={null}
                error={!!getAccessByPropPath(errors, `subscriptions.${index}.paymentAccount`)}
                helperText={renderIntlMessage(
                  getAccessByPropPath(errors, `subscriptions.${index}.paymentAccount`)?.message,
                )}
              />
            );
          }}
          PaperComponent={({ children, ...other }) => {
            return (
              <Paper {...other}>
                <MenuItem
                  className={classes.addNewBtn}
                  onClick={() => setIsOpenAddCreditCardModal(true)}
                  onMouseDown={e => e.preventDefault()}
                >
                  <Typography>
                    <FormattedMessage {...commonMessages.addNewBtn} />
                  </Typography>
                </MenuItem>
                {children}
              </Paper>
            );
          }}
        />
      )}
    />
  );
};

export default PaymentAccountField;
