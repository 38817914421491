import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { fromJS, List as ImmutableList } from 'immutable';
import { Grid, Box, Typography, Button, FormControlLabel, IconButton } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons';

// interfaces
import {
  IImmutablePrimaryMemberInfo,
  IPrimaryMemberInfo,
} from 'common/components/PersonProfile/interfaces';
// components
import {
  Select,
  Checkbox,
  CreateMemberAutocomplete,
  AddressBlock,
  TooltipTypography,
  AvatarWithInitials,
} from 'common/components';
import { PhoneTypesOptions } from 'common/components/PersonProfile/constants';
import GuestStatus from 'modules/front-desk/components/GuestStatus/GuestStatus';
// hooks
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
// utils
import { getInitials } from 'helpers/common';
// messages
import inputLabels from 'common/messages/inputLabels';
import commonMessages from 'common/messages/messages';
import { PersonSearchParamsType } from 'common/interfaces/http';
import { ILeadProfile } from 'modules/crm/interfaces/leads';
import { TShortProfileFieldInfoImt } from 'modules/corporate-settings/interfaces';
import { RequiredProfileInfo } from 'modules/corporate-settings/constants/requiredFields';

const useStyles = makeStyles((theme: Theme) => ({
  checkboxRow: {
    display: 'flex',
    alignItems: 'stretch',
    height: '100%',
  },
  checkboxBlock: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  addBtn: {
    minWidth: 0,
    padding: 0,
  },
  deleteBtn: {
    marginLeft: theme.spacing(1),
    padding: 0,
    opacity: '0.6',
  },
  optionInfo: {
    width: 'calc(100% - 40px)',
  },
  optionTitle: {
    display: 'block',
  },
}));

interface IContactSectionProps<T> {
  isActiveMembersRestricted?: boolean;
  hideAutocomplete?: boolean;
  selectedMembers?: any;
  setPrimaryInfo?: (person: IImmutablePrimaryMemberInfo) => void;
  getOptionsByValue: (searchString: string, customerSearchSortBy: PersonSearchParamsType) => void;
  persons: T;
  isPersonSearchLoading: boolean;
  clearSearchResults: () => void;
  recommendedFields: TShortProfileFieldInfoImt;
}

const ContactSection = <T extends ImmutableList<IPrimaryMemberInfo | ILeadProfile>>({
  isActiveMembersRestricted,
  selectedMembers,
  setPrimaryInfo,
  hideAutocomplete,
  getOptionsByValue,
  persons,
  isPersonSearchLoading,
  clearSearchResults,
  recommendedFields,
}: IContactSectionProps<T>): JSX.Element => {
  const [phoneIndex, setPhoneIndex] = useState<{ index: null | number }>({ index: null });

  const classes = useStyles();

  const renderIntlMessage = useRenderIntlMessage();

  const { control, errors, setValue, getValues } = useFormContext();

  const { fields: phones, append: appendPhone, remove: removePhone } = useFieldArray({
    control,
    keyName: 'phoneNumberId',
    name: 'phones',
  });

  useEffect(() => {
    const { index } = phoneIndex;

    if (index !== null && getValues(`phones[${index}].useAsDefault`)) {
      setValue(`phones[${index}].useAsDefault`, false);
    }
  }, [getValues, setValue, phoneIndex]);

  const onAddPhone = () => {
    appendPhone({
      phoneType: PhoneTypesOptions.values[2].value,
      phone: '',
      allowAutomatedCalls: true,
      canReceiveSms: true,
      useAsDefault: false,
    });
  };

  // renders
  const renderOption = (person: IPrimaryMemberInfo, field) => {
    const { firstName, lastName, type, email, image } = person;

    let fieldName: React.ReactNode = '';
    if (field === 'email') {
      fieldName = email;
    }
    if (field === 'phones') {
      const phonesArray = [];
      person.phones.forEach(val => {
        phonesArray.push(val.phone);
      });
      fieldName = phonesArray.join(', ');
    }

    const initials = getInitials(firstName, lastName);

    return (
      <Grid
        container
        spacing={1}
        alignItems="center"
        wrap="nowrap"
        onClick={() => setPrimaryInfo(fromJS(person))}
      >
        <Grid item>
          <AvatarWithInitials
            initials={initials}
            imageUrl={image?.url}
            width="40px"
            height="40px"
            variant="circle"
            fontSize="1rem"
            marginRight={0}
          />
        </Grid>

        <Grid item className={classes.optionInfo}>
          <TooltipTypography
            ellipsized
            variant="subtitle2"
          >{`${firstName} ${lastName}`}</TooltipTypography>
          <GuestStatus type={type} />
          <Box width="100%">
            <TooltipTypography
              ellipsized
              className={classes.optionTitle}
              variant="caption"
              color={field === 'email' ? 'textSecondary' : 'textPrimary'}
            >
              {fieldName}
            </TooltipTypography>
          </Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="button" color="textSecondary">
          <FormattedMessage {...inputLabels.address} />
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <AddressBlock recommendedFields={recommendedFields} />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="button" color="textSecondary">
          <FormattedMessage {...inputLabels.contact} />
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Controller
          control={control}
          name="email"
          render={({ value, onChange, onBlur }) => (
            <CreateMemberAutocomplete<T>
              label={<FormattedMessage {...inputLabels.email} />}
              minWidth={250}
              itemSize={66}
              fullWidth
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              freeSolo
              disableClearable
              getOptionsByValue={searchString => {
                getOptionsByValue(searchString, 'EMAIL');
              }}
              recommended={!!recommendedFields.get(RequiredProfileInfo.Email)}
              options={persons}
              isActiveMembersRestricted={isActiveMembersRestricted}
              selectedMembers={selectedMembers}
              hideAutocomplete={hideAutocomplete}
              renderOption={person => renderOption(person, 'email')}
              getOptionLabel={(member: IPrimaryMemberInfo | null) => {
                if (member && member.email) {
                  const { email } = member;
                  return email;
                }
                return '';
              }}
              loading={isPersonSearchLoading}
              clearResults={clearSearchResults}
              error={!!errors.email?.message}
              helperText={renderIntlMessage(errors.email?.message)}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          {phones?.map((phone, i) => {
            return (
              <Grid item xs={12} key={phone.phoneNumberId}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      {/* id is required for edditing phone item */}
                      {phone.id && (
                        <Controller
                          control={control}
                          name={`phones[${i}].id`}
                          render={() => <></>}
                        />
                      )}

                      <Grid item xs={6}>
                        <Controller
                          control={control}
                          name={`phones[${i}].phone`}
                          render={({ value, onChange, onBlur, ref }) => (
                            <CreateMemberAutocomplete<T>
                              minWidth={250}
                              itemSize={66}
                              label={<FormattedMessage {...inputLabels.phone} />}
                              fullWidth
                              value={value}
                              onChange={onChange}
                              freeSolo
                              disableClearable
                              autoComplete="none"
                              isPhoneNumberAutocomplete
                              getOptionsByValue={searchString => {
                                getOptionsByValue(searchString, 'PHONE_NUMBER');
                              }}
                              recommended={!!recommendedFields.get(RequiredProfileInfo.PhoneNumber)}
                              options={persons}
                              isActiveMembersRestricted={isActiveMembersRestricted}
                              hideAutocomplete={hideAutocomplete}
                              selectedMembers={selectedMembers}
                              onBlur={onBlur}
                              inputRef={ref}
                              renderOption={person => renderOption(person, 'phones')}
                              getOptionLabel={(member: IPrimaryMemberInfo | null) => {
                                if (member && member.phones) {
                                  return member.phones.reduce((acc, val) => {
                                    return `${acc}${val.phone} `;
                                  }, '');
                                }
                                return '';
                              }}
                              loading={isPersonSearchLoading}
                              clearResults={clearSearchResults}
                              error={!!errors.phones && !!errors.phones[i]}
                              helperText={renderIntlMessage(errors.phones?.[i]?.phone?.message)}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <Box className={classes.checkboxRow}>
                          <Box className={classes.checkboxBlock}>
                            <Controller
                              as={
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      size="small"
                                      onChange={e =>
                                        setValue(
                                          `phones[${i}].allowAutomatedCalls`,
                                          e.target.checked,
                                        )
                                      }
                                      defaultChecked={phones[i].allowAutomatedCalls}
                                    />
                                  }
                                  label={<FormattedMessage {...inputLabels.allowAutomatedCalls} />}
                                />
                              }
                              control={control}
                              name={`phones[${i}].allowAutomatedCalls`}
                              defaultValue={phones[i].allowAutomatedCalls}
                              variant="outlined"
                            />

                            <Controller
                              as={
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      size="small"
                                      onChange={e =>
                                        setValue(`phones[${i}].canReceiveSms`, e.target.checked)
                                      }
                                      defaultChecked={phones[i].canReceiveSms}
                                    />
                                  }
                                  label={<FormattedMessage {...inputLabels.allowTextMessages} />}
                                />
                              }
                              control={control}
                              name={`phones[${i}].canReceiveSms`}
                              defaultValue={phones[i].canReceiveSms}
                              variant="outlined"
                            />
                          </Box>

                          {!!i && (
                            <IconButton
                              className={classes.deleteBtn}
                              onClick={() => {
                                if (getValues(`phones[${i}].useAsDefault`)) {
                                  setValue(`phones[0].useAsDefault`, true);
                                }

                                removePhone(i);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </Box>
                      </Grid>

                      <Grid item xs={6}>
                        <Controller
                          control={control}
                          name={`phones[${i}].phoneType`}
                          defaultValue={phone.phoneType || PhoneTypesOptions.values[0].value}
                          render={({ onChange, onBlur, value, name }) => (
                            <Select
                              fullWidth
                              label={<FormattedMessage {...inputLabels.type} />}
                              variant="outlined"
                              name={name}
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                            >
                              {PhoneTypesOptions.getSelectOptions()}
                            </Select>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Controller
                      render={({ onChange, value, name }) => {
                        return (
                          <FormControlLabel
                            control={
                              <Checkbox
                                size="small"
                                checked={value}
                                name={name}
                                onChange={() => {
                                  const currentPhones = getValues().phones;

                                  if (!currentPhones[i].useAsDefault) {
                                    setPhoneIndex({
                                      index: currentPhones.findIndex(item => item.useAsDefault),
                                    });
                                    onChange(true);
                                  }
                                }}
                              />
                            }
                            label={<FormattedMessage {...inputLabels.useAsDefault} />}
                          />
                        );
                      }}
                      defaultValue={phones[i].useAsDefault}
                      control={control}
                      name={`phones[${i}].useAsDefault`}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>

      <Grid item>
        <Button
          className={classes.addBtn}
          color="primary"
          startIcon={<AddIcon />}
          onClick={onAddPhone}
        >
          <FormattedMessage {...commonMessages.addPhoneBtn} />
        </Button>
      </Grid>
    </Grid>
  );
};

export default React.memo(ContactSection) as typeof ContactSection;
