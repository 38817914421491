/* eslint-disable class-methods-use-this */
import axios from 'axios';
import ApiService from './ApiService';

import { ISignInResponse } from 'modules/authentication/interfaces';
import { INamedEntity } from 'common/interfaces/common';

export default class MemberPortalAuthenticationService extends ApiService {
  public REFRESH_TOKEN_URL = '/api/v1/member-portal/auth/token/refresh';

  public signIn(email: string, password: string, corporationId: string): Promise<ISignInResponse> {
    return axios.post('/api/v1/member-portal/auth/signIn', { email, password, corporationId });
  }

  public refreshToken(refreshToken: string): Promise<ISignInResponse> {
    return axios.post(this.REFRESH_TOKEN_URL, {}, { headers: { 'X-JWT-Token': refreshToken } });
  }

  public getCorporationsForSignIn(email: string): Promise<Array<INamedEntity>> {
    return axios.post('/api/v1/member-portal/auth/signIn/corporate', { email });
  }

  public signOut(): Promise<any> {
    return axios.post('/api/v1/member-portal/auth/logout');
  }
}
