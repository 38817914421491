import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Button, Collapse, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import cx from 'classnames';

import useScrollIntoView from 'common/hooks/useScrollIntoView';
import { formatPrice } from 'common/utils';

import { FeeTypeOptions } from 'modules/services/constants/fees';
import { FrequencyTypes } from 'modules/services/constants/packages';

import { IBundleView } from 'modules/services/interfaces/packages';

import { FieldInfo } from 'common/components/index';
import { ReactComponent as AngleDownIcon } from 'img/icons/angle-down.svg';
import { ReactComponent as AngleRightIcon } from 'img/icons/angle-right.svg';
import { ReactComponent as CrossIcon } from 'img/icons/times.svg';
import { ReactComponent as CheckIcon } from 'img/icons/check.svg';

import messages from 'common/components/PersonProfile/messages';
import inputLabels from 'common/messages/inputLabels';

const useStyles = makeStyles((theme: Theme) => ({
  sectionBtn: {
    padding: 0,
    color: theme.palette.text.secondary,

    '& svg': {
      width: '0.75rem',
      height: '0.75rem',
      marginRight: theme.spacing(1),
    },
  },
  feeTypeOption: {
    marginLeft: theme.spacing(0.5),
    color: theme.palette.darkBackground.light,
  },
  splittingStatus: {
    display: 'flex',
    alignItems: 'center',
  },
  statusIcon: {
    width: '1rem',
    height: '1rem',
    marginRight: theme.spacing(0.5),

    '&.primary': {
      color: theme.palette.primary.main,
    },
    '&.error': {
      color: theme.palette.error.main,
    },
  },
}));

interface IProps {
  bundle: IBundleView;
}

const FeesView = ({ bundle }: IProps): JSX.Element => {
  const [showContent, setShowContent] = useState<boolean>(true);

  const { scrollRef } = useScrollIntoView(showContent);

  const classes = useStyles();

  if (!bundle?.fees?.length) {
    return null;
  }

  return (
    <Box>
      <Button
        className={classes.sectionBtn}
        onClick={() => setShowContent(!showContent)}
        startIcon={showContent ? <AngleDownIcon /> : <AngleRightIcon />}
      >
        <FormattedMessage {...messages.includedFeesTitle} />
      </Button>

      <Collapse in={showContent} ref={scrollRef}>
        {bundle.fees.map((feeItem, index) => (
          <Box
            display="flex"
            flexDirection="column"
            gridGap={16}
            mt={index ? 1.5 : 1}
            key={feeItem.id}
          >
            <Typography>
              <Typography component="span" variant="h5">
                {feeItem.fee.title}
              </Typography>
              <Typography component="span" className={classes.feeTypeOption}>
                {FeeTypeOptions.translate(feeItem.fee.type)}
              </Typography>
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FieldInfo
                  label={<FormattedMessage {...inputLabels.amount} />}
                  description={formatPrice(feeItem.totalAmount)}
                />
              </Grid>

              <Grid item xs={4}>
                <FieldInfo
                  label={<FormattedMessage {...inputLabels.chargeAfterDays} />}
                  description={`${feeItem.chargeAfterDays}`}
                />
              </Grid>

              <Grid item xs={4} className={classes.splittingStatus}>
                {feeItem.allowSplitting ? (
                  <CheckIcon className={cx(classes.statusIcon, 'primary')} />
                ) : (
                  <CrossIcon className={cx(classes.statusIcon, 'error')} />
                )}

                <Typography component="span">
                  <FormattedMessage {...inputLabels.splitPayment} />
                </Typography>
              </Grid>

              {feeItem.allowSplitting && (
                <>
                  <Grid item xs={4}>
                    <FieldInfo
                      label={<FormattedMessage {...inputLabels.paymentSchedule} />}
                      description={FrequencyTypes.translate(feeItem.splitPaymentSchedule)}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <FieldInfo
                      label={<FormattedMessage {...inputLabels.payments} />}
                      description={`${feeItem.splits?.length}`}
                    />
                  </Grid>

                  {feeItem.splits?.map((splitItem, splitItemIndex) => (
                    <Grid item xs={4} key={splitItem.order}>
                      <FieldInfo
                        label={`#${splitItemIndex + 1}`}
                        description={formatPrice(splitItem.value)}
                      />
                    </Grid>
                  ))}
                </>
              )}
            </Grid>
          </Box>
        ))}
      </Collapse>
    </Box>
  );
};

export default FeesView;
