import { parsePhoneNumber, isValidPhoneNumber } from "react-phone-number-input/input";

export const getFieldName = (fieldName: string, additionalFieldName: string): string =>
  `${fieldName}${additionalFieldName}`;

export const getInitials = (firstName: string, lastName: string): string => {
  return `${(firstName || "").slice(0, 1)}${(lastName || "").slice(0, 1)}`;
};

export const convertPhoneNumber = (phoneNumber: string): string => {
  if (phoneNumber && isValidPhoneNumber(phoneNumber)) {
    const parsedPhone = parsePhoneNumber(phoneNumber);

    if (parsedPhone.country === "US") {
      return parsedPhone.formatNational();
    }

    return parsedPhone.formatInternational();

  }

  return phoneNumber;
};
