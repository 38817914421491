import React, { useCallback, useState } from 'react';
import { SearchInput } from 'common/components';
import { Box, debounce, makeStyles, Theme } from '@material-ui/core';
import { MessageDescriptor } from 'react-intl';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(2),
    '& .MuiInputBase-root': {
      borderRadius: 5,
      backgroundColor: theme.palette.secondary.light,
    },
    '& .MuiPaper-root': {
      boxShadow: 'none',
    },
  },
}));

interface ISearchInputProps {
  placeholder: MessageDescriptor;
  callback: (value: string) => void;
}

export default function SearchInputInvoice({
  placeholder,
  callback,
}: ISearchInputProps): JSX.Element {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState<string>('');
  const renderIntlMessage = useRenderIntlMessage();

  const loadMembers = useCallback(
    value => {
      callback(value);
    },
    [callback],
  );

  const onChangeSearch = useCallback(
    debounce((search: string) => {
      loadMembers(search);
    }, 500),
    [loadMembers],
  );

  return (
    <Box className={classes.root}>
      <SearchInput
        placeholder={renderIntlMessage(placeholder)}
        onChange={e => {
          onChangeSearch(e);
          setInputValue(e);
        }}
        value={inputValue}
      />
    </Box>
  );
}
