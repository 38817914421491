import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { List as ImmutableList } from 'immutable';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

// state
import { selectCurrentUserPhone } from 'modules/authentication/state/selectors';
import {
  selectDictionaryItem,
  selectDictionaryItemLoading,
} from 'common/state/dictionary/selectors';

import { resetDictionaryItemAction } from 'common/state/dictionary/actions';
// icons
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { ReactComponent as UncheckIcon } from 'img/icons/checkbox-round.svg';
import { ReactComponent as CheckIcon } from 'img/icons/сheckbox-round-checked.svg';
// interfaces
import { ICrmCampaignDtoImt, IEmployeeListItemImt } from 'common/interfaces/dictionary';
import { INamedEntityImt } from 'common/interfaces/common';
import { ICallNoteDto, IMemberPhoneItemImt } from 'common/components/PersonProfile/interfaces';
import { CustomTheme } from 'common/ui/interfaces';
import { IScriptStepDtoList } from 'modules/crm/interfaces/scripts';

// hooks
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import { convertPhoneNumber } from 'helpers/common';

// components
import {
  Checkbox,
  DateTimePicker,
  DialogComponent,
  LoadingBackdrop,
  Select,
  TextArea,
} from 'common/components/index';

// constants
import { DictionaryItem } from 'common/constants/dictionaryConstants';
import {
  DATE_PICKER_DATE_FORMAT,
  DATE_PICKER_TIME_FORMAT,
} from 'common/components/DateTimePicker/DatePickerLayer';

// messages
import inputLabels from 'common/messages/inputLabels';
import componentMessages from 'common/components/PersonProfile/messages';

// validation schema
import { NewCallValidationSchema } from './NewCallValidationSchema';
import { QuestionnaireModal } from 'common/modals';
import messages from 'common/messages/messages';

const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    flex: 1,
    minHeight: 0,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  accordion: {
    boxShadow: 'none',
  },
  accordionSummary: {
    minHeight: '48px !important',
    padding: theme.spacing(0, 1),

    '& .MuiAccordionSummary-content': {
      margin: theme.spacing(0),
    },
  },
  accordionDetails: {
    paddingLeft: theme.spacing(4.5),
    flexDirection: 'column',
  },
  accordionText: {
    marginBottom: theme.spacing(1.5),
    opacity: '0.7',
    lineHeight: '1.25',
  },
  stepName: {
    fontWeight: 500,
    marginLeft: theme.spacing(1.5),
  },
  divider: {
    width: '100%',
    backgroundColor: theme.palette.borderColor.main,
  },
  switchLabel: {
    fontSize: '0.875rem',
    fontWeight: 500,
  },
}));

interface IProps {
  isOpen: boolean;
  campaigns?: ImmutableList<INamedEntityImt>;
  personPhones?: ImmutableList<IMemberPhoneItemImt>;
  employees: ImmutableList<IEmployeeListItemImt>;
  isLoading: boolean;
  fetchCampaign: (campaignId: string) => void;
  onClose: () => void;
  onSubmit: (call: ICallNoteDto) => void;
}

const initialValues = {
  salespersonPhone: '',
  leadPhone: null,
  date: new Date(),
  campaign: null,
  note: '',
  callScriptSteps: [],
};

const NewCallModal = ({
  isOpen,
  campaigns,
  personPhones,
  employees,
  isLoading,
  fetchCampaign,
  onClose,
  onSubmit,
}: IProps): JSX.Element => {
  const dispatch = useDispatch();
  const salespersonPhone: string = useSelector(selectCurrentUserPhone);
  const campaign: ICrmCampaignDtoImt = useSelector(
    selectDictionaryItem(DictionaryItem.CAMPAIGN_PHONE),
  );
  const isCampaignLoading: boolean = useSelector(
    selectDictionaryItemLoading(DictionaryItem.CAMPAIGN_PHONE),
  );

  const [{ isOpenQuestionnaireModal, currentCampaignId }, setIsOpenQuestionnaireModal] = useState({
    isOpenQuestionnaireModal: false,
    currentCampaignId: '',
  });

  const [timezoneMoment] = useTimezoneMoment();

  const { date, time } = useMemo(() => {
    const utcDate = timezoneMoment()
      .add(10, 'minutes')
      .utc();
    return {
      date: utcDate.format(DATE_PICKER_DATE_FORMAT),
      time: utcDate.format(DATE_PICKER_TIME_FORMAT),
    };
  }, [timezoneMoment]);

  const formMethods = useForm({
    defaultValues: {
      ...initialValues,
      salespersonPhone,
      date,
      time,
    },
    resolver: yupResolver(NewCallValidationSchema),
    shouldUnregister: false,
    mode: 'all',
  });

  const { control, errors, handleSubmit, reset, getValues, setValue } = formMethods;

  const { fields: callSteps } = useFieldArray({
    control,
    name: 'callScriptSteps',
    keyName: 'key',
  });

  const classes = useStyles();

  const renderErrorMessage = useRenderIntlMessage();

  useEffect(() => {
    if (campaign?.size) {
      reset({
        ...getValues(),
        callScriptSteps: campaign
          .getIn(['scriptDto', 'scriptStepDtoList'])
          .toJS()
          .sort((firstStep, secondStep) => firstStep.index - secondStep.index)
          .map(scriptStep => ({
            ...scriptStep,
            completed: false,
            ...(scriptStep.allowInput && { note: '' }),
          })),
      });
    }
  }, [campaign, getValues, reset]);

  useEffect(() => {
    return () => {
      dispatch(resetDictionaryItemAction({ dictionary: DictionaryItem.CAMPAIGN_PHONE }));
    };
  }, [dispatch, isOpen]);

  const submitForm = (values: ICallNoteDto) => {
    const { campaign: campaignId, callScriptSteps } = values;

    const currentCampaign =
      campaigns.find(campaignItem => campaignItem.get('id') === campaignId)?.toJS() || null;

    const transformedScriptSteps = callScriptSteps.map(({ id, ...otherFields }) => ({
      ...otherFields,
      scriptStepId: id,
    }));

    onSubmit({ ...values, campaign: currentCampaign, callScriptSteps: transformedScriptSteps });
  };

  const onChangeCampaign = (v: string, onChange: (v: string) => void) => {
    const note = getValues('note');
    const callScriptSteps = getValues('callScriptSteps');
    const isExistEnteredNote = callScriptSteps?.some(({ note: n }) => Boolean(n?.trim()));

    if (note?.trim().length || isExistEnteredNote) {
      setIsOpenQuestionnaireModal({ currentCampaignId: v, isOpenQuestionnaireModal: true });
    } else if (v !== '') {
      fetchCampaign(v);
      onChange(v);
    } else {
      setValue('callScriptSteps', []);
      onChange(v);
    }
  };

  const onCloseQuestionnaireModal = () => {
    setIsOpenQuestionnaireModal({ currentCampaignId: '', isOpenQuestionnaireModal: false });
  };

  const onConfirmQuestionnaireModal = () => {
    if (currentCampaignId) {
      fetchCampaign(currentCampaignId);
    } else {
      setValue('callScriptSteps', []);
      setValue('note', '');
    }

    setValue('campaign', currentCampaignId);
    setIsOpenQuestionnaireModal({ currentCampaignId: '', isOpenQuestionnaireModal: false });
  };

  return (
    <DialogComponent
      title={<FormattedMessage {...componentMessages.newCallNoteMessage} />}
      size="xs"
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(submitForm)}
      loading={isLoading}
    >
      <FormProvider {...formMethods}>
        <form className={classes.root} autoComplete="none">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="salespersonPhone"
                    control={control}
                    render={({ value }) => (
                      <Autocomplete
                        disableClearable
                        value={value || null}
                        multiple={false}
                        disabled
                        getOptionLabel={option => convertPhoneNumber(option) || ''}
                        options={[salespersonPhone]}
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={<FormattedMessage {...inputLabels.from} />}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Controller
                    name="leadPhone"
                    control={control}
                    render={({ value, onChange }) => (
                      <Autocomplete
                        disableClearable
                        value={value || {}}
                        onChange={(event, v: IMemberPhoneItemImt) => onChange(v)}
                        getOptionLabel={option => convertPhoneNumber(option.phone) || ''}
                        options={personPhones.toJS() || []}
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="outlined"
                            error={!!errors.leadPhone}
                            helperText={renderErrorMessage(errors.leadPhone?.message)}
                            label={<FormattedMessage {...inputLabels.to} />}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <DateTimePicker disablePast />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="campaign"
                control={control}
                render={({ value, onChange }) => (
                  <Select
                    label={<FormattedMessage {...inputLabels.campaign} />}
                    variant="outlined"
                    error={!!errors.campaign}
                    helperText={errors.campaign?.message}
                    fullWidth
                    value={value}
                    onChange={v => onChangeCampaign(v, onChange)}
                  >
                    <MenuItem key="empty-option" value="">
                      <Typography variant="body1">
                        <FormattedMessage {...componentMessages.noCampaign} />
                      </Typography>
                    </MenuItem>
                    {Boolean(campaigns?.size) &&
                      campaigns.map(campaignItem => (
                        <MenuItem key={campaignItem.get('id')} value={campaignItem.get('id')}>
                          {campaignItem.get('title')}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              {callSteps?.length ? (
                <>
                  <Divider className={classes.divider} />
                  {callSteps.map((step: IScriptStepDtoList & { key: string }, i) => (
                    <Box key={step.key}>
                      <Accordion className={classes.accordion}>
                        <AccordionSummary
                          className={classes.accordionSummary}
                          expandIcon={<ExpandMoreIcon />}
                          aria-label="Expand"
                          aria-controls="additional-actions3-content"
                          id="additional-actions3-header"
                        >
                          <Controller
                            name={`callScriptSteps[${i}].completed`}
                            control={control}
                            render={({ onChange, value }) => (
                              <Checkbox
                                checked={value}
                                onChange={(e, v) => onChange(v)}
                                icon={<UncheckIcon />}
                                checkedIcon={<CheckIcon />}
                              />
                            )}
                          />

                          <Typography className={classes.stepName}>{step.title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                          <Typography className={classes.accordionText} variant="body2">
                            {step.instruction}
                          </Typography>

                          {step.allowInput && (
                            <Controller
                              as={TextField}
                              control={control}
                              name={`callScriptSteps[${i}].note`}
                              label={<FormattedMessage {...inputLabels.note} />}
                              variant="outlined"
                              multiline
                              rowsMax={3}
                              fullWidth
                              error={!!errors.callScriptSteps?.[i]?.note}
                              helperText={renderErrorMessage(
                                errors.callScriptSteps?.[i]?.note?.message,
                              )}
                            />
                          )}
                        </AccordionDetails>
                      </Accordion>
                      <Divider className={classes.divider} />
                    </Box>
                  ))}
                </>
              ) : (
                <Controller
                  name="note"
                  control={control}
                  render={({ value, onChange }) => (
                    <TextArea
                      value={value}
                      onChange={onChange}
                      maxSymbols={300}
                      fullWidth
                      rows={7}
                      variant="outlined"
                      label={<FormattedMessage {...inputLabels.callNote} />}
                    />
                  )}
                />
              )}

              <LoadingBackdrop isLoading={isCampaignLoading} />
            </Grid>

            {/* <Grid item xs={12}>
              <Grid container justify="space-between" alignItems="center">
                <Grid item>
                  <Typography className={classes.switchLabel}>Create appointment</Typography>
                </Grid>
                <Grid item>
                  <Controller
                    control={control}
                    name="appointment"
                    render={({ value, onChange }) => (
                      <Switch
                        color="primary"
                        value={value}
                        onChange={e => {
                          onChange(e.target.checked);
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
             <Grid item xs={12}>
              <Collapse in={watch('appointment')} unmountOnExit>
                <CallEventSection event={call && call.get('event')} employees={employees} />
              </Collapse>
            </Grid> */}
          </Grid>
        </form>
      </FormProvider>
      {isOpenQuestionnaireModal && (
        <QuestionnaireModal
          isOpen
          onClose={onCloseQuestionnaireModal}
          onSubmit={onConfirmQuestionnaireModal}
          body={
            <FormattedMessage
              {...componentMessages.confirmOverrideTextMessage}
              values={{ value: 'call' }}
            />
          }
          submitBtnTitle={<FormattedMessage {...messages.confirmBtn} />}
          cancelBtnTitle={<FormattedMessage {...messages.cancelBtn} />}
        />
      )}
    </DialogComponent>
  );
};

export default NewCallModal;
