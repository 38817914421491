import { makeStyles, Theme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core';

const sidebarStyle = makeStyles((theme: Theme) => {
  return {
    root: {
      position: 'absolute',
      left: 0,
      top: 0,
      backgroundColor: theme.palette.text.primary,
      display: 'flex',
      minWidth: 230,
      width: 230,
      height: '100%',
      transition: 'all .15s',
      flexDirection: 'column',
      overflow: 'hidden',
      zIndex: 10,
      maxHeight: '100%',

      '& + .pageWrapper': {
        paddingLeft: 0,
      },

      [theme.breakpoints.up('desktop')]: {
        '& + .pageWrapper': {
          paddingLeft: 230,
        },
      },

      '&.sidebar--closed': {
        zIndex: 10,
        width: 0,
        minWidth: 0,

        '& + .pageWrapper': {
          paddingLeft: 0,
        },
        [theme.breakpoints.up('desktop')]: {
          width: '64px',
          minWidth: '64px',
          '& + .pageWrapper': {
            paddingLeft: 64,
          },
        },
        '&.scrollBox': {
          padding: theme.spacing(0, 1, 1, 1),
        },
      },

      '&.sidebar--open-on-hover': {
        position: 'absolute',
        zIndex: 10,
        '& + .pageWrapper': {
          paddingLeft: '64px',
        },
      },
    },
    inactiveMenu: {
      pointerEvents: 'none',
    },
    footer: {
      minHeight: 52,
      padding: theme.spacing(1, 1.5),
      borderTop: `1px solid ${theme.palette.secondary.main}`,
    },
    footerLogo: {
      height: 30,
      minWidth: 30,
      width: 30,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
    },
    sidebarIconWrapper: {
      color: theme.palette.common.white,
      '& button:hover': {
        backgroundColor: fade(theme.palette.text.secondary, 0.7),
      },
      '&:hover': {
        backgroundColor: fade(theme.palette.text.secondary, 0.7),
      },
    },
  };
});

export default sidebarStyle;
