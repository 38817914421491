import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { List as ImmutableList } from 'immutable';
import {
  Card,
  Grid,
  IconButton,
  StyledComponentProps,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
// Components
import { TimesIcon } from 'img/icons';
// Messages
import messages from 'modules/front-desk/messages/messages';
// Constants
import { DictionaryList } from 'common/constants/dictionaryConstants';
// interfaces
import { IPrimaryMemberInfo } from 'common/components/PersonProfile/interfaces';
// state
import { selectDictionaryList } from 'common/state/dictionary/selectors';
import { fetchDictionaryList } from 'common/state/dictionary/actions';
import * as actions from 'common/state/newPerson/primaryInfo/actions';
import * as selectors from 'common/state/newPerson/primaryInfo/selectors';
// hooks
import { useAppDispatch } from 'store/hooks';
// common
import { DialogComponent, MemberPrimaryInfoStep } from 'common/components';
import { selectCurrentUser } from 'modules/authentication/state/selectors';
import { CustomTheme } from 'common/ui/interfaces';
import Footer from 'common/containers/StepContainers/EditProfile/Footer';
import {
  ICameFromIndicatorDictionaryItemImt,
  IEmployeeListItemImt,
} from 'common/interfaces/dictionary';
import { PeakModules } from 'common/constants/peakModules';
import { ActionResult } from 'common/constants';

interface IEditProfileProps extends StyledComponentProps {
  closeEditProfile: () => void;
  isOpenEditProfile: boolean;
  module?: PeakModules.FrontDesk | PeakModules.Members;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    flex: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  pageHeader: {
    padding: theme.spacing(2, 2, 0, 2),
  },
  closeBtn: {
    width: '14px',
    height: '14px',
  },
  closeBtnIcon: {
    fontSize: '14px',
    opacity: '0.3',
  },
}));

const EditProfile = (props: IEditProfileProps): JSX.Element => {
  const { closeEditProfile, module = PeakModules.FrontDesk, isOpenEditProfile } = props;

  const dispatch = useAppDispatch();

  const frontDeskProfile = useSelector(selectors.selectPrimaryInfo);
  const memberProfile = useSelector(selectors.selectPrimaryInfo);
  const loading = useSelector(selectors.selectLoading);
  const updateProfileActionResult = useSelector(selectors.selectActionUpdateResult);
  const employees: ImmutableList<IEmployeeListItemImt> = useSelector(
    selectDictionaryList(DictionaryList.EMPLOYEES),
  );
  const cameFromIndicators: ImmutableList<ICameFromIndicatorDictionaryItemImt> = useSelector(
    selectDictionaryList(DictionaryList.CAME_FROM),
  );
  const currentUser = useSelector(selectCurrentUser);

  const profile = module === PeakModules.FrontDesk ? frontDeskProfile : memberProfile;

  const classes = useStyles();

  useEffect(() => {
    dispatch(fetchDictionaryList(DictionaryList.EMPLOYEES, { module }));
    dispatch(fetchDictionaryList(DictionaryList.CAME_FROM, { module }));

    return () => {
      dispatch(actions.clearNewMemberDataAction());
    };
  }, [dispatch, module]);

  useEffect(() => {
    if (updateProfileActionResult === ActionResult.SUCCESS_ACTION) {
      dispatch(actions.resetUpdateMemberResult());
      closeEditProfile();
    }
  }, [updateProfileActionResult, dispatch, closeEditProfile]);

  const handleOnSubmitPrimaryInfoStep = (data: IPrimaryMemberInfo) => {
    // ToDo: when connected api need uncomment it's
    // let updatesData = {};
    // Object.keys(data).forEach(key => {
    //   if (!deepEquals(data[key], profile.toJS()[key]))
    //     updatesData = { ...updatesData, [key]: data[key] };
    // });
    // if (Object.keys(updatesData).length > 0) {
    //   updateMember(profile.get('id'), updatesData);
    // } else {
    //   this.goBack();
    // }
    dispatch(actions.updateMember(profile.get('id'), data, module));
  };

  const renderFooter = (onBack, onNext): JSX.Element => {
    return <Footer onNext={onNext} onBack={onBack} isLoading={loading} />;
  };

  return (
    <Card className={classes.root}>
      <Grid container className={classes.pageHeader} justify="space-between">
        <Grid item>
          <Typography component="h1" variant="h3">
            <FormattedMessage {...messages.editProfileCommonTitle} />
          </Typography>
        </Grid>
        <Grid item>
          <IconButton className={classes.closeBtn} size="small" onClick={closeEditProfile}>
            <SvgIcon className={classes.closeBtnIcon}>
              <TimesIcon />
            </SvgIcon>
          </IconButton>
        </Grid>
      </Grid>
      <DialogComponent
        isOpen={isOpenEditProfile}
        title={<FormattedMessage {...messages.editProfileCommonTitle} />}
        onClose={closeEditProfile}
        loading={loading}
        size="md"
        formId="primary-info-form"
      >
        <MemberPrimaryInfoStep
          cameFromIndicators={cameFromIndicators}
          currentUser={currentUser}
          employees={employees}
          primaryInfo={profile}
          hideAutocomplete
          module={module}
          handleOnSubmit={handleOnSubmitPrimaryInfoStep}
        />
      </DialogComponent>
    </Card>
  );
};

export default EditProfile;
