import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Grid } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';

import { getAccessByPropPath } from 'common/utils/errorObject';

import { FeesConfigurationSchema } from 'common/components/Steps/TrialMembershipStep/ValidationSchema';

import { IEditablePackageConfiguration } from 'common/interfaces/membership';

import { DialogComponent } from 'common/components/index';

import packagesMessages from 'modules/services/messages/packages';
import FeeInstance from './FeesSection/FeeInstance';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (packageItem: IEditablePackageConfiguration) => void;
  membershipPackage: IEditablePackageConfiguration;
}

const EditFeesModal = ({ isOpen, onClose, onSubmit, membershipPackage }: IProps): JSX.Element => {
  const formMethods = useForm({
    defaultValues: { fees: [] },
    resolver: yupResolver(FeesConfigurationSchema),
    mode: 'onBlur',
    shouldUnregister: false,
  });

  const { handleSubmit, control, errors, watch, reset } = formMethods;

  useEffect(() => {
    reset({ fees: membershipPackage.feeSection.fees });
  }, [membershipPackage, reset]);

  const handleSubmitForm = formValues => {
    onSubmit({
      ...membershipPackage,
      feeSection: { ...membershipPackage.feeSection, ...formValues },
    });
  };

  return (
    <DialogComponent
      isOpen={isOpen}
      onClose={onClose}
      title={<FormattedMessage {...packagesMessages.editFeesModalTitle} />}
      onSubmit={handleSubmit(handleSubmitForm)}
    >
      <FormProvider {...formMethods}>
        <form>
          <Grid container spacing={2}>
            {membershipPackage.feeSection.fees.map((feeItem, index) => (
              <Grid item xs={12}>
                <FeeInstance
                  key={feeItem.id}
                  formName={`fees[${index}]`}
                  packageFee={feeItem}
                  control={control}
                  errors={getAccessByPropPath(errors, `fees.${index}`)}
                  watch={watch}
                />
              </Grid>
            ))}
          </Grid>
        </form>
      </FormProvider>
    </DialogComponent>
  );
};

export default EditFeesModal;
