import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Divider, makeStyles, Typography } from '@material-ui/core';

import Chips from './Chips';
import messages from '../messages';
import commonMessages from 'common/messages/messages';
import { CustomTheme } from 'common/ui/interfaces';
import { ISecurityRoleToClubListDto } from 'common/interfaces/employee';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { LoadingBackdrop } from 'common/components';

interface IPermissionSectionProps {
  profilePermissions: ISecurityRoleToClubListDto[];
  EditPermissions?: JSX.Element;
  isLoading: boolean;
  hideProfileClubCount?: boolean;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  block: {
    width: '100%',
    border: `1px solid ${theme.palette.borderColor.main}`,
    borderRadius: '3px',

    // TODO: Add a general solution for the styles
    '& .empty-section-placeholder': {
      height: 'calc(100% - 30px)',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      paddingBottom: theme.spacing(2),

      '& .empty-text': {
        textAlign: 'center',
        fontSize: '0.9rem',
        color: theme.palette.text.secondary,
        opacity: 0.5,
        lineHeight: '19px',
      },
    },
  },
  blockTitle: {
    textTransform: 'uppercase',
    padding: theme.spacing(2, 0, 2, 0),
  },
  clubCountTitle: {
    color: theme.palette.darkBackground.light,
  },
  roleTitle: {
    paddingRight: theme.spacing(2),
    maxWidth: '75%',
  },
}));

const PermissionsBlock = (props: IPermissionSectionProps): JSX.Element => {
  const { profilePermissions, isLoading, hideProfileClubCount, EditPermissions } = props;

  const classes = useStyles();

  const renderIntlMessage = useRenderIntlMessage();
  const elements = profilePermissions.map(item => {
    const { length } = item.clubList;
    return (
      <Box pb={2} display="flex" flexDirection="column">
        <Divider />
        <Box display="flex" justifyContent="space-between" pt={2} pb={1.25}>
          <Typography variant="h5" color="primary" className={classes.roleTitle}>
            {item.securityRole.title}
          </Typography>
          {!hideProfileClubCount && (
            <Typography noWrap className={classes.clubCountTitle}>{`${length} ${renderIntlMessage(
              length === 1 ? commonMessages.clubLabel : commonMessages.clubsLabel,
            )}`}</Typography>
          )}
        </Box>
        <Chips chips={item.clubList} />
      </Box>
    );
  });

  return (
    <Box
      className={classes.block}
      pr={2}
      pl={2}
      pb={2.5}
      position="relative"
      display="flex"
      flexDirection="column"
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" color="textSecondary" className={classes.blockTitle}>
          <FormattedMessage {...messages.permissions} />
        </Typography>
        {EditPermissions}
      </Box>

      {profilePermissions.length ? (
        elements
      ) : (
        <Box className="empty-section-placeholder">
          <Typography className="empty-text">
            <FormattedMessage {...messages.emptyPermissions} />
          </Typography>
          <Typography className="empty-text">
            <FormattedMessage {...messages.emptyPermissionsHint} />
          </Typography>
        </Box>
      )}
      <LoadingBackdrop isLoading={isLoading} />
    </Box>
  );
};

export default PermissionsBlock;
