import React from 'react';
import { DateRangePicker, defaultStaticRanges } from 'react-date-range';
import moment from 'moment-timezone';
import { fade, makeStyles } from '@material-ui/core';

import { IDateRangeFilterValue } from 'common/interfaces/filter';
import { CustomTheme } from 'common/ui/interfaces';

import menuItems from 'common/messages/menuItems';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    color: theme.palette.text.primary,

    '& .rdrCalendarWrapper': {
      color: theme.palette.text.primary,
    },

    '& .rdrDateDisplayWrapper': {
      backgroundColor: theme.palette.background.paper,

      '& .rdrDateDisplayItem': {
        border: 'none',
        boxShadow: 'none',
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(0, 1),

        '&:first-child': {
          textAlign: 'right',
        },

        '&:last-child': {
          textAlign: 'left',
        },
      },
    },

    '& .rdrNextPrevButton': {
      background: 'none',
    },

    '& .rdrMonthAndYearWrapper': {
      height: 20,
      padding: 0,
    },

    '& .rdrMonthAndYearPickers': {
      display: 'none',
    },

    '& .rdrMonth': {
      width: 250,
      padding: '0 10px',

      '&:first-child': {
        paddingLeft: 20,
      },

      '&:last-child': {
        paddingRight: 20,
      },

      '& .rdrMonthName': {
        textAlign: 'center',
        fontSize: 14,
        marginTop: -30,
        color: theme.palette.text.primary,
      },

      '& .rdrWeekDays': {
        paddingBottom: '10px',

        '& .rdrWeekDay': {
          fontWeight: 500,
        },
      },
    },

    '& .rdrWeekDay': {
      lineHeight: 1,
    },

    '& .rdrInRange': {
      color: `${fade(theme.palette.primary.main, 0.5)} !important`,
    },

    '& .rdrDay': {
      color: `${theme.palette.primary.main} !important`,

      '& .rdrStartEdge,.rdrEndEdge': {
        color: `${theme.palette.primary.main} !important`,
      },

      '& .rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview': {
        borderColor: theme.palette.primary.main,
      },
    },

    '& .rdrDayToday .rdrDayNumber span:after': {
      background: theme.palette.primary.main,
    },

    '& .rdrStaticRange.rdrStaticRangeSelected .rdrStaticRangeLabel': {
      color: theme.palette.primary.main,
    },
  },
}));

interface IProps {
  value: IDateRangeFilterValue;
  onChange: (dateRange: IDateRangeFilterValue) => void;
  months?: number;
  moveRangeOnFirstSelection?: boolean;
  direction?: 'horizontal' | 'vertical';
  maxDate?: Date | null;
  minDate?: Date | null;
}

const DateRange = ({
  value,
  months = 2,
  moveRangeOnFirstSelection,
  onChange,
  direction = 'horizontal',
  maxDate,
  minDate,
}: IProps): JSX.Element => {
  const classes = useStyles();

  const calendarRangeItem = {
    startDate: value.startDate ? new Date(value.startDate) : new Date(),
    endDate: value.endDate ? new Date(value.endDate) : new Date(),
    key: 'selection',
  };

  const handleRangeChange = rangeItem => {
    const {
      selection: { startDate, endDate },
    } = rangeItem;

    // When date is processed to be send to backend as string,  it is converted to string by .toISOString() method
    // In this case from time adjusted timezone value. Date creates with 00:00:00 time, and in some cases like local
    // When got date like 'Wed Jun 15 2022 00:00:00 GMT+0300' we send to backend date like this:
    // - '2022-06-14T21:00:00.000Z' - a day before selected date in date range picker, this is global topic for FE and BE.
    onChange({
      startDate: new Date(startDate.setHours(23)),
      endDate: new Date(endDate.setHours(23)),
    });
  };

  const maxDateProps = maxDate ? { maxDate } : {};
  const minDateProps = minDate ? { minDate } : {};

  return (
    <DateRangePicker
      className={classes.root}
      weekdayDisplayFormat="EEEEE"
      monthDisplayFormat="MMMM, yyyy"
      showMonthAndYearPickers={false}
      months={months}
      direction={direction}
      ranges={[calendarRangeItem]}
      moveRangeOnFirstSelection={moveRangeOnFirstSelection}
      onChange={handleRangeChange}
      editableDateInputs
      staticRanges={[
        ...defaultStaticRanges,
        {
          label: <FormattedMessage {...menuItems.lastYear} />,
          range: () => ({
            startDate: moment()
              .subtract(1, 'year')
              .startOf('year')
              .toDate(),
            endDate: moment()
              .subtract(1, 'year')
              .endOf('year')
              .toDate(),
          }),
          isSelected(range) {
            // eslint-disable-next-line react/no-this-in-sfc
            const definedRange = this.range();

            return (
              moment(range.startDate).isSame(definedRange.startDate, 'day') &&
              moment(range.endDate).isSame(definedRange.endDate, 'day')
            );
          },
        },
        {
          label: <FormattedMessage {...menuItems.thisYear} />,
          range: () => ({
            startDate: moment()
              .startOf('year')
              .toDate(),
            endDate: moment()
              .endOf('year')
              .toDate(),
          }),
          isSelected(range) {
            // eslint-disable-next-line react/no-this-in-sfc
            const definedRange = this.range();

            return (
              moment(range.startDate).isSame(definedRange.startDate, 'day') &&
              moment(range.endDate).isSame(definedRange.endDate, 'day')
            );
          },
        },
      ]}
      {...maxDateProps}
      {...minDateProps}
    />
  );
};

export default React.memo(DateRange);
