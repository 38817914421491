// eslint-disable-next-line max-classes-per-file
export interface IWebStorageOptions {
  prefix?: string;
}

const DEFAULT_OPTIONS = {
  prefix: 'app.',
};

abstract class WebStorageService {
  private readonly storage: any;

  private readonly options: IWebStorageOptions;

  protected constructor(storage: Storage, options: IWebStorageOptions) {
    this.storage = storage;
    this.options = { ...Object.assign(DEFAULT_OPTIONS, options) };
  }

  private generateStorageKey = (key: string): string => `${this.options.prefix}${key}`;

  public get(key: string): any {
    const storageKey = this.generateStorageKey(key);
    const value = this.storage.getItem(storageKey);

    try {
      return JSON.parse(value);
    } catch (error) {
      return value;
    }
  }

  public set(key: string, value: any): void {
    const storageKey = this.generateStorageKey(key);
    this.storage.setItem(storageKey, typeof value === 'string' ? value : JSON.stringify(value));
  }

  public remove(key: string) {
    const storageKey = this.generateStorageKey(key);
    this.storage.removeItem(storageKey);
  }

  public clear() {
    this.storage.clear();
  }
}

export class LocalStorageService extends WebStorageService {
  constructor(options: IWebStorageOptions) {
    super(localStorage, options);
  }
}

export class SessionStorageService extends WebStorageService {
  constructor(options: IWebStorageOptions) {
    super(sessionStorage, options);
  }
}
