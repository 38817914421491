import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { List as ImmutableList } from 'immutable';
import { Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { yupResolver } from '@hookform/resolvers/yup';

// state
import {
  selectPersonsFoundList,
  selectPersonsFoundListLoading,
} from 'common/state/newPerson/primaryInfo/selectors';
import {
  resetPersonsFound,
  searchMembers,
  searchReferralMembers,
} from 'common/state/newPerson/primaryInfo/actions';
// interfaces
import {
  ICameFromIndicatorDictionaryItemImt,
  IEmployeeListItemImt,
} from 'common/interfaces/dictionary';
import {
  IImmutablePrimaryMemberInfo,
  IPersonAdditionalFormFields,
  IPrimaryMemberInfo,
} from 'common/components/PersonProfile/interfaces';
import { INamedEntityImt } from 'common/interfaces/common';
import { PeakModules } from 'common/constants/peakModules';
import { IUserProfileInfoImt } from 'modules/authentication/interfaces';
import { PersonSearchParamsType } from 'common/interfaces/http';
// constants
import { PhoneTypes } from 'common/components/PersonProfile/constants';
import { initialAddress } from 'common/constants/initialValues';
import { StepContext } from 'common/createContext/stepContext';
// hooks
import { useAppDispatch } from 'store/hooks';
// ValidationSchema
import { PrimaryInfoStepValidationSchema } from './ValidationSchema/ValidationSchema';
// components
import { ScrollBox } from 'common/components/index';
import GeneralInfoSection from 'modules/crm/components/LeadForm/LeadPrimaryInfoStep/GenerealInfoSection/GeneralInfoSection';
import ContactSection from 'modules/crm/components/LeadForm/LeadPrimaryInfoStep/ContactSection/ContactSection';
import SalesInfoSection from './SalesInfoSection/SalesInfoSection';
import AttachmentsSection from './AttachmentsSection/AttachmentsSection';
import LeadSettingsSection from './LeadSettingsSection/LeadSettingsSection';
import { selectProfileRequiredFields } from 'common/state/settings/selectors';
import { useRecommendedFields } from 'common/hooks/useRecommendedFields';
import { selectIsSubmittedRecommendedFieldsModal } from 'common/state/modals/selectors';
import { showRecommendedFieldsModal } from 'common/state/modals/actions';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1, 2),
    flex: 1,
    height: '100%',
    minHeight: 0,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  formInner: {
    paddingBottom: theme.spacing(2),
  },
}));

interface IProps {
  employees?: ImmutableList<IEmployeeListItemImt>;
  cameFromIndicators?: ImmutableList<ICameFromIndicatorDictionaryItemImt>;
  primaryInfo?: IImmutablePrimaryMemberInfo;
  isActiveMembersRestricted?: boolean;
  hideAutocomplete?: boolean;
  selectedMembers?: any;
  setPrimaryInfo?: (person: IImmutablePrimaryMemberInfo) => void;
  isResponseSuccess?: boolean;
  handleOnSubmit?: (data?) => void;
  hasHiddenFooter?: boolean;
  currentUser?: IUserProfileInfoImt;
  module: PeakModules;
}

const getInitialValues = (salesPerson): IPrimaryMemberInfo => ({
  firstName: '',
  lastName: '',
  birthday: null,
  gender: '',
  barcode: '',
  image: null,
  email: '',
  address: initialAddress,
  attachments: [],
  phones: [
    {
      phoneType: PhoneTypes.Mobile,
      phone: '',
      allowAutomatedCalls: true,
      canReceiveSms: true,
      useAsDefault: true,
    },
  ],
  salesperson: salesPerson
    ? {
        id: salesPerson.get('id'),
        name: `${salesPerson.get('firstName')} ${salesPerson.get('lastName')}`,
      }
    : null,
  cameFromValue: '',
  cameFromIndicatorId: '',
  referralMember: null,
  leadMember: false,
  ptLeadMember: false,
});

const MemberPrimaryInfoStep = ({
  employees,
  cameFromIndicators,
  isActiveMembersRestricted,
  selectedMembers,
  primaryInfo,
  setPrimaryInfo = () => {},
  hideAutocomplete,
  handleOnSubmit,
  isResponseSuccess,
  hasHiddenFooter,
  currentUser,
  module,
}: IProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const initialValues = useMemo(() => getInitialValues(currentUser), [currentUser]);

  const membersAutocompleteList: ImmutableList<IPrimaryMemberInfo | INamedEntityImt> = useSelector(
    selectPersonsFoundList,
  );
  const isSubmitted = useSelector(selectIsSubmittedRecommendedFieldsModal);
  const profileRequiredFields = useSelector(selectProfileRequiredFields);
  const isLoading = useSelector(selectPersonsFoundListLoading);

  const { checkRecommendedFieldsAreNotEmpty, profileRecommendedFields } = useRecommendedFields();

  const { onBack, renderFooter, onNext } = useContext(StepContext);

  const classes = useStyles();

  const formMethods = useForm<IPrimaryMemberInfo & IPersonAdditionalFormFields>({
    defaultValues: initialValues,
    resolver: yupResolver(PrimaryInfoStepValidationSchema(employees, profileRequiredFields)),
    mode: 'onBlur',
  });

  const { handleSubmit, reset, getValues } = formMethods;

  useEffect(() => {
    if (
      primaryInfo?.get('firstName') ||
      primaryInfo?.get('lastName') ||
      primaryInfo?.get('phones')?.size
    ) {
      const convertedPrimaryInfo = primaryInfo.toJS();
      const { referralMember, cameFromIndicator, phones, salesperson } = convertedPrimaryInfo;

      reset({
        ...initialValues,
        ...convertedPrimaryInfo,
        referralMember,
        phones: phones.length
          ? phones
          : [
              {
                phoneType: PhoneTypes.Mobile,
                phone: '',
                allowAutomatedCalls: true,
                canReceiveSms: true,
                useAsDefault: true,
              },
            ],
        cameFromIndicatorId: cameFromIndicator?.id,
        cameFromValue: convertedPrimaryInfo.cameFromValue || '',
        cameFromType: cameFromIndicator?.type,
        optional: cameFromIndicator?.optional,
        attachments: convertedPrimaryInfo?.attachments || [],
        ...(salesperson && {
          salesperson: {
            id: salesperson.id,
            name: `${salesperson.firstName} ${salesperson.lastName}`,
          },
        }),
      });
    } else {
      const convertedPrimaryInfo = primaryInfo?.toJS();
      const leadMember = module === PeakModules.FrontDesk || convertedPrimaryInfo.leadMember;
      const ptLeadMember = module === PeakModules.FrontDesk || convertedPrimaryInfo.ptLeadMember;

      reset({
        ...initialValues,
        ...convertedPrimaryInfo,
        leadMember,
        ptLeadMember,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryInfo, reset]);

  useEffect(() => {
    if (isResponseSuccess) {
      onNext();
    }
  }, [isResponseSuccess, onNext]);

  useEffect(() => {
    if (isSubmitted) {
      const { optional, cameFromType, referralMember, salesperson, ...data } = getValues();

      (handleOnSubmit || onNext)({
        ...data,
        referralMemberId: referralMember?.id,
        assignedSalespersonId: salesperson?.id,
      });

      dispatch(showRecommendedFieldsModal(false));
    }
  }, [isSubmitted, getValues, handleOnSubmit, onNext, dispatch]);

  const getOptionsByValue = useCallback(
    (searchString: string, customerSearchSortBy: PersonSearchParamsType) => {
      dispatch(searchMembers(searchString, customerSearchSortBy, module));
    },
    [dispatch, module],
  );

  const getReferralMembersByValue = useCallback(
    (searchString: string) => {
      dispatch(searchReferralMembers(searchString, module));
    },
    [dispatch, module],
  );

  const clearSearchResults = useCallback(() => {
    dispatch(resetPersonsFound());
  }, [dispatch]);

  const onSubmit = (values: IPrimaryMemberInfo & IPersonAdditionalFormFields) => {
    const hasEmptyRecommendedFields = checkRecommendedFieldsAreNotEmpty<
      IPrimaryMemberInfo & IPersonAdditionalFormFields
    >(values);

    if (hasEmptyRecommendedFields) {
      dispatch(showRecommendedFieldsModal(true));
    } else {
      const { optional, cameFromType, referralMember, salesperson, ...data } = values;
      (handleOnSubmit || onNext)({
        ...data,
        referralMemberId: referralMember?.id,
        assignedSalespersonId: salesperson?.id,
      });
    }
  };

  return (
    <FormProvider {...formMethods}>
      <ScrollBox hasShadowsOnScroll>
        <form
          id="primary-info-form"
          onSubmit={handleSubmit(onSubmit)}
          className={classes.root}
          autoComplete="none"
        >
          <Grid className={classes.formInner} container spacing={4}>
            <Grid item xs={12}>
              <GeneralInfoSection<ImmutableList<IPrimaryMemberInfo>>
                clearSearchResults={clearSearchResults}
                isPersonSearchLoading={isLoading}
                persons={membersAutocompleteList as ImmutableList<IPrimaryMemberInfo>}
                getOptionsByValue={getOptionsByValue}
                setPrimaryInfo={setPrimaryInfo}
                isActiveMembersRestricted={isActiveMembersRestricted}
                selectedMembers={selectedMembers}
                hideAutocomplete={hideAutocomplete}
                recommendedFields={profileRecommendedFields}
              />
            </Grid>

            <Grid item xs={12}>
              <ContactSection<ImmutableList<IPrimaryMemberInfo>>
                clearSearchResults={clearSearchResults}
                isPersonSearchLoading={isLoading}
                persons={membersAutocompleteList as ImmutableList<IPrimaryMemberInfo>}
                getOptionsByValue={getOptionsByValue}
                setPrimaryInfo={setPrimaryInfo}
                isActiveMembersRestricted={isActiveMembersRestricted}
                selectedMembers={selectedMembers}
                hideAutocomplete={hideAutocomplete}
                recommendedFields={profileRecommendedFields}
              />
            </Grid>

            <Grid item xs={12}>
              <SalesInfoSection
                primaryInfo={primaryInfo}
                isPersonSearchLoading={isLoading}
                persons={membersAutocompleteList as ImmutableList<INamedEntityImt>}
                getReferralMembersByValue={getReferralMembersByValue}
                clearSearchResults={clearSearchResults}
                cameFromIndicators={cameFromIndicators}
                employees={employees}
                recommendedFields={profileRecommendedFields}
              />
            </Grid>

            <Grid item xs={12}>
              <LeadSettingsSection />
            </Grid>

            <Grid item xs={12}>
              <AttachmentsSection />
            </Grid>
          </Grid>
        </form>
      </ScrollBox>

      {!hasHiddenFooter && !!renderFooter && renderFooter(onBack, handleSubmit(onSubmit))}
    </FormProvider>
  );
};

export default MemberPrimaryInfoStep;
