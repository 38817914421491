import React, { useEffect, useRef, useState } from 'react';
import { Box, Link, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import cn from 'classnames';

import { getQueueColor } from 'common/utils';
import { ReactComponent as ZoomMinusIcon } from 'img/icons/zoom-minus.svg';
import { ReactComponent as ZoomPlusIcon } from 'img/icons/zoom-plus.svg';
import { CustomTheme } from 'common/ui/interfaces';
import { TooltipTypography } from 'common/components/index';
import { IBarChartData } from 'common/interfaces/barChart';

interface IBarChartProps {
  data: IBarChartData[];
  className?: string;
  title: JSX.Element | string;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  barChartContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    border: `1px solid ${theme.palette.borderColor.main}`,
    height: 104,
    width: '100%',
    padding: theme.spacing(2),
  },
  barContainer: {
    height: 32,
    width: '100%',
    backgroundColor: theme.palette.borderColor.main,
    overflowX: 'scroll',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
  bar: {
    height: '100%',
    display: 'flex',
  },
  title: {
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
  },
  zoomInBtn: {
    marginRight: theme.spacing(1.5),
  },
}));

const STEP = 150;
const WHEEL_STEP = 10;
const DEFAULT_SIZE = 100;

const BarChart = (props: IBarChartProps): JSX.Element => {
  const { data, className, title } = props;

  const isPressedCtrlBtn = useRef(false);
  const ref = useRef<HTMLDivElement>(null);

  const [barSize, setBarSize] = useState(DEFAULT_SIZE);

  const theme = useTheme();

  const classes = useStyles();

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Control') {
        isPressedCtrlBtn.current = true;
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === 'Control') {
        isPressedCtrlBtn.current = false;
      }
    };

    const handleOnWheel = (event: WheelEvent) => {
      if (isPressedCtrlBtn.current) {
        event.preventDefault();
        if (event.deltaY < 0) {
          setBarSize(size => size + WHEEL_STEP);
        } else {
          setBarSize(size => {
            const currentSize = size - WHEEL_STEP;
            if (currentSize < DEFAULT_SIZE) {
              return DEFAULT_SIZE;
            }
            return currentSize;
          });
        }
      }
    };

    const el = ref.current;

    el.addEventListener('wheel', handleOnWheel, { passive: false });
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      el.removeEventListener('wheel', handleOnWheel);
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const handleZoomIn = () => {
    setBarSize(size => size + STEP);
  };

  const handleZoomOut = () => {
    if (barSize > DEFAULT_SIZE) {
      setBarSize(size => {
        const currentSize = size - STEP;
        if (currentSize < DEFAULT_SIZE) {
          return DEFAULT_SIZE;
        }
        return currentSize;
      });
    }
  };

  return (
    <Box className={cn(classes.barChartContainer, className)}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
        <Box display="flex">
          <Link
            className={classes.zoomInBtn}
            color="secondary"
            component="button"
            onClick={handleZoomIn}
            underline="none"
          >
            <ZoomPlusIcon />
          </Link>
          <Link
            color="secondary"
            component="button"
            onClick={handleZoomOut}
            underline="none"
            disabled={barSize === DEFAULT_SIZE}
          >
            <ZoomMinusIcon />
          </Link>
        </Box>
      </Box>

      <Box {...{ ref }} className={classes.barContainer}>
        <Box className={classes.bar} width={`${barSize}%`}>
          {data.map((item, index) => {
            const bgColor = getQueueColor(index);
            const { percentage, title: text } = item;
            const values = percentage.toString().split('.');
            const decimalValue = values[1];
            const condition = decimalValue?.length > 2;
            const percentageTitle = condition
              ? `${values[0]}.${decimalValue.slice(0, 2)}`
              : percentage;
            return (
              <Box
                height="100%"
                bgcolor={bgColor}
                width={`${percentage}%`}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <TooltipTypography
                  style={{
                    paddingLeft: percentage ? 2 : 0,
                    paddingRight: percentage ? 2 : 0,
                    color: theme.palette.getContrastText(bgColor),
                  }}
                  ellipsized
                  variant="h6"
                >
                  {`${text} ${percentageTitle}%`}
                </TooltipTypography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default BarChart;
