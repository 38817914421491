import { createAction } from '@reduxjs/toolkit';
import { batch } from 'react-redux';

import Services from 'services/network';
import {
  CartUnitTypes,
  IAddInvoiceUnitDTO,
  IBalance,
  IFetchReceiptPayload,
  IInvoiceDetailsDto,
  IInvoicePaymentSplit,
  INotifications,
  InvoiceStatus,
  IVoidInvoiceRequestPayload,
  PaymentAccountType,
  PaymentsType,
} from 'common/interfaces/invoices';
import { IPaymentMethodItem } from 'modules/pos-settings/interfaces/paymentMethods';
import { IPaymentAccount } from 'common/components/PersonProfile/interfaces';
import { IAddCreditCard } from 'common/components/CreditCardData/interfaces';
import { ActionResult } from 'common/constants';
import { printHTML, silentPrintHTML } from 'common/state/printHTML/actions';

import { actionTypes } from './constants';
import { GeneralThunkAction, ImmutableObject } from 'common/state/interfaces';
import { enqueueErrorNotification } from 'common/state/notifications/actions';
import { IVoidInvoiceSuccessResponse } from 'common/state/invoice/actions';
import { fetchProfileInfoView } from '../profile/actions';
import { fetchPersonDocuments } from '../documents/actions';
import { fetchRecentPersonPayments } from '../payments/actions';

// Add invoice unit

const addInvoiceUnitAction = createAction<IInvoiceDetailsDto>(actionTypes.ADD_INVOICE_UNIT);
const addInvoiceUnitLoadingAction = createAction<boolean>(actionTypes.ADD_INVOICE_UNIT_LOADING);

export const addInvoiceUnit = (
  invoiceId: string,
  data: Partial<IAddInvoiceUnitDTO>,
  unitType: CartUnitTypes,
  callback?: () => void,
): GeneralThunkAction => async dispatch => {
  dispatch(addInvoiceUnitLoadingAction(true));

  try {
    const addInvoiceUnitResult = await Services.MemberPortalProfile.addInvoiceUnit(
      invoiceId,
      data,
      unitType,
    );

    dispatch(addInvoiceUnitAction(addInvoiceUnitResult));
    dispatch(fetchInvoiceUnitAmount(addInvoiceUnitResult.invoiceUnits.length));

    if (callback) {
      callback();
    }
  } catch (error) {
    dispatch(enqueueErrorNotification(error));
  } finally {
    dispatch(addInvoiceUnitLoadingAction(false));
  }
};

const deleteInvoiceUnitAction = createAction<IInvoiceDetailsDto>(actionTypes.ADD_INVOICE_UNIT);
const deleteInvoiceUnitLoadingAction = createAction<boolean>(actionTypes.ADD_INVOICE_UNIT_LOADING);

export const deleteInvoiceUnit = (
  invoiceId: string,
  invoiceUnitId: string,
): GeneralThunkAction => async dispatch => {
  dispatch(deleteInvoiceUnitLoadingAction(true));

  try {
    const deleteInvoiceUnitResult = await Services.MemberPortalProfile.deleteInvoiceUnit(
      invoiceId,
      invoiceUnitId,
    );

    dispatch(deleteInvoiceUnitAction(deleteInvoiceUnitResult));
    dispatch(fetchInvoiceUnitAmount(deleteInvoiceUnitResult.invoiceUnits.length));
  } catch (error) {
    dispatch(enqueueErrorNotification(error));
  } finally {
    dispatch(deleteInvoiceUnitLoadingAction(false));
  }
};

// const changeInvoiceUnitNumberAction = createAction<IInvoiceDetailsDto>(
//   actionTypes.CHANGE_INVOICE_UNIT_NUMBER,
// );
const changeInvoiceUnitNumberLoadingAction = createAction<boolean>(
  actionTypes.CHANGE_INVOICE_UNIT_NUMBER_LOADING,
);

export const changeInvoiceUnitNumber = (): // invoiceId: string,
// invoiceUnitId: string,
// unitNumber: number,
GeneralThunkAction => async dispatch => {
  dispatch(changeInvoiceUnitNumberLoadingAction(true));

  try {
    // const changeInvoiceUnitNumberResult = await Services.MemberPortalProfile.changeInvoiceUnitNumber(
    //   invoiceId,
    //   invoiceUnitId,
    //   unitNumber,
    // );
    // dispatch(changeInvoiceUnitNumberAction(changeInvoiceUnitNumberResult));
  } catch (error) {
    dispatch(enqueueErrorNotification(error));
  } finally {
    dispatch(changeInvoiceUnitNumberLoadingAction(false));
  }
};

const updateInvoiceLoadingAction = createAction<boolean>(actionTypes.UPDATE_INVOICE_LOADING);

export type IVoidInvoiceSuccessResponseImt = ImmutableObject<IVoidInvoiceSuccessResponse>;
const cancelInvoiceAction = createAction<IVoidInvoiceSuccessResponse>(actionTypes.CANCEL_INVOICE);
const cancelInvoiceLoadingAction = createAction<boolean>(actionTypes.CANCEL_INVOICE_LOADING);
export const resetCancelInvoice = createAction(actionTypes.RESET_CANCEL_INVOICE);

export const cancelInvoice = (
  invoiceId: string,
  profileId: string,
  shouldGoPrevStep?: boolean,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(cancelInvoiceLoadingAction(true));

    try {
      await Services.MemberPortalProfile.cancelMemberInvoice(invoiceId);

      dispatch(
        cancelInvoiceAction({
          success: true,
          invoiceId,
          shouldGoBack: shouldGoPrevStep,
        }),
      );

      dispatch(fetchInvoiceUnitAmount(0));
      dispatch(fetchRecentPersonPayments());
      dispatch(resetMemberInvoice());
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(cancelInvoiceLoadingAction(false));
    }
  };
};

export const voidInvoice = (payload: IVoidInvoiceRequestPayload): GeneralThunkAction => {
  return async dispatch => {
    dispatch(cancelInvoiceLoadingAction(true));

    try {
      const { invoiceId, shouldGoPrevStep, reason } = payload;

      await Services.MemberPortalProfile.voidInvoice(invoiceId, reason);

      batch(() => {
        dispatch(
          cancelInvoiceAction({
            success: true,
            invoiceId,
            shouldGoBack: shouldGoPrevStep,
          }),
        );
        dispatch(fetchInvoiceUnitAmount(0));
        dispatch(fetchRecentPersonPayments());
        dispatch(resetMemberInvoice());
      });
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(cancelInvoiceLoadingAction(false));
    }
  };
};

export const sendNotifications = (invoiceId: string, data: INotifications): GeneralThunkAction => {
  return async dispatch => {
    dispatch(updateInvoiceLoadingAction(true));

    try {
      await Services.MemberPortalProfile.sendNotifications(invoiceId, data);

      dispatch(resetCheckoutResultData());
      dispatch(resetMemberInvoice());
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(updateInvoiceLoadingAction(false));
    }
  };
};

//  Payments

const fetchAvailablePaymentMethodsAction = createAction<IPaymentMethodItem[]>(
  actionTypes.FETCH_AVAILABLE_PAYMENT_METHODS_RESULT,
);
const fetchAvailablePaymentMethodsLoadingAction = createAction<boolean>(
  actionTypes.FETCH_AVAILABLE_PAYMENT_METHODS_LOADING,
);
export const resetAvailablePaymentMethodsList = createAction(
  actionTypes.RESET_AVAILABLE_PAYMENT_METHODS,
);
export const fetchAvailablePaymentMethods = (memberId: string): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchAvailablePaymentMethodsLoadingAction(true));

    try {
      const result = await Services.MemberPortalProfile.fetchAvailablePaymentMethods(memberId);

      dispatch(fetchAvailablePaymentMethodsAction(result));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchAvailablePaymentMethodsLoadingAction(false));
    }
  };
};

const fetchReceiptLoadingAction = createAction<boolean>(actionTypes.FETCH_RECEIPT_LOADING);
export const fetchReceipt = (payload: IFetchReceiptPayload): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchReceiptLoadingAction(true));

    const { invoiceId, isSilentPrint = false } = payload;

    try {
      const result = await Services.MemberPortalProfile.getInvoiceReceipt(invoiceId);

      if (isSilentPrint) {
        dispatch(silentPrintHTML(result));
      } else {
        dispatch(printHTML(result));
      }
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchReceiptLoadingAction(false));
    }
  };
};

// // checkout result receipt data

const setCheckoutResultData = createAction<IInvoiceDetailsDto>(actionTypes.SET_CHECKOUT_RESULT);
export const resetCheckoutResultData = createAction(actionTypes.RESET_CHECKOUT_RESULT);

// invoice sync

const updateInvoiceWithSyncLoadingAction = createAction<boolean>(
  actionTypes.UPDATE_INVOICE_WITH_SYNC_LOADING,
);
const updateInvoiceWithSyncResultAction = createAction<IInvoiceDetailsDto>(
  actionTypes.UPDATE_INVOICE_WITH_SYNC_RESULT,
);
export const updateInvoiceWithSyncErrorAction = createAction<any>(
  actionTypes.UPDATE_INVOICE_WITH_SYNC_ERROR,
);
export const resetUpdateInvoiceWithSync = createAction(actionTypes.RESET_UPDATE_INVOICE_WITH_SYNC);

export const updateInvoiceWithSync = (
  invoiceData: IInvoiceDetailsDto,
  invoicePaymentSplit: IInvoicePaymentSplit,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(updateInvoiceWithSyncLoadingAction(true));

    try {
      const result = await Services.MemberPortalProfile.addInvoicePaymentSplit(
        invoiceData.id,
        invoicePaymentSplit,
      );

      const isUpdatedStatusToInProgress =
        invoiceData.status === InvoiceStatus.OPEN && result.status === InvoiceStatus.IN_PROGRESS;
      const isUpdatedStatusToPaid =
        result.status === InvoiceStatus.PAID &&
        (invoiceData.status === InvoiceStatus.IN_PROGRESS ||
          invoiceData.status === InvoiceStatus.OPEN);

      if (isUpdatedStatusToInProgress || isUpdatedStatusToPaid) {
        dispatch(fetchMemberInvoiceThunk());
      }

      if (result.status === InvoiceStatus.PAID) {
        batch(() => {
          dispatch(updateInvoiceWithSyncResultAction(result));
          dispatch(setCheckoutResultData(result));
        });
      } else {
        dispatch(updateInvoiceWithSyncResultAction(result));
      }

      // delete member alert
      // if (result.status === InvoiceStatus.PAID && module === PeakModules.FrontDesk && isCustomer) {
      //   const { id } = invoiceData.customer;

      //   const alerts = await Services.MemberPortalProfile.getMemberAlerts();

      //   const alert: IMemberAlert = alerts.find(
      //     ({ condition }) =>
      //       condition === AlertCondition.InactiveMember ||
      //       condition === AlertCondition.MemberWillExpireSoon ||
      //       condition === AlertCondition.MemberIsFrozen,
      //   );

      //   if (alert) {
      //     dispatch(updatePersonMembershipPackageAction(alert.condition, id));
      //   }
      // }
    } catch (error) {
      dispatch(updateInvoiceWithSyncErrorAction(error));
    } finally {
      dispatch(updateInvoiceWithSyncLoadingAction(false));
    }
  };
};

export const proceedFreeInvoice = (
  invoiceId: string,
  profileId?: string,
): GeneralThunkAction => async dispatch => {
  dispatch(updateInvoiceWithSyncLoadingAction(true));

  try {
    const result = await Services.MemberPortalProfile.proceedFreeInvoice(invoiceId);

    batch(() => {
      dispatch(updateInvoiceWithSyncResultAction(result));
      dispatch(setCheckoutResultData(result));
    });

    if (profileId) {
      dispatch(fetchProfileInfoView());
      dispatch(fetchPersonDocuments());
    }

    // delete member alert
    // if (module === PeakModules.FrontDesk && isCustomer) {
    //   const { id } = result.customer;

    //   const alerts = await Services.MemberPortalProfile.getMemberAlerts();

    //   const alert: IMemberAlert = alerts.find(
    //     ({ condition }) =>
    //       condition === AlertCondition.InactiveMember ||
    //       condition === AlertCondition.MemberWillExpireSoon ||
    //       condition === AlertCondition.MemberIsFrozen,
    //   );

    //   if (alert) {
    //     dispatch(updatePersonMembershipPackageAction(alert.condition, id));
    //   }
    // }
  } catch (error) {
    dispatch(enqueueErrorNotification(error));
  } finally {
    dispatch(updateInvoiceWithSyncLoadingAction(false));
  }
};

const fetchStoredCreditCardsLoadingAction = createAction<any>(
  actionTypes.FETCH_STORED_CREDIT_CARDS_LOADING,
);
const fetchStoredCreditCardsResultAction = createAction<IPaymentAccount[]>(
  actionTypes.FETCH_STORED_CREDIT_CARDS_RESULT,
);
export const fetchStoredCreditCards = (
  cardType: PaymentsType,
): GeneralThunkAction => async dispatch => {
  try {
    dispatch(fetchStoredCreditCardsLoadingAction(true));

    const fetchCreditCardsResult = await Services.MemberPortalProfile.fetchPaymentAccounts(
      PaymentAccountType.CREDIT_CARD,
      cardType,
    );

    dispatch(fetchStoredCreditCardsResultAction(fetchCreditCardsResult));
  } catch (error) {
    dispatch(enqueueErrorNotification(error));
  } finally {
    dispatch(fetchStoredCreditCardsLoadingAction(false));
  }
};

export const resetStoredCreditCardsReducer = createAction(
  actionTypes.RESET_STORED_CREDIT_CARDS_REDUCER,
);
const storeCreditCardLoadingAction = createAction<boolean>(actionTypes.STORE_CREDIT_CARD_LOADING);
const storeCreditCardResultAction = createAction<ActionResult>(
  actionTypes.STORE_CREDIT_CARD_RESULT,
);
export const resetStoredCreditCardResult = createAction(
  actionTypes.RESET_STORED_CREDIT_CARD_RESULT,
);

export const storeCreditCard = (data: IAddCreditCard): GeneralThunkAction => async dispatch => {
  try {
    dispatch(storeCreditCardLoadingAction(true));
    dispatch(storeCreditCardResultAction(null));
    await Services.MemberPortalProfile.addCreditCardPaymentAccount(data);
    dispatch(storeCreditCardResultAction(ActionResult.SUCCESS_ACTION));
  } catch (error) {
    dispatch(enqueueErrorNotification(error));
  } finally {
    dispatch(storeCreditCardLoadingAction(false));
  }
};

// Checking
const fetchInvoiceCheckingSavingsData = createAction<IPaymentAccount[]>(
  actionTypes.FETCH_CHECKING_SAVINGS_DATA,
);
const fetchInvoiceCheckingSavingsDataLoading = createAction<boolean>(
  actionTypes.FETCH_CHECKING_SAVINGS_DATA_LOADING,
);
export const resetInvoiceCheckingSavingsData = createAction(
  actionTypes.RESET_CHECKING_SAVINGS_DATA,
);

export const fetchInvoiceCheckingSavingsDataThunk = (): GeneralThunkAction => async dispatch => {
  try {
    dispatch(fetchInvoiceCheckingSavingsDataLoading(true));

    const response = await Services.MemberPortalProfile.fetchPaymentAccounts(
      PaymentAccountType.CREDIT_CARD,
    );

    dispatch(fetchInvoiceCheckingSavingsData(response));
  } catch (error) {
    dispatch(enqueueErrorNotification(error));
  } finally {
    dispatch(fetchInvoiceCheckingSavingsDataLoading(false));
  }
};

const addInvoiceCheckingSavingsData = createAction<IPaymentAccount>(
  actionTypes.ADD_CHECKING_SAVINGS_DATA,
);
const addInvoiceCheckingSavingsDataLoading = createAction<boolean>(
  actionTypes.ADD_CHECKING_SAVINGS_DATA_LOADING,
);
export const addInvoiceCheckingSavingsDataActionResult = createAction<ActionResult | null>(
  actionTypes.ADD_CHECKING_SAVINGS_DATA_ACTION_RESULT,
);

export const addInvoiceCheckingSavingsDataThunk = (
  personId: string,
  formData: Omit<IPaymentAccount, 'id'>,
): GeneralThunkAction => async dispatch => {
  try {
    dispatch(addInvoiceCheckingSavingsDataLoading(true));

    const response = await Services.MemberPortalProfile.addCheckingPaymentAccount(formData);

    if (response) {
      dispatch(addInvoiceCheckingSavingsData(response));
      dispatch(addInvoiceCheckingSavingsDataActionResult(ActionResult.SUCCESS_ACTION));
    }
  } catch (error) {
    dispatch(enqueueErrorNotification(error));
  } finally {
    dispatch(addInvoiceCheckingSavingsDataLoading(false));
  }
};

const fetchCustomerBalanceLoading = createAction<boolean>(
  actionTypes.FETCH_BALANCE_BY_CUSTOMER_ID_LOADING,
);
const fetchCustomerBalance = createAction<IBalance>(actionTypes.FETCH_BALANCE_BY_CUSTOMER_ID);
export const resetCustomerBalance = createAction(actionTypes.RESET_BALANCE);

export const fetchCustomerBalanceThunk = (
  customerId: string,
): GeneralThunkAction => async dispatch => {
  try {
    dispatch(fetchCustomerBalanceLoading(true));

    const response = await Services.MemberPortalProfile.fetchBalanceById(customerId);

    dispatch(fetchCustomerBalance(response));
  } catch (error) {
    dispatch(enqueueErrorNotification(error));
  } finally {
    dispatch(fetchCustomerBalanceLoading(false));
  }
};

const fetchMemberInvoiceLoading = createAction<boolean>(actionTypes.FETCH_MEMBER_INVOICE_LOADING);
const fetchMemberInvoice = createAction<IInvoiceDetailsDto>(actionTypes.FETCH_MEMBER_INVOICE);
export const resetMemberInvoice = createAction<IInvoiceDetailsDto>(
  actionTypes.RESET_MEMBER_INVOICE,
);

export const fetchMemberInvoiceThunk = (): GeneralThunkAction => async dispatch => {
  try {
    dispatch(fetchMemberInvoiceLoading(true));
    const response = await Services.MemberPortalProfile.getMemberInvoice();
    dispatch(fetchMemberInvoice(response));

    dispatch(fetchInvoiceUnitAmount(response.invoiceUnits.length));
  } catch (error) {
    dispatch(enqueueErrorNotification(error));
  } finally {
    dispatch(fetchMemberInvoiceLoading(false));
  }
};

const fetchInvoiceUnitAmount = createAction<number>(actionTypes.FETCH_NVOICE_UNIT_COUNT);

export const fetchInvoiceUnitAmountThunk = (): GeneralThunkAction => async dispatch => {
  try {
    const response = await Services.MemberPortalProfile.getInvoiceUnitCount();
    dispatch(fetchInvoiceUnitAmount(response));
  } catch (error) {
    dispatch(enqueueErrorNotification(error));
  }
};
