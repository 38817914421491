import React from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Grid, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

interface IProps {
  id: string;
  firstName: string;
  lastName: string;
  photoUrl?: string;
  small?: boolean;
}

const StyledAvatar = styled(Avatar)({
  width: 16,
  height: 16,
});

const StyledLink = styled(Link)({
  '&:hover': {
    textDecoration: 'underline',
  },
});

const SalespersonName = ({ id, firstName, lastName, photoUrl, small }: IProps) => {
  const salespersonPageUrl = `/crm/leads/${id}`;

  const fullName = small ? `${firstName[0]}.${lastName}` : `${firstName} ${lastName}`;

  const color = small ? 'textSecondary' : 'initial';
  const variant = small ? 'body2' : 'body1';

  return (
    <Grid container wrap="nowrap" alignItems="center" spacing={1}>
      {small && (
        <Grid item>
          <StyledAvatar alt={firstName} src={photoUrl} variant="circle" />
        </Grid>
      )}

      <Grid item>
        <Typography variant={variant} color={color}>
          {fullName}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default React.memo(SalespersonName);
