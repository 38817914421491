import { defineMessages } from 'react-intl';

export default defineMessages({
  club: {
    id: 'app.common.tableFilters.club',
    defaultMessage: 'Club',
  },
  status: {
    id: 'app.common.tableFilters.status',
    defaultMessage: 'Status',
  },
  inUse: {
    id: 'app.common.tableFilters.inUse',
    defaultMessage: 'In Use',
  },
  department: {
    id: 'app.common.tableFilters.department',
    defaultMessage: 'Department',
  },
  payType: {
    id: 'app.common.tableFilters.payType',
    defaultMessage: 'Pay Type',
  },
  revenueCode: {
    id: 'app.common.tableFilters.revenueCode',
    defaultMessage: 'Revenue Code',
  },
  type: {
    id: 'app.common.tableFilters.type',
    defaultMessage: 'Type',
  },
  redeemType: {
    id: 'app.common.tableFilters.redeemType',
    defaultMessage: 'Redeem Type',
  },
  channel: {
    id: 'app.common.tableFilters.channel',
    defaultMessage: 'Channel',
  },
  created: {
    id: 'app.common.tableFilters.created',
    defaultMessage: 'Created',
  },
  crmStatus: {
    id: 'app.common.tableFilters.crmStatus',
    defaultMessage: 'CRM Status',
  },
  ptCrmStatus: {
    id: 'app.common.tableFilters.ptCrmStatus',
    defaultMessage: 'PT CRM Status',
  },
  membershipStatus: {
    id: 'app.common.tableFilters.membershipStatus',
    defaultMessage: 'Membership status',
  },
  period: {
    id: 'app.common.tableFilters.period',
    defaultMessage: 'Period',
  },
  campaign: {
    id: 'app.common.tableFilters.campaign',
    defaultMessage: 'Campaign',
  },
  salesperson: {
    id: 'app.common.tableFilters.salesperson',
    defaultMessage: 'Salesperson',
  },
  cameFrom: {
    id: 'app.common.tableFilters.cameFrom',
    defaultMessage: 'Came From',
  },
  employee: {
    id: 'app.common.tableFilters.employee',
    defaultMessage: 'Employee',
  },
  resourceTag: {
    id: 'app.common.tableFilters.resourceTag',
    defaultMessage: 'Resource tag',
  },
  eventTag: {
    id: 'app.common.tableFilters.eventTag',
    defaultMessage: 'Event tag',
  },
  paymentStatus: {
    id: 'app.common.tableFilters.paymentStatus',
    defaultMessage: 'Payment status',
  },
});
