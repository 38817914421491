// Libraries
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { createStyles, StyledComponentProps, Typography, withStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import Dropzone, { DropzoneRef, FileRejection } from 'react-dropzone';
import { FormattedMessage } from 'react-intl';
// Custom components
import { AllowedTo, Button, DialogComponent, ImageCropper } from 'common/components';
// Messages
import messages from './messages';
import commonMessages from 'common/messages/messages';
// Selectors
import {
  selectUploadedImage,
  selectUploadedImageDialogId,
  selectUploadImageError,
  selectUploadImageLoading,
} from 'common/state/uploadImage/selectors';
// hooks
import { useAppDispatch } from 'store/hooks';
// Interfaces
import { ICropResult, IAttachmentImt, ImageFormatType } from 'common/interfaces/uploadFile';

import { generateFileName } from 'common/utils';
import useComponentDidUpdate from 'common/hooks/useComponentDidUpdate';
import { resetUploadImage, uploadImage } from 'common/state/uploadImage/actions';
import { MAX_FILE_SIZE } from 'common/constants';

interface IUploadImageModalProps extends StyledComponentProps {
  // Dialog id is required to identify, which dialog component is currently active and uploading file
  id: string;

  isOpen: boolean;
  src?: string;

  onSubmit: (uploadedImageUrl: IAttachmentImt) => void;
  onToggle: () => void;
  isPublicImage?: boolean;
  aspectRatio?: number;
}

interface IUploadImageModalState {
  file?: File;
  fileUrl?: string;

  hasDropError?: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    uploadButton: {
      width: '100%',
      '&:hover': {
        backgroundColor: `${theme.palette.info.main}!important`,
      },
    },
    error: {
      color: theme.palette.error.main,
      fontSize: '0.7rem',
    },
    imageUploadField: {
      width: '100%',
    },
  });

const UploadImageModal = (props: IUploadImageModalProps) => {
  const { id, onSubmit, onToggle, src, isOpen, classes, isPublicImage, aspectRatio } = props;

  const dispatch = useAppDispatch();

  const uploadedDialogId = useSelector(selectUploadedImageDialogId());
  const uploadedImage = useSelector(selectUploadedImage());
  const requestError = useSelector(selectUploadImageError());
  const isLoading = useSelector(selectUploadImageLoading());

  const initialState = {
    file: null,
    fileUrl: null,
    hasDropError: false,
  };

  const [{ file, fileUrl, hasDropError }, setUploadImageState] = useState<IUploadImageModalState>(
    initialState,
  );

  const dropzoneRef = useRef<DropzoneRef>();
  const croppedImageRef = useRef<ICropResult>(null);

  const resetModal = () => {
    croppedImageRef.current = null;
    setUploadImageState({ ...initialState });
    dispatch(resetUploadImage());
  };

  useComponentDidUpdate(() => {
    // TODO: refactor all this component, if we will definitely send file instead of URL.
    if (uploadedImage && !requestError && uploadedDialogId === id) {
      resetModal();
      onSubmit(uploadedImage);
    }
  }, [uploadedImage, requestError, uploadedDialogId]);

  const onToggleImageModal = () => {
    resetModal();
    onToggle();
  };

  const onUploadImage = () => {
    let uploadingFile: File;

    if (file) {
      uploadingFile = file;
    }

    if (croppedImageRef.current) {
      const { blob } = croppedImageRef.current;

      const fileName = generateFileName(blob.type, file);

      uploadingFile = new File([blob], fileName);
    }
    dispatch(uploadImage(id, uploadingFile, isPublicImage));
  };

  const getDropzoneClass = (isDragAccept, isDragReject): string => {
    if (isDragReject) return 'reject';
    if (isDragAccept) return 'accept';
    return '';
  };

  const onOpenDownloadWindow = (): void => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  const onDropAccepted = (files): void => {
    setUploadImageState({
      file: files[0],
      fileUrl: URL.createObjectURL(files[0]),
      hasDropError: false,
    });
  };

  const onDropRejected = (fileRejections: FileRejection[]): void => {
    const errors: string = fileRejections
      .map(fileRejection => fileRejection.errors.map(error => error.message).join(', '))
      .join(' ');

    console.error('File rejected with the reason: ', errors);

    setUploadImageState(state => ({ ...state, hasDropError: true }));
  };

  const onImageCropped = (croppedImage): void => {
    croppedImageRef.current = croppedImage;
  };

  const imageUrl: string = fileUrl || src;

  return (
    <DialogComponent
      isOpen={isOpen}
      title={<FormattedMessage {...messages.modalUploadImageTitle} />}
      disabled={!imageUrl || hasDropError || requestError}
      loading={isLoading}
      onSubmit={onUploadImage}
      onClose={onToggleImageModal}
    >
      <Dropzone
        ref={dropzoneRef}
        noClick
        multiple={false}
        maxSize={MAX_FILE_SIZE}
        accept={{
          [ImageFormatType.PNG]: ['.png'],
          [ImageFormatType.JPEG]: ['.jpeg'],
        }}
        onDropAccepted={onDropAccepted}
        onDropRejected={onDropRejected}
      >
        {({ getRootProps, getInputProps, isDragAccept, isDragReject }) => {
          return (
            <section>
              <input {...getInputProps()} />
              <div {...getRootProps()}>
                {imageUrl ? (
                  <div {...getRootProps()}>
                    <ImageCropper
                      src={imageUrl}
                      onCrop={onImageCropped}
                      aspectRatio={aspectRatio}
                    />
                  </div>
                ) : (
                  <div
                    {...getRootProps()}
                    className={`dropzone ${getDropzoneClass(isDragAccept, isDragReject)}`}
                  >
                    <span>
                      <FormattedMessage {...commonMessages.uploadFieldBody} />
                    </span>
                  </div>
                )}
              </div>
            </section>
          );
        }}
      </Dropzone>
      <Button
        color="info"
        variant="contained"
        className={classes.uploadButton}
        disabled={isLoading}
        onClick={onOpenDownloadWindow}
      >
        <FormattedMessage {...messages.modalUploadImageUploadBtn} />
      </Button>
      <AllowedTo perform={hasDropError}>
        <Typography component="span" className={classes.error}>
          <FormattedMessage {...messages.modalUploadImageDropzoneErrorMessage} />
        </Typography>
      </AllowedTo>
    </DialogComponent>
  );
};

export default withStyles(styles)(UploadImageModal);
