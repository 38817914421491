import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Typography } from '@material-ui/core';
import messages from '../messages';

interface ISignStatusProps {
  isSignedContract: boolean;
}

const ContractStatus = ({ isSignedContract }: ISignStatusProps) =>
  isSignedContract ? (
    <Typography color="primary">
      <FormattedMessage {...messages.contractStatusSigned} />
    </Typography>
  ) : (
    <Typography color="error">
      <FormattedMessage {...messages.contractStatusUnsigned} />
    </Typography>
  );

export default ContractStatus;
