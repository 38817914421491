import { Box, Chip, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { CustomTheme } from '../../../ui/interfaces';
import { TooltipTypography } from 'common/components/index';
import { FormattedMessage } from 'react-intl';
import messages from '../../../messages/messages';
import InvoicePastDueItem from '../InvoicePastDueItem/InvoicePastDueItem';
import InvoiceBillingItem from '../InvoiceBillingItem/InvoiceBillingItem';
import { IInvoiceUnitDTO } from '../../../interfaces/invoices';
import { IBaseInvoicePackage } from '../../../interfaces/membership';

const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    padding: theme.spacing(1.5, 0),
    borderBottom: `1px solid ${theme.palette.borderColor.light}`,
  },
  membershipChip: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    marginLeft: theme.spacing(0.5),
    background: theme.palette.text.secondary,
    color: theme.palette.primary.contrastText,
    fontWeight: 700,
  },
  cancelChipWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
  },
}));

interface IInvoiceCancelPackageItem {
  invoicePackage: IBaseInvoicePackage;
  pastDues: IInvoiceUnitDTO[];
  billingSchedules: IInvoiceUnitDTO[];
  isMembership: boolean;
}

export default function InvoiceCancelPackageItem({
  invoicePackage,
  pastDues,
  billingSchedules,
  isMembership,
}: IInvoiceCancelPackageItem): JSX.Element {
  const classes = useStyles();

  const chipMessage = isMembership ? messages.cancelMembershipChip : messages.cancelBundleChip;

  return (
    <Box className={classes.root}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" overflow="hidden">
          <TooltipTypography ellipsized variant="h5">
            {invoicePackage.title}
          </TooltipTypography>
        </Box>

        <Typography component="p" className={classes.cancelChipWrapper}>
          <Chip
            size="small"
            label={<FormattedMessage {...chipMessage} />}
            className={classes.membershipChip}
          />
        </Typography>
      </Box>
      {!!pastDues?.length &&
        pastDues.map(({ id, pastDue, pastDueResolve }) => (
          <InvoicePastDueItem
            pastDue={pastDue}
            isIncluded
            pastDueResolve={pastDueResolve}
            key={id}
          />
        ))}

      {!!billingSchedules?.length &&
        billingSchedules.map(({ id, billingSchedule }) => (
          <InvoiceBillingItem billing={billingSchedule} key={id} isIncluded />
        ))}
    </Box>
  );
}
