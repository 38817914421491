import { GeneralThunkAction } from 'common/state/interfaces';
import Services from 'services/network';
import { enqueueErrorNotification } from 'common/state/notifications/actions';
import { createAction } from '@reduxjs/toolkit';
import { actionTypes } from 'memberPortalModules/MemberProfile/constants';
import { IProfileInfo } from 'common/components/PersonProfile/interfaces';
import { setUserAvatar } from 'memberPortalModules/authentication/state/actions';
import { IMemberAlert } from 'modules/front-desk/interfaces';
import { AlertCondition } from 'common/interfaces/alerts';
import { SenderAvailabilityTypeList } from 'modules/booking/constants/senderAvailability';
import { IFetchSenderAvailabilityData } from 'modules/booking/interfaces';
import {
  FetchSenderAvailabilityLoadingPayload,
  FetchSenderAvailabilitySuccessPayload,
} from 'modules/booking/state/senderAvailability/reducers';
import { getSenderAvailabilityByNotificationType } from 'modules/booking/constants/notificationType';

const fetchProfileInfoLoading = createAction<boolean>(actionTypes.FETCH_PROFILE_INFO_LOADING);

const fetchProfileInfo = createAction<Partial<IProfileInfo>>(actionTypes.FETCH_PROFILE_INFO);

export const resetProfileInfo = createAction(actionTypes.RESET_PROFILE_INFO);

export const fetchProfileInfoView = (): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchProfileInfoLoading(true));

    try {
      const profile = await Services.MemberPortalProfile.getMemberInfoView();

      const { preferredName, image } = profile;
      dispatch(setUserAvatar({ preferredName, avatar: image?.url }));
      dispatch(fetchProfileInfo(profile));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchProfileInfoLoading(false));
    }
  };
};

export const fetchMemberAlertsAction = createAction<IMemberAlert[]>(
  actionTypes.FETCH_PERSON_ALERTS,
);
export const fetchMemberAlertsLoadingAction = createAction<boolean>(
  actionTypes.FETCH_PERSON_ALERTS_LOADING,
);

export const fetchMemberAlerts = (): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchMemberAlertsLoadingAction(true));
    try {
      const memberAlerts = await Services.MemberPortalProfile.getMemberAlerts();
      dispatch(fetchMemberAlertsAction(memberAlerts));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(fetchMemberAlertsLoadingAction(false));
    }
  };
};

export const resolvePastDueAlert = createAction<AlertCondition.PastDuePayments>(
  actionTypes.RESOLVE_PAST_DUE_ALERT,
);

const fetchSenderAvailabilityLoading = createAction<FetchSenderAvailabilityLoadingPayload>(
  actionTypes.FETCH_SENDER_AVAILABILITY_LOADING,
);
const fetchSenderAvailability = createAction<FetchSenderAvailabilitySuccessPayload>(
  actionTypes.FETCH_SENDER_AVAILABILITY,
);

export const fetchSenderAvailabilityThunk = (
  types: SenderAvailabilityTypeList[],
  options?: IFetchSenderAvailabilityData,
): GeneralThunkAction => {
  return async dispatch => {
    types.forEach(type => {
      dispatch(fetchSenderAvailabilityLoading({ type, isLoading: true }));
    });

    try {
      const senderAvailability = await Services.MemberPortalProfile.getSenderAvailability(options);

      senderAvailability.availabilityItemDtoList.forEach(data => {
        const typeSenderAvailability = getSenderAvailabilityByNotificationType(data.type);
        dispatch(fetchSenderAvailability({ type: typeSenderAvailability, data }));
      });
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      types.forEach(type => {
        dispatch(fetchSenderAvailabilityLoading({ type, isLoading: false }));
      });
    }
  };
};
