import * as yup from 'yup';

import { regExp } from 'common/constants/regExp';
import { getRequiredMessage } from 'common/constants/globalConstants';
import { BULK_STRING } from 'common/constants/bulkConstants';
import inputErrors from 'common/messages/inputErrors';

// TODO maybe need to delete
const ZIP_CODE_BULK = yup.lazy(value => {
  if (value === BULK_STRING) {
    return yup.string().notRequired();
  }
  return yup
    .string()
    .nullable()
    .matches(regExp.ZIP_CODE, () => inputErrors.invalidZipCode)
    .required(getRequiredMessage);
});

const ZIP_CODE = yup
  .string()
  .nullable()
  .matches(regExp.ZIP_CODE, () => inputErrors.invalidZipCode);

export const validators = {
  ZIP_CODE,
};
