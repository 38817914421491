// Profile
export const FETCH_PROFILE_INFO = 'FETCH_PROFILE_INFO';
export const FETCH_PROFILE_INFO_LOADING = 'FETCH_PROFILE_INFO_LOADING';

export const UPDATE_PERSON_LOADING = 'UPDATE_PERSON_LOADING';
export const UPDATE_PROFILE_INFO = 'UPDATE_PROFILE_INFO';
export const RESET_PERSON_UPDATE_ACTION_RESULT = 'RESET_PERSON_UPDATE_ACTION_RESULT';

export const UPDATE_PERSON_PHOTO = 'UPDATE_PERSON_PHOTO';

// Alerts

export const FETCH_PERSON_ALERTS = 'FETCH_PERSON_ALERTS';
export const FETCH_PERSON_ALERTS_LOADING = 'FETCH_PERSON_ALERTS_LOADING';
export const UPDATE_PERSON_EMERGENCY_CONTACT_INFO = 'UPDATE_PERSON_EMERGENCY_CONTACT_INFO';
export const UPDATE_PERSON_INSURANCE_INFO = 'UPDATE_PERSON_INSURANCE_INFO';
export const UPDATE_PERSON_SALESPERSON = 'UPDATE_PERSON_SALESPERSON';
export const UPDATE_PERSON_ALLERGIES = 'UPDATE_PERSON_ALLERGIES';
export const UPDATE_PERSON_MEMBERSHIP_PACKAGE = 'UPDATE_PERSON_MEMBERSHIP_PACKAGE';
export const RESOLVE_PERSON_NOTE_ALERT = 'RESOLVE_PERSON_NOTE_ALERT';
export const RESOLVE_PERSON_UNSIGNED_WAIVER = 'RESOLVE_PERSON_UNSIGNED_WAIVER';
export const REMIND_LATER_PERSON_UNSIGNED_WAIVER = 'REMIND_LATER_PERSON_UNSIGNED_WAIVER_ALERT';
export const REMIND_LATER_PERSON_APPOINTMENT_ALERT = 'REMIND_LATER_PERSON_APPOINTMENT_ALERT';
export const IGNORE_PERSON_APPOINTMENT_ALERT = 'IGNORE_PERSON_APPOINTMENT_ALERT';
export const IGNORE_PERSON_UNSIGNED_WAIVER = 'IGNORE_PERSON_UNSIGNED_WAIVER_ALERT';
export const RESOLVE_PERSON_MISSING_BILLING_INFO = 'RESOLVE_PERSON_MISSING_BILLING_INFO';
export const RESOLVE_PAST_DUE_ALERT = 'RESOLVE_PAST_DUE_ALERT';

export const IGNORE_PERSON_ALERT = 'IGNORE_PERSON_ALERT';
export const REMIND_LATER_PERSON_ALERT = 'REMIND_LATER_PERSON_ALERT';
export const SET_REMIND_LATER_ACTION_RESULT = 'SET_REMIND_LATER_ACTION_RESULT';

// Additional info

export const GET_PERSON_ADDITIONAL_INFO = 'GET_PERSON_ADDITIONAL_INFO';
export const UPDATE_PERSON_ADDITIONAL_INFO = 'UPDATE_PERSON_ADDITIONAL_INFO';

// Tags
export const UPDATE_PERSON_TAGS = 'UPDATE_PERSON_TAGS';
export const UPDATE_PERSON_TAGS_LOADING = 'UPDATE_PERSON_TAGS_LOADING';

// Fallthrough Capture
export const FETCH_PERSON_FALLTHROUGH_CAPTURE = 'FETCH_PERSON_FALLTHROUGH_CAPTURE';
export const FETCH_PERSON_FALLTHROUGH_CAPTURE_LOADING = 'FETCH_PERSON_FALLTHROUGH_CAPTURE_LOADING';
export const SET_PERSON_FALLTHROUGH_CAPTURE = 'SET_PERSON_FALLTHROUGH_CAPTURE';
export const SET_PERSON_FALLTHROUGH_CAPTURE_LOADING = 'SET_PERSON_FALLTHROUGH_CAPTURE_LOADING';
export const DELETE_PERSON_FALLTHROUGH_CAPTURE = 'DELETE_PERSON_FALLTHROUGH_CAPTURE';
export const DELETE_PERSON_FALLTHROUGH_CAPTURE_LOADING =
  'DELETE_PERSON_FALLTHROUGH_CAPTURE_LOADING';
export const SET_PERSON_FALLTHROUGH_CAPTURE_ACTION_SUCCESS =
  'SET_PERSON_FALLTHROUGH_CAPTURE_ACTION_SUCCESS';

// Payment methods
export const RESET_PAYMENT_METHODS = 'RESET_PAYMENT_METHODS';
export const FETCH_STORED_CREDIT_CARDS = 'FETCH_STORED_CREDIT_CARDS';
export const FETCH_PAYMENT_METHODS_MODAL_DATA_LOADING = 'FETCH_PAYMENT_METHODS_MODAL_DATA_LOADING';
export const ADD_CREDIT_CARD_RESULT = 'ADD_CREDIT_CARD_RESULT';
export const ADD_CREDIT_CARD_RESULT_LOADING = 'ADD_CREDIT_CARD_RESULT_LOADING';
export const DELETE_CREDIT_CARD_RESULT = 'DELETE_CREDIT_CARD_RESULT';
export const DELETE_CREDIT_CARD_RESULT_LOADING = 'DELETE_CREDIT_CARD_RESULT_LOADING';
export const RESET_DELETE_CREDIT_CARD_RESULT = 'RESET_DELETE_CREDIT_CARD_RESULT';

export const FETCH_PERSON_SUBSCRIPTIONS = 'FETCH_PERSON_SUBSCRIPTIONS';
export const RESET_PERSON_SUBSCRIPTIONS = 'RESET_PERSON_SUBSCRIPTIONS';
export const FETCH_PERSON_PAYMENT_METHODS = 'FETCH_PERSON_PAYMENT_METHODS';
export const UPDATE_PERSON_SUBSCRIPTIONS = 'UPDATE_PERSON_SUBSCRIPTIONS';
export const UPDATE_PERSON_SUBSCRIPTIONS_LOADING = 'UPDATE_PERSON_SUBSCRIPTIONS_LOADING';
export const RESET_UPDATE_PERSON_SUBSCRIPTIONS_ACTION_RESULT =
  'RESET_UPDATE_PERSON_SUBSCRIPTIONS_ACTION_RESULT';

export const FETCH_CHECKING_SAVINGS_DATA = 'FETCH_CHECKING_SAVINGS_DATA';
export const FETCH_CHECKING_SAVINGS_DATA_LOADING = 'FETCH_CHECKING_SAVINGS_DATA_LOADING';
export const ADD_CHECKING_SAVINGS_DATA = 'ADD_CHECKING_SAVINGS_DATA';
export const ADD_CHECKING_SAVINGS_DATA_LOADING = 'ADD_CHECKING_SAVINGS_DATA_LOADING';
export const ADD_CHECKING_SAVINGS_DATA_ACTION_RESULT = 'ADD_CHECKING_SAVINGS_DATA_ACTION_RESULT';
export const DELETE_CHECKING_SAVINGS_ITEM = 'DELETE_CHECKING_SAVINGS_ITEM';
export const DELETE_CHECKING_SAVINGS_ITEM_LOADING = 'DELETE_CHECKING_SAVINGS_ITEM_LOADING';
export const DELETE_CHECKING_SAVINGS_ITEM_ACTION_RESULT =
  'DELETE_CHECKING_SAVINGS_ITEM_ACTION_RESULT';
export const RESET_CHECKING_SAVINGS_DATA = 'RESET_CHECKING_SAVINGS_DATA';

// Notes
export const FETCH_PERSON_NOTES = 'FETCH_PERSON_NOTES';
export const FETCH_PERSON_NOTES_LOADING = 'FETCH_PERSON_NOTES_LOADING';

export const ADD_PERSON_NOTE = 'ADD_PERSON_NOTE';
export const ADD_PERSON_NOTE_LOADING = 'ADD_PERSON_NOTE_LOADING';
export const RESET_PERSON_CREATED_NOTE = 'RESET_PERSON_CREATED_NOTE';
export const UPDATE_PERSON_NOTE_LOADING = 'UPDATE_PERSON_NOTE_LOADING';
export const UPDATE_PERSON_NOTE = 'UPDATE_PERSON_NOTE';
export const DELETE_PERSON_NOTE_LOADING = 'DELETE_PERSON_NOTE_LOADING';
export const DELETE_PERSON_NOTE = 'DELETE_PERSON_NOTE';

// Note Codes
export const FETCH_NOTE_CODES = 'FETCH_NOTE_CODES';
export const RESET_NOTE_CODES = 'RESET_NOTE_CODES';
export const FETCH_NOTE_CODES_IS_LOADING = 'FETCH_NOTE_CODES_IS_LOADING';

// Appointments
export const FETCH_RECENT_PERSON_APPOINTMENTS = 'FETCH_RECENT_PERSON_APPOINTMENTS';
export const FETCH_RECENT_PERSON_APPOINTMENTS_LOADING = 'FETCH_RECENT_PERSON_APPOINTMENTS_LOADING';
export const FETCH_PERSON_APPOINTMENTS_HISTORY = 'FETCH_PERSON_APPOINTMENTS_HISTORY';
export const FETCH_PERSON_APPOINTMENTS_HISTORY_LOADING =
  'FETCH_PERSON_APPOINTMENTS_HISTORY_LOADING';

export const FETCH_PERSON_APPOINTMENT_DETAILS = 'FETCH_PERSON_APPOINTMENT_DETAILS';
export const FETCH_PERSON_APPOINTMENT_DETAILS_LOADING = 'FETCH_PERSON_APPOINTMENT_DETAILS_LOADING';
export const RESET_PERSON_APPOINTMENT_DETAILS = 'RESET_PERSON_APPOINTMENT_DETAILS';

export const DELETE_APPOINTMENT = 'DELETE_APPOINTMENT';
export const DELETE_APPOINTMENT_LOADING = 'DELETE_APPOINTMENT_LOADING';

export const UPDATE_APPOINTMENT = 'UPDATE_APPOINTMENT';
export const UPDATE_APPOINTMENT_LOADING = 'UPDATE_APPOINTMENT_LOADING';

export const CREATE_APPOINTMENT_LOADING = 'CREATE_APPOINTMENT_LOADING';
export const CREATE_APPOINTMENT = 'CREATE_APPOINTMENT';

export const REDEEM_PERSON_APPOINTMENT = 'REDEEM_PERSON_APPOINTMENT';

export const CANCEL_APPOINTMENT_PARTICIPATION = 'CANCEL_APPOINTMENT_PARTICIPATION';
export const CANCEL_APPOINTMENT_PARTICIPATION_LOADING = 'CANCEL_APPOINTMENT_PARTICIPATION_LOADING';

export const CONFIRM_APPOINTMENT_PARTICIPATION = 'CONFIRM_APPOINTMENT_PARTICIPATION';
export const CONFIRM_APPOINTMENT_PARTICIPATION_LOADING =
  'CONFIRM_APPOINTMENT_PARTICIPATION_LOADING';

export const CANCEL_APPOINTMENT = 'CANCEL_APPOINTMENT';
export const CANCEL_APPOINTMENT_LOADING = 'CANCEL_APPOINTMENT_LOADING';

export const RESTORE_APPOINTMENT = 'RESTORE_APPOINTMENT';
export const RESTORE_APPOINTMENT_LOADING = 'RESTORE_APPOINTMENT_LOADING';

export const REMIND_APPOINTMENT = 'REMIND_APPOINTMENT';
export const REMIND_APPOINTMENT_LOADING = 'REMIND_APPOINTMENT_LOADING';

export const RESET_APPOINTMENT_ACTION_RESULT = 'RESET_APPOINTMENT_ACTION_RESULT';
// Suggested products
export const FETCH_PERSON_SUGGESTED_PRODUCTS = 'FETCH_PERSON_SUGGESTED_PRODUCTS';
export const FETCH_PERSON_SUGGESTED_PRODUCTS_LOADING = 'FETCH_PERSON_SUGGESTED_PRODUCTS_LOADING';
export const UPDATE_SOCKET_SUGGESTED_PRODUCTS = 'UPDATE_SOCKET_SUGGESTED_PRODUCTS';

// Payments
export const FETCH_PERSON_PAYMENTS = 'FETCH_PERSON_PAYMENTS';
export const FETCH_PERSON_PAYMENTS_LOADING = 'FETCH_PERSON_PAYMENTS_LOADING';
export const FETCH_RECENT_PERSON_PAYMENTS = 'FETCH_RECENT_PERSON_PAYMENTS';
export const FETCH_RECENT_PERSON_PAYMENTS_LOADING = 'FETCH_RECENT_PERSON_PAYMENTS_LOADING';
export const FETCH_PAYMENT_DETAILS = 'FETCH_PAYMENT_DETAILS';
export const FETCH_PAYMENT_DETAILS_LOADING = 'FETCH_PAYMENT_DETAILS_LOADING';

export const VOID_PERSON_INVOICE_ACTION_RESULT = 'VOID_PERSON_INVOICE_ACTION_RESULT';
export const VOID_PERSON_INVOICE_LOADING = 'VOID_PERSON_INVOICE_LOADING';

// Calls
export const FETCH_PERSON_CALLS_LOADING = 'FETCH_PERSON_CALLS_LOADING';
export const FETCH_PERSON_CALLS_SUCCESS = 'FETCH_PERSON_CALLS_SUCCESS';
export const RESET_PERSON_CALLS = 'RESET_PERSON_CALLS';

// Recent calls
export const FETCH_PERSON_RECENT_CALLS_LOADING = 'FETCH_PERSON_RECENT_CALLS_LOADING';
export const FETCH_PERSON_RECENT_CALLS_SUCCESS = 'FETCH_PERSON_RECENT_CALLS_SUCCESS';

export const FETCH_PERSON_CALL_DETAILS = 'FETCH_PERSON_CALL_DETAILS';
export const FETCH_PERSON_CALL_DETAILS_LOADING = 'FETCH_PERSON_CALL_DETAILS_LOADING';
export const RESET_PERSON_CALL_DETAILS = 'RESET_PERSON_CALL_DETAILS';

export const ADD_PERSON_CALL = 'ADD_PERSON_CALL';
export const ADD_PERSON_CALL_LOADING = 'ADD_PERSON_CALL_LOADING';

export const RESET_CREATE_PERSON_CALL_RESULT = 'RESET_CREATE_PERSON_CALL_RESULT';

// SMS
export const FETCH_PERSON_SMS_LOADING = 'FETCH_PERSON_SMS_LOADING';
export const FETCH_PERSON_SMS_SUCCESS = 'FETCH_PERSON_SMS_SUCCESS';

export const FETCH_PERSON_SMS_DETAILS_LOADING = 'FETCH_PERSON_SMS_DETAILS_LOADING';
export const FETCH_PERSON_SMS_DETAILS_SUCCESS = 'FETCH_PERSON_SMS_DETAILS_SUCCESS';
export const RESET_PERSON_SMS_DETAILS = 'RESET_PERSON_SMS_DETAILS';

export const FETCH_PERSON_SMS_HISTORY_LOADING = 'FETCH_PERSON_SMS_HISTORY_LOADING';
export const FETCH_PERSON_SMS_HISTORY_SUCCESS = 'FETCH_PERSON_SMS_HISTORY_SUCCESS';

export const ADD_PERSON_SMS_SUCCESS = 'ADD_PERSON_SMS_SUCCESS';
export const RESET_PERSON_CREATE_SMS_ACTION_RESULT = 'RESET_PERSON_CREATE_SMS_ACTION_RESULT';
export const ADD_PERSON_SMS_LOADING = 'ADD_PERSON_SMS_LOADING';

// Reminders
export const FETCH_PERSON_REMINDERS_LOADING = 'FETCH_PERSON_REMINDERS_LOADING';
export const FETCH_PERSON_REMINDERS_SUCCESS = 'FETCH_PERSON_REMINDERS_SUCCESS';
export const RESET_PERSON_REMINDERS = 'RESET_PERSON_REMINDERS';

// Reminder
export const FETCH_PERSON_REMINDER_LOADING = 'FETCH_PERSON_REMINDER_LOADING';
export const FETCH_PERSON_REMINDER_SUCCESS = 'FETCH_PERSON_REMINDER_SUCCESS';
export const RESET_PERSON_REMINDER = 'RESET_PERSON_REMINDER';

// Recent reminders
export const FETCH_PERSON_RECENT_REMINDERS_LOADING = 'FETCH_PERSON_RECENT_REMINDERS_LOADING';
export const FETCH_PERSON_RECENT_REMINDERS_SUCCESS = 'FETCH_PERSON_RECENT_REMINDERS_SUCCESS';

export const RESET_ADD_PERSON_REMINDER = 'RESET_ADD_PERSON_REMINDER';
export const ADD_PERSON_REMINDER = 'ADD_PERSON_REMINDER';
export const ADD_PERSON_REMINDER_LOADING = 'ADD_PERSON_REMINDER_LOADING';

// Recent Emails
export const FETCH_PERSON_RECENT_EMAILS_LOADING = 'FETCH_PERSON_RECENT_EMAILS_LOADING';
export const FETCH_PERSON_RECENT_EMAILS_SUCCESS = 'FETCH_PERSON_RECENT_EMAILS_SUCCESS';
export const SOCKET_UPDATE_PERSON_RECENT_EMAILS = 'SOCKET_UPDATE_PERSON_RECENT_EMAILS';

// Emails
export const FETCH_PERSON_EMAILS_LOADING = 'FETCH_PERSON_EMAILS_LOADING';
export const FETCH_PERSON_EMAILS_SUCCESS = 'FETCH_PERSON_EMAILS_SUCCESS';
export const RESET_PERSON_EMAILS = 'RESET_PERSON_EMAILS';

export const FETCH_PERSON_EMAIL_LOADING = 'FETCH_PERSON_EMAIL_LOADING';
export const FETCH_PERSON_EMAIL_SUCCESS = 'FETCH_PERSON_EMAIL_SUCCESS';
export const RESET_PERSON_EMAIL = 'RESET_PERSON_EMAIL';

export const ADD_PERSON_EMAIL = 'ADD_PERSON_EMAIL';
export const RESET_PERSON_CREATE_EMAIL_ACTION_RESULT = 'RESET_PERSON_CREATE_EMAIL_ACTION_RESULT';
export const ADD_PERSON_EMAIL_LOADING = 'ADD_PERSON_EMAIL_LOADING';

export const DELETE_PERSON_EMAIL = 'DELETE_PERSON_EMAIL';
export const DELETE_PERSON_EMAIL_LOADING = 'DELETE_PERSON_EMAIL_LOADING';

// Campaigns
export const FETCH_PERSON_CAMPAIGNS_LOADING = 'FETCH_PERSON_CAMPAIGNS_LOADING';
export const FETCH_PERSON_CAMPAIGNS_SUCCESS = 'FETCH_PERSON_CAMPAIGNS_SUCCESS';

export const FETCH_PERSON_CAMPAIGNS_HISTORY_LOADING = 'FETCH_PERSON_CAMPAIGNS_HISTORY_LOADING';
export const FETCH_PERSON_CAMPAIGNS_HISTORY_SUCCESS = 'FETCH_PERSON_CAMPAIGNS_HISTORY_SUCCESS';

export const FETCH_PERSON_APPOINTMENT_SERVICES_LOADING =
  'FETCH_PERSON_APPOINTMENT_SERVICES_LOADING';
export const FETCH_PERSON_APPOINTMENT_SERVICES = 'FETCH_PERSON_APPOINTMENT_SERVICES';
export const RESET_PERSON_APPOINTMENT_SERVICES = 'RESET_PERSON_APPOINTMENT_SERVICES';

export const FETCH_PERSON_SERVICES_FOR_EVENT_REDEEM = 'FETCH_PERSON_SERVICES_FOR_EVENT_REDEEM';
export const FETCH_PERSON_SERVICES_FOR_EVENT_REDEEM_LOADING =
  'FETCH_PERSON_SERVICES_FOR_EVENT_REDEEM_LOADING';
export const RESET_PERSON_SERVICES_FOR_EVENT_REDEEM = 'RESET_PERSON_SERVICES_FOR_EVENT_REDEEM';

// Modification history
export const FETCH_PERSON_MODIFICATION_HISTORY = 'FETCH_PERSON_MODIFICATION_HISTORY';
export const FETCH_PERSON_RECENT_MODIFICATION_HISTORY = 'FETCH_PERSON_RECENT_MODIFICATION_HISTORY';
export const FETCH_PERSON_RECENT_MODIFICATION_HISTORY_LOADING =
  'FETCH_PERSON_RECENT_MODIFICATION_HISTORY_LOADING';
export const FETCH_PERSON_MODIFICATION_HISTORY_LOADING =
  'FETCH_PERSON_MODIFICATION_HISTORY_LOADING';

// CheckIns history

export const FETCH_PERSON_CHECK_INS_HISTORY = 'FETCH_PERSON_CHECK_INS_HISTORY';
export const FETCH_PERSON_RECENT_CHECK_INS_HISTORY = 'FETCH_PERSON_RECENT_CHECK_INS_HISTORY';
export const FETCH_PERSON_CHECK_INS_HISTORY_LOADING = 'FETCH_PERSON_CHECK_INS_HISTORY_LOADING';
export const FETCH_PERSON_RECENT_CHECK_INS_HISTORY_LOADING =
  'FETCH_PERSON_RECENT_CHECK_INS_HISTORY_LOADING';

export const FETCH_PERSON_MODIFICATION_HISTORY_ITEM = 'FETCH_PERSON_MODIFICATION_HISTORY_ITEM';
export const FETCH_PERSON_MODIFICATION_HISTORY_ITEM_LOADING =
  'FETCH_PERSON_MODIFICATION_HISTORY_ITEM_LOADING';
export const RESET_PERSON_MODIFICATION_HISTORY_ITEM = 'RESET_PERSON_MODIFICATION_HISTORY_ITEM';

// Documents history

export const FETCH_PERSON_DOCUMENTS_HISTORY = 'FETCH_PERSON_DOCUMENTS_HISTORY';
export const FETCH_PERSON_DOCUMENTS_HISTORY_LOADING = 'FETCH_PERSON_DOCUMENTS_HISTORY_LOADING';

// Upcoming billings
export const FETCH_PERSON_UPCOMING_BILLINGS = 'FETCH_PERSON_UPCOMING_BILLINGS';
export const FETCH_PERSON_UPCOMING_BILLINGS_LOADING = 'FETCH_PERSON_UPCOMING_BILLINGS_LOADING';
export const UPDATE_BILLING_SCHEDULE_AMOUNT = 'UPDATE_BILLING_SCHEDULE_AMOUNT';
export const UPDATE_BILLING_SCHEDULE_AMOUNT_LOADING = 'UPDATE_BILLING_SCHEDULE_AMOUNT_LOADING';
export const RESET_UPDATE_BILLING_SCHEDULE_AMOUNT_ACTION_RESULT =
  'RESET_UPDATE_BILLING_SCHEDULE_AMOUNT_ACTION_RESULT';

// Past due billings
export const FETCH_PERSON_PAST_DUE_BILLINGS = 'FETCH_PERSON_PAST_DUE_BILLINGS';
export const FETCH_PERSON_PAST_DUE_BILLINGS_LOADING = 'FETCH_PERSON_PAST_DUE_BILLINGS_LOADING';

// Package billings

export const FETCH_PACKAGE_UPCOMING_BILLINGS = 'FETCH_PACKAGE_UPCOMING_BILLINGS';
export const FETCH_PACKAGE_UPCOMING_BILLINGS_LOADING = 'FETCH_PACKAGE_UPCOMING_BILLINGS_LOADING';
export const RESET_PACKAGE_UPCOMING_BILLINGS = 'RESET_PACKAGE_UPCOMING_BILLINGS';

// sign waiver
export const SIGN_WAIVER = 'SIGN_WAIVER';
export const SIGN_WAIVER_LOADING = 'SIGN_WAIVER_LOADING';
export const RESET_SIGN_WAIVER = 'RESET_SIGN_WAIVER';

// SalesInfo
export const FETCH_PERSON_SALES_INFO = 'FETCH_PERSON_SALES_INFO';
export const RESET_PERSON_SALES_INFO = 'RESET_PERSON_SALES_INFO';
export const FETCH_PERSON_SALES_INFO_LOADING = 'FETCH_PERSON_SALES_INFO_LOADING';
export const UPDATE_PERSON_SALES_INFO = 'UPDATE_PERSON_SALES_INFO';
export const UPDATE_PERSON_SALES_INFO_LOADING = 'UPDATE_PERSON_SALES_INFO_LOADING';
export const RESET_UPDATE_PERSON_SALES_INFO = 'RESET_UPDATE_PERSON_SALES_INFO';

// Documents
export const FETCH_PERSON_DOCUMENTS = 'FETCH_PERSON_DOCUMENTS';
export const FETCH_PERSON_DOCUMENTS_LOADING = 'FETCH_PERSON_DOCUMENTS_LOADING';
export const RESET_PERSON_DOCUMENTS = 'RESET_PERSON_DOCUMENTS';
export const UPDATE_PERSON_ATTACHMENTS = 'UPDATE_PERSON_ATTACHMENTS';
export const UPDATE_PERSON_ATTACHMENTS_LOADING = 'UPDATE_PERSON_ATTACHMENTS_LOADING';
export const DELETE_PERSON_ATTACHMENT = 'DELETE_PERSON_ATTACHMENT';
export const DELETE_PERSON_ATTACHMENT_LOADING = 'DELETE_PERSON_ATTACHMENT_LOADING';
export const RESET_PERSON_ATTACHMENTS_ACTION_RESULT = 'RESET_PERSON_ATTACHMENTS_ACTION_RESULT';
export const FETCH_DOCUMENT_HTML_LOADING = 'FETCH_DOCUMENT_HTML_LOADING';

// Rewards
export const UPDATE_PERSON_REWARDS = 'UPDATE_PERSON_REWARDS';
export const UPDATE_PERSON_REWARDS_LOADING = 'UPDATE_PERSON_REWARDS_LOADING';
