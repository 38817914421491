// Utils
const toArrayBuffer = str => {
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0; i < str.length; i += 1) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
};

const toBase64Encode = arrayBuffer => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return window.btoa(String.fromCharCode(...new Uint8Array(arrayBuffer)));
};

// RSA Algorithm
const asymmerticallyEncrypt = async (base64PubKey, sourceString) => {
  const keyBuf = toArrayBuffer(atob(base64PubKey));
  const pubKeyDer = await window.crypto.subtle.importKey(
    'spki',
    keyBuf,
    { name: 'RSA-OAEP', hash: 'SHA-256' },
    true,
    ['encrypt'],
  );
  const encryptedBlock = await window.crypto.subtle.encrypt(
    { name: 'RSA-OAEP' },
    pubKeyDer,
    new TextEncoder().encode(sourceString),
  );
  return toBase64Encode(encryptedBlock);
};

interface IEncryptionDataParams {
  rsaAsymmetricPublicKey: string;
  keyId: string;
  cardData: string;
  nameOnCard: string;
  expirationMonth: string;
  expirationYear: string;
  securityCode: string;
}

export interface IEncryptedCardData {
  keyId: string;
  encryptionType: string;
  encryptionBlock: string;
  encryptionBlockFields: string;
  encryptionTarget: string;
}

export const encryptCreditCardData = async ({
  rsaAsymmetricPublicKey,
  keyId,
  cardData,
  nameOnCard,
  expirationMonth,
  expirationYear,
  securityCode = '',
}: IEncryptionDataParams): Promise<IEncryptedCardData> => {
  const cardObject = {
    cardData,
    nameOnCard,
    expirationMonth,
    expirationYear,
    securityCode,
  };

  const encryptionBlock = await asymmerticallyEncrypt(
    rsaAsymmetricPublicKey,
    Object.values(cardObject).join(''),
  );

  const encoder = new TextEncoder();
  const encryptionBlockFields = Object.keys(cardObject)
    .map(key => `card.${key}:${encoder.encode(cardObject[key]).length}`)
    .join(',');
  return {
    keyId,
    encryptionType: 'RSA',
    encryptionBlock,
    encryptionBlockFields,
    encryptionTarget: 'MANUAL',
  };
};
