import { IPersonSalesInfoImt } from 'common/components/PersonProfile/interfaces';
import { createSelector } from 'reselect';
import { AppStoreTypeImt } from 'store';
import { IStoredFileSimpleDtoImt } from 'common/interfaces/uploadFile';

const selectLeadsDomain = (state: any) => state.getIn(['crmModule', 'leadsTable']);

// Leads list
const selectLeads = () => createSelector(selectLeadsDomain, state => state.get('list'));

const selectLeadsMeta = () => createSelector(selectLeadsDomain, state => state.get('meta'));

const selectLeadsLoading = () =>
  createSelector(selectLeadsDomain, state => state.get('listLoading'));

const selectLeadsAssignLoading = () =>
  createSelector(selectLeadsDomain, state => state.get('leadsAssigninLoading'));

const selectLeadsStatusChangeLoading = () =>
  createSelector(selectLeadsDomain, state => state.get('leadsStatusChangeLoading'));

const selectLeadsActionResult = () =>
  createSelector(selectLeadsDomain, state => state.get('leadsActionResult'));

// Lead
const selectLeadDomain = (state: any) => state.getIn(['crmModule', 'leadForm']);

const selectLeadUpdateLoading = createSelector(selectLeadDomain, state =>
  state.get('updateLoading'),
);

const selectLeadLoading = createSelector(selectLeadDomain, state => state.get('loading'));

const selectLeadForm = createSelector(selectLeadDomain, state => state.get('lead'));

const selectCreatedLead = createSelector(selectLeadDomain, state => state.get('createdLead'));

export const crmLeadFormPath = ['crmModule', 'leadForm'];

const selectCreateLeadActionResult = state =>
  state.getIn([...crmLeadFormPath, 'createLeadActionResult']);

const selectLeadActionResult = (state: AppStoreTypeImt) => {
  return state.getIn([...crmLeadFormPath, 'resultAction']);
};

const selectLeadAvatar = createSelector(
  selectLeadDomain,
  (state): IStoredFileSimpleDtoImt => state.getIn(['lead', 'image']),
);

const selectLeadId = createSelector(selectLeadDomain, (state): string =>
  state.getIn(['lead', 'id']),
);

const updateLeadAvatarLoading = () =>
  createSelector(selectLeadDomain, state => state.getIn(['lead', 'imageLoading']));

const selectLeadFormSalesInfo = (state): IPersonSalesInfoImt =>
  state.getIn([...crmLeadFormPath, 'leadFormSalesInfo']);

const selectLeadFormSalesInfoLoading = (state): boolean =>
  state.getIn([...crmLeadFormPath, 'leadFormSalesInfoLoading']);

const selectLeadActivities = state => state.getIn([...crmLeadFormPath, 'leadActivities']);

const selectLeadActivitiesLoading = state =>
  state.getIn([...crmLeadFormPath, 'leadActivitiesLoading']);

export {
  selectLeads,
  selectLeadsMeta,
  selectLeadsLoading,
  selectLeadsAssignLoading,
  selectLeadsStatusChangeLoading,
  selectLeadsActionResult,
  // Lead
  selectLeadLoading,
  selectLeadUpdateLoading,
  selectLeadForm,
  selectCreatedLead,
  selectLeadFormSalesInfo,
  selectLeadFormSalesInfoLoading,
  selectLeadActivities,
  selectLeadActivitiesLoading,
  selectCreateLeadActionResult,
  selectLeadActionResult,
  updateLeadAvatarLoading,
  selectLeadAvatar,
  selectLeadId,
};
