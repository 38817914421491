import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Box, Button, Grid, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';

import { ExportTypes } from 'modules/reports/interfaces/export';

import { routes } from 'modules/reports/constants/routes';

import { RouteBackground } from 'components';
import { ButtonWithCircularProgress, ModuleSidebar, ScrollBox } from 'common/components';
import { ReactComponent as BackArrowIcon } from 'img/arrow-circle-left.svg';
import { ReactComponent as PrinterIcon } from 'img/icons/printer.svg';
import { CameraAlt } from '@material-ui/icons';
import { ExportReport } from '../ExportReport/ExportReport';

import commonMessages from 'common/messages/messages';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
  },
  body: {
    flex: 1,
    minHeight: 0,
    width: '100%',
  },
  contentWrapper: {
    flex: 1,
    height: '100%',
    minWidth: 0,
    [theme.breakpoints.down('md')]: {
      minWidth: 'auto',
    },
    overflow: 'auto',
  },
  title: {
    marginLeft: theme.spacing(16),

    '& p': {
      fontSize: '1.375rem',
    },
  },
  snapshotsButton: {
    margin: theme.spacing(0, 2),
  },
  buttonsSection: {
    width: '50%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  sidebarWrapper: {
    height: '100%',
    width: 210,
    minWidth: 210,
    paddingRight: theme.spacing(2),
  },
}));

interface IReportContainerProps {
  className?: string;
  title?: string | JSX.Element;
  showSnapshotsButtons?: boolean;
  showReprintButton?: boolean;
  hideModuleTitle?: boolean;
  titleComponent?: JSX.Element;
  backRedirectLink?: string;
  hideSidebar?: boolean;
  openSnapshotsModal?: () => void;
  hideExportButton?: boolean;
  isPrinting?: boolean;
  children: JSX.Element;
  exportResult?: Record<string, boolean>;
  exportTypes?: Array<ExportTypes>;
  exportAction?: (data: Record<string, unknown>) => void;
  onReprint?: () => void;
}

export default function ReportsContainer({
  className,
  children,
  title = '',
  hideModuleTitle = false,
  showSnapshotsButtons = false,
  showReprintButton = false,
  isPrinting,
  titleComponent,
  hideSidebar,
  openSnapshotsModal,
  hideExportButton,
  backRedirectLink,
  exportResult,
  exportTypes,
  exportAction,
  onReprint,
}: IReportContainerProps): JSX.Element {
  const classes = useStyles();

  return (
    <RouteBackground hasMobilePadding>
      <Grid container spacing={2} direction="column" className={`${classes.root} ${className}`}>
        <Grid item container spacing={1} justify="space-between">
          <Grid item>
            <Box display="flex" width="100%" alignItems="center">
              {backRedirectLink && (
                <Box mr={1}>
                  <Link to={backRedirectLink}>
                    <IconButton style={{ padding: 0 }}>
                      <BackArrowIcon style={{ width: 17, height: 17 }} />
                    </IconButton>
                  </Link>
                </Box>
              )}
              {!hideModuleTitle && (
                <Typography variant="h2">
                  <FormattedMessage {...commonMessages.reports} />
                </Typography>
              )}
              <Grid item className={!hideModuleTitle && classes.title}>
                <Typography component="p">{title}</Typography>
              </Grid>
              {titleComponent && titleComponent}
            </Box>
          </Grid>

          <Grid item className={classes.buttonsSection}>
            {showSnapshotsButtons && (
              <>
                {/* TODO: implement creating snapshots */}
                <Button variant="contained" color="primary">
                  <FormattedMessage {...commonMessages.makeSnapshotsButton} />
                </Button>
                <Button
                  variant="contained"
                  className={classes.snapshotsButton}
                  startIcon={<CameraAlt />}
                  onClick={openSnapshotsModal}
                >
                  <FormattedMessage {...commonMessages.snapshotsButton} />
                </Button>
              </>
            )}
            {showReprintButton && (
              <ButtonWithCircularProgress
                isSubmitting={isPrinting}
                variant="contained"
                disableBackground
                startIcon={<PrinterIcon width={20} height={20} />}
                onClick={onReprint}
              >
                <FormattedMessage {...commonMessages.reprintBtn} />
              </ButtonWithCircularProgress>
            )}
            {!hideExportButton && (
              <ExportReport
                exportAction={exportAction}
                exportResult={exportResult}
                exportTypes={exportTypes}
              />
            )}
          </Grid>
        </Grid>
        <Grid item className={classes.body}>
          <ScrollBox>
            <Box display="flex" style={{ width: '100%', height: '100%' }}>
              {!hideSidebar && (
                <Box className={classes.sidebarWrapper}>
                  <ScrollBox suppressScrollX>
                    <ModuleSidebar routes={routes} />
                  </ScrollBox>
                </Box>
              )}
              <Box className={`${classes.contentWrapper} reportsContentWrapper`}>{children}</Box>
            </Box>
          </ScrollBox>
        </Grid>
      </Grid>
    </RouteBackground>
  );
}
