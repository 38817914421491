import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import commonMessages from 'common/messages/messages';
import { ButtonWithCircularProgress } from 'common/components';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { CustomTheme } from 'common/ui/interfaces';

const useStyles = makeStyles((theme: CustomTheme) => ({
  footer: {
    padding: theme.spacing(2),
    flexWrap: 'nowrap',
  },
}));

interface IFooterProps {
  isLoading: boolean;
  onNext: () => void;
  onBack: () => void;
}

const Footer = (props: IFooterProps) => {
  const { onBack, onNext, isLoading } = props;

  const classes = useStyles();

  const handleOnBack = () => {
    if (onBack) {
      onBack();
    }
  };

  const handleOnNext = () => {
    if (onNext) {
      onNext();
    }
  };

  return (
    <Grid container className={classes.footer} justify="flex-end" spacing={2}>
      <Grid item>
        <Button color="primary" onClick={handleOnBack}>
          <FormattedMessage {...commonMessages.cancelBtn} />
        </Button>
      </Grid>

      <Grid item>
        <ButtonWithCircularProgress
          type="submit"
          variant="contained"
          onClick={handleOnNext}
          isSubmitting={isLoading}
        >
          <FormattedMessage {...commonMessages.saveBtn} />
        </ButtonWithCircularProgress>
      </Grid>
    </Grid>
  );
};

export default Footer;
