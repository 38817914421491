import React from 'react';
import { List as ImmutableList } from 'immutable';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { FormControlLabel, Grid, MenuItem, TextField } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { TDocumentTemplateListItemImt } from 'common/interfaces/dictionary';
import { IScriptListItemImt } from 'modules/crm/interfaces/scripts';
import { CampaignTypes } from 'modules/crm/constants/campaigns';
import inputLabels from 'common/messages/inputLabels';
import { Checkbox, MultipleSelect, Select } from 'common/components';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { CampaignType } from 'common/constants/campaign';

interface IProps {
  scripts: ImmutableList<IScriptListItemImt>;
  documentTemplates: ImmutableList<TDocumentTemplateListItemImt>;
  isEditCampaign?: boolean;
}

const CampaignInfoSection = ({
  scripts,
  documentTemplates,
  isEditCampaign,
}: IProps): JSX.Element => {
  const { control, errors } = useFormContext();

  const campaignType = useWatch({
    control,
    name: 'campaignType',
  });

  const renderIntlMessage = useRenderIntlMessage();

  const isEmailTemplateFiledVisible = CampaignType.EMAIL === campaignType;
  const isTextTemplateFiledVisible = CampaignType.SMS === campaignType;
  const isAutomaticFiledVisible =
    CampaignType.EMAIL === campaignType || CampaignType.SMS === campaignType;

  const getTemplateOptionsByType = () => {
    return documentTemplates
      .map(script => ({
        id: script.get('id'),
        title: script.get('title'),
      }))
      .toArray();
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Controller
          control={control}
          name="title"
          render={({ value, onChange }) => (
            <TextField
              value={value}
              onChange={onChange}
              label={<FormattedMessage {...inputLabels.title} />}
              variant="outlined"
              autoComplete="off"
              fullWidth
              error={!!errors.title}
              helperText={renderIntlMessage(errors.title?.message)}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name="campaignType"
          defaultValue=""
          render={({ name, value, onChange, onBlur }) => (
            <Select
              fullWidth
              disabled={isEditCampaign}
              label={<FormattedMessage {...inputLabels.type} />}
              variant="outlined"
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              error={!!errors.campaignType}
              helperText={renderIntlMessage(errors.campaignType?.message)}
            >
              {CampaignTypes.getSelectOptions()}
            </Select>
          )}
        />
      </Grid>

      {isEmailTemplateFiledVisible || isTextTemplateFiledVisible ? (
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="documentTemplate"
            defaultValue=""
            render={({ name, value, onChange, onBlur }) => (
              <MultipleSelect
                fullWidth
                label={
                  <FormattedMessage
                    {...(isEmailTemplateFiledVisible
                      ? inputLabels.emailTemplate
                      : inputLabels.smsTemplate)}
                  />
                }
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.documentTemplate}
                helperText={renderIntlMessage(errors.documentTemplate?.message)}
                options={getTemplateOptionsByType()}
              />
            )}
          />
        </Grid>
      ) : (
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="script.id"
            defaultValue=""
            render={({ name, value, onChange, onBlur }) => (
              <Select
                fullWidth
                label={<FormattedMessage {...inputLabels.script} />}
                variant="outlined"
                name={name}
                value={value === null ? '' : value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.script}
                helperText={renderIntlMessage(errors.script?.id?.message)}
              >
                {scripts.map(script => (
                  <MenuItem key={script.get('id')} value={script.get('id')}>
                    {script.get('title')}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>
      )}

      {isAutomaticFiledVisible && (
        <Grid item xs={12}>
          <Controller
            render={({ onChange, value }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={value}
                    onChange={e => onChange(e.target.checked)}
                    color="primary"
                  />
                }
                label={<FormattedMessage {...inputLabels.automaticRun} />}
              />
            )}
            control={control}
            name="automatic"
          />
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(CampaignInfoSection);
