import { defineMessages } from 'react-intl';

export default defineMessages({
  pageTitle: {
    id: 'app.routes.FrontDesk.pageTitle',
    defaultMessage: 'Main Reception',
  },

  // Block titles
  appointmentsBlockTitle: {
    id: 'app.modules.personProfile.block.titles.appointments',
    defaultMessage: 'Appointments',
  },
  appointmentsHistoryTitle: {
    id: 'app.modules.personProfile.block.appointments.appointmentsHistoryModal.title',
    defaultMessage: 'Appointments History',
  },
  noServicesMessage: {
    id: 'app.modules.personProfile.block.appointments.redeemModal.noServices.message',
    defaultMessage: 'No services for redeem.',
  },
  // Documents
  documentsBlockTitle: {
    id: 'app.modules.personProfile.block.titles.documents',
    defaultMessage: 'Documents',
  },
  emptyDocumentsListMessage: {
    id: 'app.modules.personProfile.Documents.empty',
    defaultMessage: 'No Documents yet.',
  },
  // Tickets
  ticketsBlockTitle: {
    id: 'app.modules.personProfile.block.titles.tickets',
    defaultMessage: 'Tickets',
  },
  emptyTicketsListMessage: {
    id: 'app.modules.personProfile.Tickets.empty',
    defaultMessage: 'No Tickets yet.',
  },
  cancelMember: {
    id: 'app.modules.personProfile.Tickets.cancelMember',
    defaultMessage: 'Cancel Member',
  },
  freezeMember: {
    id: 'app.modules.personProfile.Tickets.freezeMember',
    defaultMessage: 'Freeze Member',
  },
  memberChange: {
    id: 'app.modules.personProfile.Tickets.memberChange',
    defaultMessage: 'Member Change',
  },
  refundMember: {
    id: 'app.modules.personProfile.Tickets.refundMember',
    defaultMessage: 'Refund Member',
  },
  generalQuestion: {
    id: 'app.modules.personProfile.Tickets.generalQuestion',
    defaultMessage: 'General Question',
  },
  questionOnReport: {
    id: 'app.modules.personProfile.Tickets.questionOnReport',
    defaultMessage: 'Question on a Report',
  },
  newTicketTitle: {
    id: 'app.modules.personProfile.Tickets.newTicketTitle',
    defaultMessage: 'New Ticket',
  },
  newTicketNote: {
    id: 'app.modules.personProfile.Tickets.newTicketNote',
    defaultMessage: `It can take up to a few minutes for new tickets, users, and other resources to be indexed for search. If new resources don't appear in your search results, wait a few minutes and try again.`,
  },
  newStatus: {
    id: 'app.modules.personProfile.Tickets.newStatus',
    defaultMessage: 'New',
  },
  openStatus: {
    id: 'app.modules.personProfile.Tickets.openStatus',
    defaultMessage: 'Opened',
  },
  pendingStatus: {
    id: 'app.modules.personProfile.Tickets.pendingStatus',
    defaultMessage: 'Pending',
  },
  holdStatus: {
    id: 'app.modules.personProfile.Tickets.holdStatus',
    defaultMessage: 'Hold',
  },
  solvedStatus: {
    id: 'app.modules.personProfile.Tickets.solvedStatus',
    defaultMessage: 'Solved',
  },
  closedStatus: {
    id: 'app.modules.personProfile.Tickets.closedStatus',
    defaultMessage: 'Closed',
  },
  deletedStatus: {
    id: 'app.modules.personProfile.Tickets.deletedStatus',
    defaultMessage: 'Deleted',
  },
  reply: {
    id: 'app.modules.personProfile.Tickets.reply',
    defaultMessage: 'Reply #{id} {date}',
  },
  respondToEmailInfo: {
    id: 'app.modules.personProfile.Tickets.respondToEmailInfo',
    defaultMessage:
      'This is the email address that will be used to respond to your request. Please use the "View My Information" to change your email address if it is incorrect.',
  },
  internalNote: {
    id: 'app.modules.personProfile.Tickets.internalNote',
    defaultMessage: 'Internal Note',
  },
  // Referrals
  referralsBlockTitle: {
    id: 'app.modules.personProfile.block.titles.referrals',
    defaultMessage: 'Referrals',
  },
  emptyReferralsListMessage: {
    id: 'app.modules.personProfile.Referrals.empty',
    defaultMessage: 'No Referrals yet.',
  },
  newReferralTitle: {
    id: 'app.modules.personProfile.Referrals.newReferralTitle',
    defaultMessage: 'New Referral',
  },
  newReferralNote: {
    id: 'app.modules.personProfile.Referrals.newReferralNote',
    defaultMessage: `You consent to receive text messages during the term of this inquiry and thereafter. Consent is not a required condition. At any time reply "STOP" to opt out of receiving text messages. Message and data rates may apply.`,
  },
  referralCameFrom: {
    id: 'app.modules.personProfile.Referrals.referralCameFrom',
    defaultMessage: 'Came From',
  },
  selectReferral: {
    id: 'app.modules.personProfile.Referrals.selectReferral',
    defaultMessage: 'Select Referral',
  },
  // Services
  servicesBlockTitle: {
    id: 'app.modules.personProfile.Services.title',
    defaultMessage: 'Services',
  },
  emptyServiceListMessage: {
    id: 'app.modules.personProfile.Services.empty',
    defaultMessage: 'No Services yet.',
  },
  amountLeftShortLabel: {
    id: 'app.modules.personProfile.Services.session.short',
    defaultMessage: '{available} of {total} left',
  },
  sessionsAmountLeftLabel: {
    id: 'app.modules.personProfile.Services.session.long',
    defaultMessage: '{available} of {total} sessions left',
  },
  durationAmountLeftLabel: {
    id: 'app.modules.personProfile.Services.duration',
    defaultMessage: '{available} of {total} {durationUnit} left',
  },
  emptyServiceHistoryListMessage: {
    id: 'app.modules.personProfile.ServiceHistoryList.empty',
    defaultMessage: 'No used services yet.',
  },
  outOfServiceAvailabilityMessage: {
    id: 'app.modules.personProfile.Services.RedeemModal.outOfServiceAvailability',
    defaultMessage: 'Selected redeem datetime out of your service availability hours: {ranges}',
  },
  oneTime: {
    id: 'app.modules.personProfile.Services.InventoryDetailsView.oneTime',
    defaultMessage: 'One time',
  },
  perCycle: {
    id: 'app.modules.personProfile.Services.InventoryDetailsView.perCycle',
    defaultMessage: 'Per cycle',
  },
  perSession: {
    id: 'app.modules.personProfile.Services.InventoryDetailsView.perSession',
    defaultMessage: 'Per session',
  },

  // Additional Info
  moreInfo: {
    id: 'app.modules.personProfile.additional.moreInfo',
    defaultMessage: 'More Info',
  },
  noAdditionalFields: {
    id: 'app.modules.personProfile.additional.noAdditionalFields',
    defaultMessage: 'No additional fields available',
  },
  leadType: {
    id: 'app.modules.personProfile.additional.leadType',
    defaultMessage: 'Lead Type',
  },
  preferableCommunication: {
    id: 'app.modules.personProfile.additional.preferableCommunication',
    defaultMessage: 'Preferable way of communication',
  },
  doNotContactHours: {
    id: 'app.modules.personProfile.additional.doNotContactHours',
    defaultMessage: 'Do not contact hours',
  },
  insuranceInfoBlockTitle: {
    id: 'app.modules.personProfile.block.titles.insuranceInfo',
    defaultMessage: 'Insurance information',
  },
  emergencyContactBlockTitle: {
    id: 'app.modules.personProfile.block.titles.emergencyContact',
    defaultMessage: 'Emergency contact',
  },
  allergiesBlockTitle: {
    id: 'app.modules.personProfile.block.titles.allergies',
    defaultMessage: 'Allergies',
  },

  // Notes
  emptyNotesListTextMessage: {
    id: 'app.modules.personProfile.notes.empty',
    defaultMessage: 'No notes added.',
  },
  emptyNotesListTextDescription: {
    id: 'app.modules.personProfile.notes.emptyDescription',
    defaultMessage: 'You can add a note by pressing the button above',
  },

  // Products
  emptyProductsListMessage: {
    id: 'app.modules.personProfile.suggestedProducts.empty',
    defaultMessage: 'No suggested products.',
  },

  // Family members
  emptyFamilyMembersListTextMessage: {
    id: 'app.modules.personProfile.familyMembers.empty',
    defaultMessage: 'No family members added.',
  },
  emptyFamilyMembersListTextDescription: {
    id: 'app.modules.personProfile.familyMembers.emptyDescription',
    defaultMessage: 'You can add a family member by pressing the button above',
  },

  // SMS
  emptySMSListTextMessage: {
    id: 'app.modules.personProfile.sms.empty',
    defaultMessage: 'No SMS yet.',
  },

  // Reminders
  remindersTitle: {
    id: 'app.modules.personProfile.reminders.title',
    defaultMessage: 'Reminders',
  },
  addReminderMessage: {
    id: 'app.modules.personProfile.reminders.addReminderMessage',
    defaultMessage: 'Add Reminder',
  },
  emptyReminderListTextMessage: {
    id: 'app.modules.personProfile.reminders.empty',
    defaultMessage: 'No reminders added.',
  },
  emptyReminderListTextDescription: {
    id: 'app.modules.personProfile.reminders.emptyReminderList',
    defaultMessage: 'You can add a reminder by pressing the button above',
  },

  // Calls
  callsTitle: {
    id: 'app.modules.personProfile.calls.title',
    defaultMessage: 'Calls',
  },
  emptyCallsListTextMessage: {
    id: 'app.modules.personProfile.calls.empty',
    defaultMessage: 'No calls yet.',
  },
  editCallNoteMessage: {
    id: 'app.modules.personProfile.calls.editCallNoteMessage',
    defaultMessage: 'Edit Call Note',
  },
  newCallNoteMessage: {
    id: 'app.modules.personProfile.calls.newCallNoteMessage',
    defaultMessage: 'New Call Note',
  },

  // Emails
  viewTitle: {
    id: 'app.modules.personProfile.emails.view',
    defaultMessage: 'View',
  },
  replyTitle: {
    id: 'app.modules.personProfile.emails.replyTitle',
    defaultMessage: 'Reply',
  },
  emailDetailsTitle: {
    id: 'app.modules.personProfile.emails.emailDetails',
    defaultMessage: 'Email details',
  },
  newMessageTitle: {
    id: 'app.modules.personProfile.emails.newMessageTitle',
    defaultMessage: 'New Message',
  },
  emptyEmailsListTextMessage: {
    id: 'app.modules.personProfile.emails.empty',
    defaultMessage: 'No emails yet.',
  },
  confirmOverrideTextMessage: {
    id: 'app.modules.personProfile.emails.confirmOverride',
    defaultMessage:
      'Your {value} already have some entered data - are you sure to override it from template?',
  },
  acceptedStatus: {
    id: 'app.modules.personProfile.status.accepted',
    defaultMessage: 'Accepted',
  },
  sentStatus: {
    id: 'app.modules.personProfile.status.sent',
    defaultMessage: 'Sent',
  },
  failedToSendStatus: {
    id: 'app.modules.personProfile.status.failedToSend',
    defaultMessage: 'Failed to send',
  },
  sendingStatus: {
    id: 'app.modules.personProfile.status.sending',
    defaultMessage: 'Sending',
  },
  receivedStatus: {
    id: 'app.modules.personProfile.status.received',
    defaultMessage: 'Received',
  },
  emailSettingsNotConfigured: {
    id: 'app.modules.personProfile.emails.status.emailSettingsNotConfigured',
    defaultMessage: 'Email settings not configured',
  },
  smsSettingsNotConfigured: {
    id: 'app.modules.personProfile.emails.status.smsSettingsNotConfigured',
    defaultMessage: 'SMS settings not configured',
  },

  // Campaigns
  emptyCampaignsListTextMessage: {
    id: 'app.modules.personProfile.campaigns.empty',
    defaultMessage: 'No campaigns yet.',
  },
  noCampaign: {
    id: 'app.modules.personProfile.noCampaign',
    defaultMessage: 'No campaign',
  },

  // Appointments
  emptyAppointmentsListTextMessage: {
    id: 'app.modules.personProfile.appointments.empty',
    defaultMessage: 'No appointments added.',
  },

  // Messages
  membershipIsRejectedTextMessage: {
    id: 'app.modules.personProfile.membership.isRejected',
    defaultMessage: 'Membership offer is rejected',
  },
  autorenewTextMessage: {
    id: 'app.modules.personProfile.membership.autorenew',
    defaultMessage: 'Autorenew',
  },

  // Modals
  // Member Modification History

  memberModificationHistoryTitle: {
    id: 'app.modules.personProfile.sections.memberModificationHistory.modal.title',
    defaultMessage: 'Member Modification History',
  },
  memberEditChangeTypeLabel: {
    id: 'app.modules.personProfile.sections.memberModificationHistory.editChangeType.label',
    defaultMessage: 'Edited profile',
  },
  memberCreateChangeTypeLabel: {
    id: 'app.modules.personProfile.sections.memberModificationHistory.createChangeType.label',
    defaultMessage: 'Created profile',
  },

  // Change plan modal
  changePlanModalTitle: {
    id: 'app.modules.personProfile.membership.changePlan.title',
    defaultMessage: 'Change plan',
  },
  addBundleTitle: {
    id: 'app.modules.personProfile.servicesSection.addBundle.title',
    defaultMessage: 'Add Bundle',
  },

  // Edit more info modal
  editMoreInfoModalTitle: {
    id: 'app.modules.personProfile.membership.editMoreInfo.title',
    defaultMessage: 'Edit More Info',
  },

  // Edit sales info
  editSalesInfoModalTitle: {
    id: 'app.modules.personProfile.salesInfo.editSalesInfo.title',
    defaultMessage: 'Edit Sales Info',
  },

  // Change Package Plan TODO - move here translations that used on TrialMembershipStep
  includedInventoryTitle: {
    id: 'app.modules.personProfile.membership.changePlan.includedInventory.title',
    defaultMessage: 'Included Inventory',
  },
  includedServicesTitle: {
    id: 'app.modules.personProfile.membership.changePlan.includedServices.title',
    defaultMessage: 'Included Services & Service bundles',
  },
  includedFeesTitle: {
    id: 'app.modules.personProfile.membership.changePlan.includedFees.title',
    defaultMessage: 'Included Fees',
  },
  includedServicesDurationTitle: {
    id: 'app.modules.personProfile.membership.changePlan.includedServices.duration.title',
    defaultMessage: 'Duration',
  },
  includedServicesDurationMembers: {
    id: 'app.modules.personProfile.membership.changePlan.includedServices.duration.members',
    defaultMessage: 'Members',
  },
  includedServicesDurationSessions: {
    id: 'app.modules.personProfile.membership.changePlan.includedServices.duration.sessions',
    defaultMessage: 'Sessions',
  },
  includedServicesDurationAmount: {
    id: 'app.modules.personProfile.membership.changePlan.includedServices.duration.amount',
    defaultMessage: 'Amount',
  },
  includedServicesDayAndHoursAccessCustom: {
    id: 'app.modules.personProfile.membership.changePlan.includedServices.accessHours.custom',
    defaultMessage: 'Custom',
  },
  includedServicesDayAndHoursAccessSameAsParent: {
    id:
      'app.modules.personProfile.membership.changePlan..includedServices.accessHours.sameAsParent',
    defaultMessage: 'Same as a parent service',
  },
  includedServicesPaymentTypeTitle: {
    id: 'app.modules.personProfile.membership.changePlan.includedServices.paymentType.title',
    defaultMessage: 'Payment type',
  },
  includedServicesPaymentAmountTitle: {
    id: 'app.modules.personProfile.membership.changePlan.includedServices.paymentAmount.title',
    defaultMessage: 'Amount',
  },
  // Membership card
  cancellationDate: {
    id: 'app.modules.personProfile.sections.profile.membership.card.cancellationDate',
    defaultMessage: 'Cancellation date: {date}',
  },

  // CancelMembershipModal
  cancelMembershipModalTitle: {
    id: 'app.modules.personProfile.sections.profile.membership.cancel.title',
    defaultMessage: 'Cancel Membership',
  },
  cancelBundleTitle: {
    id: 'app.modules.personProfile.sections.profile.servicesSection.cancelBundle.title',
    defaultMessage: 'Cancel Bundle',
  },
  changePlanConfirmationMessage: {
    id:
      'app.modules.personProfile.sections.profile.membership.cancel.changePlanConfirmation.message',
    defaultMessage:
      'Before change plan you need to cancel previous membership, do you want to cancel it now?',
  },
  cancelMembershipReasonStepTitle: {
    id: 'app.modules.personProfile.sections.profile.membership.cancel.reason.step.title',
    defaultMessage: 'Reason',
  },
  cancelMembershipPaymentStepTitle: {
    id: 'app.modules.personProfile.sections.profile.membership.cancel.payment.step.title',
    defaultMessage: 'Payment',
  },
  cancelMembershipReasonRelocationLabel: {
    id: 'app.modules.personProfile.sections.profile.membership.cancel.reason.relocation.label',
    defaultMessage: 'Relocation',
  },
  cancelMembershipReasonDisabilityLabel: {
    id: 'app.modules.personProfile.sections.profile.membership.cancel.reason.disability.label',
    defaultMessage: 'Disability',
  },
  cancelMembershipReasonOtherLabel: {
    id: 'app.modules.personProfile.sections.profile.membership.cancel.reason.other.label',
    defaultMessage: 'Other',
  },
  cancelMembershipModalAcceptedResaleQuestion: {
    id: 'app.modules.personProfile.sections.profile.membership.cancel.accepted.resale.question',
    defaultMessage: 'Has the member accepted resale option?',
  },
  cancelMembershipModalCancellationReasonQuestion: {
    id: 'app.modules.personProfile.sections.profile.membership.cancel.accepted.resale.option',
    defaultMessage: 'What is the Reason of Cancellation?',
  },
  cancelMembershipModalNoFutureBillingsMessage: {
    id:
      'app.modules.personProfile.sections.profile.membership.cancel.billings.noFutureBillings.msg',
    defaultMessage: `There are no future billings for this member`,
  },
  cancelMembershipModalPastDuesOptionsKeep: {
    id: 'app.modules.personProfile.sections.profile.membership.cancel.pastDues.options.keep',
    defaultMessage: `Keep`,
  },
  cancelMembershipModalPastDuesOptionsWaive: {
    id: 'app.modules.personProfile.sections.profile.membership.cancel.pastDues.options.waive',
    defaultMessage: 'Waive',
  },
  cancelMembershipModalConfirmationReasonLabel: {
    id: 'app.modules.personProfile.sections.profile.membership.cancel.confirmation.reason.label',
    defaultMessage: 'Reason of Cancellation',
  },
  cancelMembershipModalConfirmationDateLabel: {
    id: 'app.modules.personProfile.sections.profile.membership.cancel.confirmation.date.label',
    defaultMessage: 'Cancellation date',
  },
  cancelMembershipModalConfirmationTimeLabel: {
    id: 'app.modules.personProfile.sections.profile.membership.cancel.confirmation.time.label',
    defaultMessage: 'Cancellation time',
  },
  cancelMembershipModalConfirmationScheduledBillingsLabel: {
    id: 'app.modules.personProfile.sections.profile.membership.cancel.confirmation.billings.label',
    defaultMessage: 'Selected and modified scheduled billings considering cancellation date',
  },
  cancelMembershipModalConfirmationPastDuesLabel: {
    id: 'app.modules.personProfile.sections.profile.membership.cancel.confirmation.pastDues.label',
    defaultMessage: 'Past due payments resolutions',
  },
  homeClubTimezoneIsDifferentMsg: {
    id:
      'app.modules.personProfile.sections.profile.membership.cancel.homeClubTimezoneIsDifferentMsg.msg',
    defaultMessage:
      "Your timezone view {timezoneViewOffset} is different from member home club's timezone {clubZoneOffset}. Membership will be cancelled at {cancellationDate} for member's home club.",
  },
  // Freeze membership modal
  freezeMembershipModalTitle: {
    id: 'app.modules.personProfile.sections.profile.membership.freezeModal.title',
    defaultMessage: 'Freeze Membership',
  },
  freezeBundleTitle: {
    id: 'app.modules.personProfile.sections.profile.servicesSection.freezeBundle.title',
    defaultMessage: 'Freeze Bundle',
  },
  freezeMembershipModalSuccessBtn: {
    id: 'app.modules.personProfile.sections.profile.membership.freezeModal.success.btn',
    defaultMessage: 'Freeze',
  },
  freezeMembershipModalFreezingTermLabel: {
    id: 'app.modules.personProfile.sections.profile.membership.freezeModal.freezingTerm.label',
    defaultMessage: 'Freezing term',
  },
  freezeMembershipModalFreezeReasonLabel: {
    id: 'app.modules.personProfile.sections.profile.membership.freezeModal.freezeReason.label',
    defaultMessage: 'Freeze reason',
  },
  freezeMembershipModalFreezeHistoryBtnLabel: {
    id: 'app.modules.personProfile.sections.profile.membership.freezeModal.freezeHistory.btn.label',
    defaultMessage: 'Freeze History',
  },
  freezeMembershipModalFreezeTableDescription: {
    id: 'app.modules.personProfile.sections.profile.membership.freezeModal.table.description',
    defaultMessage: 'Services/Bundles to Freeze',
  },
  freezeMembershipModalLastFreezeLabel: {
    id: 'app.modules.personProfile.sections.profile.membership.freezeModal.lastFreeze.label',
    defaultMessage: 'Last freeze',
  },
  freezeMembershipModalFreezeTableFreezeDaysLabel: {
    id: 'app.modules.personProfile.sections.profile.membership.freezeModal.table.freezeDays.label',
    defaultMessage: 'Freeze days',
  },
  freezeMembershipModalFreezeTableFreezePeriodLabel: {
    id:
      'app.modules.personProfile.sections.profile.membership.freezeModal.table.freezePeriod.label',
    defaultMessage: 'Freeze period',
  },
  freezeMembershipModalFreezeTablePriceDescription: {
    id: 'app.modules.personProfile.sections.profile.membership.freezeModal.table.price.description',
    defaultMessage: 'Price during freezing',
  },
  freezeMembershipModalFreezeTableRegularPriceLabel: {
    id: 'app.modules.personProfile.sections.profile.membership.freezeModal.regularPrice.label',
    defaultMessage: 'Regular, No Freeze',
  },
  freezeMembershipModalTableNotFrozenYetLabel: {
    id: 'app.modules.personProfile.sections.profile.membership.freezeModal.notFrozenYet.label',
    defaultMessage: 'Not frozen yet',
  },
  freezeMembershipModalHistoryModalTitleLabel: {
    id: 'app.modules.personProfile.sections.profile.membership.freezeModal.freeze.title.label',
    defaultMessage: 'Freeze history',
  },
  freezeMembershipModalHistoryModalTableNameLabel: {
    id: 'app.modules.personProfile.sections.profile.membership.freezeModal.freeze.title.label',
    defaultMessage: 'Services/Bundles to Freeze',
  },
  freezeMembershipModalHistoryModalTableFreezeReasonLabel: {
    id: 'app.modules.personProfile.sections.profile.membership.freezeModal.freeze.title.label',
    defaultMessage: 'Freeze reason',
  },
  freezeMembershipModalHistoryModalTableLastFreezeLabel: {
    id: 'app.modules.personProfile.sections.profile.membership.freezeModal.freeze.title.label',
    defaultMessage: 'Last freeze',
  },
  freezeMembershipModalHistoryModalTablePricePerBillingLabel: {
    id: 'app.modules.personProfile.sections.profile.membership.freezeModal.freeze.title.label',
    defaultMessage: 'Price during freezing',
  },
  unfreezeLabel: {
    id: 'app.modules.personProfile.sections.profile.membership.freezeModal.unfreeze.label',
    defaultMessage: 'Unfreeze',
  },
  // Past dues modal
  pastDuesModalTitle: {
    id: 'app.modules.personProfile.sections.profile.billing.pastDuesModal.title',
    defaultMessage: 'Past dues',
  },
  pastDuesModalTotalLabel: {
    id: 'app.modules.personProfile.sections.profile.billing.pastDuesModal.total.label',
    defaultMessage: 'Total: {price}',
  },
  pastDuesModalSelectAllLabel: {
    id: 'app.modules.personProfile.sections.profile.billing.pastDuesModal.selectAll.label',
    defaultMessage: 'Select all',
  },
  pastDuesModalNoPastDues: {
    id: 'app.modules.personProfile.sections.profile.billing.pastDuesModal.emptyList.msg',
    defaultMessage: 'There are no payments, that are past due.',
  },
  // Billing schedule modal
  billingScheduleModalTitle: {
    id: 'app.modules.personProfile.sections.profile.billing.billingScheduleModal.title',
    defaultMessage: 'Billing Schedule',
  },
  billingScheduleModalNoBillingSchedule: {
    id: 'app.modules.personProfile.sections.profile.billing.billingScheduleModal.emptyList.msg',
    defaultMessage: 'There are no payments.',
  },
  billingScheduleModalNextPaymentDateLabel: {
    id:
      'app.modules.personProfile.sections.profile.billing.billingScheduleModal.nextPaymentDate.label',
    defaultMessage: 'Next payment date',
  },
  billingScheduleModalDateAdjustmentWarningMessage: {
    id:
      'app.modules.personProfile.sections.profile.billing.billingScheduleModal.dayAdjustment.warning.msg',
    defaultMessage:
      'By adjusting this date you are changing the amount of payments member is obligated for per their contract.',
  },
  billingScheduleModalPushOutRenewalOption: {
    id:
      'app.modules.personProfile.sections.profile.billing.billingScheduleModal.dayAdjustment.pushOutRenewal.option',
    defaultMessage: 'Push out the renewal date',
  },
  billingScheduleModalUpdateAllFutureBillingsOption: {
    id:
      'app.modules.personProfile.sections.profile.billing.billingScheduleModal.updateAllFutureBilling',
    defaultMessage: 'Update all future billing dates',
  },
  billingScheduleModalCollectProtectedAmountOption: {
    id:
      'app.modules.personProfile.sections.profile.billing.billingScheduleModal.dayAdjustment.collectProtectedAmount.option',
    defaultMessage: 'Collect a protected amount',
  },
  // Payment methods modal
  paymentMethodsModalTitle: {
    id: 'app.modules.personProfile.sections.profile.billing.paymentMethodsModal.title',
    defaultMessage: 'Payment methods',
  },
  cardUsedInPaymentsMessage: {
    id: 'app.modules.personProfile.paymentMethods.cardUsedInPaymentsMessage',
    defaultMessage: '( Card used in payments )',
  },
  creditCardsLabel: {
    id: 'app.modules.personProfile.paymentMethods.creditCardsLabel',
    defaultMessage: 'Credit Cards',
  },
  regularPaymentsLabel: {
    id: 'app.modules.personProfile.paymentMethods.regularPaymentsLabel',
    defaultMessage: 'Regular Payments (Subscriptions)',
  },
  inactivePaymentMethodTitle: {
    id: 'app.modules.personProfile.paymentMethods.inactive',
    defaultMessage: '(Inactive)',
  },
  // Top up balance modal
  topUpBalanceTitle: {
    id: 'app.modules.personProfile.sections.profile.topUpBalance.title',
    defaultMessage: 'Account Credit',
  },
  //  Top up rewards modal
  rewardsTitle: {
    id: 'app.modules.personProfile.sections.profile.rewards.title',
    defaultMessage: 'Rewards',
  },
  amountRewardsLabel: {
    id: 'app.modules.personProfile.sections.profile.topUpRewards.modal.amountRewards.label',
    defaultMessage: 'Amount Rewards',
  },
  // Reactivate modal
  reactivateMembershipModalTitle: {
    id: 'app.modules.personProfile.sections.profile.membership.reactivate.title',
    defaultMessage: 'Reactivate Membership',
  },
  reactivateBundleTitle: {
    id: 'app.modules.personProfile.sections.profile.servicesSection.reactivateBundle.title',
    defaultMessage: 'Reactivate Bundle',
  },
  reactivateMembershipSelectPackageStepLabel: {
    id:
      'app.modules.personProfile.sections.profile.membership.reactivate.select.package.step.label',
    defaultMessage: 'Select Services\\Bundles which should be reactivated',
  },
  reactivateMembershipSubMembersStepLabel: {
    id: 'app.modules.personProfile.sections.profile.membership.reactivate.submembers.step.label',
    defaultMessage: 'Select Sub-members which Should be Reactivated',
  },
  reactivateMembershipPastDuesTableDescription: {
    id:
      'app.modules.personProfile.sections.profile.membership.reactivate.past.dues.step.description',
    defaultMessage: 'Services/Bundles which should be waived or kept',
  },
  reactivateMembershipConfirmationServicesLabel: {
    id:
      'app.modules.personProfile.sections.profile.membership.reactivate.confirmation.services.label',
    defaultMessage: 'Services/Bundles to be Reactivated',
  },
  reactivateMembershipConfirmationSubMembersLabel: {
    id:
      'app.modules.personProfile.sections.profile.membership.reactivate.confirmation.sub-members.label',
    defaultMessage: 'Sub-members to be Reactivated',
  },
  reactivateMembershipConfirmationDuesLabel: {
    id: 'app.modules.personProfile.sections.profile.membership.reactivate.confirmation.dues.label',
    defaultMessage: 'Past due payments resolutions',
  },
  reactivateMembershipNoPackagesAvailable: {
    id: 'app.modules.personProfile.sections.profile.membership.reactivate.packages.empty',
    defaultMessage: 'There are no services/bundles available for reactivation.',
  },
  reactivateMembershipNoSubMembersAvailable: {
    id: 'app.modules.personProfile.sections.profile.membership.reactivate.submembers.empty',
    defaultMessage: 'There are no Sub-members available for reactivation.',
  },
  reactivateMembershipNoSubMembersSelected: {
    id: 'app.modules.personProfile.sections.profile.membership.reactivate.submembers.noSelected',
    defaultMessage: 'There are no Sub-members selected for reactivation.',
  },
  facilityNotSet: {
    id: 'app.modules.personProfile.checkInInfo.facilityNoSet',
    defaultMessage: 'Home Club Not Set',
  },
  checkedIn: {
    id: 'app.modules.personProfile.checkedIn',
    defaultMessage: 'Checked in',
  },
  joinedOn: {
    id: 'app.modules.personProfile.joinedOn',
    defaultMessage: 'Joined on',
  },
  hasMobileAppRegistration: {
    id: 'app.modules.personProfile.hasMobileAppRegistration',
    defaultMessage: 'Mobile App Registration',
  },
  // Add family member
  dlgTitle: {
    id: 'app.modules.personProfile.addFamilyMember.dialog.title',
    defaultMessage: 'Add Family Member',
  },
  dlgAddBtn: {
    id: 'app.modules.personProfile.addFamilyMember.dialog.btn.add',
    defaultMessage: 'Add',
  },
  dlgAutocompleteLabel: {
    id: 'app.modules.personProfile.addFamilyMember.dialog.search.label',
    defaultMessage: 'Search members/guests',
  },
  dlgWarning: {
    id: 'app.modules.personProfile.addFamilyMember.dialog.warning',
    defaultMessage: 'Active members can’t be added',
  },
  relationMemberToPerson: {
    id: 'app.modules.personProfile.addFamilyMember.dialog.relationMemberToPerson',
    defaultMessage: 'How {memberFName} relates to {personFName}',
  },
  relationPersonToMember: {
    id: 'app.modules.personProfile.addFamilyMember.dialog.relationPersonToMember',
    defaultMessage: 'How {fromPersonFName} relates to {toPersonFName} (optional)',
  },
  familyMembersBlockTitle: {
    id: 'app.modules.personProfile.addFamilyMember.block.title',
    defaultMessage: 'FAMILY MEMBERS',
  },
  familyMembersBlockAddBtn: {
    id: 'app.modules.personProfile.addFamilyMember.block.add.btn',
    defaultMessage: '+ ADD',
  },

  stepAutocompleteLabel: {
    id: 'app.modules.personProfile.addFamilyMember.step.search.label',
    defaultMessage: 'Search for members/guests',
  },
  stepRelationMemberToPerson: {
    id: 'app.modules.personProfile.addFamilyMember.step.relationMemberToPerson',
    defaultMessage: 'How {memberFName} relates to {personFName}',
  },
  stepRelationPersonToMember: {
    id: 'app.modules.personProfile.addFamilyMember.step.relationPersonToMember',
    defaultMessage: 'How {personFName} relates to {memberFName} (optional)',
  },
  wayOfContact: {
    id: 'app.modules.personProfile.wayOfContact',
    defaultMessage: 'Way of contact',
  },
  salesInfo: {
    id: 'app.modules.personProfile.salesInfo',
    defaultMessage: 'Sales info',
  },
  value: {
    id: 'app.modules.personProfile.value',
    defaultMessage: 'Value',
  },
  dateOfCreation: {
    id: 'app.modules.personProfile.dateOfCreation',
    defaultMessage: 'Date of creation',
  },
  doNotContact: {
    id: 'app.modules.personProfile.doNotContact',
    defaultMessage: 'Do not contact',
  },
  vayOfContact: {
    id: 'app.modules.personProfile.vayOfContact',
    defaultMessage: 'Vay of contact',
  },
  viewFullProfile: {
    id: 'app.modules.personProfile.viewFullProfile',
    defaultMessage: 'View Full Profile',
  },
  assignee: {
    id: 'app.modules.personProfile.assignee',
    defaultMessage: 'Assignee',
  },
  cameFrom: {
    id: 'app.modules.personProfile.cameFrom',
    defaultMessage: 'Came from',
  },
  interests: {
    id: 'app.modules.personProfile.interests',
    defaultMessage: 'Interests',
  },
  potentialClub: {
    id: 'app.modules.personProfile.potentialClub',
    defaultMessage: 'Potential club',
  },
  membershipSubLabel: {
    id: 'app.modules.personProfile.membershipSubLabel',
    defaultMessage: 'Membership',
  },
  additionalSubLabel: {
    id: 'app.modules.personProfile.additionalSubLabel',
    defaultMessage: 'Additional',
  },
  inheritedSubLabel: {
    id: 'app.modules.personProfile.inheritedSubLabel',
    defaultMessage: 'Inherited From Family',
  },
  redeemDescriptionTitle: {
    id: 'app.modules.personProfile.redeem.description.title',
    defaultMessage: 'Notes',
  },
  redeemNoteLabel: {
    id: 'app.modules.personProfile.redeem.description.label',
    defaultMessage: 'Description (optional)',
  },
  redeemPinTitle: {
    id: 'app.modules.personProfile.redeem.pinCode.title',
    defaultMessage: 'Pin code required',
  },
  canceleMembershipSelectPackageStepLabel: {
    id: 'app.modules.personProfile.sections.profile.membership.cancele.select.package.step.label',
    defaultMessage: 'Select Services\\Bundles which should be canceled ',
  },
  canceleMembershipNoPackagesAvailable: {
    id: 'app.modules.personProfile.sections.profile.membership.cancele.packages.empty',
    defaultMessage: 'There are no services/bundles available for cancellation.',
  },
});
