import { fromJS } from 'immutable';
import { QueryPageList } from 'common/constants';
import { CHANGE_QUERY_PARAMS } from 'common/constants/actionTypes';

export type FetchQueryPagePayload = {
  page: QueryPageList;
  query: string;
};

type FetchQueryPageAction = {
  type: typeof CHANGE_QUERY_PARAMS;
  payload: FetchQueryPagePayload;
};

const initQueryPageItem = {
  query: '',
};

const initQueryPagesStateImt = fromJS({
  [QueryPageList.RESOURCES]: initQueryPageItem,
  [QueryPageList.BOOKING]: initQueryPageItem,
  [QueryPageList.TASKS]: initQueryPageItem,
  [QueryPageList.LEADS]: initQueryPageItem,
  [QueryPageList.SCRIPTS]: initQueryPageItem,
  [QueryPageList.CAMPAIGNS]: initQueryPageItem,
  [QueryPageList.EMPLOYEES]: initQueryPageItem,
  [QueryPageList.REGISTER_SETTINGS]: initQueryPageItem,
  [QueryPageList.TAX_RATES_SETTINGS]: initQueryPageItem,
  [QueryPageList.REVENUE_CODES_SETTINGS]: initQueryPageItem,
  [QueryPageList.VENDORS_SETTINGS]: initQueryPageItem,
  [QueryPageList.INVENTORY_SETTINGS]: initQueryPageItem,
  [QueryPageList.INVENTORY_CONFIGURATION_SETTINGS]: initQueryPageItem,
  [QueryPageList.RECEIPTS_SETTINGS]: initQueryPageItem,
  [QueryPageList.RECEIPTS_CONFIGURATION_SETTINGS]: initQueryPageItem,
  [QueryPageList.PAYMENT_METHODS_DEFAULT_SETTINGS]: initQueryPageItem,
  [QueryPageList.PAYMENT_METHODS_CUSTOM_SETTINGS]: initQueryPageItem,
  [QueryPageList.PAYMENT_METHODS_CUSTOM_LIST_SETTINGS]: initQueryPageItem,
  [QueryPageList.PAYMENT_METHODS_CUSTOM_ITEM_CONFIGURATION_SETTINGS]: initQueryPageItem,
  [QueryPageList.INVOICE_HISTORY_REPORT]: initQueryPageItem,
  [QueryPageList.PAYMENT_TRANSACTIONS_REPORT]: initQueryPageItem,
  [QueryPageList.Z_OUT_REPORT]: initQueryPageItem,
  [QueryPageList.CAMPAIGNS_REPORT]: initQueryPageItem,
  [QueryPageList.DOCUMENT_TEMPLATES]: initQueryPageItem,
  [QueryPageList.CRM_TEMPLATES]: initQueryPageItem,
  [QueryPageList.SERVICE_PACKAGES]: initQueryPageItem,
  [QueryPageList.CORPORATIONS]: initQueryPageItem,
  [QueryPageList.CLUBS]: initQueryPageItem,
  [QueryPageList.SERVICE_DOCUMENT_TEMPLATES]: initQueryPageItem,
});

export type InitQueryPagesStateImt = typeof initQueryPagesStateImt;

export const queryPageListReducer = (
  state = initQueryPagesStateImt,
  action: FetchQueryPageAction,
): InitQueryPagesStateImt => {
  switch (action.type) {
    case CHANGE_QUERY_PARAMS:
      const { query } = action.payload;
      return state.setIn([action.payload.page, 'query'], fromJS(query));
    default:
      return state;
  }
};
