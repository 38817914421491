import React, { useCallback, useMemo, useRef } from 'react';
import Dropzone, { Accept, DropEvent, DropzoneRef, FileRejection } from 'react-dropzone';
import { Box, fade, FormHelperText, Grid, IconButton, Link, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import classNames from 'classnames';
import { Variant } from '@material-ui/core/styles/createTypography';
import { FormattedMessage } from 'react-intl';

import { CustomTheme } from 'common/ui/interfaces';
import commonMessages from 'common/messages/messages';
import commonFileErrorMessages from 'common/messages/fileErrors';
import { ReactComponent as TrashIcon } from 'img/icons/trash_deprecated.svg';
import { ReactComponent as DownloadDocIcon } from 'img/icons/download-doc.svg';
import { formatFileSize } from 'common/utils/format';
import { LoadingBackdrop, ScrollBox, Table } from 'common/components/index';
import { IAttachment, IUploaderCustomError } from 'common/interfaces/uploadFile';
import { IHeadCell, ITableRow } from 'common/interfaces/table';
import tableHeaders from 'common/messages/tableHeaders';
import { MAX_FILE_SIZE } from 'common/constants';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { FileErrorTypes } from 'common/components/FilesUploader/constants';

interface IFileUploaderProps<T> {
  onUploadFiles?: (files: T[], uploadFiles: File[], deletedFile?: T) => void;
  onRejected?: (errors: IUploaderCustomError[], event?: DropEvent) => void;
  height: string;
  isMultiple?: boolean;
  onToggleWebcamModal: () => void;
  isLoading?: boolean;
  files?: T[];
  hasVisibleScanDocumentBtn?: boolean;
  hasVisibleTakePhotoBtn?: boolean;
  hasVisibleDownloadBtn?: boolean;
  error?: FieldErrors;
  filesListTextVariant?: Variant;
  showTableHeader?: boolean;
  acceptFileTypes?: Accept;
  dropzoneClassName?: string;
  className?: string;
  hideDropzone?: boolean;
  hideDeleteBtn?: boolean;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    borderRadius: '5px',
    width: '100%',
  },
  dropzone: {
    border: `1px dashed ${theme.palette.primary.main}`,
  },
  dropzoneActive: {
    border: `2px dashed ${theme.palette.primary.main}`,
    background: theme.palette.primary.light,
  },
  dropzoneActiveText: {
    fontSize: '24px',
    color: fade(theme.palette.primary.main, 0.5),
    fontWeight: 700,
  },
  file: {
    borderBottom: `1px solid ${theme.palette.borderColor.main}`,

    '&:first-child': {
      borderTop: `1px solid ${theme.palette.borderColor.main}`,
    },

    '&>p:first-child': {
      width: '50%',
    },

    '& svg': {
      cursor: 'pointer',
    },
  },
  actionButton: {
    textTransform: 'unset',
  },
}));

const headerOptions: IHeadCell[] = [
  { id: 'name', label: <FormattedMessage {...tableHeaders.name} />, sort: false },
  { id: 'size', label: <FormattedMessage {...tableHeaders.size} />, align: 'center', sort: false },
  { id: 'downloadAction', label: '', sort: false },
  { id: 'deleteAction', label: '', sort: false },
];

function FilesDropzone<T extends File | IAttachment>(props: IFileUploaderProps<T>) {
  const {
    onUploadFiles,
    onRejected,
    height,
    isMultiple = false,
    onToggleWebcamModal,
    files = [],
    error,
    showTableHeader = false,
    hasVisibleScanDocumentBtn = true,
    hasVisibleTakePhotoBtn = true,
    acceptFileTypes,
    isLoading,
    className,
    dropzoneClassName,
    hasVisibleDownloadBtn,
    filesListTextVariant = 'body2',
    hideDropzone,
    hideDeleteBtn,
  } = props;
  const dropzoneRef = useRef<DropzoneRef>();
  const renderIntlMessage = useRenderIntlMessage();

  const classes = useStyles();

  const onDragFileAccepted = (uploadFiles: File[]) => {
    const fileKeys = uploadFiles.map(item => `${item.name}${item.size}`);
    const isSomeFile = files?.some((item: T) => {
      return fileKeys.includes(
        `${item.name}${(item as File).size || (item as IAttachment).sizeInBytes}`,
      );
    });

    if (isSomeFile) {
      onRejected([{ id: '', error: '', customError: commonFileErrorMessages.duplicateFileError }]);
    } else if (onUploadFiles) {
      onUploadFiles(files, uploadFiles);
    }
  };

  const onDragFileRejected = (uploadFiles: FileRejection[], event: DropEvent) => {
    const fileErrors = uploadFiles.map(({ errors, file }) => {
      const { code, message } = errors[0];

      const id = `${file.name}${file.size}`;

      const errorObj = {
        id,
        error: message,
        customError: commonFileErrorMessages.fileInvalidTypeError,
      };

      errorObj.customError = FileErrorTypes.message(code);

      return errorObj;
    });

    if (onRejected) onRejected(fileErrors, event);
  };

  const deleteFile = useCallback(
    (index: number) => {
      const copyFiles = [...files];
      const file = copyFiles.splice(index, 1);

      if (onUploadFiles) {
        onUploadFiles(copyFiles, [], file[0]);
      }
    },
    [files, onUploadFiles],
  );

  const onOpenDownloadWindow = (e): void => {
    e.preventDefault();
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  const rows: ITableRow[] = useMemo(
    () =>
      files?.map(
        (file, index): ITableRow => {
          const fileId = 'id' in file ? file.id : file.name;

          const downloadButton =
            hasVisibleDownloadBtn && 'id' in file ? (
              <a download href={file.filePath}>
                <IconButton color="secondary">
                  <DownloadDocIcon width={20} height={20} />
                </IconButton>
              </a>
            ) : null;

          const deleteBtn = !hideDeleteBtn && (
            <IconButton color="secondary">
              <TrashIcon width={20} height={20} onClick={() => deleteFile(index)} />
            </IconButton>
          );

          return {
            id: fileId,
            cells: [
              {
                label: 'name',
                ellipsis: true,
                padding: 'none',
                cellComponent: (
                  <Typography color="primary" variant={filesListTextVariant}>
                    {file.name}
                  </Typography>
                ),
              },
              {
                label: 'size',
                align: 'left',
                customPadding: '0 24px 0 0',
                ellipsis: true,
                cellComponent: (
                  <Typography noWrap color="secondary" variant={filesListTextVariant}>
                    {formatFileSize((file as File).size || (file as IAttachment).sizeInBytes)}
                  </Typography>
                ),
              },
              {
                label: '',
                align: 'right',
                padding: 'none',
                ellipsis: true,
                width: '47px',
                cellComponent: downloadButton,
              },
              {
                label: '',
                align: 'right',
                ellipsis: true,
                padding: 'none',
                width: hideDeleteBtn ? '0px' : '47px',
                cellComponent: deleteBtn,
              },
            ],
          };
        },
      ),
    [deleteFile, files, filesListTextVariant, hasVisibleDownloadBtn, hideDeleteBtn],
  );

  return (
    <Box className={className} position="relative">
      <LoadingBackdrop isLoading={isLoading} />
      {!hideDropzone && (
        <Dropzone
          accept={acceptFileTypes}
          ref={dropzoneRef}
          noClick
          maxSize={MAX_FILE_SIZE}
          multiple={isMultiple}
          onDropAccepted={onDragFileAccepted}
          onDropRejected={onDragFileRejected}
        >
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <Box>
                <input {...getInputProps()} />
                <Box
                  {...getRootProps()}
                  height={height}
                  borderRadius="5px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  className={classNames(classes.dropzone, dropzoneClassName, {
                    [classes.dropzoneActive]: isDragActive,
                  })}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="space-between"
                    minHeight={isDragActive ? 'auto' : '40px'}
                  >
                    {isDragActive ? (
                      <Box className={classes.dropzoneActiveText} component="span">
                        <FormattedMessage {...commonMessages.uploadFieldBody} />
                      </Box>
                    ) : (
                      <>
                        <Box display="flex" alignItems="center">
                          <Typography variant="h5" color="textSecondary">
                            <FormattedMessage {...commonMessages.uploadFieldBodyFirstPart} />
                          </Typography>
                          &nbsp;
                          <Typography variant="body1" color="textSecondary">
                            <FormattedMessage {...commonMessages.uploadFieldBodySecondPart} />
                          </Typography>
                        </Box>
                        <Grid container justifyContent="center" spacing={2}>
                          <Grid item>
                            <Link
                              className={classes.actionButton}
                              component="button"
                              onClick={onOpenDownloadWindow}
                              underline="none"
                              type="button"
                            >
                              <Typography variant="h5" color="inherit">
                                <FormattedMessage {...commonMessages.browseForFilesBtn} />
                              </Typography>
                            </Link>
                          </Grid>
                          {hasVisibleTakePhotoBtn && (
                            <Grid item>
                              <Link
                                className={classes.actionButton}
                                component="button"
                                onClick={onToggleWebcamModal}
                                underline="none"
                                type="button"
                              >
                                <Typography variant="h5" color="inherit">
                                  <FormattedMessage {...commonMessages.takePhotoBtn} />
                                </Typography>
                              </Link>
                            </Grid>
                          )}
                          {hasVisibleScanDocumentBtn && (
                            <Grid item>
                              <Link
                                className={classes.actionButton}
                                component="button"
                                underline="none"
                                type="button"
                              >
                                <Typography variant="h5" color="inherit">
                                  <FormattedMessage {...commonMessages.scanDocumentBtn} />
                                </Typography>
                              </Link>
                            </Grid>
                          )}
                        </Grid>
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
            );
          }}
        </Dropzone>
      )}

      {error && <FormHelperText error>{renderIntlMessage(error.message)}</FormHelperText>}

      {!!files?.length && (
        <Box height="100%" maxHeight="260px" mt={hideDropzone ? 0 : 2}>
          <ScrollBox>
            <Table
              defaultToolbar={false}
              hideSearchInput
              hideToolbar
              hidePagination
              hidePadding
              rows={rows}
              headerOptions={showTableHeader ? headerOptions : []}
              isLoading={false}
              suppressFiltersMobilePadding
              suppressSmartBehavior
            />
          </ScrollBox>
        </Box>
      )}
    </Box>
  );
}

export default FilesDropzone;
