import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { FormProvider, useForm } from 'react-hook-form';
import { List as ImmutableList } from 'immutable';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, makeStyles, SvgIcon, Typography } from '@material-ui/core';
import { Cancel as CancelIcon } from '@material-ui/icons';
import { ReactComponent as AddIcon } from 'img/icons/add.svg';

import * as actions from 'common/components/PersonProfile/state/actions';
import {
  addCheckingSavingsDataActionResult,
  addCheckingSavingsDataThunk,
} from 'common/components/PersonProfile/state/actions';
import * as selectors from 'common/components/PersonProfile/state/selectors';
import {
  selectAddCheckingSavingsDataActionResult,
  selectAddCheckingSavingsDataLoading,
} from 'common/components/PersonProfile/state/selectors';

import {
  IPackageInstanceSubscription,
  IPackageInstanceSubscriptionImt,
  IPackageInstanceSubscriptionUpdateDTO,
  IPaymentAccountImt,
} from 'common/components/PersonProfile/interfaces';
import { CustomTheme } from 'common/ui/interfaces';
import { AlertCondition } from 'common/interfaces/alerts';
import { IPaymentMethodItemImt } from 'modules/pos-settings/interfaces/paymentMethods';

import { ActionResult } from 'common/constants';

import { usePersonSelectorTemplate } from 'common/components/PersonProfile/hooks/usePersonSelector';

import { getCreditCardIcon } from 'common/components/CreditCardData/constants';
import { getRequiredMessage } from 'common/constants/globalConstants';
import { getExpiryDate } from 'common/utils/time';

import { Button, DialogComponent, LoadingBackdrop } from 'common/components';
import SubscriptionsTable from './SubscriptionsTable';

import messages from 'common/components/PersonProfile/messages';
import inputErrors from 'common/messages/inputErrors';
import commonMessages from 'common/messages/messages';
import AddCreditCardModal from '../AddCreditCardModal/AddCreditCardModal';
import AddCheckingSavingsModal from '../AddCheckingSavingsModal/AddCheckingSavingsModal';
import CheckingSavingsItem from '../../components/CheckingSavingsItem/CheckingSavingsItem';
import { PaymentsType } from 'common/interfaces/invoices';
import { PeakModules } from 'common/constants/peakModules';
import { PaymentProcessorType } from '../../../../../modules/corporate-settings/interfaces';
import { selectCurrentPaymentProcessor } from '../../../../../modules/authentication/state/selectors';

const useStyles = makeStyles((theme: CustomTheme) => ({
  noPaymentMethodsBox: {
    height: '200px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardNumber: {
    margin: theme.spacing(0, 1, 0, 1.5),
  },
  expDate: {
    marginRight: theme.spacing(1),
  },
  expTitle: {
    marginLeft: theme.spacing(1),
  },
  addIcon: {
    width: '16px',
    height: '16px',
  },
  addButton: {
    marginTop: theme.spacing(2),
  },
}));

const ValidationSchema = yup.object().shape({
  subscriptions: yup.array().of(
    yup.object().shape({
      paymentMethod: yup
        .object()
        .nullable()
        .required(getRequiredMessage),
      paymentAccount: yup
        .object()
        .when('paymentMethod', {
          is: paymentMethod => paymentMethod?.type === PaymentsType.CREDIT_CARD,
          then: yup
            .object()
            .test(
              'isInactiveCreditCard',
              () => inputErrors.inactiveCardError,
              value => {
                if (value) {
                  return !value.deleted;
                }

                return true;
              },
            )
            .required(getRequiredMessage)
            .nullable(),
          otherwise: yup.object().nullable(),
        })
        .test(
          'expired',
          () => inputErrors.expiredCardError,
          value => {
            if (value) {
              return !value.expired;
            }

            return true;
          },
        ),
      checking: yup.object().when('paymentMethod', {
        is: paymentMethod => paymentMethod?.type === PaymentsType.CHECKING_SAVINGS,
        then: yup
          .object()
          .test(
            'isInactiveChecking',
            () => inputErrors.inactiveCheckingSavingsError,
            value => {
              if (value) {
                return !value.deleted;
              }

              return true;
            },
          )
          .nullable()
          .required(getRequiredMessage),
        otherwise: yup.object().nullable(),
      }),
    }),
  ),
});

interface IPaymentMethodsModalProps {
  isOpen: boolean;
  personId: string;

  onSuccess: () => void;
  onCancel: () => void;
  module: PeakModules;
  alert?: AlertCondition;
}

const PaymentMethodsModal: FunctionComponent<IPaymentMethodsModalProps> = ({
  isOpen,
  personId,
  onSuccess,
  onCancel,
  alert,
  module,
}: IPaymentMethodsModalProps) => {
  // state
  const dispatch = useDispatch();

  const usePersonSelector = usePersonSelectorTemplate(personId);

  const storedCreditCards: ImmutableList<IPaymentAccountImt> = usePersonSelector(
    selectors.selectStoredCreditCards,
  );
  const personSubscriptions: ImmutableList<IPackageInstanceSubscriptionImt> = usePersonSelector(
    selectors.selectPersonSubscriptions,
  );
  const paymentMethods: ImmutableList<IPaymentMethodItemImt> = usePersonSelector(
    selectors.selectPersonPaymentMethods,
  );
  const isPaymentMethodsModalDataLoading: boolean = usePersonSelector(
    selectors.selectPaymentMethodsModalDataLoading,
  );
  const addCreditCardResult: ActionResult = usePersonSelector(selectors.selectAddCreditCardResult);
  const addCreditCardResultLoading: boolean = usePersonSelector(
    selectors.selectAddCreditCardResultLoading,
  );
  const deleteCardResult: ActionResult = usePersonSelector(selectors.selectDeleteCardResult);
  const deleteCardResultLoading: boolean = usePersonSelector(
    selectors.selectDeleteCardResultLoading,
  );
  const updateSubscriptionsLoading: boolean = usePersonSelector(
    selectors.selectUpdatePersonSubscriptionsActionLoading,
  );
  const updateSubscriptionsActionResult: ActionResult = usePersonSelector(
    selectors.selectUpdatePersonSubscriptionsActionResult,
  );
  const checkingSavingsData = useSelector(selectors.selectCheckingSavingsData(personId));
  const isFetchCheckingSavingsDataLoading = useSelector(
    selectors.selectFetchCheckingSavingsDataLoading(personId),
  );
  const isDeleteCheckingSavingsDataLoading = useSelector(
    selectors.selectDeleteCheckingSavingsItemLoading(personId),
  );
  const checkingSavingsDeleteActionResult = useSelector(
    selectors.selectDeleteCheckingSavingsItemActionResult(personId),
  );
  const isAddCheckingSavingsDataLoading = useSelector(
    selectAddCheckingSavingsDataLoading(personId),
  );
  const checkingSavingsAddActionResult = useSelector(
    selectAddCheckingSavingsDataActionResult(personId),
  );
  const paymentProcessorType = useSelector(selectCurrentPaymentProcessor);

  const [isNewCardInputDisabled, setIsNewCardInputDisabled] = useState<boolean>(false);
  const [isOpenAddCreditCardModal, setIsOpenAddCreditCardModal] = useState<boolean>(false);
  const [isOpenCheckingSavingsModal, setIsOpenCheckingSavingsModal] = useState<boolean>(false);

  const formMethods = useForm({
    defaultValues: { subscriptions: [] },
    resolver: yupResolver(ValidationSchema),
    mode: 'onChange',
  });

  const { reset, handleSubmit } = formMethods;

  const classes = useStyles();

  // lifecycle

  useEffect(() => {
    if (personId) {
      dispatch(actions.fetchPaymentMethodsModalData(personId, module));
    }

    return () => {
      dispatch(actions.resetPersonSubscriptionsAction(null, personId));
      dispatch(actions.resetCheckingSavingsData(null, personId));
    };
  }, [dispatch, personId, module]);

  useEffect(() => {
    if (checkingSavingsAddActionResult === ActionResult.SUCCESS_ACTION) {
      dispatch(addCheckingSavingsDataActionResult(null, personId));
      setIsOpenCheckingSavingsModal(false);
    }
  }, [setIsOpenCheckingSavingsModal, personId, dispatch, checkingSavingsAddActionResult]);

  useEffect(() => {
    if (addCreditCardResult === ActionResult.SUCCESS_ACTION) {
      dispatch(actions.fetchPersonStoredCreditCards(personId));
      dispatch(actions.storeCreditCardResultAction(null, personId));

      if (alert) {
        dispatch(actions.resolvePersonMissingBillingInfoAction(alert, personId));
      }

      setIsNewCardInputDisabled(false);
    }
  }, [addCreditCardResult, alert, dispatch, personId]);

  useEffect(() => {
    if (updateSubscriptionsActionResult === ActionResult.SUCCESS_ACTION) {
      dispatch(actions.resetUpdatePersonSubscriptionsActionResult(null, personId));
      onSuccess();
    }
  }, [dispatch, onSuccess, personId, updateSubscriptionsActionResult]);

  useEffect(() => {
    if (deleteCardResult === ActionResult.SUCCESS_ACTION) {
      dispatch(actions.resetDeletePaymentMethodCardResultAction(null, personId));
    }

    if (deleteCardResult === ActionResult.SUCCESS_ACTION && !storedCreditCards.size) {
      dispatch(actions.fetchMemberAlerts(personId));
    }
  }, [deleteCardResult, dispatch, personId, storedCreditCards]);

  useEffect(() => {
    if (personSubscriptions?.size) {
      reset({ subscriptions: personSubscriptions.toJS() });
    }
  }, [personSubscriptions, reset]);

  // handlers
  const handleAddCard = values => {
    switch (paymentProcessorType) {
      case PaymentProcessorType.COMMERCEHUB:
        dispatch(actions.tokenizeCommerceHubEncryptedCard(personId, values));
        break;
      case PaymentProcessorType.ITRANSACT:
        dispatch(actions.storeCreditCard(personId, values, module));
        break;
      case PaymentProcessorType.DISABLED:
        throw new Error(`Payment processor is: ${paymentProcessorType}`);
      default:
        throw new Error(`Unsupported payment processor type: ${paymentProcessorType}`);
    }
  };

  const handleDeleteCard = cardId => {
    dispatch(actions.deletePaymentMethodCard(personId, cardId, module));
  };

  const onSubmitForm = formValues => {
    if (formValues.subscriptions?.length) {
      const transformedDTO: IPackageInstanceSubscriptionUpdateDTO[] = formValues.subscriptions.map(
        ({
          packageInstance,
          paymentMethod,
          paymentAccount,
          checking,
        }: IPackageInstanceSubscription) => {
          return {
            paymentAccountId: checking?.id || paymentAccount?.id || null,
            packageInstanceId: packageInstance.packageInstanceId,
            paymentMethodId: paymentMethod.id,
          };
        },
      );

      dispatch(actions.updatePersonSubscriptions(personId, transformedDTO, module));
    } else {
      onSuccess();
    }
  };

  const onAddCheckingSavingsSubmit = values => {
    dispatch(addCheckingSavingsDataThunk(personId, values, module));
  };

  const onDeleteCheckingSavingsItem = (checkingId: string) => {
    dispatch(actions.deleteCheckingSavingsItemThunk(personId, checkingId, module));
  };

  return (
    <DialogComponent
      isOpen={isOpen}
      size="lg"
      onSubmit={handleSubmit(onSubmitForm)}
      onClose={onCancel}
      title={<FormattedMessage {...messages.paymentMethodsModalTitle} />}
      formId="subscriptions-form"
    >
      <Typography component="p" variant="button" color="textSecondary">
        <FormattedMessage {...messages.creditCardsLabel} />
      </Typography>

      {storedCreditCards?.map(cardItem => {
        return (
          <Box key={cardItem.get('id')} display="flex" alignItems="center" marginY={1.5}>
            <SvgIcon
              fontSize="large"
              component={getCreditCardIcon(cardItem.get('creditCardType'))}
            />
            <Typography className={classes.cardNumber}>{`**** ${cardItem.get(
              'lastFour',
            )}`}</Typography>

            <Typography
              color={cardItem.get('expired') ? 'error' : 'initial'}
              className={classes.expDate}
            >
              <Typography component="span">{getExpiryDate(cardItem.get('expDate'))}</Typography>

              {cardItem.get('expired') && (
                <Typography component="span" className={classes.expTitle}>
                  <FormattedMessage {...commonMessages.expired} />
                </Typography>
              )}
            </Typography>

            <Button
              disabled={isNewCardInputDisabled}
              color="secondary"
              size="small"
              onClick={() => handleDeleteCard(cardItem.get('id'))}
            >
              <CancelIcon />
              &nbsp;
              {cardItem.get('inUse') && (
                <FormattedMessage {...messages.cardUsedInPaymentsMessage} />
              )}
            </Button>
          </Box>
        );
      })}

      <Button
        startIcon={<AddIcon className={classes.addIcon} />}
        color="primary"
        onClick={() => setIsOpenAddCreditCardModal(true)}
        className={classes.addButton}
      >
        <FormattedMessage {...commonMessages.addCreditCardLabel} />
      </Button>

      <Box mt={5}>
        <Typography component="p" variant="button" color="textSecondary">
          <FormattedMessage {...commonMessages.checkingSavingsLabel} />
        </Typography>

        {Boolean(checkingSavingsData?.size) && (
          <Box mt={2} mb={2}>
            {checkingSavingsData?.map(item => (
              <CheckingSavingsItem
                onDelete={() => onDeleteCheckingSavingsItem(item.get('id'))}
                item={item}
              />
            ))}
          </Box>
        )}

        <Button
          startIcon={<AddIcon className={classes.addIcon} />}
          color="primary"
          onClick={() => setIsOpenCheckingSavingsModal(true)}
          className={classes.addButton}
        >
          <FormattedMessage {...commonMessages.addCheckingSavingsLabel} />
        </Button>
      </Box>

      <Box mt={5}>
        <Typography variant="button" color="textSecondary">
          <FormattedMessage {...messages.regularPaymentsLabel} />
        </Typography>

        <FormProvider {...formMethods}>
          <form id="subscriptions-form">
            <SubscriptionsTable
              isNewCardInputDisabled={isNewCardInputDisabled}
              paymentMethods={paymentMethods}
              storedCreditCards={storedCreditCards}
              deleteCardActionResult={deleteCardResult}
              checkingSavingsDeleteActionResult={checkingSavingsDeleteActionResult}
              checkingSavingsData={checkingSavingsData}
              personId={personId}
              setIsOpenAddCreditCardModal={setIsOpenAddCreditCardModal}
              setIsOpenCheckingSavingsModal={setIsOpenCheckingSavingsModal}
            />
          </form>
        </FormProvider>
      </Box>
      <LoadingBackdrop
        isLoading={
          isPaymentMethodsModalDataLoading ||
          addCreditCardResultLoading ||
          deleteCardResultLoading ||
          updateSubscriptionsLoading ||
          isFetchCheckingSavingsDataLoading ||
          isDeleteCheckingSavingsDataLoading
        }
      />

      {isOpenAddCreditCardModal && (
        <AddCreditCardModal
          withScanning
          isOpen={isOpenAddCreditCardModal}
          onClose={() => setIsOpenAddCreditCardModal(false)}
          onCardAdd={handleAddCard}
        />
      )}

      {isOpenCheckingSavingsModal && (
        <AddCheckingSavingsModal
          onSubmit={onAddCheckingSavingsSubmit}
          isOpen={isOpenCheckingSavingsModal}
          onClose={() => setIsOpenCheckingSavingsModal(false)}
          isLoading={isAddCheckingSavingsDataLoading}
        />
      )}
    </DialogComponent>
  );
};

export default PaymentMethodsModal;
