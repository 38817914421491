import React from 'react';
import { FormattedMessage } from 'react-intl';

import messages from 'modules/corporations/messages';
import clubMessages from 'modules/clubs/messages';
import commonMessages from 'common/messages/messages';

export const actionTypes = {
  // Corporation action types
  FETCH_CORPORATIONS_LIST_LOADING: 'FETCH_CORPORATIONS_LIST_LOADING',
  FETCH_CORPORATIONS_LIST_SUCCESS: 'FETCH_CORPORATIONS_LIST_SUCCESS',
  RESET_CORPORATIONS_LIST: 'RESET_CORPORATIONS_LIST',

  CREATE_CORPORATION_LOADING: 'CREATE_CORPORATION_LOADING',
  CREATE_CORPORATION_SUCCESS: 'CREATE_CORPORATION_SUCCESS',
  RESET_CREATED_CORPORATION: 'RESET_CREATED_CORPORATION',

  FETCH_CORPORATION_GENERAL_INFO_VIEW: 'FETCH_CORPORATION_GENERAL_INFO_VIEW',
  FETCH_CORPORATION_GENERAL_INFO_VIEW_LOADING: 'FETCH_CORPORATION_GENERAL_INFO_VIEW_LOADING',
  RESET_CORPORATION_GENERAL_INFO_VIEW: 'RESET_CORPORATION_GENERAL_INFO_VIEW',

  FETCH_CORPORATION_GENERAL_INFO: 'FETCH_CORPORATION_GENERAL_INFO',
  FETCH_CORPORATION_GENERAL_INFO_LOADING: 'FETCH_CORPORATION_GENERAL_INFO_LOADING',
  RESET_CORPORATION_GENERAL_INFO: 'RESET_CORPORATION_GENERAL_INFO',

  FETCH_CORPORATION_STEP_INFO: 'FETCH_CORPORATION_STEP_INFO',
  FETCH_CORPORATION_STEP_INFO_LOADING: 'FETCH_CORPORATION_STEP_INFO_LOADING',
  RESET_CORPORATION_STEP_INFO: 'RESET_CORPORATION_STEP_INFO',

  FETCH_CORPORATION_BANK_INFO_VIEW: 'FETCH_CORPORATION_BANK_INFO_VIEW',
  FETCH_CORPORATION_BANK_INFO_VIEW_LOADING: 'FETCH_CORPORATION_BANK_INFO_VIEW_LOADING',
  RESET_CORPORATION_BANK_INFO_VIEW: 'RESET_CORPORATION_BANK_INFO_VIEW',

  UPDATE_CORPORATION_STEP_INFO: 'UPDATE_CORPORATION_STEP_INFO',
  UPDATE_CORPORATION_STEP_INFO_LOADING: 'UPDATE_CORPORATION_STEP_INFO_LOADING',

  UPDATE_REQUIRED_PROFILE_INFO: 'UPDATE_REQUIRED_PROFILE_INFO',

  UPDATE_CORPORATION_GENERAL_INFO_ACTION_RESULT: 'UPDATE_CORPORATION_GENERAL_INFO_ACTION_RESULT',

  UPDATE_CORPORATION_GENERAL_STEP_INFO: 'UPDATE_CORPORATION_GENERAL_STEP_INFO',
  UPDATE_CORPORATION_BANK_INFO_STEP: 'UPDATE_CORPORATION_BANK_INFO_STEP',
  UPDATE_CORPORATION_INTEGRATIONS_STEP: 'UPDATE_CORPORATION_INTEGRATIONS_STEP',

  RESET_UPDATED_CORPORATION_STEP_INFO_RESULT: 'RESET_UPDATED_CORPORATION_STEP_INFO_RESULT',

  CHANGE_CORPORATION_STATUS: 'CHANGE_CORPORATION_STATUS',
  CHANGE_CORPORATION_STATUS_LOADING: 'CHANGE_CORPORATION_STATUS_LOADING',
  RESET_CORPORATION_CHANGE_STATUS_RESULT: 'RESET_CORPORATION_CHANGE_STATUS_RESULT',

  FETCH_CORPORATIONS_PAY_PERIOD_LOADING: 'FETCH_CORPORATIONS_PAY_PERIOD_LOADING',
  FETCH_CORPORATIONS_PAY_PERIOD_SUCCESS: 'FETCH_CORPORATIONS_PAY_PERIOD_SUCCESS',
  RESET_CORPORATIONS_PAY_PERIOD: 'RESET_CORPORATIONS_PAY_PERIOD',

  FETCH_ALL_PAY_PERIOD_SETTINGS_LOADING: 'FETCH_ALL_PAY_PERIOD_SETTINGS_LOADING',
  FETCH_ALL_PAY_PERIOD_SETTINGS_SUCCESS: 'FETCH_ALL_PAY_PERIOD_SETTINGS_SUCCESS',
  RESET_ALL_PAY_PERIOD_SETTINGS: 'RESET_ALL_PAY_PERIOD_SETTINGS',

  CREATE_PAY_PERIOD_SETTING_LOADING: 'CREATE_PAY_PERIOD_SETTING_LOADING',
  CREATE_PAY_PERIOD_SETTING_SUCCESS: 'CREATE_PAY_PERIOD_SETTING_SUCCESS',
  RESET_CREATE_PAY_PERIOD_SETTING: 'RESET_CREATE_PAY_PERIOD_SETTING',

  GET_PAY_PERIOD_SETTING_INFO_LOADING: 'GET_PAY_PERIOD_SETTING_INFO_LOADING',
  GET_PAY_PERIOD_SETTING_INFO_SUCCESS: 'GET_PAY_PERIOD_SETTING_INFO_SUCCESS',
  RESET_GET_PAY_PERIOD_SETTING_INFO: 'RESET_GET_PAY_PERIOD_SETTING_INFO',

  UPDATE_PAY_PERIOD_SETTING_INFO_LOADING: 'UPDATE_PAY_PERIOD_SETTING_INFO_LOADING',
  UPDATE_PAY_PERIOD_SETTING_INFO_SUCCESS: 'UPDATE_PAY_PERIOD_SETTING_INFO_SUCCESS',
  RESET_UPDATE_PAY_PERIOD_SETTING_INFO: 'RESET_UPDATE_PAY_PERIOD_SETTING_INFO',

  GET_PAY_PERIOD_BY_ID_LOADING: 'GET_PAY_PERIOD_BY_ID_LOADING',
  GET_PAY_PERIOD_BY_ID_SUCCESS: 'GET_PAY_PERIOD_BY_ID_SUCCESS',
  RESET_GET_PAY_PERIOD_BY_ID: 'RESET_GET_PAY_PERIOD_BY_ID',

  UPDATE_PAY_PERIOD_BY_ID_LOADING: 'UPDATE_PAY_PERIOD_BY_ID_LOADING',
  UPDATE_PAY_PERIOD_BY_ID_SUCCESS: 'UPDATE_PAY_PERIOD_BY_ID_SUCCESS',
  RESET_UPDATE_PAY_PERIOD_BY_ID: 'RESET_GET_PAY_PERIOD_BY_ID',

  CHANGE_PAY_PERIOD_STATUS_LOADING: 'CHANGE_PAY_PERIOD_STATUS_LOADING',
  CHANGE_PAY_PERIOD_STATUS_SUCCESS: 'CHANGE_PAY_PERIOD_STATUS_SUCCESS',
  RESET_CHANGE_PAY_PERIOD_STATUS: 'RESET_CHANGE_PAY_PERIOD_STATUS',

  DEACTIVATE_PAY_PERIODS_LOADING: 'DEACTIVATE_PAY_PERIODS_LOADING',
  DEACTIVATE_PAY_PERIODS_SUCCESS: 'DEACTIVATE_PAY_PERIODS_SUCCESS',

  TRANSFER_OWNERSHIP_LOADING: 'TRANSFER_OWNERSHIP_LOADING',
  TRANSFER_OWNERSHIP: 'TRANSFER_OWNERSHIP',
  RESET_TRANSFER_OWNERSHIP_ACTION_RESULT: 'RESET_TRANSFER_OWNERSHIP_ACTION_RESULT',
};

export enum CorporationsPaths {
  CORPORATIONS = 'corporations',
  NEW_CORPORATION = 'new',

  CORPORATION_EDIT_BANK_INFO = ':corporationId/bank-info',
  CORPORATION_EDIT_BILLING = ':corporationId/billing',
  CORPORATION_EDIT_PROCESSOR_CONFIG = ':corporationId/processor-config',
  CORPORATION_EDIT_TAXPAYER = ':corporationId/taxpayer',
  CORPORATION_EDIT_INTEGRATIONS = ':corporationId/integrations',
  CORPORATION_EDIT_OTHER = ':corporationId/other',
}

export enum CorporationSteps {
  GENERAL_INFORMATION = 'GENERAL_INFORMATION',
  BANK_INFO = 'BANK_INFO',
  BILLING = 'BILLING',
  PROCESSOR_CONFIGURATION = 'PROCESSOR_CONFIGURATION',
  TAXPAYER = 'TAXPAYER',
  INTEGRATIONS = 'INTEGRATIONS',
  OTHER = 'OTHER',
  PAY_PERIOD = 'PAY_PERIOD',
  REQUIRED_FIELDS = 'REQUIRED_FIELDS',
}

export const NewCorporationStepsLabels = {
  [CorporationSteps.GENERAL_INFORMATION]: (
    <FormattedMessage {...messages.generalInformationLabel} />
  ),
  [CorporationSteps.BANK_INFO]: <FormattedMessage {...messages.bankInfoLabel} />,
  [CorporationSteps.BILLING]: <FormattedMessage {...commonMessages.billingLabel} />,
  [CorporationSteps.PROCESSOR_CONFIGURATION]: (
    <FormattedMessage {...messages.processorConfigInfoLabel} />
  ),
  [CorporationSteps.TAXPAYER]: <FormattedMessage {...messages.taxpayerLabel} />,
  [CorporationSteps.INTEGRATIONS]: <FormattedMessage {...commonMessages.integrations} />,
  [CorporationSteps.OTHER]: <FormattedMessage {...clubMessages.otherLabel} />,
};
