import React, { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { useAppDispatch } from 'store/hooks';

import { DialogComponent, Table, TooltipTypography } from 'common/components/index';

import messages from 'common/components/PersonProfile/messages';
import { TableOrderByParams } from 'common/constants';
import { FilterTypes, IFilterSettings, ISingleFilterValue } from 'common/interfaces/filter';
import tableFilters from 'common/messages/tableFilters';
import * as actions from 'common/components/PersonProfile/state/appointments/actions';
import { PeakModules } from 'common/constants/peakModules';
import { IHeadCell, ITableParams, ITableRow } from 'common/interfaces/table';
import { useSelector } from 'react-redux';
import { List as ImmutableList } from 'immutable';
import { IAppointmentHistoryItemImt } from 'modules/booking/interfaces';
import * as selectors from 'common/components/PersonProfile/state/appointments/selectors';
import { IPageMetaImt } from 'common/interfaces/pagination';
import tableHeaders from 'common/messages/tableHeaders';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import { DEFAULT_DATE_TIME_FORMAT } from 'common/constants/dateFormats';
import commonMessages from 'common/messages/messages';
import {
  selectCurrentUserAvailableClubs,
  selectFilterByClubDefaultValue,
} from 'modules/authentication/state/selectors';
import { INamedEntityImt } from 'common/interfaces/common';
import { useUpdateFiltersOnChangeClub } from 'common/hooks/useUpdateFiltersOnChangeClub';
import { Typography } from '@material-ui/core';
import {
  AppointmentStatusColors,
  AppointmentStatuses,
} from 'common/components/PersonProfile/constants';
import { appointmentReportStatuses } from 'modules/reports/constants/appointmentsReport';

const headerOptions: IHeadCell[] = [
  {
    id: TableOrderByParams.DATE,
    label: <FormattedMessage {...tableHeaders.date} />,
    sort: true,
  },
  {
    id: TableOrderByParams.TITLE,
    label: <FormattedMessage {...tableHeaders.name} />,
    sort: true,
  },
  {
    id: 'eventDescription',
    label: <FormattedMessage {...tableHeaders.eventDescription} />,
    sort: false,
  },
  {
    id: 'resources',
    label: <FormattedMessage {...tableHeaders.resources} />,
    sort: false,
  },
  {
    id: 'eventTag',
    label: <FormattedMessage {...tableHeaders.eventTag} />,
    sort: false,
  },
  {
    id: TableOrderByParams.STATUS,
    label: <FormattedMessage {...tableHeaders.status} />,
    sort: true,
  },
  {
    id: 'type',
    label: <FormattedMessage {...tableHeaders.type} />,
    sort: false,
  },
];

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onEventClick: (event: IAppointmentHistoryItemImt) => void;
  module: PeakModules;
  personId: string;
}

const AppointmentsHistoryModal = ({ isOpen, onClose, onEventClick, module, personId }: IProps) => {
  // state
  const dispatch = useAppDispatch();

  const appointmentsHistory: ImmutableList<IAppointmentHistoryItemImt> = useSelector(
    selectors.selectPersonAppointmentsHistory(personId),
  );
  const isAppointmentsHistoryLoading: boolean = useSelector(
    selectors.selectPersonAppointmentsHistoryLoading(personId),
  );
  const appointmentsHistoryMeta: IPageMetaImt = useSelector(
    selectors.selectPersonAppointmentsHistoryMeta(personId),
  );
  const defaultClubFilterValue: ISingleFilterValue = useSelector(selectFilterByClubDefaultValue);
  const clubs: ImmutableList<INamedEntityImt> = useSelector(selectCurrentUserAvailableClubs);

  const [timezoneMoment] = useTimezoneMoment();

  const clubFilterName = 'clubIds';

  const clubFilterOptions = useMemo(
    () =>
      clubs.toJS().map(club => ({
        key: club.id,
        label: club.title,
        value: club.id,
      })),
    [clubs],
  );

  const tableFilterSettings: IFilterSettings[] = useMemo(
    () => [
      {
        name: 'range',
        title: <FormattedMessage {...tableFilters.period} />,
        type: FilterTypes.DATE_RANGE,
        shouldClearToDefault: true,
        options: {
          startDate: timezoneMoment()
            .subtract(1, 'week')
            .startOf('day')
            .utc()
            .format(),
          endDate: timezoneMoment()
            .add(1, 'week')
            .endOf('day')
            .utc()
            .format(),
        },
        defaultValue: {
          startDate: timezoneMoment()
            .subtract(1, 'week')
            .startOf('day')
            .utc()
            .format(),
          endDate: timezoneMoment()
            .add(1, 'week')
            .endOf('day')
            .utc()
            .format(),
        },
      },
      {
        title: <FormattedMessage {...commonMessages.clubTitle} />,
        name: clubFilterName,
        type: FilterTypes.MULTIPLE,
        options: clubFilterOptions,
        defaultValue: defaultClubFilterValue ? [defaultClubFilterValue] : null,
      },
    ],
    [timezoneMoment, defaultClubFilterValue, clubFilterOptions],
  );

  const [tableParams, setTableParams] = useUpdateFiltersOnChangeClub(
    { filterSettings: tableFilterSettings, clubFilterName },
    null,
    {
      orderBy: TableOrderByParams.DATE,
      order: 'desc',
    },
  );

  useEffect(() => {
    dispatch(actions.fetchPersonAppointmentsHistory(personId, tableParams, module));
  }, [dispatch, module, personId, tableParams]);

  const handleChangeTableProps = useCallback((tableProps: ITableParams): void => {
    setTableParams(tableProps);
  }, []);

  const rows = useMemo(
    () =>
      appointmentsHistory
        .map(
          (item: IAppointmentHistoryItemImt): ITableRow => ({
            id: item.get('appointmentId'),
            cells: [
              {
                label: '',
                cellComponent: (
                  <Typography color="primary" variant="button" onClick={() => onEventClick(item)}>
                    {timezoneMoment(item.get('date')).format(DEFAULT_DATE_TIME_FORMAT)}
                  </Typography>
                ),
              },
              { label: item.get('title') },
              { label: item.get('description') || '-' },
              {
                label: '',
                maxWidth: '100px',
                cellComponent: (
                  <TooltipTypography ellipsized>
                    {item.get('resources')?.size
                      ? item
                          .get('resources')
                          .map(resourceItem => resourceItem.get('title'))
                          .join(',')
                      : '-'}
                  </TooltipTypography>
                ),
              },
              {
                label: '',
                maxWidth: '100px',
                cellComponent: (
                  <TooltipTypography ellipsized>
                    {item.get('tags')?.size
                      ? item
                          .get('tags')
                          .map(resourceItem => resourceItem.get('title'))
                          .join(',')
                      : '-'}
                  </TooltipTypography>
                ),
              },
              {
                label: '',
                cellComponent: (
                  <Typography
                    variant="button"
                    style={{ color: AppointmentStatusColors[item.get('status')] }}
                  >
                    {AppointmentStatuses.translate(item.get('status'))}
                  </Typography>
                ),
              },
              {
                label: '',
                cellComponent: (
                  <Typography
                    variant="button"
                    style={{ color: AppointmentStatusColors[item.get('type')] }}
                  >
                    {appointmentReportStatuses.translate(item.get('type'))}
                  </Typography>
                ),
              },
            ],
          }),
        )
        .toJS(),
    [appointmentsHistory, timezoneMoment],
  );

  return (
    <DialogComponent
      title={<FormattedMessage {...messages.appointmentsHistoryTitle} />}
      isOpen={isOpen}
      onClose={onClose}
      submitBtn={null}
      cancelBtn={null}
      size="lg"
    >
      <Table
        rows={rows}
        headerOptions={headerOptions}
        isLoading={isAppointmentsHistoryLoading}
        page={appointmentsHistoryMeta?.get('page')}
        totalRows={appointmentsHistoryMeta?.get('total')}
        onChangeParams={handleChangeTableProps}
        tableParams={tableParams}
        filters={tableFilterSettings}
      />
    </DialogComponent>
  );
};

export default AppointmentsHistoryModal;
