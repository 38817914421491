import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { Button, Grid, makeStyles, Theme } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';

import { ButtonGroup } from 'common/components';

import messages from 'modules/booking/messages';
import commonMessages from 'common/messages/messages';

const useStyles = makeStyles((theme: Theme) => ({
  btn: {
    paddingLeft: '12px',
    paddingRight: '12px',
  },
  btnMobileWrapper: {
    [theme.breakpoints.down('xs')]: {
      marginRight: 'auto',
    },
  },
  btnActive: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: 'white',
  },
  btnFirst: {
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0',
  },
  btnLast: {
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
  },
  btnCenter: {
    borderRadius: '0',
  },
  navBtn: {
    minWidth: '32px',
    width: '32px',
    height: '32px',
  },
}));

interface IProps {
  calendarView: 'timeGridDay' | 'timeGridWeek' | 'dayGridMonth';
  onChangeCalendarView: (view) => void;
  onCalendarNav: (dir) => void;
  isSmallScreen: boolean;
}

function ToolbarNav(props: IProps): JSX.Element {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const { calendarView, onChangeCalendarView, onCalendarNav, isSmallScreen } = props;
  const onClose = (): void => {
    setAnchorEl(null);
  };
  const handleClick = view => {
    onChangeCalendarView(view);
    onClose();
  };
  const onMenuClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const buttonText = () => {
    switch (calendarView) {
      case 'timeGridDay':
        return <FormattedMessage {...commonMessages.dayLabel} />;
      case 'timeGridWeek':
        return <FormattedMessage {...commonMessages.weekLabel} />;
      case 'dayGridMonth':
        return <FormattedMessage {...commonMessages.monthLabel} />;
      default:
        return <FormattedMessage {...commonMessages.weekLabel} />;
    }
  };
  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      spacing={2}
      wrap="nowrap"
    >
      <ButtonGroup
        title="Calendar view"
        isSmallScreen={isSmallScreen}
        settings={[
          {
            title: 'timeGridDay',
            label: <FormattedMessage {...commonMessages.dayLabel} />,
            isActive: calendarView === 'timeGridDay',
            onClick: () => onChangeCalendarView('timeGridDay'),
          },
          {
            title: 'timeGridWeek',
            label: <FormattedMessage {...commonMessages.weekLabel} />,
            isActive: calendarView === 'timeGridWeek',
            onClick: () => onChangeCalendarView('timeGridWeek'),
          },
          {
            title: 'dayGridMonth',
            label: <FormattedMessage {...commonMessages.monthLabel} />,
            isActive: calendarView === 'dayGridMonth',
            onClick: () => onChangeCalendarView('dayGridMonth'),
          },
        ]}
      />

      <Grid item>
        <Button className={classes.btn} variant="contained" onClick={() => onCalendarNav('today')}>
          <FormattedMessage {...messages.todayLabel} />
        </Button>
      </Grid>

      <Grid item>
        <Grid container spacing={1} wrap="nowrap">
          <Grid item>
            <Button
              className={classnames(classes.btn, classes.navBtn)}
              variant="contained"
              onClick={() => onCalendarNav('prev')}
            >
              <ChevronLeft />
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={classnames(classes.btn, classes.navBtn)}
              variant="contained"
              onClick={() => onCalendarNav('next')}
            >
              <ChevronRight />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ToolbarNav;
