// libraries
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { List } from 'immutable';
import { Box, makeStyles, Typography } from '@material-ui/core';
// components
import { Table } from 'common/components';
// interfaces
import { IHeadCell, ITableParams, ITableRow } from 'common/interfaces/table';
import { IPastDue, IPastDueImt } from 'common/components/PersonProfile/interfaces';
import { CustomTheme } from 'common/ui/interfaces';
import { FilterTypes, IFilterSettings } from 'common/interfaces/filter';
// constants
import { PastDueActions, PastDueResolutions } from 'common/components/PersonProfile/constants';
import { StepContext } from 'common/createContext/stepContext';
// hooks
import { useAppDispatch } from 'store/hooks';
import { usePersonSelectorTemplate } from 'common/components/PersonProfile/hooks/usePersonSelector';
// utils
import { getBillingItemDescription, getCurrentPageList } from '../utils';
import { makeTableParams } from 'common/utils/http';
// state
import * as actions from 'common/components/PersonProfile/state/actions';
import * as selectors from 'common/components/PersonProfile/state/selectors';
import { PeakModules } from 'common/constants/peakModules';
// messages
import messages from 'common/components/PersonProfile/messages';
import tableHeaders from 'common/messages/tableHeaders';
import tableFilters from 'common/messages/tableFilters';
import { DEFAULT_DATE_FORMAT } from 'common/constants/dateFormats';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import useMultipleSelectTableControl, {
  IMultipleSelectTableControlProps,
} from 'common/hooks/useMultipleSelectTableControl';
import { formatNumberToPrice, formatPrice } from 'common/utils';
import { TableOrderByParams } from 'common/constants';
import { removeTaxAmount } from 'modules/services/utils/billingUtils';

const useStyles = makeStyles((theme: CustomTheme) => ({
  tableWrapper: {
    borderBottom: `1px solid ${theme.palette.borderColor.main}`,
  },
  totalLabel: {
    fontSize: '1.25rem',
    textAlign: 'right',
    marginTop: theme.spacing(1.5),
  },
  noPastDuesBox: {
    height: '200px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

interface IProps {
  personId: string;
  membershipId?: string;
  module: PeakModules;
  isMembershipCard?: boolean;
}

const tableFilterSettings: IFilterSettings[] = [
  {
    name: 'range',
    title: <FormattedMessage {...tableFilters.period} />,
    type: FilterTypes.DATE_RANGE,
    options: {
      startDate: null,
      endDate: null,
    },
  },
];

const headerOptions: IHeadCell[] = [
  { id: 'status', label: <FormattedMessage {...tableHeaders.status} />, sort: false },
  { id: TableOrderByParams.DATE, label: <FormattedMessage {...tableHeaders.date} />, sort: true },
  { id: 'price', label: <FormattedMessage {...tableHeaders.price} />, align: 'right', sort: false },
  { id: 'packageBillingInfo', label: <FormattedMessage {...tableHeaders.name} />, sort: false },
];

const PastDuesStep = ({
  personId,
  membershipId,
  module,
  isMembershipCard,
}: IProps): JSX.Element => {
  // state
  const dispatch = useAppDispatch();

  const usePersonSelector = usePersonSelectorTemplate(personId);

  const pastDueBillings: List<IPastDueImt> = usePersonSelector(selectors.selectPastDueBillings);
  const isPastDueBillingsLoading: boolean = usePersonSelector(
    selectors.selectPastDueBillingsLoading,
  );

  const [tableParams, setTableParams] = useState(() =>
    makeTableParams([], null, { orderBy: TableOrderByParams.DATE, order: 'desc' }),
  );
  const [pastDuesData, setPastDuesData] = useState<Partial<IMultipleSelectTableControlProps>>({
    isAllSelected: false,
    selected: [],
  });

  const isInitialPastDuesExist = useRef(false);
  const prevTableParams = useRef(tableParams);

  const onChangeTableParamsSubscriber = (rowsData: IMultipleSelectTableControlProps) => {
    setPastDuesData(rowsData);
  };

  const [timezoneMoment] = useTimezoneMoment();

  const { onBack, renderFooter, onNext } = useContext(StepContext);

  const classes = useStyles();

  const fetchPastDues = useCallback(() => {
    if (membershipId) {
      dispatch(
        actions.fetchPastDuesForCancel(
          personId,
          membershipId,
          tableParams,
          module,
          isMembershipCard,
        ),
      );
    } else {
      dispatch(actions.fetchPastDuesForReactivate(personId, tableParams, module, isMembershipCard));
    }
  }, [membershipId, module, personId, isMembershipCard]);

  useEffect(() => {
    fetchPastDues();
  }, [fetchPastDues]);

  useEffect(() => {
    if (!isInitialPastDuesExist.current && pastDueBillings?.size) {
      isInitialPastDuesExist.current = true;
    }
  }, [pastDueBillings]);

  const { ref } = useMultipleSelectTableControl({
    onChangeTableParamsSubscriber,
  });

  const handleChangeTableParams = useCallback((tableProps: ITableParams): void => {
    const { page: prevPage, perPage: prevPerPage } = prevTableParams.current;
    const { page, perPage } = tableProps;

    setTableParams(tableProps);

    prevTableParams.current = tableProps;

    if (prevPage !== page || prevPerPage !== perPage) {
      return;
    }

    fetchPastDues();
  }, []);

  const handleResolveStep = (): void => {
    let pastDueIds;

    if (pastDuesData.isAllSelected && !pastDuesData.excluded.length) {
      pastDueIds = convertedPastDues.map(pastDueItem => pastDueItem.id);
    } else if (pastDuesData.excluded?.length) {
      pastDueIds = convertedPastDues.reduce(
        (acc, pastDueItem) =>
          pastDuesData.excluded.includes(pastDueItem.id) ? acc : [...acc, pastDueItem],
        [],
      );
    } else {
      pastDueIds = pastDuesData.selected;
    }

    onNext({ pastDues: pastDueIds });
  };

  const convertedPastDues = useMemo(() => pastDueBillings.toJS(), [pastDueBillings]);

  const currentPagePastDues = useMemo(
    () => getCurrentPageList(pastDueBillings.toJS(), tableParams.page, tableParams.perPage),
    [convertedPastDues, tableParams.page, tableParams.perPage],
  );

  const checkIsPastDueItemAdded = useCallback(
    (pastDueId: string): boolean =>
      pastDuesData.selected.includes(pastDueId) ||
      (pastDuesData.isAllSelected && !pastDuesData.excluded.includes(pastDueId)),
    [pastDuesData],
  );

  const pastDuesTotal = useMemo(() => {
    if (pastDuesData.isAllSelected && !pastDuesData.excluded?.length) {
      return convertedPastDues.reduce((totalAmount, pastDueItem) => {
        const { id, amount, revenueCode } = pastDueItem;

        const pastDueAmount = revenueCode?.totalTax
          ? removeTaxAmount(amount, revenueCode.totalTax)
          : amount;

        return totalAmount + (pastDuesData.excluded.includes(id) ? 0 : pastDueAmount);
      }, 0);
    }

    if (pastDuesData.excluded?.length) {
      return convertedPastDues.reduce((totalAmount, pastDueItem) => {
        const { id, amount, revenueCode } = pastDueItem;

        const pastDueAmount = revenueCode?.totalTax
          ? removeTaxAmount(amount, revenueCode.totalTax)
          : amount;

        return totalAmount + (pastDuesData.excluded.includes(id) ? 0 : pastDueAmount);
      }, 0);
    }

    return pastDuesData.selected.reduce((totalAmount, pastDueItemId) => {
      const { amount, revenueCode } = convertedPastDues.find(
        pastDueItem => pastDueItem.id === pastDueItemId,
      );

      const pastDueAmount = revenueCode?.totalTax
        ? removeTaxAmount(amount, revenueCode.totalTax)
        : amount;

      return totalAmount + pastDueAmount;
    }, 0);
  }, [convertedPastDues, pastDuesData]);

  const tableRows: ITableRow[] = useMemo(
    () =>
      currentPagePastDues.map((pastDue: IPastDue) => ({
        id: pastDue.id,
        cells: [
          {
            label: '',
            cellComponent: (
              <Typography
                variant="button"
                color={checkIsPastDueItemAdded(pastDue.id) ? 'primary' : 'error'}
              >
                {PastDueResolutions.translate(
                  checkIsPastDueItemAdded(pastDue.id) ? PastDueActions.Keep : PastDueActions.Waive,
                )}
              </Typography>
            ),
          },
          {
            align: 'left',
            variant: 'h5',
            label: pastDue.paymentDate
              ? timezoneMoment(pastDue.paymentDate).format(DEFAULT_DATE_FORMAT)
              : '-',
          },
          {
            align: 'right',
            variant: 'h5',
            label: `$${formatNumberToPrice(
              pastDue.revenueCode?.totalTax
                ? removeTaxAmount(pastDue.amount, pastDue.revenueCode.totalTax)
                : pastDue.amount,
            )}`,
          },
          {
            align: 'left',
            label: getBillingItemDescription(pastDue.package),
          },
        ],
      })),
    [checkIsPastDueItemAdded, currentPagePastDues, timezoneMoment],
  );

  return (
    <>
      {pastDueBillings.size || isInitialPastDuesExist.current ? (
        <>
          <Box className={classes.tableWrapper}>
            <Table
              activeSelect
              showPerPageSelect
              rows={tableRows}
              isLoading={isPastDueBillingsLoading}
              onChangeParams={handleChangeTableParams}
              headerOptions={headerOptions}
              totalRows={pastDueBillings?.size}
              filters={tableFilterSettings}
              refMultipleSelectParams={ref}
              tableParams={tableParams}
            />
          </Box>

          <Typography component="p" className={classes.totalLabel}>
            <FormattedMessage
              {...messages.pastDuesModalTotalLabel}
              values={{
                price: (
                  <Typography component="span" variant="h3">
                    {formatPrice(pastDuesTotal)}
                  </Typography>
                ),
              }}
            />
          </Typography>
        </>
      ) : (
        <Box className={classes.noPastDuesBox}>
          <Typography variant="h2" color="textSecondary">
            <FormattedMessage {...messages.pastDuesModalNoPastDues} />
          </Typography>
        </Box>
      )}

      {renderFooter(onBack, handleResolveStep)}
    </>
  );
};

export default React.memo(PastDuesStep);
