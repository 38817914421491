import React from 'react';
import { Box, ListItem, makeStyles, Typography } from '@material-ui/core';

import { AvatarWithInitials, TooltipTypography } from 'common/components';
import { CustomTheme } from 'common/ui/interfaces';
import { ITicketShortDtoImt } from 'common/components/PersonProfile/interfaces';
import { ticketStatuses } from 'common/components/PersonProfile/constants';
import { IReferralListInfoImt } from 'common/interfaces/referrals';
import { getInitials } from 'helpers/common';

const useStyles = makeStyles((theme: CustomTheme) => ({
  listItem: {
    cursor: 'default',
  },
  listItemContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    wordBreak: 'break-word',
  },
  referralName: {
    display: 'flex',
    flexDirection: 'column',

    '& span': {
      lineHeight: '1.125rem',
      fontWeight: 700,
    },
  },
}));

const ReferralListItem = (props: { item: IReferralListInfoImt }): JSX.Element => {
  const classes = useStyles();
  const { item } = props;
  const referral = item.toJS();

  const photo = referral.customer.imageUrl;
  const initials = getInitials(
    referral.customer.title.split(' ')[0],
    referral.customer.title.split(' ')[1],
  );

  return (
    <ListItem disableGutters className={classes.listItem}>
      <Box className={classes.listItemContainer}>
        <Box position="relative" marginRight={1}>
          <AvatarWithInitials
            initials={initials}
            imageUrl={photo}
            width="35px"
            height="35px"
            marginRight={0}
          />
        </Box>

        <Typography className={classes.referralName}>
          <Typography component="span">{referral.customer.title}</Typography>
          <Typography color="textSecondary" component="span">
            {referral.club?.addressLine}
          </Typography>
        </Typography>
      </Box>
    </ListItem>
  );
};

export default ReferralListItem;
