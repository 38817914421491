import React, { useMemo } from 'react';
import { createStyles, makeStyles, Theme, Tooltip, Typography } from '@material-ui/core';
import { WatchLater, CheckCircle, Cancel } from '@material-ui/icons';
import { InvoiceStatus } from 'common/interfaces/invoices';
import { InvoiceStatuses } from 'common/constants/invoices';

const useStyles = makeStyles((theme: Theme) => ({
  warning: {
    color: theme.palette.warning.main,
  },
  success: {
    color: theme.palette.success.main,
  },
  icon: {
    width: 12,
    height: 12,
    marginRight: 4,
  },
}));

interface IStatusCellProps {
  status: string;
}

export default function StatusCell({ status }: IStatusCellProps): JSX.Element {
  const classes = useStyles();
  const renderCellIcon = useMemo(() => {
    switch (status as string) {
      case InvoiceStatus.PENDING:
      case InvoiceStatus.IN_PROGRESS:
      case InvoiceStatus.OPEN:
        return <WatchLater className={classes.icon} />;
      case InvoiceStatus.VOIDED:
      case InvoiceStatus.UNPAID:
      case InvoiceStatus.DEFERRED:
        return <Cancel className={classes.icon} />;
      case InvoiceStatus.CHECK_OUT:
      default:
        return <CheckCircle className={classes.icon} />;
    }
  }, [status, classes]);
  return (
    <Tooltip title={InvoiceStatuses.translate(status)} aria-label="Status">
      <Typography component="p" style={{ color: InvoiceStatuses.color(status) }}>
        {renderCellIcon}
        {InvoiceStatuses.translate(status)}
      </Typography>
    </Tooltip>
  );
}
