import React, { Component } from 'react';
import { StyledComponentProps } from '@material-ui/core';
import { List } from 'immutable';
import { FormattedMessage } from 'react-intl';

// Components
import { Connect } from 'common/decorators';
import TableSection, {
  ITableHeaderColumn,
  ITableSectionColumn,
} from 'modules/reports/components/TableSection/TableSection';
// Selectors
import {
  selectCashSummary,
  selectCashSummaryTotals,
  selectCashSummaryLoading,
} from 'modules/reports/state/salesPOS/selectors';
// Actions
import { fetchCashSummary } from 'modules/reports/state/salesPOS/actions';
// Constants
import { DEFAULT_TABLE_PARAMS } from 'common/constants/table';
// Interfaces
import {
  ICashSummaryItem,
  ICashSummaryItemImt,
  ICashSummaryTotalsImt,
} from 'modules/reports/interfaces/salesPOS';
import { DispatchFunction } from 'common/state/interfaces';
import { ITableParams, IFooterCell, Order } from 'common/interfaces/table';
// Utils
import { formatPrice } from 'common/utils';
// Messages
import inputLabels from 'common/messages/inputLabels';
import messages from 'modules/reports/messages/messages';

interface ICashSummaryProps extends StyledComponentProps {
  mainTableParams: ITableParams;
  cashSummary?: List<ICashSummaryItemImt>;
  cashSummaryTotals?: ICashSummaryTotalsImt;
  cashSummaryLoading?: boolean;

  fetchCashSummaryData?: (params?) => void;
}

@(Connect({
  mapStateToProps: () => ({
    cashSummary: selectCashSummary(),
    cashSummaryTotals: selectCashSummaryTotals(),
    cashSummaryLoading: selectCashSummaryLoading(),
  }),
  mapDispatchToProps: (dispatch: DispatchFunction) => ({
    fetchCashSummaryData: (requestOptions?) => dispatch(fetchCashSummary(requestOptions)),
  }),
}) as any)
class CashSummary extends Component<ICashSummaryProps, ITableParams<ICashSummaryItem>> {
  private readonly columnSettings: ITableSectionColumn[];

  private readonly headerSettings: ITableHeaderColumn<ICashSummaryItem>[];

  constructor(props: ICashSummaryProps) {
    super(props);

    this.state = {
      page: 0,
      perPage: 5,
      orderBy: 'amount',
      order: 'desc',
      filters: [],
    };

    this.columnSettings = [
      {
        name: 'type',
        label: <FormattedMessage {...inputLabels.type} />,
      },
      {
        name: 'amount',
        label: <FormattedMessage {...inputLabels.amount} />,
        align: 'right',
        transform: value => formatPrice(value),
      },
    ];

    this.headerSettings = [
      {
        name: 'type',
        label: <FormattedMessage {...inputLabels.type} />,
        sort: true,
      },
      {
        name: 'amount',
        label: <FormattedMessage {...inputLabels.amount} />,
        align: 'right',
        sort: true,
      },
    ];
  }

  componentDidMount(): void {
    const { fetchCashSummaryData, mainTableParams } = this.props;
    const { page, perPage, orderBy, order } = this.state;

    this.setState({ filters: mainTableParams.filters });
    fetchCashSummaryData({
      ...DEFAULT_TABLE_PARAMS,
      filters: mainTableParams.filters,
      page,
      perPage,
      orderBy,
      order,
    });
  }

  componentDidUpdate(): void {
    const { mainTableParams, fetchCashSummaryData } = this.props;
    const { page, perPage, orderBy, order, filters } = this.state;

    if (JSON.stringify(filters) !== JSON.stringify(mainTableParams.filters)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ filters: mainTableParams.filters });
      fetchCashSummaryData({
        ...DEFAULT_TABLE_PARAMS,
        filters: mainTableParams.filters,
        page,
        perPage,
        orderBy,
        order,
      });
    }
  }

  handleChangeTableProps = (page: number, orderBy, order: Order): void => {
    this.updateTableParams({ ...this.state, page, orderBy, order });
  };

  updateTableParams = (tableParams: ITableParams<ICashSummaryItem>): void => {
    this.setState(tableParams);
    const { fetchCashSummaryData } = this.props;
    fetchCashSummaryData(tableParams);
  };

  createTableFooter = (): IFooterCell[] => {
    const { cashSummaryTotals } = this.props;

    return [
      {
        id: 'title',
        label: <FormattedMessage {...inputLabels.totalCash} />,
        variant: 'h5',
      },
      {
        id: 'amount',
        label: formatPrice(cashSummaryTotals?.get('amount')),
        variant: 'h5',
        align: 'right',
      },
    ];
  };

  render(): JSX.Element {
    const { cashSummary, cashSummaryLoading } = this.props;
    const { page, perPage, orderBy, order } = this.state;

    return (
      <TableSection<ICashSummaryItem, ICashSummaryItemImt>
        id="cash-summary-section"
        title={<FormattedMessage {...messages.cashSummaryTitle} />}
        columns={this.columnSettings}
        headerColumns={this.headerSettings}
        data={cashSummary}
        dataLoading={cashSummaryLoading}
        page={page}
        total={cashSummary?.size}
        order={order}
        orderBy={orderBy}
        perPage={perPage}
        hidePagination
        footerOptions={{ sticky: true, cells: this.createTableFooter() }}
        onSettingsChanged={this.handleChangeTableProps}
      />
    );
  }
}

export default CashSummary;
