import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { List as ImmutableList } from 'immutable';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
// interfaces
import {
  ICameFromIndicatorDictionaryItemImt,
  IEmployeeListItemImt,
} from 'common/interfaces/dictionary';
import { IImmutablePrimaryMemberInfo } from 'common/components/PersonProfile/interfaces';
// state
import { fetchDictionaryList } from 'common/state/dictionary/actions';
import { selectDictionaryList } from 'common/state/dictionary/selectors';
import { selectLoading } from 'common/state/newPerson/primaryInfo/selectors';
// hooks
import { useAppDispatch } from 'store/hooks';
// common
import { DialogComponent, LoadingBackdrop, MemberPrimaryInfoStep } from 'common/components';
// constants
import { DictionaryList } from 'common/constants/dictionaryConstants';
// components
import commonMessages from 'common/messages/messages';
import { selectCurrentUser } from 'modules/authentication/state/selectors';
import { PeakModules } from 'common/constants/peakModules';

const useStyles = makeStyles({
  root: {
    '& .MuiDialog-paper': {
      maxWidth: '480px',

      '& form': {
        padding: 0,
      },
    },
  },
});

interface INewMemberModalProps {
  isOpen: boolean;
  isLoading?: boolean;
  selectedMembers?: any;

  onClose: () => void;
  onSubmit: (data) => void;
  onSelectMember?: (person: IImmutablePrimaryMemberInfo) => void;
  module?: PeakModules;
}

const NewMemberModal = (props: INewMemberModalProps): JSX.Element => {
  const {
    isLoading = false,
    isOpen,
    onSelectMember,
    selectedMembers,
    onClose,
    onSubmit,
    module,
  } = props;

  const dispatch = useAppDispatch();

  const employees: ImmutableList<IEmployeeListItemImt> = useSelector(
    selectDictionaryList(DictionaryList.EMPLOYEES),
  );
  const cameFromIndicators: ImmutableList<ICameFromIndicatorDictionaryItemImt> = useSelector(
    selectDictionaryList(DictionaryList.CAME_FROM),
  );
  const isCreateMemberLoading = useSelector(selectLoading);
  const currentUser = useSelector(selectCurrentUser);

  const classes = useStyles();

  useEffect(() => {
    if (isOpen) {
      dispatch(fetchDictionaryList(DictionaryList.EMPLOYEES, { module }));
      dispatch(fetchDictionaryList(DictionaryList.CAME_FROM, { module }));
    }
  }, [dispatch, isOpen, module]);

  return (
    <DialogComponent
      className={classes.root}
      title={<FormattedMessage {...commonMessages.newMemberCommonTitle} />}
      submitBtnTitle={<FormattedMessage {...commonMessages.saveBtn} />}
      isOpen={isOpen}
      onClose={onClose}
      size="xs"
      formId="primary-info-form"
      disableFullScreenBehavior
      loading={isCreateMemberLoading}
    >
      <LoadingBackdrop isLoading={isLoading} />
      <MemberPrimaryInfoStep
        currentUser={currentUser}
        hasHiddenFooter
        handleOnSubmit={onSubmit}
        employees={employees}
        cameFromIndicators={cameFromIndicators}
        setPrimaryInfo={onSelectMember}
        hideAutocomplete
        isActiveMembersRestricted
        selectedMembers={selectedMembers}
        module={module}
      />
    </DialogComponent>
  );
};

export default React.memo(NewMemberModal);
