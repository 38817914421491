import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@material-ui/core';

import { Table } from 'common/components/index';
import { IHeadCell, ITableParams, ITableRow } from 'common/interfaces/table';
import { IFilterSettings } from 'common/interfaces/filter';
import { IPageMetaImt } from 'common/interfaces/pagination';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import { DEFAULT_DATE_TIME_FORMAT } from 'common/constants/dateFormats';

import tableHeaders from 'common/messages/tableHeaders';
import tableFilters from 'common/messages/tableFilters';
import inputLabels from 'common/messages/inputLabels';
import { payTypeLabels } from 'modules/employees/constants';
import { ITimeclockListItem } from 'modules/timeclock/interfaces/timeclock';
import { calculateDuration } from 'common/utils/calculate';
import { DurationUnit } from 'common/interfaces/common';

interface IProps {
  title?: string | JSX.Element;
  timeclockHistory: ITimeclockListItem[];
  timeclockHistoryMeta?: IPageMetaImt;
  isTimeclockHistoryLoading: boolean;
  tableFilterSettings?: IFilterSettings[];
  tableParams?: ITableParams;
  onChangeTableParams?: (tableParams: ITableParams) => void;
  hidePagination?: boolean;
  disableSorting?: boolean;
}

const TimeclockHistoryTable = ({
  title,
  timeclockHistory,
  timeclockHistoryMeta,
  isTimeclockHistoryLoading,
  tableFilterSettings,
  tableParams,
  onChangeTableParams,
  hidePagination,
  disableSorting,
}: IProps): JSX.Element => {
  const [timezoneMoment] = useTimezoneMoment();

  const headerOptions = useMemo(
    (): IHeadCell[] => [
      {
        id: 'club',
        label: <FormattedMessage {...tableHeaders.club} />,
        sort: !disableSorting,
      },
      {
        id: 'department',
        label: <FormattedMessage {...tableFilters.department} />,
        sort: !disableSorting,
      },
      {
        id: 'clock_in',
        label: <FormattedMessage {...tableHeaders.clockIn} />,
        sort: !disableSorting,
      },
      {
        id: 'clock_out',
        label: <FormattedMessage {...tableHeaders.clockOut} />,
        sort: !disableSorting,
      },
      {
        id: 'duration',
        label: <FormattedMessage {...inputLabels.duration} />,
        sort: !disableSorting && false,
      },
      {
        id: 'pay_type',
        label: <FormattedMessage {...inputLabels.payType} />,
        sort: !disableSorting,
      },
      {
        id: 'unit',
        label: <FormattedMessage {...tableHeaders.unit} />,
        sort: !disableSorting,
      },
    ],
    [disableSorting],
  );

  const rows = useMemo(
    () =>
      timeclockHistory?.map(
        (item: ITimeclockListItem): ITableRow => {
          return {
            id: item.id,
            cells: [
              {
                label: '',
                cellComponent: <Typography variant="body1">{item.clubTitle}</Typography>,
              },
              {
                label: '',
                cellComponent: <Typography variant="body1">{item.departmentTitle}</Typography>,
              },
              {
                label: '',
                cellComponent: (
                  <Typography variant="body1">
                    {timezoneMoment(item.clockIn).format(DEFAULT_DATE_TIME_FORMAT)}
                  </Typography>
                ),
              },
              {
                label: '',
                cellComponent: (
                  <Typography variant="body1">
                    {item.clockOut
                      ? timezoneMoment(item.clockOut).format(DEFAULT_DATE_TIME_FORMAT)
                      : '-'}
                  </Typography>
                ),
              },
              {
                label: '',
                cellComponent: (
                  <Typography variant="body1">
                    {item.durationInMinutes
                      ? calculateDuration(DurationUnit.Mins, item.durationInMinutes)
                      : '-'}
                  </Typography>
                ),
              },
              {
                label: '',
                cellComponent: (
                  <Typography variant="body1">{payTypeLabels.translate(item.payType)}</Typography>
                ),
              },
              {
                label: '',
                cellComponent: (
                  <Typography variant="body1">
                    {item.payType === 'UNIT' ? item.eventPerson?.title : '-'}
                  </Typography>
                ),
              },
            ],
          };
        },
      ),
    [timeclockHistory, timezoneMoment],
  );

  return (
    <Table
      title={title}
      rows={rows || []}
      headerOptions={headerOptions}
      hideSearchInput
      showPerPageSelect
      hidePagination={hidePagination}
      isLoading={isTimeclockHistoryLoading}
      page={timeclockHistoryMeta?.get('page')}
      totalRows={timeclockHistoryMeta?.get('total')}
      onChangeParams={onChangeTableParams}
      filters={tableFilterSettings}
      tableParams={tableParams}
    />
  );
};

export default TimeclockHistoryTable;
