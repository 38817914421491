import moment, { Moment, unitOfTime } from 'moment-timezone';

import { IDayTimeAvailabilityDto, Weekday } from 'common/interfaces/common';
import { DEFAULT_DATE_FORMAT } from 'common/constants/dateFormats';

export const getTimeFromMinutes = (minutes: number): string => {
  const dur = moment.duration(minutes, 'minutes').asMilliseconds();

  return moment.utc(dur).format('HH:mm');
};

export const generateTimeLabels = (
  startTime: string,
  interval: number,
  period: unitOfTime.Base,
): string[] => {
  const periodsInADay = moment.duration(1, 'day').as(period) - interval;

  const timeLabels = [];
  const startTimeMoment = moment(startTime, 'HH:mm');

  for (let i = 0; i < periodsInADay; i += interval) {
    startTimeMoment.add(i === 0 ? 0 : interval, period);
    timeLabels.push(startTimeMoment.format('HH:mm'));
  }
  timeLabels.push('24:00');

  return timeLabels;
};

export const getHoursAndMinutesFromDate = (date: Date): { hours: number; minutes: number } => {
  const minutes = new Date(date).getMinutes();
  const hours = new Date(date).getHours();

  return {
    hours,
    minutes,
  };
};

export const getPersonAgeString = (birthday: Date | string): string => {
  if (!birthday) return '';

  return `${moment().diff(moment(birthday), 'years')} years old (${moment(birthday).format(
    DEFAULT_DATE_FORMAT,
  )})`;
};

export const getExpiryDate = (expDate: string): string =>
  `${expDate.slice(0, 2)}/${expDate.slice(-2)}`;

export const checkIsNowInOperatingHours = (
  ranges: IDayTimeAvailabilityDto[],
  clubTimezone: string,
): boolean => {
  const clubDate = moment.tz(clubTimezone);
  const currentClubDay = clubDate.day();

  let isInOperatingHours = false;

  const currentClubDayRanges = ranges.find(
    rangeItem => rangeItem.weekday === Object.values(Weekday)[currentClubDay],
  );

  if (!currentClubDayRanges) {
    return false;
  }

  if (currentClubDayRanges.allDay) {
    return true;
  }

  currentClubDayRanges.timeRanges.forEach(rangeItem => {
    if (isInOperatingHours) {
      return;
    }

    const startKidZoneTime = moment.tz(rangeItem.startTime as string, 'HH:mm', clubTimezone);
    const endKidZoneTime = moment.tz(rangeItem.endTime as string, 'HH:mm', clubTimezone);

    if (clubDate.isBetween(startKidZoneTime, endKidZoneTime)) {
      isInOperatingHours = true;
    }
  });

  return isInOperatingHours;
};

export const transformMinutesToTimeFormat = (minutes: number): string => {
  const remainder = minutes % 60;
  return `${Math.trunc(minutes / 60)}h ${remainder < 10 ? `0${remainder}` : remainder}m`;
};

export const minutesOfDay = (m: Moment): number => {
  return m.minutes() + m.hours() * 60;
};
