import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  SvgIcon,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
// state
import { useSelector } from 'react-redux';
import { setIsSidebarOpen } from 'common/state/settings/actions';
import { selectIsSidebarOpen } from 'common/state/settings/selectors';
import { selectCurrentUserSelectedLevel } from 'modules/authentication/state/selectors';
// constants
import { PermissionLevels } from 'common/constants/permissions';
import { HOME_PATH } from 'common/constants/globalConstants';
// hooks
import useMenuState from 'common/hooks/useMenuState';
import { useAppDispatch } from 'store/hooks';
// components
import {
  ClubSelector,
  HeaderClubLogo,
  LanguageSelector,
  UserMenu,
  UserTimezoneSelector,
  // UserNotifications, // TODO refactor notifications
} from './components';
import { GlobalSearch, HelpInfoSearch, ContactPopover } from 'components';
// interface
import { CustomTheme } from 'common/ui/interfaces';
// icons
import { ReactComponent as Hamburger } from 'img/icons/hamburger.svg';
import { ReactComponent as HelpIcon } from 'img/icons/help.svg';
import { ReactComponent as SearchIcon } from 'img/icons/search.svg';
import { ReactComponent as TimeclockIcon } from 'img/icons/hourglass.svg';
import { ReactComponent as PhoneIcon } from 'img/icons/phone.svg';
import peakLogo from 'img/peakFullLogo.png';
import { ClockInModal } from 'modules/timeclock/components';
import { selectUserRole } from 'common/state/userRole/selectors';
import MemberPortalCart from './components/MemberPortalCart';

const useStyles = makeStyles((theme: CustomTheme) => ({
  iconButton: {
    width: '20px',
    height: '20px',
  },
  root: {
    boxShadow: theme.shadows[2],
  },
}));

const Header = (): JSX.Element => {
  const theme = useTheme();
  const isDesktopView = useMediaQuery(theme.breakpoints.up('sm'));

  const {
    menuAnchorEl: globalSearchAnchorEl,
    handleShowMenu: handleShowGlobalSearch,
    handleCloseMenu: handleCloseGlobalSearch,
  } = useMenuState();

  const {
    menuAnchorEl: helpInfoSearchAnchorEl,
    handleShowMenu: handleShowHelpInfoSearch,
    handleCloseMenu: handleCloseHelpInfoSearch,
  } = useMenuState();

  const {
    menuAnchorEl: contactPopoverAnchorEl,
    handleShowMenu: handleShowContactPopover,
    handleCloseMenu: handleCloseContactPopover,
  } = useMenuState();

  const {
    menuAnchorEl: clockInModalAnchorEl,
    handleShowMenu: handleShowClockInModal,
    handleCloseMenu: handleCloseClockInModal,
  } = useMenuState();

  // state

  const dispatch = useAppDispatch();
  const userPermissionLevel: PermissionLevels = useSelector(selectCurrentUserSelectedLevel);
  const isSidebarOpen = useSelector(selectIsSidebarOpen);
  const userRole = useSelector(selectUserRole);

  const isClientPortal = userRole === 'client';

  const classes = useStyles();

  // handlers

  const toggleSidebar = () => {
    dispatch(setIsSidebarOpen(!isSidebarOpen));
  };

  // renders

  // TODO fix markup after UI KIT changes
  return (
    <>
      <Box position="relative" width="100%" zIndex={10}>
        <Paper className={classes.root}>
          <Box display="flex" justifyContent="space-between" p={1.25} overflow="hidden">
            <Grid container spacing={1} alignItems="center" justify="space-between">
              <Grid item>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <IconButton id="hamburger" onClick={toggleSidebar} color="secondary">
                      <SvgIcon component={Hamburger} />
                    </IconButton>
                  </Grid>
                  {isClientPortal && userPermissionLevel !== PermissionLevels.PEAK ? (
                    <>
                      <Grid item>
                        <NavLink to={HOME_PATH}>
                          <HeaderClubLogo />
                        </NavLink>
                      </Grid>

                      <Grid item>
                        <ClubSelector />
                      </Grid>
                    </>
                  ) : (
                    <Grid item>
                      <NavLink to={HOME_PATH}>
                        <img height={25} src={peakLogo} alt="peak logo" />
                      </NavLink>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item>
                <Grid container spacing={3} alignItems="center">
                  {isDesktopView && (
                    <>
                      <Grid item>
                        <UserTimezoneSelector />
                      </Grid>

                      {isClientPortal && userPermissionLevel !== PermissionLevels.PEAK && (
                        <Grid item>
                          <IconButton onClick={handleShowClockInModal} color="secondary">
                            <TimeclockIcon className={classes.iconButton} />
                          </IconButton>
                        </Grid>
                      )}

                      {isClientPortal && (
                        <Grid item>
                          <IconButton onClick={handleShowGlobalSearch} color="secondary">
                            <SearchIcon className={classes.iconButton} />
                          </IconButton>
                        </Grid>
                      )}

                      <Grid item>
                        <LanguageSelector />
                      </Grid>

                      <Grid item>
                        <IconButton onClick={handleShowContactPopover} color="secondary">
                          <PhoneIcon className={classes.iconButton} />
                        </IconButton>
                      </Grid>

                      <Grid item>
                        <IconButton
                          disabled={!isClientPortal}
                          onClick={handleShowHelpInfoSearch}
                          color="secondary"
                        >
                          <HelpIcon className={classes.iconButton} />
                        </IconButton>
                      </Grid>

                      {!isClientPortal && (
                        <Grid item>
                          <MemberPortalCart />
                        </Grid>
                      )}

                      {/* <Grid item> // TODO refactor notifications
                        <UserNotifications />
                      </Grid> */}
                    </>
                  )}

                  <Grid item>
                    <UserMenu />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>

      {!!globalSearchAnchorEl && (
        <GlobalSearch anchorEl={globalSearchAnchorEl} onClose={handleCloseGlobalSearch} />
      )}

      {!!helpInfoSearchAnchorEl && (
        <HelpInfoSearch anchorEl={helpInfoSearchAnchorEl} onClose={handleCloseHelpInfoSearch} />
      )}

      {!!contactPopoverAnchorEl && (
        <ContactPopover
          isClientPortal={isClientPortal}
          currentUserLevel={userPermissionLevel}
          anchorEl={contactPopoverAnchorEl}
          onClose={handleCloseContactPopover}
        />
      )}

      {!!clockInModalAnchorEl && (
        <ClockInModal anchorEl={clockInModalAnchorEl} onClose={handleCloseClockInModal} />
      )}
    </>
  );
};

export default Header;
