import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import {
  Box,
  Button,
  Collapse,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Switch,
  Theme,
  Typography,
} from '@material-ui/core';

import useScrollIntoView from 'common/hooks/useScrollIntoView';

import { FeeTypeOptions } from 'modules/services/constants/fees';

import { IEditablePackageConfiguration } from 'common/interfaces/membership';
import { TEditableInstance } from '../MembershipFormView';

import { FieldInfo } from 'common/components/index';
import { ReactComponent as AngleDownIcon } from 'img/icons/angle-down.svg';
import { ReactComponent as AngleRightIcon } from 'img/icons/angle-right.svg';
import { ReactComponent as CrossIcon } from 'img/icons/times.svg';
import { ReactComponent as CheckIcon } from 'img/icons/check.svg';
import { ReactComponent as EditIcon } from 'img/icons/pencil_deprecated.svg';

import messages from 'common/components/PersonProfile/messages';
import inputLabels from 'common/messages/inputLabels';
import { FrequencyTypes } from 'modules/services/constants/packages';
import { formatPrice } from 'common/utils';

const useStyles = makeStyles((theme: Theme) => ({
  sectionBtn: {
    padding: 0,
    color: theme.palette.text.secondary,

    '& svg': {
      width: '0.75rem',
      height: '0.75rem',
      marginRight: theme.spacing(1),
    },
  },
  editIcon: {
    width: '1.25rem',
    height: '1.25rem',
  },
  switchLabel: {
    width: '100%',
    margin: theme.spacing(0, 0, 1, 0),

    '& .MuiFormControlLabel-label': {
      flex: 1,
      margin: theme.spacing(0, 1, 0, 0),
    },
  },
  feeTypeOption: {
    marginLeft: theme.spacing(0.5),
    color: theme.palette.darkBackground.light,
  },
  splittingStatus: {
    display: 'flex',
    alignItems: 'center',
  },
  statusIcon: {
    width: '1rem',
    height: '1rem',
    marginRight: theme.spacing(0.5),

    '&.primary': {
      color: theme.palette.primary.main,
    },
    '&.error': {
      color: theme.palette.error.main,
    },
  },
}));

interface IProps {
  membershipPackage: IEditablePackageConfiguration;
  onEditFees: (servicePackage: IEditablePackageConfiguration, instance: TEditableInstance) => void;
  onExcludeFee: (servicePackage: IEditablePackageConfiguration) => void;
}

const FeesSectionView = ({ membershipPackage, onEditFees, onExcludeFee }: IProps): JSX.Element => {
  const [showContent, setShowContent] = useState<boolean>(true);

  const { scrollRef } = useScrollIntoView(showContent);

  const classes = useStyles();

  const handleEditFees = () => {
    onEditFees(membershipPackage, 'fees');
  };

  const handleExcludeFee = (feeItemId: string, included: boolean) => {
    const updatedPackage = {
      ...membershipPackage,
      feeSection: {
        ...membershipPackage.feeSection,
        fees: membershipPackage.feeSection.fees.map(feeItem =>
          feeItem.id === feeItemId ? { ...feeItem, included } : feeItem,
        ),
      },
    };

    onExcludeFee(updatedPackage);
  };

  if (!membershipPackage.feeSection.fees?.length) {
    return null;
  }

  return (
    <Box mt={1.25}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Button
          className={classes.sectionBtn}
          onClick={() => setShowContent(!showContent)}
          startIcon={showContent ? <AngleDownIcon /> : <AngleRightIcon />}
        >
          <FormattedMessage {...messages.includedFeesTitle} />
        </Button>

        {!!membershipPackage.feeSection.hasEditable && (
          <IconButton color="primary" size="small" onClick={handleEditFees}>
            <EditIcon className={classes.editIcon} />
          </IconButton>
        )}
      </Box>

      <Collapse in={showContent} ref={scrollRef}>
        {membershipPackage.feeSection.fees.map((feeItem, index) => (
          <Box mt={index ? 1.5 : 1} key={feeItem.id}>
            <FormControlLabel
              labelPlacement="start"
              className={classes.switchLabel}
              label={
                <>
                  <Typography component="span" variant="h5">
                    {feeItem.fee.title}
                  </Typography>
                  <Typography component="span" className={classes.feeTypeOption}>
                    {FeeTypeOptions.translate(feeItem.fee.type)}
                  </Typography>
                </>
              }
              control={
                <Switch
                  color="primary"
                  size="small"
                  checked={feeItem.included}
                  disabled={!feeItem.excludable}
                  onChange={(e, checked) => handleExcludeFee(feeItem.id, checked)}
                />
              }
            />

            {feeItem.included && (
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <FieldInfo
                    label={<FormattedMessage {...inputLabels.amount} />}
                    description={`$${feeItem.totalAmount.value}`}
                  />
                </Grid>

                <Grid item xs={4}>
                  <FieldInfo
                    label={<FormattedMessage {...inputLabels.chargeAfterDays} />}
                    description={`${feeItem.chargeAfterDays.value}`}
                  />
                </Grid>

                <Grid item xs={4} className={classes.splittingStatus}>
                  {feeItem.split ? (
                    <CheckIcon className={cx(classes.statusIcon, 'primary')} />
                  ) : (
                    <CrossIcon className={cx(classes.statusIcon, 'error')} />
                  )}

                  <Typography component="span">
                    <FormattedMessage {...inputLabels.splitPayment} />
                  </Typography>
                </Grid>

                {feeItem.split && (
                  <>
                    <Grid item xs={4}>
                      <FieldInfo
                        label={<FormattedMessage {...inputLabels.paymentSchedule} />}
                        description={FrequencyTypes.translate(feeItem.splitPaymentSchedule)}
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <FieldInfo
                        label={<FormattedMessage {...inputLabels.payments} />}
                        description={`${feeItem.editableSplits.length}`}
                      />
                    </Grid>

                    {feeItem.editableSplits.map((splitItem, splitItemIndex) => (
                      <Grid item xs={4} key={splitItem.order}>
                        <FieldInfo
                          label={`#${splitItemIndex + 1}`}
                          description={formatPrice(splitItem.value)}
                        />
                      </Grid>
                    ))}
                  </>
                )}
              </Grid>
            )}
          </Box>
        ))}
      </Collapse>
    </Box>
  );
};

export default FeesSectionView;
