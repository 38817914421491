import { List as ImmutableList } from 'immutable';

import { IPaginatedData } from 'common/interfaces/pagination';
import { ImmutableObject } from 'common/state/interfaces';
import { INamedEntity } from 'common/interfaces/common';
import { IUploadFileResult } from 'common/interfaces/uploadFile';
import {
  IEmployeeJobPositionShort,
  IEmployeeJobPositionShortImt,
} from 'common/interfaces/employee';

export type PayTypes = 'HOURLY' | 'SALARY' | 'UNIT';

export enum EPayTypes {
  HOURLY = 'HOURLY',
  SALARY = 'SALARY',
  UNIT = 'UNIT',
}

export interface IJobPositionByClub {
  club: INamedEntity;
  jobPositions: Array<IEmployeeJobPositionShort>;
}

export interface IJobPositionByClubImt extends Map<string, any> {
  toJS(): IJobPositionByClub;

  get(key: 'jobPositions'): ImmutableList<IEmployeeJobPositionShortImt>;
}

export interface IEmployeeCommon {
  active: boolean;
  firstName: string;
  id: string;
  imageUrl?: string | null;
  lastName: string;
  phone?: string;
  preferredName?: string;
  title?: string;
  userId: string;
  terminationDate?: string;
}

export interface IEmployeeListItem extends IEmployeeCommon {
  jobPositionsByClub?: Array<IJobPositionByClub>;
}

export interface IEmployeeListItemImt extends Map<string, any> {
  toJS(): IEmployeeListItem;

  get<K extends keyof IEmployeeListItem>(key: K): IEmployeeListItem[K];

  get(key: 'jobPositionsByClub'): ImmutableList<IJobPositionByClubImt>;
}

export type IPaginatedEmployees = IPaginatedData<IEmployeeListItem>;

export type IAttachment = IUploadFileResult;

export type IAttachmentImt = ImmutableObject<IAttachment>;
