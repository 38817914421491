import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { IconButton, makeStyles } from '@material-ui/core';

import { selectSearchResults } from 'common/state/globalSearch/selectors';
import { selectCurrentUserSelectedLevel } from 'modules/authentication/state/selectors';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

import { ModuleTypes } from 'modules/dictionaries/constants/helpInfo';
import { PermissionLevels } from 'common/constants/permissions';

import { IHelpInfoSearchResult, IHelpInfoSearchResultImt } from 'common/interfaces/search';
import { CustomTheme } from 'common/ui/interfaces';
import { ContentType } from 'modules/dictionaries/interfaces/helpInfo';
import { IGroupTotalResultsCount } from 'components/SearchContainer/SearchContainer';

import { SearchContainer } from 'components/index';
import { SearchResultItem, SearchResultSection } from 'components/SearchContainer/components';
import { ReactComponent as VideoDefaultIcon } from 'img/icons/video-default-icon.svg';
import { ReactComponent as HTMLPageDefaultIcon } from 'img/icons/html-page-default-icon.svg';
import { ReactComponent as DocumentDefaultIcon } from 'img/icons/document-default-icon.svg';
import { ReactComponent as ExternalIcon } from 'img/icons/external.svg';

import commonMessages from 'common/messages/messages';

const useStyles = makeStyles((theme: CustomTheme) => ({
  redirectButton: {
    marginLeft: theme.spacing(0.5),
    color: theme.palette.darkBackground.light,
  },
}));

const defaultContentIcons = {
  [ContentType.VIDEO]: <VideoDefaultIcon />,
  [ContentType.HTML_PAGE]: <HTMLPageDefaultIcon />,
  [ContentType.DOCUMENT]: <DocumentDefaultIcon />,
};

interface IProps {
  anchorEl: null | Element;
  onClose: () => void;
}

const transformSearchResults = (results: IHelpInfoSearchResult): IGroupTotalResultsCount => ({
  groups: results.groups?.reduce(
    (acc, { moduleType, total }) => ({ ...acc, [moduleType]: { total } }),
    {},
  ),
  total: results.total,
});

const HelpInfoSearch = ({ anchorEl, onClose }: IProps): JSX.Element => {
  const searchResult: IHelpInfoSearchResultImt = useSelector(selectSearchResults);
  const selectedPermissionsLevel = useSelector(selectCurrentUserSelectedLevel);
  const isPeakLevel = selectedPermissionsLevel === PermissionLevels.PEAK;

  const renderIntlMessage = useRenderIntlMessage();

  const classes = useStyles();

  const convertedSearchResult = useMemo(() => searchResult.toJS(), [searchResult]);

  const totalSearchResultItems = convertedSearchResult.total;

  return (
    <SearchContainer
      anchorEl={anchorEl}
      title={<FormattedMessage {...commonMessages.helpTitle} />}
      onClose={onClose}
      searchGroupsLabels={ModuleTypes.values}
      searchGroupsResults={transformSearchResults(convertedSearchResult)}
      searchEntity="help"
      isPeakLevel={isPeakLevel}
    >
      <>
        {!!totalSearchResultItems &&
          convertedSearchResult.groups.map(({ moduleType, data }) => {
            return (
              <SearchResultSection
                key={moduleType}
                sectionId={moduleType}
                sectionTitle={renderIntlMessage(ModuleTypes.find(moduleType)?.message)}
              >
                {data.map(({ id, contentType, title, url }) => (
                  <SearchResultItem
                    key={id}
                    label={title}
                    description={url}
                    defaultIcon={defaultContentIcons[contentType]}
                    additionalElement={
                      <IconButton
                        size="small"
                        color="primary"
                        onClick={() => window.open(url)}
                        className={classes.redirectButton}
                      >
                        <ExternalIcon />
                      </IconButton>
                    }
                  />
                ))}
              </SearchResultSection>
            );
          })}
      </>
    </SearchContainer>
  );
};

export default HelpInfoSearch;
