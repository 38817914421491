import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as EditIcon } from 'img/icons/edit.svg';
import { ReactComponent as ChangeIcon } from 'img/icons/change.svg';
import leadsMessages from 'modules/crm/messages/leads';
import ptCrmMessages from 'modules/personal-training-crm/messages/messages';
import { ActionItem, ActionsMenu } from 'common/components';

const useStyles = makeStyles({
  icon: {
    width: 16,
    height: 16,
  },
});

interface IProps {
  leadId: string;
  isCrmModule: boolean;
  onChangeSalesperson: (leadId: string) => void;
  onChangeStatus: (leadId: string) => void;
}

const LeadsActionsMenu = (props: IProps): JSX.Element => {
  const classes = useStyles();
  const { leadId, onChangeSalesperson, onChangeStatus, isCrmModule } = props;

  const handleChangeStatusClick = () => {
    onChangeStatus(leadId);
  };

  const handleChangeSalespersonClick = () => {
    onChangeSalesperson(leadId);
  };

  return (
    <ActionsMenu horizontal tableActionsMode>
      <ActionItem
        key="1"
        icon={<ChangeIcon className={classes.icon} />}
        onClick={handleChangeStatusClick}
      >
        <FormattedMessage
          {...(isCrmModule
            ? leadsMessages.changeStatusLeadProfileTitle
            : ptCrmMessages.changeStatusPtLeadProfileTitle)}
        />
      </ActionItem>
      <ActionItem
        key="2"
        icon={<EditIcon className={classes.icon} />}
        onClick={handleChangeSalespersonClick}
      >
        <FormattedMessage {...leadsMessages.changeSalesTitle} />
      </ActionItem>
    </ActionsMenu>
  );
};

export default LeadsActionsMenu;
