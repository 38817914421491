import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Grid, Menu, MenuItem } from '@material-ui/core';
import classnames from 'classnames';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import React, { useState } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: 'min-content',
  },
  btn: {
    paddingLeft: '12px',
    paddingRight: '12px',
    whiteSpace: (props: IButtonGroupProps) => (props.noWrap ? 'nowrap' : 'normal'),
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  btnMobileWrapper: {
    [theme.breakpoints.down('xs')]: {
      marginRight: 'auto',
    },
  },
  btnActive: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: 'white',
  },

  btnFirst: {
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0',
  },
  btnLast: {
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
  },
  btnCenter: {
    borderRadius: '0',
  },
  navBtn: {
    minWidth: '32px',
    width: '32px',
    height: '32px',
  },
}));

interface IButtonSettings {
  title: string;
  label: string | JSX.Element;
  isActive: boolean;
  onClick: () => void;
}

interface IButtonGroupProps {
  title: React.ReactNode;
  settings: IButtonSettings[];
  isSmallScreen?: boolean;
  noWrap?: boolean;
  className?: string;
}

const ButtonGroup: React.FunctionComponent<IButtonGroupProps> = (
  props: IButtonGroupProps,
): JSX.Element => {
  const classes = useStyles(props);

  const { title, settings, isSmallScreen, className } = props;

  const [anchorEl, setAnchorEl] = useState<any>(null);

  const onMenuClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const onCloseMenu = (): void => {
    setAnchorEl(null);
  };

  const handleClick = (actionFn: () => void): void => {
    actionFn();
    onCloseMenu();
  };

  const activeButtonSetting: IButtonSettings = settings.find(setting => setting.isActive);

  // renders

  return !isSmallScreen ? (
    <Grid item container wrap="nowrap" className={classes.wrapper}>
      {settings.map((setting, index) => (
        <Button
          key={setting.title}
          className={classnames(
            classes.btn,
            {
              [classes.btnFirst]: index === 0,
              [classes.btnLast]: index === settings.length - 1,
              [classes.btnActive]: setting.isActive,
            },
            className,
          )}
          variant="contained"
          onClick={setting.onClick}
        >
          {setting.label}
        </Button>
      ))}
    </Grid>
  ) : (
    <Grid item className={classes.btnMobileWrapper}>
      <Button
        className={classes.btn}
        variant="contained"
        endIcon={<ArrowDropDownIcon />}
        onClick={onMenuClick}
      >
        {activeButtonSetting ? activeButtonSetting.label : title}
      </Button>
      <Menu id="menu-list-grow" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onCloseMenu}>
        {settings.map(setting => (
          <MenuItem key={setting.title} onClick={() => handleClick(setting.onClick)}>
            {setting.label}
          </MenuItem>
        ))}
      </Menu>
    </Grid>
  );
};

export default React.memo(ButtonGroup);
