import React, { useCallback, useEffect, useState } from 'react';
import { List as ImmutableList } from 'immutable';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
// interfaces
import { IScriptListItemImt } from 'modules/crm/interfaces/scripts';
import { IFilterSettings } from 'common/interfaces/filter';
import { IHeadCell, IMultipleSelectData, ITableParams, ITableRow } from 'common/interfaces/table';
import { ISuccessResponse } from 'common/interfaces/http';
// icons
import { ReactComponent as CheckIcon } from 'img/icons/check.svg';
import { ReactComponent as EditIcon } from 'img/icons/edit.svg';
import { ReactComponent as XDeleteIcon } from 'img/icons/x-bold_deprecated.svg';
// constants
import { PeakModules } from 'common/constants/peakModules';
// components
import { Table, StatusLabel, ActionsMenu, ActionItem } from 'common/components';
import SmallScreenCell from './SmallScreenCell/SmallScreenCell';
import ChangeScriptStatusModal from './ChangeScriptStatusModal/ChangeScriptStatusModal';
import useMultipleSelectTableControl from 'common/hooks/useMultipleSelectTableControl';
// messages
import menuItems from 'common/messages/menuItems';
import scriptsMessages from 'modules/crm/messages/scripts';
import ptCrmMessages from 'modules/personal-training-crm/messages/messages';
import tableHeaders from 'common/messages/tableHeaders';
import commonMessages from 'common/messages/messages';

interface IProps {
  items: ImmutableList<IScriptListItemImt>;
  mainTableParams: ITableParams;
  tableFilterSettings: IFilterSettings[];
  totalRows: number;
  isLoading: boolean;
  changeScriptStatusResult: ISuccessResponse;
  onChangeParams: (tableParams: ITableParams) => void;
  changeScriptStatus: (data: IMultipleSelectData, isActive: boolean) => void;
  resetChangeScriptStatusResult: () => void;
  module: PeakModules.Crm | PeakModules.PersonalTrainingCrm;
}

const headerOptions: IHeadCell[] = [
  { id: 'name', label: <FormattedMessage {...tableHeaders.name} />, sort: true, padding: 'none' },
  { id: 'active', label: <FormattedMessage {...tableHeaders.status} />, sort: true },
  { id: 'in_use', label: <FormattedMessage {...tableHeaders.inUse} />, sort: true },
  {
    id: 'actions',
    label: <FormattedMessage {...tableHeaders.actions} />,
    sort: false,
    padding: 'none',
    align: 'center',
  },
];

const useStyles = makeStyles({
  icon: {
    width: 16,
    height: 16,
  },
});

const ScriptsTable = (props: IProps): JSX.Element => {
  const {
    isLoading,
    items,
    totalRows,
    mainTableParams,
    tableFilterSettings,
    changeScriptStatusResult,
    onChangeParams,
    changeScriptStatus,
    resetChangeScriptStatusResult,
    module,
  } = props;
  const [selectedScript, setSelectedScript] = useState(null);
  const [scriptNewStatus, setScriptNewStatus] = useState<boolean>(null);
  const [selectedScriptsMultipleSelectData, setSelectedScriptsMultiSelectData] = useState(null);
  const [isChangeStatusModalOpen, setIsChangeStatusModalOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const isCrmModule = module === PeakModules.Crm;

  const onCloseModal = () => {
    setSelectedScript(null);
    setSelectedScriptsMultiSelectData(null);
    setIsChangeStatusModalOpen(false);
  };

  const { ref, tableParams } = useMultipleSelectTableControl({
    deleteResult: changeScriptStatusResult,
    closeDeleteModal: onCloseModal,
  });

  const classes = useStyles();

  useEffect(() => {
    if (changeScriptStatusResult.success) {
      resetChangeScriptStatusResult();
      setScriptNewStatus(null);
    }
  }, [changeScriptStatusResult, resetChangeScriptStatusResult]);

  const handleEditClick = useCallback(
    (resourceId: string) => {
      navigate(`/${location.pathname.split('/')[1]}/scripts/${resourceId}/edit`);
    },
    [location.pathname, navigate],
  );

  const handleActivateClick = (selected, options?) => {
    setSelectedScriptsMultiSelectData({
      ...options,
      includedIds: selected,
      tableParams,
    });
    setIsChangeStatusModalOpen(true);
    setScriptNewStatus(true);
  };
  const handleDeactivateClick = (selected, options?) => {
    setSelectedScriptsMultiSelectData({
      ...options,
      includedIds: selected,
      tableParams,
    });
    setIsChangeStatusModalOpen(true);
    setScriptNewStatus(false);
  };

  const onChangeScriptStatus = () => {
    if (selectedScript) {
      changeScriptStatus({ includedIds: [selectedScript.id] }, scriptNewStatus);
    } else changeScriptStatus(selectedScriptsMultipleSelectData, scriptNewStatus);
  };

  const multipleSelectActions = [
    {
      id: 'activate',
      tooltip: <FormattedMessage {...menuItems.activate} />,
      className: classes.icon,
      ButtonIcon: CheckIcon,
      onClick: handleActivateClick,
    },
    {
      id: 'deactivate',
      tooltip: <FormattedMessage {...menuItems.deactivate} />,
      className: classes.icon,
      ButtonIcon: XDeleteIcon,
      onClick: handleDeactivateClick,
    },
  ];

  const rows: ITableRow[] = items
    .map(
      (script: IScriptListItemImt): ITableRow => ({
        id: script.get('id'),
        cells: [
          {
            padding: 'none',
            variant: 'subtitle2',
            label: script.get('title'),
          },
          {
            label: 'status',
            cellComponent: <StatusLabel isActive={script.get('active')} />,
          },
          {
            label: '-',
            cellComponent: (
              <StatusLabel
                isActive={script.get('inUse')}
                activeOptionMessage={commonMessages.yesOption}
                inactiveOptionMessage={commonMessages.noOption}
              />
            ),
          },
          {
            label: 'actions',
            padding: 'none',
            width: '54px',
            align: 'center',
            cellComponent: (
              <ActionsMenu horizontal tableActionsMode>
                <ActionItem
                  key="1"
                  icon={<EditIcon className={classes.icon} />}
                  onClick={() => handleEditClick(String(script.get('id')))}
                >
                  <FormattedMessage {...menuItems.edit} />
                </ActionItem>
                <ActionItem
                  key="2"
                  icon={
                    script.get('active') ? (
                      <XDeleteIcon className={classes.icon} />
                    ) : (
                      <CheckIcon className={classes.icon} />
                    )
                  }
                  onClick={() => {
                    setIsChangeStatusModalOpen(true);
                    setScriptNewStatus(!script.get('active'));
                    setSelectedScript({ name: script.get('title'), id: script.get('id') });
                  }}
                >
                  <Typography>
                    {script.get('active') ? (
                      <FormattedMessage {...menuItems.deactivate} />
                    ) : (
                      <FormattedMessage {...menuItems.activate} />
                    )}
                  </Typography>
                </ActionItem>
              </ActionsMenu>
            ),
          },
        ],
        cellsMini: [
          {
            label: '-',
            cellComponent: (
              <SmallScreenCell
                name={script.get('title')}
                isActive={script.get('active')}
                inUse={script.get('inUse')}
              />
            ),
          },
          {
            label: '-',
            align: 'center',
            padding: 'none',
            width: '54px',
            cellComponent: (
              <ActionsMenu horizontal tableActionsMode>
                <ActionItem
                  key="1"
                  icon={<EditIcon className={classes.icon} />}
                  onClick={() => handleEditClick(String(script.get('id')))}
                >
                  <FormattedMessage {...menuItems.edit} />
                </ActionItem>
                <ActionItem
                  key="2"
                  icon={
                    script.get('active') ? (
                      <XDeleteIcon className={classes.icon} />
                    ) : (
                      <CheckIcon className={classes.icon} />
                    )
                  }
                  onClick={() => {
                    setIsChangeStatusModalOpen(true);
                    setScriptNewStatus(!script.get('active'));
                    setSelectedScript({ name: script.get('title'), id: script.get('id') });
                  }}
                >
                  <Typography>
                    {script.get('active') ? (
                      <FormattedMessage {...menuItems.deactivate} />
                    ) : (
                      <FormattedMessage {...menuItems.activate} />
                    )}
                  </Typography>
                </ActionItem>
              </ActionsMenu>
            ),
          },
        ],
      }),
    )
    .toJS();

  return (
    <>
      <Table
        title={
          <FormattedMessage
            {...(isCrmModule ? scriptsMessages.scripts : ptCrmMessages.ptScripts)}
          />
        }
        backRedirectLink={`/${location.pathname.split('/')[1]}`}
        addButtonRedirect={`/${location.pathname.split('/')[1]}/scripts/new`}
        headerOptions={headerOptions}
        showPerPageSelect
        isLoading={isLoading}
        rows={rows}
        filters={tableFilterSettings}
        totalRows={totalRows}
        onChangeParams={onChangeParams}
        tableParams={mainTableParams}
        multipleSelectActions={multipleSelectActions}
        activeSelect
        refMultipleSelectParams={ref}
      />
      <ChangeScriptStatusModal
        isLoading={isLoading}
        scriptTitle={selectedScript?.name}
        scriptNewStatus={scriptNewStatus}
        isOpen={isChangeStatusModalOpen}
        onClose={onCloseModal}
        onSubmit={onChangeScriptStatus}
      />
    </>
  );
};

export default ScriptsTable;
