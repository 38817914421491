import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  ClickAwayListener,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { ContactSupport } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment-timezone';
import cx from 'classnames';
// icons
import img from 'img/PeakLogoSmall.svg';
// styles
import sidebarStyle from 'components/Sidebar/sidebarStyle';
// components
import MemberPortalSidebarRoutes from './MemberPortalSidebarRoutes';
import { LanguageSelector } from 'components/Header/components';
// state
import { setIsSidebarOpen } from 'common/state/settings/actions';
import { selectIsSidebarOpen } from 'common/state/settings/selectors';
// messages
import messages from 'common/messages/messages';
import { ThemeBreakpoints } from 'common/ui/theme/default';
// hooks
import { useAppDispatch } from 'store/hooks';
import { Link } from 'react-router-dom';
import { PRIVACY_POLICY_PATH } from 'common/constants/globalConstants';

const MemberPortalSidebar = (): JSX.Element => {
  const year = moment().format('YYYY');
  const theme = useTheme();
  const isMobileView = !useMediaQuery(theme.breakpoints.up('sm'));

  const classes = sidebarStyle();
  const isSidebarOpen = useSelector(selectIsSidebarOpen);
  const dispatch = useAppDispatch();
  const [isSidebarOpenOnHover, setIsSidebarOpenOnHover] = useState(false);
  const [isTextVisible, setIsTextVisible] = useState(isSidebarOpen);

  let showTextInterval;
  let openSidebarnterval;

  const showListItemText = isTextVisible && (isSidebarOpen || isSidebarOpenOnHover);

  // Handlers

  const toggleSidebar = useCallback(isOpen => {
    setTimeout(() => {
      setIsTextVisible(isOpen);
    }, 250);
    setIsSidebarOpenOnHover(false);
  }, []);

  const closeSidebarOnClickOver = (e: React.MouseEvent<Document, MouseEvent>) => {
    if (window.innerWidth < ThemeBreakpoints.DESKTOP && isSidebarOpen) {
      const hamburger = document.getElementById('hamburger');
      if (e.target instanceof Node && hamburger.contains(e.target)) return;
      dispatch(setIsSidebarOpen(false));
      toggleSidebar(false);
      e.preventDefault();
    }
  };

  const onDrawerHoverIn = () => {
    if (window.innerWidth > ThemeBreakpoints.DESKTOP) {
      openSidebarnterval = setTimeout(() => {
        if (!isSidebarOpen) {
          setIsSidebarOpenOnHover(true);
          showTextInterval = setTimeout(() => {
            setIsTextVisible(true);
          }, 250);
        }
      }, 500);
    }
  };

  const onDrawerHoverOut = () => {
    clearTimeout(showTextInterval);
    clearTimeout(openSidebarnterval);
    if (isSidebarOpen) return;
    setIsTextVisible(false);
    setIsSidebarOpenOnHover(false);
  };

  useEffect(() => {
    toggleSidebar(isSidebarOpen);
  }, [isSidebarOpen, toggleSidebar]);

  const isVisible = isTextVisible && (isSidebarOpen || isSidebarOpenOnHover);

  return (
    <ClickAwayListener
      touchEvent="onTouchEnd"
      mouseEvent="onMouseUp"
      onClickAway={closeSidebarOnClickOver}
    >
      <Box
        className={cx(`sidebar ${classes.root}`, {
          'sidebar--closed': !isSidebarOpen && !isSidebarOpenOnHover,
          'sidebar--open-on-hover': isSidebarOpenOnHover,
        })}
        id="sidebarRoot"
        onMouseEnter={onDrawerHoverIn}
        onMouseLeave={onDrawerHoverOut}
        boxShadow={4}
        height="100%"
      >
        <Box flex={1} minHeight={0} display="flex" flexDirection="column">
          {isMobileView && (
            <Box
              overflow="hidden"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              pl={1}
              pr={1}
              pt={1}
            >
              <Box>
                <LanguageSelector className={classes.sidebarIconWrapper} />
              </Box>
              <Box className={classes.sidebarIconWrapper}>
                <IconButton color="inherit" onClick={() => {}} style={{ fontSize: '1.25rem' }}>
                  <ContactSupport />
                </IconButton>
              </Box>
            </Box>
          )}
          <Box flex={1} minHeight={0}>
            <PerfectScrollbar options={{ wheelPropagation: false }}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                minHeight="100%"
              >
                <Box id="sidebarWrapper">
                  <MemberPortalSidebarRoutes isTextVisible={showListItemText} />
                </Box>
                <Box className={classes.footer}>
                  {isVisible && (
                    <Box pb={1.25} pt={2}>
                      <Link to={PRIVACY_POLICY_PATH}>
                        <Typography color="secondary">
                          <FormattedMessage {...messages.privacyPolicy} />
                        </Typography>
                      </Link>
                    </Box>
                  )}
                  <Box display="flex" justifyContent="center" alignItems="center">
                    {isVisible && (
                      <Box flexGrow={1}>
                        <Typography color="secondary">
                          <FormattedMessage
                            values={{ year, br: <br /> }}
                            {...messages.sidebarFooterText}
                          />
                        </Typography>
                      </Box>
                    )}
                    <Box
                      className={classes.footerLogo}
                      style={{ backgroundImage: `url(${img})` }}
                    />
                  </Box>
                </Box>
              </Box>
            </PerfectScrollbar>
          </Box>
        </Box>
      </Box>
    </ClickAwayListener>
  );
};

export default MemberPortalSidebar;
