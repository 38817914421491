import axios from 'axios';

import ApiService from 'services/network/ApiService';

import { IMessagingSettings, PaymentProcessorType } from 'modules/corporate-settings/interfaces';

export default class CorporationsService extends ApiService {
  public getCorporationMessageSettings = (corporationId: string): Promise<IMessagingSettings> =>
    axios.get(`/api/v1/corporations/${corporationId}/messaging-settings`);

  public updateCorporationMessageSettings = (
    corporationId: string,
    payload: IMessagingSettings,
  ): Promise<IMessagingSettings> =>
    axios.put(`api/v1/corporations/${corporationId}/messaging-settings/settings `, payload);

  // Sendgrid
  public updateCorporationEmailSettings = (
    corporationId: string,
    payload: Partial<IMessagingSettings>,
  ): Promise<IMessagingSettings> =>
    axios.put(`/api/v1/corporations/${corporationId}/messaging-settings/email`, payload);

  public changeCorporationEmailStatus = (
    corporationId: string,
    isActive: boolean,
  ): Promise<IMessagingSettings> =>
    axios.put(`/api/v1/corporations/${corporationId}/messaging-settings/email/change-status`, {
      active: isActive,
    });

  // Twilio
  public updateCorporationSmsSettings = (
    corporationId: string,
    payload: Partial<IMessagingSettings>,
  ): Promise<IMessagingSettings> =>
    axios.put(`/api/v1/corporations/${corporationId}/messaging-settings/sms`, payload);

  public changeCorporationSmsStatus = (
    corporationId: string,
    isActive: boolean,
  ): Promise<IMessagingSettings> =>
    axios.put(`/api/v1/corporations/${corporationId}/messaging-settings/sms/change-status`, {
      active: isActive,
    });

  // Payeezy
  public updateCorporationPayeezySettings = (
    corporationId: string,
    payload: Partial<IMessagingSettings>,
  ): Promise<IMessagingSettings> =>
    axios.put(`/api/v1/corporations/${corporationId}/messaging-settings/settings/payeezy`, payload);

  public updateCorporationITransactSettings = (
    corporationId: string,
    payload: Partial<IMessagingSettings>,
  ): Promise<IMessagingSettings> =>
    axios.put(
      `/api/v1/corporations/${corporationId}/messaging-settings/settings/i-transact`,
      payload,
    );

  public updatePaymentProcessorType = (
    corporationId: string,
    paymentProcessorType: PaymentProcessorType,
  ): Promise<IMessagingSettings> =>
    axios.put(
      `/api/v1/corporations/${corporationId}/messaging-settings/settings/payment-processor-type`,
      null,
      { params: { paymentProcessorType } },
    );

  public changeCorporationPayeezyStatus = (
    corporationId: string,
    isActive: boolean,
  ): Promise<IMessagingSettings> =>
    axios.put(
      `api/v1/corporations/${corporationId}/messaging-settings/settings/payeezy/change-status`,
      {
        active: isActive,
      },
    );

  // Campaign settings

  public updateCorporationCampaignSettings = (
    corporationId: string,
    payload: Partial<IMessagingSettings>,
  ): Promise<IMessagingSettings> =>
    axios.put(
      `/api/v1/corporations/${corporationId}/messaging-settings/campaign-settings`,
      payload,
    );

  public changeCorporationCampaignSettingsStatus = (
    corporationId: string,
    isActive: boolean,
  ): Promise<IMessagingSettings> =>
    axios.put(
      `/api/v1/corporations/${corporationId}/messaging-settings/campaign-settings/change-status`,
      {
        active: isActive,
      },
    );

  // Commercehub

  public updateCorporationCommercehubSettings = (
    corporationId: string,
    payload: Partial<IMessagingSettings>,
  ): Promise<IMessagingSettings> =>
    axios.put(
      `/api/v1/corporations/${corporationId}/messaging-settings/settings/commercehub`,
      payload,
    );
}
