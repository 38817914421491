import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import { Alert as MaterialAlert } from '@material-ui/lab';
import {
  createStyles,
  fade,
  withStyles,
  WithStyles,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { MoreHoriz, SvgIconComponent } from '@material-ui/icons';

import { AlertVariant, IStylesProps } from 'common/components/Alert/types';
import { AlertTypes } from 'common/interfaces/alerts';
import { CustomColor, CustomTheme } from 'common/ui/interfaces';

import {
  getAlertColor,
  getAlertIcon,
  getFadeValue,
  getPaletteColorField,
} from 'common/components/Alert/helper';

import Button from '../Button/Button';

import messages from 'common/messages/messages';

const styles = (theme: CustomTheme) =>
  createStyles({
    root: {
      width: '100%',
      marginBottom: theme.spacing(1),
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '3px',
      padding: '7px 7px 7px 13px',
      border: ({ severity, variant }: IStylesProps) =>
        variant === 'outlined'
          ? `1px solid ${theme.palette[getAlertColor(severity)].main}`
          : 'none',
      color: ({ severity, variant }: IStylesProps) =>
        theme.palette[getAlertColor(severity)][getPaletteColorField(variant)],
      backgroundColor: ({ severity, variant }: IStylesProps) =>
        fade(theme.palette[getAlertColor(severity)].main, getFadeValue(variant)),

      '& .MuiAlert-action': {
        margin: theme.spacing(0, 0, 0, 'auto'),
      },
      '& .MuiAlert-icon': {
        padding: 0,
      },
      '& .MuiAlert-message': {
        flex: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '3px 0',
      },
    },
    icon: {
      color: ({ severity, variant }: IStylesProps) =>
        theme.palette[getAlertColor(severity)][variant === 'filled' ? 'contrastText' : 'main'],
    },
    dotsIcon: {
      color: 'inherit',
      padding: 0,
      marginRight: '-5px',
      minWidth: '35px',
    },
    actionButton: {
      padding: '8px 14px',
      marginLeft: theme.spacing(0.5),
      '&.MuiButton-contained': {
        marginLeft: theme.spacing(1.25),
      },
    },
  });

interface IAlertProps extends Partial<WithStyles<typeof styles>> {
  title: string | JSX.Element;
  severity: AlertTypes;
  variant?: AlertVariant;
  icon?: SvgIconComponent;
  noIcon?: boolean;
  small?: boolean;
  alertId?: string;
  resolveLabel?: string | JSX.Element;
  extraClassName?: string;
  isDisabled?: boolean;

  onIgnore?: (alertId?: string) => void;
  onRemindLater?: (alertId?: string) => void;
  onResolve?: (alertId?: string) => void;
  onConnect?: (alertId?: string) => void;
  onViewNote?: (alertId?: string) => void;
}

const Alert = withStyles(styles)(
  ({
    title,
    severity,
    onIgnore,
    onRemindLater,
    onResolve,
    onConnect,
    onViewNote,
    resolveLabel,
    classes,
    small,
    alertId,
    noIcon,
    icon,
    isDisabled,
    extraClassName,
  }: IAlertProps): JSX.Element => {
    const Icon = icon || getAlertIcon(severity);
    const alertColor: CustomColor = getAlertColor(severity);
    const [anchorEl, setAnchorEl] = useState<any>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
      setAnchorEl(null);
    };

    const handleIgnore = () => {
      setAnchorEl(null);
      onIgnore(alertId);
    };
    const handleRemindLater = () => {
      setAnchorEl(null);
      onRemindLater(alertId);
    };
    const handleResolve = () => {
      setAnchorEl(null);
      onResolve(alertId);
    };

    const handleConnect = () => {
      setAnchorEl(null);
      onConnect(alertId);
    };

    const handleViewNote = () => {
      setAnchorEl(null);
      onViewNote(alertId);
    };

    return !small ? (
      <MaterialAlert
        className={cx(classes.root, extraClassName)}
        icon={noIcon ? false : <Icon className={classes.icon} />}
        action={
          <>
            {!!onIgnore && (
              <Button
                disabled={isDisabled}
                variant="text"
                size="small"
                color={alertColor}
                className={classes.actionButton}
                onClick={handleIgnore}
              >
                <FormattedMessage {...messages.ignoreBtn} />
              </Button>
            )}
            {!!onViewNote && (
              <Button
                disabled={isDisabled}
                variant="text"
                size="small"
                color={alertColor}
                className={classes.actionButton}
                onClick={handleViewNote}
              >
                <FormattedMessage {...messages.viewNoteBtn} />
              </Button>
            )}
            {!!onRemindLater && (
              <Button
                disabled={isDisabled}
                variant="text"
                size="small"
                color={alertColor}
                className={classes.actionButton}
                onClick={handleRemindLater}
              >
                <FormattedMessage {...messages.remindLaterBtn} />
              </Button>
            )}
            {!!onResolve && (
              <Button
                disabled={isDisabled}
                variant="contained"
                size="small"
                color={alertColor}
                className={classes.actionButton}
                onClick={handleResolve}
              >
                {resolveLabel || <FormattedMessage {...messages.resolveBtn} />}
              </Button>
            )}
            {!!onConnect && (
              <Button
                disabled={isDisabled}
                variant="contained"
                size="small"
                className={classes.actionButton}
                onClick={handleConnect}
              >
                <Typography color="error" variant="inherit">
                  <FormattedMessage {...messages.connectBtn} />
                </Typography>
              </Button>
            )}
          </>
        }
      >
        <Typography variant="h5" component="span" color="inherit">
          {title}
        </Typography>
      </MaterialAlert>
    ) : (
      <MaterialAlert
        className={cx(classes.root, extraClassName)}
        icon={<Icon className={classes.icon} />}
      >
        {title}
        {Boolean(onIgnore || onRemindLater || onResolve || onConnect || onViewNote) && (
          <>
            <Button onClick={handleClick} className={classes.dotsIcon}>
              <MoreHoriz />
            </Button>
            <Menu
              open={Boolean(anchorEl)}
              onClose={handleClose}
              id="menu-alert-actions"
              anchorEl={anchorEl}
            >
              {!!onIgnore && (
                <MenuItem disabled={isDisabled} onClick={handleIgnore}>
                  <FormattedMessage {...messages.ignoreBtn} />
                </MenuItem>
              )}
              {!!onRemindLater && (
                <MenuItem disabled={isDisabled} onClick={handleRemindLater}>
                  <FormattedMessage {...messages.remindLaterBtn} />
                </MenuItem>
              )}
              {!!onResolve && (
                <MenuItem disabled={isDisabled} onClick={handleResolve}>
                  {resolveLabel || <FormattedMessage {...messages.resolveBtn} />}
                </MenuItem>
              )}
              {!!onConnect && (
                <MenuItem disabled={isDisabled} onClick={handleConnect}>
                  <FormattedMessage {...messages.connectBtn} />
                </MenuItem>
              )}
              {!!onViewNote && (
                <MenuItem disabled={isDisabled} onClick={handleConnect}>
                  <FormattedMessage {...messages.viewNoteBtn} />
                </MenuItem>
              )}
            </Menu>
          </>
        )}
      </MaterialAlert>
    );
  },
);

export default Alert;
