// libraries
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Box, Grid, Typography } from '@material-ui/core';
import cx from 'classnames';

// custom interfaces
import { CustomTheme } from 'common/ui/interfaces';
// helpers
import { getInitials } from 'helpers/common';
// components
import MemberStatusView from '../MemberStatusView/MemberStatusView';
// img
import profilePlaceholder from 'img/photo-placeholder.png';

interface IPersonSimpleViewProps {
  firstName: string;
  lastName: string;
  imageUrl?: string;

  bold?: boolean;
  memberStatus?: string;
  titleColor?:
    | 'initial'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'textPrimary'
    | 'textSecondary'
    | 'error';
  isHeaderTitle?: boolean;
  className?: string;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  avatar: {
    width: '28px',
    height: '28px',
  },
  avatarWrap: {
    position: 'relative',
    flex: 'none',
    borderRadius: '50%',
    backgroundColor: theme.palette.secondary.light,
  },
  avatarInitial: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    fontSize: '12px',
    fontWeight: 700,
    color: theme.palette.darkBackground.light,
    whiteSpace: 'nowrap',
  },
}));

const PersonSimpleView: React.FC<IPersonSimpleViewProps> = (
  props: IPersonSimpleViewProps,
): JSX.Element => {
  const classes = useStyles(props);
  const {
    firstName,
    lastName,
    imageUrl,
    memberStatus,
    bold,
    titleColor,
    isHeaderTitle = false,
    className,
  } = props;

  const initials = getInitials(firstName, lastName);

  const isBoldTitleVariant = bold ? 'subtitle2' : 'body1';
  const titleVariant = isHeaderTitle ? 'h2' : isBoldTitleVariant;
  // renders

  return (
    <Grid className={className} container spacing={1} alignItems="center" wrap="nowrap">
      <Grid item>
        <Box className={cx(classes.avatar, classes.avatarWrap)}>
          {initials && !imageUrl ? (
            <Box className={classes.avatarInitial}>{initials}</Box>
          ) : (
            <Avatar
              src={imageUrl || profilePlaceholder}
              className={classes.avatar}
              alt={firstName}
            />
          )}
        </Box>
      </Grid>

      {!!memberStatus && (
        <Grid item>
          <MemberStatusView status={memberStatus} />
        </Grid>
      )}

      <Grid item>
        <Typography
          variant={titleVariant}
          color={titleColor}
          noWrap
        >{`${firstName} ${lastName}`}</Typography>
      </Grid>
    </Grid>
  );
};

export default React.memo(PersonSimpleView);
