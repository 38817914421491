import { defineMessages } from 'react-intl';

export default defineMessages({
  todayLabel: {
    id: 'app.modules.Booking.todayLabel',
    defaultMessage: 'Today',
  },
  selectClubHelperLabel: {
    id: 'app.modules.Booking.labels.clubsAvailability',
    defaultMessage: 'Start typing to Search for clubs, or click down arrow to select from list',
  },
  allowDurationChangeLabel: {
    id: 'app.modules.Booking.labels.allowDurationChangeLabel',
    defaultMessage: 'Allow duration change upon booking',
  },
  applyChangesFutureEventsLabel: {
    id: 'app.modules.Booking.labels.applyChangesFutureEventsLabel',
    defaultMessage: 'Apply changes to future events',
  },
  notifyParticipantsLabel: {
    id: 'app.modules.Booking.labels.notifyParticipantsLabel',
    defaultMessage: 'Notify participants via:',
  },
  remindParticipantsLabel: {
    id: 'app.modules.Booking.labels.remindParticipantsLabel',
    defaultMessage: 'Remind participants via:',
  },
  preventJoiningLabel: {
    id: 'app.modules.Booking.labels.preventJoiningLabel',
    defaultMessage: 'Prevent joining after',
  },
  preventCancellingLabel: {
    id: 'app.modules.Booking.labels.preventCancellingLabel',
    defaultMessage: 'Prevent cancelling after',
  },
  preventEditingLabel: {
    id: 'app.modules.Booking.labels.preventEditingLabel',
    defaultMessage: 'Prevent editing after',
  },
  noParticipantsAddedLabel: {
    id: 'app.modules.Booking.labels.noParticipantsAddedLabel',
    defaultMessage: 'No participants added yet.',
  },
  availableSpotsLabel: {
    id: 'app.modules.Booking.labels.availableSpotsLabel',
    defaultMessage: '{restSpots} of {spots} spots available to join',
  },
  noAvailableSpotsLabel: {
    id: 'app.modules.Booking.labels.noAvailableSpotsLabel',
    defaultMessage: 'No available spots',
  },
  unlimitedNumberLabel: {
    id: 'app.modules.Booking.labels.unlimitedNumberLabel',
    defaultMessage: 'Unlimited number can join',
  },
  limitClassSpotsLabel: {
    id: 'app.modules.Booking.labels.limitClassSpotsLabel',
    defaultMessage: 'Limit class spots',
  },
  allowSelfBooking: {
    id: 'app.modules.Booking.labels.allowSelfBooking',
    defaultMessage: 'Allow self-booking',
  },
  // buttons
  eventBtn: {
    id: 'app.modules.Booking.eventBtn',
    defaultMessage: 'Event',
  },
  clearAllBtn: {
    id: 'app.modules.Booking.clearAllBtn',
    defaultMessage: 'Clear',
  },
  cancelEventBtn: {
    id: 'app.modules.Booking.cancelEventBtn',
    defaultMessage: 'Cancel Event',
  },
  cancelParticipation: {
    id: 'app.modules.Booking.cancelParticipation',
    defaultMessage: 'Cancel Participation',
  },
  confirmParticipation: {
    id: 'app.modules.Booking.confirmParticipation',
    defaultMessage: 'Confirm Participation',
  },
  restoreEventBtn: {
    id: 'app.modules.Booking.restoreEventBtn',
    defaultMessage: 'Restore Event',
  },
  modificationHistory: {
    id: 'app.modules.Booking.modificationHistory',
    defaultMessage: 'Modification history',
  },
  resources: {
    id: 'app.modules.Booking.resources',
    defaultMessage: 'Resources',
  },
  tags: {
    id: 'app.modules.Booking.tags',
    defaultMessage: 'Tags',
  },
  share: {
    id: 'app.modules.Booking.share',
    defaultMessage: 'Share',
  },
  newEvent: {
    id: 'app.modules.Booking.newEvent',
    defaultMessage: 'New Event',
  },
  editEvent: {
    id: 'app.modules.Booking.editEvent',
    defaultMessage: 'Edit Event',
  },
  createAppointment: {
    id: 'app.modules.Booking.createAppointment',
    defaultMessage: 'Create appointment',
  },
  additional: {
    id: 'app.modules.Booking.additional',
    defaultMessage: 'Additional',
  },
  // delete event
  deleteEventTitle: {
    id: 'app.routes.booking.event.delete.title',
    defaultMessage: 'Delete Event',
  },
  deleteEventBtn: {
    id: 'app.routes.booking.event.delete.button',
    defaultMessage: 'Delete Event',
  },
  deleteEventWarning: {
    id: 'app.routes.booking.event.delete.warning',
    defaultMessage: "Note, event can't be restored later.",
  },
  cancelEventWarning: {
    id: 'app.routes.booking.event.cancel.warning',
    defaultMessage: 'Note, event can be restored later.',
  },
  deleteEventOnlyThis: {
    id: 'app.routes.booking.event.delete.onlyThisEvent',
    defaultMessage: 'Delete this event only',
  },
  deleteEventAllFuture: {
    id: 'app.routes.booking.event.delete.allFuture',
    defaultMessage: 'Delete all future recurring events',
  },
  cancelEventOnlyThis: {
    id: 'app.routes.booking.event.cancel.onlyThisEvent',
    defaultMessage: 'Cancel this event only',
  },
  cancelEventAllFuture: {
    id: 'app.routes.booking.event.cancel.allFuture',
    defaultMessage: 'Cancel all future recurring events',
  },
  deleteEventReasonLabel: {
    id: 'app.routes.booking.event.delete.reasonLabel',
    defaultMessage: 'Reason',
  },
  deleteResourceWarning: {
    id: 'app.routes.booking.resource.deleteResourceWarning',
    defaultMessage: 'Do you really want to delete resource - {resourceName}',
  },
  newResource: {
    id: 'app.modules.Booking.newResource',
    defaultMessage: 'New Resource',
  },
  deleteResource: {
    id: 'app.modules.Booking.deleteResource',
    defaultMessage: 'Delete resource?',
  },
  editResource: {
    id: 'app.modules.Booking.editResource',
    defaultMessage: 'Edit Resource',
  },
  approveChangeAllowedParticipantsMsg: {
    id: 'app.modules.booking.event.changeAllowedParticipantsModal.msg',
    defaultMessage:
      'All already added participants which are belong to deleting group will be removed',
  },
  copyParticipantsListMsg: {
    id: 'app.modules.booking.duplicateParticipants.modal.msg',
    defaultMessage: 'Do you need to copy participants list?',
  },
  timezoneIsDifferentMsg: {
    id: 'app.modules.booking.eventForm.clubSection.msg',
    defaultMessage:
      "Your timezone view {timezoneViewOffset} is different from club's timezone {clubZoneOffset}. Event will be created on {eventDate} for selected club.",
  },
  canNotEditEventMsg: {
    id: 'app.modules.booking.eventForm.canNotEditEvent.msg',
    defaultMessage:
      "You can't edit events before 2 hours of their start. Participants already confirmed their attendance and redeemed sessions.",
  },
  canNotRedeemEventMsg: {
    id: 'app.modules.booking.eventForm.canNotRedeemEvent.msg',
    defaultMessage: "You can't redeem events in future - only within next 2 hours after now.",
  },
  // titles
  appointmentNewMemberTourTitle: {
    id: 'app.modules.Booking.appointmentNewMemberTourTitle',
    defaultMessage: 'Tour ({name})',
  },
  resourcesPageTitle: {
    id: 'app.routes.booking.resources.pageTitle',
    defaultMessage: 'Resources',
  },
  approveChangeAllowedParticipantsTitle: {
    id: 'app.routes.booking.resources.pageTitle',
    defaultMessage: 'Change allowed participants',
  },
  duplicateParticipantsModalTitle: {
    id: 'app.modules.booking.duplicateParticipants.modal.title',
    defaultMessage: 'Duplicate Event',
  },
  remindParticipantsModalTitle: {
    id: 'app.modules.booking.remindParticipants.modal.title',
    defaultMessage: 'Remind Participants',
  },
  // labels
  eventEditChangeTypeLabel: {
    id: 'app.modules.booking.eventDetails.modal.eventEditChangeType.label',
    defaultMessage: 'Edited event',
  },
  eventCreateChangeTypeLabel: {
    id: 'app.modules.booking.eventDetails.modal.eventCreateChangeType.label',
    defaultMessage: 'Created event',
  },
  // appointment reminder types
  appointmentReminderDays: {
    id: 'app.modules.Booking.appointmentReminder.days',
    defaultMessage: 'Days',
  },
  appointmentReminderWeeks: {
    id: 'app.modules.Booking.appointmentReminder.weeks',
    defaultMessage: 'Weeks',
  },
  appointmentReminderMonths: {
    id: 'app.modules.Booking.appointmentReminder.months',
    defaultMessage: 'Months',
  },
  appointmentReminderYears: {
    id: 'app.modules.Booking.appointmentReminder.years',
    defaultMessage: 'Years',
  },
  // repeat frequencies
  repeatFrequencyDaily: {
    id: 'app.modules.Booking.repeatFrequency.daily',
    defaultMessage: 'Daily',
  },
  repeatFrequencyWeekly: {
    id: 'app.modules.Booking.repeatFrequency.weekly',
    defaultMessage: 'Weekly',
  },
  repeatFrequencyMonthly: {
    id: 'app.modules.Booking.repeatFrequency.monthly',
    defaultMessage: 'Monthly',
  },
  repeatFrequencySemiAnnually: {
    id: 'app.modules.Booking.repeatFrequency.semiAnnually',
    defaultMessage: 'Semi-annually',
  },
  repeatFrequencyAnnually: {
    id: 'app.modules.Booking.repeatFrequency.annually',
    defaultMessage: 'Annually',
  },
  // repeat ends
  repeatEndForever: {
    id: 'app.modules.Booking.repeatEnd.forever',
    defaultMessage: 'Repeat forever',
  },
  repeatEndTimes: {
    id: 'app.modules.Booking.repeatEnd.times',
    defaultMessage: 'Repeat N times',
  },
  repeatEndOnDate: {
    id: 'app.modules.Booking.repeatEnd.onDate',
    defaultMessage: 'On date',
  },
  // attendee types
  attendeeTypeMember: {
    id: 'app.modules.Booking.attendeeType.attendeeTypeMember',
    defaultMessage: 'Member',
  },
  attendeeTypeProspect: {
    id: 'app.modules.Booking.attendeeType.attendeeTypeProspect',
    defaultMessage: 'Prospect',
  },
  attendeeTypeEmployee: {
    id: 'app.modules.Booking.attendeeType.attendeeTypeEmployee',
    defaultMessage: 'Employee',
  },
  // overbooking types
  overbookingTypeNotAllowed: {
    id: 'app.modules.Booking.overbookingType.overbookingTypeNotAllowed',
    defaultMessage: "Don't allow overbooking",
  },
  overbookingTypeWaitlist: {
    id: 'app.modules.Booking.overbookingType.overbookingTypeWaitlist',
    defaultMessage: 'Waitlist',
  },
  overbookingTypeDuplicateEvent: {
    id: 'app.modules.Booking.overbookingType.overbookingTypeDuplicateEvent',
    defaultMessage: 'Duplicate event for extra participants',
  },
  // edit recurring event
  editRecurringModalTitle: {
    id: 'app.modules.booking.editRecurring.modal.title',
    defaultMessage: 'Edit recurring event',
  },
  editRecurringThisEventOption: {
    id: 'app.modules.booking.editRecurring.options.this',
    defaultMessage: 'This event',
  },
  editRecurringFutureEventOption: {
    id: 'app.modules.booking.editRecurring.options.future',
    defaultMessage: 'This and following events',
  },
  editRecurringAllEventOption: {
    id: 'app.modules.booking.editRecurring.options.all',
    defaultMessage: 'All events',
  },
});
