import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, makeStyles, Popper, SvgIcon, Tooltip, Typography } from '@material-ui/core';

import { InfoIcon, CheckIcon } from 'img/icons';
import { IMemberPhoneItem } from 'common/components/PersonProfile/interfaces';
import profileMessages from 'common/components/Steps/MemberPrimaryInfoStep/messages';
import { ReactComponent as XDeleteIcon } from 'img/icons/x-bold_deprecated.svg';
import { convertPhoneNumber } from 'helpers/common';

const useStyles = makeStyles(theme => ({
  infoIcon: {
    color: theme.palette.text.secondary,
    width: '1.05rem',
    height: '1.05rem',
    marginLeft: theme.spacing(0.75),
    '&:hover': {
      color: theme.palette.darkBackground.main,
    },
  },
  popper: {
    backgroundColor: theme.palette.text.secondary,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(1.25),
    borderRadius: 3,
  },
  itemWrapper: {
    display: 'flex',
    alignItems: 'center',
    '&:nth-of-type(n+2)': {
      marginTop: 10,
    },
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
}));

interface IMemberPhoneItemProps {
  phoneData: IMemberPhoneItem;
  showComma?: boolean;
  className?: string;
}

const MemberPhoneItem = ({
  phoneData,
  showComma,
  className,
}: IMemberPhoneItemProps): JSX.Element => {
  const { useAsDefault, allowAutomatedCalls, canReceiveSms, phone } = phoneData;
  const classes = useStyles();

  return (
    <Box display="inline-flex" alignItems="center" className={className}>
      <Typography variant={useAsDefault ? 'h6' : 'body1'} component="span">
        {convertPhoneNumber(phone)}
      </Typography>
      {phone && (
        <Tooltip
          title={phone}
          PopperComponent={props => (
            <Popper {...props}>
              <Box className={classes.popper}>
                <Typography color="inherit" className={classes.itemWrapper}>
                  {allowAutomatedCalls ? (
                    <SvgIcon color="inherit" component={CheckIcon} fontSize="inherit" />
                  ) : (
                    <SvgIcon color="inherit" component={XDeleteIcon} fontSize="inherit" />
                  )}
                  <Typography variant="body2" component="span" color="inherit">
                    <FormattedMessage
                      {...profileMessages.newMemberPrimaryInfoAllowAutomatedCalls}
                    />
                  </Typography>
                </Typography>
                <Typography color="inherit" className={classes.itemWrapper}>
                  {canReceiveSms ? (
                    <SvgIcon color="inherit" component={CheckIcon} fontSize="inherit" />
                  ) : (
                    <SvgIcon color="inherit" component={XDeleteIcon} fontSize="inherit" />
                  )}
                  <Typography variant="body2" component="span" color="inherit">
                    <FormattedMessage
                      {...profileMessages.newMemberPrimaryInfoCanReceiveTextMessages}
                    />
                  </Typography>
                </Typography>
              </Box>
            </Popper>
          )}
        >
          <InfoIcon color="inherit" className={classes.infoIcon} fontSize="small" />
        </Tooltip>
      )}
      {showComma && <Typography>,&nbsp;</Typography>}
    </Box>
  );
};

export default React.memo(MemberPhoneItem);
