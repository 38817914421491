import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import moment from 'moment-timezone';

import { ILead, ISalesPerson } from 'modules/crm/interfaces/tasks';
import { TASK_DATE_FORMAT } from 'modules/crm/constants/tasks';

import ActivityTypeChip from 'modules/crm/components/ActivityTypeChip/ActivityTypeChip';
import LeadName from '../LeadName/LeadName';
import SalespersonName from '../SalespersonName/SalespersonName';

interface IProps {
  taskType: string;
  lead: ILead;
  leadStatus: string;
  salesperson: ISalesPerson;
  date: string;
  isCrmModule: boolean;
}

const SmallScreenCell = (props: IProps) => {
  const { taskType, lead, leadStatus, salesperson, date, isCrmModule } = props;

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} style={{ marginTop: 8 }}>
        <ActivityTypeChip name={taskType} />
      </Grid>

      <Grid item xs={12}>
        <LeadName
          id={lead.firstName}
          firstName={lead.firstName}
          lastName={lead.lastName || ''}
          photoUrl={lead.imageUrl}
          status={leadStatus}
          small
          isCrmModule={isCrmModule}
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container wrap="nowrap" alignItems="center" spacing={1}>
          <Grid item>
            <SalespersonName
              id={salesperson.firstName}
              firstName={salesperson.firstName}
              lastName={salesperson.lastName}
              small
            />
          </Grid>

          <Grid item>
            <HourglassEmptyIcon style={{ fontSize: 16 }} />
          </Grid>

          <Grid item>
            <Typography variant="body2" color="textSecondary">
              {moment(date).format(TASK_DATE_FORMAT)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(SmallScreenCell);
