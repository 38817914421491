import React from 'react';
import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { selectLocale } from 'common/state/settings/selectors';

interface IProps {
  messages: any;
  children: React.ReactNode;
}

export const LanguageProvider: React.FC<IProps> = ({ messages, children }: IProps) => {
  const locale = useSelector(selectLocale);
  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      {React.Children.only(children)}
    </IntlProvider>
  );
};

export default LanguageProvider;
