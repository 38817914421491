import React from 'react';
import { Route, Routes as Switch } from 'react-router-dom';

import {
  Campaigns,
  CRMTemplates,
  EditCampaign,
  EditEmail,
  EditLead,
  EditScript,
  EditSMS,
  LeadProfile,
  Leads,
  MainPage,
  NewCampaign,
  NewLead,
  NewScript,
  Scripts,
  Tasks,
} from 'modules/crm/containers';
import CatchAll from 'routes/CatchAll';
import CustomRoute from 'modules/authentication/containers/CustomRoute/CustomRoute';

import { PermissionLevels } from 'common/constants/permissions';

export enum PtCrmRoutes {
  PT_CRM = 'personal-training-crm',
  SMS_TEMPLATES = 'templates/sms',
  SMS_TEMPLATES_NEW = 'templates/sms/new',
  SMS_TEMPLATES_EDIT = 'templates/sms/:id',
  EMAIL_TEMPLATES = 'templates/email',
  EMAIL_TEMPLATES_NEW = 'templates/email/new',
  EMAIL_TEMPLATES_EDIT = 'templates/email/:id',
  TASKS = 'tasks',
  SCRIPTS = 'scripts',
  SCRIPTS_NEW = 'scripts/new',
  LEADS = 'leads',
  LEADS_NEW = 'leads/new',
  LEADS_ID = 'leads/:id',
  LEADS_EDIT = 'leads/edit/:id',
  CAMPAIGNS = 'campaigns',
  CAMPAIGNS_NEW = 'campaigns/new',
  CAMPAIGNS_EDIT = 'campaigns/:id/edit',
  SCRIPTS_EDIT = 'scripts/:id/edit',
}

const Routes = (): JSX.Element => (
  <Switch>
    <Route
      path="/"
      element={
        <CustomRoute secure levels={[PermissionLevels.CORPORATE, PermissionLevels.CLIENT]}>
          <MainPage />
        </CustomRoute>
      }
    />

    <Route
      path={PtCrmRoutes.TASKS}
      element={
        <CustomRoute secure levels={[PermissionLevels.CORPORATE, PermissionLevels.CLIENT]}>
          <Tasks />
        </CustomRoute>
      }
    />

    <Route
      path={PtCrmRoutes.SCRIPTS}
      element={
        <CustomRoute secure levels={[PermissionLevels.CORPORATE, PermissionLevels.CLIENT]}>
          <Scripts />
        </CustomRoute>
      }
    />

    <Route
      path={PtCrmRoutes.SCRIPTS_NEW}
      element={
        <CustomRoute secure levels={[PermissionLevels.CORPORATE, PermissionLevels.CLIENT]}>
          <NewScript />
        </CustomRoute>
      }
    />

    <Route
      path={PtCrmRoutes.LEADS}
      element={
        <CustomRoute secure levels={[PermissionLevels.CORPORATE, PermissionLevels.CLIENT]}>
          <Leads />
        </CustomRoute>
      }
    />

    <Route
      path={PtCrmRoutes.LEADS_NEW}
      element={
        <CustomRoute secure levels={[PermissionLevels.CORPORATE, PermissionLevels.CLIENT]}>
          <NewLead />
        </CustomRoute>
      }
    />

    <Route
      path={PtCrmRoutes.LEADS_EDIT}
      element={
        <CustomRoute secure levels={[PermissionLevels.CORPORATE, PermissionLevels.CLIENT]}>
          <EditLead />
        </CustomRoute>
      }
    />

    <Route
      path={PtCrmRoutes.LEADS_ID}
      element={
        <CustomRoute secure levels={[PermissionLevels.CORPORATE, PermissionLevels.CLIENT]}>
          <LeadProfile />
        </CustomRoute>
      }
    />

    <Route
      path={PtCrmRoutes.CAMPAIGNS}
      element={
        <CustomRoute secure levels={[PermissionLevels.CORPORATE, PermissionLevels.CLIENT]}>
          <Campaigns />
        </CustomRoute>
      }
    />

    <Route
      path={PtCrmRoutes.CAMPAIGNS_NEW}
      element={
        <CustomRoute secure levels={[PermissionLevels.CORPORATE, PermissionLevels.CLIENT]}>
          <NewCampaign />
        </CustomRoute>
      }
    />

    <Route
      path={PtCrmRoutes.CAMPAIGNS_EDIT}
      element={
        <CustomRoute secure levels={[PermissionLevels.CORPORATE, PermissionLevels.CLIENT]}>
          <EditCampaign />
        </CustomRoute>
      }
    />

    <Route
      path={PtCrmRoutes.SCRIPTS_EDIT}
      element={
        <CustomRoute secure levels={[PermissionLevels.CORPORATE, PermissionLevels.CLIENT]}>
          <EditScript />
        </CustomRoute>
      }
    />

    <Route
      path={PtCrmRoutes.SMS_TEMPLATES}
      element={
        <CustomRoute secure levels={[PermissionLevels.CORPORATE, PermissionLevels.CLIENT]}>
          <CRMTemplates />
        </CustomRoute>
      }
    />

    <Route
      path={PtCrmRoutes.SMS_TEMPLATES_NEW}
      element={
        <CustomRoute secure levels={[PermissionLevels.CORPORATE, PermissionLevels.CLIENT]}>
          <EditSMS />
        </CustomRoute>
      }
    />

    <Route
      path={PtCrmRoutes.EMAIL_TEMPLATES}
      element={
        <CustomRoute secure levels={[PermissionLevels.CORPORATE, PermissionLevels.CLIENT]}>
          <CRMTemplates />
        </CustomRoute>
      }
    />

    <Route
      path={PtCrmRoutes.SMS_TEMPLATES_EDIT}
      element={
        <CustomRoute secure levels={[PermissionLevels.CORPORATE, PermissionLevels.CLIENT]}>
          <EditSMS />
        </CustomRoute>
      }
    />

    <Route
      path={PtCrmRoutes.EMAIL_TEMPLATES_NEW}
      element={
        <CustomRoute secure levels={[PermissionLevels.CORPORATE, PermissionLevels.CLIENT]}>
          <EditEmail />
        </CustomRoute>
      }
    />

    <Route
      path={PtCrmRoutes.EMAIL_TEMPLATES_EDIT}
      element={
        <CustomRoute secure levels={[PermissionLevels.CORPORATE, PermissionLevels.CLIENT]}>
          <EditEmail />
        </CustomRoute>
      }
    />

    <Route element={<CatchAll />} />
  </Switch>
);

export default Routes;
