import React from 'react';
import { useLocation } from 'react-router-dom';

import { ReactComponent as Tasks } from 'img/icons/tasks.svg';
import { ReactComponent as Leads } from 'img/icons/leads.svg';
import { ReactComponent as Scripts } from 'img/icons/scripts.svg';
import { ReactComponent as Comment } from 'img/icons/comment.svg';
import { ReactComponent as Campaigns } from 'img/icons/campaigns.svg';

import messages from 'modules/crm/messages/common';
import ptMessages from 'modules/personal-training-crm/messages/messages';

import { MainModulePage } from 'common/components';
import { useResetInvoiceData } from 'common/hooks/useResetInvoiceData';

const MainPage = (): JSX.Element => {
  const location = useLocation();

  useResetInvoiceData();

  const isPersonalTrainingCrm = location.pathname === '/personal-training-crm';
  const mainTitle = isPersonalTrainingCrm ? 'Personal training CRM' : 'CRM';

  const CRMModules = [
    {
      id: 'tasks',
      message: isPersonalTrainingCrm ? ptMessages.ptTasks : messages.tasks,
      link: isPersonalTrainingCrm ? '/personal-training-crm/tasks' : '/crm/tasks',
      iconComponent: Tasks,
      viewBox: '0 0 18 24',
    },
    {
      id: 'leads',
      message: isPersonalTrainingCrm ? ptMessages.ptLeads : messages.leads,
      link: isPersonalTrainingCrm ? '/personal-training-crm/leads' : '/crm/leads',
      iconComponent: Leads,
      viewBox: '0 0 34 24',
    },
    {
      id: 'scripts',
      message: isPersonalTrainingCrm ? ptMessages.ptScripts : messages.scripts,
      link: isPersonalTrainingCrm ? '/personal-training-crm/scripts' : '/crm/scripts',
      iconComponent: Scripts,
      viewBox: '0 0 29 24',
    },
    {
      id: 'campaigns',
      message: isPersonalTrainingCrm ? ptMessages.ptCampaigns : messages.campaigns,
      link: isPersonalTrainingCrm ? '/personal-training-crm/campaigns' : '/crm/campaigns',
      iconComponent: Campaigns,
      viewBox: '0 0 27 24',
    },
    {
      id: 'documentTemplates',
      message: isPersonalTrainingCrm ? ptMessages.ptDocumentTemplates : messages.templates,
      link: isPersonalTrainingCrm ? '/personal-training-crm/templates/sms' : '/crm/templates/sms',
      iconComponent: Comment,
      viewBox: '0 0 24 24',
    },
  ];

  return <MainModulePage routes={CRMModules} pageTitle={mainTitle} />;
};

export default MainPage;
