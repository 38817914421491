import { Dispatch, SetStateAction } from 'react';
import { Dispatch as ReduxDispatch } from 'redux';
import { NavigateFunction } from 'react-router-dom';

import {
  FilterTypes,
  IDateRangeFilterValue,
  IFilter,
  IFilterSettings,
  IFilterValue,
  IMultipleFilterValue,
  ISingleFilterValue,
} from '../interfaces/filter';
import { ITableParams } from '../interfaces/table';
import { DEFAULT_TABLE_PARAMS } from 'common/constants';
import { deepClone } from '.';
import Services from 'services/network';
import { enqueueErrorNotification } from 'common/state/notifications/actions';
import { ITagOption } from 'common/interfaces/common';

export function urlDecode(str: string): string {
  return decodeURIComponent(`${str}`.replace(/\+/g, '%20'));
}

export function makeRequestUrl(
  url: string,
  requestOptions?: ITableParams,
  isHidePagination?: boolean,
  isHideOrder?: boolean,
): string {
  const paramsArray: string[] = [];

  if (requestOptions) {
    const { page, perPage, searchStr, order, orderBy, calendarView } = requestOptions;

    if (!isHidePagination) paramsArray.push(`page=${page}`, `perPage=${perPage}`);
    if (searchStr) paramsArray.push(`searchStr=${searchStr}`);
    if (!isHideOrder && order !== 'asc') paramsArray.push(`order=${order}`);
    if (!isHideOrder && orderBy) paramsArray.push(`orderBy=${String(orderBy)}`);
    if (calendarView) paramsArray.push(`calendarView=${calendarView}`);
  }

  return `${url}${paramsArray ? `?${paramsArray.join('&')}` : ''}`;
}

export function makeRequestUrlFromFilter(url: string, filters: IFilter[]): string {
  const queryParamsString = filters
    .map(filter => {
      let queryString;
      const { name, type, value: filterResult } = filter;

      if (type === FilterTypes.SINGLE) {
        const { value } = filterResult as ISingleFilterValue;
        queryString = `${name}=${value}`;
      }

      if (type === FilterTypes.MULTIPLE || type === FilterTypes.MULTIPLE_WITH_PAGINATE) {
        const selectedOptions = filterResult as IMultipleFilterValue;
        queryString = `${name}=${selectedOptions.map(option => option.value).join(',')}`;
      }

      if (type === FilterTypes.DATE_RANGE) {
        const dateRangeValue: IDateRangeFilterValue = filterResult as IDateRangeFilterValue;

        const startDate = new Date(dateRangeValue.startDate).valueOf();
        const endDate = new Date(dateRangeValue.endDate).valueOf();

        queryString = `${name}StartDate=${startDate}&${name}EndDate=${endDate}`;
      }

      return queryString;
    })
    .join('&');

  return `${url}${queryParamsString ? `?${queryParamsString}` : ''}`;
}

export function pushQueryToUrl(
  navigate: NavigateFunction,
  tableProps: ITableParams,
  updateQuery?: (value) => void,
  isHidePagination = false,
  isHideOrder = false,
): string {
  const queryParams = `${makeRequestUrl('', tableProps, isHidePagination, isHideOrder)}${
    tableProps.filters.length > 0
      ? `&${makeRequestUrlFromFilter('', tableProps.filters).slice(1)}`
      : ''
  }`;
  navigate(queryParams);
  if (updateQuery) updateQuery(queryParams);

  return queryParams;
}

function getFilterValue(
  filterSettings: IFilterSettings[],
  selectedFilters: Array<string[]>,
): IFilter[] {
  const filters = filterSettings.reduce((acc, filterSettingItem): any => {
    let value: IFilterValue;
    let filterValue;

    if (filterSettingItem.type === FilterTypes.DATE_RANGE) {
      const startDate = selectedFilters.find((filterKeyValueArray: string[]) =>
        filterKeyValueArray[0].endsWith('StartDate'),
      )?.[1];
      const endDate = selectedFilters.find((filterKeyValueArray: string[]) =>
        filterKeyValueArray[0].endsWith('EndDate'),
      )?.[1];

      filterValue =
        startDate || endDate
          ? ({
              startDate: startDate ? new Date(Number(startDate)) : null,
              endDate: endDate ? new Date(Number(endDate)) : null,
            } as IDateRangeFilterValue)
          : null;
    } else {
      filterValue = selectedFilters.find(
        (filterKeyValueArray: string[]) => filterSettingItem.name === filterKeyValueArray[0],
      )?.[1];
    }

    if (!filterSettingItem.defaultValue && !filterValue) return acc;

    if (filterSettingItem.defaultValue && !filterValue) {
      value = filterSettingItem.defaultValue;
    } else {
      if (filterSettingItem.type === FilterTypes.SINGLE) {
        let convertedFilterValue: any = filterValue;
        if (filterValue === 'true') convertedFilterValue = true;
        if (filterValue === 'false') convertedFilterValue = false;

        if (!filterSettingItem.options.length) {
          value = {
            key: filterValue,
            label: filterValue,
            value: filterValue,
          };
        } else {
          value = filterSettingItem.options.find(option => option.value === convertedFilterValue);
        }
      }

      if (
        filterSettingItem.type === FilterTypes.MULTIPLE ||
        filterSettingItem.type === FilterTypes.MULTIPLE_WITH_PAGINATE
      ) {
        value = filterValue?.split(',').map(val => ({ key: val, label: val, value: val }));
      }

      if (filterSettingItem.type === FilterTypes.DATE_RANGE) {
        value = filterValue;
      }
    }

    const updatedFilter: IFilter = {
      name: filterSettingItem.name,
      type: filterSettingItem.type,
      value,
      defaultValue: filterSettingItem.defaultValue ?? null,
    } as IFilter;

    acc.push(updatedFilter);

    return acc;
  }, []);

  return filters;
}

export function makeTableParams(
  filtersSettings: IFilterSettings[],
  queryStr: string,
  defaultParams?: Partial<ITableParams>,
): ITableParams {
  let newTableParams: ITableParams = deepClone({ ...DEFAULT_TABLE_PARAMS, ...defaultParams });
  const selectedFilters = [];

  const decodedQuery = urlDecode(queryStr);

  const querySeparateParams = decodedQuery ? decodedQuery.slice(1).split('&') : [];

  querySeparateParams.forEach(param => {
    const keyValueArray: Array<string> = param.split('=');
    switch (keyValueArray[0]) {
      case 'page': {
        newTableParams.page = Number(keyValueArray[1]);
        break;
      }
      case 'perPage': {
        newTableParams.perPage = Number(keyValueArray[1]);
        break;
      }
      case 'order': {
        newTableParams.order = keyValueArray[1] === 'desc' ? 'desc' : 'asc';
        break;
      }
      case 'searchStr': {
        newTableParams = { ...newTableParams, searchStr: keyValueArray[1] };
        break;
      }
      case 'orderBy': {
        newTableParams = { ...newTableParams, orderBy: keyValueArray[1] };
        break;
      }
      default: {
        selectedFilters.push(keyValueArray);
        break;
      }
    }
  });

  const resultFiltersSettings = getFilterValue(filtersSettings, selectedFilters);

  return { ...newTableParams, filters: resultFiltersSettings };
}

export const changeAllergies = async (
  values: ITagOption[],
  onChange: (val: ITagOption[]) => void,
  dispatch: ReduxDispatch,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
) => {
  try {
    const addedValue = values.find(item => !item.id);

    if (addedValue) {
      setIsLoading(true);

      const createdAllergy = await Services.Allergies.createAllergy(addedValue);

      onChange(values.map(val => (val.id ? val : createdAllergy)));
    } else {
      onChange(values);
    }
  } catch (e) {
    dispatch(enqueueErrorNotification(e));
  } finally {
    setIsLoading(false);
  }
};

export const parseErrorArgs = (args: string[]): Record<string, string> => {
  return args.reduce((acc, argItem, index) => ({ ...acc, [`variable_${index}`]: argItem }), {});
};
