import { IGiftCard } from '../../../interfaces/invoices';
import { Box, Button, IconButton, makeStyles, Typography } from '@material-ui/core';
import RemoveInvoiceItemController from '../RemoveInvoiceItemController/RemoveInvoiceItemController';
import React, { useState } from 'react';
import { CustomTheme } from '../../../ui/interfaces';
import { FormattedMessage, useIntl } from 'react-intl';
import commonMessages from '../../../messages/messages';
import { formatNumberToPrice } from '../../../utils';
import GiftCardAvailableClubModal from './GiftCardAvailableClubModal';
import { Info } from '@material-ui/icons';
import invoiceMessages from 'common/components/InvoiceOperating/messages';

interface IStyleProps {
  isAddItems?: boolean;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    padding: theme.spacing(2, 0),
    borderBottom: `1px solid ${theme.palette.borderColor.light}`,
    margin: theme.spacing(0),
  },
  pushRight: {
    marginLeft: 'auto',
  },
  itemTitle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    rowGap: theme.spacing(0.5),
  },
  maxTotalInvoice: {
    display: 'inline-block',
    marginTop: theme.spacing(0.5),
    color: theme.palette.secondary.dark,
  },
  heavyTitle: ({ isAddItems }: IStyleProps) =>
    isAddItems
      ? {
          fontSize: '1rem',
          fontWeight: 500,
        }
      : {
          fontWeight: 700,
        },
  actionButton: {
    marginLeft: theme.spacing(1),
  },
}));

interface IInvoiceGiftCardItemProps {
  giftCard: IGiftCard;
  onDelete?: () => void;
  onAdd?: () => void;
  disabled?: boolean;
  isAddItems?: boolean;
  isAlreadyAdded?: boolean;
}

const InvoiceGiftCardItem = ({
  giftCard,
  onDelete,
  onAdd,
  disabled,
  isAddItems,
  isAlreadyAdded,
}: IInvoiceGiftCardItemProps) => {
  const classes = useStyles({ isAddItems });
  const intl = useIntl();

  const [isOpenAvailableInClubs, setIsOpenAvailableInClubs] = useState(false);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className={classes.root}
      >
        {!isAddItems && !!onDelete && (
          <RemoveInvoiceItemController onDelete={onDelete} disabled={disabled} />
        )}

        <Typography className={classes.itemTitle}>
          <Typography component="p" className={classes.heavyTitle}>
            {giftCard.name}

            {isAddItems && (
              <IconButton
                size="small"
                type="button"
                color="primary"
                onClick={() => setIsOpenAvailableInClubs(true)}
              >
                <Info />
              </IconButton>
            )}
          </Typography>

          {isAddItems && (
            <Typography component="span">
              <Typography component="span" className={classes.maxTotalInvoice}>
                {`${intl.formatMessage({ ...invoiceMessages.maxTotalInvoice })}:`}
              </Typography>
              <Typography component="span" variant="h5">
                {` ${giftCard.invoiceAmountPercentage}%`}
              </Typography>
            </Typography>
          )}
        </Typography>

        <Typography component="span" className={classes.pushRight}>
          <Typography component="span" className={classes.heavyTitle}>
            ${formatNumberToPrice(giftCard.amount)}
          </Typography>
        </Typography>

        {isAddItems && (
          <Button
            color={isAlreadyAdded ? 'default' : 'primary'}
            variant="contained"
            size="medium"
            className={classes.actionButton}
            onClick={() => (isAlreadyAdded ? onDelete() : onAdd())}
          >
            <FormattedMessage
              {...(!isAlreadyAdded ? commonMessages.addBtn : commonMessages.removeBtn)}
            />
          </Button>
        )}
      </Box>
      <GiftCardAvailableClubModal
        open={isOpenAvailableInClubs}
        onClose={() => setIsOpenAvailableInClubs(false)}
        clubs={giftCard.clubs}
      />
    </>
  );
};

export default InvoiceGiftCardItem;
