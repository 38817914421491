import React from 'react';
import { Box, IconButton, Menu, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as DotsIcon } from 'img/icons/dots-horizontal.svg';
import { ReactComponent as DotsVertIcon } from 'img/icons/dots-vertical.svg';
import { CustomTheme } from 'common/ui/interfaces';

interface IProps {
  menuAlign?: 'left' | 'right';
  iconColor?: 'textSecondary' | 'primary';
  icon?: JSX.Element;
  minWidth?: boolean;
  horizontal?: boolean;
  className?: string;
  tableActionsMode?: boolean;
  moreActionsBtn?: JSX.Element;

  children: React.ReactNode;

  onOpen?: () => void;
  onClose?: () => void;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  menu: {
    '& .MuiMenuItem-root .MuiListItemIcon-root:last-child': {
      marginLeft: 0,
    },
  },
  iconButton: {
    padding: '0',
    '&:hover': {
      backgroundColor: 'inherit',
    },

    color: ({ iconColor }: IProps) => {
      if (iconColor === 'primary') {
        return theme.palette.primary.main;
      }

      return theme.palette.text.secondary;
    },
  },
  iconButtonGutterTop: {
    marginTop: theme.spacing(1),
  },
}));

export default function ActionsMenu(props: IProps): JSX.Element {
  const {
    menuAlign = 'right',
    iconColor = 'textSecondary',
    minWidth = false,
    horizontal = false,
    moreActionsBtn,
    tableActionsMode = false,
    className,
    children,
    icon,
    onOpen,
    onClose,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const classes = useStyles(props);

  const handleOpenClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);

    if (onOpen) {
      onOpen();
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);

    if (onClose) {
      onClose();
    }
  };

  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    if (open) handleCloseMenu();
  };

  let moreActionsIcon =
    icon ||
    (horizontal ? <DotsIcon width={16} height={16} /> : <DotsVertIcon width={16} height={16} />);

  if (horizontal && tableActionsMode) {
    moreActionsIcon = <DotsIcon width={20} height={20} />;
  }

  const boxProps = tableActionsMode ? {} : { minWidth: minWidth ? 55 : 30 };

  return (
    <Box display="inline-flex" height="fit-content" className={className} {...boxProps}>
      {moreActionsBtn ? (
        <Box onClick={handleOpenClick}>{moreActionsBtn}</Box>
      ) : (
        <Tooltip title="More actions">
          <IconButton
            disableRipple
            className={classes.iconButton}
            aria-label="more"
            aria-controls="actions-menu"
            aria-haspopup="true"
            onClick={handleOpenClick}
          >
            {moreActionsIcon}
          </IconButton>
        </Tooltip>
      )}
      {open && (
        <Menu
          id="actions-menu"
          className={classes.menu}
          keepMounted
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: menuAlign,
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: menuAlign,
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          onClick={handleClickMenu}
        >
          {children}
        </Menu>
      )}
    </Box>
  );
}
