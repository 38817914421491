import { createAction } from '@reduxjs/toolkit';

import Services from 'services/network';
import { actionTypes } from 'modules/booking/constants';

import { enqueueErrorNotification } from 'common/state/notifications/actions';

import {
  IBookingEvent,
  IEventAction,
  IRemindAppointmentDto,
  IUpdatedEventDto,
} from 'modules/booking/interfaces';
import { GeneralThunkAction } from 'common/state/interfaces';
import { INamedEntity } from 'common/interfaces/common';
import { ITableParams } from 'common/interfaces/table';

const getBookingEventsLoading = createAction<boolean>(actionTypes.GET_BOOKING_EVENTS_LOADING);
const getBookingEventsAction = createAction<IBookingEvent[]>(actionTypes.GET_BOOKING_EVENTS);
export const resetBookingEvents = createAction(actionTypes.RESET_BOOKING_EVENTS);

export const getBookingEvents = (params?: ITableParams): GeneralThunkAction => {
  return async dispatch => {
    dispatch(getBookingEventsLoading(true));

    try {
      const events = await Services.Booking.getBookingEvents(params);

      dispatch(getBookingEventsAction(events.data));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(getBookingEventsLoading(false));
    }
  };
};

const getBookingEventLoading = createAction<boolean>(actionTypes.GET_BOOKING_EVENT_LOADING);
const getBookingEventAction = createAction<IBookingEvent>(actionTypes.GET_BOOKING_EVENT);
export const resetBookingEvent = createAction(actionTypes.RESET_BOOKING_EVENT_DETAILS);
export const getBookingEvent = (id: string, eventDate: string): GeneralThunkAction => {
  return async dispatch => {
    dispatch(getBookingEventLoading(true));

    try {
      const event = await Services.Booking.getBookingEvent(id, eventDate);
      dispatch(getBookingEventAction(event));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(getBookingEventLoading(false));
    }
  };
};

export const resetEventActionResult = createAction(actionTypes.RESET_EVENT_ACTION_RESULT);

const createEventLoading = createAction<boolean>(actionTypes.CREATE_EVENT_LOADING);
const createEventAction = createAction<IBookingEvent>(actionTypes.CREATE_EVENT);

export const createEvent = (event: IBookingEvent): GeneralThunkAction => {
  return async dispatch => {
    dispatch(createEventLoading(true));
    try {
      const newEvent = await Services.Booking.createEvent(event);
      dispatch(createEventAction(newEvent));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(createEventLoading(false));
    }
  };
};

const updateEventLoading = createAction<boolean>(actionTypes.UPDATE_EVENT_LOADING);
const updateEventAction = createAction<IBookingEvent>(actionTypes.UPDATE_EVENT);

export const updateEvent = (
  eventId: string,
  eventDate: string,
  event: IUpdatedEventDto,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(updateEventLoading(true));
    try {
      const updatedEvent = await Services.Booking.updateEvent(eventId, eventDate, event);
      dispatch(updateEventAction(updatedEvent));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(updateEventLoading(false));
    }
  };
};

const cancelEventLoading = createAction<boolean>(actionTypes.CANCEL_EVENT_LOADING);
const cancelEventAction = createAction<IEventAction>(actionTypes.CANCEL_EVENT);

export const cancelEvent = (
  eventId: string,
  eventDate: string,
  payload: IEventAction,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(cancelEventLoading(true));
    try {
      await Services.Booking.cancelEvent(eventId, eventDate, payload);
      dispatch(cancelEventAction(payload));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(cancelEventLoading(false));
    }
  };
};

const restoreEventLoading = createAction<boolean>(actionTypes.RESTORE_EVENT_LOADING);
const restoreEventAction = createAction<{ eventId: string }>(actionTypes.RESTORE_EVENT);

export const restoreEvent = (eventId: string, eventDate: string): GeneralThunkAction => {
  return async dispatch => {
    dispatch(restoreEventLoading(true));

    try {
      await Services.Booking.restoreEvent(eventId, eventDate);
      dispatch(restoreEventAction({ eventId }));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(restoreEventLoading(false));
    }
  };
};

const deleteEventLoading = createAction<boolean>(actionTypes.DELETE_EVENT_LOADING);
const deleteEventAction = createAction<IBookingEvent>(actionTypes.DELETE_EVENT);

export const deleteEvent = (
  eventId: string,
  eventDate: string,
  payload: IEventAction,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(deleteEventLoading(true));

    try {
      const deletedEvent = await Services.Booking.deleteEvent(eventId, eventDate, payload);
      dispatch(deleteEventAction(deletedEvent));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(deleteEventLoading(false));
    }
  };
};

const fetchEventServicesLoading = createAction<boolean>(actionTypes.FETCH_EVENT_SERVICES_LOADING);
const fetchEventServices = createAction<INamedEntity[]>(actionTypes.FETCH_EVENT_SERVICES);
export const resetEventServices = createAction<INamedEntity[]>(actionTypes.RESET_EVENT_SERVICES);

export const fetchEventServicesThunk = (search: string): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchEventServicesLoading(true));

    try {
      const services = await Services.Booking.getEventServices(search);

      dispatch(fetchEventServices(services));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchEventServicesLoading(false));
    }
  };
};

const duplicateEventAction = createAction<IBookingEvent>(actionTypes.DUPLICATE_EVENT);
const duplicateEventLoadingAction = createAction<boolean>(actionTypes.DUPLICATE_EVENT_LOADING);

export const duplicateEvent = (
  eventId: string,
  eventDate: string,
  isDuplicateParticipants: boolean,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(duplicateEventLoadingAction(true));

    try {
      const duplicate = await Services.Booking.duplicateEvent(
        eventId,
        eventDate,
        isDuplicateParticipants,
      );

      dispatch(duplicateEventAction(duplicate));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(duplicateEventLoadingAction(false));
    }
  };
};

const remindEventAction = createAction<Partial<IBookingEvent>>(actionTypes.REMIND_EVENT);
const remindEventLoadingAction = createAction<boolean>(actionTypes.REMIND_EVENT_LOADING);

export const remindEvent = (
  eventId: string,
  payload: IRemindAppointmentDto,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(remindEventLoadingAction(true));

    try {
      await Services.Booking.remindEvent(eventId, payload);

      dispatch(remindEventAction(payload));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(remindEventLoadingAction(false));
    }
  };
};
