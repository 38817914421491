import { List } from 'immutable';
import {
  IBalanceImt,
  IInvoiceDetailsImt,
  IPaymentMethodCustomResponseImt,
} from 'common/interfaces/invoices';
import { IPaymentMethodsPosSettingsImt } from 'modules/pos-settings/interfaces/paymentMethods';
import { IPaymentAccountImt } from 'common/components/PersonProfile/interfaces';

//  Invoices operating
const invoicesOperatingPath = ['memberPortalProfile', 'invoices', 'invoicesOperating'];

export const selectMemberInvoice = state =>
  state.getIn([...invoicesOperatingPath, 'memberInvoice']);

export const selectInvoiceUnitCount = state =>
  state.getIn([...invoicesOperatingPath, 'invoiceUnitCount']);

export const selectMemberInvoiceLoading = state =>
  state.getIn([...invoicesOperatingPath, 'isMemberInvoiceLoading']);

export const selectInvoiceId = state => {
  return state.getIn([...invoicesOperatingPath, 'selectedInvoiceId']);
};

export const selectCancelResult = state => {
  return state.getIn([...invoicesOperatingPath, 'actionCancelResult']);
};

export const selectCancelResultLoading = state => {
  return state.getIn([...invoicesOperatingPath, 'isCancelResultLoading']);
};

export const selectReceiptLoading = state => {
  return state.getIn([...invoicesOperatingPath, 'isFetchReceiptLoading']);
};

export const selectIsUpdateInvoiceLoading = state =>
  state.getIn([...invoicesOperatingPath, 'isUpdateInvoiceLoading']);

//  Balance
export const selectCustomerBalance = (state): IBalanceImt | null => {
  return state.getIn([...invoicesOperatingPath, 'balance']);
};
export const selectCustomerBalanceLoading = (state): boolean => {
  return state.getIn([...invoicesOperatingPath, 'isFetchBalanceLoading']);
};

// Custom payment method
const invoiceCustomPaymentMethodPath = [
  'memberPortalProfile',
  'invoices',
  'invoiceCustomPaymentMethod',
];

export const selectBarcodePaymentMethod = (state): IPaymentMethodCustomResponseImt =>
  state.getIn([...invoiceCustomPaymentMethodPath, 'result']);
export const selectBarcodePaymentMethodError = state =>
  state.getIn([...invoiceCustomPaymentMethodPath, 'error']);
export const selectBarcodePaymentMethodLoading = state =>
  state.getIn([...invoiceCustomPaymentMethodPath, 'isLoading']);

// Available payment methods

const invoicePaymentMethodsPath = ['memberPortalProfile', 'invoices', 'invoicePaymentMethods'];

export const selectAvailablePaymentMethods = (state): List<IPaymentMethodsPosSettingsImt> =>
  state.getIn([...invoicePaymentMethodsPath, 'data']);

export const selectAvailablePaymentMethodsLoading = (state): boolean =>
  state.getIn([...invoicePaymentMethodsPath, 'isLoading']);

// Update sync
const invoiceSyncActionPath = ['memberPortalProfile', 'invoices', 'invoiceSyncAction'];

export const selectUpdatedInvoiceResult = (state): IInvoiceDetailsImt =>
  state.getIn([...invoiceSyncActionPath, 'data']);

export const selectUpdatedInvoiceLoading = (state): boolean =>
  state.getIn([...invoiceSyncActionPath, 'isLoading']);

export const selectUpdatedInvoiceError = (state): any =>
  state.getIn([...invoiceSyncActionPath, 'error']);

// Checkout

export const selectInvoiceCheckoutResult = (state): IInvoiceDetailsImt =>
  state.getIn(['memberPortalProfile', 'invoices', 'invoiceCheckoutResult']);

const invoiceStoredCreditCardsPath = [
  'memberPortalProfile',
  'invoices',
  'invoiceStoredCreditCards',
];

export const selectStoredCreditCardsLoading = state =>
  state.getIn([...invoiceStoredCreditCardsPath, 'isLoading']);
export const selectStoredCreditCardsResult = state =>
  state.getIn([...invoiceStoredCreditCardsPath, 'creditCards']);
export const selectAddCreditCardLoading = state =>
  state.getIn([...invoiceStoredCreditCardsPath, 'addCreditCardLoading']);
export const selectAddCreditCardResult = state =>
  state.getIn([...invoiceStoredCreditCardsPath, 'addCreditCardResult']);

// Checking

const invoiceCheckingSavingsPath = ['memberPortalProfile', 'invoices', 'invoiceCheckingSavings'];
export const selectInvoiceCheckingSavingsData = (state): List<IPaymentAccountImt> =>
  state.getIn([...invoiceCheckingSavingsPath, 'list']);
export const selectFetchInvoiceCheckingSavingsDataLoading = state =>
  state.getIn([...invoiceCheckingSavingsPath, 'fetchCheckingSavingsLoading']);
export const selectAddInvoiceCheckingSavingsDataLoading = state =>
  state.getIn([...invoiceCheckingSavingsPath, 'addCheckingSavingsLoading']);
export const selectAddInvoiceCheckingSavingsDataActionResult = state =>
  state.getIn([...invoiceCheckingSavingsPath, 'addCheckingSavingsActionResult']);
export const selectDeleteInvoiceCheckingSavingsItemLoading = state =>
  state.getIn([...invoiceCheckingSavingsPath, 'deleteCheckingSavingsLoading']);
export const selectDeleteInvoiceCheckingSavingsItemActionResult = state =>
  state.getIn([...invoiceCheckingSavingsPath, 'deleteCheckingSavingsActionResult']);
