import { createAction } from '@reduxjs/toolkit';

import Services from 'services/network';
import { ITableParams } from 'common/interfaces/table';
import { actionTypes } from 'modules/reports/constants/salesPOS';
import {
  ISalesPOSSummary,
  ICashSummary,
  IInvoicesSummary,
  IPaymentSummary,
  IRegistersSummary,
  IRevenueSummary,
  ISalesSummary,
  IServiceSummary,
  ITaxSummary,
} from 'modules/reports/interfaces/salesPOS';
import { enqueueErrorNotification } from 'common/state/notifications/actions';
import { GeneralThunkAction } from 'common/state/interfaces';

const fetchSalesPOSSummaryLoading = createAction<boolean>(
  actionTypes.FETCH_SALES_POS_SUMMARY_LOADING,
);
const fetchSalesPOSSummaryAction = createAction<ISalesPOSSummary>(
  actionTypes.FETCH_SALES_POS_SUMMARY,
);

export const fetchSalesPOSSummary = (requestOptions?: ITableParams): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchSalesPOSSummaryLoading(true));
    try {
      const salesPOSSummary = await Services.Reports.getSalesPOSSummary(requestOptions);
      dispatch(fetchSalesPOSSummaryAction(salesPOSSummary));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchSalesPOSSummaryLoading(false));
    }
  };
};

const fetchCashSummaryLoading = createAction<boolean>(actionTypes.FETCH_CASH_SUMMARY_LOADING);
const fetchCashSummaryAction = createAction<ICashSummary>(actionTypes.FETCH_CASH_SUMMARY);

export const fetchCashSummary = (requestOptions?: ITableParams): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchCashSummaryLoading(true));
    try {
      const cashSummary = await Services.Reports.getCashSummary();
      dispatch(fetchCashSummaryAction(cashSummary));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchCashSummaryLoading(false));
    }
  };
};

const fetchPaymentsSummaryLoading = createAction<boolean>(
  actionTypes.FETCH_PAYMENTS_SUMMARY_LOADING,
);
const fetchPaymentsSummaryAction = createAction<IPaymentSummary>(
  actionTypes.FETCH_PAYMENTS_SUMMARY,
);

export const fetchPaymentsSummary = (requestOptions?: ITableParams): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPaymentsSummaryLoading(true));

    try {
      const paymentsSummary = await Services.Reports.getPaymentsSummary(requestOptions);
      dispatch(fetchPaymentsSummaryAction(paymentsSummary));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchPaymentsSummaryLoading(false));
    }
  };
};

const fetchServiceSummaryLoading = createAction<boolean>(actionTypes.FETCH_SERVICE_SUMMARY_LOADING);
const fetchServiceSummaryAction = createAction<IServiceSummary>(actionTypes.FETCH_SERVICE_SUMMARY);

export const fetchServiceSummary = (requestOptions?: ITableParams): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchServiceSummaryLoading(true));
    try {
      const serviceSummary = await Services.Reports.getServiceModeSummary();
      dispatch(fetchServiceSummaryAction(serviceSummary));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchServiceSummaryLoading(false));
    }
  };
};

const fetchInvoicesSummaryLoading = createAction<boolean>(
  actionTypes.FETCH_INVOICES_SUMMARY_LOADING,
);
const fetchInvoicesSummaryAction = createAction<IInvoicesSummary>(
  actionTypes.FETCH_INVOICES_SUMMARY,
);

export const fetchInvoicesSummary = (requestOptions?: ITableParams): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchInvoicesSummaryLoading(true));
    try {
      const invoicesSummary = await Services.Reports.getInvoicesSummary(requestOptions);
      dispatch(fetchInvoicesSummaryAction(invoicesSummary));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchInvoicesSummaryLoading(false));
    }
  };
};

const fetchSalesSummaryLoading = createAction<boolean>(actionTypes.FETCH_SALES_SUMMARY_LOADING);
const fetchSalesSummaryAction = createAction<ISalesSummary>(actionTypes.FETCH_SALES_SUMMARY);

export const fetchSalesSummary = (requestOptions?: ITableParams): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchSalesSummaryLoading(true));
    try {
      const salesSummary = await Services.Reports.getSalesCategorySummary(requestOptions);
      dispatch(fetchSalesSummaryAction(salesSummary));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchSalesSummaryLoading(false));
    }
  };
};

const fetchRegistersSummaryLoading = createAction<boolean>(
  actionTypes.FETCH_REGISTERS_SUMMARY_LOADING,
);
const fetchRegistersSummaryAction = createAction<IRegistersSummary>(
  actionTypes.FETCH_REGISTERS_SUMMARY,
);

export const fetchRegistersSummary = (requestOptions?: ITableParams): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchRegistersSummaryLoading(true));
    try {
      const registersSummary = await Services.Reports.getRegistersSummary(requestOptions);
      dispatch(fetchRegistersSummaryAction(registersSummary));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchRegistersSummaryLoading(false));
    }
  };
};

const fetchTaxSummaryLoading = createAction<boolean>(actionTypes.FETCH_TAX_SUMMARY_LOADING);
const fetchTaxSummaryAction = createAction<ITaxSummary>(actionTypes.FETCH_TAX_SUMMARY);

export const fetchTaxSummary = (requestOptions?: ITableParams): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchTaxSummaryLoading(true));
    try {
      const taxSummary = await Services.Reports.getTaxSummary(requestOptions);
      dispatch(fetchTaxSummaryAction(taxSummary));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchTaxSummaryLoading(false));
    }
  };
};

const fetchRevenueSummaryLoading = createAction<boolean>(actionTypes.FETCH_REVENUE_SUMMARY_LOADING);
const fetchRevenueSummaryAction = createAction<IRevenueSummary>(actionTypes.FETCH_REVENUE_SUMMARY);

export const fetchRevenueSummary = (requestOptions?: ITableParams): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchRevenueSummaryLoading(true));
    try {
      const revenueSummary = await Services.Reports.getRevenueSummary(requestOptions);
      dispatch(fetchRevenueSummaryAction(revenueSummary));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchRevenueSummaryLoading(false));
    }
  };
};

// export action

const exportSalesPOSReportAction = createAction<any>(actionTypes.EXPORT_SALES_POS_REPORT);
const exportSalesPOSReportLoadingAction = createAction<boolean>(
  actionTypes.EXPORT_SALES_POS_REPORT_LOADING,
);
export const resetExportSalesPOSReport = createAction(actionTypes.RESET_EXPORT_SALES_POS_REPORT);

export const exportSalesPOSReport = (
  data: Record<string, any>,
  tableParams: ITableParams,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(exportSalesPOSReportLoadingAction(true));

    try {
      const response = await Services.Reports.exportSalesPOSReport(data, tableParams);
      dispatch(exportSalesPOSReportAction(response));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(exportSalesPOSReportLoadingAction(false));
    }
  };
};
