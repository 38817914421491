import React, { useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import { createStyles, fade, Theme } from '@material-ui/core/styles';

// interfaces
import { EventUpdateType, IEventAction } from 'modules/booking/interfaces';
// components
import { DialogComponent } from 'common/components';
import NotifyVariant from 'modules/booking/components/Modals/NotifyVariant';
// constants
import { initialValuesEventAction } from 'modules/booking/components/Modals/initialValues';
import { EShortNotificationType } from 'modules/booking/constants/notificationType';
import { SenderAvailabilityTypeList } from 'modules/booking/constants/senderAvailability';
// hooks
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
// state
import { resetBookingEvents } from 'modules/booking/state/senderAvailability/actions';
// messages
import inputErrors from 'common/messages/inputErrors';
import messages from 'modules/booking/messages';
import commonMessages from 'common/messages/messages';
import { selectSenderAvailability } from 'modules/booking/state/senderAvailability/selectors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      '& .MuiDialog-paper': {
        maxWidth: 434,
      },
    },
    infoBox: {
      padding: '12px',
      borderRadius: '3px',
      backgroundColor: fade(theme.palette.primary.main, 0.08),
    },
    radioInput: {
      '& .MuiRadio-root': {
        paddingLeft: 0,
      },
    },
    selectInputRow: {
      minWidth: 112,
      maxWidth: 300,
      flex: 1,
    },
    selectInput: {
      '& .MuiOutlinedInput-input': {
        padding: '10px 12px',
      },
    },
  }),
);

const CancelEventValidationSchema = yup.object().shape({
  reason: yup.string().max(1000, () => inputErrors.reasonLengthError),
});

interface ICancelEventModalProps {
  isUpdateEventLoading?: boolean;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (formValues: IEventAction) => void;
  type: SenderAvailabilityTypeList;
  onLoadSenderAvailability: (
    type: SenderAvailabilityTypeList,
    shortTypeEvent: EShortNotificationType,
  ) => void;
  isRepeatedEvent: boolean;
}

const CancelEventModal = ({
  isOpen,
  isUpdateEventLoading,
  onClose,
  onSubmit,
  type,
  onLoadSenderAvailability,
  isRepeatedEvent,
}: ICancelEventModalProps): JSX.Element => {
  const senderAvailability = useSelector(selectSenderAvailability(type));

  // TODO: excessive model?
  const formMethods = useForm<IEventAction>({
    defaultValues: initialValuesEventAction,
    resolver: yupResolver(CancelEventValidationSchema),
    mode: 'onBlur',
  });

  const { control, handleSubmit, errors } = formMethods;

  const classes = useStyles();
  const dispatch = useDispatch();

  const renderErrorMessage = useRenderIntlMessage();

  const handleCancelEvent = formData => {
    onSubmit(formData);
  };

  useEffect(() => {
    if (isOpen) {
      onLoadSenderAvailability(type, EShortNotificationType.Cancel);
    }
    return () => {
      batch(() => {
        dispatch(resetBookingEvents({ type }));
      });
    };
  }, [dispatch, isOpen, onLoadSenderAvailability, type]);

  return (
    <DialogComponent
      className={classes.dialog}
      title={<FormattedMessage {...messages.cancelEventBtn} />}
      submitBtnTitle={<FormattedMessage {...messages.cancelEventBtn} />}
      cancelBtnTitle={<FormattedMessage {...commonMessages.notNowBtn} />}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(handleCancelEvent)}
      loading={isUpdateEventLoading}
    >
      <FormProvider {...formMethods}>
        <form id="cancel-event-form" autoComplete="none">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box className={classes.infoBox} display="flex" alignItems="center">
                <Typography color="primary">
                  <FormattedMessage {...messages.cancelEventWarning} />
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="updateType"
                control={control}
                render={({ value, onChange, onBlur }) => (
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="recurringEventUpdateType"
                      name="eventUpdateType"
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                    >
                      {isRepeatedEvent && (
                        <>
                          <FormControlLabel
                            value={EventUpdateType.OnlyCurrentInstance}
                            control={<Radio />}
                            label={<FormattedMessage {...messages.editRecurringThisEventOption} />}
                          />
                          <FormControlLabel
                            value={EventUpdateType.CurrentAndAllNext}
                            control={<Radio />}
                            label={
                              <FormattedMessage {...messages.editRecurringFutureEventOption} />
                            }
                          />
                          <FormControlLabel
                            value={EventUpdateType.All}
                            control={<Radio />}
                            label={<FormattedMessage {...messages.editRecurringAllEventOption} />}
                          />
                        </>
                      )}
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Box mt={1}>
                <Controller
                  name="reason"
                  control={control}
                  render={({ value, onChange, onBlur }) => (
                    <TextField
                      fullWidth
                      variant="outlined"
                      label={<FormattedMessage {...messages.deleteEventReasonLabel} />}
                      multiline
                      rowsMax={3}
                      autoComplete="none"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={!!errors.reason}
                      helperText={renderErrorMessage(errors?.reason?.message)}
                    />
                  )}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <NotifyVariant senderAvailability={senderAvailability} />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </DialogComponent>
  );
};

export default CancelEventModal;
