import qz from 'qz-tray';
import { KJUR, KEYUTIL, stob64, hextorstr } from 'jsrsasign';
import { IOptions, IPrintConfig } from 'common/interfaces/print';
import PrintKeysService from 'services/PrintKeyStorageService/PrintKeyStorageService';

export const startConnection = (): Promise<Error | null> => {
  return qz.websocket.connect({ host: 'localhost', usingSecure: true });
};

export const endConnection = (): Promise<Error | null> => {
  return qz.websocket.disconnect();
};

export const isActiveConnection = (): boolean => {
  return qz.websocket.isActive();
};

export const findPrinters = async (): Promise<string[] | Error> => {
  if (!isActiveConnection()) {
    await startConnection();
  }

  return qz.printers.find();
};

export const subscribeToCertificate = (): void => {
  qz.security.setCertificatePromise((resolve: (key: string) => void, reject: () => void) => {
    // Preferred method - from server
    //        fetch("assets/signing/digital-certificate.txt", {cache: 'no-store', headers: {'Content-Type': 'text/plain'}})
    //          .then(function(data) { data.ok ? resolve(data.text()) : reject(data.text()); });

    // Alternate method 1 - anonymous
    //        resolve();  // remove this line in live environment

    // Alternate method 2 - direct

    const key = (PrintKeysService.getDigitalCertificateKey() || '').trim();

    resolve(key);
  });

  qz.security.setSignatureAlgorithm('SHA512'); // Since 2.1
  // qz.security.setSignaturePromise(toSign => {
  //   return (resolve, reject) => {
  //     // Preferred method - from server
  //     //            fetch("/secure/url/for/sign-message?request=" + toSign, {cache: 'no-store', headers: {'Content-Type': 'text/plain'}})
  //     //              .then(function(data) { data.ok ? resolve(data.text()) : reject(data.text()); });
  //
  //     // Alternate method - unsigned
  //     resolve(); // remove this line in live environment
  //   };
  // });

  qz.security.setSignaturePromise(toSign => {
    return (resolve: (data: string) => void, reject: (error: Error) => void) => {
      try {
        const key = (PrintKeysService.getPrivateKey() || '').trim();

        const pk = KEYUTIL.getKey(key);
        const sig = new KJUR.crypto.Signature({ alg: 'SHA512withRSA' });
        sig.init(pk);
        sig.updateString(toSign);
        const hex = sig.sign();
        resolve(stob64(hextorstr(hex)));
      } catch (err) {
        // TODO: Calling reject crashes the application
        // reject(err);
      }
    };
  });
};

export const createConfig = (): IPrintConfig => {
  const config = qz.configs.create(null);

  config.reconfigure({
    scaleContent: true,
    margins: {
      bottom: 0,
      left: 10,
      right: 0,
      top: 0,
    },
    units: 'mm',
  });

  return config;
};

export const printHtml = async (
  printingHtml: string,
  config: IPrintConfig,
): Promise<Error | null> => {
  if (!isActiveConnection()) {
    await startConnection();
  }

  const opts: IOptions = {
    pageWidth: 72,
  };

  return qz.print(config, [
    {
      type: 'pixel',
      format: 'html',
      flavor: 'plain',
      data: printingHtml,
      options: opts,
    },
  ]);
};
