import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Tooltip, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { selectCurrentUser } from 'modules/authentication/state/selectors';
import inputLabels from 'common/messages/inputLabels';
import messages from 'common/components/PersonProfile/messages';
import { InfoIcon } from 'img/icons';
import { makeStyles } from '@material-ui/core/styles';
import { CustomTheme } from 'common/ui/interfaces';

const useStyles = makeStyles((theme: CustomTheme) => ({
  label: {
    marginRight: theme.spacing(0.5),
    fontSize: '0.75rem',
  },
  infoIcon: {
    color: theme.palette.text.secondary,
    width: '1.05rem',
    height: '1.05rem',
    marginLeft: theme.spacing(1),
    '&:hover': {
      color: theme.palette.darkBackground.main,
    },
  },
}));

const CurrentUserInfo = (): JSX.Element => {
  const currentUser = useSelector(selectCurrentUser);

  const classes = useStyles();

  return (
    <Box display="flex" flexWrap="wrap">
      <Box display="flex" alignItems="center" mr={2}>
        <Typography variant="h6" className={classes.label}>
          <FormattedMessage {...inputLabels.from} />:
        </Typography>
        <Typography variant="body2">{`${currentUser.get('firstName')} ${currentUser.get(
          'lastName',
        )}`}</Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Typography variant="h6" className={classes.label}>
          <FormattedMessage {...inputLabels.email} />:
        </Typography>
        <Box display="flex" alignItems="center">
          <Typography variant="body2">{currentUser.get('email')}</Typography>
          <Tooltip title={<FormattedMessage {...messages.respondToEmailInfo} />}>
            <InfoIcon color="inherit" className={classes.infoIcon} fontSize="small" />
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
};

export default CurrentUserInfo;
