import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { List as ImmutableList } from 'immutable';
import moment from 'moment-timezone';
import { Box, IconButton, Typography } from '@material-ui/core';

import { IPersonDocumentGroupItemImt } from 'common/components/PersonProfile/interfaces';
import { IHeadCell, ITableRow } from 'common/interfaces/table';

import { DocumentTypes } from 'common/components/PersonProfile/constants';
import { ContractStatus } from 'common/components/Steps/SignDocumentStep/components';
import { Table } from 'common/components/index';
import { ReactComponent as SignatureIcon } from 'img/icons/signature.svg';
import { ReactComponent as DownloadDocIcon } from 'img/icons/download-doc.svg';

import tableHeaders from 'common/messages/tableHeaders';
import { DEFAULT_DATE_FORMAT } from 'common/constants/dateFormats';
import { WaiverStatus } from 'modules/crm/constants/leads';

interface IProps {
  documents: ImmutableList<IPersonDocumentGroupItemImt>;
  onSelectSignDocument: (document: IPersonDocumentGroupItemImt) => void;
}

const headerOptions: IHeadCell[] = [
  {
    id: 'name',
    label: <FormattedMessage {...tableHeaders.name} />,
    sort: false,
  },
  {
    id: 'id',
    label: <FormattedMessage {...tableHeaders.documentNumber} />,
    sort: false,
  },
  {
    id: 'type',
    label: <FormattedMessage {...tableHeaders.type} />,
    sort: false,
  },
  {
    id: 'date',
    label: <FormattedMessage {...tableHeaders.date} />,
    sort: false,
  },
  {
    id: 'status',
    label: <FormattedMessage {...tableHeaders.status} />,
    sort: false,
  },
  {
    id: 'actions',
    label: '',
    align: 'right',
    sort: false,
  },
];

const DocumentsTable = ({ documents, onSelectSignDocument }: IProps): JSX.Element => {
  const rows: ITableRow[] = useMemo(
    () =>
      documents
        .map(
          (document: IPersonDocumentGroupItemImt): ITableRow => {
            const needSighDocument = document?.get('status') !== WaiverStatus.NO_SIGN;

            return {
              id: document.get('id'),
              cells: [
                {
                  label: '',
                  cellComponent: (
                    <Typography color="primary" variant="h5">
                      {document.get('title')}
                    </Typography>
                  ),
                },
                {
                  label: `${document.get('code')}`,
                },
                {
                  label: '',
                  cellComponent: (
                    <Typography>
                      <FormattedMessage {...DocumentTypes.message(document.get('type'))} />
                    </Typography>
                  ),
                },
                {
                  label: document.get('createdDate')
                    ? moment(document.get('createdDate')).format(DEFAULT_DATE_FORMAT)
                    : '-',
                },
                {
                  label: '',
                  cellComponent: needSighDocument ? (
                    <ContractStatus
                      isSignedContract={document.get('status') === WaiverStatus.SIGNED}
                    />
                  ) : (
                    '-'
                  ),
                },
                {
                  label: '',
                  align: 'right',
                  padding: 'none',
                  cellComponent: (
                    <Box
                      display="flex"
                      justifyContent={needSighDocument ? 'end' : 'flex-start'}
                      gridGap={3}
                      paddingRight={needSighDocument ? 0 : 5.5}
                    >
                      <a download href={document.get('url')}>
                        <IconButton color="secondary">
                          <DownloadDocIcon width={20} height={20} />
                        </IconButton>
                      </a>

                      {document.get('status') !== WaiverStatus.SIGNED && needSighDocument && (
                        <IconButton
                          color="secondary"
                          onClick={() => onSelectSignDocument(document)}
                        >
                          <SignatureIcon />
                        </IconButton>
                      )}
                    </Box>
                  ),
                },
              ],
            };
          },
        )
        .toJS(),
    [documents, onSelectSignDocument],
  );

  return (
    <Table
      rows={rows}
      isLoading={false}
      headerOptions={headerOptions}
      hidePagination
      hidePadding
      hideToolbar
      hideSearchInput
    />
  );
};

export default DocumentsTable;
