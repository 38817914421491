import { createAction } from '@reduxjs/toolkit';

import { ITableParams } from 'common/interfaces/table';

import Services from 'services/network';
import { actionTypes } from '../../constants/services';
import { IPaginatedServicesList, IServiceFormData, IServiceItem } from '../../interfaces/services';
import { ActionResult } from 'common/constants/globalConstants';
import { enqueueErrorNotification } from 'common/state/notifications/actions';
import { GeneralThunkAction } from 'common/state/interfaces';

export const resetServiceAction = createAction<boolean>(actionTypes.RESET_SERVICE);

const fetchServicesListLoading = createAction<boolean>(actionTypes.FETCH_SERVICES_LIST_LOADING);
const fetchServicesListAction = createAction<IPaginatedServicesList>(
  actionTypes.FETCH_SERVICES_LIST,
);
export const resetServicesList = createAction(actionTypes.RESET_SERVICES_LIST);
export const fetchServicesList = (requestOptions?: ITableParams): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchServicesListLoading(true));

    try {
      const paginatedData = await Services.ServicesList.getServicesList(requestOptions);
      dispatch(fetchServicesListAction(paginatedData));
    } catch (error) {
      // dispatch(pushRequestError((error as Error).message));
    } finally {
      dispatch(fetchServicesListLoading(false));
    }
  };
};

// const fetchServiceByIdLoading = createAction<boolean>(actionTypes.FETCH_SERVICE_LOADING);
// const fetchServiceByIdAction = createAction<IService>(actionTypes.FETCH_SERVICE);
// const resetServiceAction = createAction<boolean>(actionTypes.RESET_SERVICE);

// export const resetService = (): GeneralThunkAction => {
//   return async dispatch => {
//     dispatch(resetServiceAction());
//   };
// };
// export const fetchServiceById = (
//   serviceId: string,
// ): GeneralThunkAction => {
//   return async dispatch => {
//     dispatch(fetchServiceByIdLoading(true));

//     try {
//       const invoice = await Services.ServicesSection.getServiceById(serviceId);
//       dispatch(fetchServiceByIdAction(invoice));
//     } catch (error) {
//       // dispatch(pushRequestError((error as Error).message));
//     } finally {
//       dispatch(fetchServiceByIdLoading(false));
//     }
//   };
// };\
const changeServiceStatusLoading = createAction<boolean>(actionTypes.CHANGE_SERVICE_STATUS_LOADING);
const changeServiceStatusAction = createAction<Record<string, any>>(
  actionTypes.CHANGE_SERVICE_STATUS,
);

export const changeServiceStatus = (id: string, isActive: boolean): GeneralThunkAction => {
  return async dispatch => {
    dispatch(changeServiceStatusLoading(true));
    try {
      const response = await Services.ServicesList.changeServiceStatus(id, isActive);
      dispatch(changeServiceStatusAction(response));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(changeServiceStatusLoading(false));
    }
  };
};

// const editServiceLoding = createAction<boolean>(actionTypes.EDIT_SERVICE_LOADING);
// const editServiceAction = createAction<any>(actionTypes.EDIT_SERVICE);
// export const resetEditSuccess = createAction(actionTypes.RESET_EDIT_SUCCESS);

// export const editService = (
//   serviceId: string,
//   data: string,
// ): GeneralThunkAction => {
//   return async dispatch => {
//     dispatch(editServiceLoding(true));

//     try {
//       const response = await Services.ServicesSection.editService(serviceId, data);
//       dispatch(editServiceAction(response));
//     } catch (error) {
//       // dispatch(pushRequestError((error as Error).message));
//     } finally {
//       dispatch(editServiceLoding(false));
//     }
//   };
// };

export const createUpdateServiceLoading = createAction<boolean>(
  actionTypes.CREATE_UPDATE_SERVICE_LOADING,
);
export const createUpdateResultAction = createAction<ActionResult>(
  actionTypes.CREATE_UPDATE_SERVICE_RESULT_ACTION,
);

export const createService = (data: IServiceFormData): GeneralThunkAction => {
  return async dispatch => {
    dispatch(createUpdateServiceLoading(true));

    try {
      await Services.ServicesList.createService(data);
      dispatch(createUpdateResultAction(ActionResult.SUCCESS_ACTION));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(createUpdateServiceLoading(false));
    }
  };
};

export const updateService = (serviceId: string, data: IServiceFormData): GeneralThunkAction => {
  return async dispatch => {
    dispatch(createUpdateServiceLoading(true));

    try {
      await Services.ServicesList.updateService(serviceId, data);
      dispatch(createUpdateResultAction(ActionResult.SUCCESS_ACTION));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(createUpdateServiceLoading(false));
    }
  };
};

const fetchServiceLoading = createAction<boolean>(actionTypes.FETCH_SERVICE_LOADING);
const fetchServiceAction = createAction<IServiceItem>(actionTypes.FETCH_SERVICE);
export const fetchService = (serviceId: string): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchServiceLoading(true));

    try {
      const response = await Services.ServicesList.getServiceById(serviceId);
      dispatch(fetchServiceAction(response));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchServiceLoading(false));
    }
  };
};
