import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { List as ImmutableList } from 'immutable';
import { Grid, Button, makeStyles, Theme } from '@material-ui/core';

import * as selectors from 'common/components/PersonProfile/state/selectors';

import { PeakModules } from 'common/constants/peakModules';

import { IPastDue, IProfileInfoImt } from 'common/components/PersonProfile/interfaces';
import { IMemberAlertImt } from 'modules/front-desk/interfaces';
import { AlertCondition } from 'common/interfaces/alerts';

import {
  BillingScheduleModal,
  PastDuesResolutionModal,
  PaymentMethodsModal,
} from 'common/components/PersonProfile/modals';

import messages from 'common/components/PersonProfile/messages';
import { CartUnitTypes, IInvoiceDetailsDto, InvoiceStatus } from 'common/interfaces/invoices';
import { ADD_TOP_UP_BALANCE } from 'common/constants/delayedActionsKeys';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import { useAppDispatch } from 'store/hooks';
import { selectSelectedRegisterId } from 'modules/pos-kiosk/state/register/selectors';
import StorageServices from 'services/storage';
import { selectCurrentUserId } from 'modules/authentication/state/selectors';
import { ButtonWithCircularProgress } from 'common/components/index';
import { snackbar } from 'common/utils/snackbarUtils';
import TopUpBalanceModal from 'common/components/InvoiceOperating/InvoiceModals/TopUpBalanceModal/TopUpBalanceModal';
import * as actions from 'common/state/invoice/actions';
import { selectInvoicesForRegisterList } from 'common/state/invoice/selectors';
import TopUpRewardsModal from 'common/components/PersonProfile/modals/TopUpRewardsModal/TopUpRewardsModal';

const useStyles = makeStyles((theme: Theme) => ({
  selectPackage: {
    color: theme.palette.secondary.main,
    width: '100%',
    height: '100%',
  },
}));

interface IProps {
  profile: IProfileInfoImt;
  module: PeakModuleForNewPersonType;
  openPOSPanel: () => void;
  handlePOSPanelChange: (isOpen: boolean) => void;
  handleNewMemberPanelChange: (isOpen: boolean) => void;
  handleChangePackagePlanChange: (isOpen: boolean) => void;
  disablePosBtn: boolean;
  isOpenPOSPanel: boolean;
  isSmallScreen?: boolean;
}

const { addDelayedAction } = StorageServices.delayedActions;

export default function BillingActionsBlock({
  profile,
  module,
  openPOSPanel,
  isOpenPOSPanel,
  isSmallScreen,
  disablePosBtn,
  handlePOSPanelChange,
  handleNewMemberPanelChange,
  handleChangePackagePlanChange,
}: IProps): JSX.Element {
  const dispatch = useAppDispatch();

  const classes = useStyles();

  const selectedRegisterId: string = useSelector(selectSelectedRegisterId);
  const currentRegisterInvoicesList = useSelector(selectInvoicesForRegisterList);
  const currentUserId = useSelector(selectCurrentUserId);

  const [isBillingsScheduleModalOpen, setIsBillingsScheduleModalOpen] = useState<boolean>(false);
  const [isPastDuesModalOpen, setIsPastDuesModalOpen] = useState<boolean>(false);
  const [isPaymentMethodsModalOpen, setIsPaymentMethodsModalOpen] = useState<boolean>(false);
  const [isOpenTopUpBalanceModal, setIsOpenTopUpBalanceModal] = useState(false);
  const [isOpenTopUpRewardsModal, setIsOpenTopUpRewardsModal] = useState(false);

  const personId = profile.get('id');

  const memberAlertsList: ImmutableList<IMemberAlertImt> = useSelector(
    selectors.selectPersonAlerts(personId),
  );

  const isPaymentMethodsNotExist = memberAlertsList?.some(
    memberAlert => memberAlert.get('condition') === AlertCondition.MissingBillingInfo,
  );

  const isMembersModule = module === PeakModules.Members;

  // handlers
  const handleSubmitTopUpBalanceModal = (amountToPay: number) => {
    openPOSPanel();
    setIsOpenTopUpBalanceModal(false);

    const handleCreateInvoice = (invoicesList: IInvoiceDetailsDto[], registerId: string) => {
      const openedInvoiceForSelectedCustomer: IInvoiceDetailsDto | void = invoicesList?.find(
        (invoiceItem: IInvoiceDetailsDto) => {
          return (
            invoiceItem.status === InvoiceStatus.OPEN &&
            invoiceItem.customer?.id === profile.get('id') &&
            invoiceItem.salesperson?.id === currentUserId
          );
        },
      );

      if (openedInvoiceForSelectedCustomer) {
        dispatch(
          actions.addInvoiceUnit(
            openedInvoiceForSelectedCustomer.id,
            {
              topUpBalances: [{ amount: amountToPay }],
              memberId: openedInvoiceForSelectedCustomer.customer?.id ?? null,
              registerId,
            },
            CartUnitTypes.TOP_UP_BALANCE,
            module,
            false,
            true,
          ),
        );
      } else {
        dispatch(
          actions.addInvoiceUnit(
            null,
            {
              createNewInvoice: true,
              topUpBalances: [{ amount: amountToPay }],
              memberId: personId,
              registerId,
            },
            CartUnitTypes.TOP_UP_BALANCE,
            module,
            false,
            true,
          ),
        );
      }
    };

    if (isOpenPOSPanel) {
      handleCreateInvoice(currentRegisterInvoicesList?.toJS(), selectedRegisterId);
    } else {
      addDelayedAction(ADD_TOP_UP_BALANCE, handleCreateInvoice);
    }
  };

  const toggleBillingsSchedule = useCallback(
    () => setIsBillingsScheduleModalOpen(prevState => !prevState),
    [],
  );

  const togglePastDues = useCallback(() => setIsPastDuesModalOpen(prevState => !prevState), []);

  const togglePaymentMethods = useCallback(
    () => setIsPaymentMethodsModalOpen(prevState => !prevState),
    [],
  );

  const handlePastDuesSubmit = useCallback(
    (selectedDues: IPastDue[]) => {
      snackbar.warning(
        `Opens a POS section to pay for ${selectedDues.length} selected dues. Not implemented yet`,
      );
      togglePastDues();
    },
    [togglePastDues],
  );

  // renders

  return (
    <Grid container spacing={1}>
      {!isMembersModule && (
        <Grid item xs={isSmallScreen ? 3 : 6}>
          <ButtonWithCircularProgress
            disableBackground
            isSubmitting={false}
            variant="contained"
            className={classes.selectPackage}
            onClick={() => setIsOpenTopUpBalanceModal(true)}
          >
            <FormattedMessage {...messages.topUpBalanceTitle} />
          </ButtonWithCircularProgress>
        </Grid>
      )}
      {!isMembersModule && (
        <Grid item xs={isSmallScreen ? 3 : 6}>
          <ButtonWithCircularProgress
            disableBackground
            isSubmitting={false}
            variant="contained"
            className={classes.selectPackage}
            onClick={() => setIsOpenTopUpRewardsModal(true)}
          >
            <FormattedMessage {...messages.rewardsTitle} />
          </ButtonWithCircularProgress>
        </Grid>
      )}
      {!isMembersModule && (
        <Grid item xs={isSmallScreen ? 3 : 6}>
          <Button variant="contained" className={classes.selectPackage} onClick={togglePastDues}>
            <FormattedMessage {...messages.pastDuesModalTitle} />
          </Button>
        </Grid>
      )}
      {!isMembersModule && (
        <Grid item xs={isSmallScreen ? 3 : 6}>
          <Button
            variant="contained"
            className={classes.selectPackage}
            onClick={toggleBillingsSchedule}
          >
            <FormattedMessage {...messages.billingScheduleModalTitle} />
          </Button>
        </Grid>
      )}
      <Grid item xs={isSmallScreen ? 3 : 12}>
        <Button
          variant="contained"
          className={classes.selectPackage}
          onClick={togglePaymentMethods}
        >
          <FormattedMessage {...messages.paymentMethodsModalTitle} />
        </Button>
      </Grid>
      {isBillingsScheduleModalOpen && (
        <BillingScheduleModal
          handlePOSPanelChange={handlePOSPanelChange}
          handleNewMemberPanelChange={handleNewMemberPanelChange}
          handleChangePackagePlanChange={handleChangePackagePlanChange}
          isOpenPOSPanel={isOpenPOSPanel}
          disablePosBtn={disablePosBtn}
          isOpen={isBillingsScheduleModalOpen}
          personId={personId}
          onSuccess={toggleBillingsSchedule}
          onCancel={toggleBillingsSchedule}
          module={PeakModules.FrontDesk}
        />
      )}

      {isPastDuesModalOpen && (
        <PastDuesResolutionModal
          handleNewMemberPanelChange={handleNewMemberPanelChange}
          handleChangePackagePlanChange={handleChangePackagePlanChange}
          handlePOSPanelChange={handlePOSPanelChange}
          isOpenPOSPanel={isOpenPOSPanel}
          disablePosBtn={disablePosBtn}
          isOpen={isPastDuesModalOpen}
          personId={personId}
          onSubmit={handlePastDuesSubmit}
          onCancel={togglePastDues}
          module={PeakModules.FrontDesk}
        />
      )}

      {isPaymentMethodsModalOpen && (
        <PaymentMethodsModal
          isOpen={isPaymentMethodsModalOpen}
          personId={personId}
          onSuccess={togglePaymentMethods}
          onCancel={togglePaymentMethods}
          alert={isPaymentMethodsNotExist ? AlertCondition.MissingBillingInfo : null}
          module={module}
        />
      )}

      {isOpenTopUpBalanceModal && (
        <TopUpBalanceModal
          isOpen={isOpenTopUpBalanceModal}
          onClose={() => setIsOpenTopUpBalanceModal(false)}
          handleSubmit={handleSubmitTopUpBalanceModal}
        />
      )}
      {isOpenTopUpRewardsModal && (
        <TopUpRewardsModal
          isOpen={isOpenTopUpRewardsModal}
          onClose={() => setIsOpenTopUpRewardsModal(false)}
          personId={personId}
          personRewards={profile.get('rewardPointNumber')}
        />
      )}
    </Grid>
  );
}
