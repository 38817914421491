import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { dispatch as busDispatch } from 'use-bus';

import { enqueueCheckinNotification } from 'common/state/notifications/actions';

import Services from 'services/network';

import usePlayAlertSound from 'modules/front-desk/hooks/usePlayAlertSound';

import { FRONT_DESK_PATH } from 'common/constants/globalConstants';
import { actionTypes } from 'common/components/SocketListener/constants';
import { KidZonePaths } from 'modules/kid-zone/constants';

import { CheckInSocketDto, mapCheckInDto } from 'common/utils/responseMapper';
import { ICheckIn } from 'modules/front-desk/interfaces';
import { ISocketMessage } from 'common/components/SocketListener/interfaces';

export const useCheckInHandler = (
  clubId: string,
): ((msg: ISocketMessage<CheckInSocketDto>) => void) => {
  const dispatch = useDispatch();

  const playAlertSound = usePlayAlertSound();

  const logInfo = (checkin: ICheckIn) =>
    console.info(
      'New checkin action:\n',
      `FirstName: ${checkin.firstName}\n`,
      `LastName: ${checkin.lastName}\n`,
      `Checked In: ${checkin.checkInTime}\n`,
      `Checked Type: ${checkin.checkInType}\n`,
      `Alerts: ${checkin.alerts}\n`,
    );

  return useCallback(
    async (msg: ISocketMessage<CheckInSocketDto>) => {
      const path = window.location.pathname;

      const isKidZoneCheckin = msg.data.kidZone;
      if (
        (clubId && clubId !== msg.data.clubId) ||
        path === KidZonePaths.KID_ZONE ||
        isKidZoneCheckin
      ) {
        return;
      }

      const checkIn = await Services.CheckIn.getCheckInInfo(msg.data.checkInId);

      const transformedCheckIn = mapCheckInDto(checkIn);

      if (path === FRONT_DESK_PATH) {
        console.log('passing checkin to front-desk...');
        logInfo(transformedCheckIn);
        busDispatch({ type: actionTypes.NEW_CHECKIN, payload: transformedCheckIn });
      } else {
        console.log('passing checkin to redux to show notification...');
        logInfo(transformedCheckIn);

        playAlertSound(transformedCheckIn.alerts);

        dispatch(enqueueCheckinNotification(transformedCheckIn));
      }
    },
    [clubId, dispatch, playAlertSound],
  );
};
