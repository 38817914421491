import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Box, makeStyles, Tooltip } from '@material-ui/core';

import commonMessages from 'common/messages/messages';
import { Button } from 'common/components/index';
import { CustomTheme } from 'common/ui/interfaces';
import { ReactComponent as AddIcon } from 'img/icons/add.svg';

interface IReferralSectionActionsProps {
  onAddNewClick: () => void;
}

const useStyles = makeStyles(() => ({
  icon: {
    width: 20,
    height: 20,
  },
}));

const ReferralSectionActions = (props: IReferralSectionActionsProps): JSX.Element => {
  const { onAddNewClick } = props;

  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center">
      <Tooltip title={<FormattedMessage {...commonMessages.addBtn} />}>
        <Button type="button" onClick={onAddNewClick} hasHoverStyle color="primary">
          <AddIcon className={classes.icon} />
        </Button>
      </Tooltip>
    </Box>
  );
};

export default ReferralSectionActions;
