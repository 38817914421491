import GrapesJS from 'grapesjs';

import productsIcon from 'img/editor-icons/block-products.svg';
import { GrapesCustomCommands, GrapesCustomTypes } from '../constants';
import { defaultToolbar } from './TextPlugin';

(GrapesJS as any).plugins.add(GrapesCustomTypes.ProductsList, editor => {
  function addCommands() {
    editor.Commands.add(GrapesCustomCommands.SearchProducts, {
      run() {},
      stop(commandEditor, sender, data) {
        const component = commandEditor.getSelected();

        if (data && data.component) {
          component.components().add({
            type: GrapesCustomTypes.ProductItem,
            attributes: {
              product: data.component,
            },
          });
        }
      },
    });
  }

  function addComponents() {
    const defaultType = editor.DomComponents.getType('default');
    const { initToolbar } = defaultType.model.prototype;

    editor.DomComponents.addType(GrapesCustomTypes.ProductsList, {
      model: {
        ...defaultType.model,
        defaults: {
          droppable: false,
        },
        initToolbar(args) {
          initToolbar.apply(this, args);

          const toolbar = [
            {
              attributes: { class: 'fa fa-plus' },
              command: GrapesCustomCommands.SearchProducts,
            },
            ...defaultToolbar,
          ];

          this.set('toolbar', toolbar);
        },
        isComponent(el) {
          if (
            el.hasAttribute === 'function' &&
            el.hasAttribute('data-gjs-type') === GrapesCustomTypes.ProductsList
          ) {
            return { type: GrapesCustomTypes.ProductsList };
          }
          return false;
        },
      },
      view: {
        ...defaultType.view,
      },
    });

    editor.DomComponents.addType(GrapesCustomTypes.ProductItem, {
      model: {
        ...defaultType.model,
        defaults: {
          draggable: false,
          droppable: false,
          highlightable: false,
        },
        init() {
          const attrs = this.getAttributes();
          const product = attrs.product || {};

          this.get('components').reset(`
            <img class="product-photo" src="${product.imageUrl}" alt="${product.title}" data-inventory-id=${product.id} />
            <div class="product-info">
              <div class="product-name" data-inventory-id=${product.id}>${product.title}</div>
            </div>
          `);

          this.addClass(GrapesCustomTypes.ProductItem);
        },
        isComponent(el) {
          if (
            el.hasAttribute === 'function' &&
            el.hasAttribute('data-gjs-type') === GrapesCustomTypes.ProductItem
          ) {
            return { type: GrapesCustomTypes.ProductItem };
          }
          return false;
        },
      },
      view: {
        ...defaultType.view,
        tagName: 'div',
      },
    });
  }

  function addBlocks() {
    editor.BlockManager.add(GrapesCustomTypes.ProductsList, {
      label: `
        <img class="gjs-block-icon" src="${productsIcon}" alt="Products" />
        <div class="gjs-block-title">Products</div>
      `,
      content: `
        <div class="products" data-gjs-type="products-list"></div>
        <style>
          .products {
            padding: 8px;
            min-height: 60px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
          }
          .product-item {
            margin: 8px;
            padding: 12px;
            flex: 1;
            flex-basis: 45%;
            display: flex;
            flex-direction: row;
            align-items: center;
            border: solid 1px #d6d6d6;
            border-radius: 4px;
          }
          .product-photo {
            margin-right: 12px;
            width: 66px;
            height: 66px;
            background-color: lightgrey;
            border-radius: 3px;
          }
          .product-name {
            margin-bottom: 5px;
            font-size: 14px;
            font-weight: bold;
          }
          .product-price {
            font-size: 14px;
            color: #323232;
            opacity: 0.7;
          }
        </style>
      `,
    });
  }

  addCommands();
  addComponents();
  addBlocks();
});

export default GrapesCustomTypes.ProductsList;
