import { List, fromJS } from 'immutable';

import { IBookingEvent } from 'modules/booking/interfaces';

import { initialState } from 'common/constants/initialState';
import { actionTypes } from 'modules/booking/constants';

const initialStateEvents = fromJS({
  list: List<IBookingEvent>([]),
  isLoading: false,
});

export const eventsReducer = (state = initialStateEvents, action) => {
  switch (action.type) {
    case actionTypes.GET_BOOKING_EVENTS_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.GET_BOOKING_EVENTS:
      return state.set('list', fromJS(action.payload)).set('isLoading', false);
    case actionTypes.RESET_BOOKING_EVENTS:
      return initialStateEvents;
    default:
      return state;
  }
};

const initialStateEventDetails = fromJS({
  event: {},
  isEventLoading: false,
  eventActionStatus: initialState,
});

export const eventDetailsReducer = (state = initialStateEventDetails, action) => {
  switch (action.type) {
    case actionTypes.GET_BOOKING_EVENT_LOADING:
      return state.set('isEventLoading', action.payload);
    case actionTypes.GET_BOOKING_EVENT:
      return state.set('event', fromJS(action.payload));
    case actionTypes.CREATE_EVENT_LOADING:
    case actionTypes.UPDATE_EVENT_LOADING:
    case actionTypes.RESTORE_EVENT_LOADING:
    case actionTypes.CANCEL_EVENT_LOADING:
    case actionTypes.DELETE_EVENT_LOADING:
    case actionTypes.DUPLICATE_EVENT_LOADING:
    case actionTypes.REMIND_EVENT_LOADING:
      return state.setIn(['eventActionStatus', 'isLoading'], action.payload);
    case actionTypes.CREATE_EVENT:
    case actionTypes.UPDATE_EVENT:
    case actionTypes.DELETE_EVENT:
    case actionTypes.DUPLICATE_EVENT:
    case actionTypes.REMIND_EVENT:
      return state.setIn(['eventActionStatus', 'result'], fromJS(action.payload));
    case actionTypes.CANCEL_EVENT:
      return state
        .setIn(['event', 'canceled'], true)
        .setIn(['eventActionStatus', 'result'], fromJS(action.payload))
        .set('isEventLoading', false);
    case actionTypes.RESTORE_EVENT:
      return state
        .setIn(['event', 'canceled'], false)
        .setIn(['eventActionStatus', 'result'], fromJS(action.payload))
        .set('isEventLoading', false);
    case actionTypes.RESET_BOOKING_EVENT_DETAILS:
      return state.set('event', fromJS({}));
    case actionTypes.RESET_EVENT_ACTION_RESULT:
      return state.set('eventActionStatus', fromJS(initialState));
    default:
      return state;
  }
};

const initialEventServicesState = fromJS({
  list: [],
  isLoading: false,
});

export const eventServicesReducer = (state = initialEventServicesState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_EVENT_SERVICES_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_EVENT_SERVICES:
      return state.set('list', fromJS(action.payload));
    case actionTypes.RESET_EVENT_SERVICES:
      return initialEventServicesState;
    default:
      return state;
  }
};
